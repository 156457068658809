import { Component, Input } from '@angular/core';

@Component({
    selector: 'trends-over-time-fishereffect-table',
    templateUrl: './trends-over-time-fishereffect-table.component.html',
    styleUrls: ['./trends-over-time-fishereffect-table.component.scss']
})
export class TrendsOverTimeFishereffectTableComponent {
    @Input() public inflationarraytable: any[] = [];
    @Input() public cashflowarraytable: any[] = [];
    @Input() public tabledateArray: any[] = [];
    @Input() public inputcountryname!: string;
    @Input() public cashflowcountryname!: string;
    @Input() public inflationline!: boolean;
    @Input() public cashflowline!: boolean;
    constructor() { }
}
