import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalManager, Spinner, SubscriberEntity } from '@concurrency/angular';
import { Select, Store } from '@ngxs/store';
import { Observable, combineLatest } from 'rxjs';
import { FilesClient } from 'src/app/_api/clients/files.client';
import { UserClient } from '../_api/clients/user.client';
import { TitleService } from '../_navigator/data/service/title.service';
import { DataStore } from '../_navigator/data/store/data.store';
import { CreateBetaEstimate, GetBetaEstimates } from '../_navigator/estimates-list-store/beta-estimates-list-actions';
import { CreateEstimate, GetEstimates } from '../_navigator/estimates-list-store/estimates-list-actions';
import { AccessType } from '../_navigator/user/enum/access-type';
import { User } from '../_navigator/user/model/user.model';
import { UserStore } from '../_navigator/user/store/user.store';
import { GetBasicMinimumDate } from '../_navigator/values-store/values-actions';
import { AcceptTermsModalComponent } from './_modals/accept-terms-modal.component';
import { FooterLinkUtil } from './footer/footer-link.util';
import { ContainerTypeEnum } from './resources-library/enum/container-type.enum';
import { ResourcesLibraryComponent } from './resources-library/resources-library.component';
import { AcceptVersionData, TermsAndConditionsVersions, UserSubscription } from '../_api/responses/user.response';
import { NzIconService } from 'ng-zorro-antd/icon';
import { EstimateType } from '../_api/enums/estimate-type';
import { NewEstimateModalComponent } from './_modals/new-estimate-modal.component';
import { ModuleType } from './dashboard/module-type';
import { DashboardModule } from './dashboard/dashboard-module.model';
import { SubscriptionData } from '../_navigator/user/model/subscription.model';
import { NewBetaEstimateModalComponent } from './_modals/new-beta-estimate-modal.component';
import { ProductType } from '../_navigator/user/enum/product-type';
import { SubscriptionLevel } from '../_navigator/user/enum/subscription-level';
import { UserUtil } from '../_navigator/user/util/user.util';
import { Analysis } from '../_navigator/data/model/analysis.model';
import { map, takeUntil } from 'rxjs/operators';
import { MinimumDate } from '../_api/responses/minimum-date.response';
import { ValuesState } from '../_navigator/values-store/values-state';
import * as moment from 'moment';
import { MenuDetails } from './home.menu.model';
import MenuData from './home.menu.data';
import { MenuType, cocProSubscriptions, cocestimmenuItemsatesTools, ineProSubscriptions } from './menu.type';


@Component({ templateUrl: './home.component.html' })
export class HomeComponent extends SubscriberEntity implements OnInit {
    isActive!: boolean;
    public get ContainerTypeEnum(): typeof ContainerTypeEnum {
        return ContainerTypeEnum;
    }

    @Select(ValuesState.get) public valuesSelector!: Observable<MinimumDate | undefined>;
    public isProductNewsRead = false;
    public FooterLinkUtil = FooterLinkUtil;
    public user!: User;
    public isTrial = false;
    public isRenew = false;
    public isauthorized = false;
    public subscriptionExpiryDate!: Date;
    public menuDetails!: MenuDetails[];
    constructor(
        public titleService: TitleService,
        public router: Router,
        public userStore: UserStore,
        private store: Store,
        private dataStore: DataStore,
        private modalManager: ModalManager,
        private userClient: UserClient,
        private spinner: Spinner,
        private filesClient: FilesClient, private userProfileClient: UserClient,
        private iconService: NzIconService
    ) {
        super();

        this.menuDetails = MenuData;
        this.genereateCustomeIconNameSpace();



    }
    public logout = () => this.userStore.logoutCustomerOrEmployee();
    public goto = (route: string) => this.router.navigate([route]);



    public ngOnInit(): void {
        const profileRequest = this.userProfileClient.read();
        //this.spinner.while(profileRequest);
        this.spinner.begin();
        profileRequest.onceDefined((profile) => {
            for (const i of profile.Subscriptions) {
                const isAuthorizednow = new Date(i.End) > new Date();
                if (isAuthorizednow === true) {
                    this.isauthorized = isAuthorizednow;
                }
            }
            this.spinner.end();
        });
        this.userStore.access.onceDefined((access: AccessType) => {
            if (access === 'Authorized') {
                this.dataStore.init();
                const request = combineLatest([
                    this.store.dispatch(new GetEstimates()),
                    this.store.dispatch(new GetBetaEstimates()),
                    this.store.dispatch(new GetBasicMinimumDate())
                ]);
                this.spinner.while(request);
            }
        });
        this.userStore.user.onceDefined((user) => {
            this.user = user;
            this.isProductNewsRead = this.user.ProductNewsRead;

            if (user.AcceptTerms === false) { //uncomeented to  Accept T&C for existing user
                this.requireTermsAndConditions();
            }
            this.isTrial = user.hasTrial();
            this.titleService.set(`${user.FullName} <span>${user.Email}</span>`);
            this.isRenew = user.showRenew();

            const iterateEl = document.getElementsByClassName('ms-Icon,.ms-Icon--Cancel,.cls');
            // console.log(iterateEl.length);
            if (iterateEl.length === 6) {
                iterateEl[0].addEventListener('click', () => this.productNewsRead(''));
            }

            this.userStore.user.onceDefined((user) => {
                this.isActive = user.IsActive;
                this.user = user;
                if (user && user.IsActive === false) {

                }
                this.titleService.set(`${user.FullName} <span>${user.Email}</span>`);
                this.getActiveMenuItems(user.subscriptionDetails)


            });

        });
    }

    public productNewsRead(productNewsId: string): void {
        this.isProductNewsRead = true;
        this.userClient.productNewsReadStatusUpdate(productNewsId, true).subscribe();
    }


    //terms and condition popup window
    public requireTermsAndConditions(): void {

        const request = this.userClient.getTermsAndCondition();
        request.once((data: TermsAndConditionsVersions) => {
            if (data) {
                this.userStore.AcceptTermsData = data;
                const modal = this.modalManager.open<AcceptVersionData, AcceptTermsModalComponent>(AcceptTermsModalComponent,
                    { mayDismiss: false, size: 'lg' },);
                modal.once((acceptData: AcceptVersionData) => this.userClient.acceptTerms(acceptData));
            }
        });



    }

    public getFile(fileName: string): void {
        this.filesClient.getFile(fileName).subscribe((data) => {
            ResourcesLibraryComponent.openFile(data, fileName);
        });
    }

    public getResourceFile(fileName: string): void {
        this.filesClient.getResourceFile(fileName).subscribe((data) => {
            ResourcesLibraryComponent.openFile(data, fileName);
        });
    }

    public getStorageFile(fileName: string, typeContainer: ContainerTypeEnum): void {
        this.filesClient.getStorageFile(fileName, typeContainer).subscribe((data) => {
            ResourcesLibraryComponent.openFile(data, fileName);
        });
    }


    public launchModule(module: MenuDetails): void {
        if (module.type === MenuType.UsCostOfCapitalEstimates) {
            this.openNewEstimateModal(EstimateType.USEstimate, 'estimate');
        } else if (module.type === MenuType.UsIndustryBenchmarking) {
            this.startIndustryAnalysis();
        } else if (module.type === MenuType.InternationalCostOfCapitalEstimate) {
            this.openNewEstimateModal(EstimateType.InternationalEstimate, 'international-estimate');
        } else if (module.type === MenuType.InternationalIndustryBenchmarking) {
            this.startIndustryAnalysis();
        } else if (module.type === MenuType.CompanyLevelBeta) {
            this.openNewBetaEstimateModal(EstimateType.BetaEstimate, 'beta');
            // this.navigate('beta');
        }
    }

    private openNewEstimateModal(type: EstimateType, route: string): void {
        const modal = this.modalManager.open<string, NewEstimateModalComponent>(NewEstimateModalComponent);
        modal.once((name) => {
            const request = this.store.dispatch(new CreateEstimate(name, type));
            request.once(() => {
                this.dataStore.setIsIndustryAnalysis(false);
                this.router.navigate([route]);
            });
            this.spinner.while(request);
        });
    }


    private openNewBetaEstimateModal(type: EstimateType, route: string): void {
        const modal = this.modalManager.open<string, NewBetaEstimateModalComponent>(NewBetaEstimateModalComponent);
        modal.once((name) => {
            const request = this.store.dispatch(new CreateBetaEstimate(name, type));
            request.once(() => {
                this.router.navigate([route]);
            });
            this.spinner.while(request);
        });
    }


    private startIndustryAnalysis(isInternationalIndustryBenchmarking: boolean = false): void {
        combineLatest([
            this.userStore.user,
            this.valuesSelector
        ]).pipe(
            takeUntil(this.destroyed),
            map((x) => ({
                user: x[0],
                values: x[1]
            }))
        ).onceDefined((data) => {
            if (data.user == null || data.values == null) {
                return;
            }
            const defaultValuationDate = moment().toISOString();
            this.setTrialMinimumDate(data, ProductType.Usi, SubscriptionLevel.Trial, defaultValuationDate);
            this.router.navigate(['industry-benchmarking']);
        });
    }

    private setTrialMinimumDate(data: any, productType: ProductType, level: SubscriptionLevel, defaultValuationDate: string): void {
        if (data.user.subscriptionDetails.find((x: any) => x.productType === productType && x.level === level)) {
            const minimumValuationDate = UserUtil.getSubscriptionMinimumValuationDate(
                productType,
                SubscriptionLevel.Trial,
                data.values.UsIndustries,
                data.user.subscriptionDetails
            );
            defaultValuationDate = minimumValuationDate.toISOString();
        }
        this.dataStore.setIsIndustryAnalysis(true);
        this.dataStore.setAnalysis(new Analysis(defaultValuationDate, [], true));
    }


    private getActiveMenuItems(subscriptionData: SubscriptionData[]) {
        const cocPro = cocProSubscriptions;
        const inePro = ineProSubscriptions;
        const subscriptionFeatures = subscriptionData.filter(item => (item.productType === ProductType.Coc && item.isExpired === false) || (item.productType === ProductType.Ine && item.isExpired === false));

        if (subscriptionFeatures.length > 0) {
            const isAnyActiveSubscription = subscriptionFeatures.find(s => s.isExpired === false);
            if (isAnyActiveSubscription) {
                this.menuDetails.forEach(s => s.isDisabled = false);
            }
            const isCocProExists = subscriptionFeatures.find(s => s.productType === ProductType.Coc);
            const ineProExist = subscriptionFeatures.find(d => d.productType === ProductType.Ine);
            if (isCocProExists) {
                this.menuDetails[1].children.map(s => s.isDisabled = false);//making Viewdata menu items to false so that the it will enable to user
                this.menuDetails[2].children.map(item => {
                    if (cocPro.find(s => s === item.type)) {
                        item.isDisabled = false;
                    }
                })//making Cost of Capital Tool menu to false so that the it will enable to user

            }
            if (ineProExist) {
                this.menuDetails[1].children.map(s => s.isDisabled = false); //Making COC Inputs and Benchamrking enabled 
                // this.menuDetails[1].children[1].type = MenuType.InternationalIndustryBenchmarking //By default ViewData industryBenchmarking have UsIndustrybenchmarking if user have inepro we are updating to internationalIndustryBenchMarking
                this.menuDetails[2].children.map(f => {
                    if (inePro.find(d => d === f.type)) {
                        f.isDisabled = false;

                    }
                })//making Cost of Capital Tool menu to false so that the it will enable to user
            }

        } else {
            return;
        }



    }

    private handlingPageNavigation(menuItem: MenuDetails) {
        if (menuItem.isDisabled === false) {
            const cocestimatestools = cocestimmenuItemsatesTools;
            if (cocestimatestools.find(s => s === menuItem.type)) {
                this.launchModule(menuItem);
            } else {
                this.goto(menuItem.path);
            }
        } else {
            return;
        }

    }

    public genereateCustomeIconNameSpace() {
        const CustomIcons = [
            {
                icon: 'ng-zorro:dashboard', svg: `<svg width="28" height="23" viewBox="0 0 23 23" fill="none">
            <path d="M7.75 8C6.7835 8 6 8.7835 6 9.75V16.25C6 17.2165 6.7835 18 7.75 18H18.25C19.2165 18 20 17.2165 20 16.25V9.75C20 8.7835 19.2165 8 18.25 8H7.75ZM7.5 9.75C7.5 9.61193 7.61193 9.5 7.75 9.5H18.25C18.3881 9.5 18.5 9.61193 18.5 9.75V11.5H7.5V9.75ZM11.5 13H18.5V16.25C18.5 16.3881 18.3881 16.5 18.25 16.5H11.5V13ZM10 13V16.5H7.75C7.61193 16.5 7.5 16.3881 7.5 16.25V13H10ZM5.75 5C4.23122 5 3 6.23122 3 7.75V18.25C3 19.7688 4.23122 21 5.75 21H20.25C21.7688 21 23 19.7688 23 18.25V7.75C23 6.23122 21.7688 5 20.25 5H5.75ZM4.5 7.75C4.5 7.05964 5.05964 6.5 5.75 6.5H20.25C20.9404 6.5 21.5 7.05964 21.5 7.75V18.25C21.5 18.9404 20.9404 19.5 20.25 19.5H5.75C5.05964 19.5 4.5 18.9404 4.5 18.25V7.75Z" fill="#43B049"/>
            </svg>`},
            {
                icon: 'ng-zorro:tools', svg: `<svg width="23" height="23" viewBox="0 0 23 23" fill="none">
            <path d="M7 7C7 5.89543 7.89543 5 9 5H15C16.1046 5 17 5.89543 17 7V8C17 9.10457 16.1046 10 15 10H9C7.89543 10 7 9.10457 7 8V7ZM9 6.5C8.72386 6.5 8.5 6.72386 8.5 7V8C8.5 8.27614 8.72386 8.5 9 8.5H15C15.2761 8.5 15.5 8.27614 15.5 8V7C15.5 6.72386 15.2761 6.5 15 6.5H9ZM8.25 14.5C8.94036 14.5 9.5 13.9404 9.5 13.25C9.5 12.5596 8.94036 12 8.25 12C7.55964 12 7 12.5596 7 13.25C7 13.9404 7.55964 14.5 8.25 14.5ZM9.5 17.25C9.5 17.9404 8.94036 18.5 8.25 18.5C7.55964 18.5 7 17.9404 7 17.25C7 16.5596 7.55964 16 8.25 16C8.94036 16 9.5 16.5596 9.5 17.25ZM15.75 14.5C16.4404 14.5 17 13.9404 17 13.25C17 12.5596 16.4404 12 15.75 12C15.0596 12 14.5 12.5596 14.5 13.25C14.5 13.9404 15.0596 14.5 15.75 14.5ZM17 17.25C17 17.9404 16.4404 18.5 15.75 18.5C15.0596 18.5 14.5 17.9404 14.5 17.25C14.5 16.5596 15.0596 16 15.75 16C16.4404 16 17 16.5596 17 17.25ZM12 14.5C12.6904 14.5 13.25 13.9404 13.25 13.25C13.25 12.5596 12.6904 12 12 12C11.3096 12 10.75 12.5596 10.75 13.25C10.75 13.9404 11.3096 14.5 12 14.5ZM13.25 17.25C13.25 17.9404 12.6904 18.5 12 18.5C11.3096 18.5 10.75 17.9404 10.75 17.25C10.75 16.5596 11.3096 16 12 16C12.6904 16 13.25 16.5596 13.25 17.25ZM7.25 2C5.45507 2 4 3.45507 4 5.25V18.75C4 20.5449 5.45507 22 7.25 22H16.75C18.5449 22 20 20.5449 20 18.75V5.25C20 3.45507 18.5449 2 16.75 2H7.25ZM5.5 5.25C5.5 4.2835 6.2835 3.5 7.25 3.5H16.75C17.7165 3.5 18.5 4.2835 18.5 5.25V18.75C18.5 19.7165 17.7165 20.5 16.75 20.5H7.25C6.2835 20.5 5.5 19.7165 5.5 18.75V5.25Z" fill="#43B049"/>
            </svg>`},
            {
                icon: 'ng-zorro:pieChart', svg: `<svg width="23" height="23" viewBox="0 0 23 23" fill="none">
            <path d="M20.2499 12.1405H11.8593V3.74986C11.8593 3.64673 11.7749 3.56236 11.6718 3.56236H11.0624C9.83122 3.56034 8.6118 3.80179 7.47429 4.2728C6.33677 4.74381 5.30362 5.4351 4.43427 6.30689C3.57812 7.16039 2.89595 8.17211 2.42567 9.2858C1.93631 10.4409 1.6852 11.6829 1.68739 12.9374C1.68538 14.1685 1.92682 15.388 2.39783 16.5255C2.86885 17.663 3.56014 18.6961 4.43192 19.5655C5.29208 20.4256 6.29521 21.103 7.41083 21.5741C8.56592 22.0634 9.80792 22.3146 11.0624 22.3124C12.2936 22.3144 13.513 22.0729 14.6505 21.6019C15.788 21.1309 16.8212 20.4396 17.6905 19.5678C18.5507 18.7077 19.228 17.7045 19.6991 16.5889C20.1885 15.4338 20.4396 14.1918 20.4374 12.9374V12.328C20.4374 12.2249 20.353 12.1405 20.2499 12.1405ZM16.5397 18.4639C15.814 19.184 14.9533 19.7538 14.007 20.1407C13.0606 20.5276 12.0472 20.724 11.0249 20.7186C8.96005 20.7092 7.01942 19.9006 5.55927 18.4405C4.08974 16.971 3.28114 15.0163 3.28114 12.9374C3.28114 10.8585 4.08974 8.90376 5.55927 7.43423C6.83895 6.15455 8.48661 5.37408 10.2655 5.19595V13.7342H18.8038C18.6233 15.5225 17.8358 17.1795 16.5397 18.4639ZM22.3124 10.8374L22.2515 10.1764C22.0522 8.01783 21.0936 5.98111 19.5515 4.44361C18.0082 2.90329 15.9756 1.95001 13.8046 1.74829L13.1413 1.68736C13.0311 1.67798 12.9374 1.76236 12.9374 1.87251V10.8749C12.9374 10.978 13.0218 11.0624 13.1249 11.0624L22.1249 11.0389C22.235 11.0389 22.3218 10.9452 22.3124 10.8374ZM14.5265 9.4733V3.45923C16.0029 3.76825 17.3577 4.49943 18.4265 5.56392C19.4975 6.63267 20.2311 7.99204 20.5358 9.45689L14.5265 9.4733Z" fill="#43B049"/>
            </svg>`},
            {
                icon: 'ng-zorro:file', svg: `<svg width="23" height="23" viewBox="0 0 23 23" fill="none">
            <path d="M5.5028 4.62704L5.5 6.75V17.2542C5.5 19.0491 6.95507 20.5042 8.75 20.5042L17.3663 20.5045C17.0573 21.3782 16.224 22.0042 15.2444 22.0042H8.75C6.12665 22.0042 4 19.8776 4 17.2542V6.75C4 5.76929 4.62745 4.93512 5.5028 4.62704ZM13.1284 2C13.7254 2 14.2979 2.23723 14.7199 2.65947L19.3383 7.28054C19.7599 7.70246 19.9968 8.27456 19.9968 8.87107V17.2542C19.9968 18.4969 18.9895 19.5042 17.7468 19.5042H8.75241C7.50977 19.5042 6.50241 18.4969 6.50241 17.2542V4.25C6.50241 3.00736 7.50977 2 8.75241 2H13.1284ZM12.9994 3.5H8.75241C8.33819 3.5 8.00241 3.83579 8.00241 4.25V17.2542C8.00241 17.6684 8.33819 18.0042 8.75241 18.0042H17.7468C18.161 18.0042 18.4968 17.6684 18.4968 17.2542L18.4964 9.003L15.25 9.00389C14.0591 9.00389 13.0844 8.07873 13.0052 6.90794L13 6.75389L12.9994 3.5ZM14.4994 4.561L14.5 6.75389C14.5 7.13359 14.7822 7.44738 15.1482 7.49704L15.25 7.50389L17.4404 7.503L14.4994 4.561Z" fill="#43B049"/>
            </svg>`},
            {
                icon: 'ng-zorro:link', svg: `<svg width="26" height="26" viewBox="0 0 26 26" fill="none">
            <path d="M11 8C11.5523 8 12 8.44772 12 9C12 9.51284 11.614 9.93551 11.1166 9.99327L11 10H8C5.79086 10 4 11.7909 4 14C4 16.1422 5.68397 17.8911 7.80036 17.9951L8 18H11C11.5523 18 12 18.4477 12 19C12 19.5128 11.614 19.9355 11.1166 19.9933L11 20H8C4.68629 20 2 17.3137 2 14C2 10.7616 4.56557 8.12243 7.77506 8.00414L8 8H11ZM20 8C23.3137 8 26 10.6863 26 14C26 17.2384 23.4344 19.8776 20.2249 19.9959L20 20H17C16.4477 20 16 19.5523 16 19C16 18.4872 16.386 18.0645 16.8834 18.0067L17 18H20C22.2091 18 24 16.2091 24 14C24 11.8578 22.316 10.1089 20.1996 10.0049L20 10H17C16.4477 10 16 9.55228 16 9C16 8.48716 16.386 8.06449 16.8834 8.00673L17 8H20ZM8 13H20C20.5523 13 21 13.4477 21 14C21 14.5128 20.614 14.9355 20.1166 14.9933L20 15H8C7.44772 15 7 14.5523 7 14C7 13.4872 7.38604 13.0645 7.88338 13.0067L8 13H20H8Z" fill="#43B049"/>
            </svg>`}, {
                icon: 'ng-zorro:excel', svg: `<svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.91387 7.03711C5.9241 7.05018 5.93717 7.06075 5.95209 7.06803C5.96702 7.0753 5.9834 7.07907 6 7.07907C6.0166 7.07907 6.03298 7.0753 6.04791 7.06803C6.06283 7.06075 6.0759 7.05018 6.08613 7.03711L7.61738 5.0998C7.67344 5.02871 7.62285 4.92344 7.53125 4.92344H6.51816V0.296875C6.51816 0.236719 6.46895 0.1875 6.40879 0.1875H5.58848C5.52832 0.1875 5.4791 0.236719 5.4791 0.296875V4.92207H4.46875C4.37715 4.92207 4.32656 5.02734 4.38262 5.09844L5.91387 7.03711ZM11.0039 6.55859H10.1836C10.1234 6.55859 10.0742 6.60781 10.0742 6.66797V8.77344H1.92578V6.66797C1.92578 6.60781 1.87656 6.55859 1.81641 6.55859H0.996094C0.935937 6.55859 0.886719 6.60781 0.886719 6.66797V9.375C0.886719 9.61699 1.08223 9.8125 1.32422 9.8125H10.6758C10.9178 9.8125 11.1133 9.61699 11.1133 9.375V6.66797C11.1133 6.60781 11.0641 6.55859 11.0039 6.55859Z" fill="#4D4D4F"/>
                </svg>`
            },

        ];

        CustomIcons.forEach(item => {
            this.iconService.addIconLiteral(item.icon, item.svg)

        })



    }

    public navigateToDataponts() {
        this.router.navigate(['/dataPoints-offered'])
    }

    public navigateToHeatMap() {
        this.router.navigate(['/heatmap'])
    }

}
