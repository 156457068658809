import { SubscriptionLevel } from 'src/app/_navigator/user/enum/subscription-level';
import { SubscriptionData } from 'src/app/_navigator/user/model/subscription.model';
import { Categories } from './const/categories.const';
import { Metrics } from './const/metrics.const';
import { Category } from './enums/category';
import { Metric } from './enums/metric';
import { MetricGroup } from './enums/metric-group';
import { TearSheetRow } from './models/tear-sheet-row.model';

export function TearSheet(subscriptionData?: SubscriptionData): TearSheetRow[] {
    const hasProPlusSubscription = subscriptionData && subscriptionData.level === SubscriptionLevel.Pro ||
        subscriptionData && subscriptionData.level === SubscriptionLevel.Enterprise;

    return [
        {
            id: 1,
            categories: [
                {
                    category: Category.ReturnRatios,
                    categoryName: Categories.ReturnRatios,
                    showCompositeLabels: true,
                    position: 1,
                    metricGroups: [
                        {
                            metricGroup: MetricGroup.ReturnOnAssets,
                            metricGroupName: Metrics.ReturnOnAssets,
                            metrics: [
                                Metric.ReturnOnAssets
                            ],
                            trendsOverTimeColumn: hasProPlusSubscription
                        },
                        {
                            metricGroup: MetricGroup.ReturnOnEquity,
                            metricGroupName: Metrics.ReturnOnEquity,
                            metrics: [
                                Metric.ReturnOnEquity
                            ],
                            trendsOverTimeColumn: hasProPlusSubscription
                        },
                        {
                            metricGroup: MetricGroup.DividendYield,
                            metricGroupName: Metrics.DividendYield,
                            metrics: [
                                Metric.DividendYield
                            ],
                            trendsOverTimeColumn: hasProPlusSubscription
                        }
                    ]
                },
                {
                    category: Category.LiquidityRatio,
                    categoryName: Categories.LiquidityRatio,
                    showCompositeLabels: false,
                    position: 2,
                    metricGroups: [
                        {
                            metricGroup: MetricGroup.CurrentRatio,
                            metricGroupName: Metrics.CurrentRatio,
                            metrics: [
                                Metric.CurrentRatio
                            ],
                            trendsOverTimeColumn: hasProPlusSubscription
                        }
                    ]
                },
                {
                    category: Category.ProfitabilityRatio,
                    categoryName: Categories.ProfitabilityRatio,
                    showCompositeLabels: false,
                    position: 3,
                    metricGroups: [
                        {
                            metricGroup: MetricGroup.OperatingMargin,
                            metricGroupName: Metrics.OperatingMargin,
                            metrics: [
                                Metric.OperatingMargin
                            ],
                            trendsOverTimeColumn: hasProPlusSubscription
                        }
                    ]
                },
                {
                    category: Category.GrowthRates,
                    categoryName: Categories.GrowthRates,
                    showCompositeLabels: false,
                    position: 4,
                    metricGroups: [
                        {
                            metricGroup: MetricGroup.LongTermEps,
                            metricGroupName: Metrics.LongTermEps,
                            metrics: [
                                Metric.LongTermEps
                            ],
                            trendsOverTimeMetricTitle: hasProPlusSubscription
                        }
                    ]
                }
            ]
        },
        {
            id: 2,
            categories: [
                {
                    category: Category.BetasLevered,
                    categoryName: Categories.BetasLevered,
                    showCompositeLabels: true,
                    position: 1,
                    metricGroups: [
                        {
                            metricGroup: MetricGroup.BetasLeveredRaw,
                            metricGroupName: Metrics.RawOls,
                            metrics: [
                                Metric.RawOlsLevered
                            ],
                            trendsOverTimeMetricTitle: hasProPlusSubscription
                        },
                        {
                            metricGroup: MetricGroup.BetasLeveredBlumeAdjusted,
                            metricGroupName: Metrics.BlumeAdjusted,
                            metrics: [
                                Metric.BlumeAdjustedLevered
                            ],
                            trendsOverTimeMetricTitle: hasProPlusSubscription
                        },
                        {
                            metricGroup: MetricGroup.BetasLeveredPeerGroup,
                            metricGroupName: Metrics.PeerGroup,
                            metrics: [
                                Metric.PeerGroupLevered
                            ],
                            trendsOverTimeMetricTitle: hasProPlusSubscription
                        },
                        {
                            metricGroup: MetricGroup.BetasLeveredVasicekAdjusted,
                            metricGroupName: Metrics.VasicekAdjusted,
                            metrics: [
                                Metric.VasicekAdjustedLevered
                            ],
                            trendsOverTimeMetricTitle: hasProPlusSubscription
                        },
                        {
                            metricGroup: MetricGroup.BetasLeveredSum,
                            metricGroupName: Metrics.Sum,
                            metrics: [
                                Metric.SumLevered
                            ],
                            trendsOverTimeMetricTitle: hasProPlusSubscription
                        },
                        // {
                        //     metricGroup: MetricGroup.BetasLeveredDownside,
                        //     metricGroupName: Metrics.Downside,
                        //     metrics: [
                        //         Metric.DownsideLevered
                        //     ],
                        //     trendsOverTimeMetricTitle: hasProPlusSubscription
                        // }
                    ]
                },
                {
                    category: Category.BetasUnlevered,
                    categoryName: Categories.BetasUnlevered,
                    showCompositeLabels: false,
                    position: 2,
                    metricGroups: [
                        {
                            metricGroup: MetricGroup.BetasUnleveredRaw,
                            metricGroupName: Metrics.RawOls,
                            metrics: [
                                Metric.RawOlsUnlevered
                            ],
                            trendsOverTimeMetricTitle: hasProPlusSubscription
                        },
                        {
                            metricGroup: MetricGroup.BetasUnleveredBlumeAdjusted,
                            metricGroupName: Metrics.BlumeAdjusted,
                            metrics: [
                                Metric.BlumeAdjustedUnlevered
                            ],
                            trendsOverTimeMetricTitle: hasProPlusSubscription
                        },
                        {
                            metricGroup: MetricGroup.BetasUnleveredPeerGroup,
                            metricGroupName: Metrics.PeerGroup,
                            metrics: [
                                Metric.PeerGroupUnlevered
                            ],
                            trendsOverTimeMetricTitle: hasProPlusSubscription
                        },
                        {
                            metricGroup: MetricGroup.BetasUnleveredVasicekAdjusted,
                            metricGroupName: Metrics.VasicekAdjusted,
                            metrics: [
                                Metric.VasicekAdjustedUnlevered
                            ],
                            trendsOverTimeMetricTitle: hasProPlusSubscription
                        },
                        {
                            metricGroup: MetricGroup.BetasUnleveredSum,
                            metricGroupName: Metrics.Sum,
                            metrics: [
                                Metric.SumUnlevered
                            ],
                            trendsOverTimeMetricTitle: hasProPlusSubscription
                        },
                        // {
                        //     metricGroup: MetricGroup.BetasUnleveredDownside,
                        //     metricGroupName: Metrics.Downside,
                        //     metrics: [
                        //         Metric.DownsideUnlevered
                        //     ],
                        //     trendsOverTimeMetricTitle: hasProPlusSubscription
                        // }
                    ]
                }
            ]
        },
        {
            id: 3,
            categories: [
                {
                    category: Category.EquityValuationMultiples,
                    categoryName: Categories.EquityValuationMultiples,
                    showCompositeLabels: true,
                    position: 1,
                    metricGroups: [
                        {
                            metricGroup: MetricGroup.PriceSales,
                            metricGroupName: Metrics.PriceSales,
                            metrics: [
                                Metric.PriceSales
                            ],
                            trendsOverTimeColumn: hasProPlusSubscription
                        },
                        {
                            metricGroup: MetricGroup.PriceEarnings,
                            metricGroupName: Metrics.PriceEarnings,
                            metrics: [
                                Metric.PriceEarnings
                            ],
                            trendsOverTimeColumn: hasProPlusSubscription
                        },
                        {
                            metricGroup: MetricGroup.MarketBook,
                            metricGroupName: Metrics.MarketBook,
                            metrics: [
                                Metric.MarketBook
                            ],
                            trendsOverTimeColumn: hasProPlusSubscription
                        },
                        {
                            metricGroup: MetricGroup.PriceTbv,
                            metricGroupName: Metrics.PriceTbv,
                            metrics: [
                                Metric.PriceTbv
                            ],
                            trendsOverTimeColumn: hasProPlusSubscription
                        },
                        {
                            metricGroup: MetricGroup.PriceEbt,
                            metricGroupName: Metrics.PriceEbt,
                            metrics: [
                                Metric.PriceEbt
                            ],
                            trendsOverTimeColumn: hasProPlusSubscription
                        }
                    ]
                },
                {
                    category: Category.EnterpriseValuationMultiples,
                    categoryName: Categories.EnterpriseValuationMultiples,
                    showCompositeLabels: false,
                    position: 2,
                    metricGroups: [
                        {
                            metricGroup: MetricGroup.EvSales,
                            metricGroupName: Metrics.EvSales,
                            metrics: [
                                Metric.EvSales
                            ],
                            trendsOverTimeColumn: hasProPlusSubscription
                        },
                        {
                            metricGroup: MetricGroup.EvEbitda,
                            metricGroupName: Metrics.EvEbitda,
                            metrics: [
                                Metric.EvEbitda
                            ],
                            trendsOverTimeColumn: hasProPlusSubscription
                        },
                        {
                            metricGroup: MetricGroup.EnterpriseValuation,
                            metricGroupName: Metrics.EnterpriseValuation,
                            metrics: [
                                Metric.EnterpriseValuation
                            ]
                        }
                    ]
                }
            ]
        },
        {
            id: 4,
            categories: [
                {
                    category: Category.FamaFrenchFiveFactor,
                    categoryName: Categories.FamaFrench5FactorModel,
                    showCompositeLabels: true,
                    position: 1,
                    metricGroups: [
                        {
                            metricGroup: MetricGroup.FamaFrenchComponents,
                            metricGroupName: Metrics.FamaFrenchComponents,
                            metrics: [
                                Metric.FiveFactorModelBeta,
                                Metric.FiveFactorModelSmbPremium,
                                Metric.FiveFactorModelHmlPremium,
                                Metric.FiveFactorModelRmwPremium,
                                Metric.FiveFactorModelCmaPramium
                            ],
                            trendsOverTimeColumn: hasProPlusSubscription
                        }
                    ]
                },
                {
                    category: Category.LeverageRatios,
                    categoryName: Categories.LeverageRatios,
                    showCompositeLabels: false,
                    position: 2,
                    metricGroups: [
                        {
                            metricGroup: MetricGroup.DebtMvEquity,
                            metricGroupName: Metrics.DebtMvEquity,
                            metrics: [
                                Metric.DebtMvEquity
                            ],
                            trendsOverTimeColumn: hasProPlusSubscription
                        },
                        {
                            metricGroup: MetricGroup.DebtTotalCapital,
                            metricGroupName: Metrics.DebtTotalCapital,
                            metrics: [
                                Metric.DebtTotalCapital
                            ],
                            trendsOverTimeColumn: hasProPlusSubscription
                        }
                    ]
                },
                {
                    category: Category.CostOfDebt,
                    categoryName: Categories.CostOfDebt,
                    showCompositeLabels: false,
                    position: 3,
                    metricGroups: [
                        {
                            metricGroup: MetricGroup.CostOfDebt,
                            metricGroupName: Metrics.CostOfDebt,
                            metrics: [
                                Metric.CostOfDebt
                            ],
                            trendsOverTimeColumn: hasProPlusSubscription
                        }
                    ]
                },
                {
                    category: Category.CapitalStructure,
                    categoryName: Categories.CapitalStructure,
                    showCompositeLabels: false,
                    position: 4,
                    metricGroups: [
                        {
                            metricGroup: MetricGroup.SicComposite,
                            metricGroupName: Metrics.SicComposite,
                            metrics: [
                                Metric.SICComposite
                            ]
                        }
                    ]
                }
            ]
        },
        {
            id: 5,
            categories: [
                {
                    category: Category.CostOfEquityCapital,
                    categoryName: Categories.CostOfEquityCapital,
                    showCompositeLabels: true,
                    position: 1,
                    metricGroups: [
                        {
                            metricGroup: MetricGroup.CostOfEquityCapitalCapm,
                            metricGroupName: '&nbsp;',
                            metrics: [
                                Metric.Capm
                            ],
                            trendsOverTimeColumn: hasProPlusSubscription
                        },
                        {
                            metricGroup: MetricGroup.CostOfEquityCapitalCrspDeciles,
                            metricGroupName: Metrics.CrspDeciles,
                            metrics: [
                                Metric.CrspCapm,
                                Metric.CrspBuildUp
                            ],
                            trendsOverTimeColumn: hasProPlusSubscription
                        },
                        {
                            metricGroup: MetricGroup.CostOfEquityCapitalRiskPremiumReport,
                            metricGroupName: Metrics.RiskPremiumReport,
                            metrics: [
                                Metric.RprCapm,
                                Metric.RprBuildUp
                            ],
                            trendsOverTimeColumn: hasProPlusSubscription
                        },
                        {
                            metricGroup: MetricGroup.CostOfEquityCapitalDiscountedCashFlow,
                            metricGroupName: Metrics.DiscountedCashFlow,
                            metrics: [
                                Metric.OneStageDcf,
                                Metric.ThreeStageDcf,
                                Metric.ThreeStageFactor
                            ],
                            trendsOverTimeColumn: hasProPlusSubscription
                        },
                        {
                            metricGroup: MetricGroup.CostOfEquityCapitalFamaFrench,
                            metricGroupName: Metrics.FamaFrench,
                            metrics: [
                                Metric.FiveStageFactor
                            ],
                            trendsOverTimeColumn: hasProPlusSubscription
                        },
                        {
                            metricGroup: MetricGroup.CostOfEquityCapital,
                            metricGroupName: Metrics.CostOfEquityCapital,
                            metrics: [
                                Metric.CostOfEquityCapital
                            ]
                        }
                    ]
                }
            ]
        },
        {
            id: 6,
            categories: [
                {
                    category: Category.Wacc,
                    categoryName: Categories.Wacc,
                    showCompositeLabels: true,
                    position: 1,
                    metricGroups: [
                        {
                            metricGroup: MetricGroup.WaccCapm,
                            metricGroupName: '&nbsp;',
                            metrics: [
                                Metric.WaccCapm
                            ],
                            trendsOverTimeColumn: hasProPlusSubscription
                        },
                        {
                            metricGroup: MetricGroup.WaccCrspDeciles,
                            metricGroupName: Metrics.CrspDeciles,
                            metrics: [
                                Metric.WaccCrspCapm,
                                Metric.WaccCrspBuildUp
                            ],
                            trendsOverTimeColumn: hasProPlusSubscription
                        },
                        {
                            metricGroup: MetricGroup.WaccRiskPremiumReport,
                            metricGroupName: Metrics.RiskPremiumReport,
                            metrics: [
                                Metric.WaccRprCapm,
                                Metric.WaccRprBuildUp
                            ],
                            trendsOverTimeColumn: hasProPlusSubscription
                        },
                        {
                            metricGroup: MetricGroup.WaccDiscountedCashFlow,
                            metricGroupName: Metrics.DiscountedCashFlow,
                            metrics: [
                                Metric.WaccOneStageDcf,
                                Metric.WaccThreeStageDcf,
                                Metric.WaccThreeStageFactor
                            ],
                            trendsOverTimeColumn: hasProPlusSubscription
                        },
                        {
                            metricGroup: MetricGroup.WaccFamaFrench,
                            metricGroupName: Metrics.FamaFrench,
                            metrics: [
                                Metric.WaccFiveStageFactor
                            ],
                            trendsOverTimeColumn: hasProPlusSubscription
                        },
                        {
                            metricGroup: MetricGroup.Wacc,
                            metricGroupName: Metrics.Wacc,
                            metrics: [
                                Metric.WaccSicComposite
                            ]
                        }
                    ]
                }
            ]
        }
    ];
}
