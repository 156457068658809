import { Component, OnInit } from '@angular/core';
import { Spinner, SubscriberEntity } from '@concurrency/angular';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs/internal/Observable';
import { UserClient } from 'src/app/_api/clients/user.client';
import { EstimateType } from 'src/app/_api/enums/estimate-type';
import { Estimate } from 'src/app/_navigator/data/model/estimate.model';
import { EstimatesListState } from 'src/app/_navigator/estimates-list-store/estimates-list-state';

@Component({
    selector: 'intl-estimates',
    templateUrl: './intl-estimates.component.html'
})
export class IntlEstimatesComponent extends SubscriberEntity implements OnInit {
    public moduleEstimates!: Estimate[] | undefined;
    public hideestimates = false;
    @Select(EstimatesListState.get) public estimates!: Observable<Estimate[] | undefined>;

    constructor(private spinner: Spinner, private userProfileClient: UserClient) {
        super();
    }

    public ngOnInit(): void {
        this.estimates.onceDefined((estimates) => {
            this.moduleEstimates = estimates.filter((x) => x.EstimateType === EstimateType.InternationalEstimate);
        });
        const profileRequest = this.userProfileClient.read();
        this.spinner.while(profileRequest);
        profileRequest.onceDefined((profile) => {
            for (const i of profile.Subscriptions) {
                if (i.Type === 'IneTrial' || i.Type === 'IneBasic' || i.Type === 'InePro' || i.Type === 'IneEnterprise') {
                    const isAuthorized = new Date(i.End) > new Date();
                    this.hideestimates = isAuthorized;
                }
            }
        });
    }
}
