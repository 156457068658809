// Modules
import { ErrorHandler, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbDateParserFactory, AuthenticationManager } from '@concurrency/angular';
import { ConcurrencyModule } from '@concurrency/angular';
import { AADSettings, B2CSettings } from '@concurrency/authentication';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsModule } from '@ngxs/store';
import { HIGHCHARTS_MODULES } from 'angular-highcharts';
import * as more from 'highcharts/highcharts-more.src';
import * as exporting from 'highcharts/modules/exporting.src';
import * as offlineExporting from 'highcharts/modules/offline-exporting.src';
import { SignalRModule } from '@henkkelder/ng2-signalr';
import { LoggerModule } from 'ngx-logger';
import { EstimateSummaryState } from 'src/app/_navigator/estimate-summary/estimate-summary-state';
import { environment } from 'src/environments/environment';
import { ErrorHandlerService } from 'src/error-handler.service';
import { ApiClients } from './_config/api-clients';
import { aadSettings, b2cSettings } from './_config/authentication.configuration';
import { CommonModules } from './_config/common-modules';
import { routeConfig } from './_config/route.configuration';
import { signalRConfig } from './_config/signalr.configuration';
import { CcrState } from './_navigator/ccr-store/ccr-state';
import { CountryListState } from './_navigator/country-list-store/country-list-state';
import { CountryRiskPremiaListState } from './_navigator/country-risk-premia-list-store/country-risk-premia-list-state';
import { CurrencyListState } from './_navigator/currency-list-store/currency-list-state';
import { DynamicTextSummaryState } from './_navigator/dynamic-text-store/dynamic-text-summary-state';
import { EstimateState } from './_navigator/estimate-store/estimate-state';
import { BetaEstimatesListState } from './_navigator/estimates-list-store/beta-estimates-list-state';
import { EstimatesListState } from './_navigator/estimates-list-store/estimates-list-state';
import { InflationListState } from './_navigator/inflation-list-store/inflation-list-state';
import { IntlBetaListState } from './_navigator/intl-beta-list-store/intl-beta-list-state';
import { IntlErpState } from './_navigator/intl-erp-store/intl-erp-state';
import { IntlIndustryListState } from './_navigator/intl-industry-list-store/intl-industry-list.state';
import { IntlRfState } from './_navigator/intl-rf-store/intl-rf-state';
import { SuggestionListState } from './_navigator/suggestion-list-store/suggestion-list-state';
import { TaxRateListState } from './_navigator/tax-rate-list-store/tax-rate-list-state';
import { ValuesState } from './_navigator/values-store/values-state';
import { AppComponent } from './app.component';
import { BenchmarkingModule } from './benchmarking/benchmarking.module';
import { BetaModule } from './beta/beta.module';
import { EstimateModule } from './estimate/estimate.module';
import { HomeModule } from './home/home.module';
import { InternationalEstimateModule } from './international-estimate/international-estimate.module';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { CocInputsModule } from './costofcapital-inputs/coc-inputs.module';
export const AppDefinition: NgModule = {
    declarations: [
        AppComponent,
    ],
    imports: [
        RouterModule.forRoot(routeConfig),
        SignalRModule.forRoot(signalRConfig),
        LoggerModule.forRoot({ level: environment.loggerLevel }),
        ConcurrencyModule.forRoot(environment),
        NgxsModule.forRoot([
            BetaEstimatesListState,
            EstimatesListState,
            EstimateState,
            CurrencyListState,
            CountryListState,
            IntlErpState,
            IntlRfState,
            SuggestionListState,
            IntlIndustryListState,
            IntlBetaListState,
            InflationListState,
            CcrState,
            CountryRiskPremiaListState,
            EstimateSummaryState,
            DynamicTextSummaryState,
            TaxRateListState,
            ValuesState
        ]),
        NgxsReduxDevtoolsPluginModule.forRoot({
            disabled: environment.production
        }),
        AuthModule.forRoot(environment.auth0),
        HomeModule,
        EstimateModule,
        InternationalEstimateModule,
        BenchmarkingModule,
        BetaModule,
        ...CommonModules,
        DialogModule,
        ConfirmDialogModule,
        CocInputsModule,
    ],
    providers: [
        ...ApiClients,
        // TODO: Properly define this somewhere else
        {
            provide: NgbDateParserFormatter,
            useFactory: NgbDateParserFactory
        }, {
            provide: AADSettings,
            useValue: aadSettings
        }, {
            provide: B2CSettings,
            useValue: b2cSettings
        }, {
            provide: AuthenticationManager,
            deps: [AADSettings, B2CSettings]
        },
        { provide: HIGHCHARTS_MODULES, useFactory: () => [more, exporting, offlineExporting] },
        { provide: ErrorHandler, useClass: ErrorHandlerService },
        { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true }
    ],
    bootstrap: [AppComponent]
};

@NgModule(AppDefinition)
export class AppModule { }
