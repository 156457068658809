import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'beta-summary-statistics',
    templateUrl: './beta-summary-statistics.component.html',
    styleUrls: ['./beta-summary-statistics.component.scss']
})
export class BetaSummaryStatisticsComponent {
    constructor() {

    }


}
