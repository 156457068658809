import { OnInit, Directive } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { EquationType } from 'src/app/_api/responses/equation.response';
import { Equation } from 'src/app/_navigator/data/model/equation.model';
import { Estimate } from 'src/app/_navigator/data/model/estimate.model';
import { Scenario } from 'src/app/_navigator/data/model/scenario.model';
import { BaseStudyComponent } from './base-study.component';

@Directive()
export abstract class ScenarioStudyComponent extends BaseStudyComponent implements OnInit {
    protected abstract equationType: EquationType;
    public scenarios: Scenario[] = [];
    public equations: Equation[] = [];
    public equationString?: string;
    public estimate?: Estimate;
    public equationTypeName = '';

    public ngOnInit(): void {
        this.equations = this.buildEquations();
        this.equationString = EquationType[this.equationType];
        this.getEquationTypeName(this.equationType);
        // TODO: Obviate takeUntil by using Async pipes and local Observable streams
        this.dataStore.estimate.pipe(takeUntil(this.destroyed)).whileDefined((estimate) => {
            this.estimate = estimate;
            this.scenarios = estimate.Scenarios;
        });
    }

    public abstract buildEquations(): Equation[];
    public getEquationTypeName(type: EquationType): void {
        switch (type) {
            case EquationType.RprsCapmSizeStudy:
                this.equationTypeName = 'CAPM + Size Premium';
                break;
            case EquationType.RprsBuildup2:
                this.equationTypeName = 'Build-up 2';
                break;
            case EquationType.RprsBuildup3:
                this.equationTypeName = ' Build-up 3';
                break;
            default:
                break;
        }
    }
}
