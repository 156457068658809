import { SummaryOptions } from '../types/summary-options';
import { headerCellHeight, headerFontSize, krollblue, krollshadow, normalCellHeight, normalFontSize, smallFontSize } from '../util/summary-helpers';

// TODO figure out this any type
export function summarySelectionLog(options: SummaryOptions): any {
    return options.generalInputsHeader.concat([
        {
            cells: [{ value: '' }]
        },
        {
            height: headerCellHeight,
            cells: [
                {
                    value: 'Size and Risk Inputs',
                    bold: 'true',
                    color: krollblue,
                    fontSize: headerFontSize,
                    borderBottom: { size: 1, color: krollshadow },
                    colSpan: 2
                }
            ]
        },
        {
            height: normalCellHeight,
            cells: [
                {
                    value: 'Size Inputs ($USD in millions except for Number of Employees)',
                    bold: 'true',
                    fontSize: normalFontSize,
                    borderBottom: { size: 1 },
                    colSpan: 2,
                    color: krollblue
                }
            ]
        },
        {
            cells: [ { value: ''} ]
        }
    ]).concat(options.estimateSummaries.estSummarySizeMeasureInputs).
        concat(
            {
                cells: [{ value: '' }]
            },
            {
                height: normalCellHeight,
                cells: [
                    {
                        value: 'Risk Measures',
                        bold: 'true',
                        borderBottom: { size: 1 },
                        colSpan: 2,
                        fontSize: normalFontSize
                    }
                ]
            }
        ).concat(options.estimateSummaries.estSummaryRiskMeasureInputs)
        .concat(
            {
                cells: [{ value: '' }]
            },
            {
                height: normalCellHeight,
                cells: [
                    {
                        value: 'High-Financial-Risk Study',
                        bold: 'true',
                        borderBottom: { size: 1 },
                        colSpan: 2,
                        fontSize: normalFontSize
                    }
                ]
            }
        )
        .concat(options.scenarioHelper.buildExcelRows(options.summary.HFRSelectionLogValues))
        .concat(
            {
                height: headerCellHeight,
                cells: [
                    {
                        value: 'Cost of Equity Inputs',
                        bold: 'true',
                        color: krollblue,
                        fontSize: headerFontSize,
                        borderBottom: { size: 1, color: krollshadow },
                        colSpan: 2
                    }
                ]
            }
        ).concat(options.scenarioHelper.createSelectionLog(options.summary.costOfEquityInputs))
        .concat( {cells: [ { value: '' } ]} )
        .concat(
            {
                height: headerCellHeight,
                cells: [
                    {
                        value: 'Cost of Debt and WACC Inputs',
                        bold: 'true',
                        color: krollblue,
                        fontSize: headerFontSize,
                        borderBottom: { size: 1, color: krollshadow },
                        colSpan: 2
                    }
                ]
            }
        ).concat(options.scenarioHelper.createSelectionLog(options.summary.costOfDebtWaccInputs));
}
