
import { OnInit, Directive } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { EquationType } from 'src/app/_api/responses/equation.response';
import { Equation } from 'src/app/_navigator/data/model/equation.model';
import { Estimate } from 'src/app/_navigator/data/model/estimate.model';
import { Scenario } from 'src/app/_navigator/data/model/scenario.model';
import { BaseStudyComponent } from './base-study.component';

// TODO: There are serious code re-use issues between this and the scenario-study component
@Directive()
export abstract class LeveredScenarioStudyComponent extends BaseStudyComponent implements OnInit {
    protected abstract leveredEquationType: EquationType;
    protected abstract unleveredEquationType: EquationType;
    protected abstract releveredEquationType: EquationType;
    public estimate?: Estimate;
    public scenarios: Scenario[] = [];
    public equations: Equation[] = [];
    public leverState?: string;
    public leveredEquations: Equation[] = [];
    public unleveredEquations: Equation[] = [];
    public releveredEquations: Equation[] = [];
    public canDuplicate: { [key: string]: boolean } = {};

    // TODO: Make an enum for leverState
    private setLeverState(state: string, equations: Equation[]): void {
        this.leverState = state;
        this.equations = equations;
    }

    public abstract buildEquations(equationType: EquationType): Equation[];

    public ngOnInit(): void {
        // TODO: Obviate takeUntil by using Async pipes and local Observable streams
        this.dataStore.estimate.pipe(takeUntil(this.destroyed)).whileDefined((estimate) => {
            this.estimate = estimate;
            this.scenarios = estimate.Scenarios;
            this.scenarios.forEach((scenario) => {
                const hasCompleteEquation = this.equations.some((equation) => equation.calculate(scenario));
                this.canDuplicate[scenario.Id] = hasCompleteEquation && this.scenarios.length < 10;
            });
        });
        this.leveredEquations = this.buildEquations(this.leveredEquationType);
        this.unleveredEquations = this.buildEquations(this.unleveredEquationType);
        this.releveredEquations = this.buildEquations(this.releveredEquationType);
        this.lever();

    }

    public lever(): void {
        this.hasRegressionToggle = true;
        this.setLeverState('levered', this.leveredEquations);
    }

    public unlever(): void {
        this.hasRegressionToggle = true;
        this.setLeverState('unlevered', this.unleveredEquations);
    }

    public relever(): void {
        this.hasRegressionToggle = true;
        this.setLeverState('relevered', this.releveredEquations);
    }
}
