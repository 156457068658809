import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SubscriberEntity } from '@concurrency/angular';
import { ExcelExportComponent } from '@progress/kendo-angular-excel-export';
import { takeUntil } from 'rxjs/operators';
import { Tab } from '../_navigator/data/enum/tab.enum';
import { Step } from '../_navigator/data/model/step.model';
import { DataStore } from '../_navigator/data/store/data.store';
import { Steps } from '../_navigator/data/util/steps-utilities';
import { InternationalEstimateService } from './data/service/international-estimate.service';
import { InternationalEstimateExcelExportService } from './export/international-estimate-excel-export.service';

@Component({
    selector: 'international-estimate',
    templateUrl: './international-estimate.component.html'
})
export class InternationalEstimateComponent extends SubscriberEntity implements OnInit {
    public steps: Step[] = Steps.getInternationalEstimateSteps();
    public activeTab!: Tab | null;
    public canExport = false;
    public message: string | undefined;
    public exportFileName = '';

    constructor(
        public router: Router,
        private dataStore: DataStore,
        public internationalEstimateService: InternationalEstimateService,
        private internationalEstimateExcelExportService: InternationalEstimateExcelExportService
    ) {
        super();
    }

    public ngOnInit(): void {
        this.activeTab = Steps.setupTab(this.router.url, this.steps);
        this.dataStore.allowExport.pipe(takeUntil(this.destroyed)).while((allowExport) => this.canExport = allowExport);
        this.router.events.pipe(takeUntil(this.destroyed)).while(() => {
            this.activeTab = Steps.setupTab(this.router.url, this.steps);
        });
    }

    public showExcelExport(): boolean {
        return this.canExport && (
            this.activeTab === Tab.CountryRiskPremiaSummary ||
            this.activeTab === Tab.CostOfEquitySummary ||
            this.activeTab === Tab.CostOfDebtSummary ||
            this.activeTab === Tab.WaccSummary);
    }

    public goToRoute(nextStep: Step): void {
        if (this.activeTab == null) {
            return;
        }

        this.internationalEstimateService.saveEstimate(nextStep);
    }

    public exportToExcel(component: ExcelExportComponent): void {
        this.exportFileName = 'intl-cofc-summary.xlsx';
        this.internationalEstimateExcelExportService.save(component);
    }
}
