import { Action, Selector, State, StateContext } from '@ngxs/store';
import { of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { SuggestionResponse } from 'src/app/_api/responses/suggestion.response';
import { SetSuggestions } from './suggestion-list-actions';
import { Injectable } from '@angular/core';


export type SuggestionListModel = SuggestionResponse[] | undefined;

@State<SuggestionListModel>({
    name: 'suggestions',
    defaults: undefined
})

@Injectable()
export class SuggestionListState {

    @Selector()
    public static get(state: SuggestionListModel): SuggestionResponse[] | undefined {
        return state;
    }

    @Action(SetSuggestions)
    public set(context: StateContext<SuggestionListModel>, action: SetSuggestions): Observable<void> {
        context.setState(action.suggestions);
        return of(void 0);
    }
}
