export class ComparableCompanyHeaders {
    public CompanyHeaders = {
        CompanyName: 'Company Name',
        Observations: '# of Observations',
        Ticker: 'Ticker',
        IndustryCodeDescription: 'Industry (GICS) Code – Description',
        KeyWords: 'Key Words',
        Sales: 'Sales',
        EBITDA: 'EBITDA',
        Assets: 'Assets',
        MarketCap: 'Market Cap',
        BusinessSegments: 'Business Segments',
        GeographySegments: 'Geography Segments',
        IncorporationCountry: 'Incorporation Country',
        HeadquarterCountry: 'Headquarter Country',
        Exchange: 'Exchange'
    } as any;
    public getHeaderName(headerKey: string): any {
        return this.CompanyHeaders[headerKey];
    }
}

export enum ComparableCompanyFieldTypes {
    TypeHeader,
    ComparativeOparator
}
