import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Spinner, SubscriberEntity } from '@concurrency/angular';
import { ContextualNumber } from '@concurrency/core';
import { Select, Store } from '@ngxs/store';
import { combineLatest } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { map, takeUntil } from 'rxjs/operators';
import { SelectionType } from '../_api/enums/selection-type';
import { CountryRiskPremia } from '../_api/responses/country-risk-premia.response';
import { Country } from '../_api/responses/country.response';
import { EstimateSummary } from '../_api/responses/estimate-summary.response';
import { InputType } from '../_api/responses/input.response';
import { IntlData } from '../_api/responses/intl-data.response';
import { CcrModel, CcrState } from '../_navigator/ccr-store/ccr-state';
import { CommonInputConfig } from '../_navigator/common/inputs/common-user-input.component';
import { CountryRiskPremiaListState } from '../_navigator/country-risk-premia-list-store/country-risk-premia-list-state';
import { Estimate } from '../_navigator/data/model/estimate.model';
import { Suggestion } from '../_navigator/data/model/suggestion.model';
import { UpdateDynamicTextSummary } from '../_navigator/dynamic-text-store/dynamic-text-summary-action';
import { DynamicTextSummaryState } from '../_navigator/dynamic-text-store/dynamic-text-summary-state';
import { ErrorService } from '../_navigator/error/error.service';
import { UpdateDbEstimate, UpdateLocalEstimate } from '../_navigator/estimate-store/estimate-actions';
import { EstimateState } from '../_navigator/estimate-store/estimate-state';
import { UpdateEstimateSummary } from '../_navigator/estimate-summary/estimate-summary-action';
import { EstimateSummaryState } from '../_navigator/estimate-summary/estimate-summary-state';
import { HelpText } from '../_navigator/help/help-text';
import { GetIntlBetaList, ResetBetaList } from '../_navigator/intl-beta-list-store/intl-beta-list-actions';
import { IntlBetaListState } from '../_navigator/intl-beta-list-store/intl-beta-list-state';
import { GetIntlErp } from '../_navigator/intl-erp-store/intl-erp-actions';
import { IntlErpState } from '../_navigator/intl-erp-store/intl-erp-state';
import { GetIntlRf } from '../_navigator/intl-rf-store/intl-rf-actions';
import { IntlRfState } from '../_navigator/intl-rf-store/intl-rf-state';
import { ProductType } from '../_navigator/user/enum/product-type';
import { SubscriptionLevel } from '../_navigator/user/enum/subscription-level';
import { User } from '../_navigator/user/model/user.model';
import { UserStore } from '../_navigator/user/store/user.store';
import { InputText } from './data/model/input-text';
import { IntlEstimateUtil } from './data/util/intl-estimate.util';
import { DynamicText } from './dynamic-text/model/dynamic-text';
import { DynamicTextSummary } from './dynamic-text/model/dynamic-text-summary';
import { DynamicTextBuilderUtil } from './dynamic-text/util/dynamic-text-builder.util';
import { RouteUtil } from './route/route.util';

@Component({
    selector: 'coe-inputs',
    templateUrl: './coe-inputs.component.html'
})
export class CostOfEquityInputsComponent extends SubscriberEntity implements OnInit {

    private currentRoute!: string;
    public dynamicText: DynamicText[] = [];
    public estimate!: Estimate;
    public SelectionType = SelectionType;
    public saveBtnText = 'Save & Continue';
    public isKeAvailable = false;

    public alpha: ContextualNumber = new ContextualNumber();
    public beta: ContextualNumber = new ContextualNumber();
    public erp: ContextualNumber = new ContextualNumber();
    public rf: ContextualNumber = new ContextualNumber();
    public sizePremium: ContextualNumber = new ContextualNumber();
    public erpSuggestions: Suggestion<number | null>[] = [];
    public betaSuggestions: Suggestion<number | null>[] = [];
    public rfSuggestions: Suggestion<number | null>[] = [];
    public ccrResult!: CcrModel;

    @Select(EstimateState.get) public estimateSelector!: Observable<Estimate | undefined>;
    @Select(IntlErpState.get) public intlErpSelector!: Observable<IntlData[] | undefined>;
    @Select(IntlRfState.get) public intlRfSelector!: Observable<IntlData[] | undefined>;
    @Select(IntlBetaListState.get) public intlBetaListSelector!: Observable<IntlData[] | undefined>;
    @Select(DynamicTextSummaryState.get) public dynamicTextSummarySelector!: Observable<DynamicTextSummary | undefined>;
    @Select(EstimateSummaryState.get) public estimateSummarySelector!: Observable<EstimateSummary | undefined>;
    @Select(CountryRiskPremiaListState.get) public countryRiskPremiaSelector!: Observable<CountryRiskPremia[] | undefined>;
    @Select(CcrState.get) public ccrSelector!: Observable<CcrModel | undefined>;

    public alphaConfig: CommonInputConfig = {
        name: InputText.Alpha,
        help: HelpText.Alpha,
        error: this.errorService.alpha,
        minimumValue: 0,
        maximumValue: 100,
        mask: `{}%`,
        pattern: '([0-9][.]?[0-9]?[0-9]?|[1-9][0-9][.]?[0-9]?[0-9]?|100[.]?0?0?)'
    };

    public betaConfig: CommonInputConfig = {
        name: InputText.Beta,
        help: HelpText.IntlBeta,
        error: this.errorService.beta,
        minimumValue: 0,
        maximumValue: 5,
        pattern: '([0-9][.]?[0-9]?[0-9]?|10[.]?0?0?)'
    };

    public erpConfig: CommonInputConfig = {
        name: InputText.EquityRiskPremium,
        help: HelpText.IntlEquityRiskPremium,
        error: this.errorService.equityRiskPremium,
        mask: `{}%`,
        minimumValue: 0,
        maximumValue: 10,
        pattern: '([0-9][.]?[0-9]?[0-9]?|10[.]?0?0?)'
    };

    public rfConfig: CommonInputConfig = {
        name: InputText.RiskFreeRate,
        help: HelpText.IntlRiskFreeRate,
        error: this.errorService.riskFreeRate,
        mask: `{}%`,
        minimumValue: -5,
        maximumValue: 10,
        pattern: '(^-?[0-4][.]?[0-9]?[0-9]?|10[.]?0?0?|-5[.]?0?0?|[5-9][.]?[0-9]?[0-9]?)'
    };

    public sizePremiumConfig: CommonInputConfig = {
        name: InputText.SizePremium,
        help: HelpText.IntlSizePremium,
        error: this.errorService.sizeMeasure,
        mask: `{}%`,
        minimumValue: 0,
        maximumValue: 100,
        pattern: '([0-9][.]?[0-9]?[0-9]?|[1-9][0-9][.]?[0-9]?[0-9]?|100[.]?0?0?)'
    };

    constructor(
        private router: Router,
        private store: Store,
        private spinner: Spinner,
        private userStore: UserStore,
        private errorService: ErrorService
    ) {
        super();
    }

    private initialize(inputCountry: Country, estimate: Estimate): void {
        const gic = estimate.getInput(InputType.Gic);
        const requests = [
            this.store.dispatch(new GetIntlErp(inputCountry.CountryId, estimate.ValuationDate)),
            this.store.dispatch(new GetIntlRf(inputCountry.CountryId, estimate.ValuationDate))
        ];

        if (gic.Value) {
            const getIntlBetaList = new GetIntlBetaList(
                estimate.InvesteeCountryId,
                gic.Value,
                inputCountry.Currency.CurrencyId,
                estimate.ValuationDate
            );
            const betaRequest = this.store.dispatch(getIntlBetaList);
            requests.push(betaRequest);
        } else {
            const resetRequest = this.store.dispatch(new ResetBetaList());
            requests.push(resetRequest);
        }

        const storeRequest = combineLatest(requests);
        this.spinner.while(storeRequest);
        storeRequest.once(() => {
            const selectorRequest = combineLatest([
                this.intlErpSelector,
                this.intlRfSelector,
                this.userStore.user,
                this.intlBetaListSelector
            ]).pipe(
                takeUntil(this.destroyed),
                map((x) => ({
                    erp: x[0],
                    rf: x[1],
                    user: x[2],
                    betas: x[3]
                }))
            );
            this.spinner.while(selectorRequest);
            selectorRequest.onceDefined((data) => {
                this.initializeInputs(estimate, data.erp, data.rf, data.user, data.betas);
            });
        });
    }

    private initializeInputs(
        estimate: Estimate,
        erp: IntlData[] | undefined,
        rf: IntlData[] | undefined,
        user: User | undefined,
        betas: IntlData[] | undefined
    ): void {
        if (user == null) {
            return;
        }

        if (erp) {
            this.erpSuggestions = erp.map((x) => IntlEstimateUtil.intlDataAsSuggestion(x.Value, x.Label, x.DataAsOf, x.Source));
        }

        if (rf) {
            this.rfSuggestions = rf.map((x) => IntlEstimateUtil.intlDataAsSuggestion(x.Value, x.Label, x.DataAsOf, x.Source));
        }

        const intSubData = user.subscriptionDetails.find(
            (x) => x.productType === ProductType.Ine && (x.level === SubscriptionLevel.Pro || x.level === SubscriptionLevel.Enterprise)
        );

        if (intSubData && betas) {
            this.betaSuggestions = betas.map(
                (x) => IntlEstimateUtil.intlDataAsSuggestion(x.Value, x.Label, x.DataAsOf, x.Source, false)
            );
        }

        const scenario = estimate.Scenarios[0];
        this.beta = scenario.getSelectionAsContextualNumber(SelectionType.IntlBeta, InputType.None);
        this.erp = scenario.getSelectionAsContextualNumber(SelectionType.IntlEquityRiskPremium, InputType.None);
        this.rf = scenario.getSelectionAsContextualNumber(SelectionType.IntlRiskFreeRate, InputType.None);

        const rpsSelection = scenario.getSelectionAsContextualNumber(SelectionType.IntlSizePremium, InputType.None);
        if (rpsSelection.asNumber) {
            this.sizePremium = rpsSelection;
        }

        const alphaSelection = scenario.getSelectionAsContextualNumber(SelectionType.IntlAlpha, InputType.None);
        if (alphaSelection.asNumber) {
            this.alpha = alphaSelection;
        }
    }

    public ngOnInit(): void {
        this.ccrSelector.onceDefined(ccrResult => {
            this.ccrResult = ccrResult;
        });
        this.currentRoute = this.router.url;
        combineLatest([
            this.dynamicTextSummarySelector,
            this.estimateSummarySelector,
            this.countryRiskPremiaSelector,
            this.estimateSelector
        ]).pipe(
            takeUntil(this.destroyed),
            map((x) => ({
                dynamicText: x[0],
                summary: x[1],
                crp: x[2],
                estimate: x[3]
            }))
        ).onceDefined((data) => {
            if (data.dynamicText == null
                || data.summary == null
                || data.crp == null
                || data.estimate == null
                || data.summary.InvestorCountryId == null
                || data.summary.InvesteeCountryId == null
                || data.summary.CashFlowCountry == null
                || data.summary.CountryOfInputs == null
            ) {
                return;
            }

            this.estimate = data.estimate;
            this.isKeAvailable = true;
            const dynamicTextSummary = DynamicTextBuilderUtil.getDynamicTextSummary(data.summary, data.dynamicText, data.crp);
            this.store.dispatch(new UpdateDynamicTextSummary(dynamicTextSummary));
            this.dynamicTextSummarySelector.onceDefined((dt) => {
                if (dt.CostOfEquityInputsText == null) {
                    return;
                }
                this.dynamicText = dt.CostOfEquityInputsText;

            });
            this.initialize(data.summary.CountryOfInputs, data.estimate);
        });
    }

    public saveAndContinue(): void {
        const request = this.store.dispatch(new UpdateDbEstimate());
        this.spinner.while(request);
        request.once(() => {
            RouteUtil.saveAndContinue(this.router.url, this.router);
        });
    }

    public update(model: ContextualNumber, selection: SelectionType): void {
        if(selection == SelectionType.IntlRiskFreeRate){
            this.updateCcrSource();
        }
        this.estimate.Scenarios[0].setSelection(model, selection, InputType.None);
        this.store.dispatch(new UpdateEstimateSummary(this.estimate));
        this.store.dispatch(new UpdateLocalEstimate(this.estimate));
    }

    public updateCcrSource(){
        const ccrsource = new ContextualNumber(0, 'CCR Source Information', this.ccrResult?.DataAsOf,  0, false,  this.ccrResult?.Intl_Source);
        this.estimate.Scenarios[0].setSelection(ccrsource, SelectionType.SourceInfoCcr, InputType.None);
    }
    public previous(): void {
        RouteUtil.previous(this.currentRoute, this.router);
    }
}
