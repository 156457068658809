import { HttpResponse } from '@angular/common/http';
import { ApiClient } from '@concurrency/angular';
import { Observable } from 'rxjs';
import { BetaEstimateRequest } from 'src/app/beta/beta.types';
import { environment } from 'src/environments/environment';
import { EstimateType } from '../enums/estimate-type';
import { BetaEstimateResponse } from '../responses/beta-estimate.response';
import { Injectable } from '@angular/core';
import { Auth0ApiClient } from '../api.client';


@Injectable()
export class BetaEstimateClient extends Auth0ApiClient {
    protected baseUrl = `${environment.urls.navigatorApi}/api`;

    public search(): Observable<BetaEstimateResponse[]> {
        return this.get(`BetaEstimate`);
    }

    public read(id: string): Observable<BetaEstimateResponse> {
        return this.get(`BetaEstimate/${id}`);
    }

    public create(name: string, type: EstimateType): Observable<string> {
        return this.post(`BetaEstimate/create?name=${encodeURIComponent(name)}&type=${type}`, {});
    }

    public update(estimate: BetaEstimateRequest): Observable<void> {
        return this.post(`BetaEstimate/update`, estimate);
    }

    public rename(id: string, name: string): Observable<void> {
        return this.post(`BetaEstimate/rename?id=${id}&name=${encodeURIComponent(name)}`, {});
    }

    public clone(id: string): Observable<HttpResponse<void>> {
        return this.post(`BetaEstimate/clone/${id}`, null);
    }

    public deactivate(id: string): Observable<void> {
        return this.post(`BetaEstimate/deactivate/${id}`, {});
    }
}
