import { Component, OnInit } from '@angular/core';
import { DebtBetaTrendsOverTime } from '../../models/debtBetaTrendsOverTime';
import { Chart } from 'angular-highcharts';
import { CocInputsSharedService } from '../../service/cocInputShared.service';
import { Spinner } from '@concurrency/angular';
import * as moment from 'moment';

@Component({
    selector: 'debt-betas-trends-over-time',
    templateUrl: './debt-betas-trends-over-time.component.html',
    styleUrls: ['./debt-betas-trends-over-time.component.scss']
})
export class DebtBetasTrendsOverTimeComponent implements OnInit {

    constructor(private sharedCocInput: CocInputsSharedService, private spinner: Spinner) { }

    debtTrendsData!: DebtBetaTrendsOverTime[]
    AraydataAsof!: string[]
    public display: boolean = false;
    public debtBetaTrendsOver!: any;
    public isChartexpanded: boolean = false;
    public dataNotAvailble: boolean = false;

    ngOnInit(): void {
        this.getCharthData();
    }




    private resizeChart(): void {
        this.isChartexpanded = !this.isChartexpanded;
        if (this.isChartexpanded) {
            this.showChart(1100, 500);
        } else {
            this.showChart(900, 400);
        }
    }


    getCharthData() {
        this.spinner.begin();
        this.sharedCocInput.getDebtBetaTrendsOverTime(this.sharedCocInput.cocInputValues.valuationDate).subscribe((data: DebtBetaTrendsOverTime[]) => {
            this.debtTrendsData = data;
            this.AraydataAsof = this.debtTrendsData.map(data => moment(data.DataAsOf).format('MM/DD/YYYY'));
            this.showChart(900, 400);

        })

    }


    setCharthData(): any[] {
        const series = [];
        series.push(
            {
                type: 'line',
                name: `Aaa`,
                color: 'rgb(208, 0, 112)',
                data: [...this.debtTrendsData.map(s => s.Aaa)],
                marker: {
                    enabled: true
                }
            },

            {
                type: 'line',
                name: `Aa`,
                color: 'rgb(67, 120, 160)',
                data: [...this.debtTrendsData.map(s => s.Aa)],
                marker: {
                    enabled: true
                }
            },
            {
                type: 'line',
                name: 'A',
                color: 'rgb(20, 72, 127)',
                data: [...this.debtTrendsData.map(s => s.A)],
                marker: {
                    enabled: true
                }
            },
            {
                type: 'line',
                name: `Baa`,
                color: 'rgb(67, 120, 160)',
                data: [...this.debtTrendsData.map(s => s.Baa)],
                marker: {
                    enabled: true
                }
            },

            {
                type: 'line',
                name: `Ba`,
                color: 'rgb(67, 176, 73)',
                data: [...this.debtTrendsData.map(s => s.Ba)],
                marker: {
                    enabled: true
                }
            },

            {
                type: 'line',
                name: `B`,
                color: 'rgb(76, 159, 200)',
                data: [...this.debtTrendsData.map(s => s.B)],
                marker: {
                    enabled: true
                }
            },



            {
                type: 'line',
                name: `Caa`,
                color: 'rgb(40, 80, 130)',
                data: [...this.debtTrendsData.map(s => s.Caa)],
                marker: {
                    enabled: true
                }
            },
            {
                type: 'line',
                name: `Ca-D`,
                color: 'rgb(187, 221, 230)',
                data: [...this.debtTrendsData.map(s => s.CaD)],
                marker: {
                    enabled: true
                }
            }
        );

        return series;

    }




    showChart(width: number, height: number): any {
        this.debtBetaTrendsOver = new Chart({
            chart: {
                height: height,
                width: width,
                type: 'line',
                style: {
                    fontFamily: "nunito-sans"
                }
            },
            rangeSelector: {
                selected: 1
            },
            legend: {
                itemMarginBottom: 10
            },
            title: {
                text: ''
            },
            series: this.setCharthData(),
            xAxis: {
                title: {
                    text: ''
                },
                startOnTick: true,
                categories: [...this.AraydataAsof],
                gridLineWidth: 0,



                labels: {
                    align: 'left',

                }
            },
            yAxis: {
                title: {
                    text: 'Values'
                },
                tickInterval: 0.001,
                gridLineWidth: 0,
                gridLineColor: 'transparent',
                labels: {
                    format: '{value:.2f}'
                }
            },
            tooltip: {
                pointFormat: '<br> {series.name} : {point.y:.2f} </br>',
                shared: true,
            },
            credits: {
                enabled: false
            }
        });
        this.spinner.end();
        this.display = true;

    }

}
