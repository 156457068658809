import { Component, OnInit } from '@angular/core';
import { SubscriberEntity } from '@concurrency/angular';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs/internal/Observable';
import { EstimateSummary } from '../../_api/responses/estimate-summary.response';
import { EstimateSummaryState } from '../../_navigator/estimate-summary/estimate-summary-state';
import { EstimateSummaryText } from './model/estimate-summary-text';

@Component({
    selector: 'estimate-summary',
    templateUrl: './estimate-summary.component.html'
})
export class EstimateSummaryComponent extends SubscriberEntity implements OnInit {
    @Select(EstimateSummaryState.get) public estimateSummarySelector!: Observable<EstimateSummary | undefined>;

    public estimateSummary: EstimateSummary | undefined;
    public EstimateSummaryText = EstimateSummaryText;

    constructor() {
        super();
    }
    public ngOnInit(): void {
        this.estimateSummarySelector.subscribe((summary) => {
            this.estimateSummary = summary;
        });
    }
}
