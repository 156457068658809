import { Component, OnInit } from '@angular/core';
import { BetaService } from '../beta.service';
import { BetaColors, BetaToT, ComaparableCompany, CompanyReturnsRequest } from '../beta.types';
import { DataStore } from 'src/app/_navigator/data/store/data.store';
import { Spinner } from '@concurrency/angular';
import * as moment from 'moment';
import { Chart } from 'angular-highcharts';

@Component({
    selector: 'beta-trends-over-time',
    templateUrl: './beta-trends-over-time.component.html',
    styleUrls: ['./beta-trends-over-time.component.scss'],
})
export class BetaTrendsOverTimeComponent implements OnInit {

    public title: string = '';
    public inputText: string = '';
    public display = true;
    public frequency: string = '';
    // public lineChartOptionsArray: Highcharts.Options[] = [];
    constructor(
        private betaService: BetaService,
        private dataStore: DataStore,
        private spinner: Spinner
    ) { }

    ngOnInit() {
        this.betaService.totChart = {};
        this.betaService.betasToTTitle = this.title;
        this.inputText = `${this.betaService.metricInputs.LookbackPeriod} ${this.betaService.metricInputs.Frequency} 
                        beta trends over time measured against ${this.betaService.marketIndexName} Index denominated in ${this.betaService.metricInputs.Currency.substring(0, 3)}`;
        this.setLineChartOptions(this.betaService.betas);
    }


    private setLineChartOptions(betaToT: BetaToT[]): void {
        let dateArray: string[] = [];
        betaToT.forEach((beta) => {
            if (dateArray.length > 0 && !dateArray.includes(moment(beta.DataAsOf).format('MM/DD/YYYY'))) {
                dateArray = [...dateArray, moment(beta.DataAsOf).format('MM/DD/YYYY')];
            } else if (dateArray.length === 0) {
                dateArray = [...dateArray, moment(beta.DataAsOf).format('MM/DD/YYYY')];
            }
        });

        const interval = this.frequency === 'Monthly' ? 12 : 52;
        const pointDate = new Date(dateArray[0]);
        this.betaService.totChart = new Chart({
            chart: {
                type: 'line',
                // width: '1000',
                height: '450',
                style: {
                    fontFamily: 'nunito-sans',
                    flex: '0 0 90%',
                    maxWidth: '100%',
                    paddingLeft: '15px',
                    //paddingRight: '15px',
                    marginLeft: '0',
                    marginRight: '0',
                },
            },
            title: {
                text: `${this.title}`,
                style: {
                    color: 'rgb(77, 77, 79)',
                    fontFamily: 'nunito-sans',
                    fontSize: '1rem',
                    fontWeight: 'bold'
                }
            },
            subtitle: {
                text: `${this.inputText}`,
                style: {
                    color: 'rgb(77, 77, 79)', //rgb(20,72,127)
                    fontFamily: 'nunito-sans',
                    fontSize: '1rem',
                    // fontWeight: 'bold'
                }
            },
            tooltip: {
                backgroundColor: {
                    linearGradient: { x1: 0, x2: 0, y1: 0, y2: 60 },
                    stops: [
                        [0, '#FFFFFF'],
                        [1, '#E0E0E0']
                    ]
                },
                borderWidth: 1,
                split: true,
                valueDecimals: 2,
                snap: 0
            },
            credits: {
                enabled: false
            },
            series: [],
            xAxis: {
                //type: 'datetime'
                categories: [...dateArray],
                tickInterval: interval,
                //tickPositions: [0, dateArray.length - 1]
            },
            yAxis: {
                title: {
                    text: 'Values'
                },
            },
            navigation: {
                buttonOptions: {
                    enabled: false
                }
            },
            plotOptions: {
                series: {
                    marker: {
                        enabled: false,
                    },
                    enableMouseTracking: false,
                    states: {
                        hover: {
                            enabled: false
                        }
                    },
                    // pointStart: Date.UTC(pointDate.getFullYear(), pointDate.getMonth(), pointDate.getDate()),
                    // pointInterval: 1000 * 3600 * 24 * 365
                },

            },

        });

        if (betaToT.length > 0) {
            let index = 0;
            this.betaService.finalSelectedCompanies.forEach((company: ComaparableCompany) => {
                let olsArray: any[] = [];
                let sumArray: any[] = [];
                // company.TrendsOverTimeDisplay = true;
                const companyName = company.CompanyName;
                const betasToT = betaToT.filter(x => x.Gvkey === company.GVKEY);
                if (betasToT.length > 0) {
                    betasToT.forEach((x) => {
                        olsArray = [...olsArray, x.OlsBeta];
                        sumArray = [...sumArray, x.SumBeta];
                    });
                }
                if (this.title === 'OLS Betas') {
                    if (company.TrendsOverTimeDisplay) {
                        this.betaService.totChart.options.series.push({
                            type: 'line',
                            name: companyName,
                            color: BetaColors.Colors[index],
                            //connectNulls: true,
                            data: [...olsArray]
                        })
                    }

                } else {
                    if (company.TrendsOverTimeDisplay) {
                        this.betaService.totChart.options.series.push({
                            type: 'line',
                            name: companyName,
                            color: BetaColors.Colors[index],
                            //connectNulls: true,
                            data: [...sumArray]
                        })
                    }
                }
                index++;
            })
        }
        console.log(this.betaService.totChart);
    }

    public updateCompanyFilterResult(gvKey: any): void {
        this.betaService.finalSelectedCompanies.forEach((company: ComaparableCompany) => {
            if (company.GVKEY === gvKey && company.TrendsOverTimeDisplay) {
                company.TrendsOverTimeDisplay = false;
                const index = this.betaService.totChart.options.series.findIndex((x: any) => x.name === company.CompanyName);
                this.betaService.totChart.removeSeries(index);
                this.betaService.totChart.options.series.splice(index, 1);
            } else if (company.GVKEY === gvKey && !company.TrendsOverTimeDisplay) {
                company.TrendsOverTimeDisplay = true;
                this.setLineChartOptions(this.betaService.betas);
            }
        })
    }


}
