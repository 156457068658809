import { Component, OnInit } from '@angular/core';
import { IndustryAssumptions } from 'src/app/_api/responses/IndustryAssumption';
import { UserStore } from 'src/app/_navigator/user/store/user.store';
import { TearSheetService } from 'src/app/home/tear-sheet/data/tear-sheet.service';
import { industryAssumptionJSON } from 'src/app/home/tear-sheet/enums/viewMore';
import { TearSheetContent } from 'src/app/home/tear-sheet/models/tear-sheet-content.model';

@Component({
    selector: 'industry-assumptions',
    templateUrl: './industry-assumptions.component.html',
    styleUrls: ['./industry-assumptions.component.scss']
})
export class IndustryAssumptionsComponent {

    constructor(private userStore: UserStore, private tearSheetService: TearSheetService) { }

    public usTittle = "U.S.(in USD)"
    public assumptionIndex = 0;
    public isVisable = true;
    public worldTittle = "World (in EUR)"
    public onClose!: Function
    public industryAssumptions!: IndustryAssumptions
    public assumptionProp = ['CostOfEquity', 'CostOfDebt', 'DebtBeta']
    public DataAsOf!: string
    public industryData!: TearSheetContent

    ngOnInit(): void {
        this.industryData = this.tearSheetService.getTearSheetContent();
        this.industryAssumptions = this.industryData.IndustryAssumptions;
        this.DataAsOf = this.industryData.DataThroughDate;
    }


    public getEachAssumptionData(prop: string) {
        const keyProp = prop as keyof IndustryAssumptions
        return this.industryAssumptions[keyProp];
    }

    public getAssumptionName(objKey: string) {
        const assumptions = industryAssumptionJSON;
        return assumptions[objKey]
    }




    close(): void {
        this.onClose();
    }



}
