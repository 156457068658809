import { EstimateType } from 'src/app/_api/enums/estimate-type';

export class GetEstimates {
    public static type = '[Estimate API] Get All';
}

export class CreateEstimate {
    public static type = '[Estimate API] Create';
    constructor(public name: string, public type: EstimateType) { }
}

export class ArchiveEstimate {
    public static type = '[Estimate API] Archive';
    constructor(public id: string) { }
}

export class CloneEstimate {
    public static type = '[Estimates API] Clone';
    constructor(public id: string) { }
}

export class RenameEstimate {
    public static type = '[Estimates API] Rename';
    constructor(public id: string, public name: string) { }
}
