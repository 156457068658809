import * as moment from 'moment';

import { Injectable } from '@angular/core';
import {
    ExcelExportComponent,
    Workbook,
    WorkbookSheetRow,
    WorkbookSheetRowCell
} from '@progress/kendo-angular-excel-export';
import { ExcelExportUtil } from 'src/app/estimate/exports/util/excel-export.util';

@Injectable()
export class Trendsovertimefishereffectexcelexportservice {

    private moduleName = 'International Cost of Capital Module'; // TODO rename this based on subscription or url

    public columnWidth = 125;       // width of each cell in grid system
    public blueColor = '#14487f'; // color of all orange items
    public krollblue = '#14487f'; // color of all blue items
    public numberFormat = '0.00';   // number format for cells
    constructor(
    ) { }

    private saveWorksheet(component: ExcelExportComponent, tabledateArray: any[] = [], inflationarraytable: any[] = [], cashflowarraytable: any[] = [], inputcountryname: string, cashflowcountryname: string): void {

        const excelExport = this.getWorksheet(tabledateArray, inflationarraytable, cashflowarraytable, inputcountryname, cashflowcountryname);
        excelExport.sheets.forEach((sheet) => {
            if (sheet.rows) {
                sheet.rows.forEach((row) => {
                    if (row.cells) {
                        row.cells.forEach((cell) => {
                            cell.fontFamily = 'Arial';
                            if (!cell.color) {
                                cell.color = '#4d4d4f';
                            }
                        });
                    }
                });
            }
        });
        component.save(excelExport);
    }

    private getWorksheet(tabledateArray: any[] = [], inflationarraytable: any[] = [], cashflowarraytable: any[] = [], inputcountryname: string, cashflowcountryname: string): Workbook {
        //  const valuationDate = tableData.industries[0].ValuationDate;

        const workbook = new Workbook();
        workbook.sheets = [{
            name: `Trends Over Time Fisher effect`,
            columns: Array(8).fill({ width: this.columnWidth }),
            rows: [
                ...this.getHeader(),
                ...this.buildTableData(tabledateArray, inflationarraytable, cashflowarraytable, inputcountryname, cashflowcountryname),
                ...ExcelExportUtil.getFooter(this.moduleName)
            ]
        }];

        return workbook;
    }

    private getHeader(): WorkbookSheetRow[] {
        const row: WorkbookSheetRow[] = [{
            cells: [{
                value: 'Trends Over Time-International Fisher Effect Inputs',
                bold: true,
                colSpan: 6,
                borderBottom: { size: 2 }
            }]
        }];

        return row;
    }

    private getCompLabels(inputcountryname: string, cashflowcountryname: string): WorkbookSheetRow[] {

        // TODO create workbook sheet row builder
        const row: WorkbookSheetRow[] = [
            {
                cells: [{
                    value: '',
                    colSpan: 1
                }]
            },
            {
                cells: [{
                    value: '',
                    colSpan: 1
                }]
            },
            {
                cells: [{
                    value: inputcountryname,
                    colSpan: 1
                }]
            },
            {
                cells: [{
                    value: cashflowcountryname,
                    colSpan: 1
                }]
            }];
        return row;
    }

    private buildMetricTableRow(inflationarraytable: string | number | boolean | Date | null | undefined): WorkbookSheetRow[] {
        const tableRow: WorkbookSheetRow[] = [];
        let row: WorkbookSheetRow = {};
        row.cells = [];
        let cl: WorkbookSheetRowCell = {};

        // TODO construct builder to build this out and return the entire result row
        cl = ExcelExportUtil.createCell(inflationarraytable, 1, this.numberFormat);
        row.cells.push(cl);

        tableRow.push(row);

        row = {};
        row.cells = [];
        return tableRow;
    }

    private buildScenarioHeader(table: string): WorkbookSheetRow[] {

        const row: WorkbookSheetRow[] = [{
            cells: [{
                value: table,
                colSpan: 4,
                borderBottom: { size: 1, color: this.krollblue },
                bold: true
            }
            ]
        }];

        return row;
    }

    private buildDataAsOfTitle(title: string, width: number): WorkbookSheetRow[] {
        const dateValue = new Date(title);

        const row: WorkbookSheetRow[] = [{
            cells: [{
                value: moment(dateValue).format('MM/DD/YYYY'),
                colSpan: width,
                borderBottom: { size: 3 },
                bold: true
            }]
        }];

        return row;
    }

    private buildTableData(tabledateArray: any[] = [], inflationarraytable: any[] = [], cashflowarraytable: any[] = [], inputcountryname: string, cashflowcountryname: string): WorkbookSheetRow[] {
        let resultData: WorkbookSheetRow[] = [];
        let scenarioHeader: WorkbookSheetRow[] = [];
        let rightSideTableData: WorkbookSheetRow[] = [];
        let combineCompLables: WorkbookSheetRow[] = [];
        let tableTitle;
        let tableRow;
        const titleWidth = 1;

        scenarioHeader = this.buildScenarioHeader('Expected Long-term Inflation (30-year average)');
        for (let j = 0; j < tabledateArray.length; j++) {
            tableTitle = this.buildDataAsOfTitle(tabledateArray[j], titleWidth);
            tableRow = this.buildMetricTableRow(cashflowarraytable[j] ? cashflowarraytable[j].y : '');
            const ccreur = this.buildMetricTableRow(inflationarraytable[j] ? inflationarraytable[j].y : '');
            tableTitle = tableTitle.concat(tableRow);
            tableTitle = tableTitle.concat(ccreur);
            if (rightSideTableData.length > 0) {
                rightSideTableData = this.concatenateRows(rightSideTableData, tableTitle);
            } else {
                rightSideTableData = tableTitle;
            }
        }
        rightSideTableData = scenarioHeader.concat(rightSideTableData);
        combineCompLables = this.concatenateRows(this.getCompLabels(inputcountryname, cashflowcountryname), rightSideTableData);

        resultData = resultData.concat(combineCompLables);
        rightSideTableData = [];
        return resultData;

    }

    private concatenateRows(rowDataLeft: WorkbookSheetRow[], rowDataRight: WorkbookSheetRow[]): WorkbookSheetRow[] {
        const leftWidth: number = ExcelExportUtil.getWidthOfWidestRow(rowDataLeft);

        const rowData: WorkbookSheetRow[] = [];
        for (let i = 0; i < rowDataLeft.length; ++i) {
            if (i < rowDataRight.length) { // row in right side, add it to the left
                const leftCells = rowDataLeft[i].cells;
                const rightCells = rowDataRight[i].cells;

                if (leftCells && rightCells) {
                    let newCells: WorkbookSheetRowCell[] = [];

                    newCells = leftCells.concat(rightCells);
                    rowDataLeft[i].cells = newCells;
                }

                rowData.push(rowDataLeft[i]);
            }
        }

        //
        //  process any rows on right beyond the left
        //
        for (let j: number = rowDataLeft.length; j < rowDataRight.length; ++j) {
            const rightCells = rowDataRight[j].cells;

            if (rightCells) {
                let newCells: WorkbookSheetRowCell[] = ExcelExportUtil.getEmptyCell(leftWidth);
                newCells = newCells.concat(rightCells);
                rowDataRight[j].cells = newCells;
                rowData.push(rowDataRight[j]);
            }

        }

        return rowData;
    }

    public save(component: ExcelExportComponent, tabledateArray: any[] = [], inflationarraytable: any[] = [], cashflowarraytable: any[] = [], inputcountryname: string, cashflowcountryname: string): void {
        this.saveWorksheet(component, tabledateArray, inflationarraytable, cashflowarraytable, inputcountryname, cashflowcountryname);
    }
}
