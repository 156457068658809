import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SubscriberEntity } from '@concurrency/angular';
import { Select, Store } from '@ngxs/store';
import { combineLatest } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { map, takeUntil } from 'rxjs/operators';
import { SelectionType } from 'src/app/_api/enums/selection-type';
import { CountryCreditRating } from 'src/app/_api/responses/country-credit-rating.response';
import { Country } from 'src/app/_api/responses/country.response';
import { CurrencyData } from 'src/app/_api/responses/currency-data.response';
import { InputType } from 'src/app/_api/responses/input.response';
import { CcrState } from 'src/app/_navigator/ccr-store/ccr-state';
import { CountryListState } from 'src/app/_navigator/country-list-store/country-list-state';
import { CurrencyListState } from 'src/app/_navigator/currency-list-store/currency-list-state';
import { Estimate } from 'src/app/_navigator/data/model/estimate.model';
import { DynamicTextSummaryState } from 'src/app/_navigator/dynamic-text-store/dynamic-text-summary-state';
import { EstimateState } from 'src/app/_navigator/estimate-store/estimate-state';
import { DynamicTextSummary } from 'src/app/international-estimate/dynamic-text/model/dynamic-text-summary';
import { SummaryText } from '../data/model/summary-text';
import { DynamicText } from '../dynamic-text/model/dynamic-text';
import { EquationGroup } from '../equation/equation-group.model';
import { EquationGroupUtil } from '../equation/equation-group.util';

@Component({
    selector: 'coe-summary-fisher-effect',
    templateUrl: './coe-summary-fisher-effect.component.html'
})
export class CoeSummaryFisherEffectComponent extends SubscriberEntity implements OnInit {
    public showFisherEffect = false;
    public equations: EquationGroup[] = [];
    public dynamicText: DynamicText[] = [];
    public isKeAvailable = false;
    public isInflationAvailable = false;
    public SummaryText = SummaryText;
    public translateCurrenciesLabel = '';

    @Select(EstimateState.get) public estimateSelector!: Observable<Estimate | undefined>;
    @Select(CurrencyListState.getAll) public currencyListSelector!: Observable<CurrencyData[] | undefined>;
    @Select(DynamicTextSummaryState.get) public dynamicTextSummarySelector!: Observable<DynamicTextSummary | undefined>;
    @Select(CcrState.get) public ccrSelector!: Observable<CountryCreditRating | undefined>;

    constructor(private store: Store, private router: Router) {
        super();
    }

    private initialize(
        estimate: Estimate,
        cashFlowCountry: Country | undefined,
        countryOfInput: Country | undefined,
        ccr: CountryCreditRating | undefined
    ): void {
        if (cashFlowCountry == null || countryOfInput == null || ccr == null) {
            return;
        }

        this.isKeAvailable = true;
        this.translateCurrenciesLabel = SummaryText.translateCurrencies(
            countryOfInput.Currency.CurrencyAcronym,
            cashFlowCountry.Currency.CurrencyAcronym
        );
        this.showFisherEffect = cashFlowCountry.Currency.CurrencyId !== countryOfInput.Currency.CurrencyId;

        if (this.showFisherEffect === false) {
            return;
        }

        const scenario = estimate.Scenarios[0];
        const inflationInvestor = scenario.getSelection(SelectionType.InflationInvestor, InputType.None);
        const inflationInvestee = scenario.getSelection(SelectionType.InflationInvestee, InputType.None);

        if (inflationInvestor.Value && inflationInvestee.Value) {
            this.equations = EquationGroupUtil.getCostOfEquityFisherEffectGroup(estimate, countryOfInput, cashFlowCountry, ccr);
            this.isInflationAvailable = true;
        }
    }

    public ngOnInit(): void {
        this.estimateSelector.onceDefined((estimate) => {
            const scenario = estimate.Scenarios[0];
            const countryOfInputs = estimate.getInput(InputType.CountryOfInputs);
            const cashFlowCountry = estimate.getInput(InputType.CashFlows);
            const rf = scenario.getSelection(SelectionType.IntlRiskFreeRate, InputType.None);
            const erp = scenario.getSelection(SelectionType.IntlEquityRiskPremium, InputType.None);
            const beta = scenario.getSelection(SelectionType.IntlBeta, InputType.None);

            if (countryOfInputs.Value == null
                || cashFlowCountry.Value == null
                || rf.Value == null
                || erp.Value == null
                || beta.Value == null
            ) {
                return;
            }

            combineLatest([
                this.store.select(CountryListState.getById(cashFlowCountry.Value)),
                this.store.select(CountryListState.getById(countryOfInputs.Value)),
                this.ccrSelector
            ]).pipe(
                takeUntil(this.destroyed),
                map((x) => ({
                    cashFlowCountry: x[0],
                    countryOfInput: x[1],
                    ccr: x[2]
                }))
            ).onceDefined((data) => {
                this.initialize(estimate, data.cashFlowCountry, data.countryOfInput, data.ccr);
            });
        });

        this.dynamicTextSummarySelector.onceDefined((dt) => {
            if (dt.CostOfEquitySummaryFisherEffectText == null) {
                return;
            }
            this.dynamicText = dt.CostOfEquitySummaryFisherEffectText;
        });
    }

    public edit(): void {
        this.router.navigate(['international-estimate/fisher-effect']);
    }
}
