import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ExcelExportComponent } from '@progress/kendo-angular-excel-export';
import { PDFExportComponent } from '@progress/kendo-angular-pdf-export';
import * as Highcharts from 'highcharts';
import * as moment from 'moment';
import { Dialog } from 'primeng/dialog';
import { Inflation } from 'src/app/_api/responses/inflation.response';
import { TrendsOverTimePDFExportService } from 'src/app/_navigator/data/service/trends-over-time-pdf-export.service';
import { Trendsovertimefishereffectexcelexportservice } from 'src/app/home/exports/excel/trends-over-time-fishereffect-excel-export.service';

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'trends-over-time-fishereffect',
    templateUrl: './trends-over-time-fishereffect.component.html',
    styleUrls: ['./trends-over-time-fishereffect.component.scss']
})
export class TrendsOverTimeFishereffectComponent implements OnInit, AfterViewInit {
    @ViewChild('trendsovertimeriskpdf') public trendsovertimeriskpdf!: PDFExportComponent;
    @ViewChild('trendsovertimemodal') public trendsovertimemodal!: ElementRef;
    @ViewChild(Dialog) public dialog!: Dialog;
    @Input() public inputcountry!: Inflation[];
    @Input() public inputcountryname!: string;
    @Input() public cashflowcountryname!: string;
    @Input() public valuationdate!: string;
    public modifiedDate = new Date().toLocaleDateString();
    public displayGraph = true;
    public display = true;
    public excelFileName = '';
    public displayContent = false;
    public tabledateArray: any[] = [];
    public pdficonhide = true;
    public inflationarraytable: any[] = [];
    public cashflowarraytable: any[] = [];
    public dialogHeader = 'Trends Over Time';
    public lineChartOptionsArrayyeildspread: Highcharts.Options[] = [];
    public inflationline = true;
    public cashflowline = true;
    constructor(private trendsOverTimeExcelExportService: Trendsovertimefishereffectexcelexportservice, private trendsOverTimePDFExportService: TrendsOverTimePDFExportService) { }

    private setTableData(): void {
        this.tabledateArray = this.tabledateArray;
        this.cashflowarraytable = this.cashflowarraytable;
        this.inflationarraytable = this.inflationarraytable;
    }
    private setGraphData(): void {
        this.lineChartOptionsArrayyeildspread = [];
        this.setLineChartOptions(this.inputcountry);
    }
    private setLineChartOptions(inputcountry: Inflation[]): void {
        // Any type is used since highchart's data property in a series accepts many data types
        let inflationarray: any[] = [];
        let cashflowarray: any[] = [];
        let dateArray: any[] = [];
        inputcountry.forEach((request) => {
            if (request.DataAsOf !== undefined && request.Label.includes(this.inputcountryname)) {
                const date = new Date(request.DataAsOf);
                dateArray = [...dateArray, moment(date).format('MM/DD/YYYY')];
            }
            if (request.Label.includes(this.inputcountryname) && this.inflationline === true) {
                inflationarray = [...inflationarray,
                {
                    y: request.InflationRate
                }
                ];
            }
            if (request.Label.includes(this.cashflowcountryname) && this.cashflowline === true) {
                cashflowarray = [...cashflowarray,
                {
                    y: request.InflationRate
                }
                ];
            }

        });
        this.tabledateArray = dateArray;
        this.cashflowarraytable = cashflowarray;
        this.inflationarraytable = inflationarray;
        const lineChartOptions: Highcharts.Options = {
            chart: {
                type: 'line',
                style: {
                    fontFamily: 'nunito-sans'
                },
                className: 'trends-over-time'
            },
            exporting: {
                enabled: false
            },
            title: {
                text: 'Expected Long-term Inflation (30-year average)',
                style: {
                    color: 'rgb(69, 85, 96)',
                    fontFamily: 'nunito-sans',
                    fontSize: '1rem'
                }
            },
            tooltip: {
                backgroundColor: {
                    linearGradient: { x1: 0, x2: 0, y1: 0, y2: 60 },
                    stops: [
                        [0, '#FFFFFF'],
                        [1, '#E0E0E0']
                    ]
                },
                borderWidth: 1,
                split: true,
                valueDecimals: 2
            },
            credits: {
                enabled: false
            },
            series: [
                {
                    type: 'line',
                    name: this.inputcountryname,
                    color: 'rgb(103,210,223)',
                    connectNulls: true,
                    data: [...inflationarray]
                },
                {
                    type: 'line',
                    name: this.cashflowcountryname,
                    color: 'rgb(208,0,112)',
                    connectNulls: true,
                    data: [...cashflowarray]
                }

            ],
            xAxis: {
                categories: [...dateArray]
            }
        };
        this.lineChartOptionsArrayyeildspread = [...this.lineChartOptionsArrayyeildspread, lineChartOptions];
    }

    private getHighCharts(): any {
        const svgArr: string[] = [];
        let top = 0;
        let width = 0;
        // Reference to Highcharts forcing it to an any to utlize export utilities.
        const HC = Highcharts as any;

        HC.charts.forEach((chart: any) => {
            if (chart &&
                chart.options &&
                chart.options.chart) {

                if (chart.options.chart.className === 'trends-over-time') {

                    let svg: string = chart.getSVG();
                    const svgMatchWidth = svg.match(/^<svg[^>]*width\s*=\s*\"?(\d+)\"?[^>]*>/);
                    const svgMatchHeight = svg.match(/^<svg[^>]*height\s*=\s*\"?(\d+)\"?[^>]*>/);

                    if (svgMatchWidth && svgMatchHeight) {
                        const svgWidth = +svgMatchWidth[1];
                        const svgHeight = +svgMatchHeight[1];

                        svg = svg.replace(
                            '<svg',
                            '<g transform="translate(0,' + top + ')" '
                        );
                        svg = svg.replace('</svg>', '</g>');

                        top += svgHeight;
                        width = Math.max(width, svgWidth);

                        svgArr.push(svg);
                    }
                }
            }
        });

        return (`<svg height="${top}" width="${width}" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    ${svgArr.join('')}
                </svg>`
        );
    }

    private exportCharts(): void {
        const HC = Highcharts as any;
        let options = {
            type: 'application/pdf'
        } as Highcharts.ExportingOptions;

        options = Highcharts.merge(Highcharts.getOptions().exporting, options);

        // TODO continue researching a way to utilize offline exporting and see if multiple chart exports is capable of this.

        HC.post(options.url, {
            filename: `Trends Over Time `,
            type: options.type,
            width: options.width,
            svg: this.getHighCharts()
        });
    }
    public ngAfterViewInit(): void {
        this.trendsOverTimePDFExportService.setPdf(this.trendsovertimeriskpdf);
        this.updateFilterResult();
        setTimeout(() => { this.resizeofGraph(); }, 1000);
    }
    public ngOnInit(): void {
        // this.subscription = this.userdata.Subscriptions[0].Type;
        if (this.inputcountry.length) {
            this.updateFilterResult();
            this.displayContent = true;
        }
    }
    public updateFilterResult(): void {
        this.setTableData();
        this.resizeofGraph();
    }

    public toggleDisplay(): void {
        this.displayGraph = !this.displayGraph;
    }

    public compositestoggle(event: { target: HTMLInputElement }): void {

        if (event.target.id === '0') {
            this.inflationline = event.target.checked;
        }
        if (event.target.id === '1') {
            this.cashflowline = event.target.checked;
        }

        this.updateFilterResult();
    }

    public exportToExcel(component: ExcelExportComponent): void {
        this.excelFileName = `Trends Over Time.xlsx`;
        this.trendsOverTimeExcelExportService.save(component, this.tabledateArray, this.cashflowarraytable, this.inflationarraytable, this.inputcountryname, this.cashflowcountryname);
    }

    public exportToPdf(): void {
        if (this.displayGraph) {
            this.exportCharts();
        } else {
            this.pdficonhide = false;
            this.trendsOverTimePDFExportService.savePdf();
            setTimeout(() => { this.pdficonhide = true; }, 2000);
        }
    }

    public resizeofGraph(): void {
        setTimeout(() => { this.setGraphData(); }, 1000);
    }
}
