import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { ConcurrencyAuthenticator } from '@concurrency/authentication';
import { AppInsights } from 'applicationinsights-js';
import { aadSettings, b2cSettings } from './app/_config/authentication.configuration';
import { AppInjector } from './app/app-injector';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

// const windowIdentity = ConcurrencyAuthenticator.initializeWindow(b2cSettings, aadSettings);
// if (windowIdentity === 'MainWindow') {
//     bootstrapApp();
// } else {
//     // TODO: Display progress indicator and/or Timeout after some duration
// }
bootstrapApp();

function bootstrapApp(): void {
    enableProdMode();

    if (environment.applicationInsights && window.location.origin !== 'https://localhost:3000') {
        if (AppInsights.downloadAndSetup) {
            AppInsights.downloadAndSetup({ instrumentationKey: environment.applicationInsights.key });
        } else {
            AppInsights.config.instrumentationKey = environment.applicationInsights.key;
        }
    }

    platformBrowserDynamic()
        .bootstrapModule(AppModule)
        .then((moduleRef) => {
            AppInjector.setInjector(moduleRef.injector);
        })
        .catch((err) => {
            console.error(err);
        });
}
