import { SelectionType } from 'src/app/_api/enums/selection-type';
import { CountryCreditRating } from 'src/app/_api/responses/country-credit-rating.response';
import { Country } from 'src/app/_api/responses/country.response';
import { InputType } from 'src/app/_api/responses/input.response';
import { Estimate } from 'src/app/_navigator/data/model/estimate.model';
import { EquationGroupName } from './equation-group-name';
import { EquationGroup } from './equation-group.model';
import { EquationsUtil } from './equations.util';

export class EquationGroupUtil {
    public static getCostOfEquityCapmGroup(estimate: Estimate, ccr: CountryCreditRating, countryOfInputs: Country): EquationGroup[] {
        if (estimate.InvesteeCountryId === countryOfInputs.CountryId) {
            const equation = EquationsUtil.getSingleCountryCapmEquation(estimate, countryOfInputs.Currency.CurrencyAcronym);

            return [{ name: EquationGroupName.SingleCountryCapm, equation, isApplicable: true }];
        }

        const crp = EquationsUtil.getCcrEquation(estimate, countryOfInputs.Currency.CurrencyAcronym);
        const ys = EquationsUtil.getYieldSpreadEquation(estimate, countryOfInputs.Currency.CurrencyAcronym);
        const rv = EquationsUtil.getVolatilityEquation(estimate, countryOfInputs.Currency.CurrencyAcronym);
        const isCcrApplicable = ccr.CountryRiskPremia != null ? true : false;
        const isYsApplicable = ccr.YieldSpread != null ? true : false;
        const isRvApplicable = ccr.RelativeVolatility != null ? true : false;

        return [
            { name: EquationGroupName.CapmCountryCreditRating, equation: crp, isApplicable: isCcrApplicable },
            { name: EquationGroupName.CapmYieldSpread, equation: ys, isApplicable: isYsApplicable },
            { name: EquationGroupName.CapmRelativeVolatility, equation: rv, isApplicable: isRvApplicable }
        ];
    }

    public static getCostOfEquityFisherEffectGroup(
        estimate: Estimate,
        countryOfInput: Country,
        cashFlowCountry: Country,
        ccr: CountryCreditRating
    ): EquationGroup[] {
        if (estimate.InvesteeCountryId === countryOfInput.CountryId) {
            const equation = EquationsUtil.getCostOfEquityFisherEffectEquation(
                estimate,
                countryOfInput.CountryName,
                cashFlowCountry.CountryName,
                SelectionType.KeSingleCountryCapm,
                SelectionType.KeSingleCountryFisherEffect,
                countryOfInput.Currency.CurrencyAcronym,
                cashFlowCountry.Currency.CurrencyAcronym
            );

            return [{ name: EquationGroupName.SingleCountryFisherEffect, equation, isApplicable: true }];
        }

        const crp = EquationsUtil.getCostOfEquityFisherEffectEquation(
            estimate,
            countryOfInput.CountryName,
            cashFlowCountry.CountryName,
            SelectionType.CostOfEquityCapmCcr,
            SelectionType.CostOfEquityFisherEffectCcr,
            countryOfInput.Currency.CurrencyAcronym,
            cashFlowCountry.Currency.CurrencyAcronym
        );
        const ys = EquationsUtil.getCostOfEquityFisherEffectEquation(
            estimate,
            countryOfInput.CountryName,
            cashFlowCountry.CountryName,
            SelectionType.CostOfEquityCapmYieldSpread,
            SelectionType.CostOfEquityFisherEffectYieldSpread,
            countryOfInput.Currency.CurrencyAcronym,
            cashFlowCountry.Currency.CurrencyAcronym
        );
        const rv = EquationsUtil.getCostOfEquityFisherEffectEquation(
            estimate,
            countryOfInput.CountryName,
            cashFlowCountry.CountryName,
            SelectionType.CostOfEquityCapmVolatility,
            SelectionType.CostOfEquityFisherEffectVolatility,
            countryOfInput.Currency.CurrencyAcronym,
            cashFlowCountry.Currency.CurrencyAcronym
        );
        const isCcrApplicable = ccr.CountryRiskPremia != null ? true : false;
        const isYsApplicable = ccr.YieldSpread != null ? true : false;
        const isRvApplicable = ccr.RelativeVolatility != null ? true : false;

        return [
            { name: EquationGroupName.CapmCountryCreditRating, equation: crp, isApplicable: isCcrApplicable },
            { name: EquationGroupName.CapmYieldSpread, equation: ys, isApplicable: isYsApplicable },
            { name: EquationGroupName.CapmRelativeVolatility, equation: rv, isApplicable: isRvApplicable }
        ];
    }

    public static getCostOfEquityConclusionGroup(estimate: Estimate, cashFlowCountry: Country, countryOfInput: Country): EquationGroup[] {
        const useFisherEffect = cashFlowCountry.Currency.CurrencyId !== countryOfInput.Currency.CurrencyId;
        const keSelection = useFisherEffect ? SelectionType.KeSingleCountryFisherEffect : SelectionType.KeSingleCountryCapm;
        const ke = EquationsUtil.getCostOfEquityConclusionEquation(estimate, keSelection, cashFlowCountry.Currency.CurrencyAcronym);

        return [{ name: EquationGroupName.SingleCountryCapm, equation: ke, isApplicable: true }];
    }

    public static getCostOfDebtCapitalGroup(
        estimate: Estimate,
        countryOfInput: Country,
        investeeCountryName: string,
        ccr: CountryCreditRating
    ): EquationGroup[] {
        if (estimate.InvesteeCountryId === countryOfInput.CountryId) {
            const equation = EquationsUtil.getSingleCountryCostOfDebtEquation(
                estimate,
                countryOfInput.Currency.CurrencyAcronym,
                investeeCountryName
            );

            return [{ name: EquationGroupName.SingleCountryCostOfDebt, equation, isApplicable: true }];
        }

        const crp = EquationsUtil.getCostOfDebtEquation(
            estimate,
            SelectionType.CountryRiskPremium,
            SelectionType.CostOfDebtCcr,
            countryOfInput.Currency.CurrencyAcronym,
            'CCR',
            investeeCountryName
        );
        const ys = EquationsUtil.getCostOfDebtEquation(
            estimate,
            SelectionType.YieldSpread,
            SelectionType.CostOfDebtYieldSpread,
            countryOfInput.Currency.CurrencyAcronym,
            'YS',
            investeeCountryName
        );
        const rv = EquationsUtil.getCostOfDebtVolatilityEquation(estimate, countryOfInput.Currency.CurrencyAcronym, investeeCountryName);
        const isCcrApplicable = ccr.CountryRiskPremia != null ? true : false;
        const isYsApplicable = ccr.YieldSpread != null ? true : false;
        const isRvApplicable = ccr.RelativeVolatility != null ? true : false;
        const e1: EquationGroup = { name: EquationGroupName.KdCountryCreditRating, equation: crp, isApplicable: isCcrApplicable };
        const e2: EquationGroup = { name: EquationGroupName.KdYieldSpread, equation: ys, isApplicable: isYsApplicable };
        const e3: EquationGroup = { name: EquationGroupName.KdRelativeVolatility, equation: rv, isApplicable: isRvApplicable };
        const useYieldSpreadOnly = estimate.Scenarios[0].getSelection(SelectionType.UseYieldSpreadOnly, InputType.None).Value;

        return useYieldSpreadOnly ? [e2] : [e1, e2, e3];
    }

    public static getCostOfDebtFisherEffectGroup(
        estimate: Estimate,
        countryOfInput: Country,
        cashFlowCountry: Country,
        ccr: CountryCreditRating
    ): EquationGroup[] {
        if (estimate.InvesteeCountryId === countryOfInput.CountryId) {
            const equation = EquationsUtil.getCostOfDebtFisherEffectEquation(
                estimate,
                countryOfInput.CountryName,
                cashFlowCountry.CountryName,
                SelectionType.KdSingleCountryCapm,
                SelectionType.KdSingleCountryFisherEffect,
                countryOfInput.Currency.CurrencyAcronym,
                cashFlowCountry.Currency.CurrencyAcronym
            );

            return [{ name: EquationGroupName.SingleCountryFisherEffect, equation, isApplicable: true }];
        }

        const crp = EquationsUtil.getCostOfDebtFisherEffectEquation(
            estimate,
            countryOfInput.CountryName,
            cashFlowCountry.CountryName,
            SelectionType.CostOfDebtCcr,
            SelectionType.CostOfDebtFisherEffectCcr,
            countryOfInput.Currency.CurrencyAcronym,
            cashFlowCountry.Currency.CurrencyAcronym
        );
        const ys = EquationsUtil.getCostOfDebtFisherEffectEquation(
            estimate,
            countryOfInput.CountryName,
            cashFlowCountry.CountryName,
            SelectionType.CostOfDebtYieldSpread,
            SelectionType.CostOfDebtFisherEffectYieldSpread,
            countryOfInput.Currency.CurrencyAcronym,
            cashFlowCountry.Currency.CurrencyAcronym
        );
        const rv = EquationsUtil.getCostOfDebtFisherEffectEquation(
            estimate,
            countryOfInput.CountryName,
            cashFlowCountry.CountryName,
            SelectionType.CostOfDebtVolatility,
            SelectionType.CostOfDebtFisherEffectVolatility,
            countryOfInput.Currency.CurrencyAcronym,
            cashFlowCountry.Currency.CurrencyAcronym
        );
        const isCcrApplicable = ccr.CountryRiskPremia != null ? true : false;
        const isYsApplicable = ccr.YieldSpread != null ? true : false;
        const isRvApplicable = ccr.RelativeVolatility != null ? true : false;
        const e1: EquationGroup = { name: EquationGroupName.KdCountryCreditRating, equation: crp, isApplicable: isCcrApplicable };
        const e2: EquationGroup = { name: EquationGroupName.KdYieldSpread, equation: ys, isApplicable: isYsApplicable };
        const e3: EquationGroup = { name: EquationGroupName.KdRelativeVolatility, equation: rv, isApplicable: isRvApplicable };
        const useYieldSpreadOnly = estimate.Scenarios[0].getSelection(SelectionType.UseYieldSpreadOnly, InputType.None).Value;

        return useYieldSpreadOnly ? [e2] : [e1, e2, e3];
    }

    public static getCostOfDebtConclusionGroup(estimate: Estimate, cashFlowCountry: Country, countryOfInput: Country): EquationGroup[] {
        const useFisherEffect = cashFlowCountry.Currency.CurrencyId !== countryOfInput.Currency.CurrencyId;
        const kdSelection = useFisherEffect ? SelectionType.KdSingleCountryFisherEffect : SelectionType.KdSingleCountryCapm;
        const kd = EquationsUtil.getCostOfDebtConclusionEquation(estimate, kdSelection, cashFlowCountry.Currency.CurrencyAcronym);

        return [{ name: EquationGroupName.SingleCountryCostOfDebt, equation: kd, isApplicable: true }];
    }

    public static getWaccCapitalGroup(
        estimate: Estimate,
        ccr: CountryCreditRating,
        cashFlowCountry: Country,
        keInputCountry: Country,
        kdInputCountry: Country
    ): EquationGroup[] {
        const useKeFisherEffect = cashFlowCountry.Currency.CurrencyId !== keInputCountry.Currency.CurrencyId;

        if (estimate.InvesteeCountryId === keInputCountry.CountryId && estimate.InvesteeCountryId === kdInputCountry.CountryId) {
            const singleCountryWaccEquation = EquationsUtil.getSingleCountryWaccEquation(estimate, useKeFisherEffect);

            return [{ name: EquationGroupName.SingleCountryWacc, equation: singleCountryWaccEquation, isApplicable: true }];
        }

        const useYsModelOnly = estimate.Scenarios[0].getSelection(SelectionType.UseYieldSpreadOnly, InputType.None).Value ? true : false;
        const useKdFisherEffect = cashFlowCountry.Currency.CurrencyId !== kdInputCountry.Currency.CurrencyId;
        const useSingleCountryKd = estimate.InvesteeCountryId === kdInputCountry.CountryId;
        const crp = EquationsUtil.getWaccCcrEquation(
            estimate,
            useKeFisherEffect,
            useKdFisherEffect,
            useSingleCountryKd,
            useYsModelOnly
        );
        const ys = EquationsUtil.getWaccYieldSpreadEquation(
            estimate,
            useKeFisherEffect,
            useKdFisherEffect,
            useSingleCountryKd,
            useYsModelOnly
        );
        const rv = EquationsUtil.getWaccVolatilityEquation(
            estimate,
            useKeFisherEffect,
            useKdFisherEffect,
            useSingleCountryKd,
            useYsModelOnly
        );
        const isCcrApplicable = ccr.CountryRiskPremia != null ? true : false;
        const isYsApplicable = ccr.YieldSpread != null ? true : false;
        const isRvApplicable = ccr.RelativeVolatility != null ? true : false;

        return [
            { name: EquationGroupName.WaccCountryCreditRating, equation: crp, isApplicable: isCcrApplicable },
            { name: EquationGroupName.WaccYieldSpread, equation: ys, isApplicable: isYsApplicable },
            { name: EquationGroupName.WaccRelativeVolatility, equation: rv, isApplicable: isRvApplicable }
        ];
    }

    public static getWaccConclusionGroup(estimate: Estimate, countryOfInput: Country): EquationGroup[] {
        const waccCcr = EquationsUtil.getWaccConclusionEquation(estimate, SelectionType.WaccCcr);
        const waccYs = EquationsUtil.getWaccConclusionEquation(estimate, SelectionType.WaccYieldSpread);
        const waccRv = EquationsUtil.getWaccConclusionEquation(estimate, SelectionType.WaccVolatility);
        const waccSingleCountry = EquationsUtil.getWaccConclusionEquation(estimate, SelectionType.WaccSingleCountry);

        const isCcrApplicable = estimate.Scenarios[0].hasValue(SelectionType.CountryRiskPremium);
        const isYsApplicable = estimate.Scenarios[0].hasValue(SelectionType.YieldSpread);
        const isRvApplicable = estimate.Scenarios[0].hasValue(SelectionType.RelativeVolatility);
        const useKeSingleCountry = countryOfInput.CountryId === estimate.InvesteeCountryId;

        const e1: EquationGroup = { name: EquationGroupName.WaccCountryCreditRating, equation: waccCcr, isApplicable: isCcrApplicable };
        const e2: EquationGroup = { name: EquationGroupName.WaccYieldSpread, equation: waccYs, isApplicable: isYsApplicable };
        const e3: EquationGroup = { name: EquationGroupName.WaccRelativeVolatility, equation: waccRv, isApplicable: isRvApplicable };
        const e4: EquationGroup = { name: EquationGroupName.SingleCountryWacc, equation: waccSingleCountry, isApplicable: true };

        return useKeSingleCountry ? [e4] : [e1, e2, e3];
    }
}
