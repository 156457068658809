import { ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, Directive } from '@angular/core';
import { SubscriberEntity } from '@concurrency/angular';
import { Action, ContextualValue } from '@concurrency/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap/datepicker/datepicker.module';
import { InputError } from '../../error/error.service';
import { Help } from '../../help/help.model';

export interface DebounceConfig {
    callback: Action<any>;
    delay?: number;
}

export interface CommonInputConfig {
    name?: string;
    containerClassList?: string;
    autofocus?: boolean; // TODO: Re-implement to be false by default
    readonly?: boolean;
    disabled?: boolean;
    required?: boolean;
    placeholder?: string;
    tabindex?: string;
    debounce?: DebounceConfig;
    minimumValue?: number | NgbDateStruct;
    maximumValue?: number | NgbDateStruct;
    help?: Help;
    error?: InputError;
    mask?: string;
    label?: string;
    pattern?: string;
    inputType?: string; // TODO: Make this an enum
    stackedDisplay?: boolean;
    iconWithLabel?: boolean;
}

@Directive()
export abstract class CommonUserInputComponent extends SubscriberEntity implements OnInit, OnChanges {
    @Output() public modelChange = new EventEmitter();
    @Output() public submit1 = new EventEmitter();
    @Output() public isValid = new EventEmitter<boolean>();
    @Output() public focus1 = new EventEmitter<FocusEvent>();
    @Input() public config!: CommonInputConfig;
    @Input() public model!: ContextualValue<any>; // TOOD: Should not be any
    @ViewChild('input') public input!: ElementRef;

    public invalidValue?: string;

    public ngOnInit(): void {
        if (this.config && this.config.autofocus === true) {
            this.setFocus();
        }
    }

    public setFocus(): void {
        const attemptDuration = 5000;
        let keepTrying = true;
        setTimeout(() => {
            keepTrying = false;
        }, attemptDuration);

        setTimeout(() => {
            if (this.input) {
                this.input.nativeElement.focus();
            } else if (keepTrying === true) {
                this.setFocus();
            }
        });
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes != null && changes.model != null && changes.model.firstChange) {
            setTimeout(() => {
                if (this.input == null) {
                    return;
                }

                if (this.config != null && this.config.autofocus === false) {
                    return;
                }
            });
        }
    }

    public emitChange(isValid: boolean): void {
        if (isValid) {
            this.model.context = this.model.context || 'Custom';
            this.modelChange.emit(this.model);
            this.invalidValue = undefined;
        } else {
            this.invalidValue = this.model.value;
        }

        this.isValid.emit(isValid);
    }

    public emitFocus(event: FocusEvent): void {
        this.focus1.emit(event);
    }

    // TODO: Fix up validity checking and turn this back on
    public emitSubmit(): void {
        // this.submit.emit();
    }
}
