import { Component } from '@angular/core';
import { SelectionType } from 'src/app/_api/enums/selection-type';
import { EquationType, ImplicationType } from 'src/app/_api/responses/equation.response';
import { InputType } from 'src/app/_api/responses/input.response';
import { Equation, EquationParameter } from 'src/app/_navigator/data/model/equation.model';
import { Scenario } from 'src/app/_navigator/data/model/scenario.model';
import { OperandUtility } from '../operand.utility';
import { StudyComponent } from '../study.component';
import { CrspDecileComponent } from './data-table/crsp-decile.component';

@Component({ templateUrl: '../study.component.html' })
export class CrspBuildupComponent extends StudyComponent {
    protected equationType = EquationType.CrspBuildup;
    protected hasRegressionToggle = false;
    public dataTable = CrspDecileComponent;

    public buildEquation(): Equation {
        const costOfEquityCapital = OperandUtility.getCostOfEquityCapital(this.equationType);
        const irpOperand = OperandUtility.getIndustryRiskPremium(SelectionType.CrspIndustryRiskPremium);

        const ke: EquationParameter = { item: costOfEquityCapital, operator: '=', canEdit: false };
        const rf: EquationParameter = { item: OperandUtility.riskFreeRate, operator: '+', canEdit: true };
        const erp: EquationParameter = { item: OperandUtility.equityRiskPremium, operator: '+', canEdit: true };
        const irp: EquationParameter = { item: irpOperand, operator: '+', canEdit: true };
        const sp: EquationParameter = { item: OperandUtility.buildupSizePremium, operator: '', canEdit: true };

        return {
            name: '',
            equationType: this.equationType,
            implicationType: ImplicationType.None,
            parameters: [ke, rf, erp, irp, sp],
            calculate: this.calculate
        };
    }

    public calculate = (scenario: Scenario): boolean => {
        const equation = scenario.getEquation(this.equationType, ImplicationType.None);
        const riskFreeRate = scenario.getValueOrNaN(SelectionType.RiskFreeRate, InputType.None);
        const equityRiskPremium = scenario.getValueOrNaN(SelectionType.EquityRiskPremium, InputType.None);
        const sizePremium = scenario.getValueOrNaN(SelectionType.CrspBuildupSizePremium, InputType.None);
        const industryRiskPremium = scenario.getValueOrNaN(SelectionType.CrspIndustryRiskPremium, InputType.None);

        const result = riskFreeRate + equityRiskPremium + industryRiskPremium + sizePremium;
        equation.Result = result || undefined;

        return equation.Result != null;
    }
}
