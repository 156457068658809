import { ExcelExportUtil } from '../../util/excel-export.util';
import { summaryExcelAbout } from '../summary-export-sections/about';
import { summaryExcelGlossary } from '../summary-export-sections/glossary';
import { keCRSPRows } from '../summary-export-sections/ke_CRSP';
import { keHFRRows } from '../summary-export-sections/ke_HFR';
import { keRPRRows } from '../summary-export-sections/ke_RPR';
import { summarySelectionLog } from '../summary-export-sections/selection-log';
import { sizeRiskPremiaSummary } from '../summary-export-sections/size-risk-premia-summary';
import { summaryExcelWACC } from '../summary-export-sections/wacc-summary';
import { SummaryOptions } from '../types/summary-options';
import { KEColumnDetails } from './summary-helpers';

export function summaryOptions(header: any, options: SummaryOptions): any {
    const moduleName = 'U.S. Cost of Capital Module';

    return {
        sheets: [
            {
                name: 'Selection Log',

                columns: [
                    { width: 400 },
                    { width: 300 },
                    { width: 100 },
                    { width: 100 },
                    { width: 100 },
                    { width: 100 },
                    { width: 100 },
                    { width: 100 },
                    { width: 100 },
                    { width: 100 }
                ],
                rows: header.concat(summarySelectionLog(options))

            },
            {
                name: 'Size&Risk Premia Summary',
                columns: [
                    { width: 225 },
                    { width: 275 },
                    { width: 400 },
                    { width: 125 },
                    { width: 200 },
                    { width: 125 },
                    { width: 275 },
                    { width: 200 }
                ],
                rows: header.concat(sizeRiskPremiaSummary(options, true))
            },
            {
                name: 'Ke_CRSP',
                columns: KEColumnDetails,
                rows: header.concat(keCRSPRows(options, false))
            },
            {
                name: 'Ke_RPR',
                columns: KEColumnDetails,
                rows: header.concat(keRPRRows(options, false))
            },
            {
                name: 'Ke_HFR',
                columns: KEColumnDetails,
                rows: header.concat(keHFRRows(options, false))
            },
            {
                name: 'WACC_Summary',
                columns: KEColumnDetails,
                rows: header.concat(summaryExcelWACC(options))
            },
            {
                name: 'Glossary',
                columns: [
                    { width: 200 },
                    { width: 1000 }
                ],
                rows: header.concat(summaryExcelGlossary())
            },
            {
                name: 'About',
                columns: [
                    { width: 200 },
                    { width: 750 }
                ],
                rows: header.concat(summaryExcelAbout())
            }
        ]  // End of Sheets

    }; // End of Workbook const
}
