import { Component } from '@angular/core';
import { CommonUserInputComponent } from './common-user-input.component';

@Component({
    selector: 'common-text-input',
    templateUrl: './common-text-input.component.html'
})
export class CommonTextInputComponent extends CommonUserInputComponent {
    public debounce(event: any): void {
        if (this.config.debounce && this.config.debounce.callback && this.invalidValue == null) {
            this.config.debounce.callback(event);
        }
    }
}
