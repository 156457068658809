import * as moment from 'moment';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SubscriberEntity } from '@concurrency/angular';
import { takeUntil } from 'rxjs/operators';
import { InputType } from 'src/app/_api/responses/input.response';
import { Estimate } from 'src/app/_navigator/data/model/estimate.model';
import { DataStore } from 'src/app/_navigator/data/store/data.store';
import { HelpText } from 'src/app/_navigator/help/help-text';
import { HelpService } from 'src/app/_navigator/help/help.service';

enum Tab { Capm, Buildup }

@Component({ templateUrl: './hfr.component.html' })
export class HfrComponent extends SubscriberEntity implements OnInit, OnDestroy {
    public activeTab: Tab = Tab.Capm;
    public isAvailable = true;
    public Tab = Tab;

    constructor(
        private dataStore: DataStore,
        private router: Router,
        private helpService: HelpService
    ) { super(); }

    private setHfrAvailability(estimate: Estimate): boolean {
        const valuationDate = moment(estimate.ValuationDate);
        const unavailableDate = moment('2009-12-31');

        if (valuationDate.isBefore(unavailableDate)) {
            this.isAvailable = false;
            this.helpService.open('hfrUnavailable', {
                text: 'The High-financial-risk Study is unavailable for valuation dates before December 31, 2009.'
            }, false);
            return false;
        }

        return true;
    }

    private setHfrRecommended(estimate: Estimate): void {
        const forceAllow = estimate.getInput(InputType.ForceAllowHfr);
        if (forceAllow) {
            return;
        }

        if (estimate.Industries == null) {
            return;
        }

        if (estimate.hasFinancialIndustry()) {
            this.isAvailable = false;
            this.helpService.open('hfrNotRecommended', HelpText.FinancialServicesExcludedHfr, false);
            this.helpService.setAction(() => {
                this.isAvailable = true;
                this.helpService.close();
                estimate.setInput(InputType.ForceAllowHfr, 1);
            });
        }
    }

    public ngOnInit(): void {
        const setupTab = () => {
            if (this.router.url.includes('/estimate/studies/hfr/buildup')) {
                this.activeTab = Tab.Buildup;
            } else {
                this.activeTab = Tab.Capm;
            }
        };

        setupTab();
        // TODO: Obviate takeUntil by using Async pipes and local Observable streams
        this.router.events.pipe(takeUntil(this.destroyed)).while(() => setupTab());

        // TODO: Obviate takeUntil by using Async pipes and local Observable streams
        this.dataStore.estimate.pipe(takeUntil(this.destroyed)).whileDefined((estimate) => {
            setTimeout(() => {
                const isAvailable = this.setHfrAvailability(estimate);
                if (isAvailable) {
                    this.setHfrRecommended(estimate);
                }
            });
        });
    }

    public routeTo(study: Tab): void {
        this.activeTab = study;

        switch (study) {
            case Tab.Buildup:
                this.router.navigate([`/estimate/studies/hfr/buildup`]);
                break;
            case Tab.Capm:
            default:
                this.router.navigate([`/estimate/studies/hfr/capm`]);
        }
    }

    public ngOnDestroy(): void {
        this.helpService.closeImmediately();
        super.ngOnDestroy();
    }
}
