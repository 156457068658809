import { Component, Input, OnInit } from '@angular/core';
import { CommonInputConfig } from './common-user-input.component';

@Component({
    selector: 'input-container',
    templateUrl: './input-container.component.html'
})
export class InputContainerComponent implements OnInit {
    public id: string = Math.random().toString(36).substring(2);
    @Input() public config!: CommonInputConfig;
    @Input() public invalidValue!: string;

    public ngOnInit(): void {
        if (this.config.containerClassList) {
            this.config.containerClassList = `common-input-container ${this.config.containerClassList}`;
        } else {
            this.config.containerClassList = 'common-input-container';
        }
    }

    public getErrorText(): string {
        if (this.config.error == null) {
            throw Error(`Expected an error message to be defined by the configuration`);
        }

        return this.config.error.text.replace('{}', this.invalidValue);
    }

    public hasError(): boolean {
        if (this.config.error == null) {
            return false;
        }

        return this.invalidValue ? true : false || this.config.error.alwaysShow;
    }
}
