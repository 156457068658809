import { ApiClient } from '@concurrency/angular';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { IntlData } from '../responses/intl-data.response';
import { Auth0ApiClient } from '../api.client';
import { SuggestionResponse } from '../responses/suggestion.response';
import { RFTrendsOverTime } from '../responses/rf-trends-over-time.reponse';
import { RiskFreeRateOverTime } from 'src/app/costofcapital-inputs/models/cocInputsTabs';

export class IntlRfClient extends Auth0ApiClient {
    protected baseUrl = `${environment.urls.navigatorApi}/api`;

    public read(countryId: number, dataAsOf: string): Observable<IntlData[]> {
        return this.get(`IntlRf?countryId=${countryId}&dataAsOf=${dataAsOf}`);
    }

    public GetUsRfr(dataAsOf: string): Observable<SuggestionResponse[]> {
        return this.get(`InternationalRf/USRfrs?dataAsOf=${dataAsOf}`);
    }

    //Fetching data from RF table for 1 year
    public GetRfrsForToT(objData: RiskFreeRateOverTime): Observable<RFTrendsOverTime[]> {
        return this.post(`InternationalRf/RfrsForToT`, objData);
    }
}
