import { Categories } from 'src/app/home/tear-sheet/const/categories.const';
import { CategoryName } from 'src/app/home/tear-sheet/enums/category';
import { Help } from './help.model';

export class IntlTearsheetHelptext {
    public static AnnualizedMonthlyPerformanceStatistics: Help = {
        title: Categories.AnnualizedMonthlyPerformanceStatistics,
        text: `<p>Annualized monthly performance statistics (geometric return, arithmetic return, and standard deviation) are calculated using the total returns of a portfolio comprising all companies in each industry over the most recent five-year (60 months) period, the most recent three-year (36 months) period, and the most recent one-year (12 months) period as of the “data though” date. Portfolio Formation To compute the total returns for a portfolio comprised of each industry’s company set, the portfolio returns are market-capitalization-weighted as follows: the end-of-month-market capitalization in the previous month for each company in a specific industry (or composite) is used as the weight for each month in the period being examined (i.e., 60-, 36-, or 12-month periods). </p>
        <p><b>Portfolio formation</b></p>
        <p>To compute the total returns for a portfolio comprised of each industry’s company set, the portfolio returns are market-capitalization-weighted as follows: the end-of-month-market capitalization in the previous month for each company in a specific industry (or composite) is used as the weight for each month in the period being examined (i.e., 60-, 36-, or 12-month periods). </p>
        <p><b>Standard Deviation</b></p>
        <p>For performance benchmarking purposes, the same return statistics are also calculated and presented for the “market” over the same 60-, 36-, and 12-month periods. </p>
        <p>For industry analyses utilizing the World company set, the “market” is represented by the MSCI ACWI IMI. </p>
        <p>For industry analyses utilizing (i) the European Union, (ii) the Eurozone, and (iii) the United Kingdom company sets, the “market” is represented by the MSCI Europe Index. </p>
        <p><b>Portfolio formation</b></p>
        <p>The number of companies in the Median, GICS Composite, Large Composite, Small Composite, and the High-Financial-Risk category appears in the first occurrence of these designations (in the “Return Ratios” section). </p>
        <p>The number of companies in the GICS Composite appears directly to the right of “GICS Composite”, in parentheses (Example: “GICS Composite (114)”). This is the total number of companies (114) in the industry; this number will match the number that appears in the upper left of each Industry Data Exhibit (e.g., “Number of Companies: 114”). The number of companies in the “Large” and “Small” Composite are presented in the same fashion. </p>
        <p>The GICS Composite number of companies is the number of “healthy” companies in the main company set for each industry; this number does not include the High-Financial-Risk set of companies. The High-Financial-Risk statistics are calculated separately. The number of companies in the High-Financial-Risk appears directly to the right of “High-Financial-Risk”, in parentheses (Example: “High-Financial-Risk (10)”).</p>`
    };
    public static ReturnRatios: Help = {
        title: Categories.ReturnRatios,
        text: `<p><b>Return on Assets:</b></p>
        <p class='helpicon-color'>Latest </p>
        <p>The “Latest” statistic is calculated as after-tax income before extraordinary items for the most recent fiscal year divided by average total assets for the most recent fiscal year. Average total assets for the most recent fiscal year is calculated as the average of the total assets as of the most recent fiscal year (FY[0]) and the total assets as of the second most recent fiscal year (FY[-1]).</p>
        <p class='helpicon-color'>5-Year Average </p>
        <p>The “5-Year Average” statistic is calculated as the sum of after-tax income before extraordinary items for each of the previous five fiscal years divided by the sum of average total assets calculated for each of the previous five fiscal years. For example, average total assets for the most recent fiscal year is calculated as the average of the total assets as of the most recent fiscal year (FY[0]) and the total assets as of the second most recent fiscal year (FY[-1]). Average total assets for the second most recent fiscal year is calculated as the average of the total assets as of the second most recent fiscal year (FY[-1]) and total assets as of the third most recent fiscal year (FY[-2]), etc. After-tax income before extraordinary (e.g., one-time) items is used in this calculation because after-tax income excluding extraordinary items is likely a better representation of an expected sustainable income stream.</p>
        <p><b>Return on Equity:</b> </p>
        <p class='helpicon-color'>Latest</p>
        <p>The “Latest” statistic is calculated as after-tax income before extraordinary items less preferred stock cash dividends in the most recent fiscal year divided by the average book value of equity for the most recent fiscal year. The average book value of equity for the most recent year is calculated as the average of the book value of equity as of the most recent fiscal year (FY[0]) and the book value of equity as of the second most recent fiscal year (FY[-1]).</p>
        <p class='helpicon-color'>5-Year Average </p>
        <p>The “5-Year Average” statistic is calculated as the sum of after-tax income before extraordinary items less preferred stock cash dividends for each of the previous five fiscal years divided by the sum of average book value of equity calculated for each of the previous five fiscal years. For example, average book value of equity for the most recent fiscal year is calculated as the average of the book value of equity as of the most recent fiscal year (FY[0]) and the book value of equity as of the second most recent fiscal year (FY[-1]). Average book value of equity for the second most recent fiscal year is calculated as the average of the book value of equity as of the second most recent fiscal year (FY[-1]) and the book value of equity as of the third most recent fiscal year (FY[-2]), etc. again, after-tax income before extraordinary (e.g., one-time) items is also used in this calculation because income excluding extraordinary items is likely a better representation of an expected sustainable income stream.</p>
        <p><b>Dividend Yield:</b> </p>
        <p>The number of common shares outstanding for each issue of common stock is multiplied by the respective dividends paid per share of each respective issue, as of month-end. The sum of all respective dividends paid per share, for each issue, for each company, is then summed. Number of common shares outstanding for each issue of common stock is multiplied by the respective price per share of each respective issue, as of month-end. The sum of all respective market capitalizations, for each issue, for each company, is then summed.</p>
        <p class='helpicon-color'>Latest </p>
        <p>The “Latest” statistic is calculated as the sum of common shares outstanding multiplied by dividends per common share for each of the 12 months ending With the “data through” date, which is then divided by the sum of common shares outstanding multiplied by price per common share for each of the 12 months ending with the “data through” date. <p>
        <p class='helpicon-color'>5-Year Average </p>
        <p>The “5-Year Average” statistic is calculated as the sum of common shares outstanding multiplied by dividends per common share for each of the 60 months ending with the “data through” date, which is then divided by the sum of common shares outstanding multiplied by price per common share for each of the 60 months ending with the “data through” date.</p>`
    };
    public static LiquidityRatio: Help = {
        title: Categories.LiquidityRatio,
        text: `<p><b>Latest </b></p>
        <p>The “Latest” statistic is calculated as current assets in the most recent fiscal year divided by current liabilities in the most recent fiscal year. </p>
        <p><b>5-Year Average </b></p>
        <p>The “5-Year Average” statistic is calculated as the sum of current assets for each of the previous five fiscal years divided by the sum of current liabilities for each of the previous five fiscal years.</p>`
    };
    public static ProfitabilityRatio: Help = {
        title: Categories.ProfitabilityRatio,
        text: `<p><b>Latest </b></p>
        <p>The “Latest” statistic is calculated as operating income after depreciation and amortization in the most recent fiscal year divided by net sales in the most recent fiscal year. </p>
        <p><b>5-Year Average </b></p>
        <p>The “5-Year Average” statistic is calculated as the sum of operating income after depreciation and amortization for each of the previous five fiscal years divided by the sum of net sales for each of the previous five fiscal years.</p>`
    };
    public static GrowthRates: Help = {
        title: Categories.GrowthRates,
        text: `<p><b>Long-Term Earnings Per Share (EPS)</b></p>
        <p>Refinitiv I/B/E/S Consensus Estimates database is the source for analysts’ estimates of future earnings growth. Growth rates for long-term earnings are calculated herein on a “Latest” basis only. </p>
        <p class='helpicon-color'>Latest</p>
        <p>Growth rates for long-term earnings are calculated as the market-capitalization-weighted I/B/E/S long-term EPS growth rate. The market capitalization as of the end of the most recent month as of the “data through” date is used for the weighting.</p>
        <p>For companies that do not have an I/B/E/S long-term EPS growth rate, the market-capitalization weighted growth rate for the most specific SIC code in which the company appears, and in which there are at least five companies with growth rates, is substituted. For example, if hypothetical Company ABC in GICS 3030 does not have a growth rate, the market-capitalization-weighted growth rate for the other companies in GICS 3030 are used. If GICS 3030 does not have at least five other companies with growth rates, then the calculation is performed using GICS 30 (up one level, and thus less specific), etc.</p>`
    };
    public static BetasLevered: Help = {
        title: Categories.BetasLevered,
        text: `<p>Levered betas for companies and portfolios are calculated in the Cost of Capital Navigator – International Industry Benchmarking Module in three primary ways: (i) raw ordinary least squares (OLS) beta, (ii) sum beta, and (iii) downside beta.<p>
        <p>Beta is a measure of the systematic risk of a stock; the tendency of a stock’s price to correlate with changes in the overall market. In all cases in the analyses presented herein:
            <ul>
                <li>The MSCI ACWI IMI Index is used as the “market” benchmark in the regressions performed to calculate individual company (and portfolio) beta calculations of industries comprised of companies in the “World” company set. </li>
                <li>The MSCI Europe Index is used as the “market” benchmark in the regressions performed to calculate individual company (and portfolio) beta calculations of industries comprised of companies in the (i) the European Union, (ii) the Eurozone, and (iii) the United Kingdom. </li>
                <li>Beta is calculated over the 60-month period ending with the “data through” date.  </li>
                <li>Betas less than or equal to zero and betas greater than or equal to five are discarded and not included in any further calculations. </li>
                <li>All betas are presented in both “levered” and “unlevered” form:</li>
            </ul>
        </p>
        <p><b>Levered Betas:</b> Published beta estimates for public stocks typically reflect the capital structure of each respective firm at market values. The historical beta estimates are typically made using realized returns for the subject business's stock and the stock market as a whole as measured by the market index (e.g., the MSCI ACWI IMI, or the MSCI Europe Index). These betas are sometimes referred to as “levered” betas (also called “equity” betas), since these beta estimates reflect the actual leverage (i.e., debt) in the subject business's capital structure. </p>
        <p>One typically uses excess returns in the regression analysis that is performed to calculate beta estimates. Excess returns are calculated by subtracting a risk-free rate from the total returns of both the market benchmark (the “independent” variable in the regression), and the total returns of the company or portfolio (the “dependent” variable in the regression) for which the beta is being calculated. The reason for doing this is that the beta is likely to be used to calculate estimated excess returns for the company or portfolio within the context of the Capital Asset Pricing Model (CAPM), and therefore the inputs in calculating the beta should also be excess returns. </p>
        <p><b>“Total” Returns, and Not “Excess” Returns, Are Used in All Beta Calculations Herein </b></p>
        <p>As a simplification (due to the difficulty in identifying short-term monthly risk-free rates for, say, the “World”), all beta analyses herein were calculated using total returns (for both the market benchmarks, and for the companies or portfolios of companies), and not excess returns. In most cases it does not make much difference whether one uses total returns or one uses excess returns in the calculation of beta. This is especially true in the most recent years in which the short-term risk-free rate (e.g., 30 days) that would be subtracted from say, monthly company (or portfolio) total returns, is virtually zero. </p>
        <p><b>Raw (OLS) Beta</b></p>
        <p>Raw (OLS) beta is calculated in the International Industry Benchmarking Module as a linear regression with a company’s (or portfolio’s) monthly total returns acting as the dependent variable, and the monthly total returns of the market benchmark acting as the independent variable. </p>
        <p><b>Peer Group Beta </b></p>
        <p>The “peer group” betas for composite portfolios herein are calculated based upon the sales weighted “full-information” betas of the industries in which all companies in the GICS Composite, Large Composite, Small Composite, or composite of all high-financial-risk companies participate. </p>
        <p>The full-information beta methodology is based on the premise that a business can be thought of as a portfolio of assets, and is designed to capture the impact that the individual segments of companies have upon the overall business beta.  Ideally, one would like to have a sampling of betas from many “pure play” guideline public companies when estimating a proxy beta. “Pure play” companies are companies with say, at least 75% of their revenue derived from a single Global Industry Classification Standard (GICS) code, as is required of the companies used in the analyses in the International Industry Benchmarking Module. However, in some industries the largest participants in an industry may be conglomerates for which participation in the given industry is a small part of the conglomerate’s overall revenues. In a strictly pure play analysis, these companies would be excluded, even though they may be the dominant participants in their industries. The full-information beta approach enables the inclusion of these companies’ participation in the industry in the beta calculation.</p>
        <p>To calculate full-information betas, segment sales for companies are identified, and a beta estimate for each of the companies is calculated. Then, a multiple regression is run with these betas as the dependent variables (applying a weight to each beta based on its relative market capitalization to the industry market capitalization) and sales of the segments of each of the companies in the industry as the independent variable. That is, one is measuring the relative impact on the betas of companies in an industry based on the relative contributed sales each company has within any given industry. </p>
        <p>Applying market capitalization weights in the process tends to reduce the beta estimates because large capitalization companies on the average have lower betas than smaller companies. Measuring the impact on betas using segment sales data may present a problem in that the market weighs profits, not sales. This procedure may over-weight the relative importance of business segments having high sales and low profits. </p>
        <p>The full-information betas used in the calculations in the Cost of Capital Navigator – International Industry Benchmarking Module are calculated using groupings of companies at the 4-digit GICS level. For the analyses presented herein, a total of 12 independently calculated sets of 24 4-digit GICS full information betas were calculated as of the “data through” date, for each of the four global areas (World, EU, Eurozone, and United Kingdom), for each of the three currencies (EUR, GBP, and USD). </p>
        <p>To calculate the median peer group beta for each area/currency/industry combination, a sales weighted beta is calculated based upon the appropriate sales weighted full-information industry beta(s) corresponding to each industry that each individual company participates in. These results are then ranked from highest to lowest, and the middle value is identified. </p>
        <p>To calculate the peer group beta for each GICS Composite Large Composite, Small Composite, and a composite of all high-financial-risk companies for each area/currency/industry combination, the sum of all sales for all companies that are constituents of the given area/currency/industry combination is first summed, and then a peer group beta is calculated based upon the appropriate sales-weighted full-information industry beta(s) corresponding to each industry that all companies in the area/currency/industry combination participate in, in aggregate. </p>
        <p><b>Sum Beta:</b></p>
        <p>For all but the largest companies, the prices of individual stocks tend to react (in part) to movements in the overall market with a lag. The smaller the company, generally the greater the lag in the price reaction. Sum beta is an attempt to capture more fully the lagged effect of co-movement in a company's returns with returns on the market.</p>
        <p>Sum beta is calculated in the International Industry Benchmarking Module as a multiple regression with a company’s (or portfolio’s) monthly total returns (i.e., returns over the risk-free rate) acting as the dependent variable, and the market's current month's returns and the market's previous month's (i.e., “lagged”) returns acting as the independent variables, and then summing the resulting coefficients.</p>
        <p><b>Downside Beta:</b></p>
        <p>For all but the largest companies, the prices of individual stocks tend to react (in part) to movements in the overall market</p>
        <p>The “downside” beta attempts to isolate the downside potential of a security’s returns relative to that of the market’s returns. According to this measure, securities that magnify the market’s upward swings are not necessarily risky; only those that magnify the market’s downward swings are. The assumption made when using downside beta is that when assessing risk, investors are only interested in the relative downside potential with respect to any chosen benchmark. </p>
        <p><b>Adjusted Betas</b></p>
        <p>Cost of capital is inherently a forward-looking concept, and thus all of its inputs should also be forward-looking. Betas are generally calculated using historical data, and the betas calculated in this fashion may be “forward-looking” only to the degree that history repeats itself. </p>
        <p>There are several “adjustment” techniques that can be used in an effort to make a historical beta more forward-looking. “Adjusted” betas for companies and portfolios are calculated in the Cost of Capital Navigator – International Industry Benchmarking Module using two commonly used techniques. The first of these (Blume),  adjusts to the market beta of 1.0; the second of these (Vasicek),  is used to adjust to the industry peer group beta, dependent on the statistical quality of the calculated Raw (OLS) beta.</p>
        <p><b>Blume-Adjusted Beta:</b></p>
        <p>This adjustment is sometimes referred to as the “Blume” adjustment, or the “one third/two thirds” adjustment. This technique has been popularized in Bloomberg terminals, even though many users may be unaware of what it really represents. The so-called “Bloomberg Adjusted Beta” is nothing more than the application of the “Blume” adjustment. </p>
        <p>The Blume adjustment is a mechanical “one-size-fits-all” adjustment that has the net effect of adjusting historical betas toward the “market” beta of 1.0 (i.e., historical betas that are less than 1.0 are adjusted upward, and historical betas that are greater than 1.0 are adjusted downward). The adjustment is said to create a “forward” (or prospective) estimated beta because this adjustment is based on the assumption that betas tend to move toward the market’s beta (1.0) over time. It does not indicate that any adjustment to the data used in calculating the historical beta estimate was made.</p>
        <p><b>Vasicek-Adjusted Beta:</b></p>
        <p>Another technique for adjusting historical beta estimates is sometimes referred to as Vasicek “shrinkage”. The idea is that betas with low statistical quality (as measured by high standard error) are the least reliable estimates, and therefore should be adjusted toward the industry average (i.e., peer group beta) to a greater degree than betas with high statistical quality (as measured by low standard error).  Because high-beta stocks also tend to have the highest standard errors in their raw betas, they tend to be subject to the most significant adjustments toward their industry averages.</p>
        <p>The Vasicek adjustment equation may look complex, but what it accomplishes is a straightforward “sliding scale” between the historical beta and the peer group beta, dependent on the statistical quality (as measured by standard error) of the historical beta.</p>
        <p>The net result is that the more statistically significant the historical beta is (as measured by standard error), the less the adjustment toward the peer beta (i.e., “weight” will be closer to 1); the less statistically significant the historical beta is, the greater the adjustment toward the peer beta (i.e., “weight” will be closer to 0).</p>
        <p><b>Graph – Betas (Levered)</b></p>
        <p>The “Betas (Levered)” bar graph plots the GICS Composite Raw (OLS) beta, Peer Group beta, Blume-adjusted beta, Vasicek-adjusted beta, Sum beta, and Downside beta.</p>`
    };
    public static BetasUnlevered: Help = {
        title: Categories.BetasUnlevered,
        text: `<p>“Unlevered” betas (also called “asset” betas) have the effect of financial leverage removed, thereby reflecting only the effect of business risk. An unlevered beta is the beta that would be expected if a company were financed only with equity capital (i.e., no debt). The (levered) betas calculated in the industry analyses presented herein are as follows: (i) Raw (OLS) Beta, (ii) Blume-adjusted Raw (OLS) Beta, (iii) Peer Group Beta, (iv) Vasicek-adjusted Raw (OLS) Beta, (v) Sum Beta, and (vi) Downside Beta. Each of these levered betas is also presented in “unlevered” form. There are several commonly used unlevering methodologies.  In the analyses presented in the International Industry Benchmarking Module, the “Harris-Pringle” formulas are used to unlever all beta estimates. </p>
        <p>Beta is a measure of the systematic risk of a stock; the tendency of a stock’s price to correlate with changes in the overall market. In all cases in the analyses presented herein:
            <ul>
                <li>The MSCI ACWI IMI Index is used as the “market” benchmark in the regressions performed to calculate individual company (and portfolio) beta calculations of industries comprised of companies in the “World” company set. </li>
                <li>The MSCI Europe Index is used as the “market” benchmark in the regressions performed to calculate individual company (and portfolio) beta calculations of industries comprised of companies in the (i) the European Union, (ii) the Eurozone, and (iii) the United Kingdom. </li>
                <li>Beta is calculated over the 60-month period ending with the “data through” date.  </li>
                <li>Betas less than or equal to zero and betas greater than or equal to five are discarded and not included in any further calculations. </li>
                <li>All betas are presented in both “levered” and “unlevered” form:</li>
            </ul>
        </p>
        <p><b>Unlevered Betas:</b> Alternatively, “unlevered” betas (also called “asset” betas) have the effect of financial leverage (i.e., debt) removed, thereby reflecting only the effect of business risk. An unlevered beta is the beta that would be expected if a company were financed only with equity capital.</p>
        <p>One typically uses excess returns in the regression analysis that is performed to calculate beta estimates. Excess returns are calculated by subtracting a risk-free rate from the total returns of both the market benchmark (the “independent” variable in the regression), and the total returns of the company or portfolio (the “dependent” variable in the regression) for which the beta is being calculated. The reason for doing this is that the beta is likely to be used to calculate estimated excess returns for the company or portfolio within the context of the Capital Asset Pricing Model (CAPM), and therefore the inputs in calculating the beta should also be excess returns. </p>
        <p><b>“Total” Returns, and Not “Excess” Returns, Are Used in All Beta Calculations Herein </b></p>
        <p>As a simplification (due to the difficulty in identifying short-term monthly risk-free rates for, say, the “World”), all beta analyses herein were calculated using total returns (for both the market benchmarks, and for the companies or portfolios of companies), and not excess returns. In most cases it does not make much difference whether one uses total returns or one uses excess returns in the calculation of beta. This is especially true in the most recent years in which the short-term risk-free rate (e.g., 30 days) that would be subtracted from say, monthly company (or portfolio) total returns, is virtually zero. </p>
        <p>In the calculations in the main section of this book, debt is defined simply as the “book” value of interest-bearing debt (as a proxy for market value of debt). For example, “book” debt is the debt measure used as an input in the Harris-Pringle formula to calculate the unlevered betas presented in the main Industry Data Exhibits. </p>`
    };
    public static EquValMutliples: Help = {
        title: Categories.EquityValuationMultiples,
        text: `<p><b>Price to Sales</b></p>
        <p class='helpicon-color'>Latest </p>
        <p>The “Latest” statistic is calculated as total market capitalization of common equity at the end of the most recent month divided by net sales for the most recent fiscal year.</p>
        <p class='helpicon-color'>5-Year Average </p>
        <p>The “5-Year Average” statistic is calculated as the sum of total market capitalization of common equity for each of the previous five fiscal years divided by the sum of net sales for each of the previous five fiscal years. For the 5-Year average, total market capitalization of common equity is calculated as the sum of the monthly market capitalizations of common equity for each year divided by 12 for each of the most recent five fiscal years.</p>
        <p><b>Price to Earnings</b></p>
        <p class='helpicon-color'>Latest </p>
        <p>The “Latest” statistic is calculated as total market capitalization of common equity at the end of the most recent month divided by the after-tax income before extraordinary items less preferred dividends for the most recent fiscal year.</p>
        <p class='helpicon-color'>5-Year Average </p>
        <p>The “5-Year Average” statistic is calculated as the sum of total market capitalization of common equity for each of the previous five fiscal years divided by the sum of after-tax income before extraordinary items less preferred dividends for each of the previous five fiscal years. For the 5-Year average, total market capitalization of common equity is calculated as the sum of the monthly market capitalization of equity for each year divided by 12, for each of the most recent five fiscal years.</p>
        <p><b>Market to Book</b></p>
        <p class='helpicon-color'>Latest </p>
        <p>The “Latest” statistic is calculated as total market capitalization of common equity at the end of the most recent month divided by book value of common equity (plus net deferred taxes and investment tax credits) for the most recent fiscal year.</p>
        <p class='helpicon-color'>5-Year Average </p>
        <p>The “5-Year Average” statistic is calculated as the sum of total market capitalization of common equity for each of the previous five fiscal years divided by the sum of book value of common equity (plus net deferred taxes and investment tax credits) for each of the previous five fiscal years. For the 5-Year Average, total market capitalization of common equity is calculated as the sum of the monthly market capitalizations of equity for each year divided by 12 for each of the most recent five fiscal years.</p>
        <p><b>NOTE:</b> In previous editions of the Valuation Handbook – International Industry Cost of Capital, Market-to-book was calculated for all GICS, including “Financial GICS” (i.e., all GICS in GICS 40) and GICS 60 (i.e., Real Estate, which, as of August 31, 2016, was removed from Financials and promoted to its own sector under GICS 60). In the Cost of Capital Navigator – International Industry Benchmarking Module (this book), Market-to-book will continue to be calculated for non-Financial GICS (i.e., all GICS not included in GICS 40 or GICS 60). Price to Tangible Book replaces Market-to-book multiple for all Financial and Real Estate GICS.</p>
        <p><b>Price to Tangible Book</b></p>
        <p class='helpicon-color'>Latest </p>
        <p>The “Latest” statistic is calculated as total market capitalization of common equity at the end of the most recent month divided by tangible book value of common equity (plus net deferred taxes and investment tax credits) for the most recent fiscal year.</p>
        <p class='helpicon-color'>5-Year Average</p>
        <p>The “5-Year Average” statistic is calculated as the sum of total market capitalization of common equity for each of the previous five fiscal years divided by the sum of tangible book value of common equity (plus net deferred taxes and investment tax credits) for each of the previous five fiscal years. For the 5-Year Average, total market capitalization of common equity is calculated as the sum of the monthly market capitalizations of equity for each year divided by 12 for each of the most recent five fiscal years.</p>
        <p><b>NOTE:</b> Starting data calculated through September 30, 2016, Price to Earnings Before Taxes replaces the EV to Sales multiple for GICS beginning in “40” and “60”, (i.e., Financials and Real Estate, respectively). EV to Sales will continue to be calculated for all GICS not beginning in “40” and “60”.</p>
        <p><b>Price to Earnings Before Taxes</b></p>
        <p class='helpicon-color'>Latest </p>
        <p>The “Latest” statistic is calculated as total market capitalization of common equity at the end of the most recent month divided by the pre-tax income before extraordinary items less special items for the most recent fiscal year.</p>
        <p class='helpicon-color'>5-Year Average </p>
        <p>The “5-Year Average” statistic is calculated as the sum of total market capitalization of common equity for each of the previous five fiscal years divided by the sum of pre-tax income before extraordinary items less special items for each of the previous five fiscal years. For the 5-Year Average, total market capitalization of common equity is calculated as the sum of the monthly market capitalization of equity for each year divided by 12, for each of the most recent five fiscal years.</p>`
    };
    public static EntpValMutliples: Help = {
        title: Categories.EnterpriseValuationMultiples,
        text: `<p><b>EV to Sales</b></p>
        <p class='helpicon-color'>Latest </p>
        <p>The “Latest” statistic is calculated as enterprise value in the most recent fiscal year divided by net sales in the most recent fiscal year. </p>
        <p>Enterprise value is calculated as total debt, plus total market capitalization of common equity at the end of the most recent month, plus non-controlling interest (a.k.a. minority interest), minus cash and cash equivalents. With the exception of market capitalization of common equity, all other inputs are based on their book values as of the most recent fiscal year. </p>
        <p>Total debt is calculated as the sum of long-term debt, the current portion of long-term debt, notes payable, and preferred stock.  Again, all of these inputs are based on their respective book values as of the most recent fiscal year.</p>
        <p class='helpicon-color'>5-Year Average </p>
        <p>The “5-Year Average” statistic is calculated as the sum of enterprise value for each of the previous five fiscal years divided by the sum of net sales for each of the previous five fiscal years. </p.
        <p>Enterprise value is calculated as total debt, plus total market capitalization of common equity, plus non-controlling interest (a.k.a. minority interest), minus cash and cash equivalents for each of the previous five fiscal years. For the 5-Year Average, total market capitalization of common equity is calculated as the sum of the monthly market capitalizations of equity for each year divided by 12 for each of the most recent five fiscal years. </p>
        <p>Total debt is calculated as the sum of long-term debt, the current portion of long-term debt, notes payable, and preferred stock in each of the previous five fiscal years.</p>
        <p><b>EV to EBITDA </b></p>
        <p class='helpicon-color'>Latest </p>
        <p>The “Latest” statistic is calculated as enterprise value in the most recent fiscal year divided by EBITDA in the most recent fiscal year. </p>
        <p>Enterprise value is calculated as total debt, plus total market capitalization of common equity capitalization at the end of the most recent month, plus non-controlling interest (a.k.a. minority interest), minus cash and cash equivalents. With the exception of market capitalization of common equity, all other inputs are based on their book values as of the most recent fiscal year.</p>
        <p>Total debt is calculated as the sum of long-term debt, the current portion of long-term debt, notes payable, and preferred stock.  Again, all of these inputs are based on their respective book values as of the most recent fiscal year. </p>
        <p>EBITDA is calculated as earnings before interest, taxes, depreciation, and amortization plus nonoperating income (as a proxy for an adjustment for non-recurring items) in the most recent fiscal year.</p>
        <p class='helpicon-color'>5-Year Average </p>
        <p>The “5-Year Average” statistic is calculated as the sum of enterprise value for each of the previous five fiscal years divided by the sum of EBITDA for each of the previous five fiscal years. </p>
        <p>Enterprise value is calculated as total debt, plus total market capitalization of common equity, plus non-controlling interest (a.k.a. minority interest), minus cash and cash equivalents for each of the previous five fiscal years. For the 5-Year Average, total market capitalization of common equity is calculated as the sum of the monthly market capitalization of equity for each year divided by 12 for each of the most recent five fiscal years.</p>
        <p>Total debt is calculated as the sum of long-term debt, the current portion of long-term debt, notes payable, and preferred stock in each of the previous five fiscal years. </p>
        <p>EBITDA is calculated as earnings before interest, taxes, depreciation, and amortization plus nonoperating income (as a proxy for an adjustment for non-recurring items) in each of the previous five fiscal years.</p>`
    };
    public static LeverageRatio: Help = {
        title: Categories.LeverageRatios,
        text: `<p><b>Debt-to-MV Equity</b></p>
        <p class='helpicon-color'>Latest </p>
        <p>The “Latest” statistic is calculated as the most recent fiscal year total debt divided by total market capitalization of common equity at the end of the most recent month. Total debt is calculated as the sum of long-term debt, the current portion of long-term debt, notes payable, and preferred stock.  Again, all of these inputs are based on their respective book values as of the most recent fiscal year.</p>
        <p class='helpicon-color'>5-Year Average </p>
        <p>The “5-Year Average” statistic is calculated as the sum of total debt for each of the previous five fiscal years divided by the sum of total market capitalization of common equity for each of the previous five fiscal years.</p>
        <p>Total debt is calculated as the sum of long-term debt, the current portion of long-term debt, notes payable, and preferred stock in each of the previous five fiscal years.</p>
        <p>In the “5-Year Average” calculation, total market capitalization of common equity is calculated as the sum of the monthly market capitalizations of equity for each year divided by 12 for each of the most recent five fiscal years.</p>
        <p><b>Debt-to-Total Capital</b></p>
        <p class='helpicon-color'>Latest </p>
        <p>The “Latest” statistic is calculated as the most recent fiscal year total debt divided by total capital at the end of the most recent fiscal year. </p>
        <p>Total debt is calculated as the sum of long-term debt, the current portion of long-term debt, notes payable, and preferred stock.  Again, all of these inputs are based on their respective book values as of the most recent fiscal year. </p>
        <p>Total capital is measured as total debt plus total common equity capitalization at the end of the most recent month.</p>
        <p class='helpicon-color'>5-Year Average </p>
        <p>The “5-Year Average” statistic is calculated as the sum of total debt for each of the previous five fiscal years divided by the sum of total capital for each of the previous five fiscal years. </p>
        <p>Total debt is calculated as the sum of long-term debt, the current portion of long-term debt, notes payable, and preferred stock in each of the previous five fiscal years. </p>
        <p>Total capital is calculated as total debt plus total market capitalization of common equity in each of the previous five fiscal years. In the “5-Year Average” calculation, total market capitalization of common equity is calculated as the sum of monthly market capitalizations of equity for each year divided by 12 for each of the most recent five fiscal years.</p>`
    };
    public static CostOfDebts: Help = {
        title: Categories.CostOfDebt,
        text: `<p>The following approaches are more commonly used to estimate the pre-tax cost of debt: (1) using the yield-to-maturity on publicly traded long-term bonds issued by the subject company, when available; (2) using the average yield-to-maturity on publicly traded bonds with the same credit rating as the subject company; or (3) estimating a default spread based upon the company’s rating. This presumes that a company is rated by credit rating agencies such as Standard & Poor’s (S&P), Moody’s, or Fitch Ratings, for example. In absence of such credit rating, a quasi or “synthetic” rating can be estimated for the subject company.</p>
        <p><b>Credit Ratings</b></p>
        <p>A cost of debt estimate is calculated for each company based upon (i) the S&P credit rating for the company, or (ii) for companies that do not have an S&P credit rating, a long-term credit score from S&P Global Market Intelligence Credit Analytics is substituted. ,   For companies without an S&P credit rating, or a credit score from S&P Global Market Intelligence Credit Analytics, an average credit rating for the most specific GICS code in which the company appears, and in which there are at least five companies with an S&P credit rating and/or credit score from S&P Global Market Intelligence Credit Analytics, is substituted. For example, if hypothetical Company ABC in GICS 251010 does not have a credit rating, the average credit rating for the other companies in GICS 251010 are used. If GICS 251010 does not have at least five other companies with credit ratings, then the calculation is performed using GICS 2510 (up one level, and thus less specific), etc.</p>
        <p><b>Default Spreads</b></p>
        <p>The S&P credit rating for each company (or, in the case of companies without an S&P credit rating, the “synthetic” credit rating) was then mapped to a pre-tax cost of debt based on the company’s selected currency (EUR, GBP, or USD).</p>
        <p>The cost of debt has to be estimated in the same currency as the cost of equity and the projected cash flows in the valuation. </p>
        <p>To calculate a pre-tax cost of debt for each rating and currency combination, we first started by compiling the yields-to-maturity on: (i) 15-year European corporate bonds denominated in Euros; 15-year European corporate bonds denominated in British Pounds; and (ii) 15-year U.S. corporate bonds denominated in U.S. Dollars. This yield-to-maturity information was collected as of the “data through” date for each full rating category (AAA, AA, A, BBB, BB, B, and CCC).  </p>
        <p>In order to estimate the default spread associated with each rating within each currency, we then compiled the spot yields on: (i) 15-year German government bonds (a.k.a. Bunds) in Euros,  (ii) United Kingdom government bonds (a.k.a. Gilts) in Pounds, and (iii) U.S. government bonds (a.k.a. Treasuries) in U.S. Dollars.  These yields are considered reasonable representations for the risk-free rate in each of these currencies. </p>
        <p>The “default spreads” for each credit rating within each currency were then derived by subtracting the corporate yields in each rating category from the government bond yields in the same currency. For example, the following calculation would result in the default spread appropriate for a BBB rated company in Euros: </p>
        <p>Default Spread BBB, EUR = (Yield-to-maturity on 15-year corporate bonds, rated BBB in EUR) – (15- year German government bond yield)</p>
        <p>The default spreads from each currency and credit rating were then added to the respective normalized risk-free rate (as discussed in the section entitled “Derivation of Long-term Risk-free Rates and Long-term Equity Risk Premia”). Because the cost of debt is calculated based on a normalized risk-free rate, some may label the resulting estimate a normalized cost of debt</p>
        <p>If the valuation analyst has a different view on the risk-free rate input for any given currency, the following formula can be used to adjust the published cost of debt in the same currency estimated for the subject industry: </p>
        <p>Custom Estimate Selected Currency = (Custom Risk-free Rate Selected Currency) + (Published Cost of Selected Currency) – (Normalized Risk-free Rate Selected Currency) </p>
        <p>Cost of debt is calculated herein on a “Latest” basis only. The median cost of debt is calculated as the middle value of all individual companies’ cost of debt estimates. The cost of debt for the “GICS Composite”, the “Large Composite”, the “Small Composite”, and a composite comprised of all companies in the “High-Financial-Risk” category is calculated as the debt-weighted average yield of all individual company’s cost of debt estimates.</p>`
    };
    public static CostOfEquityCapital: Help = {
        title: Categories.CostOfEquityCapital,
        text: `<p>There are five cost of capital estimates presented in the analyses herein:
        <ul>
            <li>Capital Asset Pricing Model (CAPM) </li>
            <li>Stage Discounted Cash Flow (DCF) Model </li>
            <li>Stage Discounted Cash Flow (DCF) Model </li>
            <li>Cost of Debt </li>
            <li>Weighted Average Cost of Capital (WACC) </li>
        </ul>
    </p>
    <p>Cost of capital estimates using the CAPM use “Vasicek-adjusted” OLS betas. </p>
    <p>All cost of capital estimates (and their inputs) are based on data from publicly held/publicly traded companies. </p>
    <p>All cost of capital estimates are after corporate taxes, before personal taxes, and prior to any additional adjustments for “size”, “country”, or “company-specific” risk premia that the individual valuation analyst may deem appropriate.</p>
    <p><b>CAPM</b></p>
    <p>In the industry-level cost of equity capital calculations presented herein, an estimate is first calculated using textbook CAPM. “Textbook” CAPM is CAPM calculated prior to any additional adjustments for “size”, country, or “company-specific” risk premiums that the individual valuation analyst may deem appropriate.</p>
    <p class='helpicon-color'>Median</p>
    <p>The median “base” CAPM cost of equity estimate is calculated differently from how the “base” CAPM cost of equity estimates for the composites (described in next section) are calculated. The median calculation is a multi-step process. </p>
    <p>First, a 60-month raw (OLS) beta is calculated for each company in the industry, as described previously in the section “Raw (OLS) Beta”. </p>
    <p>Second, a “peer group” beta is calculated based upon the sales-weighted “full-information” betas of the industries in which each of the companies included in the subject industry participate, as described previously in the section “Peer Group Betas”. </p>
    <p>Third, the 60-month raw (OLS) beta for each company in the industry is then adjusted toward the peer group beta using the “Vasicek” adjustment, as described previously in the section “Vasicek adjusted Beta”. </p>
    <p>Fourth, the Vasicek-adjusted betas calculated in the previous step for each company is substituted into the CAPM equation to determine a base cost of equity capital estimate for each company in the industry. </p>
    <p>Finally, the median (middle value) base cost of equity estimate is identified.</p>
    <p class='helpicon-color'>Composites</p>
    <p>The composite-level “base” CAPM cost of equity estimate is calculated by aggregation. For illustration purposes, a generic “Composite” will be discussed here, although these same steps are followed for the GICS Composite, the Large Composite, the Small Composite, or a composite comprised of high-financial-risk companies. The composite-level calculation is also a multi-step process. </p>
    <p>First, the market-cap-weighted total returns of a portfolio comprised of all of the companies in the Composite are calculated, as described previously in the section “Portfolio Formation”. </p>
    <p>Second, a 60-month raw (OLS) beta for the portfolio is calculated using the market-cap-weighted portfolio total returns calculated in the previous step. </p>
    <p>Third, a “peer group” beta is calculated based upon the sales-weighted “full-information” betas of the industries in which all the companies included in the Composite participate, in aggregate, as described previously in the section “Peer Group Beta”. </p>
    <p>Fourth, the 60-month raw (OLS) beta for the Composite is adjusted toward the peer group beta using the “Vasicek” adjustment, as described previously in the section “Vasicek-Adjusted Beta”.</p>
    <p>Fifth, the Vasicek-adjusted Composite beta calculated in the previous step is substituted into the CAPM equation to determine a base cost of equity capital estimate for the Composite.</p>
    <p><b>Using a Custom ERP and Risk-free Rate</b></p>
    <p>As previously discussed in the section “Long-term Risk-free Rates and Long-term Equity Risk Premia”, the risk-free rates and ERPs (as of the “data through” date) in Exhibit 21 and Exhibit 22 were used to estimate cost of capital estimates for each of the industry analyses pages herein. </p>
    <p>If the valuation analyst would like to adjust the “base” CAPM cost of equity capital estimates to see what they would look like using a custom ERP and/or risk-free rate, this can be accomplished by substituting the custom ERP and risk-free rate values into the following equation: </p>
    <p>Custom Estimate = (Custom Risk-free Rate) + (Custom ERP) x (Vasicek-adjusted Beta) </p>
    <p>As previously noted, the Vasicek-adjusted beta is used in the “base” CAPM estimates presented herein. A Vasicek-adjusted beta is calculated for each industry in five different ways: (i) Median, (ii) GICS Composite, (iii) Large Composite, (iv) Small Composite, and (v) a composite comprised of the “high-financial-risk” companies in the industry. To calculate the custom median “base” CAPM cost of equity capital estimate, use the median Vasicek-adjusted beta; to calculate the custom Large Composite “base” CAPM cost of equity capital estimate, use the Large Composite Vasicek-adjusted beta; etc.</p>
    <p><b>Discounted Cash Flow (DCF) Models </b></p>
    <p class='helpicon-color'>1-Stage DCF</p>
    <p>The 1-Stage DCF model presented in the International Industry Benchmarking Module is essentially the constant growth model, commonly known as the Gordon Growth Model.  The constant growth model is a method of “capitalizing” cash flows. In capitalizing, the focus is on the cash flow of just a single period, as opposed to projecting the multiple expected cash flows from the subject investment over the life of the investment. </p>
    <p class='helpicon-color'>Median</p>
    <p>For each company in the industry, the cost of equity capital using the 1-Stage DCF model is first calculated. Then, the median (middle value) is identified.</p>
    <p class='helpicon-color'>Composites</p>
    <p>The composite-level 1-Stage DCF cost of equity capital estimate is calculated by aggregation. For illustration purposes, a generic Composite will be discussed here, although these same steps are followed for the GICS Composite, the Large Composite, the Small Composite, or a composite of all high-financial-risk companies.</p>
    <p>The aggregate growth rate is the market-capitalization-weighted growth rate of all companies in the Composite, based upon the market capitalization of each company as of the end of the most recent month. Aggregate dividends are the sum of all dividends for each company in the Composite in the 12 months ending in the most recent month. The aggregate price per share is the sum of the price per common share at the end of the most recent month for each company in the Composite.</p>
    <p><b>3-Stage DCF</b></p>
    <p>For most equity investments, it is probably not reasonable to expect a constant growth rate into perpetuity, as is assumed in the preceding 1-stage DCF model. This dilemma is typically dealt with by the use of “multi-stage” DCF models.</p>
    <p>Solving for the cost of equity capital using the 3-Stage DCF model presented herein is accomplished in all cases through an iterative process that equates the present value of all expected future cash flows for an individual company (or composite) to the market capitalization of the company (or composite) in the most recent month, dependent on the discount rate.</p>
    <p class='helpicon-color'>Median</p>
    <p>For each company in the industry, the cost of equity capital using the 3-Stage DCF model is first calculated. Then, the median (middle value) cost of equity capital estimate is identified.</p>
    <p>At the individual-company level, the growth rate used in Stage 1 is based on each company’s expected growth rate from the Refinitiv I/B/E/S Consensus Estimates database. The growth rate used in Stage 2 is the expected industry growth rate, measured as the market capitalization-weighted growth rate of all companies in the industry as of the most recent month. The growth rate used in Stage 3 is the expected growth rate of the economy as a whole, the derivation of which was previously described in the section “Derivation of Growth Rates”. This is done based on the assumption that over time a company’s growth rate will move toward its industry’s growth rate, and ultimately will move toward the growth rate of the economy as a whole.</p>
    <p>Cash flows are used in Stage 1 (years 1–5) and Stage 2 (years 6–10) because many companies do not pay dividends. Cash flows for Stage 1 and Stage 2 are calculated in a multi-step process. First, cash flows for each company are calculated as after-tax income before extraordinary items, plus depreciation and amortization, plus deferred taxes, minus capital expenditures, for each of the most recent five fiscal years. This sum is then “normalized” by dividing by the sum of net sales for each company in each of most recent five fiscal years, and then multiplying this ratio by the net sales of each respective company in the most recent fiscal year. </p>
    <p>Cash flows for Stage 3 (years 11+) are calculated differently. In Stage 3, after-tax income before extraordinary items alone is used in place of cash flows (as defined in Stages 1 and 2). This is done assuming that over long periods, capital expenditure and depreciation will be the equal (i.e., net investment will be zero). This is consistent with a company ultimately reaching a “steady state” of operations and “[moving] toward the growth rate of the economy as a whole”.  </p>
    <p>Cash flows for Stage 3 are calculated in a multi-step process. First, the sum of income before extraordinary items for each company in each of the most recent five fiscal years is calculated. This sum is then “normalized” by dividing by the sum of net sales for each respective company in the most recent five fiscal years, and then multiplying this ratio by the net sales of each respective company in the most recent fiscal year.</p>
    <p class='helpicon-color'>Composites</p>
    <p>The composite-level 3-Stage DCF cost of equity capital estimate is calculated by aggregation. For illustration purposes, a generic “Composite” will be discussed here, although these same steps are followed for the GICS Composite, the Large Composite, the Small Composite, or a composite of all high-financial-risk companies. </p>
    <p>The net result of the aggregation process (i.e., a cost of equity is estimated for the group of companies – within an industry composite – as a whole as opposed to estimating cost of equity for each company and then combining these estimates into a cost of equity for the group) is that when composites comprised of multiple companies are being calculated, the market-weighted average of the companies’ expected growth rate is used for both Stage 1 and Stage 2. The growth rate used in Stage 3 is the expected growth rate of the economy as a whole, the derivation of which was previously described in the previous section entitled “Derivation of Long-term Growth Rates”. This is done based on the assumption that over time a company’s growth rate will move toward its industry’s growth rate, and ultimately will move toward the growth rate of the economy as a whole. </p>
    <p>Cash flows are used in Stage 1 (years 1–5) and Stage 2 (years 6–10) because many companies do not pay dividends. Cash flows for Stage 1 and Stage 2 are calculated in a multi-step process. First, the sum of income before extraordinary items, plus depreciation and amortization, plus deferred taxes, minus capital expenditures, for all companies in the Composite is calculated for each of the most recent five years. This sum is then “normalized” by dividing by the sum of net sales for all companies in the Composite in each of most recent five fiscal years, and then multiplying this ratio by the sum of net sales for all companies in the Composite in the most recent fiscal year.</p>
    <p>Cash flows for Stage 3 (years 11+) are calculated differently. In Stage 3, after-tax income before extraordinary items alone is used in place of cash flows (as defined in Stages 1 and 2). Over long periods, it can be assumed that capital expenditure and depreciation will be equal (i.e., net investment will be zero). This is consistent with a company ultimately reaching a “steady state” of operations and “[moving] toward the growth rate of the economy as a whole” (see Footnote 77). </p>
    <p>Cash flows for Stage 3 are calculated in a multi-step process. First, the sum of income before extraordinary items for all companies in the Composite in each of the most recent five fiscal years is calculated. This sum is then “normalized” by dividing by the sum of net sales for all companies in the Composite in the most recent five fiscal years, and then multiplying this ratio by the net sales of all companies in the Composite in the most recent fiscal year.</p>`
    };
    public static WACC: Help = {
        title: Categories.Wacc,
        text: `<p>All risks inherent in the assets of the business (i.e., overall business risk) are borne by the investors who provided debt and equity capital. The appropriate rate of return for overall business risk is not generally observable, and therefore must be imputed from the estimated cost of capital for the debt capital and equity capital. This “blended” cost of capital is usually called the weighted average cost of capital, or WACC. The WACC represents the weighted cost for all of the company’s invested capital (both equity and debt). </p>
        <p>WACC can be applied in a single-year capitalization of net cashflows or multi-year discounted net cashflows valuation. It can also be used in valuing a control or minority interest position. The most obvious instance in which to use WACC is when the objective is to value the overall business enterprise. </p>
        <p>WACC can be used in many valuation situations. It is especially appropriate for project selection in capital budgeting. The proportions of debt capital and equity capital that could be available to finance various projects might differ according to the project, and the cost of capital generally should be based on the debt capacity of the specific investment. The WACC can also be used to value highly leveraged companies, but careful adjustments need to be made so that any financial distress risks are appropriately captured. </p>
        <p>Alternatively, WACC can be used even when the objective is ultimately to value only the equity capital. This can be accomplished by valuing the overall business enterprise, and then subtracting the market value of the debt to estimate the value of the equity capital. </p>
        <p><b>WACC Formula</b></p>
        <p>WACC is an after-tax concept (i.e., WACC is based on the cost of each capital structure component net of any corporate-level tax effect of that component). For example, in the United States interest expense on debt is a tax-deductible expense to a corporate taxpayer, and therefore the formula for computing the after-tax WACC for an entity with a three-tier capital structure (common equity, preferred equity, and debt) will make a tax-adjustment for the debt component. </p>
        <p><b>WACC Inputs</b>
            <ul>
                <li><b>Cost of equity capital:</b> There are three methods of cost of equity capital estimation presented in the analyses in the International Industry Benchmarking Module. A WACC estimate is calculated using the cost of equity capital estimated by each of the three models described previously, (in each case) plus an estimate for the cost of preferred capital and the cost of debt capital inputs.
                    Note that for each company (or composite) a single cost of debt capital and a single cost of preferred capital estimate is calculated, unlike the cost of (common) equity capital which is computed using three different methods</li>
                <li><b>Cost of preferred equity capital:</b> Cost of preferred equity capital is calculated for each company used in the analysis in the International Industry Benchmarking Module as preferred dividends in the most recent fiscal year, divided by the number of preferred shares at year-end multiplied by the par or stated value per share as presented in the company's balance sheet.  At the composite-level, this calculation is performed in an aggregative fashion. Cost of preferred equity is used in the calculation of the WACC estimates presented herein, but is not presented as a separate data point. </li>
                <li><b>Cost of debt capital:</b> Cost of debt capital is estimated for each company included in the International Industry Benchmarking Module, the derivation of which was previously described in the section “Cost of Debt”. Cost of debt is presented as a separate data point. </li>
                <li><b>Weights:</b> The weight of each component in the WACC calculation is calculated as the percentage of total capital (common equity, preferred equity, and debt) it represents. See the section “Debt to Total Capital” for a detailed explanation of how total capital is calculated. </li>
                <li><b>Tax rate:</b> The source of company tax rates (used as an input in the calculation of weighted average cost of capital [WACC]) is S&P.</li>
            </ul>
        </p>`
    };
    public static WaccCalculations: Help = {
        title: CategoryName.WaccCalculations,
        text: `<p><b>Media</b></p>
        <p>For each company in the industry, and for each of the three methods of cost of equity capital estimation presented in the International Industry Benchmarking Module, the WACC is first calculated. Then, for each, the median (middle value) WACC is identified.</p>
        <p class='helpicon-color'>Composites</p>
        <p>Composite-level WACC estimates are calculated by using data points previously developed by aggregation.</p>
        <p>For example, to calculate the “Large Composite” WACC estimate using the “CAPM” method, the (i) cost of common equity capital estimate calculated for the “Large Composite” using the “CAPM” method for the given industry is used, in conjunction with (ii) the cost of debt capital and (iii) the cost of preferred equity capital that were calculated for the “Large Composite” for the given industry.</p>
        <p>The weights used in this example are the aggregate weights for (i) common equity capital, (ii) preferred equity capital, and (iii) debt capital for the “Large Composite” for the given industry in relation to aggregate total capital for the “Large Composite” for the given industry. </p>
        <p>Alternatively, to calculate the “Small Composite” WACC estimate using the “CAPM” method, the (i) cost of common equity capital estimate calculated for the “Small Composite” using the “CAPM” method for the given industry is used, in conjunction with (ii) the cost of debt capital and (iii) the cost of preferred equity capital that were calculated for the “Small Composite” for the given industry. </p>
        <p>The weights used in this example are the aggregate weights for (i) common equity capital, (ii) preferred equity capital, and (iii) debt capital for the “Small Composite” for the given industry in relation to aggregate total capital for the “Small Composite” for the given industry.</p>
        <p><b>WACC Graph</b></p>
        <p>The “WACC (%)” horizontal line graph plots the low, high, average, and median of the following three estimates of WACC, at the GICS-Composite level: (i) “CAPM”, (ii) 1-Stage DCF, and (iii) 3-Stage DCF.</p>`
    };
}
