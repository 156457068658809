import { Component, Input } from '@angular/core';
import { Help } from '../help/help.model';
import { HelpService } from '../help/help.service';

@Component({
    selector: 'help-icon',
    templateUrl: './help-icon.component.html'
})
export class HelpIconComponent {
    @Input() public help!: Help;

    public id: string = Math.random().toString(36).substring(2);

    constructor(
        public helpService: HelpService
    ) { }
}
