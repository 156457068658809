import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Gic } from 'src/app/_api/responses/gic.response';
import { Industry } from '../../../_api/responses/us-industry.response';

@Injectable()
export class IndustryDataSharingService {
    private _sharedIndustries = new BehaviorSubject<Industry[]>([]);
    private _sharedGicIndustries = new BehaviorSubject<Gic[]>([]);
    private _sharedBenchmarkingIndustries = new BehaviorSubject<Industry[]>([]);

    public sharedIndustries$ = this._sharedIndustries.asObservable();
    public sharedGicIndustries$ = this._sharedGicIndustries.asObservable();
    public sharedBenchmarkingIndustries$ = this._sharedBenchmarkingIndustries.asObservable();

    set setSharedIndustries(value: Industry[]) {
        this._sharedIndustries.next(value);
    }

    set setSharedGicIndustries(value: Gic[]) {
        this._sharedGicIndustries.next(value);
    }

    set setSharedBenchmarkingIndustries(value: Industry[]) {
        this._sharedBenchmarkingIndustries.next(value);
    }
}
