import { ApiClient } from '@concurrency/angular';
import { Observable } from 'rxjs/internal/Observable';
import { UnleveringTaxRateInputs } from 'src/app/beta/beta.types';
import { environment } from 'src/environments/environment';
import { IntlData, IntlDataUnlevering } from '../responses/intl-data.response';
import { Auth0ApiClient } from '../api.client';

export class IntlTaxRateClient extends Auth0ApiClient {
    protected baseUrl = `${environment.urls.navigatorApi}/api`;

    public read(countryId: number, dataAsOf: string): Observable<IntlData[]> {
        return this.get(`IntlTaxRate?countryId=${countryId}&dataAsOf=${dataAsOf}`);
    }
    public GetTaxRateList(unleveringTaxRateInputs: UnleveringTaxRateInputs[]): Observable<IntlDataUnlevering[]> {
        return this.post(`IntlTaxRate/GetTaxRateList`, unleveringTaxRateInputs);
    }
}
