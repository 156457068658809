import { SummaryOptions } from '../types/summary-options';
import { headerCellHeight, headerFontSize, krollblue, krollshadow, normalCellHeight, normalFontSize } from '../util/summary-helpers';

export function sizeRiskPremiaSummary(options: SummaryOptions, displayNotes: boolean): any {
    const rows: any[] = [];

    const sizeRiskPremiaLabels = {
        height: normalCellHeight,
        cells: [
            { value: 'Study', bold: 'true', fontSize: normalFontSize },
            { value: 'Size/Risk Measure', bold: 'true', fontSize: normalFontSize },
            { value: 'Data Point', bold: 'true', fontSize: normalFontSize },
            { value: 'Value', bold: 'true', fontSize: normalFontSize },
            { value: 'Data as of', bold: 'true', fontSize: normalFontSize },
            { value: 'Size/Risk Input', bold: 'true', fontSize: normalFontSize },
            { value: 'Breakpoints/Regression Equation', bold: 'true', fontSize: normalFontSize },
            options.scenarioHelper.createNoteHeaderRow(displayNotes)
        ]
    };

    return rows.concat([
        {
            height: headerCellHeight,
            cells: [
                {
                    value: 'Size Premia',
                    color: krollblue,
                    fontSize: headerFontSize,
                    bold: 'true',
                    borderBottom: { size: 1, color: krollshadow },
                    colSpan: 8
                }
            ]
        },
        sizeRiskPremiaLabels
    ]).concat(options.scenarioHelper.CRSPDecilesSizePremia(
            options.summary.levered.studyGroups,
            options.summary.riskCRSPCapm,
            options.summary.sizeMeasures,
            displayNotes
        )
      .concat(options.scenarioHelper.CRSPRiskPremiumSizePremia(
                options.summary.levered.studyGroups,
                options.summary.riskRprsCapmSizeStudy,
                options.summary.sizeMeasures,
                displayNotes
            )
        )
      .concat(options.scenarioHelper.CRSPHFRSizePremia(
                options.summary.highFinancialRisk.studyGroups,
                options.summary.riskHFRCapm,
                options.summary.highFinancialRiskZScore,
                displayNotes
            )
        )
        .concat([
            {
                cells: [
                    { value: '' }
                ]
            },
            {
                height: headerCellHeight,
                cells: [
                    {
                        value: 'Risk Premia Over the Risk-free Rate (Levered)',
                        color: krollblue,
                        fontSize: headerFontSize,
                        bold: 'true',
                        borderBottom: { size: 1, color: krollshadow },
                        colSpan: 8
                    }
                ]
            },
            sizeRiskPremiaLabels
        ])
        .concat(options.scenarioHelper.RiskPremiumSizePremiaTypes(
                options.summary.levered.studyGroups,
                options.summary.riskPremiaLevered,
                options.summary.sizeMeasures,
                'Levered',
                displayNotes
            )
        )
        .concat(options.scenarioHelper.OperatingMarginsCoefficients(
                options.summary.buildup3Risks,
                options.summary.riskPremiaCoefficients,
                options.summary.riskMeasuresSummary,
                displayNotes
            )
        )
        .concat(options.scenarioHelper.HFRRiskPremiaLevered(
                options.summary.highFinancialRisk.studyGroups,
                options.summary.riskHFRBuildupHfr,
                options.summary.highFinancialRiskZScore,
                displayNotes
            )
        )
        .concat([
            {
                cells: [
                    { value: '' }
                ]
            },
            {
                height: headerCellHeight,
                cells: [
                    {
                        value: 'Risk Premia Over the Risk-free Rate (Unlevered)',
                        color: krollblue,
                        fontSize: headerFontSize,
                        bold: 'true',
                        borderBottom: { size: 1, color: krollshadow },
                        colSpan: 8
                    }
                ]
            },
            sizeRiskPremiaLabels
        ])
        .concat(options.scenarioHelper.RiskPremiumSizePremiaTypes(
                    options.summary.unlevered.studyGroups,
                    options.summary.riskPremiaUnlevered,
                    options.summary.sizeMeasures,
                    'Unlevered',
                    displayNotes
                )
            )
        .concat([
            {
                cells: [
                    { value: '' }
                ]
            },
            {
                height: headerCellHeight,
                cells: [
                    {
                        value: 'Risk Premia Over the Risk-free Rate (Relevered)',
                        color: krollblue,
                        fontSize: headerFontSize,
                        bold: 'true',
                        borderBottom: { size: 1, color: krollshadow },
                        colSpan: 8
                    }
                ]
            },
            sizeRiskPremiaLabels
        ])
        .concat(options.scenarioHelper.RiskPremiumSizePremiaTypes(
                    options.summary.relevered.studyGroups,
                    options.summary.riskPremiaUnlevered,
                    options.summary.sizeMeasures,
                    'Relevered',
                    displayNotes
                )
            )
    );
}
