import { Categories } from 'src/app/home/tear-sheet/const/categories.const';
import { CategoryName } from 'src/app/home/tear-sheet/enums/category';
import { Help } from './help.model';

export class TearsheetHelptext {
    public static AnnualizedMonthlyPerformanceStatistics: Help = {
        title: Categories.AnnualizedMonthlyPerformanceStatistics,
        text: `<p>Annualized monthly performance statistics (geometric return, arithmetic return, and standard deviation) are calculated using the total returns  of a portfolio comprised of all companies in each industry over the most recent five-year (60 months) period, the most recent three-year (36 months) period, and the most recent one-year (12 months) period as of the most recent month as of the “data through” date. </p>
        <p><b>Portfolio formation</b></p>
        <p>To compute the total returns for a portfolio comprised of each industry’s company set, the portfolio returns are market-capitalization-weighted as follows: the end-of-month market capitalization in the previous month for each company in a specific industry (or composite) is used as the weight for each month in the period being examined (i.e., 60-, 36-, or 12-month periods). </p>
        <p><b>Standard Deviation</b></p>
        <p>For performance benchmarking purposes, the same return statistics are also calculated and presented for the “market” (as represented by the S&P 500 total return index), over the same 60-, 36-, and 12-month periods. </p>`
    };
    public static ReturnRatios: Help = {
        title: Categories.ReturnRatios,
        text: `<p><b>Return on Assets:</b></p>
        <p class='helpicon-color'>Latest </p>
        <p>The “Latest” statistic is calculated as after-tax income before extraordinary items for the most recent fiscal year divided by average total assets for the most recent fiscal year. Average total assets for the most recent fiscal year is calculated as the average of the total assets as of the most recent fiscal year (FY[0]) and the total assets as of the second most recent fiscal year (FY[-1]).</p>
        <p class='helpicon-color'>5-Year Average </p>
        <p>The “5-Year Average” statistic is calculated as the sum of after-tax income before extraordinary items for each of the previous five fiscal years divided by the sum of average total assets calculated for each of the previous five fiscal years. For example, average total assets for the most recent fiscal year is calculated as the average of the total assets as of the most recent fiscal year (FY[0]) and the total assets as of the second most recent fiscal year (FY[-1]). Average total assets for the second most recent fiscal year is calculated as the average of the total assets as of the second most recent fiscal year (FY[-1]) and total assets as of the third most recent fiscal year (FY[-2]), etc. After-tax income before extraordinary (e.g., one-time) items is used in this calculation because after-tax income excluding extraordinary items is likely a better representation of an expected sustainable income stream.</p>
        <p><b>Return on Equity:</b> </p>
        <p class='helpicon-color'>Latest</p>
        <p>The “Latest” statistic is calculated as after-tax income before extraordinary items less preferred stock cash dividends in the most recent fiscal year divided by the average book value of equity for the most recent fiscal year. The average book value of equity for the most recent year is calculated as the average of the book value of equity as of the most recent fiscal year (FY[0]) and the book value of equity as of the second most recent fiscal year (FY[-1]).</p>
        <p class='helpicon-color'>5-Year Average </p>
        <p>The “5-Year Average” statistic is calculated as the sum of after-tax income before extraordinary items less preferred stock cash dividends for each of the previous five fiscal years divided by the sum of average book value of equity calculated for each of the previous five fiscal years. For example, average book value of equity for the most recent fiscal year is calculated as the average of the book value of equity as of the most recent fiscal year (FY[0]) and the book value of equity as of the second most recent fiscal year (FY[-1]). Average book value of equity for the second most recent fiscal year is calculated as the average of the book value of equity as of the second most recent fiscal year (FY[-1]) and the book value of equity as of the third most recent fiscal year (FY[-2]), etc. Again, after-tax income before extraordinary (e.g., one-time) items is also used in this calculation because income excluding extraordinary items is likely a better representation of an expected sustainable income stream.</p>
        <p><b>Dividend Yield:</b> </p>
        <p>The “number of common shares outstanding” data point used is actually a quarterly data point, and so the number of common shares outstanding at the end of the previous quarter is assumed for the three months in the subsequent quarter. For example, the number of common shares outstanding as of the end of March 2014 was used as the number of common shares outstanding in April, May, and June of 2014; the number of common shares outstanding as of the end of June 2014 was used as the number of common shares outstanding in July, August, and September of 2014, etc.</p>
        <p class='helpicon-color'>Latest </p>
        <p>The “Latest” statistic is calculated as the sum of common shares outstanding multiplied by dividends per common share for each of the 12 months ending on the “data through” date, which is then divided by the sum of common shares outstanding multiplied by price per common share for each of the 12 months ending on the “data through” date. <p>
        <p class='helpicon-color'>5-Year Average </p>
        <p>The “5-Year Average” statistic is calculated as the sum of common shares outstanding multiplied by dividends per common share for each of the 60 months ending on the “data through” date, which is then divided by the sum of common shares outstanding multiplied by price per common share for each of the 60 months ending on the “data through” date.</p>`
    };
    public static LiquidityRatio: Help = {
        title: Categories.LiquidityRatio,
        text: `<p><b>Latest </b></p>
        <p>The “Latest” statistic is calculated as current assets in the most recent fiscal year divided by current liabilities in the most recent fiscal year. </p>
        <p><b>5-Year Average </b></p>
        <p>The “5-Year Average” statistic is calculated as the sum of current assets for each of the previous five fiscal years divided by the sum of current liabilities for each of the previous five fiscal years.</p>`
    };
    public static ProfitabilityRatio: Help = {
        title: Categories.ProfitabilityRatio,
        text: `<p><b>Latest </b></p>
        <p>The “Latest” statistic is calculated as operating income after depreciation and amortization in the most recent fiscal year divided by net sales in the most recent fiscal year. </p>
        <p><b>5-Year Average </b></p>
        <p>The “5-Year Average” statistic is calculated as the sum of operating income after depreciation and amortization for each of the previous five fiscal years divided by the sum of net sales for each of the previous five fiscal years.</p>`
    };
    public static GrowthRates: Help = {
        title: Categories.GrowthRates,
        text: `<p><b>Long-Term Earnings Per Share (EPS)</b></p>
        <p>Refinitiv I/B/E/S Estimates database is the source for analysts’ estimates of future earnings growth. Growth rates for long-term earnings are calculated herein on a “Latest” basis only. </p>
        <p class='helpicon-color'>Latest</p>
        <p>Growth rates for long-term earnings are calculated as the market-capitalization-weighted I/B/E/S long-term EPS growth rate. The market capitalization as of the “data through” date is used for the weighting.</p>
        <p>For companies that do not have an I/B/E/S long-term EPS growth rate, the market-capitalization weighted growth rate for the most specific SIC code in which the company appears, and in which there are at least five companies with growth rates, is substituted. For example, if hypothetical Company ABC in GICS 3510 does not have a growth rate, the market-capitalization-weighted growth rate for the other companies in GICS 3510 are used. If GICS 3510 does not have at least five other companies with growth rates, then the calculation is performed using GICS 35 (up one level, and thus less specific), etc.</p>`
    };
    public static BetasLevered: Help = {
        title: Categories.BetasLevered,
        text: `<p>Beta is a measure of the systematic risk of a stock; the tendency of a stock’s price to correlate with changes in the overall market. In all cases in the analyses presented herein: “Excess” total returns of the company (or portfolio) and of the market benchmark are used in the regressions performed to calculate beta.
        <ul>
            <li>A U.S. Treasury 30-day T-bill return is used to calculate monthly “excess” returns (i.e., total returns in excess of the risk-free rate). In other words, in every given month over the time period over which beta is being calculated, the corresponding U.S. 30- day T-bill return for that month is subtracted from the total returns of the company (or portfolio) and from the market benchmark total returns. </li>
            <li>The S&P 500 total return index is used as the “market” benchmark in the regressions performed to calculate beta. </li>
            <li>Beta is calculated over the 60-month period ending with the “data through” date.  </li>
            <li>Betas less than or equal to zero and betas greater than or equal to five are discarded and not included in any further calculations. </li>
            <li>All betas are presented in both “levered” and “unlevered” form: </li>
        </ul>
    </p>
    <p><b>Levered Betas:</b> Published and calculated beta estimates for public stocks typically reflect the capital structure of each respective firm at market values. The historical beta estimates are typically made using realized returns for the subject business's stock and the stock market as a whole as measured by the market index (e.g., the S&P 500 total return index). These betas are sometimes referred to as “levered” betas, since these beta estimates reflect the actual leverage (i.e., debt) in the subject business's capital structure. </p>
    <p>Levered betas for companies and portfolios are calculated in three primary ways: (i) raw ordinary least squares (OLS) beta, (ii) sum beta, and (iii) downside beta.</p>
    <p><b>Raw (OLS) Beta:</b></p>
    <p>Raw (OLS) beta is calculated in this book as a linear regression with a company’s (or portfolio’s) excess monthly total returns (i.e., returns over the risk-free rate) acting as the dependent variable, and the excess monthly total returns of the market benchmark acting as the independent variable.</p>
    <p><b>Sum Beta: </b></p>
    <p>For all but the largest companies, the prices of individual stocks tend to react (in part) to movements in the overall market with a lag. The smaller the company, generally the greater the lag in the price reaction. Sum beta is an attempt to capture more fully the lagged effect of co-movement in a company's returns with returns on the market. Sum beta is calculated in this book as a multiple regression with a company’s (or portfolio’s) excess monthly total returns (i.e., returns over the risk-free rate) acting as the dependent variable, and the market's current month's excess returns and the market's previous month's (i.e., “lagged”) excess returns acting as the independent variables, and then summing the resulting coefficients. </p>
    <p><b>Downside Beta:</b></p>
    <p>The “downside” beta attempts to isolate the downside potential of a security’s returns relative to that of the market’s returns. According to this measure, securities that magnify the market’s upward swings are not necessarily risky; only those that magnify the market’s downward swings are. The assumption made when using downside beta is that when assessing risk, investors are only interested in the relative downside potential with respect to any chosen benchmark. </p>
    <p><b>Peer Group Beta: </b></p>
    <p>“Peer group” betas for composite portfolios are calculated based upon the sales-weighted “full-information” betas of the industries in which all companies in the Industry Composite, Large Composite, Small Composite, or composite of all high-financial-risk companies participate. </p>
    <p>At the composite-level (Industry Composite, Large Composite, Small Composite, or a composite of all high-financial-risk companies for each industry), this calculation is performed by aggregation. For the given set of companies in the composite, a unique list of all SICs/GICS (i.e., industries) from which any company in the set derives sales is first identified. The aggregate sales for each of these industries are then compiled, and, in conjunction with the corresponding full-information betas for the industries from which the company derives sales revenue, a sales-weighted peer group beta is calculated.</p>
    <p><b>Adjusted Betas:</b></p>
    <p>Cost of capital is inherently a forward-looking concept, and thus all of its inputs should also be forward-looking. Betas are generally calculated using historical data, and the betas calculated in this fashion may be “forward-looking” only to the degree that history repeats itself. </p>
    <p>There are several “adjustment” techniques that can be used in an effort to make a historical beta more forward-looking. “Adjusted” betas for companies and portfolios are calculated using two commonly used techniques. The first of these (Blume)  adjusts to the market beta of 1.0; the second of these (Vasicek)  is used to adjust to the industry peer group beta, dependent on the statistical quality of the calculated raw (OLS) beta.<p>
    <p><b>Blume-Adjusted Betas:</b></p>
    <p>This adjustment is sometimes referred to as the “Blume” adjustment, or the “one third/two thirds” adjustment. This technique has been popularized in Bloomberg terminals, even though many users may be unaware of what it really represents. The so-called “Bloomberg Adjusted Beta” is nothing more than the application of the “Blume” adjustment. </p>
    <p>This is a mechanical “one size fits all” adjustment that has the net effect of adjusting historical betas toward the “market” beta of 1.0 (i.e., historical betas that are less than 1.0 are adjusted upward, and historical betas that are greater than 1.0 are adjusted downward). The adjustment is said to create a “forward” (or prospective) estimated beta because this adjustment is based on the assumption that betas tend to move toward the market’s beta (1.0) over time. It does not indicate that any adjustment to the data used in calculating the historical beta estimate was made.</p>
    <p><b>Vasicek-Adjusted Beta:</b></p>
    <p>Another technique for adjusting historical beta estimates is sometimes referred to as Vasicek “shrinkage”. The idea is that betas with low statistical quality (as measured by high standard error) are the least reliable estimates, and therefore should be adjusted toward the industry average (i.e., peer group beta) to a greater degree than betas with high statistical quality (as measured by low standard error).  Because high-beta stocks also tend to have the highest standard errors in their raw betas, they tend to be subject to the most significant adjustment toward their industry average.</p>
    <p>The Vasicek adjustment equation may look complex, but what it accomplishes is a straightforward “sliding scale” between the historical beta and the peer group beta, dependent on the statistical quality (as measured by standard error) of the historical beta.</p>
    <p>The net result is that the more statistically significant the historical beta is (as measured by standard error), the less the adjustment toward the peer beta (i.e., “weight” will be closer to 1); the less statistically significant the historical beta is, the greater the adjustment toward the peer beta (i.e., “weight” will be closer to 0).</p>`
    };
    public static BetasUnlevered: Help = {
        title: Categories.BetasUnlevered,
        text: `<p>Beta is a measure of the systematic risk of a stock; the tendency of a stock’s price to correlate with changes in the overall market. In all cases in the analyses presented herein: “Excess” total returns of the company (or portfolio) and of the market benchmark are used in the regressions performed to calculate beta.
        <ul>
            <li>A U.S. Treasury 30-day T-bill return is used to calculate monthly “excess” returns (i.e., total returns in excess of the risk-free rate). In other words, in every given month over the time period over which beta is being calculated, the corresponding U.S. 30- day T-bill return for that month is subtracted from the total returns of the company (or portfolio) and from the market benchmark total returns. </li>
            <li>The S&P 500 total return index is used as the “market” benchmark in the regressions performed to calculate beta. </li>
            <li>Beta is calculated over the 60-month period ending with the “data through” date.  </li>
            <li>Betas less than or equal to zero and betas greater than or equal to five are discarded and not included in any further calculations. </li>
            <li>All betas are presented in both “levered” and “unlevered” form: </li>
        </ul>
    </p>
    <p><b>Unlevered Betas:</b> Alternatively, “unlevered” betas (also called “asset” betas) have the effect of financial leverage (i.e., debt) removed, thereby reflecting only the effect of business risk. An unlevered beta is the beta that would be expected if a company were financed only with equity capital.</p>
    <p>“Unlevered” betas (also called “asset” betas) have the effect of financial leverage removed, thereby reflecting only the effect of business risk. An unlevered beta is the beta that would be expected if a company were financed only with equity capital (i.e., no debt). </p>
    <p>The (levered) betas calculated in the industry analyses presented herein are as follows: (i) Raw (OLS) Beta, (ii) Blume-adjusted Raw (OLS) Beta, (iii) Peer Group Beta, (iv) Vasicek-adjusted Raw (OLS) Beta, (v) Sum Beta, and (vi) Downside Beta. Each of these levered betas is also presented in “unlevered” form. </p>
    <p>There are several commonly used unlevering methodologies.  In the analyses presented in this book, the “Miles-Ezzell” formulas are used to unlever all beta estimates. </p>
    <p>In the calculations in the main section of this book, debt is defined simply as the “book” value of interest-bearing debt (as a proxy for market value of debt). For example, “book” debt is the debt measure used as an input in the Miles-Ezzell formula to calculate the unlevered betas presented in the main Industry Data Exhibits. Appendices A and B, however, provide additional analysis in which an alternative definition of debt is used that includes “off-balance-sheet” debt (specifically, capitalized operating leases and unfunded pensions). </p>
    <p>Appendix A focuses on the impact of these “debt-like” off-balance-sheet items on the capital structure of each of the industries available in this module, and also identifies which off-balance sheet debt item is the main driver of the change in capital structure (capital operating leases or unfunded pension liabilities) for each industry. Appendix A is sorted by the “impact” of these debtlike off-balance-sheet items on the capital structure (from high to low). </p>
    <p>Appendix B does not focus solely on “impact”, but provides additional statistics (debt-to-equity, debt-to-total-capital, and unlevered betas) calculated using both (i) “book” debt and (ii) debt including off-balance-sheet items. Appendix B is sorted by SIC/GICS code.</p>`
    };
    public static EquValMutliples: Help = {
        title: Categories.EquityValuationMultiples,
        text: `<p><b>Price to Sales:</b></p>
        <p class='helpicon-color'>Latest </p>
        <p>The “Latest” statistic is calculated as total market capitalization of common equity at the end of the most recent month divided by net sales for the most recent fiscal year. </p>
        <p class='helpicon-color'>5-Year Average </p>
        <p>The “5-Year Average” statistic is calculated as the sum of total market capitalization of common equity for each of the previous five fiscal years divided by the sum of net sales for each of the previous five fiscal years. For the 5-Year Average, total market capitalization of common equity is calculated as the sum of the monthly market capitalizations of common equity for each year divided by 12 for each of the most recent five fiscal years.</p>
        <p><b>Price to Earnings:</b></p>
        <p class='helpicon-color'>Latest </p>
        <p>The “Latest” statistic is calculated as total market capitalization of common equity at the end of the most recent month divided by the after-tax income before extraordinary items less preferred dividends for the most recent fiscal year. </p>
        <p class='helpicon-color'>5-Year Average </p>
        <p>The “5-Year Average” statistic is calculated as the sum of total market capitalization of common equity for each of the previous five fiscal years divided by the sum of after-tax income before extraordinary items less preferred dividends for each of the previous five fiscal years. For the 5-Year Average, total market capitalization of common equity is calculated as the sum of the monthly market capitalization of equity for each year divided by 12, for each of the most recent five fiscal years.</p>
        <p><b>Market to Book:</b></p>
        <p class='helpicon-color'>Latest </p>
        <p>The “Latest” statistic is calculated as total market capitalization of common equity at the end of the most recent month divided by book value of common equity (plus net deferred taxes and investment tax credits) for the most recent fiscal year. </p>
        <p class='helpicon-color'>5-Year Average </p>
        <p>The “5-Year Average” statistic is calculated as the sum of total market capitalization of common equity for each of the previous five fiscal years divided by the sum of book value of common equity (plus net deferred taxes and investment tax credits) for each of the previous five fiscal years. For the 5-Year Average, total market capitalization of common equity is calculated as the sum of the monthly market capitalizations of equity for each year divided by 12 for each of the most recent five fiscal years.</p>
        <p><b>NOTE:</b> For valuation dates prior to March 31, 2017, Market-to-Book was calculated for all SICs/GICS, including those SICs beginning in “6” (Finance, Insurance and Real Estate) and GICS beginning in “40” (Financials) and “60” (Real Estate). Starting with data calculated through March 31, 2017, Market-to-Book will continue to be calculated for non-Financial SICs/GICS. Price to Tangible Book replaces Market-to-Book multiple for all Financial SICs/GICS.</p>
        <p><b>Price to Tangible Book:</b></p>
        <p class='helpicon-color'>Latest </p>
        <p>The “Latest” statistic is calculated as total market capitalization of common equity at the end of the most recent month divided by tangible book value of common equity (plus net deferred taxes and investment tax credits) for the most recent fiscal year. </p>
        <p class='helpicon-color'>5-Year Average </p>
        <p>The “5-Year Average” statistic is calculated as the sum of total market capitalization of common equity for each of the previous five fiscal years divided by the sum of tangible book value of common equity (plus net deferred taxes and investment tax credits) for each of the previous five fiscal years. For the 5-Year Average, total market capitalization of common equity is calculated as the sum of the monthly market capitalizations of equity for each year divided by 12 for each of the most recent five fiscal years.</p>
        <p><b>NOTE:</b> Starting with data calculated through March 31, 2017, Price to Earnings Before Taxes replaces the EV to Sales multiple for SICs beginning in “6” (Finance, Insurance and Real Estate) and GICS beginning in “40” (Financials) and “60” (Real Estate). EV to Sales will continue to be calculated for all non-financial SICs/GICS and non-financial companies.</p>
        <p><b>Price to Earnings Before Taxes:</b></p>
        <p class='helpicon-color'>Latest</p>
        <p>The “Latest” statistic is calculated as total market capitalization of common equity at the end of the most recent month divided by the pre-tax income before extraordinary items less special items for the most recent fiscal year. </p>
        <p class='helpicon-color'>5-Year Average </p>
        <p>The “5-Year Average” statistic is calculated as the sum of total market capitalization of common equity for each of the previous five fiscal years divided by the sum of pre-tax income before extraordinary items less special items for each of the previous five fiscal years. For the 5-Year Average, total market capitalization of common equity is calculated as the sum of the monthly market capitalization of equity for each year divided by 12, for each of the most recent five fiscal years.</p>`
    };
    public static EntpValMutliples: Help = {
        title: Categories.EnterpriseValuationMultiples,
        text: `<p>“Enterprise value” is the firm’s value to both equity and bond holders.</p>
        <p><b>EV to Sales:</b></p>
        <p class='helpicon-color'>Latest </p>
        <p>The “Latest” statistic is calculated as enterprise value in the most recent fiscal year divided by net sales in the most recent fiscal year. </p>
        <p>Enterprise value is calculated as total debt, plus total market capitalization of common equity at the end of the most recent month, plus non-controlling interest (a.k.a. minority interest), minus cash and cash equivalents. With the exception of market capitalization of common equity, all other inputs are based on their book values as of the most recent fiscal year. </p>
        <p>Total debt is calculated as the sum of long-term debt, the current portion of long-term debt, notes payable, and preferred stock.  Again, all of these inputs are based on their respective book values as of the most recent fiscal year. </p>
        <p class='helpicon-color'>5-Year Average </p>
        <p>The “5-Year Average” statistic is calculated as the sum of enterprise value for each of the previous five fiscal years divided by the sum of net sales for each of the previous five fiscal years. </p>
        <p>Enterprise value is calculated as total debt, plus total market capitalization of common equity, plus non-controlling interest (a.k.a. minority interest), minus cash and cash equivalents for each of the previous five fiscal years. For the 5-Year Average, total market capitalization of common equity is calculated as the sum of the monthly market capitalizations of equity for each year divided by 12 for each of the most recent five fiscal years. </p>
        <p>Total debt is calculated as the sum of long-term debt, the current portion of long-term debt, notes payable, and preferred stock in each of the previous five fiscal years.</p>
        <p><b>EV to EBITDA: </b></p>
        <p class='helpicon-color'>Latest </p>
        <p>The “Latest” statistic is calculated as enterprise value in the most recent fiscal year divided by EBITDA in the most recent fiscal year. </p>
        <p>Enterprise value is calculated as total debt, plus total market capitalization of common equity capitalization at the end of the most recent month, plus non-controlling interest (a.k.a. minority interest), minus cash and cash equivalents. With the exception of market capitalization of common equity, all other inputs are based on their book values as of the most recent fiscal year. </p>
        <p>Total debt is calculated as the sum of long-term debt, the current portion of long-term debt, notes payable, and preferred stock.  Again, all of these inputs are based on their respective book values as of the most recent fiscal year. </p>
        <p>EBITDA is calculated as earnings before interest, taxes, depreciation and amortization plus nonoperating income (as a proxy for an adjustment for non-recurring items) in the most recent fiscal year.</p>
        <p class='helpicon-color'>5-Year Average </p>
        <p>The “5-Year Average” statistic is calculated as the sum of enterprise value for each of the previous five fiscal years divided by the sum of EBITDA for each of the previous five fiscal years. </p>
        <p>Enterprise value is calculated as total debt, plus total market capitalization of common equity, plus non-controlling interest (a.k.a. minority interest), minus cash and cash equivalents for each of the previous five fiscal years. For the 5-Year Average, total market capitalization of common equity is calculated as the sum of the monthly market capitalization of equity for each year divided by 12 for each of the most recent five fiscal years.</p>
        <p>Total debt is calculated as the sum of long-term debt, the current portion of long-term debt, notes payable, and preferred stock in each of the previous five fiscal years. </p>
        <p>EBITDA is calculated as earnings before interest, taxes, depreciation and amortization plus nonoperating income (as a proxy for an adjustment for non-recurring items) in each of the previous five fiscal years.</p>
        <p><b>Graph – Enterprise Valuation Multiples</b></p>
        <p>The “Enterprise Valuation” (EV) bar graph plots the “Latest” and “5-year Average” EV/Sales and EV/EBITDA multiples for the Industry Composite.</p>`
    };
    public static LeverageRatio: Help = {
        title: Categories.LeverageRatios,
        text: `<p><b>Debt-to-MV Equity:</b></p>
        <p class='helpicon-color'>Latest </p>
        <p>The “Latest” statistic is calculated as the most recent fiscal year total debt divided by total market capitalization of common equity at the end of the most recent month. Total debt is calculated as the sum of long-term debt, the current portion of long-term debt, notes payable, and preferred stock.  Again, all of these inputs are based on their respective book values as of the most recent fiscal year. </p>
        <p class='helpicon-color'>5-Year Average </p>
        <p>The “5-Year Average” statistic is calculated as the sum of total debt for each of the previous five fiscal years divided by the sum of total market capitalization of common equity for each of the previous five fiscal years. Total debt is calculated as the sum of long-term debt, the current portion of long-term debt, notes payable, and preferred stock in each of the previous five fiscal years. In the “5-Year Average” calculation, total market capitalization of common equity is calculated as the sum of the monthly market capitalizations of equity for each year divided by 12 for each of the most recent five fiscal years.</p>
        <p><b>Debt-to-MV Equity:</b></p>
        <p class='helpicon-color'>Latest </p>
        <p>The “Latest” statistic is calculated as the most recent fiscal year total debt divided by total capital at the end of the most recent fiscal year. Total debt is calculated as the sum of long-term debt, the current portion of long-term debt, notes payable, and preferred stock.  Again, all of these inputs are based on their respective book values as of the most recent fiscal year. Total capital is measured as total debt plus total common equity capitalization at the end of the most recent month. </p>
        <p class='helpicon-color'>5-Year Average </p>
        <p>The “5-Year Average” statistic is calculated as the sum of total debt for each of the previous five fiscal years divided by the sum of total capital for each of the previous five fiscal years. Total debt is calculated as the sum of long-term debt, the current portion of long-term debt, notes payable, and preferred stock in each of the previous five fiscal years. Total capital is calculated as total debt plus total market capitalization of common equity in each of the previous five fiscal years. In the “5-Year Average” calculation, total market capitalization of common equity is calculated as the sum of monthly market capitalizations of equity for each year divided by 12 for each of the most recent five fiscal years.</p>
        <p><b>Graph – Latest Capital Structure</b></p>
        <p>The “Latest Capital Structure (%)” pie chart plots the “Latest” debt-to-total-capital and equity-tototal-capital ratios for the Industry Composite. “Equity-to-total-capital” is calculated as (100% – “debt-to-total-capital”).</p>`
    };
    public static CostOfDebts: Help = {
        title: Categories.CostOfDebt,
        text: `<p>A cost of debt estimate is calculated for each company based upon (i) the S&P credit rating for the company, or (ii) for companies that do not have an S&P credit rating, a long-term credit score from S&P Global Market Intelligence Credit Analytics is substituted. ,  For companies without an S&P credit rating, or a credit score from S&P Global Market Intelligence Credit Analytics, an average credit rating for the most specific SIC code in which the company appears, and in which there are at least five companies with an S&P credit rating and credit score from S&P Global Market Intelligence Credit Analytics, is substituted. For example, if hypothetical Company ABC in SIC 3714 does not have a credit rating, the average credit rating for the other companies in SIC 3714 are used. If SIC 3714 does not have at least five other companies with credit ratings, then the calculation is performed using SIC 371 (up one level, and thus less specific), etc. </p>
        <p>The credit rating for each is then mapped to 20-year corporate bond yields as of the “data through” date (AAA, AA, A, BBB, BB, B, and CCC). ,   </p>
        <p>Cost of debt is calculated herein on a “Latest” basis only. The median cost of debt is calculated as the middle value of all individual companies’ cost of debt estimates. The cost of debt for the “SIC/GICS Composite”, the “Large Composite”, the “Small Composite”, and a composite comprised of all companies in the “High-Financial-Risk” category is calculated as the debt-weighted average yield of all individual companies’ cost of debt estimates.</p>`
    };
    public static CostOfEquityCapital: Help = {
        title: Categories.CostOfEquityCapital,
        text: `<p>The long-term equity risk premium (ERP) and long-term risk-free rate (Rf) used in all cost of capital calculations presented herein are the Kroll recommended ERP as of the “data through” date, used in conjunction with a normalized risk-free rate as of the “data through” date.  </p>
        <p>The source of valuation data (ERPs, size premia, and risk premia over the risk-free rate) used to calculate the cost of capital estimates presented herein is the Cost of Capital Navigator – U.S. Cost of Capital Module. The U.S. Cost of Capital Module includes two size premium studies: The CRSP Deciles Size Study data and the Risk Premium Report Study data. </p>
        <p>The cost of equity capital estimates using the CRSP Deciles Size Study and the Risk Premium Report Study are each developed using two different estimation models in the analyses presented herein:
            <ul>
                <li>Capital Asset Pricing Model (CAPM): The CAPM has evolved to become the preferred method to estimate the cost of equity capital in both classroom settings and by financial markets. The CAPM is now the most widely used method for estimating the cost of equity capital. </li>
                <li>Build-up Method: The build-up method is an additive model commonly used for calculating the required rate of return on equity capital. As the name implies, successive “building blocks” are summed, each representing the additional risk inherent to investing in alternative assets. </li>
            </ul>
        </p>
        </p>Cost of capital estimates using the CRSP Deciles Size Study use “Vasicek-adjusted” OLS betas and OLS-beta-derived size premia (where applicable). </p>
        <p>Cost of capital estimates using the Risk Premium Report Study use sum betas and sum-beta derived size premia (where applicable). </p>
        <p>All cost of capital estimates (and their inputs) are based on data from publicly-traded U.S. companies. </p>
        <p>All cost of capital estimates are after corporate taxes, before personal taxes, and before any other company-specific adjustments (other than size) that the individual valuation analyst may deem necessary.</p>
        <p><b>CAPM</b></p>
        <p class='helpicon-color'>Median</p>
        <p>The median “base” CAPM cost of equity estimate is calculated differently from how the “base” CAPM cost of equity estimates for the composites (described in next section) are calculated. The median calculation is a multi-step process. </p>
        <p>First, a 60-month raw (OLS) beta is calculated for each company in the industry, as described previously in the section “Raw (OLS) Beta”. </p>
        <p>Second, a “peer group” beta is calculated based upon the sales-weighted “full-information” betas of the industries in which all the companies included in the subject industry participate, as described previously in the section “Peer Betas”.  </p>
        <p>Third, the 60-month raw (OLS) beta for each company in the industry is then adjusted toward the peer group beta using the “Vasicek” adjustment, as described previously in the section “Vasicek Adjusted Beta”. </p>
        <p>Fourth, the Vasicek-adjusted betas calculated in the previous step for each company is substituted into the CAPM equation to determine a base cost of equity capital estimate for each company in the industry. </p>
        <p>Finally, the median (middle value) base cost of equity estimate is identified. </p>
        <p class='helpicon-color'>Composites</p>
        <p>The composite-level “base” CAPM cost of equity estimate is calculated by aggregation. For illustration purposes, a generic “Composite” will be discussed here, although these same steps are followed for the Industry Composite, the Large Composite, the Small Composite, or a composite of all high-financial-risk companies. The composite-level calculation is also a multi-step process.</p>
        <p>First, the market-cap-weighted total returns for a portfolio comprised of all of the companies in the Composite are calculated, as described previously in the section “Portfolio Formation”. </p>
        <p>Second, a 60-month raw (OLS) beta for the portfolio is calculated using the market-cap-weighted portfolio total returns calculated in the previous step. </p>
        <p>Third, a “peer group” beta is calculated based upon the sales-weighted “full-information” betas of the industries in which all the companies included in the Composite participate, as described previously in the section “Peer Betas”. </p>
        <p>Fourth, the 60-month raw (OLS) beta for the Composite is adjusted toward the peer group beta using the “Vasicek” adjustment, as described previously in the section “Vasicek-Adjusted Beta”. </p>
        <p>Fifth, the Vasicek-adjusted Composite beta calculated in the previous step is substituted into the CAPM equation to determine a base cost of equity capital estimate for the Composite.</p>
        <p><b>Cost of Equity Capital Estimates – Using the CRSP Deciles Size Study </b></p>
        <p>Two cost of equity capital estimates are presented in the analyses herein using the CRSP Deciles Size Study: (i) CAPM plus a size premium (RPs), and (ii) a “build-up” method that starts with the risk-free rate (Rf) and equity risk premium (ERP, or RPm) and adds a size premium (RPs) and an industry risk premium (RPi).</p>
        <p>All size premia used in the calculation of “CRSP Deciles Size Study – Cost of Equity Capital Estimates” are for CRSP standard market-cap-weighted NYSE/AMEX/NASDAQ Deciles 1–10 sourced from the CRSP Deciles Size Study in the Cost of Capital Navigator – U.S. Cost of Capital Module for the most recent year-end date as of the “data through” date.</p>
        <p>All industry risk premia used in these calculations are sourced from the Industry Risk Premia (RPi) from the Cost of Capital Navigator – U.S. Cost of Capital Module.</p>
        <p><b>CAPM + Size Premium</b></p>
        <p>These cost of equity capital estimates are developed using the capital asset pricing model (CAPM), with an adjustment for “size”. </p>
        <p class='helpicon-color'>Median</p>
        <p>For each company in the industry, the appropriate size premium is identified based on each company’s market capitalization at the end of the most recent month as of the “data through” date using the size premia as shown in the Cost of Capital Navigator, under the CRSP Deciles Size Study. </p>
        <p>Then, the size premium for each company (as identified in the previous step) and the Vasicek adjusted beta calculated for each company (as previously described in the previous section “CAPM, Median”), are substituted into the CAPM equation to determine a cost of equity capital estimate (adjusted for size) for each company in the industry.</p>
        <p>Finally, the median (middle value) cost of equity capital estimate (adjusted for size) is identified. </p>
        <p class='helpicon-color'>Composites</p>
        <p>The composite-level CAPM cost of equity estimate with an adjustment for size is calculated by aggregation. For example purposes, a generic Composite will be discussed here, although these same steps are followed for the Industry Composite, the Large Composite, the Small Composite, or a composite of all high-financial-risk companies. The composite-level calculation is also a multi-step process. </p>
        <p>The first step in the Composite calculation is identical to the first step in the “Median” calculation: for each company in the industry, the appropriate size premium is identified based on each company’s market capitalization at the end of the most recent month. </p>
        <p>In the second step, a market-capitalization-weighted Composite size premium is calculated using the market capitalization of the individual companies as of the end of the most recent month. </p>
        <p>The Vasicek-adjusted Composite beta (as previously described in the section entitled “CAPM, Composites”) is also used as the beta in the calculation of the “CAPM + Size Premium” composites. </p>
        <p>Finally, the market-capitalization-weighted Composite size premium and the Vasicek-adjusted Composite beta are substituted into the CAPM + Size Premium equation to determine a cost of equity capital estimate (adjusted for size) for the Composite. </p>
        <p><b>Build-up Method</b></p>
        <p class='helpicon-color'>Median</p>
        <p>The industry risk premium (RPi) used here is the 2-digit SIC / 4-digit GICS level industry risk premium for the given industry calculated as of the most recent month as of the “data through” date.</p>
        <p> For each company in the industry, the appropriate size premium for each company in the industry is identified based on each company’s market capitalization at the end of the most recent month, using the size premia from the CRSP Deciles Size Study in the online Cost of Capital Navigator. </p>
        <p>Then, the size premium (RPs) for each company (as identified in the previous step) and the industry risk premium (RPi) are substituted into the build-up equation to determine a “build-up” cost of equity capital estimate for each company in the industry. </p>
        <p>Finally, the median (middle value) build-up cost of equity capital estimate is identified. </p>
        <p class='helpicon-color'>Composites</p>
        <p>The industry risk premium (RPi) used here is the 2-digit SIC / 4-digit GICS level industry risk premium for the given industry calculated as of the most recent month as of the “data through” date.</p>
        <p>The composite-level build-up cost of equity estimate is calculated by aggregation. For illustration purposes, a generic Composite will be discussed here, although these same steps are followed for the Industry Composite, the Large Composite, the Small Composite, or a composite of all high-financial risk companies. The composite-level calculation is also a multi-step process. </p>
        <p>The first step in the Composite calculation is identical to the first step in the “Median” calculation: for each company in the industry, the appropriate size premium is identified based on each company’s market capitalization at the end of the most recent month. </p>
        <p>In the second step, a market-capitalization-weighted Composite size premium is calculated using the market capitalization of the individual companies as of the end of the most recent month. </p>
        <p>Finally, the market-capitalization-weighted Composite size premium and the industry risk premium (RPi) are substituted into the build-up equation to determine a Composite build-up cost of equity capital estimate.</p>
        <p><b>Cost of Equity Capital Estimates – Using the Risk Premium Report Study </b></p>
        <p>Unlike the CRSP Deciles Size Study, which measures size based solely on market capitalization, the Risk Premium Report Study measures size based on eight different measures, all of which have been shown to be good proxies for size as a predictor of returns: (i) market value of equity (i.e., market capitalization), (ii) book value of equity, (iii) 5-year average net income, (iv) market value of invested capital (MVIC), (v) total assets, (vi) 5-year average EBITDA, (vii) sales, and (viii) number of employees. </p>
        <p>Two cost of equity capital estimates are presented in the analyses herein using the Risk Premium Report Study: (i) CAPM plus a size premium (RPs), and (ii) a “build-up” method that starts with the risk-free rate (Rf) and adds a “risk premium over the risk-free rate” (RPm+s). </p>
        <p>All size premia (RPs) used in the calculation of “Cost of Equity Capital Estimates – Using the Risk Premium Report Study” are for portfolios 1–25 from the Risk Premium Report Study in the online Cost of Capital Navigator. </p>
        <p>All “risk premium over the risk-free rate” (RPm+s) used in the calculation of “Cost of Equity Capital Estimates – Using the Risk Premium Report Study” are for portfolios 1–25 from the Risk Premium Report Study in the online Cost of Capital Navigator.</p>
        <p><b>CAPM + Size Premium</b></p>
        <p>These cost of equity capital estimates are developed using the capital asset pricing model (CAPM), with an adjustment for “size”.</p>
        <p class='helpicon-color'>Median</p>
        <p>For each company in the industry, the appropriate size premium for each of the eight measures of size analyzed in the Risk Premium Report Study is identified using the Risk Premium Report’s “Guideline Portfolio Method”.  This identification is based upon the respective “size” of each company as measured by (i) market value of equity (i.e., market capitalization), (ii) book value of equity, (iii) 5-year average net income, (iv) market value of invested capital (MVIC), (v) total assets, (vi) 5-year average EBITDA, (vii) Sales, and (viii) number of employees.  The median of these size premia for each company is then identified.</p>
        <p>Then, the (median) size premium for each company (as identified in the previous step) and the sum beta calculated for each company (as previously described in the section “Sum Beta”), are substituted into the CAPM equation to determine a cost of equity capital estimate (adjusted for size) for each company in the industry. </p>
        <p>Finally, the median (middle value) cost of equity capital estimate (adjusted for size) is identified.</p>
        <p class='helpicon-color'>Composites</p>
        <p>The composite-level CAPM cost of equity estimate with an adjustment for size is calculated by aggregation. For illustration purposes, a generic “Composite” will be discussed here, although these same steps are followed for the Industry Composite, the Large Composite, the Small Composite, or a composite of all high-financial-risk companies. The composite-level calculation is also a multi-step process. </p>
        <p>The first step in the Composite calculation is identical to the first step in the Median calculation: for each company in the industry, the appropriate size premium for each of the eight measures of size analyzed in the Risk Premium Report Study is identified using the Risk Premium Report’s “Guideline Portfolio Method”. This identification is based upon the respective “size” of each company as measured by (i) market value of equity (i.e., market capitalization), (ii) book value of equity, (iii) 5- year average net income, (iv) market value of invested capital (MVIC), (v) total assets, (vi) 5-year average EBITDA, (vii) sales, and (viii) number of employees. The median of these size premia for each company is then identified. </p>
        <p>In the second step, a market-capitalization-weighted Composite size premium is calculated using the individual (median) company size premia identified in the previous step and the market capitalization of the individual companies as of the end of the most recent month. </p>
        <p>In the third step, market-capitalization-weighted portfolios are created for the Composite as described previously in the section “Portfolio Formation”, and the total returns of these portfolios are used to calculate a sum beta for the portfolio, as described previously in the section “Sum Beta”. </p>
        <p>Finally, the market-capitalization-weighted Composite size premium and the Composite portfolio sum beta are substituted into the CAPM + Size Premium equation to determine a cost of equity capital estimate (adjusted for size) for the Composite.</p>
        <p><b.Build-up 1 Method</b></p>
        <p>The build-up method is an additive model commonly used for calculating the required rate of return on equity capital. As the name implies, successive “building blocks” are summed, each representing the additional risk inherent to investing in alternative assets. The name Build-up “1” is used here merely to differentiate this particular build-up method from the other build-up methods available when using the Risk Premium Report Study.</p>
        <p class='helpicon-color'>Median</p>
        <p>For each company in the industry, the appropriate “risk premium over the risk-free rate” (RPm+s) for each of the eight measures of size analyzed in the Risk Premium Report Study exhibits is identified using the Risk Premium Report’s “Guideline Portfolio Method”.  This identification is based upon the respective “size” of each company as measured by (i) market value of equity (i.e., market capitalization), (ii) book value of equity, (iii) 5-year average net income, (iv) market value of invested capital (MVIC), (v) total assets, (vi) 5-year average EBITDA, (vii) Sales, and (viii) number of employees.  The median of these “risk premia over the risk-free rate” (RPm+s) for each company is then identified, and added to the normalized risk-free rate. This sum is the cost of equity capital for each respective company. </p>
        <p>Finally, the median (middle value) cost of equity capital estimate is identified.</p>
        <p class='helpicon-color'>Composite</p>
        <p>The composite-level “Build-up 1” cost of equity estimate is calculated by aggregation. For illustration purposes, a generic “Composite” will be discussed here, although these same steps are followed for the Industry Composite, the Large Composite, the Small Composite, or a composite of all high-financial-risk companies. The composite-level calculation is also a multi-step process. </p>
        <p>The first step in the Composite calculation is identical to the first step in the Median calculation: for each company in the industry, the appropriate “risk premium over the risk free rate” for each of the eight measures of size analyzed in the Risk Premium Report Study is identified using the Risk Premium Report’s “Guideline Portfolio Method”. This identification is based upon the respective “size” of each company as measured by (i) market value of equity (i.e., market capitalization), (ii) book value of equity, (iii) 5-year average net income, (iv) market value of invested capital (MVIC), (v) total assets, (vi) 5-year average EBITDA, (vii) sales, and (viii) number of employees. The median of these “risk premia over the risk-free rate” for each company is then identified. </p>
        <p>In the second step, a market-capitalization-weighted Composite “risk premium over the risk-free rate” is calculated using the market capitalization of the individual companies as of the end of the most recent month. </p>
        <p>Finally, the market-capitalization-weighted Composite “risk premium over the risk-free rate” (RPm+s) is added to the normalized risk-free rate. This sum is the cost of equity capital estimate for the Composite.</p>
        <p><b>Discounted Cash Flow (DCF) Models </b></p>
        <p class='helpicon-color'>1-Stage DCF</p>
        <p>The 1-Stage DCF model presented in this book is essentially the constant growth model, commonly known as the Gordon Growth Model.  The constant growth model is a method of “capitalizing” cash flows. In capitalizing, the focus is on the cash flow of just a single period, as opposed to projecting the multiple expected cash flows from the subject investment over the life of the investment.</p>
        <p>The growth rates used are based on each company’s expected growth rate from Thomson Reuters I/B/E/S Consensus Estimates.  If a company (or portfolio) paid no dividends in the 12 months ending in the most recent month, the cost of equity equals the growth rate.</p>
        <p class='helpicon-color'>Median</p>
        <p>For each company in the industry, the cost of equity capital using the 1-Stage DCF model is first calculated. Then, the median (middle value) is identified. </p>
        <p class='helpicon-color'>Composite</p>
        <p>The composite-level 1-Stage DCF cost of equity capital estimate is calculated by aggregation. For illustration purposes, a generic Composite will be discussed here, although these same steps are followed for the Industry Composite, the Large Composite, the Small Composite, or a composite of all high-financial-risk companies. The aggregate growth rate is the market-capitalization-weighted growth rate of all companies in the Composite, based upon the market capitalization of each company as of the end of the most recent month. Aggregate dividends are the sum of all dividends for each company in the Composite in the 12 months ending in the most recent month. Price per share is the sum of the price per common share at the end of the most recent month for each company in the Composite.</p>
        <p class='helpicon-color'>3-Stage DCF</p>
        <p>For most equity investments, it is probably not reasonable to expect a constant growth rate into perpetuity, as is assumed in the preceding 1-stage DCF model. This dilemma is typically dealt with by the use of “multi-stage” DCF models.</p>
        <p>Solving for the cost of equity capital using the 3-stage DCF model presented herein is accomplished in all cases through an iterative process that equates the present value of all expected future cash flows for an individual company (or composite) to the market capitalization of the company (or composite) in the most recent month, dependent on the discount rate.</p>
        <p class='helpicon-color'>Median</p>
        <p>For each company in the industry, the cost of equity capital using the 3-Stage DCF model is first calculated. Then, the median (middle value) cost of equity capital estimate is identified. </p>
        <p>At the individual-company level, the growth rate used in Stage 1 is based on each company’s expected growth rate from the Thomson Reuters I/B/E/S Consensus Estimates database. The growth rate used in Stage 2 is the expected industry growth rate, measured as the market capitalization-weighted growth rate of all companies in the industry as of the most recent month. The growth rate used in Stage 3 is the rounded expected growth rate of the economy as a whole , the derivation of which was previously described in the section “Growth Rates”. This is done based on the assumption that over time a company’s growth rate will move toward its industry’s growth rate, and ultimately will move toward the growth rate of the economy as a whole.</p>
        <p>Cash flows are used in Stage 1 (years 1–5) and Stage 2 (years 6–10) because many companies do not pay dividends. Cash flows for Stage 1 and Stage 2 are calculated in a multi-step process. First, cash flows for each company are calculated as after-tax income before extraordinary items, plus depreciation and amortization, plus deferred taxes, minus capital expenditures, for each of the most recent five fiscal years. This sum is then “normalized” by dividing by the sum of net sales for each company in each of most recent five fiscal years, and then multiplying this ratio by the net sales of each respective company in the most recent fiscal year. </p>
        <p>Cash flows for Stage 3 (years 11+) are calculated differently. In Stage 3, after-tax income before extraordinary items alone is used in place of cash flows (as defined in Stages 1 and 2). This is done assuming that over long periods, capital expenditure and depreciation will be equal (i.e., net investment will be zero). This is consistent with a company ultimately reaching a “steady state” of operations and “[moving] toward the growth rate of the economy as a whole”.  </p>
        <p>Cash flows for Stage 3 are calculated in a multi-step process. First, the sum of income before extraordinary items for each company in each of the most recent five fiscal years is calculated. This sum is then “normalized” by dividing by the sum of net sales for each respective company in the most recent five fiscal years, and then multiplying this ratio by the net sales of each respective company in the most recent fiscal year.</p>
        <p class='helpicon-color'>Composite</p>
        <p>The composite-level 3-Stage DCF cost of equity capital estimate is calculated by aggregation. For illustration purposes, a generic “Composite” will be discussed here, although these same steps are followed for the Industry Composite, the Large Composite, the Small Composite, or a composite of all high-financial-risk companies.</p>
        <p>The net result of the aggregation process (i.e., a cost of equity is estimated for the group of companies – within an industry Composite – as a whole as opposed to estimating cost of equity for each company and then combining these estimates into a cost of equity for the group) is that when composites comprised of multiple companies are being calculated, the market-weighted average of the companies’ expected growth rate is used for both Stage 1 and Stage 2. The growth rate used in Stage 3 is the rounded expected growth rate of the economy as a whole (3.0%), the derivation of which was previously described in the section “Growth Rates”. This is done based on the assumption that over time a company’s growth rate will move toward its industry’s growth rate, and ultimately will move toward the growth rate of the economy as a whole. </p>
        <p>Cash flows are used in Stage 1 (years 1–5) and Stage 2 (years 6–10) because many companies do not pay dividends. Cash flows for Stage 1 and Stage 2 are calculated in a multi-step process. First, the sum of income before extraordinary items, plus depreciation and amortization, plus deferred taxes, minus capital expenditures, for all companies in the Composite is calculated for each of the most recent five years. This sum is then “normalized” by dividing by the sum of net sales for all companies in the Composite in each of most recent five fiscal years, and then multiplying this ratio by the sum of net sales for all companies in the Composite in the most recent fiscal year. </p>
        <p>Cash flows for Stage 3 (years 11+) are calculated differently. In Stage 3, after-tax income before extraordinary items alone is used in place of cash flows (as defined in Stages 1 and 2). Over long periods, it can be assumed that capital expenditure and depreciation will be equal (i.e., net investment will be zero). This is consistent with a company ultimately reaching a “steady state” of operations and “[moving] toward the growth rate of the economy as a whole” (see Footnote 77). </p>
        <p>Cash flows for Stage 3 are calculated in a multi-step process. First, the sum of income before extraordinary items for all companies in the Composite in each of the most recent five fiscal years is calculated. This sum is then “normalized” by dividing by the sum of net sales for all companies in the Composite in the most recent five fiscal years, and then multiplying this ratio by the net sales of all companies in the Composite in the most recent fiscal year.</p>
        <p class='helpicon-color'>Fama-French (F-F) 5-Factor Model </p>
        <p>For Valuation Dates of March 31, 2014 through March 30, 2015, cost of equity capital estimates using the Fama-French 3-Factor Model are presented.  </p>
        <p>Starting with March 31, 2015, cost of equity capital estimates using the Fama-French 5-Factor Model are presented.  </p>
        <p>The F-F 5-Factor Model cost of equity estimates for each industry are calculated using (i) a “normalized” long-term risk-free rate, (ii) a long-term equity risk premium, (iii) the F-F Beta calculated and presented in “Fama-French (F-F) Components”,  (iv) the SMB Premium calculated and presented in “Fama-French (F-F) Components”, (v) the HMB Premium calculated and presented in “Fama-French (F-F) Components”, (vi) the RMW Premium calculated and presented in “Fama-French (F-F) Components”, and (vii) the CMA Premium calculated and presented in “Fama-French (F-F) Components”. </p>
        <p><b>Graph — Cost of Equity Capital </b></p>
        <p>The “Cost of Equity Capital (%)” bar graph plots the Industry Composite cost of equity estimates in five different ways: (i) the simple average of the CRSP Deciles Size Study “CAPM + Size Prem” and “Build-up” estimates, (ii) the simple average of the Risk Premium Report “CAPM + Size Prem” and “Build-up 1” estimates, (iii), the “Discounted Cash Flow” 1-stage estimate, (iv) the “Discounted Cash Flow” 3-stage estimate, and (v) the Fama-French 5-Factor Model estimate.</p>`
    };
    public static WACC: Help = {
        title: Categories.Wacc,
        text: `<p>All risks inherent in the assets of the business (i.e., overall business risk) are borne by the investors who provided debt and equity capital. The appropriate rate of return for overall business risk is not generally observable, and therefore must be imputed from the cost of capital for the debt capital and equity capital. This “blended” cost of capital is usually called the weighted average cost of capital, or WACC. The WACC represents the weighted cost for all of the company’s invested capital (both equity and debt).</p>
        <p>WACC can be applied in a single-year capitalization of net cashflows or multi-year discounted net cashflows valuation. It can also be used in valuing a control or minority interest position. The most obvious instance in which to use WACC is when the objective is to value the overall business enterprise. </p>
        <p>WACC can be used in many valuation situations. It is especially appropriate for project selection in capital budgeting. The proportions of debt capital and equity capital that could be available to finance various projects might differ according to the project, and the cost of capital generally should be based on the debt capacity of the specific investment. The WACC can also be used to value highly leveraged companies, but careful adjustments need to be made, so that any financial distress risks are appropriately captured. </p>
        <p>Alternatively, WACC can be used even when the objective is ultimately to value only the equity capital. This can be accomplished by valuing the overall business enterprise, and then subtracting the market value of the debt to estimate the value of the equity capital. </p>
        <p><b>WACC Formula</b></p>
        <p>WACC is an after-tax concept (i.e., WACC is based on the cost of each capital structure component net of any corporate-level tax effect of that component). For example, in the United States interest expense on debt is a tax-deductible expense to a corporate taxpayer, and therefore the formula for computing the after-tax WACC for an entity with a three-tier capital structure (common equity, preferred equity, and debt) will make a tax-adjustment for the debt component. </p>
        <p>WACC Inputs
            <ul>
                <li><b>Cost of equity capital:</b> There are eight methods of cost of equity capital estimation presented in the analyses in this book. A WACC estimate is calculated using the cost of equity capital estimated by each of the eight models described previously (in each case), plus an estimate for the cost of preferred capital and the cost of debt capital inputs. <b>Note</b> that for each company (or composite) a single cost of debt capital and a single cost of preferred capital estimate is calculated, unlike the cost of (common) equity capital which is computed using eight different methods. Therefore, the only item that varies across each company’s (or composite’s) WACC calculation is the cost of equity capital estimated using each of the eight respective cost of equity capital methods presented herein, as summarized in Exhibit 10.</li>
                <li><b>Cost of preferred equity capital:</b> Cost of preferred equity capital is calculated for each company used in the analysis in this book as preferred dividends in the most recent fiscal year, divided by the number of preferred shares at year-end multiplied by the par or stated value per share as presented in the company's balance sheet.85 At the composite-level, this calculation is performed in an aggregative fashion. Cost of preferred equity is used in the calculation of the WACC estimates presented herein, but is not presented as a separate data point.</li>
                <li><b>Cost of debt capital:</b> Cost of debt capital is estimated for each company included in this book, the derivation of which was previously described in the section “Cost of Debt”. Cost of debt is presented as a separate data point. </li>
                <li><b>Weights:</b> The weight of each component in the WACC calculation is calculated as the percentage of total capital (common equity, preferred equity, and debt) it represents. See the section “Debt to Total Capital” for a detailed explanation of how total capital is calculated.  </li>
                <li><b>Tax rate:</b> The company tax rates used herein are provided by Professor John Graham as explained under “Data Sources, Company Tax Rates”.</li>
            </ul>
        </p>`
    };
    public static FamaFrenchFiveFactor: Help = {
        title: Categories.FamaFrench5FactorModel,
        text: `<p>For valuation dates of March 31, 2014 through March 30, 2015, cost of equity capital estimates nd their underlying components using the Fama-French 3-Factor Model are presented. ,   </p>
        <p>The Fama-French 3-Factor Model is based on the seminal work of Professors Eugene Fama and Kenneth French, which focused on finding which risk factors could help explain realized equity returns. Their empirical study found that besides market betas, other risk factors such as size and “value” (captured through ratios such as earnings-to-price, debt-to-equity, and book-to-market, the latter being defined as book value of equity divided by market value of equity), could all help in explaining realized equity returns. </p>
        <p>Starting with the data calculated through March 31, 2015, cost of equity capital estimates and its underlying components using the Fama-French 5-Factor Model are presented.  The Fama-French 5-Factor Model includes the same three factors employed in the 3-Factor Model (market, SMB and HML; see below for definitions), plus two additional risk factors (RMW and CMA; see below for definitions). </p>
        <p>The Fama-French 5-Factor Model is based on a multiple regression in which a company’s (or portfolio’s) excess monthly total returns (i.e., returns over the risk-free rate) are used as the dependent variable.</p>
        <p><b>There are five independent variables (or risk factors): </b></p>
        <p><b>Excess “market” returns:</b> The excess monthly total returns of the market benchmark (in this book, the S&P 500 total return index is used as the market benchmark).  </p>
        <p><b>SMB (small minus big) returns:</b> The difference between the monthly returns on diversified portfolios comprised of “small” company stocks and “big” (i.e., large) company stocks, as measured by market capitalization. </p>
        <p>HML (high minus low) returns: The difference between the monthly returns on diversified portfolios comprised of “value” company stocks (high book-to-market) and “growth” company stocks (low book-to-market).</p>
        <p><b>RMW (robust minus weak) returns:</b> The difference between the monthly returns on diversified portfolios comprised of company stocks with “robust” profitability and “weak” profitability. </p>
        <p><b>CMA (conservative minus aggressive) returns:</b> The difference between the monthly returns on diversified portfolios comprised of company stocks of low and high investment firms, which Fama and French define as “conservative” and “aggressive”, respectively.</p>
        <p>The market coefficient (i.e., “F-F beta”) is presented in this book. The F-F beta should not to be confused with the CAPM beta.</p>`
    };
    public static WaccCalculations: Help = {
        title: CategoryName.WaccCalculations,
        text: `<p><b>Median</b></p>
        <p>For each company in the industry, and for each of the eight methods of cost of equity capital estimation presented in this book, the WACC is first calculated. Then, for each, the median (middle value) WACC is identified.</p>
        <p><b>Composites</b></p>
        <p>Composite-level WACC estimates are calculated by using data points previously developed by aggregation. </p>
        <p>For example, to calculate the “Large Composite” WACC estimate using the CRSP Deciles “CAPM + Size Premium” method, the (i) cost of common equity capital estimate calculated for the “Large Composite” using the CRSP Deciles “CAPM + Size Premium” method for the given industry is used, in conjunction with (ii) the cost of debt capital and (iii) the cost of preferred equity capital that were calculated for the “Large Composite” for the given industry. </p>
        <p>The weights used in this example are the aggregate weights for (i) common equity capital, (ii) preferred equity capital, and (iii) debt capital for the “Large Composite” for the given industry in relation to aggregate total capital for the “Large Composite” for the given industry. </p>
        <p>Alternatively, to calculate the “Small Composite” WACC estimate using the Risk Premium Report “Build-up” method, the (i) cost of common equity capital estimate calculated for the “Small Composite” using the Risk Premium Report “Build-up” method for the given industry is used, in conjunction with (ii) the cost of debt capital and (iii) the cost of preferred equity capital that were calculated for the “Small Composite” for the given industry.</p>
        <p>The weights used in this example are the aggregate weights for (i) common equity capital, (ii) preferred equity capital, and (iii) debt capital for the “Small Composite” for the given industry in relation to aggregate total capital for the “Small Composite” for the given industry.</p>
        <p><b>Graph - WACC</b></p>
        <p>The “WACC (%)” horizontal line graph plots the average and median of the following seven estimates of WACC, at the SIC-Composite level: (i) CRSP Deciles Size Study “CAPM + Size Prem” estimate, (ii) the CRSP Deciles Size Study “Build-up” estimate, (iii) the Risk Premium Report “CAPM + Size Prem” estimate, (iv) the Risk Premium Report “Build-up” estimate, (v) the “Discounted Cash Flow” 1-stage estimate, (vi) the “Discounted Cash Flow” 3-stage estimate, and (vii) the Fama-French 5-Factor Model estimate. The “Low” and “High” of those seven estimates are also plotted. </p>`
    };
}
