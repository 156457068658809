import { Component, Input } from '@angular/core';
import { EquationGroup } from 'src/app/international-estimate/equation/equation-group.model';

@Component({
    selector: 'equation-group',
    templateUrl: './equation-group.component.html'
})
export class EquationGroupComponent {
    @Input() public equationGroup!: EquationGroup;
    public showKey = false;
}
