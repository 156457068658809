import { Component, ComponentFactoryResolver, OnInit } from '@angular/core';
import { RouteManager, SubscriberEntity } from '@concurrency/angular';
import { Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DataStore } from '../_navigator/data/store/data.store';
import { Summary } from './results/summary';

@Component({
    selector: 'results',
    templateUrl: './results.component.html'
})
export class ResultsComponent extends SubscriberEntity implements OnInit {
    public route = this.routeManager.route;

    constructor(
        private routeManager: RouteManager,
        private resolver: ComponentFactoryResolver,
        private dataStore: DataStore
    ) { super(); }

    public ngOnInit(): void {
        this.dataStore.setSummary(undefined);
        let resummarize: Subscription;

        // TODO: Obviate takeUntil by using Async pipes and local Observable streams
        this.dataStore.estimate
            .pipe(takeUntil(this.destroyed))
            .whileDefined((estimate) => {
                const summary = new Summary(this.resolver, this.dataStore);

                summary.summarize(estimate);
                this.dataStore.setSummary(summary);

                if (resummarize) {
                    resummarize.unsubscribe();
                }

                resummarize = this.dataStore.resummarize.subscribe(() => summary.summarize(estimate));
            });
    }
}
