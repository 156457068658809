import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Observable } from 'rxjs/internal/Observable';
import { InflationClient } from 'src/app/_api/clients/inflation.client';
import { Inflation } from 'src/app/_api/responses/inflation.response';
import { GetInflationList, GetInflationListTrendsovertime } from './inflation-list-actions';
import { Injectable } from '@angular/core';


export type InflationListModel = Inflation[] | undefined;

@State<InflationListModel>({
    name: 'inflationList',
    defaults: undefined
})

@Injectable()
export class InflationListState {

    @Selector()
    public static get(state: InflationListModel): Inflation[] | undefined {
        return state;
    }
    @Selector()
    public static GetEight(state: InflationListModel): Inflation[] | undefined {
        return state;
    }
    constructor(private inflationClient: InflationClient) { }

    @Action(GetInflationList)
    public getInflationList(context: StateContext<InflationListModel>, action: GetInflationList): Observable<void> {
        return this.inflationClient.read(action.investorCountry, action.investeeCountry, action.dataAsOf).once((response) => {
            context.setState(response);
        });
    }
    @Action(GetInflationListTrendsovertime)
    public GetInflationListTrendsovertime(context: StateContext<InflationListModel>, action: GetInflationList): Observable<void> {
        return this.inflationClient.GetEight(action.investorCountry, action.investeeCountry, action.dataAsOf).once((response) => {
            context.setState(response);
        });
    }
}
