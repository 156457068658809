import { betaBackGround } from 'src/app/estimate/exports/summary-export/util/summary-helpers';
import { BetaStatistics } from '../beta.types';
import { valueConversion } from './levered-betas';

export function getAdjustedRSquareOls(betaStatistics: BetaStatistics[]): any[] {
    const olsRSquare: any[] = [];
    if (betaStatistics.length > 0) {
        olsRSquare.push(
            { value: '' },
            {
                value: 'AdjustedR2(OLS)'
            },
            { value: '' }
        );
        betaStatistics.forEach((betas: BetaStatistics) => {
            olsRSquare.push(
                {
                    background: betaBackGround,
                    textAlign: 'right',
                    format: '0.00',
                    value: valueConversion(betas.AdjustedOlsRSquare)
                }
            );
        });
    }
    return olsRSquare;
}

export function getAdjustedRSquareSum(betaStatistics: BetaStatistics[]): any[] {
    const sumRSquare: any[] = [];
    if (betaStatistics.length > 0) {
        sumRSquare.push(
            { value: '' },
            {
                value: 'AdjustedR2(Sum)'
            },
            { value: '' }
        );
        betaStatistics.forEach((betas: BetaStatistics) => {
            sumRSquare.push(
                {
                    background: betaBackGround,
                    textAlign: 'right',
                    format: '0.00',
                    value: valueConversion(betas.AdjustedSumRSquare)
                }
            );
        });
    }
    return sumRSquare;
}

export function getStandardError(betaStatistics: BetaStatistics[]): any[] {
    const standardError: any[] = [];
    if (betaStatistics.length > 0) {
        standardError.push(
            { value: '' },
            {
                value: 'Standard Error'
            },
            { value: '' }
        );
        betaStatistics.forEach((betas: BetaStatistics) => {
            standardError.push(
                {
                    background: betaBackGround,
                    textAlign: 'right',
                    format: '0.00',
                    value: valueConversion(betas.StandardError)
                }
            );
        });
    }
    return standardError;
}

export function getTStat(betaStatistics: BetaStatistics[]): any[] {
    const tStat: any[] = [];
    if (betaStatistics.length > 0) {
        tStat.push(
            { value: '' },
            {
                value: 'T-Stat'
            },
            { value: '' }
        );
        betaStatistics.forEach((betas: BetaStatistics) => {
            tStat.push(
                {
                    background: betaBackGround,
                    textAlign: 'right',
                    format: '0.00',
                    value: valueConversion(betas.TStat)
                }
            );
        });
    }
    return tStat;
}

export function getSignificantLevel1(betaStatistics: BetaStatistics[]): any[] {
    const level1: any[] = [];
    if (betaStatistics.length > 0) {
        level1.push(
            { value: '' },
            {
                value: '90%'
            },
            { value: '' }
        );
        betaStatistics.forEach((betas: BetaStatistics) => {
            level1.push(
                {
                    background: betaBackGround,
                    textAlign: 'right',
                    value: betas.SignificantLevel1
                }
            );
        });
    }
    return level1;
}

export function getSignificantLevel2(betaStatistics: BetaStatistics[]): any[] {
    const level2: any[] = [];
    if (betaStatistics.length > 0) {
        level2.push(
            { value: '' },
            {
                value: '95%'
            },
            { value: '' }
        );
        betaStatistics.forEach((betas: BetaStatistics) => {
            level2.push(
                {
                    background: betaBackGround,
                    textAlign: 'right',
                    value: betas.SignificantLevel2
                }
            );
        });
    }
    return level2;
}

export function getSignificantLevel3(betaStatistics: BetaStatistics[]): any[] {
    const level3: any[] = [];
    if (betaStatistics.length > 0) {
        level3.push(
            { value: '' },
            {
                value: '99%'
            },
            { value: '' }
        );
        betaStatistics.forEach((betas: BetaStatistics) => {
            level3.push(
                {
                    background: betaBackGround,
                    textAlign: 'right',
                    value: betas.SignificantLevel3
                }
            );
        });
    }
    return level3;
}
