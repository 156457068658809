import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Spinner, SubscriberEntity } from '@concurrency/angular';
import { takeUntil } from 'rxjs/operators';
import { Tab } from '../_navigator/data/enum/tab.enum';
import { Step } from '../_navigator/data/model/step.model';
import { TitleService } from '../_navigator/data/service/title.service';
import { DataStore } from '../_navigator/data/store/data.store';
import { Steps } from '../_navigator/data/util/steps-utilities';

@Component({
    selector: 'estimate',
    templateUrl: './estimate.component.html'
})
export class EstimateComponent extends SubscriberEntity implements OnInit {
    public canExport = false;
    public activeTab!: Tab | null;
    public steps: Step[] = [];

    constructor(
        public route: ActivatedRoute,
        public router: Router,
        public spinner: Spinner,
        private titleService: TitleService,
        private dataStore: DataStore
    ) { super(); }

    public ngOnInit(): void {
        // TODO: Obviate takeUntil by using Async pipes and local Observable streams
        this.dataStore.allowExport.pipe(takeUntil(this.destroyed)).while((allowExport) => this.canExport = allowExport);

        this.dataStore.isIndustryAnalysis.once((isIndustryAnalysis) => {
            if (isIndustryAnalysis) {
                this.steps = Steps.getUsiSteps();
            } else {
                this.steps = Steps.getUsEstimateSteps();
            }

            this.activeTab = Steps.setupTab(this.router.url, this.steps);

            // TODO: Obviate takeUntil by using Async pipes and local Observable streams
            this.router.events.pipe(takeUntil(this.destroyed)).while(
                () => this.activeTab = Steps.setupTab(this.router.url, this.steps)
            );

            // TODO: Obviate takeUntil by using Async pipes and local Observable streams
            this.dataStore.estimate.pipe(takeUntil(this.destroyed)).while((estimate) => {
                if (isIndustryAnalysis === true) {
                    return;
                }

                if (estimate == null) {
                    this.router.navigate(['']);
                } else {
                    this.titleService.set(`Estimate: ${estimate.Name}`);
                    if (this.route.firstChild != null && this.route.firstChild.component != null) {
                        return;
                    }
                }
            });
        });
    }
}
