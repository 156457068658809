import { Workbook, WorkbookSheetRow } from "@progress/kendo-angular-excel-export";

export class CommonExcelExportUtil {

    public static krollshadow = '#4d4d4f';
    public static krollblue = '#14487F';

    public static getKrollCopyrightsRows(): WorkbookSheetRow[] {

        var copyrightRows: WorkbookSheetRow[] = [];
        var currentYear = new Date().getFullYear();

        copyrightRows.push(
            {
                cells: [{ value: '' }]
            },
            {
                cells: [
                    {
                        value: 'KROLL',
                        color: this.krollblue,
                        fontFamily: 'Arial',
                        verticalAlign: 'center',
                        textAlign: 'left',
                        fontSize: 16,
                        bold: true
                    }
                ]
            },
            {
                cells: [
                    {
                        color: this.krollshadow,
                        fontFamily: 'Arial',
                        verticalAlign: 'center',
                        textAlign: 'left',
                        fontSize: 10.66,
                        value: '© '+ currentYear +' Cost of Capital Navigator by Kroll, LLC. All rights reserved.'
                    }
                ]
            });

        return copyrightRows;
    }



    public static getUpdatedCopyRigtsSheet(workBook: Workbook): Workbook {

        if (workBook.sheets.length > 0) {
            workBook.sheets.forEach(sheet => {
                sheet.rows?.push(...this.getKrollCopyrightsRows());
            });

            return workBook;

        } else {
            return workBook;
        }

    }

}
