import { Injectable } from '@angular/core';

// TODO: Static data should not be a service
@Injectable()
export class ErrorService {
    public riskFreeRate: InputError = {
        text: '{}% is an invalid entry for a risk-free rate. Please enter a value between -5% and 10%.',
        alwaysShow: false
    };

    public equityRiskPremium: InputError = {
        text: '{}% is an invalid entry for an equity risk premium. Please enter a value between 0% and 10%.',
        alwaysShow: false
    };

    public beta: InputError = {
        text: '{} is an invalid entry for beta. Please enter a value between 0 and 5.',
        alwaysShow: false
    };

    public sizeMeasure: InputError = {
        text: '{} is an invalid entry for a size measure. Please enter a value between 0% and 100%.',
        alwaysShow: false
    };

    public debtRatio: InputError = {
        text: 'Please enter a value greater than 0.',
        alwaysShow: false
    };

    public alpha: InputError = {
        text: '{} is an invalid entry for alpha. Please enter a value between 0% and 100%.',
        alwaysShow: false
    };
}

export interface InputError {
    text: string;
    alwaysShow: boolean;
}
