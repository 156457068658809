import { Component } from '@angular/core';
import { SelectionType } from 'src/app/_api/enums/selection-type';
import { EquationType } from 'src/app/_api/responses/equation.response';
import { InputType } from 'src/app/_api/responses/input.response';
import { Equation, EquationParameter } from 'src/app/_navigator/data/model/equation.model';
import { OperandUtility } from '../operand.utility';
import { ScenarioStudyComponent } from '../scenario-study.component';
import { RiskTableComponent } from './risk-table.component';
import { Risk } from './risk.model';

@Component({ templateUrl: '../scenario-study.component.html', })
export class RiskBuildupTwoComponent extends ScenarioStudyComponent {
    protected equationType = EquationType.RprsBuildup2;
    protected hasRegressionToggle = true;
    public dataTable = RiskTableComponent;

    private buildEquation(risk: Risk): Equation {
        const costOfEquityCapital = OperandUtility.getCostOfEquityCapital(this.equationType, risk.implicationType);
        const riskOperand = OperandUtility.getRiskPremium(this.equationType, risk);
        const irpOperand = OperandUtility.getIndustryRiskPremium(SelectionType.RprIndustryRiskPremium);

        const ke: EquationParameter = { item: costOfEquityCapital, operator: '=', canEdit: false };
        const rf: EquationParameter = { item: OperandUtility.riskFreeRate, operator: '+', canEdit: true };
        const erp: EquationParameter = { item: OperandUtility.equityRiskPremium, operator: '+', canEdit: true };
        const rpi: EquationParameter = { item: irpOperand, operator: '+', canEdit: true };
        const rp: EquationParameter = { item: riskOperand, operator: '', canEdit: true };

        return {
            name: risk.name,
            equationType: this.equationType,
            implicationType: risk.implicationType,
            parameters: [ke, rf, erp, rpi, rp],
            calculate: (scenario) => {
                const equation = scenario.getEquation(this.equationType, risk.implicationType);
                const riskFreeRate = scenario.getValueOrNaN(SelectionType.RiskFreeRate, InputType.None);
                const equityRiskPremium = scenario.getValueOrNaN(SelectionType.EquityRiskPremium, InputType.None);
                const industryRiskPremium = scenario.getValueOrNaN(SelectionType.RprIndustryRiskPremium, InputType.None);
                const riskPremium = scenario.getValueOrNaN(risk.selectionType, risk.inputType);

                const result = riskFreeRate + equityRiskPremium + industryRiskPremium + riskPremium;
                equation.Result = result || undefined;

                return equation.Result != null;
            }
        };
    }

    public buildEquations(): Equation[] {
        const risks = OperandUtility.getRisks(this.equationType);
        return risks.map((risk) => this.buildEquation(risk));
    }
}
