import { EquationElementName } from './equation-element-name';
import { EquationElement } from './equation-element.model';

export class EquationElementUtil {
    public static getCapmCcrEquation(
        countryOfInputCurrency: string,
        ke: string | undefined,
        rf: string | undefined,
        erp: string | undefined,
        b: string,
        rps: string | undefined,
        alpha: string | undefined,
        crp: string | undefined
    ): EquationElement[] {
        return [
            { display: `K<sub>e (${countryOfInputCurrency})</sub>`, name: EquationElementName.CostOfEquity, value: ke, mask: '%' },
            { display: '=', slim: true },
            { display: `Rf`, name: EquationElementName.RiskFreeRate, value: rf, mask: '%' },
            { display: '+', slim: true },
            { display: `ERP`, name: EquationElementName.EquityRiskPremium, value: erp, mask: '%' },
            { display: '*', slim: true },
            { display: `B`, name: EquationElementName.Beta, value: b },
            { display: '+', slim: true },
            { display: `RP<sub>s</sub>`, name: EquationElementName.SizePremium, value: rps, mask: '%' },
            { display: '+', slim: true },
            { display: `A`, name: EquationElementName.Alpha, value: alpha, mask: '%' },
            { display: '+', slim: true },
            { display: `CRP<sub>(CCR)</sub>`, name: EquationElementName.CountryRiskPremium, value: crp, mask: '%', isCrpElem: true }
        ];
    }

    public static getCapmYieldSpreadEquation(
        countryOfInputCurrency: string,
        ke: string | undefined,
        rf: string | undefined,
        erp: string | undefined,
        b: string,
        rps: string | undefined,
        alpha: string | undefined,
        ys: string | undefined
    ): EquationElement[] {
        return [
            { display: `K<sub>e (${countryOfInputCurrency})</sub>`, name: EquationElementName.CostOfEquity, value: ke, mask: '%' },
            { display: '=', slim: true },
            { display: `Rf`, name: EquationElementName.RiskFreeRate, value: rf, mask: '%' },
            { display: '+', slim: true },
            { display: `ERP`, name: EquationElementName.EquityRiskPremium, value: erp, mask: '%' },
            { display: '*', slim: true },
            { display: `B`, name: EquationElementName.Beta, value: b },
            { display: '+', slim: true },
            { display: `RP<sub>s</sub>`, name: EquationElementName.SizePremium, value: rps, mask: '%' },
            { display: '+', slim: true },
            { display: `A`, name: EquationElementName.Alpha, value: alpha, mask: '%' },
            { display: '+', slim: true },
            { display: `CRP<sub>(YS)</sub>`, name: EquationElementName.YieldSpread, value: ys, mask: '%', isCrpElem: true }
        ];
    }

    public static getCapmVolatilityEquation(
        countryOfInputCurrency: string,
        ke: string | undefined,
        rf: string | undefined,
        erp: string | undefined,
        b: string,
        rv: string,
        rps: string | undefined,
        alpha: string | undefined
    ): EquationElement[] {
        return [
            { display: `K<sub>e (${countryOfInputCurrency})</sub>`, name: EquationElementName.CostOfEquity, value: ke, mask: '%' },
            { display: '=', slim: true },
            { display: `Rf`, name: EquationElementName.RiskFreeRate, value: rf, mask: '%' },
            { display: '+', slim: true },
            { display: `ERP`, name: EquationElementName.EquityRiskPremium, value: erp, mask: '%' },
            { display: '*', slim: true },
            { display: `B`, name: EquationElementName.Beta, value: b },
            { display: '*', slim: true },
            { display: `RV`, name: EquationElementName.RelativeVolatilityFactor, value: rv, isCrpElem: true },
            { display: '+', slim: true },
            { display: `RP<sub>s</sub>`, name: EquationElementName.SizePremium, value: rps, mask: '%' },
            { display: '+', slim: true },
            { display: `A`, name: EquationElementName.Alpha, value: alpha, mask: '%' }
        ];
    }

    public static getCostOfEquityFisherEffectEquation(
        countryOfInputCurrency: string,
        cashFlowCurrency: string,
        countryOfInputName: string,
        cashFlowCountryName: string,
        keCountryOfInput: string | undefined,
        keCashFlow: string | undefined,
        cashFlowCountryInflation: string | undefined,
        countryOfInputInflation: string | undefined
    ): EquationElement[] {
        const fisherEffectKe = {
            display: `K<sub>e (${cashFlowCurrency})</sub>`,
            name: `${EquationElementName.CostOfEquity} (${cashFlowCurrency})`,
            value: keCashFlow,
            mask: '%'
        };
        const ke = {
            display: `K<sub>e (${countryOfInputCurrency})</sub>`,
            name: `${EquationElementName.CostOfEquity} (${countryOfInputCurrency})`,
            value: keCountryOfInput,
            mask: '%'
        };
        const cashFlowInflation = {
            display: `i<sub>${cashFlowCountryName}</sub>`,
            name: `${EquationElementName.Inflation} (${cashFlowCountryName})`,
            value: cashFlowCountryInflation,
            mask: '%'
        };
        const inputCountryInflation = {
            display: `i<sub>${countryOfInputName}</sub>`,
            name: `${EquationElementName.Inflation} (${countryOfInputName})`,
            value: countryOfInputInflation,
            mask: '%'
        };

        return [
            fisherEffectKe,
            { display: '=', slim: true },
            { display: '(', slim: true },
            { display: '1', slim: true },
            { display: '+', slim: true },
            ke,
            { display: ')', slim: true },
            { display: '*', slim: true },
            { display: '(', slim: true },
            { display: '1', slim: true },
            { display: '+', slim: true },
            cashFlowInflation,
            { display: ')', slim: true },
            { display: '/', slim: true },
            { display: '(', slim: true },
            { display: '1', slim: true },
            { display: '+', slim: true },
            inputCountryInflation,
            { display: ')', slim: true },
            { display: '-', slim: true },
            { display: '1', slim: true }
        ];
    }

    public static getCostOfEquityConclusionEquation(currency: string, ke: string | undefined): EquationElement[] {
        return [
            { display: `K<sub>e (${currency})</sub>`, name: EquationElementName.CostOfEquity, value: ke, mask: '%' }
        ];
    }

    public static getCostOfDebtEquation(
        currency: string,
        kd: string | undefined,
        preTaxKd: string | undefined,
        crp: string | undefined,
        crpType: string,
        t: string | undefined,
        investeeCountryName: string
    ): EquationElement[] {
        return [
            { display: `KD<sub>${currency}</sub>`, name: EquationElementName.CostOfDebt, value: kd, mask: '%' },
            { display: '=', slim: true },
            { display: '(', slim: true },
            { display: `K<sub>d</sub>`, name: EquationElementName.PreTaxCostOfDebt, value: preTaxKd, mask: '%' },
            { display: '+', slim: true },
            { display: `CRP<sub>(${crpType})</sub>`, name: EquationElementName.CountryRiskPremium, value: crp, mask: '%', isCrpElem: true },
            { display: ')', slim: true },
            { display: '*', slim: true },
            { display: '(', slim: true },
            { display: '1', slim: true },
            { display: '-', slim: true },
            { display: 't', name: `${EquationElementName.TaxRate} (${investeeCountryName})`, value: t, mask: '%' },
            { display: ')', slim: true }
        ];
    }

    public static getCostOfDebtVolatilityEquation(
        currencyAcronym: string,
        kd: string | undefined,
        preTaxKd: string | undefined,
        rv: string,
        erp: string | undefined,
        t: string | undefined,
        investeeCountryName: string
    ): EquationElement[] {
        return [
            { display: `KD<sub>${currencyAcronym}</sub>`, name: EquationElementName.CostOfDebt, value: kd, mask: '%' },
            { display: '=', slim: true },
            { display: '(', slim: true },
            { display: `K<sub>d</sub>`, name: EquationElementName.PreTaxCostOfDebt, value: preTaxKd, mask: '%' },
            { display: '+', slim: true },
            { display: `RV`, name: EquationElementName.RelativeVolatilityFactor, value: rv, isCrpElem: true },
            { display: '*', slim: true },
            { display: `ERP`, name: EquationElementName.EquityRiskPremium, value: erp, mask: '%' },
            { display: '-', slim: true },
            { display: `ERP`, name: EquationElementName.EquityRiskPremium, value: erp, mask: '%' },
            { display: ')', slim: true },
            { display: '*', slim: true },
            { display: '(', slim: true },
            { display: '1', slim: true },
            { display: '-', slim: true },
            { display: 't', name: `${EquationElementName.TaxRate} (${investeeCountryName})`, value: t, mask: '%' },
            { display: ')', slim: true }
        ];
    }

    public static getCostOfDebtFisherEffectEquation(
        countryOfInputCurrency: string,
        cashFlowCurrency: string,
        countryOfInputName: string,
        cashFlowCountryName: string,
        kdCountryOfInput: string | undefined,
        kdCashFlow: string | undefined,
        iCashFlowCountry: string | undefined,
        iCountryOfInput: string | undefined
    ): EquationElement[] {
        const kdFisherEffect = {
            display: `K<sub>d (${cashFlowCurrency})</sub>`,
            name: `${EquationElementName.CostOfDebt} (${cashFlowCurrency})`,
            value: kdCashFlow,
            mask: '%'
        };
        const kd = {
            display: `KD<sub>${countryOfInputCurrency}</sub>`,
            name: `${EquationElementName.CostOfDebt} (${countryOfInputCurrency})`,
            value: kdCountryOfInput,
            mask: '%'
        };
        const inflationCashFlow = {
            display: `i<sub>${cashFlowCountryName}</sub>`,
            name: `${EquationElementName.Inflation} (${cashFlowCountryName})`,
            value: iCashFlowCountry,
            mask: '%'
        };
        const inflationCountryOfInput = {
            display: `i<sub>${countryOfInputName}</sub>`,
            name: `${EquationElementName.Inflation} (${countryOfInputName})`,
            value: iCountryOfInput,
            mask: '%'
        };

        return [
            kdFisherEffect,
            { display: '=', slim: true },
            { display: '(', slim: true },
            { display: '1', slim: true },
            { display: '+', slim: true },
            kd,
            { display: ')', slim: true },
            { display: '*', slim: true },
            { display: '(', slim: true },
            { display: '1', slim: true },
            { display: '+', slim: true },
            inflationCashFlow,
            { display: ')', slim: true },
            { display: '/', slim: true },
            { display: '(', slim: true },
            { display: '1', slim: true },
            { display: '+', slim: true },
            inflationCountryOfInput,
            { display: ')', slim: true },
            { display: '-', slim: true },
            { display: '1', slim: true }
        ];
    }

    public static getCostOfDebtConclusionEquation(currency: string, kd: string | undefined): EquationElement[] {
        return [
            { display: `K<sub>d (${currency})</sub>`, name: EquationElementName.CostOfDebt, value: kd, mask: '%' }
        ];
    }

    public static getWaccEquation(
        ke: string | undefined,
        we: string | undefined,
        kd: string | undefined,
        wd: string | undefined,
        wacc: string | undefined
    ): EquationElement[] {
        return [
            { display: 'WACC', name: EquationElementName.Wacc, value: wacc, mask: '%' },
            { display: '=', slim: true },
            { display: 'K<sub>e</sub>', name: EquationElementName.CostOfEquity, value: ke, mask: '%' },
            { display: '*', slim: true },
            { display: 'W<sub>e</sub>', name: EquationElementName.WeightedEquity, value: we, mask: '%' },
            { display: '+', slim: true },
            { display: `K<sub>d</sub>`, name: EquationElementName.CostOfDebt, value: kd, mask: '%' },
            { display: '*', slim: true },
            { display: 'W<sub>d</sub>', name: EquationElementName.WeightedDebt, value: wd, mask: '%' }
        ];
    }

    public static getWaccConclusionEquation(wacc: string | undefined): EquationElement[] {
        return [
            { display: `WACC`, name: EquationElementName.Wacc, value: wacc, mask: '%' }
        ];
    }

    public static getSingleCountryCapmEquation(
        countryOfInputCurrency: string,
        ke: string | undefined,
        rf: string | undefined,
        erp: string | undefined,
        b: string,
        rps: string | undefined,
        alpha: string | undefined
    ): EquationElement[] {
        return [
            { display: `K<sub>e (${countryOfInputCurrency})</sub>`, name: EquationElementName.CostOfEquity, value: ke, mask: '%' },
            { display: '=', slim: true },
            { display: `Rf`, name: EquationElementName.RiskFreeRate, value: rf, mask: '%' },
            { display: '+', slim: true },
            { display: `ERP`, name: EquationElementName.EquityRiskPremium, value: erp, mask: '%' },
            { display: '*', slim: true },
            { display: `B`, name: EquationElementName.Beta, value: b },
            { display: '+', slim: true },
            { display: `RP<sub>s</sub>`, name: EquationElementName.SizePremium, value: rps, mask: '%' },
            { display: '+', slim: true },
            { display: `A`, name: EquationElementName.Alpha, value: alpha, mask: '%' }
        ];
    }

    public static getSingleCountryCostOfDebtEquation(
        currencyAcronym: string,
        kd: string | undefined,
        t: string | undefined,
        preTaxKd: string | undefined,
        investeeCountryName: string
    ): EquationElement[] {
        return [
            { display: `KD<sub>${currencyAcronym}</sub>`, name: EquationElementName.CostOfDebt, value: kd, mask: '%' },
            { display: '=', slim: true },
            { display: `K<sub>d</sub>`, name: EquationElementName.PreTaxCostOfDebt, value: preTaxKd, mask: '%' },
            { display: '*', slim: true },
            { display: '(', slim: true },
            { display: '1', slim: true },
            { display: '-', slim: true },
            { display: 't', name: `${EquationElementName.TaxRate} (${investeeCountryName})`, value: t, mask: '%' },
            { display: ')', slim: true }
        ];
    }
}
