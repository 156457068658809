import { EstimateResponse } from 'src/app/_api/responses/estimate.response';

export class UpdateDbEstimate {
    public static type = '[Estimate API] Update Db';
}

export class GetEstimate {
    public static type = '[Estimate API] Read';
    constructor(public id: string) { }
}

export class UpdateLocalEstimate {
    public static type = '[Estimate API] Update Local';
    constructor(public estimate: EstimateResponse) { }
}
