import { EstimateType } from 'src/app/_api/enums/estimate-type';
import { BetaEstimateResponse } from 'src/app/_api/responses/beta-estimate.response';

export class GetBetaEstimates {
    public static type = '[BetaEstimate API] Get All';
}

export class GetBetaEstimate {
    public static type = '[BetaEstimate API] Read';
    constructor(public id: string) { }
}

export class UpdateDbEstimate {
    public static type = '[BetaEstimate API] Update Db';
}

export class UpdateLocalEstimate {
    public static type = '[BetaEstimate API] Update Local';
    constructor(public estimate: BetaEstimateResponse) { }
}

export class CreateBetaEstimate {
    public static type = '[BetaEstimate API] Create';
    constructor(public name: string, public type: EstimateType) { }
}

export class ArchiveBetaEstimate {
    public static type = '[BetaEstimate API] Archive';
    constructor(public id: string) { }
}

export class CloneBetaEstimate {
    public static type = '[BetaEstimate API] Clone';
    constructor(public id: string) { }
}

export class RenameBetaEstimate {
    public static type = '[BetaEstimate API] Rename';
    constructor(public id: string, public name: string) { }
}
