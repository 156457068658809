import { Component, Input } from '@angular/core';
import { CompositeData } from './models/composite-data.model';
import { FlatenMtericCategory, MetricCategory } from './models/metric-category.model';
import { Category } from './enums/category';

@Component({
    selector: 'metric-row',
    templateUrl: './metric-row.component.html'
})
export class MetricRowComponent {
    @Input() public metricCategories: FlatenMtericCategory[] = [];
    @Input() public compositeData!: CompositeData;
    @Input() public inPDF = false;
    @Input() public region!: string;
}
