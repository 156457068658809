import { Injectable } from '@angular/core';
import { ApiClient } from '@concurrency/angular';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IndustryTearSheet } from '../responses/industry-tear-sheet.response';
import { Auth0ApiClient } from '../api.client';

@Injectable()
export class UsIndustryDataClient extends Auth0ApiClient {
    protected baseUrl = `${environment.urls.navigatorApi}/api`;

    public tearSheet(targetDate: string, sicIds: number[]): Observable<IndustryTearSheet[]> {
        return this.post(`UsIndustryData/TearSheet?targetDate=${targetDate}`, sicIds);
    }
}
