export enum TabName {
    Inputs = 'General Inputs',
    Studies = 'Cost of Capital Equations',
    Results = 'Results',
    Analysis = 'U.S. Industry Benchmarking',
    BenchmarkingAnalysis = 'Benchmarking Analysis',
    BenchmarkingInputs = 'Benchmarking Inputs',
    InternationalInputs = 'General Inputs',
    CostOfEquityInputs = 'Cost of Equity Inputs',
    CountryRiskPremiaSummary = 'CRP/RV Summary',
    FisherEffect = 'Intl Fisher Effect Inputs',
    CostOfEquitySummary = 'Cost of Equity Summary',
    WaccInputs = 'Kd and WACC Inputs',
    CostOfDebtSummary = 'Cost of Debt Summary',
    WaccSummary = 'WACC Summary'
}
