import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Spinner } from '@concurrency/angular';
import { Store } from '@ngxs/store';
import { Step } from 'src/app/_navigator/data/model/step.model';
import { UpdateDbEstimate } from 'src/app/_navigator/estimate-store/estimate-actions';

@Injectable()
export class InternationalEstimateService {
    constructor(private router: Router, private store: Store, private spinner: Spinner) { }

    public saveEstimate(nextStep: Step): void {
        const request = this.store.dispatch(new UpdateDbEstimate());
        this.spinner.while(request);
        request.once(() => {
            this.router.navigate([nextStep.Url]);
        });
    }
}
