import { Component, Input } from '@angular/core';
import { Spinner } from '@concurrency/angular';
import * as moment from 'moment';
import { DataStore } from 'src/app/_navigator/data/store/data.store';
import { PrimeManager } from 'src/app/_navigator/modal/pmodal.manager';
import { TearSheetService } from './data/tear-sheet.service';
import { TrendsOverTimeModalComponent } from './modals/trends-over-time-modal.component';
import { MetricValues } from './models/metric-values.model';

@Component({
    selector: 'metric',
    templateUrl: './metric.component.html'
})

export class MetricComponent {
    @Input() public metric!: MetricValues;

    constructor(
        private primeManager: PrimeManager,
        private spinner: Spinner,
        private tearSheetService: TearSheetService,
        private dataStore: DataStore
    ) { }

    public openTrendsOverTimeModal(column: string): void {
        if (this.metric.tables.length) {
            // TODO move logic into a utility component or potentially a service
            const industryData = this.tearSheetService.getTrendsOverTimeIndustry();
            const isAverage = column === '5-Yr Avg' ? true : false;
            const dataRequests = industryData.map((industry) => {
                return {
                    IndustryCodeId: industry.IndustryCodeId,
                    MetricId: this.metric.tables[0].metricId,
                    CurrencyCode: industry.CurrencyCode,
                    Area: industry.Area,
                    SicId: industry.SicId,
                    GicId: industry.GicId,
                    ValuationDate: moment(industry.ValuationDate).format('LL')
                };
            });

            const request = this.dataStore.retrieveTrendsOverTime(dataRequests);

            this.spinner.while(request);

            request.once(() => {
                const trendsOverTimeData = this.dataStore.trendsOverTimeData;

                trendsOverTimeData.onceDefined((data) => {
                    if (isAverage) {
                        data.forEach((x) => {
                            x.forEach((y) => {
                                y.Metric.MedianLatest = y.Metric.MedianAverage;
                                y.Metric.SICCompositeLatest = y.Metric.SICCompositeAverage;
                                y.Metric.LargeCompositeLatest = y.Metric.LargeCompositeAverage;
                                y.Metric.HighFinancialRiskLatest = y.Metric.HighFinancialRiskAverage;
                                y.Metric.SmallCompositeLatest = y.Metric.SmallCompositeAverage;
                            });
                        });
                    }
                    this.primeManager.openDialog(TrendsOverTimeModalComponent, {
                        trendsOverTimeMetric: {
                            metricId: this.metric.tables[0].metricId,
                            metricName: this.metric.tables[0].metricName,
                            metricCategory: this.metric.tables[0].metricCategory
                        },
                        trendsOverTimeData: data,
                        trendsOverTimeIndustries: industryData
                    });
                });
            });

        }
    }
}
