import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Gic } from 'src/app/_api/responses/gic.response';

@Component({
    selector: 'inter-child-industries',
    templateUrl: './inter-child-industries.component.html',
    styleUrls: ['./inter-child-industries.component.scss']
})
export class InterChildIndustriesComponent implements OnInit, OnChanges {

    public userSelectionListLocal!: Gic[];

    @Input() public userSelectedList!: Gic[];
    @Output() public removeIndustrySelection = new EventEmitter<Gic>();

    constructor() { }

    public ngOnInit(): void {
        this.userSelectionListLocal = this.userSelectedList;
    }

    public ngOnChanges(): void {
        this.userSelectionListLocal = this.userSelectedList;
    }

    public removeSelectedIndustry = (codeId: number): void => {
        this.removeIndustrySelection.emit({
            GicId: codeId,
            GicCode: '',
            GicDescription: ''
        });
    }
}
