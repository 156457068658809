import { Action, createSelector, Selector, State, StateContext } from '@ngxs/store';
import { Observable } from 'rxjs/internal/Observable';
import { CurrencyClient } from 'src/app/_api/clients/currency.client';
import { CurrencyData } from 'src/app/_api/responses/currency-data.response';
import { GetCurrencyList, GetInvestorInvesteeCurrencies } from './currency-list-actions';
import { Injectable } from '@angular/core';

export type CurrencyListModel = CurrencyData[] | undefined;

@State<CurrencyListModel>({
    name: 'currencyList',
    defaults: undefined
})


@Injectable()
export class CurrencyListState {

    public static getById(currencyId: number): (currencies: CurrencyListModel) => CurrencyData | undefined {
        const filteredCurrencies = (currencies: CurrencyData[]) => {
            return currencies.find((x) => x.CurrencyId === currencyId);
        };

        return createSelector([CurrencyListState], (currencies) => {
            if (currencies == null) {
                return undefined;
            }

            return filteredCurrencies(currencies);
        });
    }

    @Selector()
    public static getAll(state: CurrencyListModel): CurrencyData[] | undefined {
        return state;
    }
    constructor(private currencyClient: CurrencyClient) { }

    @Action(GetCurrencyList)
    public getCurrencyList(context: StateContext<CurrencyListModel>): Observable<void> {
        return this.currencyClient.read().once((response) => {
            context.setState(response);
        });
    }

    @Action(GetInvestorInvesteeCurrencies)
    public getInvestorInvesteeCurrencies(
        context: StateContext<CurrencyListModel>,
        action: GetInvestorInvesteeCurrencies
    ): Observable<void> {
        return this.currencyClient.read(action.investorCountryId, action.investeeCountryId).once((response) => {
            context.setState(response);
        });
    }

}
