import * as moment from 'moment';

import { Component, OnInit, ViewChild } from '@angular/core';
import { List } from '@concurrency/angular';
import { Util } from '@concurrency/core';
import { Dialog } from 'primeng/dialog';
import { IntlCompany, IntlCompanyData } from 'src/app/_api/responses/intl-company.response';
import { CompanyType } from 'src/app/_api/responses/us-company.response';
import { DataStore } from 'src/app/_navigator/data/store/data.store';
import { CompanyList } from 'src/app/estimate/data/company-list';
import { CompanyListItem } from 'src/app/estimate/data/company-list-item.model';
import { CompanyTypeName } from 'src/app/estimate/data/companyTypeName';
import { BenchmarkingUtil } from '../../benchmarking.util';
import { CurrencyTypes, IntlCompanySalesFields, IntlCompanyAssetsFields, AssetsTypes } from './enums/intl-company-list-enums';
import { UserStore } from 'src/app/_navigator/user/store/user.store';
import { TearSheetService } from 'src/app/home/tear-sheet/data/tear-sheet.service';





@Component({
    templateUrl: './intl-company-list.component.html',
    styleUrls: ['./intl-company-list.component.scss']
})




export class IntlCompanyListComponent implements OnInit {
    public moment = moment;
    public Util = Util;
    public display = true;
    public sicCode!: string;
    public gicCode!: string;
    public region!: string;
    public currency!: string;
    public companies!: IntlCompanyData;
    public companyList!: List<IntlCompany>;
    public companyType: CompanyType = CompanyType.Default;
    public companyListHeaders: CompanyListItem[] = CompanyList.companyList;
    public companyTypeName: CompanyTypeName = CompanyTypeName.default;
    public dialogHeader!: string;
    public onClose!: Function
    public salesfield: string = '';
    public assetField: string = '';
    public listOfColumn: any[] = []
    public listOfIndustries!: any[];
    activeIndustrySheet!: number;

    @ViewChild(Dialog) public dialog!: Dialog;

    constructor(
        protected dataStore: DataStore,
        public userStore: UserStore,
        public tearSheetService: TearSheetService,


    ) { }

    private setDialogHeader(): void {
        this.dialogHeader = BenchmarkingUtil.isSic(this.companies.DataAsOf, this.region)
            ? `<span class="custfontsuppdatatitle">Industry Company List  SIC ${this.sicCode}</span><span class="dataasofcs"> - Data as of ${moment(this.companies.DataAsOf).format('MM/DD/YYYY')} </span>`
            : `<span class="custfontsuppdatatitle">Industry Company List  GICS ${this.gicCode}</span><span class="dataasofcs"> - Data as of ${moment(this.companies.DataAsOf).format('MM/DD/YYYY')}</span>`;
    }

    public ngOnInit(): void {
        this.setData();
        this.setIndustriesData();
    }


    public setData() {
        if (this.companies.Companies.length) {
            this.companyList = new List(this.companies.Companies, (data) => {
                if (this.companyType === CompanyType.Default) {
                    return data.Type !== CompanyType.HighFinancialRisk;
                } else {
                    return data.Type === this.companyType;
                }
            });
            this.setFields(this.currency);
        }
        this.setColumns();
        this.setCompanyType(CompanyType.Default);
        this.setDialogHeader();
    }

    public setColumns() {
        this.listOfColumn = [
            {
                title: `Company Name`,
                compare: (a: IntlCompany, b: IntlCompany) => a.Name.localeCompare(b.Name),
                sortDirections: ['ascend', 'descend'],
                sortOrder: 'ascend'
            },
            {
                title: `Sales ([${this.currency}] in millions)`,
                compare: (a: IntlCompany, b: IntlCompany) => this.sortBySales(a, b),
                sortDirections: ['ascend', 'descend'],
                sortOrder: null

            },
            {
                title: `Assets ([${this.currency}] in millions)`,
                compare: (a: IntlCompany, b: IntlCompany) => this.sortByAssets(a, b),
                sortDirections: ['ascend', 'descend'],
                sortOrder: null
            },

        ];
    }

    public sortBySales = (a: IntlCompany, b: IntlCompany) => this.sortByField(a, b, this.salesfield);
    public sortByAssets = (a: IntlCompany, b: IntlCompany) => this.sortByField(a, b, this.assetField);


    public sortByField = (a: any, b: any, field: string) => {
        {
            if (a[field] < b[field]) {
                return -1;
            }
            if (a[field] > b[field]) {
                return 1;
            }
            return 0;
        }
    }


    public setCompanyType(type: CompanyType): void {
        this.companyType = type;
        this.companyTypeName = CompanyList.find(type);

        if (this.companyList && this.companyList.all.length) {
            this.companyList.filter('-'); // NOTE: Need to pass a non-null non-empty string or it won't filter
        }
        // this.sortBySalesField(this.currency);
        this.sortBy('Name');
    }

    public setIndustriesData() {
        const tearsheetData = this.tearSheetService.getTearSheetData()
        this.listOfIndustries = tearsheetData.map(item => ({
            industry: this.createIndustryFilterLabel(item.SicCode, item.GicCode) + " - " + item.Sector,
            value: item.SicCode ? item.SicCode : item.GicCode
        }));
        this.activeIndustrySheet = this.tearSheetService.tabIndex;

    }

    public onChangeIndustry(index: number) {
        const tearsheetData = this.tearSheetService.getTearSheetData();
        this.companies.Companies = tearsheetData[index].Companies.Companies as IntlCompany[];
        this.setData();
    }


    // public sortBySalesField(currency: string): void {

    //     const currencyToLower = currency.toLowerCase();
    //     if (currencyToLower === CurrencyTypes.USD) {
    //         if (BenchmarkingUtil.isSic(this.companies.DataAsOf, this.region)) {
    //             this.sortBy(IntlCompanySalesFields.DEFAULT);
    //         } else {
    //             this.sortBy(IntlCompanySalesFields.USD);
    //         }
    //     } else if (currencyToLower === CurrencyTypes.EUR) {
    //         this.sortBy(IntlCompanySalesFields.EUR);
    //     } else if (currencyToLower === CurrencyTypes.GBP) {
    //         this.sortBy(IntlCompanySalesFields.GBP);
    //     }
    // }


    public setFields(currency: string): void {
        const currencyToLower = currency.toLowerCase();

        if (currencyToLower === CurrencyTypes.USD) {
            if (BenchmarkingUtil.isSic(this.companies.DataAsOf, this.region)) {
                this.assetField = IntlCompanyAssetsFields.DEFAULT;
                this.salesfield = IntlCompanySalesFields.DEFAULT
            } else {
                this.assetField = IntlCompanyAssetsFields.USD;
                this.salesfield = IntlCompanySalesFields.USD;
            }
        } else if (currencyToLower === CurrencyTypes.EUR) {
            this.assetField = IntlCompanyAssetsFields.EUR;
            this.salesfield = IntlCompanySalesFields.EUR;
        } else if (currencyToLower === CurrencyTypes.GBP) {
            this.assetField = IntlCompanyAssetsFields.GBP;
            this.salesfield = IntlCompanySalesFields.GBP;
        }
    }

    public sortByAssetsField(currency: string): void {
        const currencyToLower = currency.toLowerCase();

        if (currencyToLower === CurrencyTypes.USD) {
            if (BenchmarkingUtil.isSic(this.companies.DataAsOf, this.region)) {
                this.sortBy(IntlCompanyAssetsFields.DEFAULT);
            } else {
                this.sortBy(IntlCompanyAssetsFields.USD);
            }
        } else if (currencyToLower === CurrencyTypes.EUR) {
            this.sortBy(IntlCompanyAssetsFields.EUR);
        } else if (currencyToLower === CurrencyTypes.GBP) {
            this.sortBy(IntlCompanyAssetsFields.GBP);
        }
    }

    public sortBy(field: string): void {
        this.companyList.sort(field);
    }

    public createIndustryFilterLabel(sicId: string | undefined, gicId: string | undefined): string {
        let label = '';

        if (sicId) {
            label = `SIC ${sicId}`;
        } else {
            label = `GICS ${gicId}`;
        }

        return label;
    }

    close(): void {
        this.onClose();
    }


}
