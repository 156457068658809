export class CrpTableText {
    public static readonly CountryRiskPremiaComparisons = 'Country Risk Comparisons';
    public static readonly CostOfEquityCapitalComparisons = 'Cost of Equity Capital Comparisons';
    public static readonly WaccComparisons = 'WACC Comparisons';
    public static readonly CostOfDebtCapitalComparisons = 'After-tax Cost of Debt Capital Comparisons';
    public static readonly CcrModel = 'Country Credit Rating Model';
    public static readonly CountryYieldSpreadModel = 'Country Yield Spread Model';
    public static readonly RelativeVolatilityModel = 'Relative Volatility Model';
    public static readonly EuromoneyRegion = 'Euromoney Region';
    public static readonly McsiClassification = 'MSCI Classification';
    public static readonly SpCreditRating = 'S&P Credit Rating';
    public static readonly Region = 'Region';
    public static readonly NotAvailable = 'Not Available';
}
