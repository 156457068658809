import { Component, Input } from '@angular/core';
import { Chart } from 'angular-highcharts';

@Component({
    selector: 'industry-data-chart',
    templateUrl: 'industry-data-chart.component.html'
})
export class IndustryDataChartComponent {
    @Input() public chart!: Chart;
}
