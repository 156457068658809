import { Injectable } from '@angular/core';
import { ApiClient } from '@concurrency/angular';
import { Observable } from 'rxjs/internal/Observable';
import { IntlData } from 'src/app/_api/responses/intl-data.response';
import { environment } from 'src/environments/environment';
import { Auth0ApiClient } from '../api.client';


export class IntlBetaClient extends Auth0ApiClient {
    protected baseUrl = `${environment.urls.navigatorApi}/api`;

    public read(countryId: number, gicId: number, currencyId: number, dataAsOf: string): Observable<IntlData[]> {
        return this.get(`IntlBeta?countryId=${countryId}&gicId=${gicId}&currencyId=${currencyId}&dataAsOf=${dataAsOf}`);
    }
}
