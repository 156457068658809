import { Action, createSelector, Selector, State, StateContext } from '@ngxs/store';
import { Observable } from 'rxjs/internal/Observable';
import { CountryClient } from 'src/app/_api/clients/country.client';
import { Country } from 'src/app/_api/responses/country.response';
import { SetAllCountries, SetInvesteeCountries, SetInvestorCountries } from './country-list-actions';
import { Injectable } from '@angular/core';

export interface CountryListModel {
    all: Country[];
    investor: Country[];
    investee: Country[];
}

@State<CountryListModel>({
    name: 'countryList',
    defaults: undefined
})

@Injectable()
export class CountryListState {

    public static getById(countryId: number): (countries: CountryListModel) => Country | undefined {
        const filteredCountries = (countries: Country[]) => {
            return countries.find((x) => x.CountryId === countryId);
        };

        return createSelector([CountryListState], (countries) => {
            return filteredCountries(countries.all);
        });
    }

    public static getByName(countryNames: string[]): (countries: CountryListModel) => Country[] {
        const fitleredCountries = (countries: Country[]) => {
            return countries.filter((x) => countryNames.includes(x.CountryName));
        };

        return createSelector([CountryListState], (countries) => {
            return fitleredCountries(countries.all);
        });
    }

    @Selector()
    public static getInvestorCountries(state: CountryListModel): Country[] {
        return state.investor;
    }

    @Selector()
    public static getInvesteeCountries(state: CountryListModel): Country[] {
        return state.investee;
    }

    @Selector()
    public static getCountriesWithAvailableIndustries(state: CountryListModel): Country[] {
        return state.all.filter(
            (x) => x.CountryName === 'United States' || x.CountryName === 'United Kingdom' || x.Currency.CurrencyAcronym === 'EUR'
        );
    }
    constructor(private countryClient: CountryClient) { }

    @Action(SetAllCountries)
    public setAllCountries(context: StateContext<CountryListModel>): Observable<void> {
        return this.countryClient.read().once((all) => {
            const state = context.getState();
            state.all = all;
            context.setState(state);
        });
    }

    @Action(SetInvestorCountries)
    public setInvestorCountries(context: StateContext<CountryListModel>): Observable<void> {
        return this.countryClient.investors().once((investor) => {
            const state = context.getState();
            state.investor = investor;
            context.setState(state);
        });
    }

    @Action(SetInvesteeCountries)
    public setInvesteeCountries(context: StateContext<CountryListModel>, action: SetInvesteeCountries): Observable<void> {
        return this.countryClient.investee(action.dataAsOf, action.investorCountryId).once((investee) => {
            const state = context.getState();
            state.investee = investee;
            context.patchState(state);
        });
    }
}
