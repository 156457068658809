export enum EquationGroupName {
    SingleCountryCapm = 'Single Country CAPM',
    SingleCountryFisherEffect = 'Single Country Fisher Effect',
    SingleCountryCostOfDebt = 'Single Country Cost of Debt',
    SingleCountryWacc = 'Single Country WACC',
    CapmCountryCreditRating = 'CAPM + Country Credit Rating Model CRP',
    CapmYieldSpread = 'CAPM + Country Yield Spread CRP',
    CapmRelativeVolatility = 'CAPM with Relative Volatility Model RV Factor',
    CountryCreditRatingModel = 'Country Credit Rating Model',
    CountryYieldSpreadModel = 'Country Yield Spread Model',
    RelativeVolatilityFactorModel = 'Relative Volatility Factor Model',
    KdCountryCreditRating = 'Cost of Debt + Country Credit Rating Model CRP',
    KdYieldSpread = 'Cost of Debt + Country Yield Spread Model CRP',
    KdRelativeVolatility = 'Cost of Debt with Relative Volatility Model RV Factor',
    WaccCountryCreditRating = 'WACC + Country Credit Rating Model CRP',
    WaccYieldSpread = 'WACC + Yield Spread Model CRP',
    WaccRelativeVolatility = 'WACC with Relative Volatility Model RV Factor'
}
