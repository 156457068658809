import { Component, OnInit } from '@angular/core';
import { CocInputsSharedService } from '../../service/cocInputShared.service';
import { countryRiskPremiumHeaders, countryRiskSupplyHeaders } from '../../commonHeaders';
import { UserStore } from 'src/app/_navigator/user/store/user.store';
import { CostOfEquityClient } from 'src/app/_api/clients/costOfEquity.client';
import { CountryRiskPremium, TierMethodologyData } from '../../models/countryRiskPremium';
import { Spinner } from '@concurrency/angular';
import { crpDataMap } from '../../model-map-functions';
import { enumExcelExportSheets } from '../../cocViewInputs';
import { CocinputsExcelExportService } from '../../service/cocinputs-excel-export.service';
import * as moment from 'moment';

@Component({
    selector: 'country-risk-premium-relative-voltality',
    templateUrl: './country-risk-premium-relative-voltality.component.html',
    styleUrls: ['./country-risk-premium-relative-voltality.component.scss']
})
export class CountryRiskPremiumRelativeVoltalityComponent implements OnInit {
    exportFileName!: string;

    constructor(private sharedCocInputService: CocInputsSharedService, private userStore: UserStore, private csostOfEquityClient: CostOfEquityClient, private objspinner: Spinner, private exportService: CocinputsExcelExportService) { }

    public supplyHeaders = countryRiskSupplyHeaders;
    public RPtblHeaders = countryRiskPremiumHeaders;
    public cntryRiskPremiumData!: CountryRiskPremium[];
    public tierData!: TierMethodologyData[];
    public isSupplyViewd!: Boolean
    public inflationDataAsOf!: string;
    public taxRateDataAsOf!: string;

    ngOnInit(): void {
        this.getCountryRiskPremiumData();
    }





    exportToExcel(component: any) {


        let enumdataStudy = enumExcelExportSheets.CRP
        this.exportFileName = 'CRP-and-RV-data'
        this.exportService.generateWorkBook(component, enumdataStudy);


    }


    onClickCRPSupplyData() {
        this.objspinner.begin();
        let valuationDate = this.sharedCocInputService.cocInputValues.valuationDate;
        let requestCountryId = this.sharedCocInputService.cocInputValues.investorCountryId;
        this.csostOfEquityClient.getTirerMethodologyData(requestCountryId, valuationDate).onceDefined((data: TierMethodologyData[]) => {
            this.tierData = data;
            this.cntryRiskPremiumData.forEach(obj => {
                obj.Tier = this.tierData.find(item => obj.InvesteeContryId == item.InvesteeCountryID)?.Tier;
            });
            this.isSupplyViewd = true;
            this.objspinner.end();

        });

    }

    getCountryRiskPremiumData() {
        this.objspinner.begin()
        // let requestCountryId = this.sharedCocInputService.cocInputValues.investorCountryId;
        let valuationDate = this.sharedCocInputService.cocInputValues.valuationDate;
        let requestCountryId = +this.sharedCocInputService.cocInputValues.investorCountryId;
        this.csostOfEquityClient.getCountryRiskPremiumData(requestCountryId, valuationDate).
            onceDefined((investeeDetails: CountryRiskPremium[]) => {
            if (investeeDetails && investeeDetails.length > 0) {
                const inflationLabel = investeeDetails[0]?.InflationLabel;
                const startIndex = inflationLabel.indexOf('Expected');
                const inflationLabelHeader = startIndex !== -1 ? `Long Term ${inflationLabel.substring(startIndex)}` : "";
                const index = investeeDetails[0]?.IntlTaxRateLabel.search(/\d/);
                const taxRate = investeeDetails[0]?.IntlTaxRateLabel.substring(index);
                const IntlTaxRateDataAsOf = investeeDetails[0]?.IntlTaxRateDataAsOf;

                if (investeeDetails.length > 0) {
                    this.cntryRiskPremiumData = crpDataMap(investeeDetails);
                    this.objspinner.end();
                }

                //Overiding Inflation and  TaxRate based on Data 
                this.supplyHeaders[4] = inflationLabelHeader;
                this.supplyHeaders[5] = taxRate;
                this.inflationDataAsOf = "Data as of " + moment(this.cntryRiskPremiumData[0].InflationDataAsOf).format('MM/DD/YYYY');
                this.taxRateDataAsOf =  "Data as of " + moment(IntlTaxRateDataAsOf).format('MM/DD/YYYY');

            } else {
                this.objspinner.end();
            }

            });

    }

}
