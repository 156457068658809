import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SubscriberEntity } from '@concurrency/angular';
import { ContextualNumber, Util } from '@concurrency/core';
import { takeUntil } from 'rxjs/operators';
import { SelectionType } from 'src/app/_api/enums/selection-type';
import { InputType } from 'src/app/_api/responses/input.response';
import { EquationOperand, EquationParameter, LabelType } from 'src/app/_navigator/data/model/equation.model';
import { Scenario } from 'src/app/_navigator/data/model/scenario.model';
import { DataStore } from 'src/app/_navigator/data/store/data.store';
import { NumberFormatUtil } from '../../data/util/number-format.util';

@Component({
    selector: 'equation-parameter',
    templateUrl: './equation-parameter.component.html'
})
export class EquationParameterComponent extends SubscriberEntity implements OnInit {
    // TODO: Move these to EventManager
    @Output() public beginEdit = new EventEmitter();
    @Output() public saveEdit = new EventEmitter();
    @Output() public cancelEdit = new EventEmitter();

    @Input() public id: string = Math.random().toString(36).substring(2);
    @Input() public parameter!: EquationParameter;
    @Input() public scenario!: Scenario;
    @Input() public allowHover!: boolean;
    @Input() public showLabels!: boolean;

    // TODO: Eliminate many of these variables in favor of pre-calculating them during loadModel
    //      and then making functional calls with the values
    public model!: ContextualNumber;
    public displayModel?: string;
    public isHoverActive = false;
    public isEditorActive = false;
    public isEditorValid?: boolean;
    public placeholderHTML?: string;
    public secondaryLabel?: string;

    constructor(
        private dataStore: DataStore
    ) { super(); }

    // TODO: Overhaul all this secondary label code, its rusty
    private setSecondaryLabel(): void {
        const operand = this.parameter.item as EquationOperand;
        if (operand == null || this.model == null || this.model.hasValue === false) {
            return;
        }
        const secondaryLabel = operand.labelType;
        if (secondaryLabel == null) {
            return;
        }

        if (secondaryLabel === LabelType.LeveredDebt) {
            this.setSecondaryLabelToLeveredDebt();
        } else if (secondaryLabel === LabelType.ReleveredDebt) {
            this.setSecondaryLabelToReleveredDebt();
        } else if (secondaryLabel === LabelType.UnleveredDebt) {
            this.setSecondaryLabelToUnleveredDebt();
        } else if (secondaryLabel === LabelType.ZScore) {
            this.setSecondaryLabelToZScore();
        }
    }

    private setSecondaryLabelToLeveredDebt(): void {
        const operand = this.parameter.item as EquationOperand;
        const debtEquityRatioType = SelectionType.RiskPremiumOverRiskFreeRateLeveredDebtEquityRatio;
        const rprDebtEquityRatio = this.scenario.getSelection(debtEquityRatioType, operand.inputType || InputType.None);

        if (rprDebtEquityRatio.Value) {
            const debtRatio = Util.round(rprDebtEquityRatio.Value * 100);
            this.secondaryLabel = `D/E ${debtRatio}%`; // `
        } else {
            this.secondaryLabel = '';
        }
    }

    private setSecondaryLabelToUnleveredDebt(): void {
        this.secondaryLabel = `D/E 0.00%`; // `
    }

    private setSecondaryLabelToReleveredDebt(): void {
        // TODO: Obviate takeUntil by using Async pipes and local Observable streams
        this.dataStore.estimate.pipe(takeUntil(this.destroyed)).whileDefined((estimate) => {
            const debt = estimate.getInput(InputType.DebtRatioEquity);
            if (debt.Value) {
                this.secondaryLabel = `D/E ${NumberFormatUtil.numberWithCommas(debt.Value)}%`; // `
            }
        });
    }

    private setSecondaryLabelToZScore(): void {
        this.dataStore.estimate.whileDefined((estimate) => {
            const zscore = estimate.getInput(InputType.ZScore);
            if (zscore.Value) {
                this.secondaryLabel = `Z-Score ${NumberFormatUtil.numberWithCommas(zscore.Value)}`; // `
            }
        });
    }

    private hideEditor(): void {
        this.isEditorActive = false;
        this.dataStore.triggerEditorActivity(false);
    }

    public ngOnInit(): void {
        const loadModelFromScenario = () => {
            this.model = this.scenario.getItemAsContextualNumber(this.parameter.item);
            this.displayModel = this.model.hasValue
                ? (this.parameter.item.mask || '{}%').replace('{}', this.model.asStringOr(''))
                : undefined;
        };

        loadModelFromScenario();
        this.isHoverActive = false;
        this.setSecondaryLabel();

        // TODO: Obviate takeUntil by using Async pipes and local Observable streams
        this.dataStore.recalculate.pipe(takeUntil(this.destroyed)).while(() => loadModelFromScenario());
        this.dataStore.editorValidity.pipe(takeUntil(this.destroyed)).while((validity) => this.isEditorValid = validity);
        this.dataStore.lastEditorHoveredId.pipe(takeUntil(this.destroyed)).while((id) => {
            if (this.id !== id) {
                this.isHoverActive = false;
            }
        });
    }

    public activateHover(): void {
        if (this.allowHover && this.parameter.canEdit) {
            this.isHoverActive = true;
            this.dataStore.setLastEditorHovered(this.id);
            this.edit();
        }
    }

    public isReadyForEdit(): boolean {
        const operand = this.parameter.item as EquationOperand;
        if (operand.dependentType != null) {
            return this.scenario.hasValue(operand.dependentType);
        } else {
            return true;
        }
    }

    // TODO: Have this populate a local variable only when something changes
    public getName(): string | null {
        if (this.model && this.model.context && this.model.hasValue) {
            const splitSource = this.model.context.replace('(ROE)', '').split('(');
            const rightSplit = splitSource[1] ? `(${splitSource[1]}` : ``;
            return `${splitSource[0].toUpperCase().replace(/(\r\n)/g, '<br/>')}${rightSplit}`;
        } else {
            return this.parameter.item.name.toUpperCase();
        }
    }

    public edit(): void {
        this.beginEdit.emit(this.parameter.item);
        this.isEditorActive = true;
        this.dataStore.triggerEditorActivity(true);
        this.dataStore.triggerEditorValidity(true);

        this.dataStore.editorExitState.whileDefined((state) => {
            if (this.isEditorActive) {
                if (state) {
                    this.save();
                } else {
                    this.cancel();
                }
            }
        });
    }

    public cancel(): void {
        this.cancelEdit.emit();
        this.hideEditor();
        this.isHoverActive = false;
        this.activateHover();
    }

    public save(): void {
        if (this.isEditorValid === false) {
            return;
        }

        this.saveEdit.emit();
        this.hideEditor();
        this.isHoverActive = false;
    }
}
