export enum Tab {
    Inputs,
    Studies,
    Results,
    USIndustry,
    BenchmarkingInputs,
    BenchmarkingAnalysis,
    InternationalInputs,
    CostOfEquityInputs,
    CountryRiskPremiaSummary,
    FisherEffect,
    CostOfEquitySummary,
    WaccInputs,
    CostOfDebtSummary,
    WaccSummary
}
