import { IndustryMetric } from 'src/app/_api/responses/industry-metric.response';
import { Categories } from '../const/categories.const';
import { Metric } from '../enums/metric';
import { FlattenModel, MetricStatistic } from '../models/metric-statistic.model';
import { StatisticsCategory } from '../models/statistics-category.model';
import { StatisticsGroup } from '../models/statistics-group.model';
import { TearSheetUtil } from './tear-sheet.util';

export class StatisticsUtil {

    private static StaticticsMap: { [key: string]: string }
        = { geometricMean: "Geometric Mean", arithmeticMean: "Arithmetic Mean", standardDeviation: "Std. Deviation" }

    public static processStatisticsMapping(data: IndustryMetric[]): StatisticsCategory[] {
        const statisticsCategories: StatisticsCategory[] = [];
        const statistics: StatisticsGroup[] = [];


        const industryStatistics = data.filter(
            (x) => x.MetricId === Metric.OneYearIndustry
                || x.MetricId === Metric.ThreeYearIndustry
                || x.MetricId === Metric.FiveYearIndustry);

        const industryGroup: StatisticsGroup = {
            groupName: Categories.Industry,
            statistics: this.convertStatisticValues(industryStatistics)
        };

        statistics.push(industryGroup);

        const sp500Statistics = data.filter(
            (x) => x.MetricId === Metric.OneYearSp
                || x.MetricId === Metric.ThreeYearSp
                || x.MetricId === Metric.FiveYearSp
        );

        let spGroup = {} as StatisticsGroup;

        if (sp500Statistics.length) {
            spGroup = {
                groupName: Categories.MarketBenchmark,
                statistics: this.convertStatisticValues(sp500Statistics)
            };

            statistics.push(spGroup);
        }

        const MSCIEurStatistics = data.filter(
            (x) => x.MetricId === Metric.OneYearMSCIEur
                || x.MetricId === Metric.ThreeYearMSCIEur
                || x.MetricId === Metric.FiveYearMSCIEur
        );

        let MSCIEurGroup = {} as StatisticsGroup;

        if (MSCIEurStatistics.length) {
            MSCIEurGroup = {
                groupName: Categories.MarketBenchmark,
                statistics: this.convertStatisticValues(MSCIEurStatistics)
            };

            statistics.push(MSCIEurGroup);
        }

        const MSCIWorldStatistics = data.filter(
            (x) => x.MetricId === Metric.OneYearMSCIWorld
                || x.MetricId === Metric.ThreeYearMSCIWorld
                || x.MetricId === Metric.FiveYearMSCIWorld
        );

        let MSCIWorldGroup = {} as StatisticsGroup;

        if (MSCIWorldStatistics.length) {
            MSCIWorldGroup = {
                groupName: Categories.MarketBenchmark,
                statistics: this.convertStatisticValues(MSCIWorldStatistics)
            };

            statistics.push(MSCIWorldGroup);
        }

        const statisticCategory: StatisticsCategory = {
            statisticsCategoryName: Categories.AnnualizedMonthlyPerformanceStatistics,
            statisticsGroup: statistics
        };
        statisticsCategories.push(statisticCategory);


        return statisticsCategories;
    }

    private static convertStatisticValues(industryData: IndustryMetric[]): MetricStatistic[] {
        const statistics: MetricStatistic[] = [];

        industryData.forEach((x) => {
            const value: MetricStatistic = {
                year: x.MetricName,
                geometricMean: TearSheetUtil.roundTearSheetValue(x.GeometricMean, false),
                arithmeticMean: TearSheetUtil.roundTearSheetValue(x.ArithmeticMean, false),
                standardDeviation: TearSheetUtil.roundTearSheetValue(x.StandardDeviation, false)
            };
            statistics.push(value);
        });

        return statistics;
    }


    public static getFlattendData(IndustryData: IndustryMetric[]): FlattenModel {
        const data = this.processStatisticsMapping(IndustryData)[0];
        const obj = {} as FlattenModel
        const colNames = Object.keys(data.statisticsGroup[0].statistics[0]).filter(s => s != 'year').map(k => this.StaticticsMap[k])
        const rowNames = data.statisticsGroup[0].statistics.map(s => s.year);
        const dataArr: string[][] = [];

        if (rowNames.length > 0) {

            rowNames.forEach(rn => {
                let tempArr: string[] = [];
                tempArr.push(rn);
                data.statisticsGroup.forEach(g => {
                    let obj = g.statistics.find(m => m.year == rn);
                    if (obj != null) {

                        tempArr.push(obj.geometricMean, obj.arithmeticMean, obj.standardDeviation);
                    }
                });
                dataArr.push(tempArr);
            });

            obj.colNames = colNames;
            obj.statisticsCategoryName = data.statisticsCategoryName;
            obj.data = dataArr;
            obj.groupNames = data.statisticsGroup.map(d => d.groupName);

            return obj;
        }

        return obj;


    }
}
