import * as moment from 'moment';

import { Injectable } from '@angular/core';
import {
    ExcelExportComponent,
    Workbook,
    WorkbookSheetRow,
    WorkbookSheetRowCell
} from '@progress/kendo-angular-excel-export';
import { IndustryMetric } from 'src/app/_api/responses/industry-metric.response';
import { ExcelExportUtil } from 'src/app/estimate/exports/util/excel-export.util';
import { TrendsOverTimeIndustry } from '../../../benchmarking/data/trends-over-time-industry';
import { TrendsOverTimeTableData } from '../../../benchmarking/data/trends-over-time-table-data';
import { TearSheetService } from '../../tear-sheet/data/tear-sheet.service';
import { TrendsOverTimeService } from '../../tear-sheet/data/trends-over-time.service';

@Injectable()
export class TrendsOverTimeExcelExportService {

    private moduleName = 'U.S. Industry Benchmarking Module'; // TODO rename this based on subscription or url

    public columnWidth = 125;       // width of each cell in grid system
    public blueColor = '#14487f'; // color of all orange items
    public numberFormat = '0.00';   // number format for cells
    constructor(
        private trendsOverTimeService: TrendsOverTimeService,
        private tearSheetService: TearSheetService
    ) { }

    private saveWorksheet(component: ExcelExportComponent, tableData: TrendsOverTimeTableData): void {

        let excelExport = this.getWorksheet(tableData);
        excelExport = this.tearSheetService.setDefaultExcel(excelExport);
        component.save(excelExport);
    }

    private getWorksheet(tableData: TrendsOverTimeTableData): Workbook {
        const valuationDate = tableData.industries[0].ValuationDate;

        const workbook = new Workbook();
        workbook.sheets = [{
            name: `Trends Over Time`,
            columns: Array(8).fill({ width: this.columnWidth }),
            rows: [
                ...this.getHeader(valuationDate),
                ...this.buildTableData(tableData),
                ...ExcelExportUtil.getFooter(this.moduleName)
            ]
        }];

        return workbook;
    }

    private getHeader(valuationDate: string): WorkbookSheetRow[] {
        const dateValue = new Date(valuationDate);

        const row: WorkbookSheetRow[] = [{
            cells: [{
                value: `Data Updated Through : ${moment(dateValue).format('MM/DD/YYYY')}`,
                bold: true,
                colSpan: 6,
                borderBottom: { size: 2 }
            }]
        }];

        return row;
    }

    private getCompLabels(): WorkbookSheetRow[] {
        const medianLabel = 'Median';
        const sicCompositeLabel = 'Industry Composite';
        const largeCompositeLabel = 'Large Composite';
        const smallCompositeLabel = 'Small Composite';
        const highFinancialRiskLabel = 'High-Financial Risk';

        // TODO create workbook sheet row builder
        const row: WorkbookSheetRow[] = [
            {
                cells: [{
                    value: '',
                    colSpan: 1
                }]
            },
            {
                cells: [{
                    value: '',
                    colSpan: 1
                }]
            },
            {
                cells: [{
                    value: medianLabel,
                    colSpan: 1
                }]
            },
            {
                cells: [{
                    value: sicCompositeLabel,
                    colSpan: 1
                }]
            },
            {
                cells: [{
                    value: largeCompositeLabel,
                    colSpan: 1
                }]
            },
            {
                cells: [{
                    value: smallCompositeLabel,
                    colSpan: 1
                }]
            },
            {
                cells: [{
                    value: highFinancialRiskLabel,
                    colSpan: 1
                }]
            }];

        return row;
    }

    private buildMetricTableRow(metric: IndustryMetric): WorkbookSheetRow[] {
        const tableRow: WorkbookSheetRow[] = [];
        let row: WorkbookSheetRow = {};
        row.cells = [];
        let cl: WorkbookSheetRowCell = {};

        // TODO construct builder to build this out and return the entire result row
        cl = ExcelExportUtil.createCell(metric.MedianLatest, 1, this.numberFormat);
        row.cells.push(cl);

        tableRow.push(row);

        row = {};
        row.cells = [];

        cl = ExcelExportUtil.createCell(metric.SICCompositeLatest, 1, this.numberFormat);
        row.cells.push(cl);

        tableRow.push(row);

        row = {};
        row.cells = [];

        cl = ExcelExportUtil.createCell(metric.LargeCompositeLatest, 1, this.numberFormat);
        row.cells.push(cl);

        tableRow.push(row);

        row = {};
        row.cells = [];

        cl = ExcelExportUtil.createCell(metric.SmallCompositeLatest, 1, this.numberFormat);
        row.cells.push(cl);

        tableRow.push(row);

        row = {};
        row.cells = [];

        cl = ExcelExportUtil.createCell(metric.HighFinancialRiskLatest, 1, this.numberFormat);
        row.cells.push(cl);

        tableRow.push(row);

        row = {};
        row.cells = [];

        return tableRow;
    }

    private buildScenarioHeader(industryData: TrendsOverTimeIndustry): WorkbookSheetRow[] {
        const header = this.trendsOverTimeService.createIndustryFilterLabel(industryData.SicId, industryData.GicId);

        const row: WorkbookSheetRow[] = [{
            cells: [{
                value: `${header} - ${industryData.Sector} (${industryData.Area} - ${industryData.CurrencyCode})`,
                colSpan: 4,
                borderBottom: { size: 2, color: this.blueColor },
                bold: true,
                color: this.blueColor
            },
            {
                value: '',
                colSpan: 1
            }
            ]
        }];

        return row;
    }

    private buildDataAsOfTitle(title: string, width: number): WorkbookSheetRow[] {
        const dateValue = new Date(title);

        const row: WorkbookSheetRow[] = [{
            cells: [{
                value: moment(dateValue).format('MM/DD/YYYY'),
                colSpan: width,
                borderBottom: { size: 3 },
                bold: true
            }]
        }];

        return row;
    }

    private buildTableData(tableData: TrendsOverTimeTableData): WorkbookSheetRow[] {
        let resultData: WorkbookSheetRow[] = [];
        let scenarioHeader: WorkbookSheetRow[] = [];
        let rightSideTableData: WorkbookSheetRow[] = [];
        let combineCompLables: WorkbookSheetRow[] = [];

        const titleWidth = 1;

        tableData.trendsOverTimeData.forEach((scenario, index) => {
            scenarioHeader = this.buildScenarioHeader(tableData.industries[index]);

            scenario.forEach((metric) => {
                let tableTitle = this.buildDataAsOfTitle(metric.DataAsOf, titleWidth);
                const tableRow = this.buildMetricTableRow(metric.Metric);

                tableTitle = tableTitle.concat(tableRow);

                if (rightSideTableData.length > 0) {
                    rightSideTableData = this.concatenateRows(rightSideTableData, tableTitle);
                } else {
                    rightSideTableData = tableTitle;
                }
            });

            rightSideTableData = scenarioHeader.concat(rightSideTableData);
            combineCompLables = this.concatenateRows(this.getCompLabels(), rightSideTableData);

            resultData = resultData.concat(combineCompLables);
            rightSideTableData = [];
        });

        return resultData;
    }

    private concatenateRows(rowDataLeft: WorkbookSheetRow[], rowDataRight: WorkbookSheetRow[]): WorkbookSheetRow[] {
        const leftWidth: number = ExcelExportUtil.getWidthOfWidestRow(rowDataLeft);

        const rowData: WorkbookSheetRow[] = [];
        for (let i = 0; i < rowDataLeft.length; ++i) {
            if (i < rowDataRight.length) { // row in right side, add it to the left
                const leftCells = rowDataLeft[i].cells;
                const rightCells = rowDataRight[i].cells;

                if (leftCells && rightCells) {
                    let newCells: WorkbookSheetRowCell[] = [];

                    newCells = leftCells.concat(rightCells);
                    rowDataLeft[i].cells = newCells;
                }

                rowData.push(rowDataLeft[i]);
            }
        }

        //
        //  process any rows on right beyond the left
        //
        for (let j: number = rowDataLeft.length; j < rowDataRight.length; ++j) {
            const rightCells = rowDataRight[j].cells;

            if (rightCells) {
                let newCells: WorkbookSheetRowCell[] = ExcelExportUtil.getEmptyCell(leftWidth);
                newCells = newCells.concat(rightCells);
                rowDataRight[j].cells = newCells;
                rowData.push(rowDataRight[j]);
            }

        }

        return rowData;
    }

    public save(component: ExcelExportComponent, tableData: TrendsOverTimeTableData): void {
        this.saveWorksheet(component, tableData);
    }
}
