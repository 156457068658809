import { Component, OnInit } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { EnumSubsType } from 'src/app/costofcapital-inputs/models/userSubscriptionTypes';
import { CocInputsSharedService } from 'src/app/costofcapital-inputs/service/cocInputShared.service';

@Component({
    selector: 'trends-over-time-rfr-and-erp',
    templateUrl: './trends-over-time-rfr-and-erp.component.html',
    styleUrls: ['./trends-over-time-rfr-and-erp.component.scss']
})
export class TrendsOverTimeRfrAndErpComponent implements OnInit {

    public display: boolean = true;
    public RFRTrendsOverTime!: any;
    public isChartexpanded: boolean = false;
    public dataNotAvailble: boolean = false;

    constructor(private cocInputsShareService: CocInputsSharedService) { }

    ngOnInit(): void {
        if (this.cocInputsShareService.spot10YearYields.length == 0 &&
            this.cocInputsShareService.spot20YearYields.length == 0 &&
            this.cocInputsShareService.spot30YearYields.length == 0 &&
            this.cocInputsShareService.dpRecommendedRfrs.length == 0 &&
            this.cocInputsShareService.dataAsOfDates.length == 0) {
            this.dataNotAvailble = true;
        }
        this.showChart(800, 400);
    }

    private resizeChart(): void {
        this.isChartexpanded = !this.isChartexpanded;
        if (this.isChartexpanded) {
            this.showChart(1100, 500);
        } else {
            this.showChart(800, 400);
        }
    }


    private showChart(widht: number, height: number) {
        this.RFRTrendsOverTime = new Chart({
            chart: {
                height: height,
                width: widht,
                type: 'line',
                style: {
                    fontFamily: "nunito-sans"
                }
            },
            rangeSelector: {
                selected: 1
            },
            legend: {
                itemMarginBottom: 10
            },
            title: {
                text: ''
            },
            series: this.getSeriesBsedonCountryData(),
            xAxis: {
                title: {
                    text: ''
                },
                categories: [...this.cocInputsShareService.dataAsOfDates],
                gridLineWidth: 0,
                gridLineColor: 'transparent',
                tickPositions: [0, this.cocInputsShareService.dataAsOfDates.length - 1]
            },
            yAxis: {
                title: {
                    text: 'Values'
                },
                tickInterval: 0.001,
                gridLineWidth: 0,
                gridLineColor: 'transparent',
                labels: {
                    format: '{value:.2f}%'
                }
            },
            tooltip: {
                pointFormat: '<br> {series.name} : {point.y:.2f}% </br>',
                shared: true,
            },
            credits: {
                enabled: false
            }
        });
        this.RFRTrendsOverTime
    }


    private getSeriesBsedonCountryData(): any[] {
        const investorPregion = this.cocInputsShareService.cocInputValues.investorPerspectiveregion;
        const userPerms = this.cocInputsShareService.usrPerms.SubsType;
        const us = "United States"
        const series = [];
        this.cocInputsShareService.spot10YearYields.find(s => s != 0.00) && series.push({
            type: 'line',
            name: userPerms == EnumSubsType.Coc ? 'Spot 10-year Treasury Yield' : `Spot 10-year Yield (${investorPregion})`,
            color: 'rgb(20, 72, 127)',
            data: [...this.cocInputsShareService.spot10YearYields],
            marker: {
                enabled: false
            }
        });


        // for keeping  spot15YearYields object after spot10YearYields of the array intentionally (to maintain Order).
        if (investorPregion != "United States") {

            this.cocInputsShareService.spot15YearYields.find(s => s != 0.00) && series.push({
                type: 'line',
                name: `Spot 15-year Yield (${investorPregion})`,
                color: 'rgb(67, 120, 160)',
                data: [...this.cocInputsShareService.spot15YearYields],
                marker: {
                    enabled: false
                }
            });


        }


        this.cocInputsShareService.spot20YearYields.find(s => s != 0.00) && series.push({
            type: 'line',
            name: userPerms == EnumSubsType.Coc ? 'Spot 20-year Treasury Yield' : `Spot 20-year Yield (${investorPregion})`,
            color: 'rgb(208, 0, 112)',
            data: [...this.cocInputsShareService.spot20YearYields],
            marker: {
                enabled: false
            }
        });

        this.cocInputsShareService.spot30YearYields.find(s => s != 0.00) && series.push({
            type: 'line',
            name: userPerms == EnumSubsType.Coc ? 'Spot 30-year Treasury Yield' : `Spot 30-year Yield (${investorPregion})`,
            color: 'rgb(76, 159, 200)',
            data: [...this.cocInputsShareService.spot30YearYields],
            marker: {
                enabled: false
            },
        },);


        this.cocInputsShareService.dpRecommendedRfrs.find(s => s != '') && series.push({
            type: 'line',
            name: userPerms == EnumSubsType.Coc ? 'Kroll Normalized Risk-free Rate' : `Normalized Risk-free Rate (${investorPregion})`,
            color: 'rgb(67, 176, 73)',
            data: [...this.cocInputsShareService.dpRecommendedRfrs],
            marker: {
                enabled: false
            }
        });

        // for keeping  longTermYields object at end of the array intentionally (to maintain Order).
        if (investorPregion != "United States") {

            this.cocInputsShareService.longTermYields.find(s => s != 0.00) && series.push({
                type: 'line',
                name: `Spot long-term Yield (${investorPregion})`,
                color: 'rgb(187, 221, 230)',
                data: [...this.cocInputsShareService.longTermYields],
                marker: {
                    enabled: false
                }
            });

        }

        return series;

    }
}
