import { betaCompanyCellHeight, betaNormalFontSize, betaTaxRateLabelHeight, krollblue } from '../../estimate/exports/summary-export/util/summary-helpers';
import { BetaOptions } from '../beta.types';
import { getAdjustedRSquareOls, getAdjustedRSquareSum, getSignificantLevel1, getSignificantLevel2, getSignificantLevel3, getStandardError, getTStat } from './beta-statistics';
import { getCompanies } from './companies';
import { getBlumeBeta, getOlsBeta, getPortfolioBeta, getSumBeta, getVasicekBeta } from './levered-betas';
import { getObservations } from './observations';
import { getBetaType, getReleveredBetaTitles, getReleveredTaxRate, getStatistics, getTargetCapital } from './relevered-betas';
import { getBookValue, getDebtEquity, getMarketValue, getTaxRate, getTaxRateLabel } from './unlevering-inputs';
import { getMarketCap, getMarketCapWeighting, getSales, getSalesWeighting } from './weighting';

export function companyLevelBetas(options: BetaOptions): any[] {
    const inputs: any[] = [];
    inputs.push(
        {
            height: betaCompanyCellHeight,
            cells: [{ value: '' }]
        },
        {
            height: 90,
            cells: getCompanies(options.Companies)
        },
        {
            height: betaCompanyCellHeight,
            font: betaNormalFontSize,
            cells: getObservations(options.Companies)
        },
        {
            height: betaCompanyCellHeight,
            cells: [{ value: '' }]
        },
        {
            height: betaCompanyCellHeight,
            cells: [{ value: '' }]
        },
        {
            height: betaCompanyCellHeight,
            font: betaNormalFontSize,
            cells: [
                {
                    value: options.GeneralInputs.Currency != null ? 'All values below are denominated in ' + options.GeneralInputs.Currency : '',
                    italic: true
                }
            ]
        },
        {
            height: betaCompanyCellHeight,
            font: betaNormalFontSize,
            cells: [
                {
                    value: 'Levered Betas',
                    colSpan: 2,
                    bold: true
                }
            ]
        },
        {
            height: betaCompanyCellHeight,
            font: betaNormalFontSize,
            cells: getOlsBeta(options.LeveredBetas)
        },
        {
            height: betaCompanyCellHeight,
            font: betaNormalFontSize,
            cells: getSumBeta(options.LeveredBetas)
        },
        {
            height: betaCompanyCellHeight,
            font: betaNormalFontSize,
            cells: getVasicekBeta(options.LeveredBetas)
        },
        {
            height: betaCompanyCellHeight,
            font: betaNormalFontSize,
            cells: getBlumeBeta(options.LeveredBetas)
        },
        {
            height: betaCompanyCellHeight,
            font: betaNormalFontSize,
            cells: getPortfolioBeta(options.LeveredBetas)
        },
        {
            height: betaCompanyCellHeight,
            cells: [{ value: '' }]
        },
        {
            height: betaCompanyCellHeight,
            font: betaNormalFontSize,
            cells: [
                { value: '' },
                {
                    value: 'Weighting Inputs:',
                    italic: true
                }
            ]
        },
        {
            height: betaCompanyCellHeight,
            font: betaNormalFontSize,
            cells: getSales(options.Companies)
        },
        {
            height: betaCompanyCellHeight,
            font: betaNormalFontSize,
            cells: getSalesWeighting(options.Companies)
        },
        {
            height: betaCompanyCellHeight,
            font: betaNormalFontSize,
            cells: getMarketCap(options.Companies)
        },
        {
            height: betaCompanyCellHeight,
            font: betaNormalFontSize,
            cells: getMarketCapWeighting(options.Companies)
        },
        {
            height: betaCompanyCellHeight,
            cells: [{ value: '' }]
        },
        {
            height: betaCompanyCellHeight,
            font: betaNormalFontSize,
            cells: [
                {
                    value: 'Betas Statistics',
                    colSpan: 2,
                    bold: true
                }
            ]
        },
        {
            height: betaCompanyCellHeight,
            font: betaNormalFontSize,
            cells: getAdjustedRSquareOls(options.BetaStatitics)
        },
        {
            height: betaCompanyCellHeight,
            font: betaNormalFontSize,
            cells: getAdjustedRSquareSum(options.BetaStatitics)
        },
        {
            height: betaCompanyCellHeight,
            cells: [{ value: '' }]
        },
        {
            height: betaCompanyCellHeight,
            font: betaNormalFontSize,
            cells: [
                { value: '' },
                {
                    value: 'OLS Beta Statistics:',
                    italic: true
                }
            ]
        },
        {
            height: betaCompanyCellHeight,
            font: betaNormalFontSize,
            cells: getStandardError(options.BetaStatitics)
        },
        {
            height: betaCompanyCellHeight,
            font: betaNormalFontSize,
            cells: getTStat(options.BetaStatitics)
        },
        {
            height: betaCompanyCellHeight,
            cells: [{ value: '' }]
        },
        {
            height: betaCompanyCellHeight,
            font: betaNormalFontSize,
            cells: [
                { value: '' },
                {
                    value: 'Is Significant at level:'
                }
            ]
        },
        {
            height: betaCompanyCellHeight,
            font: betaNormalFontSize,
            cells: getSignificantLevel1(options.BetaStatitics)
        },
        {
            height: betaCompanyCellHeight,
            font: betaNormalFontSize,
            cells: getSignificantLevel2(options.BetaStatitics)
        },
        {
            height: betaCompanyCellHeight,
            font: betaNormalFontSize,
            cells: getSignificantLevel3(options.BetaStatitics)
        },
        {
            height: betaCompanyCellHeight,
            cells: [{ value: '' }]
        },
        {
            height: betaCompanyCellHeight,
            font: betaNormalFontSize,
            cells: [
                {
                    value: 'Unlevering Inputs',
                    colSpan: 2,
                    bold: true
                }
            ]
        },
        {
            height: betaCompanyCellHeight,
            font: betaNormalFontSize,
            cells: getBookValue(options.UnleveringBetas)
        },
        {
            height: betaCompanyCellHeight,
            font: betaNormalFontSize,
            cells: getMarketValue(options.UnleveringBetas)
        },
        {
            height: betaCompanyCellHeight,
            font: betaNormalFontSize,
            cells: getDebtEquity(options.UnleveringBetas)
        },
        {
            height: betaCompanyCellHeight,
            font: betaNormalFontSize,
            cells: getTaxRate(options.UnleveringBetas)
        },
        {
            height: betaTaxRateLabelHeight,
            font: betaNormalFontSize,
            cells: getTaxRateLabel(options.UnleveringBetas)
        },
        {
            height: betaCompanyCellHeight,
            cells: [{ value: '' }]
        },
        {
            height: betaCompanyCellHeight,
            font: betaNormalFontSize,
            cells: [
                {
                    value: 'Unlevered Betas (Unlevered Beta = Beta (Projected) / [1 + D/E ( 1 - t )])',
                    colspan: 6,
                    bold: true
                }
            ]
        },
        {
            height: betaCompanyCellHeight,
            font: betaNormalFontSize,
            cells: getOlsBeta(options.UnleveredBetas)
        },
        {
            height: betaCompanyCellHeight,
            font: betaNormalFontSize,
            cells: getSumBeta(options.UnleveredBetas)
        },
        {
            height: betaCompanyCellHeight,
            font: betaNormalFontSize,
            cells: getVasicekBeta(options.UnleveredBetas)
        },
        {
            height: betaCompanyCellHeight,
            font: betaNormalFontSize,
            cells: getBlumeBeta(options.UnleveredBetas)
        },
        {
            height: betaCompanyCellHeight,
            font: betaNormalFontSize,
            cells: getPortfolioBeta(options.UnleveredBetas)
        },
        {
            height: betaCompanyCellHeight,
            cells: [{ value: '' }]
        },
        {
            height: betaCompanyCellHeight,
            cells: [{ value: '' }]
            // borderBottom: { size: 2, color: krollblue }
        },
        {
            height: betaCompanyCellHeight,
            font: betaNormalFontSize,
            cells: [
                {
                    value: 'Relevered Betas (Relevered Beta = Unlevered Beta * [ 1 + D/E ( 1 - t )])',
                    colSpan: options.Companies.length < 6 ? 9 : 3 + options.Companies.length,
                    borderTop: { size: 2, color: krollblue },
                    bold: true
                }
            ]
        },
        {
            height: betaCompanyCellHeight,
            font: betaNormalFontSize,
            cells: getBetaType(options.ReleveredInputs)
        },
        {
            height: betaCompanyCellHeight,
            font: betaNormalFontSize,
            cells: getTargetCapital(options.ReleveredInputs)
        },
        {
            height: betaCompanyCellHeight,
            font: betaNormalFontSize,
            cells: getReleveredTaxRate(options.ReleveredInputs)
        },
        {
            height: betaCompanyCellHeight,
            cells: [{ value: '' }]
        },
        {
            height: betaCompanyCellHeight,
            font: betaNormalFontSize,
            cells: getReleveredBetaTitles()
            // borderBottom: { size: 1, color: krollblue }
        },
        {
            height: betaCompanyCellHeight,
            font: betaNormalFontSize,
            cells: getStatistics(options.LeveredStatistics, 'Levered Beta')
        },
        {
            height: betaCompanyCellHeight,
            cells: [{ value: '' }]
        },
        {
            height: betaCompanyCellHeight,
            font: betaNormalFontSize,
            cells: getStatistics(options.UnleveredStatistics, 'Unlevered Beta')
        },
        {
            height: betaCompanyCellHeight,
            cells: [{ value: '' }]
        },
        {
            height: betaCompanyCellHeight,
            font: betaNormalFontSize,
            cells: getStatistics(options.ReleveredStatistics, 'Relevered Beta')
        }

    );
    return inputs;
}
