import { EnvironmentConfiguration } from '@concurrency/angular';
import { NgxLoggerLevel } from 'ngx-logger';

export const environment: EnvironmentConfiguration = {
    production: false,
    name: 'qa',
    urls: {
        navigatorApi: 'https://costofcapital-qa-api.kroll.com',
        fastSpring: 'https://krollcostofcapital.test.onfastspring.com',
        contentManagement: 'https://vasdc8grscocqa.blob.core.windows.net/files/content-management',
        marketingPortal: 'https://dpwww-qa.dpcostofcapital.com',
        valuSource: 'https://www.valusource.com/products/business-appraiser-databases/',
        DashboardInputImage: 'https://vasdc8grscocqa.blob.core.windows.net/dashboard/kroll-cost-of-capital-inputs.png',
        erpFiles: 'https://vasdc8grscocqa.blob.core.windows.net/resources/',
        heatMap: 'https://costofcapital-qa-heatmap.kroll.com'
    },
    authentication: {
        aad: {
            client: '16a2837d-55fd-43fe-87e8-0d95e0acd866',
            tenant: '781802be-916f-42df-a204-78a2b3144934',
            endpoints: [
                'https://duffandphelps.onmicrosoft.com/db6aeb94-c157-4c59-8eca-16d1b05ada37',
                'bb2ca938-052f-4556-9b3c-1afbeaf50fbe'
            ],
            durationMinutes: 30
        },
        b2c: {
            provider: 'b2clogin.com',
            workflow: 'Redirect',
            client: '4fe32e1f-f530-4d73-871c-48d943fe7243',
            tenant: 'qausdpb2c',
            scopes: ['https://qausdpb2c.onmicrosoft.com/api/read'],
            policies: {
                signup: 'B2C_1_pearl_signup_signin',
                signin: 'B2C_1_pearl_signup_signin',
                signinSignup: 'B2C_1_pearl_signup_signin',
                forgotPassword: 'B2C_1_pearl_password_reset'
            }
        }
    },
    applicationInsights: {
        name: 'qa-us-coc-wa-01',
        key: '8b78f1ca-18c1-4506-a3f3-0378d25653e4'
    },
    loggerLevel: NgxLoggerLevel.ERROR,

    auth0: {
        domain: 'pearl-qa.eu.auth0.com',
        clientId: 'bw5cDpVbm7AhM9cwPjTGdQ3cJqJyC8R6',

        authorizationParams: {
            redirect_uri: window.location.origin,
            scope: 'openid profile email',
            audience: '851ebec8-03ef-4165-872d-b55998f2a0c9'
        },
        httpInterceptor: {
            allowedList: [
                'https://costofcapital-qa-api.kroll.com/api*',
                'https://cdn.pendo.io/agent/static/',
                'https://costofcapital-qa-api.kroll.com/files*',
                'https://costofcapital-qa-api.kroll.com/dashboard*'
            ],
        }
    }
};
