import { Component } from '@angular/core';
import { ContextualNumber } from '@concurrency/core';
import { Observable, of } from 'rxjs';
import { CompanyType, InputType } from 'src/app/_api/responses/input.response';
import { RiskService } from 'src/app/_navigator/data/service/risk.service';
import { DataStore } from 'src/app/_navigator/data/store/data.store';
import { NumberFormatUtil } from 'src/app/_navigator/data/util/number-format.util';
import { EditorComponent } from './editor.component';

// TODO: Still needs additional clean-up.
@Component({ templateUrl: './zscore-editor.component.html' })
export class ZscoreEditorComponent extends EditorComponent {

    private isCompanyDistressed = false;
    public hfrRiskPremium!: ContextualNumber;
    public marketValueOfEquity = new ContextualNumber();
    public bookValueOfEquity = new ContextualNumber();
    public totalAssets = new ContextualNumber();
    public currentYearEBIT = new ContextualNumber();
    public currentYearSales = new ContextualNumber();
    public currentAssets = new ContextualNumber();
    public currentLiabilities = new ContextualNumber();
    public retainedEarnings = new ContextualNumber();
    public companyType?: CompanyType;
    public isDistressed = false;

    public CompanyType = CompanyType;
    public ZScore?: number;
    public ZScoreValue?: string;
    public errorMessage?: string;

    public marketValueConfig = {
        name: 'Market Value of Equity', inputType: 'number',
        debounce: { callback: () => this.update() }, autofocus: true
    };
    public currentYearSalesConfig = { name: 'Current Year Sales', inputType: 'number', debounce: { callback: () => this.update() } };
    public bookValueConfig = { name: 'Book Value of Equity', inputType: 'number', debounce: { callback: () => this.update() } };
    public currentAssetsConfig = { name: 'Current Assets', inputType: 'number', debounce: { callback: () => this.update() } };
    public totalAssetsConfig = { name: 'Total Assets', inputType: 'number', debounce: { callback: () => this.update() } };
    public currentLiabilitiesConfig = { name: 'Current Liabilities', inputType: 'number', debounce: { callback: () => this.update() } };
    public currentYearEBITConfig = { name: 'Current Year EBIT', inputType: 'number', debounce: { callback: () => this.update() } };
    public retainedEarningsConfig = { name: 'Retained Earnings', inputType: 'number', debounce: { callback: () => this.update() } };

    constructor(
        protected dataStore: DataStore
    ) { super(dataStore); }

    public initialize(): void {
        this.errorMessage = '';

        this.hfrRiskPremium = this.settings.data.clone();
        this.marketValueOfEquity = this.estimate.getContextualInput(InputType.MarketValueOfCommonEquity, 2);
        this.bookValueOfEquity = this.estimate.getContextualInput(InputType.BookValueOfEquity, 2);
        this.totalAssets = this.estimate.getContextualInput(InputType.TotalAssets, 2);
        this.currentYearEBIT = this.estimate.getContextualInput(InputType.CurrentYearEbit, 2);
        this.currentYearSales = this.estimate.getContextualInput(InputType.NetSales, 2);
        this.currentAssets = this.estimate.getContextualInput(InputType.CurrentAssets, 2);
        this.currentLiabilities = this.estimate.getContextualInput(InputType.CurrentLiabilities, 2);
        this.retainedEarnings = this.estimate.getContextualInput(InputType.RetainedEarnings, 2);
        this.companyType = this.estimate.getInput(InputType.CompanyType).Value || CompanyType.Service;
        this.isDistressed = this.estimate.getInput(InputType.IsDistressed).Value ? true : false;
        this.update();
    }

    // TODO: This is a copy+paste
    public validateInputs(): boolean {
        this.errorMessage = undefined;

        if (this.totalAssets.asNumber === this.bookValueOfEquity.asNumber) {
            this.errorMessage = 'Total Assets must be an amount greater than Book Value of Equity.';
        }

        if (this.totalAssets.asNumber != null &&
            this.bookValueOfEquity.asNumber != null &&
            this.totalAssets.asNumber < this.bookValueOfEquity.asNumber
        ) {
            this.errorMessage = `Total Assets must be greater than Book Value of Equity ($${this.bookValueOfEquity.asNumber} million).`;
        }

        if (this.totalAssets.asNumber != null &&
            this.currentAssets.asNumber != null &&
            this.currentAssets.asNumber > this.totalAssets.asNumber
        ) {
            this.errorMessage = `Current Assets must be less than Total Assets ($${this.totalAssets.asNumber} million).`;
        }

        if (this.currentLiabilities.asNumber != null &&
            this.totalAssets.asNumber != null &&
            this.bookValueOfEquity.asNumber != null &&
            this.currentLiabilities.asNumber > (this.totalAssets.asNumber - this.bookValueOfEquity.asNumber)
        ) {
            this.errorMessage = `Current Liabilities must be less than the difference
            between Total Assets and Book Value Of Equity
            ($${this.totalAssets.asNumber - this.bookValueOfEquity.asNumber} million).`;
        }

        if (this.currentYearEBIT.asNumber != null &&
            this.currentYearSales.asNumber != null &&
            this.currentYearEBIT.asNumber > this.currentYearSales.asNumber
        ) {
            this.errorMessage = `Current Year EBIT must be less than Current Year Sales ($${this.currentYearSales.asNumber} million).`;
        }

        const investedCapital = this.estimate.getInput(InputType.MarketValueOfInvestedCapital);
        if (investedCapital.Value != null &&
            this.marketValueOfEquity.asNumber != null &&
            this.marketValueOfEquity.asNumber > investedCapital.Value
        ) {
            this.errorMessage = `Market Value of Equity must be less than or equal ` +
                `Market Value of Invested Capital ($${investedCapital.Value} million).`;
        }

        const isValid = this.errorMessage === undefined;

        this.dataStore.triggerEditorValidity(isValid);
        return isValid;
    }

    public update(): Observable<void> {
        const zscore = RiskService.calculateZScore({
            marketValueOfEquity: this.marketValueOfEquity.asNumberOrNaN,
            bookValueOfEquity: this.bookValueOfEquity.asNumberOrNaN,
            totalAssets: this.totalAssets.asNumberOrNaN,
            currentYearEBIT: this.currentYearEBIT.asNumberOrNaN,
            currentYearSales: this.currentYearSales.asNumberOrNaN,
            currentAssets: this.currentAssets.asNumberOrNaN,
            currentLiabilities: this.currentLiabilities.asNumberOrNaN,
            retainedEarnings: this.retainedEarnings.asNumberOrNaN,
            companyType: this.companyType || 0
        });

        if (this.validateInputs() === false || isNaN(zscore)) {
            this.ZScore = undefined;
            this.ZScoreValue = undefined;
            // tslint-disable-next-line import/no-deprecated
            return of(void 0);
        }

        this.ZScore = zscore;
        this.ZScoreValue = NumberFormatUtil.numberWithCommas(this.ZScore, 2);

        return this.dataStore.zscore.onceDefined((data) => {
            this.hfrRiskPremium = RiskService.getZScoreResult(
                data, zscore, this.companyType, this.settings.operand.selectionType, this.isDistressed
            );

            this.modelChange.emit(this.hfrRiskPremium);
        });
    }

    public save(): void {
        this.estimate.setInput(InputType.MarketValueOfCommonEquity, this.marketValueOfEquity.asNumberOrNull || undefined);
        this.estimate.setInput(InputType.BookValueOfEquity, this.bookValueOfEquity.asNumberOrNull || undefined);
        this.estimate.setInput(InputType.TotalAssets, this.totalAssets.asNumberOrNull || undefined);
        this.estimate.setInput(InputType.CurrentYearEbit, this.currentYearEBIT.asNumberOrNull || undefined);
        this.estimate.setInput(InputType.NetSales, this.currentYearSales.asNumberOrNull || undefined);
        this.estimate.setInput(InputType.CurrentAssets, this.currentAssets.asNumberOrNull || undefined);
        this.estimate.setInput(InputType.CurrentLiabilities, this.currentLiabilities.asNumberOrNull || undefined);
        this.estimate.setInput(InputType.RetainedEarnings, this.retainedEarnings.asNumberOrNull || undefined);
        this.estimate.setInput(InputType.ZScore, this.ZScore || undefined);
        this.estimate.setInput(InputType.IsDistressed, this.isCompanyDistressed ? 1 : 0);
        this.estimate.setInput(InputType.CompanyType, this.companyType || CompanyType.Service);
    }

    public cancel(): void { }

    public setCompanyType(type: CompanyType): void {
        this.companyType = type;
        this.update();
    }

    public setDistressed(distressed: boolean): void {
        this.isDistressed = distressed;
        this.update();
    }
}
