import * as moment from 'moment';

import { Component } from '@angular/core';
import { ContextualNumber, Dictionary, Util } from '@concurrency/core';
import { Observable, of } from 'rxjs';
import { InputType } from 'src/app/_api/responses/input.response';
import { RiskService } from 'src/app/_navigator/data/service/risk.service';
import { DataStore } from 'src/app/_navigator/data/store/data.store';
import { EditorComponent } from './editor.component';

enum RiskEditMode {
    Unknown = 0,
    OperatingMargin = 1,
    IncomeMargin = 2,
    BookEquity = 3
}

// TODO: This component needs significant clean-up work.
@Component({ templateUrl: './buildup3-portfolio-selector.component.html' })
export class Buildup3PortfolioSelectorComponent extends EditorComponent {
    public year: number[] = [];
    public riskInputs: Dictionary<(number | undefined)[]> = {};
    public averageMargin?: number;
    public averageRoe?: number;
    public covom?: number;
    public stdMargin?: number;
    public stdRoe?: number;
    public covboe?: number;

    public editorError = '';
    public editorMode: RiskEditMode = RiskEditMode.Unknown;

    public InputType = InputType;

    constructor(
        protected dataStore: DataStore
    ) {
        super(dataStore);
        this.riskInputs[InputType.NetSales] = [];
        this.riskInputs[InputType.OperatingIncome] = [];
        this.riskInputs[InputType.NetIncome] = [];
        this.riskInputs[InputType.BookValueOfEquity] = [];
        this.riskInputs[InputType.OperatingMargin] = [];
        this.riskInputs[InputType.ReturnOnEquity] = [];
    }

    private initYears(): void {
        const valuationDate = moment(this.estimate.ValuationDate);
        this.year[0] = valuationDate.year();
        this.year[1] = valuationDate.year() - 1;
        this.year[2] = valuationDate.year() - 2;
        this.year[3] = valuationDate.year() - 3;
        this.year[4] = valuationDate.year() - 4;
    }

    public trackFn(index: number): number {
        return index;
    }

    public initialize(): void {
        if (this.settings.operand.inputType === InputType.OperatingMargin) {
            this.editorMode = RiskEditMode.OperatingMargin;
        } else if (this.settings.operand.inputType === InputType.CoefficientOfVariationOfOperatingMargin) {
            this.editorMode = RiskEditMode.IncomeMargin;
        } else if (this.settings.operand.inputType === InputType.CoefficientOfVariationOfReturnOnEquity) {
            this.editorMode = RiskEditMode.BookEquity;
        }

        for (const inputType of Object.keys(this.riskInputs)) {
            const inputs = this.estimate.getYearlyInputs(inputType as InputType, 5);
            const values = inputs.sort((x) => x.RelativeYear).reverse().map((x) => x.Value);
            this.riskInputs[inputType] = values;
        }

        this.initYears();
        this.update();
    }

    public update(): Observable<void> {
        let sendNumber: number | undefined;
        let canCalculateMargin = false;
        let canCalculateRoe = false;

        if (this.editorMode === RiskEditMode.OperatingMargin
            || this.editorMode === RiskEditMode.IncomeMargin) {

            // eslint-disable-next-line guard-for-in
            for (const index in this.riskInputs[InputType.NetSales]) {
                const netSales = this.riskInputs[InputType.NetSales][index];
                const operatingIncome = this.riskInputs[InputType.OperatingIncome][index];

                if (netSales != null && operatingIncome != null) {
                    this.riskInputs[InputType.OperatingMargin][index] = operatingIncome / netSales;
                } else {
                    this.riskInputs[InputType.OperatingMargin][index] = undefined;
                }
            }

            const validRiskInputs = this.riskInputs[InputType.OperatingMargin]
                .filter(Util.notNull)
                .filter((x) => isNaN(x) === false && x !== Infinity);

            if (validRiskInputs.length >= 3) {
                const averageMargin = Util.average(validRiskInputs, 4) || 0;
                const std = Util.standardDeviation(validRiskInputs);

                const stdMargin = std > 0 ? std : 0;
                this.covom = (stdMargin / averageMargin) || 0;

                if (this.editorMode === RiskEditMode.OperatingMargin) {
                    sendNumber = averageMargin;
                } else if (this.editorMode === RiskEditMode.IncomeMargin) {
                    sendNumber = this.covom;
                }

                canCalculateMargin = true;

                this.averageMargin = averageMargin;
                this.stdMargin = stdMargin;

            } else {
                canCalculateMargin = false;
                this.averageMargin = undefined;
                this.stdMargin = undefined;
                this.covom = undefined;
                sendNumber = undefined;
            }

        } else if (this.editorMode === RiskEditMode.BookEquity) {
            // eslint-disable-next-line guard-for-in
            for (const index in this.riskInputs[InputType.BookValueOfEquity]) {
                const netIncome = this.riskInputs[InputType.NetIncome][index];
                const bvoe = this.riskInputs[InputType.BookValueOfEquity][index];
                if (netIncome != null && bvoe != null) {
                    this.riskInputs[InputType.ReturnOnEquity][index] = netIncome / bvoe;
                } else {
                    this.riskInputs[InputType.ReturnOnEquity][index] = undefined;
                }
            }

            if (this.riskInputs[InputType.ReturnOnEquity][0]
                && this.riskInputs[InputType.ReturnOnEquity][1]
                && this.riskInputs[InputType.ReturnOnEquity][2]
            ) {
                const roe = this.riskInputs[InputType.ReturnOnEquity].filter(Util.notNull);
                this.averageRoe = Util.average(roe, 4) || 0;
                const stdRoe = Util.standardDeviation(roe);
                this.stdRoe = stdRoe > 0 ? stdRoe : 0;
                this.covboe = (this.stdRoe / this.averageRoe) || 0;
                sendNumber = this.covboe;
                canCalculateRoe = true;
            } else {
                canCalculateRoe = false;
                this.averageRoe = undefined;
                this.stdRoe = undefined;
                this.covboe = undefined;
                sendNumber = undefined;
            }
        }

        if (canCalculateMargin || canCalculateRoe) {
            return this.dataStore.portfolios.onceDefined((portfolios) => {
                const inputType = this.settings.operand.inputType;
                const selectionType = this.settings.operand.selectionType;
                if (inputType == null) {
                    throw Error(`ZScore editor requires an InputType for calculation`);
                }

                const result = RiskService.getPortfolioResult(portfolios, sendNumber, inputType, selectionType, {
                    useRegression: this.settings.scenario.UseRegressionCalculation
                });

                this.modelChange.emit(result);
            });
        } else {
            this.modelChange.emit(new ContextualNumber());

            // tslint-disable-next-line import/no-deprecated
            return of(void 0);
        }
    }

    public save(): void {
        for (const inputType of Object.keys(this.riskInputs)) {
            // eslint-disable-next-line guard-for-in
            for (const index in this.riskInputs[inputType]) {
                const value = this.riskInputs[inputType][index];
                const relativeYear = parseInt(index, 0) * -1;
                this.estimate.setInput(inputType as InputType, value, relativeYear);
            }
        }

        if (this.editorMode === RiskEditMode.OperatingMargin || this.editorMode === RiskEditMode.IncomeMargin) {
            const aomValue = this.averageMargin == null ? undefined : Util.round(this.averageMargin * 100);
            this.estimate.setInput(InputType.AverageOperatingMargin, aomValue);

            const covomValue = this.covom == null ? undefined : Util.round(this.covom * 100);
            this.estimate.setInput(InputType.CoefficientOfVariationOfOperatingMargin, covomValue);
        }

        if (this.editorMode === RiskEditMode.BookEquity) {
            const covboeValue = this.covboe == null ? undefined : Util.round(this.covboe * 100);
            this.estimate.setInput(InputType.CoefficientOfVariationOfReturnOnEquity, covboeValue);
        }
    }

    public cancel(): void { }
}
