import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Industry } from 'src/app/_api/responses/us-industry.response';
import { IndustryDataSharingService } from 'src/app/estimate/studies/service/industry-data-sharing.service';
import { TrendsOverTimeService } from 'src/app/home/tear-sheet/data/trends-over-time.service';

@Component({
    selector: 'benchmarking-child-industries',
    templateUrl: './benchmarking-child-industries.component.html'
})

export class BenchmarkingChildIndustriesComponent implements OnInit, OnChanges {

    public userSelectionListLocal!: Industry[];

    @Input() public userSelectedList!: Industry[];
    @Output() public removeIndustrySelection = new EventEmitter<Industry>();

    constructor(
        public industryDataSharingService: IndustryDataSharingService,
        public trendsOverTimeService: TrendsOverTimeService
    ) { }

    public ngOnInit(): void {
        this.userSelectionListLocal = this.userSelectedList;
    }

    public ngOnChanges(): void {
        this.userSelectionListLocal = this.userSelectedList;
    }

    public removeSelectedIndustry = (codeId: number, area: string, currencyCode: string): void => {
        this.removeIndustrySelection.emit({
            CodeId: codeId,
            GicIndustryCode: '',
            Sector: '',
            SicIndustryCode: '',
            Area: area,
            CurrencyCode: currencyCode
        });
    }
}
