import { formatPercent } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { Util } from '@concurrency/core';

@Pipe({
  name: 'updateOperatorSign'
})
export class UpdateOperatorSignPipe implements PipeTransform {

  public absValue!: number;

  transform(value: number): string {
    if(Util.isNumber(value)) {
      if (value < 0){
        this.absValue = Math.abs(value);
        return ' - ' + formatPercent(this.absValue,'en-US','1.3-3');
      } else if (value > 0) {
        this.absValue = Math.abs(value);
        return ' + ' + formatPercent(this.absValue,'en-US','1.3-3');
      }
    } 
    return '';
  }

}
