import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Observable } from 'rxjs/internal/Observable';
import { IntlRfClient } from 'src/app/_api/clients/intlRf.client';
import { IntlData } from 'src/app/_api/responses/intl-data.response';
import { GetIntlRf } from './intl-rf-actions';
import { Injectable } from '@angular/core';


export type IntlRfModel = IntlData[] | undefined;

@State<IntlRfModel>({
    name: 'intlRf',
    defaults: undefined
})

@Injectable()
export class IntlRfState {

    @Selector()
    public static get(state: IntlRfModel): IntlData[] | undefined {
        return state;
    }
    constructor(private intlRfClient: IntlRfClient) { }

    @Action(GetIntlRf)
    public getIntlRf(context: StateContext<IntlRfModel>, action: GetIntlRf): Observable<void> {
        return this.intlRfClient.read(action.countryId, action.dataAsOf).once((response) => {
            context.setState(response);
        });
    }
}
