import { Util } from '@concurrency/core';
import { SelectionType } from 'src/app/_api/enums/selection-type';
import { InputType } from 'src/app/_api/responses/input.response';
import { Estimate } from 'src/app/_navigator/data/model/estimate.model';

export class CalculationsUtil {
    public static calculateCapm(estimate: Estimate, crp: number): number {
        const scenario = estimate.Scenarios[0];

        const rf = scenario.getValueOrNaN(SelectionType.IntlRiskFreeRate, InputType.None);
        const erp = scenario.getValueOrNaN(SelectionType.IntlEquityRiskPremium, InputType.None);
        const beta = scenario.getValueOrNaN(SelectionType.IntlBeta, InputType.None);
        const rps = scenario.getValueOrNaN(SelectionType.IntlSizePremium, InputType.None);
        const alpha = scenario.getValueOrNaN(SelectionType.IntlAlpha, InputType.None);

        const rfAsDecimal = rf / 100;
        const erpAsDecimal = erp / 100;
        const rpsAsDecimal = rps ? rps / 100 : 0;
        const alphaAsDecimal = alpha ? alpha / 100 : 0;
        const crpAsDecimal = crp / 100;

        const result = rfAsDecimal + erpAsDecimal * beta + rpsAsDecimal + alphaAsDecimal + crpAsDecimal;
        const resultAsPercentage = result * 100;

        return Util.round(resultAsPercentage);
    }

    public static calculateCapmVolatility(estimate: Estimate, volatility: number): number {
        const scenario = estimate.Scenarios[0];

        const rf = scenario.getValueOrNaN(SelectionType.IntlRiskFreeRate, InputType.None);
        const erp = scenario.getValueOrNaN(SelectionType.IntlEquityRiskPremium, InputType.None);
        const beta = scenario.getValueOrNaN(SelectionType.IntlBeta, InputType.None);
        const rps = scenario.getValueOrNaN(SelectionType.IntlSizePremium, InputType.None);
        const alpha = scenario.getValueOrNaN(SelectionType.IntlAlpha, InputType.None);

        const rfAsDecimal = rf / 100;
        const erpAsDecimal = erp / 100;
        const rpsAsDecimal = rps ? rps / 100 : 0;
        const alphaAsDecimal = alpha ? alpha / 100 : 0;

        const result = rfAsDecimal + erpAsDecimal * beta * volatility + rpsAsDecimal + alphaAsDecimal;
        const resultAsPercentage = result * 100;

        return Util.round(resultAsPercentage);
    }

    public static calculateFisherEffect(estimate: Estimate, ke: number): number {
        const scenario = estimate.Scenarios[0];
        const inflationInvestor = scenario.getSelection(SelectionType.InflationInvestor, InputType.None);
        const inflationInvestee = scenario.getSelection(SelectionType.InflationInvestee, InputType.None);

        const iInvestorValue = inflationInvestor.Value ? inflationInvestor.Value : 0;
        const iInvesteeValue = inflationInvestee.Value ? inflationInvestee.Value : 0;

        const keAsDecimal = ke / 100;
        const iInvesteeAsDecimal = iInvesteeValue / 100;
        const iInvestorAsDecimal = iInvestorValue / 100;

        const result = (1 + keAsDecimal) * (1 + iInvesteeAsDecimal) / (1 + iInvestorAsDecimal) - 1;
        const resultAsPercentage = result * 100;

        return Util.round(resultAsPercentage);
    }

    public static calculateCostOfDebt(estimate: Estimate, countryRiskPremium: number): number {
        const scenario = estimate.Scenarios[0];

        const preTaxKd = scenario.getValueOrNaN(SelectionType.PreTaxCostOfDebt, InputType.None);
        const taxRate = scenario.getValueOrNaN(SelectionType.TaxRate, InputType.None);
        const taxRateAsDecimal = taxRate / 100;

        const rawResult = (preTaxKd + countryRiskPremium) * (1 - taxRateAsDecimal);

        return Util.round(rawResult);
    }

    public static calculateCostOfDebtVolatility(estimate: Estimate, volatility: number): number {
        const scenario = estimate.Scenarios[0];

        const preTaxKd = scenario.getValueOrNaN(SelectionType.PreTaxCostOfDebt, InputType.None);
        const erp = scenario.getValueOrNaN(SelectionType.IntlEquityRiskPremium, InputType.None);
        const taxRate = scenario.getValueOrNaN(SelectionType.TaxRate, InputType.None);
        const taxRateAsDecimal = taxRate / 100;

        const result = (preTaxKd + volatility * erp - erp) * (1 - taxRateAsDecimal);

        return Util.round(result);
    }

    public static calculateSingleCountryCostOfDebt(estimate: Estimate): number {
        const scenario = estimate.Scenarios[0];

        const preTaxKd = scenario.getValueOrNaN(SelectionType.PreTaxCostOfDebt, InputType.None);
        const taxRate = scenario.getValueOrNaN(SelectionType.TaxRate, InputType.None);
        const taxRateAsDecimal = taxRate / 100;

        const rawResult = preTaxKd * (1 - taxRateAsDecimal);

        return Util.round(rawResult);
    }

    public static calculateWacc(estimate: Estimate, ke: number, kd: number): number {
        const scenario = estimate.Scenarios[0];

        const we = scenario.getValueOrNaN(SelectionType.WeightedEquity, InputType.None);
        const wd = scenario.getValueOrNaN(SelectionType.DebtToTotalCapitalRatio, InputType.None);

        const keAsDecimal = ke / 100;
        const weAsDecimal = we / 100;
        const kdAsDecimal = kd / 100;
        const wdAsDecimal = wd / 100;

        const result = keAsDecimal * weAsDecimal + kdAsDecimal * wdAsDecimal;
        const resultAsPercentage = result * 100;

        return Util.round(resultAsPercentage);
    }

    public static calculateSingleCountryCapm(estimate: Estimate): number {
        const scenario = estimate.Scenarios[0];

        const rf = scenario.getValueOrNaN(SelectionType.IntlRiskFreeRate, InputType.None);
        const erp = scenario.getValueOrNaN(SelectionType.IntlEquityRiskPremium, InputType.None);
        const beta = scenario.getValueOrNaN(SelectionType.IntlBeta, InputType.None);
        const rps = scenario.getValueOrNaN(SelectionType.IntlSizePremium, InputType.None);
        const alpha = scenario.getValueOrNaN(SelectionType.IntlAlpha, InputType.None);

        const rfAsDecimal = rf / 100;
        const erpAsDecimal = erp / 100;
        const rpsAsDecimal = rps ? rps / 100 : 0;
        const alphaAsDecimal = alpha ? alpha / 100 : 0;

        const result = rfAsDecimal + erpAsDecimal * beta + rpsAsDecimal + alphaAsDecimal;
        const resultAsPercentage = result * 100;

        return Util.round(resultAsPercentage);
    }
}
