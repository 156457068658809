export enum SelectionType {
    // Betas & Industry Risk Premiums
    CrspDecilesBeta = 'CrspDecilesBeta',
    RiskPremiumBeta = 'RiskPremiumBeta',
    HighFinancialRiskBeta = 'HighFinancialRiskBeta',
    CrspIndustryRiskPremium = 'CrspIndustryRiskPremium',
    RprIndustryRiskPremium = 'RprIndustryRiskPremium',

    // Miscellaneous
    RiskFreeRate = 'RiskFreeRate',
    EquityRiskPremium = 'EquityRiskPremium',
    EquityRiskPremiumAdjustment = 'EquityRiskPremiumAdjustment',

    // Crsp Size Premiums
    CrspSizePremium = 'CrspSizePremium',
    CrspBuildupSizePremium = 'CrspBuildupSizePremium',

    // Risk Premiums
    RiskPremiumOverCapm = 'RiskPremiumOverCapm',
    RiskPremiumOverRiskFreeRateLevered = 'RiskPremiumOverRiskFreeRateLevered',
    RiskPremiumOverRiskFreeRateLeveredDebtEquityRatio = 'RiskPremiumOverRiskFreeRateLeveredDebtEquityRatio',
    RiskPremiumOverRiskFreeRateUnlevered = 'RiskPremiumOverRiskFreeRateUnlevered',
    RiskPremiumOverRiskFreeRateRelevered = 'RiskPremiumOverRiskFreeRateRelevered',
    Buildup2RiskPremiumOverTheRiskFreeRate = 'Buildup2RiskPremiumOverTheRiskFreeRate',
    Buildup3RiskPremiumOverTheRiskFreeRate = 'Buildup3RiskPremiumOverTheRiskFreeRate',

    // HFR Premiums
    HighFinancialRiskPremiumOverCapM = 'HighFinancialRiskPremiumOverCapM',
    HighFinancialRiskPremiumOverRiskFreeRate = 'HighFinancialRiskPremiumOverRiskFreeRate',

    // International Inputs
    IntlAlpha = 'IntlAlpha',
    IntlBeta = 'IntlBeta',
    IntlEquityRiskPremium = 'IntlEquityRiskPremium',
    IntlRiskFreeRate = 'IntlRiskFreeRate',
    IntlSizePremium = 'IntlSizePremium',

    // International Cost of Equity
    CountryRiskPremium = 'CountryRiskPremium',
    YieldSpread = 'YieldSpread',
    RelativeVolatility = 'RelativeVolatility',
    CostOfEquityCapmCcr = 'CostOfEquityCapmCcr',
    CostOfEquityCapmYieldSpread = 'CostOfEquityCapmYieldSpread',
    CostOfEquityCapmVolatility = 'CostOfEquityCapmVolatility',
    CostOfEquityFisherEffectCcr = 'CostOfEquityFisherEffectCcr',
    CostOfEquityFisherEffectYieldSpread = 'CostOfEquityFisherEffectYieldSpread',
    CostOfEquityFisherEffectVolatility = 'CostOfEquityFisherEffectVolatility',

    // International Cost of Debt
    CostOfDebtCurrency = 'CostOfDebtCurrency',
    PreTaxCostOfDebt = 'PreTaxCostOfDebt',
    DebtToTotalCapitalRatio = 'DebtToTotalCapitalRatio',
    TaxRate = 'TaxRate',
    UseYieldSpreadOnly = 'UseYieldSpreadOnly',
    CostOfDebtCcr = 'CostOfDebtCcr',
    CostOfDebtYieldSpread = 'CostOfDebtYieldSpread',
    CostOfDebtVolatility = 'CostOfDebtVolatility',
    CostOfDebtFisherEffectCcr = 'CostOfDebtFisherEffectCcr',
    CostOfDebtFisherEffectYieldSpread = 'CostOfDebtFisherEffectYieldSpread',
    CostOfDebtFisherEffectVolatility = 'CostOfDebtFisherEffectVolatility',

    // International Wacc
    WeightedEquity = 'WeightedEquity',
    WaccCcr = 'WaccCcr',
    WaccYieldSpread = 'WaccYieldSpread',
    WaccVolatility = 'WaccVolatility',

    // Single Country CAPM
    KeSingleCountryCapm = 'KeSingleCountryCapm',
    KeSingleCountryFisherEffect = 'KeSingleCountryFisherEffect',
    KdSingleCountryCapm = 'KdSingleCountryCapm',
    KdSingleCountryFisherEffect = 'KdSingleCountryFisherEffect',
    WaccSingleCountry = 'WaccSingleCountry',

    // Fisher Effect
    InflationInvestor = 'InflationInvestor',
    InflationInvestee = 'InflationInvestee',

    //CCR Source
    SourceInfoCcr = 'SourceInfoCcr'
}
