export enum EquationElementName {
    CostOfEquity = 'Cost of Equity',
    RiskFreeRate = 'Risk-free rate',
    EquityRiskPremium = 'Equity Risk Premium',
    Beta = 'Beta',
    SizePremium = 'Size Premium',
    Alpha = 'Alpha',
    CountryRiskPremium = 'Country Risk Premium',
    YieldSpread = 'Country Risk Premium',
    RelativeVolatilityFactor = 'Relative Volatility Factor',
    Inflation = 'Inflation',
    CostOfDebt = 'After-tax Cost of Debt',
    PreTaxCostOfDebt = 'Pre-tax Cost of Debt',
    Wacc = 'WACC',
    WeightedEquity = 'Weight of Equity',
    WeightedDebt = 'Weight of Debt',
    TaxRate = 'Tax Rate'
}
