import { ContextualNumber, Dictionary } from '@concurrency/core';
import { SelectionType } from 'src/app/_api/enums/selection-type';
import { EquationType } from 'src/app/_api/responses/equation.response';
import { CompanyType, InputType } from 'src/app/_api/responses/input.response';
import { Portfolio } from 'src/app/_api/responses/portfolio.response';
import { ZScore } from 'src/app/_api/responses/zscore.response';
import { NumberFormatUtil } from '../util/number-format.util';

export interface PortfolioOptions {
    debt?: number;
    historicRprErp?: number;
    useRegression?: boolean;
}

export class RiskService {
    public static RiskPremia: string[] = [
        SelectionType.RiskPremiumOverCapm,
        SelectionType.RiskPremiumOverRiskFreeRateLevered,
        SelectionType.RiskPremiumOverRiskFreeRateLeveredDebtEquityRatio,
        SelectionType.RiskPremiumOverRiskFreeRateUnlevered,
        SelectionType.RiskPremiumOverRiskFreeRateRelevered,
        SelectionType.Buildup2RiskPremiumOverTheRiskFreeRate,
        SelectionType.Buildup3RiskPremiumOverTheRiskFreeRate
    ];

    public static HighFinancialRiskPremia: string[] = [
        SelectionType.HighFinancialRiskPremiumOverCapM,
        SelectionType.HighFinancialRiskPremiumOverRiskFreeRate
    ];

    public static get portfolioEquationMap(): Dictionary<string> {
        // NOTE: These mappings are weird because the back-end data is (correctly) weird
        const EquationTypes: Dictionary<EquationType> = {};
        EquationTypes[SelectionType.RiskPremiumOverCapm] = EquationType.RprsCapmSizeStudy;
        EquationTypes[SelectionType.RiskPremiumOverRiskFreeRateLevered] = EquationType.RprsBuildup1Levered;
        EquationTypes[SelectionType.RiskPremiumOverRiskFreeRateUnlevered] = EquationType.RprsBuildup1Unlevered;
        EquationTypes[SelectionType.RiskPremiumOverRiskFreeRateRelevered] = EquationType.RprsBuildup1Unlevered;
        EquationTypes[SelectionType.Buildup2RiskPremiumOverTheRiskFreeRate] = EquationType.RprsCapmSizeStudy;
        EquationTypes[SelectionType.Buildup3RiskPremiumOverTheRiskFreeRate] = EquationType.RprsBuildup3;

        return EquationTypes;
    }

    public static getPortfolioResult(
        portfolios: Portfolio[],
        input: number | undefined,
        inputType: InputType,
        selectionType: SelectionType,
        options?: PortfolioOptions
    ): ContextualNumber {
        if (input == null) {
            return new ContextualNumber();
        }

        const equationType = this.portfolioEquationMap[selectionType];
        const portfolio = portfolios.filter((x) =>
            x.InputType === inputType && x.EquationType === equationType &&
            x.Lower < input && x.Upper >= input
        )[0];

        if (portfolio == null) {
            return new ContextualNumber();
        }
        if (selectionType === SelectionType.RiskPremiumOverRiskFreeRateRelevered) {
            if (options != null && options.useRegression && portfolio.UnleveredBetaCoefficient != null && portfolio.UnleveredBetaConstant != null) {
                const factor = Math.log(input) / Math.log(10);
                const constant = portfolio.ConstantPercentage;
                const coefficient = portfolio.CoefficientPercentage;
                const UnleveredCoefficient = portfolio.UnleveredBetaCoefficient;
                const UnleveredConstant = portfolio.UnleveredBetaConstant;
                if (options == null || options.debt == null || options.historicRprErp == null) {
                    throw new Error(`options.debt and options.historicRprErp are required to look-up Relevered Portfolios `);
                }
                const debtratio = options.debt / 100;
                const erp = options.historicRprErp;
                const regressionValue = (constant + coefficient * factor) + ((UnleveredConstant + UnleveredCoefficient * factor) - 0.1) * debtratio * erp;
                const description = 'Unlevered Premium+ {{' + NumberFormatUtil.numberWithCommas(UnleveredConstant, 3) + ' + ' + NumberFormatUtil.numberWithCommas(UnleveredCoefficient, 3) + ' * Log(' + Math.abs(input) + ')} -0.1}* ' + debtratio + ' * ' + NumberFormatUtil.numberWithCommas(erp, 3);
                return new ContextualNumber(regressionValue * 100, description, portfolio.DataAsOf);
            } else {
                if (options == null || options.debt == null || options.historicRprErp == null) {
                    throw new Error(`options.debt and options.historicRprErp are required to look-up Relevered Portfolios`);
                }

                const averageDebt = options.debt / 100;
                const releveredValue =
                    portfolio.Result + (averageDebt * (portfolio.UnleveredBeta - 0.1) * options.historicRprErp);

                return new ContextualNumber(releveredValue * 100, portfolio.Description, portfolio.DataAsOf);
            }
        }

        if (options != null && options.useRegression) {
            if (inputType === InputType.CoefficientOfVariationOfOperatingMargin ||
                inputType === InputType.CoefficientOfVariationOfReturnOnEquity) {
                const factor = Math.log(input) / Math.log(10); // What is the proper name of this variable?
                const constant = portfolio.ConstantPercentage;
                const coefficient = portfolio.CoefficientPercentage;
                const regressionValue = constant + (coefficient * factor);
                const description = ` = ${constant} + ${Math.abs(coefficient)} * Log(${NumberFormatUtil.numberWithCommas(input, 3)})`;
                return new ContextualNumber(regressionValue * 100, description, portfolio.DataAsOf);
            } else {
                const factor = Math.log(input) / Math.log(10); // What is the proper name of this variable?
                const constant = portfolio.ConstantPercentage;
                const coefficient = portfolio.CoefficientPercentage;
                const regressionValue = constant + (coefficient * factor);
                const description = ` = ${constant} - ${Math.abs(coefficient)} * Log(${NumberFormatUtil.numberWithCommas(input, 3)})`;
                return new ContextualNumber(regressionValue * 100, description, portfolio.DataAsOf);
            }
        }

        return new ContextualNumber(portfolio.Result * 100, portfolio.Description, portfolio.DataAsOf);
    }

    public static getZScoreResult(
        zscore: ZScore,
        input: number | undefined,
        companyType: CompanyType | undefined,
        selectionType: SelectionType,
        forceDistressed?: boolean
    ): ContextualNumber {
        if (input == null) {
            return new ContextualNumber();
        }

        const isBuildup = selectionType === SelectionType.HighFinancialRiskPremiumOverRiskFreeRate;

        let result: number | undefined;
        if (companyType === CompanyType.Manufacturing && (input < 1.8 || forceDistressed === true)) {
            if (isBuildup) {
                result = zscore.Manufacturing.Buildup.Distress;
            } else {
                result = zscore.Manufacturing.Capm.Distress;
            }
        } else if (companyType === CompanyType.Service && (input < 1.1 || forceDistressed === true)) {
            if (isBuildup) {
                result = zscore.Service.Buildup.Distress;
            } else {
                result = zscore.Service.Capm.Distress;
            }
        }

        if (result == null) {
            return new ContextualNumber();
        } else {
            return new ContextualNumber(result * 100, 'Distressed', zscore.DataAsOf);
        }
    }

    public static calculateZScore(options: {
        marketValueOfEquity: number,
        bookValueOfEquity: number,
        totalAssets: number,
        currentYearEBIT: number,
        currentYearSales: number,
        currentAssets: number,
        currentLiabilities: number,
        retainedEarnings: number,
        companyType: CompanyType
    }): number {
        const netWorkingCapital = options.currentAssets - options.currentLiabilities;
        const A = netWorkingCapital / options.totalAssets;
        const B = options.retainedEarnings / options.totalAssets;
        const C = options.currentYearEBIT / options.totalAssets;
        const E = options.currentYearSales / options.totalAssets;

        if (options.companyType === CompanyType.Manufacturing) {
            const D = options.marketValueOfEquity / (options.totalAssets - options.bookValueOfEquity);

            return (1.2 * A) + (1.4 * B) + (3.3 * C) + (0.6 * D) + (0.999 * E);
        } else {
            const D = options.bookValueOfEquity / (options.totalAssets - options.bookValueOfEquity);

            return (6.56 * A) + (3.26 * B) + (6.72 * C) + (1.05 * D);
        }
    }
}
