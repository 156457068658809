import { Injectable } from '@angular/core';
import { ApiClient } from '@concurrency/angular';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MinimumDate } from '../responses/minimum-date.response';
import { Auth0ApiClient } from '../api.client';

@Injectable()
export class ValuesClient extends Auth0ApiClient {
    protected baseUrl = `${environment.urls.navigatorApi}/api`;

    public getBasicMinimumDate(): Observable<MinimumDate> {
        return this.get(`Values/basicMinimumDate`);
    }
}
