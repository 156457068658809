import { Util } from '@concurrency/core';
import { Chart } from 'angular-highcharts';
import { IndustryMetric } from 'src/app/_api/responses/industry-metric.response';
import { Metric } from '../enums/metric';
import { TearSheetUtil } from './tear-sheet.util';

export class EnterpriseValuationChartUtil {
    public static getEnterpriseValuationChartMetric(industryMetric: IndustryMetric[]): Chart | null {
        const evSales = TearSheetUtil.getMetric(Metric.EvSales, industryMetric);
        const evEbitda = TearSheetUtil.getMetric(Metric.EvEbitda, industryMetric);

        if (evSales == null
            || evEbitda == null
            || evSales.SICCompositeLatest == null
            || evEbitda.SICCompositeLatest == null
            || evSales.SICCompositeAverage == null
            || evEbitda.SICCompositeAverage == null
        ) {
            return null;
        }

        return this.buildChart(
            evSales.SICCompositeLatest,
            evEbitda.SICCompositeLatest,
            evSales.SICCompositeAverage,
            evEbitda.SICCompositeAverage
        );
    }

    private static buildChart(latestSales: number, latestEBITDA: number, averageSales: number, averageEBITDA: number): Chart {
        return new Chart({
            chart: {
                type: 'column',
                width: 250,
                height: 250
            },
            title: {
                text: 'Enterprise Valuation'
            },
            subtitle: {
                text: 'Industry Composite'
            },
            credits: {
                enabled: false
            },
            plotOptions: {
                series: {
                    dataLabels: {
                        enabled: true
                    }
                }
            },
            series: [
                {
                    type: 'column',
                    data: [
                        ['Latest', Util.round(latestSales, 1)],
                        ['5-Yr Avg', Util.round(averageSales, 1)]
                    ],
                    name: 'EV/Sales',
                    color: '#43b049'
                },
                {
                    type: 'column',
                    data: [
                        ['Latest', Util.round(latestEBITDA, 1)],
                        ['5-Yr Avg', Util.round(averageEBITDA, 1)]
                    ],
                    name: 'EV/EBITDA',
                    color: '#14487f'
                }
            ],
            xAxis: {
                type: 'category'
            }
        });
    }
}
