import * as moment from 'moment';

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Spinner, SubscriberEntity } from '@concurrency/angular';
import { ContextualString, ContextualValue } from '@concurrency/core';
import { Select, Store } from '@ngxs/store';
import { combineLatest, Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { SelectionType } from '../_api/enums/selection-type';
import { CountryRiskPremia } from '../_api/responses/country-risk-premia.response';
import { Country } from '../_api/responses/country.response';
import { CurrencyData } from '../_api/responses/currency-data.response';
import { EstimateSummary } from '../_api/responses/estimate-summary.response';
import { Gic } from '../_api/responses/gic.response';
import { InputType } from '../_api/responses/input.response';
import { MinimumDate } from '../_api/responses/minimum-date.response';
import { SetCcr } from '../_navigator/ccr-store/ccr-actions';
import { CommonDate, DateStruct } from '../_navigator/common/date-struct';
import { CommonInputConfig } from '../_navigator/common/inputs/common-user-input.component';
import { SetAllCountries, SetInvesteeCountries, SetInvestorCountries } from '../_navigator/country-list-store/country-list-actions';
import { CountryListState } from '../_navigator/country-list-store/country-list-state';
import { GetCountryRiskPremiaList } from '../_navigator/country-risk-premia-list-store/country-risk-premia-list-actions';
import { CountryRiskPremiaListState } from '../_navigator/country-risk-premia-list-store/country-risk-premia-list-state';
import { GetCurrencyList } from '../_navigator/currency-list-store/currency-list-actions';
import { CurrencyListState } from '../_navigator/currency-list-store/currency-list-state';
import { Estimate } from '../_navigator/data/model/estimate.model';
import { Suggestion } from '../_navigator/data/model/suggestion.model';
import { TypeaheadFormatters } from '../_navigator/data/util/typeahead-formatters.util';
import { UpdateDynamicTextSummary } from '../_navigator/dynamic-text-store/dynamic-text-summary-action';
import { DynamicTextSummaryState } from '../_navigator/dynamic-text-store/dynamic-text-summary-state';
import { UpdateDbEstimate, UpdateLocalEstimate } from '../_navigator/estimate-store/estimate-actions';
import { EstimateState } from '../_navigator/estimate-store/estimate-state';
import { UpdateEstimateSummary } from '../_navigator/estimate-summary/estimate-summary-action';
import { EstimateSummaryState } from '../_navigator/estimate-summary/estimate-summary-state';
import { GetEstimates } from '../_navigator/estimates-list-store/estimates-list-actions';
import { DynamicHelpText } from '../_navigator/help/dynamic-help-text';
import { HelpText } from '../_navigator/help/help-text';
import { GetIntlIndustries } from '../_navigator/intl-industry-list-store/intl-industry-list.actions';
import { IntlIndustryListState } from '../_navigator/intl-industry-list-store/intl-industry-list.state';
import { PrimeManager } from '../_navigator/modal/pmodal.manager';
import { ProductType } from '../_navigator/user/enum/product-type';
import { User } from '../_navigator/user/model/user.model';
import { UserStore } from '../_navigator/user/store/user.store';
import { UserUtil } from '../_navigator/user/util/user.util';
import { ValuesState } from '../_navigator/values-store/values-state';
import { IndustryDataSharingService } from '../estimate/studies/service/industry-data-sharing.service';
import { ResetType } from './data/enum/reset-type';
import { InputText } from './data/model/input-text';
import { InternationalQueryBuilderService } from './data/service/international-query-builder.service';
import { IntlEstimateUtil } from './data/util/intl-estimate.util';
import { DynamicText } from './dynamic-text/model/dynamic-text';
import { DynamicTextSummary } from './dynamic-text/model/dynamic-text-summary';
import { DynamicTextUtilities } from './dynamic-text/util/dynamic-text.util';
import { InterAddIndustriesComponent } from './inter-add-industries/inter-add-industries.component';
import { RouteUtil } from './route/route.util';

@Component({
    selector: 'international-inputs',
    templateUrl: './international-inputs.component.html',
    styleUrls: ['./international-inputs.component.scss']
})
export class InternationalInputsComponent extends SubscriberEntity implements OnInit {
    public static readonly CountriesAlwaysAvailable = ['United States', 'Germany'];

    private modelsAvailable: Set<string> = new Set<string>();
    private minimumDateForIndustry = moment('20150331', 'YYYYMMDD');

    @Select(CurrencyListState.getAll) public investorInvesteeCurrenciesSelector!: Observable<CurrencyData[] | undefined>;
    @Select(EstimateState.get) public estimateSelector!: Observable<Estimate | undefined>;
    @Select(IntlIndustryListState.get) public industryListSelector!: Observable<Gic[] | undefined>;
    @Select(CountryListState.getInvestorCountries) public investorCountriesSelector!: Observable<Country[]>;
    @Select(CountryListState.getInvesteeCountries) public investeeCountriesSelector!: Observable<Country[]>;
    @Select(CountryListState.getCountriesWithAvailableIndustries) public countriesWithAvailableIndustriesSelector!: Observable<Country[]>;
    @Select(EstimateSummaryState.get) public estimateSummarySelector!: Observable<EstimateSummary | undefined>;
    @Select(CountryRiskPremiaListState.get) public countryRiskPremiaSelector!: Observable<CountryRiskPremia[] | undefined>;
    @Select(DynamicTextSummaryState.get) public dynamicTextSummarySelector!: Observable<DynamicTextSummary | undefined>;
    @Select(ValuesState.get) public valuesSelector!: Observable<MinimumDate | undefined>;

    public TypeaheadFormatters = TypeaheadFormatters;
    public user!: User;
    public estimate!: Estimate;
    public isIndustryNotAvailable = false;
    public needsUpgrade = false;
    public noDataForGivenDate = false;
    public saveBtnText = 'Save & Continue';
    public selectedIndustries: Gic[] = [];
    public maximumNumberOfIndustries = 1;
    public objinvesteeCntry!: Country
    public objinvestorCntry!: Country

    public valuationDateConfig: CommonInputConfig = {
        name: InputText.ValuationDate,
        help: HelpText.IntlValuationDate,
        readonly: true,
        required: true
    };
    public investorCountryConfig: CommonInputConfig = {
        name: InputText.InvestorCountry,
        help: HelpText.InvestorCountry,
        required: true,
        readonly: true,
    };
    public investeeCountryConfig: CommonInputConfig = {
        name: InputText.InvesteeCountry,
        help: HelpText.InvesteeCountry,
        required: true,
        readonly: true,
    };
    public cashFlowsConfig: CommonInputConfig = {
        name: InputText.CashFlowCountry,
        help: HelpText.CashFlowCountry,
        required: true,
        readonly: true
    };
    public countryOfInputsConfig: CommonInputConfig = {
        name: InputText.KeCountryOfInput,
        help: HelpText.KeCountryOfInput,
        required: true,
        readonly: true
    };
    public industryConfig: CommonInputConfig = {
        disabled: true,
        name: InputText.Industry,
        help: HelpText.IntlIndustry,
        required: true
    };

    public valuationDate: ContextualValue<DateStruct> = new ContextualValue<DateStruct>();
    public investorCountry: ContextualString = new ContextualString();
    public investeeCountry: ContextualString = new ContextualString();
    public industry: ContextualValue<Gic> = new ContextualValue<Gic>();
    public cashFlow: ContextualString = new ContextualString();
    public countryOfInput: ContextualString = new ContextualString();
    public estimateSummary: EstimateSummary | undefined;

    public cashFlowSelections: Suggestion<string | null>[] = [];
    public countryOfInputsSelections: Suggestion<string | null>[] = [];
    public dynamicText: DynamicText[] = [];
    public dynamicTextSummary!: DynamicTextSummary;
    public inputsCountryCurrency: string | undefined = undefined;
    public minimumDate = -1;
    public userGicId: any;

    constructor(
        private userStore: UserStore,
        public internationalQueryBuilderService: InternationalQueryBuilderService,
        private store: Store,
        private spinner: Spinner,
        private router: Router,
        private primeManager: PrimeManager,
        public industryDataSharingService: IndustryDataSharingService
    ) {
        super();
    }

    private updateCashFlows(investorCountry: Country, investeeCountry: Country): void {
        const countries = [investorCountry, investeeCountry];
        this.cashFlowSelections = countries.map((x) => IntlEstimateUtil.countryAsSuggestion(x, true));
    }

    private updateCountryRiskPremia(valuationDate: DateStruct, inputCountryId: number, investeeCountryId: number): void {
        const dateAsString = CommonDate.fromStruct(valuationDate).asString();
        const crpAction = new GetCountryRiskPremiaList(inputCountryId, investeeCountryId, dateAsString);
        const request = this.store.dispatch(crpAction);
        this.spinner.while(request);
        request.once(() => {
            this.countryRiskPremiaSelector.onceDefined((crp) => {
                this.modelsAvailable = DynamicTextUtilities.getCrpModelsAvailable(crp);
                this.updateDynamicText();
            });
        });
    }

    private initialize(
        user: User | undefined,
        estimate: Estimate | undefined,
        dynamicTextSummary: DynamicTextSummary | undefined,
        minimumDate: MinimumDate | undefined
    ): void {
        if (user == null || estimate == null || dynamicTextSummary == null || minimumDate == null) {
            return;
        }

        this.user = user;
        this.estimate = estimate;
        this.dynamicTextSummary = dynamicTextSummary;
        this.needsUpgrade = user.hasProOrEnterprise() === false;
        this.minimumDate = minimumDate.InternationalEstimates;

        const updateEstimateSummaryRequest = this.store.dispatch(new UpdateEstimateSummary(this.estimate));
        updateEstimateSummaryRequest.once(() => {
            this.estimateSummarySelector.onceDefined((estimateSummary) => {
                this.estimateSummary = estimateSummary;
                this.updateDynamicText();
            });
        });

        const date = CommonDate.fromString(this.estimate.ValuationDate);
        this.noDataForGivenDate = date.asMoment().isBefore(this.minimumDateForIndustry);
        this.industryConfig.disabled = this.isIndustryDisabled();
        this.valuationDateConfig = UserUtil.getIntValuationDateInputConfig(
            date,
            ProductType.Ine,
            minimumDate.InternationalEstimates,
            user.subscriptionDetails
        );
        this.valuationDate.value = date.asStruct();

        const request = combineLatest([
            this.store.dispatch(new SetAllCountries()),
            this.store.dispatch(new SetInvestorCountries()),
            this.store.dispatch(new GetCurrencyList())
        ]);

        this.industryDataSharingService.sharedGicIndustries$.subscribe((value) => {
            if (value.length > 0) {
                if (value.length === 1 && value[0].GicId === 0) {
                    const dbGicId = this.estimate.getInput(InputType.Gic);
                    if (dbGicId.Value) {
                        this.store.select(IntlIndustryListState.getByGicId(dbGicId.Value)).onceDefined((gic) => {
                            this.selectedIndustries.push({
                                GicId: gic[0].GicId,
                                GicCode: gic[0].GicCode,
                                GicDescription: gic[0].GicDescription
                            });
                            this.reflectIndustry(gic[0]);
                        });
                    } else {
                        this.selectedIndustries = [];
                    }
                } else {
                    this.selectedIndustries = value;
                    this.reflectIndustry(value[0]);
                }
            }
        });

        this.spinner.while(request);
        request.once(() => {
            if (this.valuationDate.value == null) {
                return;
            }

            const countryOfInput = this.estimate.getInput(InputType.CountryOfInputs);
            const cashFlow = this.estimate.getInput(InputType.CashFlows);
            const gicId = this.estimate.getInput(InputType.Gic);

            this.userGicId = gicId.Value;

            this.investorCountriesSelector.onceDefined((investorCountries) => {
                this.internationalQueryBuilderService.investorCountries = investorCountries;
                this.internationalQueryBuilderService.investorCountriesOptions = this.internationalQueryBuilderService.GetInvestorCountryOptions();
            });

            this.industryListSelector.onceDefined((industries) => {
                this.internationalQueryBuilderService.industries = industries;
            });



            if (this.estimate.InvesteeCountryId) {
                this.store.select(CountryListState.getById(this.estimate.InvesteeCountryId)).onceDefined((investee) => {
                    this.objinvesteeCntry = investee;
                    this.investeeCountry = new ContextualString(investee.CountryName, 'Custom', investee.CountryId);

                });
            }

            if (this.estimate.HomeCountryId && this.estimate.InvesteeCountryId) {
                combineLatest([
                    this.store.select(CountryListState.getById(this.estimate.HomeCountryId)),
                    this.store.select(CountryListState.getById(this.estimate.InvesteeCountryId))
                ]).pipe(
                    takeUntil(this.destroyed),
                    map((x) => ({
                        investor: x[0],
                        investee: x[1]
                    }))
                ).onceDefined((data) => {
                    if (data.investor == null || data.investee == null) {
                        return;
                    }

                    this.updateCashFlows(data.investor, data.investee);
                    this.updateCountryOfInputs(data.investor, data.investee);
                });
            }

            if (countryOfInput.Value) {
                this.store.select(CountryListState.getById(countryOfInput.Value)).onceDefined((country) => {
                    this.countryOfInput = new ContextualString(country.CountryName, country.CountryName, country.CountryId);
                });
            }

            if (cashFlow.Value) {
                this.store.select(CountryListState.getById(cashFlow.Value)).onceDefined((flow) => {
                    const cashFlowName = IntlEstimateUtil.asCashFlowName(flow);
                    this.cashFlow = new ContextualString(cashFlowName, cashFlowName, flow.CountryId);
                });
            }

            if (gicId.Value) {
                this.store.select(IntlIndustryListState.getByGicId(gicId.Value)).onceDefined((gic) => {
                    this.industry = new ContextualValue(gic[0]);
                });
            }
            if (this.estimate.HomeCountryId) {
                const dateAsString = CommonDate.fromStruct(date).asString();
                this.store.dispatch(new SetInvesteeCountries(dateAsString, this.estimate.HomeCountryId)).once(() => {
                    this.investeeCountriesSelector.onceDefined((investees) => {
                        this.internationalQueryBuilderService.investeeCountries = investees;
                        this.internationalQueryBuilderService.investeeCountriesOptions = this.internationalQueryBuilderService.GetInvesteeCountryOptions();
                    });
                });

                this.store.select(CountryListState.getById(this.estimate.HomeCountryId)).onceDefined((investor) => {
                    this.objinvestorCntry = investor;
                    this.investorCountry = new ContextualString(investor.CountryName, 'Custom', investor.CountryId);

                    if (this.investorCountry.value && this.investeeCountry.value && cashFlow.Value && countryOfInput.Value) {
                        this.countriesWithAvailableIndustriesSelector.onceDefined((countries) => {
                            const isKeInputCountryFound = countries.find((x) => x.CountryId === countryOfInput.Value);

                            if (isKeInputCountryFound && countryOfInput.Value) {
                                this.isIndustryNotAvailable = false;
                                const dateAsString = CommonDate.fromStruct(date).asString();
                                this.store.select(CountryListState.getById(countryOfInput.Value)).onceDefined((country) => {
                                    const currencyId = country.Currency.CurrencyId;
                                    const getIntlIndustries = new GetIntlIndustries(this.estimate.InvesteeCountryId, currencyId, dateAsString);
                                    const gicRequest = this.store.dispatch(getIntlIndustries);
                                    this.spinner.while(gicRequest);
                                });
                            } else {
                                this.isIndustryNotAvailable = true;
                                this.industry = new ContextualValue<Gic>();
                            }
                            this.industryConfig.disabled = this.isIndustryDisabled();
                        });
                    }

                });
            }



            if (countryOfInput.Value && this.estimate.InvesteeCountryId) {
                this.updateCountryRiskPremia(this.valuationDate.value, countryOfInput.Value, this.estimate.InvesteeCountryId);
                this.store.dispatch(new SetCcr(countryOfInput.Value, this.estimate.InvesteeCountryId, this.estimate.ValuationDate));
            }
        });
    }

    private updateCountryOfInputs(investorCountry: Country, investeeCountry: Country): void {
        let selected = [investorCountry, investeeCountry];
        const alwaysAvailableCountries = selected.find(
            (x) => InternationalInputsComponent.CountriesAlwaysAvailable.includes(x.CountryName)
        );

        if (alwaysAvailableCountries == null) {
            this.store
                .select(CountryListState.getByName(InternationalInputsComponent.CountriesAlwaysAvailable))
                .onceDefined((countries) => {
                    selected = selected.concat(countries);
                    this.countryOfInputsSelections = selected.map((x) => IntlEstimateUtil.countryAsSuggestion(x));
                });
        } else {
            this.countryOfInputsSelections = selected.map((x) => IntlEstimateUtil.countryAsSuggestion(x));
        }
    }

    private reset(resetType: ResetType): void {
        if (resetType === ResetType.ValuationDate) {
            this.investorCountry = new ContextualString();
            this.investeeCountry = new ContextualString();
            this.internationalQueryBuilderService.investeeCountries = [];
            this.internationalQueryBuilderService.investeeCountriesOptions = [];
            this.cashFlowSelections = [];
            this.countryOfInputsSelections = [];
            this.estimate.HomeCountryId = 0;
            this.estimate.InvesteeCountryId = 0;
            this.cashFlow = new ContextualString();
            this.countryOfInput = new ContextualString();
            this.industry = new ContextualValue<Gic>();
            this.internationalQueryBuilderService.industries = [];
            this.estimate.Inputs = [];
            this.estimate.Scenarios[0].Selections = [];
            this.isIndustryNotAvailable = false;
            this.noDataForGivenDate = false;
            this.industryConfig.disabled = this.isIndustryDisabled();
            const emptyGicIndustry: Gic[] = new Array<Gic>();
            emptyGicIndustry.push({
                GicId: 0,
                GicCode: '',
                GicDescription: ''
            } as Gic);
            this.industryDataSharingService.setSharedGicIndustries = emptyGicIndustry;
        } else if (resetType === ResetType.Investor || resetType === ResetType.Investee) {
            if (resetType === ResetType.Investor) {
                this.investeeCountry = new ContextualString();
                this.internationalQueryBuilderService.investeeCountries = [];
                this.internationalQueryBuilderService.investeeCountriesOptions = [];

            }
            this.cashFlowSelections = [];
            this.countryOfInputsSelections = [];
            this.isIndustryNotAvailable = false;
            this.estimate.InvesteeCountryId = 0;
            this.cashFlow = new ContextualString();
            this.countryOfInput = new ContextualString();
            this.industry = new ContextualValue<Gic>();
            this.internationalQueryBuilderService.industries = [];
            this.estimate.Inputs = [];
            this.estimate.Scenarios[0].Selections = [];
            const emptyGicIndustry: Gic[] = new Array<Gic>();
            emptyGicIndustry.push({
                GicId: 0,
                GicCode: '',
                GicDescription: ''
            } as Gic);
            this.industryDataSharingService.setSharedGicIndustries = emptyGicIndustry;
        } else if (resetType === ResetType.CashFlows) {
            const selectionsToReset = [
                SelectionType.CostOfEquityFisherEffectCcr,
                SelectionType.CostOfEquityFisherEffectYieldSpread,
                SelectionType.CostOfEquityFisherEffectVolatility,
                SelectionType.CostOfDebtFisherEffectCcr,
                SelectionType.CostOfDebtFisherEffectYieldSpread,
                SelectionType.CostOfDebtFisherEffectVolatility,
                SelectionType.WaccCcr,
                SelectionType.WaccYieldSpread,
                SelectionType.WaccVolatility,
                SelectionType.KeSingleCountryFisherEffect,
                SelectionType.KdSingleCountryFisherEffect,
                SelectionType.WaccSingleCountry,
                SelectionType.InflationInvestor,
                SelectionType.InflationInvestee
            ];
            this.estimate.Scenarios[0].Selections
                .filter((x) => selectionsToReset.includes(x.SelectionType))
                .forEach((x) => x.Value = undefined);
        }
        this.store.dispatch(new UpdateLocalEstimate(this.estimate));
        this.store.dispatch(new UpdateEstimateSummary(this.estimate)).once(() => {
            this.estimateSummarySelector.onceDefined((estimateSummary) => {
                this.estimateSummary = estimateSummary;
                this.updateDynamicText();
            });
        });
    }

    private isIndustryDisabled(): boolean {
        return this.user.hasProOrEnterprise() === false || this.noDataForGivenDate || this.isIndustryNotAvailable;
    }

    private updateDynamicText(): void {
        if (this.estimateSummary == null) {
            return;
        }

        this.dynamicText = DynamicTextUtilities.updateDynamicText(
            [],
            `General Information`,
            `General Information`,
            DynamicHelpText.GeneralInformation_1(this.estimateSummary)
        );

        if (this.cashFlow.value) {
            this.dynamicText = DynamicTextUtilities.updateDynamicText(
                this.dynamicText,
                `Cash Flows and Cost of Capital Conclusion Denominated in:`,
                `Cash Flows and Cost of Capital Conclusion Denominated in: `,
                DynamicHelpText.CostOfCapitalDenominatedIn_6(this.cashFlow.value, this.estimateSummary.EstimateName),
                `${this.cashFlow.value}`
            );
        }

        if (this.countryOfInput.value) {
            this.dynamicText = DynamicTextUtilities.updateDynamicText(
                this.dynamicText,
                `Cost of Equity Inputs Country:`,
                `Cost of Equity Inputs Country: `,
                DynamicHelpText.CostOfEquityInputsCountry_2(this.countryOfInput.value),
                `${this.countryOfInput.value}`
            );
        }

        if (this.cashFlow.value
            && this.countryOfInput.value
            && this.estimateSummary.CashFlowCountry
            && this.estimateSummary.CountryOfInputs
        ) {
            if (this.estimateSummary.CashFlowCountry.Currency.CurrencyId === this.estimateSummary.CountryOfInputs.Currency.CurrencyId) {
                this.dynamicText = DynamicTextUtilities.updateDynamicText(
                    this.dynamicText,
                    `International Fisher Effect`,
                    `International Fisher Effect (Cost of Equity): `,
                    DynamicHelpText.FisherEffectCostOfEquityNotRequired_5a(
                        this.countryOfInput.value,
                        this.cashFlow.value,
                        this.inputsCountryCurrency
                    ),
                    `Not Required`
                );
            } else {
                this.dynamicText = DynamicTextUtilities.updateDynamicText(
                    this.dynamicText,
                    `International Fisher Effect`,
                    `International Fisher Effect (Cost of Equity): `,
                    DynamicHelpText.FisherEffectCostOfEquityRequired_5b(
                        this.countryOfInput.value,
                        this.cashFlow.value,
                        this.inputsCountryCurrency
                    ),
                    `Required`
                );
            }
        }

        if (this.investeeCountry.value && this.estimateSummary.CountryOfInputs) {
            if (this.investeeCountry.id === this.estimateSummary.CountryOfInputs.CountryId) {
                this.dynamicText = DynamicTextUtilities.updateDynamicText(
                    this.dynamicText,
                    `CRP/RV Factor:`,
                    `CRP/RV Factor: `,
                    DynamicHelpText.CrpRvFactorNotRequired_3a(this.countryOfInput.value, this.estimateSummary.EstimateName),
                    `Not Required`
                );
                this.dynamicText = DynamicTextUtilities.updateDynamicText(
                    this.dynamicText,
                    `Model`,
                    `Model(s) Applicable: `,
                    DynamicHelpText.ModelsApplicableSingleCountryCapm_4a(this.countryOfInput.value),
                    `Single Country CAPM`
                );
            } else {
                this.dynamicText = DynamicTextUtilities.updateDynamicText(
                    this.dynamicText,
                    `CRP/RV Factor:`,
                    `CRP/RV Factor: `,
                    DynamicHelpText.CrpRvFactorRequired_3b(
                        this.countryOfInput.value,
                        this.estimateSummary.EstimateName,
                        this.investeeCountry.value
                    ),
                    `Required`
                );
                const models = Array.from(this.modelsAvailable).join(', ');
                this.dynamicText = DynamicTextUtilities.updateDynamicText(
                    this.dynamicText,
                    `Model`,
                    `Models Available: `,
                    DynamicHelpText.ModelsAvailable_4b(
                        this.modelsAvailable,
                        models,
                        this.investeeCountry.value,
                        this.countryOfInput.value,
                        this.estimateSummary.ValuationDate
                    ),
                    `${models}`
                );
            }
        }

        this.dynamicTextSummary.GeneralInputsText = this.dynamicText;
        this.store.dispatch(new UpdateDynamicTextSummary(this.dynamicTextSummary));
    }

    public ngOnInit(): void {
        combineLatest([
            this.userStore.user,
            this.estimateSelector,
            this.dynamicTextSummarySelector,
            this.valuesSelector
        ]).pipe(
            takeUntil(this.destroyed),
            map((x) => ({
                user: x[0],
                estimate: x[1],
                dynamicTextSelector: x[2],
                value: x[3]
            }))
        ).onceDefined((data) => {
            this.initialize(data.user, data.estimate, data.dynamicTextSelector, data.value);
        });
    }

    public reflectDate(struct: DateStruct): void {
        this.reset(ResetType.ValuationDate);
        const date = CommonDate.fromStruct(struct);
        this.noDataForGivenDate = date.asMoment().isBefore(this.minimumDateForIndustry);
        this.industryConfig.disabled = this.isIndustryDisabled();
        this.valuationDateConfig = UserUtil.getIntValuationDateInputConfig(
            date,
            ProductType.Ine,
            this.minimumDate,
            this.user.subscriptionDetails
        );
        this.estimate.ValuationDate = date.asString();
        this.store.dispatch(new UpdateEstimateSummary(this.estimate));
        this.store.dispatch(new UpdateLocalEstimate(this.estimate));

        if (this.estimate.InvesteeCountryId && this.countryOfInput.id) {
            const ccrAction = new SetCcr(this.countryOfInput.id, this.estimate.InvesteeCountryId, this.estimate.ValuationDate);
            const request = this.store.dispatch(ccrAction);
            this.spinner.while(request);
        }
    }

    public reflectInvestor(valuationDate: DateStruct, investor: any): void {
        const findInvestor = this.internationalQueryBuilderService.investorCountries.filter((x) => x.CountryName === investor.value);
        if (investor.value !== '' && findInvestor.length === 0) {
            this.internationalQueryBuilderService.investorCountriesOptions = this.internationalQueryBuilderService.investorCountriesOptions.filter((item) => item.value.toLowerCase().startsWith(investor.value.toLowerCase()));
        } else {
            this.internationalQueryBuilderService.investorCountriesOptions = this.internationalQueryBuilderService.GetInvestorCountryOptions();
            const investorCountry = this.GetCountry(investor, 'investor');

            if (investorCountry.CountryId === this.estimate.HomeCountryId) {
                return;
            }

            this.reset(ResetType.Investor);
            this.estimate.HomeCountryId = investorCountry.CountryId;
            this.store.dispatch(new UpdateEstimateSummary(this.estimate));
            this.store.dispatch(new UpdateLocalEstimate(this.estimate));

            const dateAsString = CommonDate.fromStruct(valuationDate).asString();
            this.store.dispatch(new SetInvesteeCountries(dateAsString, investorCountry.CountryId)).once(() => {
                this.investeeCountriesSelector.onceDefined((investees) => {
                    this.internationalQueryBuilderService.investeeCountries = investees;
                    this.internationalQueryBuilderService.investeeCountriesOptions = this.internationalQueryBuilderService.GetInvesteeCountryOptions();
                });
            });
        }

    }

    public reflectInvestee(investor: any, investee: any): void {
        const findInvestee = this.internationalQueryBuilderService.investeeCountries.filter((x) => x.CountryName === investee.value);
        if (investee.value !== '' && findInvestee.length === 0) {
            this.internationalQueryBuilderService.investeeCountriesOptions = this.internationalQueryBuilderService.investeeCountriesOptions.filter((item) => item.value.toLowerCase().startsWith(investee.value.toLowerCase()));
        } else {
            this.internationalQueryBuilderService.investeeCountriesOptions = this.internationalQueryBuilderService.GetInvesteeCountryOptions();
            const investeeCountry = this.GetCountry(investee, 'investee');
            const investorCountry = this.GetCountry(investor, 'investor');
            if (investeeCountry.CountryId === this.estimate.InvesteeCountryId) {
                return;
            }


            this.objinvesteeCntry = investeeCountry;
            this.objinvestorCntry = investorCountry;

            this.reset(ResetType.Investee);
            this.updateCashFlows(investorCountry, investeeCountry);
            this.updateCountryOfInputs(investorCountry, investeeCountry);
            this.estimate.InvesteeCountryId = investeeCountry.CountryId;
            this.store.dispatch(new UpdateEstimateSummary(this.estimate));
            this.store.dispatch(new UpdateLocalEstimate(this.estimate));

            if (this.estimate.InvesteeCountryId && this.countryOfInput.id) {
                const ccrAction = new SetCcr(this.countryOfInput.id, this.estimate.InvesteeCountryId, this.estimate.ValuationDate);
                const request = this.store.dispatch(ccrAction);
                this.spinner.while(request);
            }
        }

    }

    public reflectCashFlows(): void {
        if (this.cashFlow.id === this.estimate.getInput(InputType.CashFlows).Value) {
            return;
        }

        this.reset(ResetType.CashFlows);
        this.estimate.setInput(InputType.CashFlows, this.cashFlow.id);
        this.store.dispatch(new UpdateLocalEstimate(this.estimate));
        this.store.dispatch(new UpdateEstimateSummary(this.estimate)).once(() => {
            this.estimateSummarySelector.onceDefined((estimateSummary) => {
                this.estimateSummary = estimateSummary;
                this.updateDynamicText();
            });
        });
    }

    public reflectCountryOfInputs(): void {
        if (this.countryOfInput.id == null || this.valuationDate.value == null || this.investeeCountry.value == null) {
            return;
        }

        const dateAsString = CommonDate.fromStruct(this.valuationDate.value).asString();
        this.updateCountryRiskPremia(this.valuationDate.value, this.countryOfInput.id, this.objinvesteeCntry.CountryId);
        this.estimate.setInput(InputType.CountryOfInputs, this.countryOfInput.id);
        this.store.dispatch(new UpdateLocalEstimate(this.estimate));

        this.store.dispatch(new UpdateEstimateSummary(this.estimate)).once(() => {
            this.estimateSummarySelector.onceDefined((estimateSummary) => {
                this.estimateSummary = estimateSummary;
                this.updateDynamicText();
            });
        });

        this.store.select(CountryListState.getById(this.countryOfInput.id)).onceDefined((country) => {
            this.inputsCountryCurrency = country.Currency.CurrencyName;

            if (this.investeeCountry.value == null) {
                return;
            }

            const request = this.store.dispatch(
                new GetIntlIndustries(this.objinvesteeCntry.CountryId, country.Currency.CurrencyId, dateAsString)
            );
            this.spinner.while(request);
            request.once(() => {
                this.industryListSelector.onceDefined((industries) => {
                    this.internationalQueryBuilderService.industries = industries;
                });
            });
        });

        this.countriesWithAvailableIndustriesSelector.onceDefined((countries) => {
            const keInputCountryFound = countries.find((x) => x.CountryId === this.countryOfInput.id);

            if (keInputCountryFound) {
                this.isIndustryNotAvailable = false;
            } else {
                this.isIndustryNotAvailable = true;
                this.industry = new ContextualValue<Gic>();
            }

            this.industryConfig.disabled = this.isIndustryDisabled();
        });

        if (this.estimate.InvesteeCountryId && this.countryOfInput.id) {
            const ccrAction = new SetCcr(this.countryOfInput.id, this.estimate.InvesteeCountryId, this.estimate.ValuationDate);
            const request = this.store.dispatch(ccrAction);
            this.spinner.while(request);
        }
    }

    public reflectIndustry(industryGic: Gic): void {
        this.estimate.setInput(InputType.Gic, Number(industryGic.GicId));
        this.store.dispatch(new UpdateEstimateSummary(this.estimate));
        this.store.dispatch(new UpdateLocalEstimate(this.estimate));
    }

    public saveAndContinue(): void {
        const estimateRequest = this.store.dispatch(new UpdateDbEstimate());
        this.spinner.while(estimateRequest);
        estimateRequest.once(() => {
            this.spinner.while(this.store.dispatch(new GetEstimates()));
            RouteUtil.saveAndContinue(this.router.url, this.router);
        });
    }

    public isGeneralInputsValid(): boolean {
        if ((this.investorCountry.value && this.investeeCountry.value && this.cashFlow.value && this.countryOfInput.value)
            && (this.selectedIndustries.length < this.maximumNumberOfIndustries)) {
            if (this.isIndustryNotAvailable) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    }

    public pullIndustries(): void {
        this.industryListSelector.onceDefined((industries) => {
            this.primeManager.openDialog(InterAddIndustriesComponent, {
                allIndustries: industries,
                userSelectedGicId: 0
            });
        });
    }

    public removeIndustry = (codeId: number): void => {
        this.selectedIndustries = this.selectedIndustries.filter((item) => item.GicId !== codeId);
    }

    public OnInvestorCountryChange(searchTerm: any): void {
        if (searchTerm !== '') {
            this.internationalQueryBuilderService.investorCountriesOptions = this.internationalQueryBuilderService.investorCountriesOptions.filter((item) => item.value.toLowerCase().startsWith(searchTerm.toLowerCase()));
        }
    }

    public OnInvesteeCountryChange(searchTerm: any): void {
        if (searchTerm !== '') {
            this.internationalQueryBuilderService.investeeCountriesOptions = this.internationalQueryBuilderService.investeeCountriesOptions.filter((item) => item.value.toLowerCase().startsWith(searchTerm.toLowerCase()));
        }
    }

    private GetCountry(country: any, name: string): Country {
        let emptyCountry: Country = {
            CountryId: 0,
            CountryName: '',
            Currency: {
                CurrencyId: 0,
                CurrencyName: '',
                CurrencyAcronym: ''
            }

        };
        const getCountry = name === 'investor' ? this.internationalQueryBuilderService.investorCountries.find((x: Country) => x.CountryId === country.id) : this.internationalQueryBuilderService.investeeCountries.find((x: Country) => x.CountryId === country.id);
        return getCountry ? getCountry : emptyCountry;
    }
}
