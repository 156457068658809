import { CountryRiskPremiaType } from 'src/app/_api/enums/country-risk-premia-type';
import { CountryRiskPremia } from 'src/app/_api/responses/country-risk-premia.response';
import { EstimateSummary } from 'src/app/_api/responses/estimate-summary.response';
import { DynamicHelpText } from 'src/app/_navigator/help/dynamic-help-text';
import { DynamicText } from '../model/dynamic-text';
import { DynamicTextSummary } from '../model/dynamic-text-summary';
import { DynamicTextUtilities } from './dynamic-text.util';

export class DynamicTextBuilderUtil {
    public static getDynamicTextSummary(
        estimateSummary: EstimateSummary,
        dynamicTextSummary: DynamicTextSummary,
        crp: CountryRiskPremia[]
    ): DynamicTextSummary {
        dynamicTextSummary.CostOfEquityInputsText = this.getCostOfEquityInputsText(estimateSummary);
        dynamicTextSummary.CrpSummaryText = this.getCrpSummaryText(crp, estimateSummary);
        dynamicTextSummary.FisherEffectText = this.getFisherEffectText(estimateSummary);
        dynamicTextSummary.CostOfEquitySummaryCapmText = this.getCostOfEquitySummaryCapmText(crp, estimateSummary);
        dynamicTextSummary.CostOfEquitySummaryFisherEffectText = this.getCostOfEquitySummaryFisherEffectText(estimateSummary);
        dynamicTextSummary.CostOfEquitySummaryConclusionText = this.getCostOfEquitySummaryConclusionText(crp, estimateSummary);
        dynamicTextSummary.KdAndWaccInputsText = this.getKdAndWaccInputxText(estimateSummary);
        dynamicTextSummary.CostOfDebtSummaryCapitalText = this.getCostOfDebtSummaryCapitalText(crp, estimateSummary);
        dynamicTextSummary.CostOfDebtSummaryFisherEffectText = this.getCostOfDebtSummaryFisherEffectText(estimateSummary);
        dynamicTextSummary.CostOfDebtSummaryConclusionText = this.getCostOfDebtSummaryConclusionText(estimateSummary, crp);
        dynamicTextSummary.WaccSummaryCapitalText = this.getWaccSummaryCapitalText(estimateSummary);
        dynamicTextSummary.WaccSummaryConclusionText = this.getWaccSummaryConclusionText(crp, estimateSummary);

        return dynamicTextSummary;
    }

    private static getCostOfEquityInputsText(estimateSummary: EstimateSummary): DynamicText[] {
        if (estimateSummary.CountryOfInputsName == null) {
            return [];
        }

        return DynamicTextUtilities.updateDynamicText(
            [],
            'Cost of Equity Inputs Country:',
            `Cost of Equity Inputs Country: ${estimateSummary.CountryOfInputsName}`,
            DynamicHelpText.CostOfEquityInputsCountry_7(`${estimateSummary.CountryOfInputsName}`)
        );
    }

    private static getCrpSummaryText(crp: CountryRiskPremia[], estimateSummary: EstimateSummary): DynamicText[] {

        let dynamicText: DynamicText[] = [];
        let euroMoneyRegion: CountryRiskPremia | undefined;
        let msciClassification: CountryRiskPremia | undefined;
        let creditRating: CountryRiskPremia | undefined;

        if (crp && crp.length > 0) {
            euroMoneyRegion = crp.find((x) => x.Type === CountryRiskPremiaType.EuromoneyRegion);
            msciClassification = crp.find((x) => x.Type === CountryRiskPremiaType.MsciClassification);
            creditRating = crp.find((x) => x.Type === CountryRiskPremiaType.SpCreditRating);
        }
        if (estimateSummary.CountryOfInputsName !== estimateSummary.InvesteeCountryName
            && euroMoneyRegion != null
            && msciClassification != null
            && creditRating != null
        ) {
            dynamicText = DynamicTextUtilities.updateDynamicText(
                dynamicText,
                'CRP/RV Factor:',
                'CRP/RV Factor: Required',
                DynamicHelpText.CountryRiskPremiumRequired_8b(
                    estimateSummary.CountryOfInputsName,
                    estimateSummary.InvesteeCountryName,
                    estimateSummary.ValuationDate,
                    euroMoneyRegion.RegionName,
                    msciClassification.RegionName,
                    creditRating.RegionName,
                    DynamicTextUtilities.getCrpModelsAvailable(crp),
                    estimateSummary.EstimateName
                )
            );
        } else {
            dynamicText = DynamicTextUtilities.updateDynamicText(
                dynamicText,
                'CRP/RV Factor:',
                'CRP/RV Factor: Not Required',
                DynamicHelpText.CountryRiskPremiumNotRequired_8a(
                    estimateSummary.CountryOfInputsName,
                    estimateSummary.EstimateName
                )
            );
        }

        return dynamicText;
    }

    private static getFisherEffectText(estimateSummary: EstimateSummary): DynamicText[] {
        let dynamicText: DynamicText[] = [];

        let hasKeFisherEffect = false;
        if (estimateSummary.InputCountryCurrency) {
            hasKeFisherEffect = estimateSummary.InputCountryCurrency !== estimateSummary.CashFlowCurrency;
        }

        let hasKdFisherEffect = false;
        if (estimateSummary.CostOfDebtInputCountryCurrency) {
            hasKdFisherEffect = estimateSummary.CostOfDebtInputCountryCurrency !== estimateSummary.CashFlowCurrency;
        }

        const isFisherEffectRequired = hasKeFisherEffect || hasKdFisherEffect;

        if (isFisherEffectRequired) {
            dynamicText = DynamicTextUtilities.updateDynamicText(
                [],
                'International Fisher Effect',
                'International Fisher Effect: Required',
                DynamicHelpText.InternationalFisherEffectRequired_9b(
                    estimateSummary.CountryOfInputsName,
                    estimateSummary.InputCountryCurrency,
                    estimateSummary.CashFlowCurrency,
                    estimateSummary.InvestorCountryName,
                    estimateSummary.InvesteeCountryName
                )
            );
        } else {
            dynamicText = DynamicTextUtilities.updateDynamicText(
                [],
                'International Fisher Effect',
                'International Fisher Effect: Not Required',
                DynamicHelpText.InternationalFisherEffectNotRequired_9a(
                    estimateSummary.CountryOfInputsName,
                    estimateSummary.InputCountryCurrency,
                    estimateSummary.CashFlowCurrency
                )
            );
        }

        return dynamicText;
    }

    private static getCostOfEquitySummaryCapmText(crp: CountryRiskPremia[], estimateSummary: EstimateSummary): DynamicText[] {
        let dynamicText: DynamicText[] = [];

        if (estimateSummary.CountryOfInputsName === estimateSummary.InvesteeCountryName) {
            dynamicText = DynamicTextUtilities.updateDynamicText(
                dynamicText,
                'Cost of Equity Estimate',
                'Cost of Equity Estimate: ',
                DynamicHelpText.coeEstimateSingleCountryCapm_10a(estimateSummary.EstimateName),
                'Single Country CAPM'
            );
        } else {
            dynamicText = DynamicTextUtilities.updateDynamicText(
                dynamicText,
                'Cost of Equity Estimate',
                'Cost of Equity Estimate(s): ',
                DynamicHelpText.capmWithCrpAndRvFactorAdjustments_10b(
                    estimateSummary.EstimateName,
                    DynamicTextUtilities.getCrpModelsAvailable(crp)
                ),
                'CAPM with CRP and/or RV Factor Adjustments'
            );
        }

        if (estimateSummary.InputCountryCurrency === estimateSummary.CashFlowCurrency) {
            dynamicText = DynamicTextUtilities.updateDynamicText(
                dynamicText,
                'Cost of Equity Capital',
                'Cost of Equity Capital: ',
                DynamicHelpText.finalCoeConclusionNoFisherEffect_11a(),
                'Final Conclusion ‒ International Fisher Effect Not Required'
            );
        } else {
            dynamicText = DynamicTextUtilities.updateDynamicText(
                dynamicText,
                'Cost of Equity Capital',
                'Cost of Equity Capital: ',
                DynamicHelpText.finalCoeConclusionWithFisherEffect_11b(estimateSummary.EstimateName),
                'Pre-Final Conclusion ‒ International Fisher Effect Required'
            );
        }

        return dynamicText;
    }

    private static getCostOfEquitySummaryFisherEffectText(estimateSummary: EstimateSummary): DynamicText[] {
        let dynamicText: DynamicText[] = [];

        if (estimateSummary.InputCountryCurrency === estimateSummary.CashFlowCurrency) {
            dynamicText = DynamicTextUtilities.updateDynamicText(
                [],
                'International Fisher Effect',
                'International Fisher Effect Not Required.',
                DynamicHelpText.internationalFisherEffect_12a()
            );
        } else {
            dynamicText = DynamicTextUtilities.updateDynamicText(
                [],
                'Cost of Equity Capital',
                'Cost of Equity Capital: Final Conclusion',
                DynamicHelpText.coeCapitalFinalConclusion_12b(estimateSummary.EstimateName)
            );
        }

        return dynamicText;
    }

    private static getCostOfEquitySummaryConclusionText(crp: CountryRiskPremia[], estimateSummary: EstimateSummary): DynamicText[] {
        const euroMoneyRegion = crp.find((x) => x.Type === CountryRiskPremiaType.EuromoneyRegion);
        const msciClassification = crp.find((x) => x.Type === CountryRiskPremiaType.MsciClassification);
        const creditRating = crp.find((x) => x.Type === CountryRiskPremiaType.SpCreditRating);
        let dynamicText: DynamicText[] = [];

        if (estimateSummary.CountryOfInputsName !== estimateSummary.InvesteeCountryName
            && euroMoneyRegion != null
            && msciClassification != null
            && creditRating != null
        ) {
            dynamicText = DynamicTextUtilities.updateDynamicText(
                dynamicText,
                'Cost of Equity Capital Conclusions',
                'Cost of Equity Capital Conclusions',
                DynamicHelpText.coeCapitalConclusions_14b(
                    estimateSummary.EstimateName,
                    estimateSummary.CountryOfInputsName,
                    estimateSummary.InvesteeCountryName,
                    euroMoneyRegion.RegionName,
                    msciClassification.RegionName,
                    creditRating.RegionName,
                    estimateSummary.ValuationDate,
                    DynamicTextUtilities.getCrpModelsAvailable(crp)
                )
            );
        } else {
            dynamicText = DynamicTextUtilities.updateDynamicText(
                dynamicText,
                'Cost of Equity Capital Conclusions',
                'Cost of Equity Capital Conclusions',
                DynamicHelpText.coeCapitalConclusions_14a(estimateSummary.EstimateName)
            );
        }

        if (estimateSummary.InputCountryCurrency === estimateSummary.CashFlowCurrency) {
            dynamicText = DynamicTextUtilities.updateDynamicText(
                dynamicText,
                'Final Cost of Equity Results',
                'Final Cost of Equity Results',
                DynamicHelpText.text_13a()
            );
        } else {
            dynamicText = DynamicTextUtilities.updateDynamicText(
                dynamicText,
                'Final Cost of Equity Results',
                'Final Cost of Equity Results (post-International Fisher Effect)',
                DynamicHelpText.text_13b(estimateSummary.EstimateName)
            );
        }

        return dynamicText;
    }

    private static getKdAndWaccInputxText(estimateSummary: EstimateSummary): DynamicText[] {
        let dynamicText: DynamicText[] = [];

        if (estimateSummary.CostOfDebtInputCountryCurrency === estimateSummary.CashFlowCurrency) {
            dynamicText = DynamicTextUtilities.updateDynamicText(
                dynamicText,
                'International Fisher Effect (Cost of Debt):',
                'International Fisher Effect (Cost of Debt): ',
                DynamicHelpText.intlFisherEffectCoDNotRequired_15a(
                    estimateSummary.EstimateName,
                    estimateSummary.CountryOfInputsName,
                    estimateSummary.InvesteeCountryName,
                    estimateSummary.InputCountryCurrency,
                    estimateSummary.CashFlowCurrency,
                    estimateSummary.InvesteeCountryCurrency
                ),
                'Not Required'
            );
        } else {
            dynamicText = DynamicTextUtilities.updateDynamicText(
                dynamicText,
                'International Fisher Effect (Cost of Debt):',
                'International Fisher Effect (Cost of Debt): ',
                DynamicHelpText.intlFisherEffectCoDRequired_15b(
                    estimateSummary.EstimateName,
                    estimateSummary.CountryOfInputsName,
                    estimateSummary.InvesteeCountryName,
                    estimateSummary.InputCountryCurrency,
                    estimateSummary.CashFlowCurrency,
                    estimateSummary.InvesteeCountryCurrency
                ),
                'Required'
            );
        }

        if (estimateSummary.CostOfDebtInputsCountryName == null || estimateSummary.InvesteeCountryName == null) {
            return dynamicText;
        }

        if (estimateSummary.CostOfDebtInputsCountryName === estimateSummary.InvesteeCountryName) {
            dynamicText = DynamicTextUtilities.updateDynamicText(
                dynamicText,
                'CRP/RV Factor:',
                'CRP/RV Factor: ',
                DynamicHelpText.crpRvFactorNotRequired_16a(
                    estimateSummary.EstimateName,
                    estimateSummary.CostOfDebtInputsCountryName,
                    estimateSummary.InvesteeCountryName
                ),
                'Not Required'
            );
        } else {
            dynamicText = DynamicTextUtilities.updateDynamicText(
                dynamicText,
                'CRP/RV Factor:',
                'CRP/RV Factor: ',
                DynamicHelpText.crpRvFactorRequired_16b(
                    estimateSummary.EstimateName,
                    estimateSummary.CostOfDebtInputsCountryName,
                    estimateSummary.InvesteeCountryName
                ),
                'Required'
            );
        }

        return dynamicText;
    }

    private static getCostOfDebtSummaryCapitalText(crp: CountryRiskPremia[], estimateSummary: EstimateSummary): DynamicText[] {
        let dynamicText: DynamicText[] = [];

        if (estimateSummary.CostOfDebtInputCountryCurrency === estimateSummary.CashFlowCurrency) {
            dynamicText = DynamicTextUtilities.updateDynamicText(
                dynamicText,
                'Cost of Debt Capital: ',
                'Cost of Debt Capital: ',
                DynamicHelpText.codCapitalPreFinalConclusionFisherEffectNotRequired_18a(),
                'Final Conclusion ‒ International Fisher Effect not Required'
            );
        } else {
            dynamicText = DynamicTextUtilities.updateDynamicText(
                dynamicText,
                'Cost of Debt Capital: ',
                'Cost of Debt Capital: ',
                DynamicHelpText.codCapitalPreFinalConclusionFisherEffectRequired_18b(estimateSummary.EstimateName),
                'Pre-Final Conclusion ‒ International Fisher Effect Required'
            );
        }

        if (estimateSummary.CostOfDebtInputsCountryName === estimateSummary.InvesteeCountryName) {
            dynamicText = DynamicTextUtilities.updateDynamicText(
                dynamicText,
                'Cost of Debt Estimate: ',
                'Cost of Debt Estimate: ',
                DynamicHelpText.codEstimateCrpRvFactorNotRequired_17a(
                    estimateSummary.CostOfDebtInputsCountryName,
                    estimateSummary.InvesteeCountryName
                ),
                'CRP/RV Factor Not Required'
            );
        } else {
            dynamicText = DynamicTextUtilities.updateDynamicText(
                dynamicText,
                'Cost of Debt Estimate: ',
                'Cost of Debt Estimate: ',
                DynamicHelpText.codEstimateCrpRvFactorRequired_17b(
                    estimateSummary.EstimateName,
                    estimateSummary.CostOfDebtInputsCountryName,
                    estimateSummary.InvesteeCountryName,
                    DynamicTextUtilities.getCrpModelsAvailable(crp)
                ),
                'CRP/RV Factor Required'
            );
        }

        return dynamicText;
    }

    private static getCostOfDebtSummaryFisherEffectText(estimateSummary: EstimateSummary): DynamicText[] {
        let dynamicText: DynamicText[] = [];

        if (estimateSummary.CostOfDebtInputCountryCurrency === estimateSummary.CashFlowCurrency) {
            dynamicText = DynamicTextUtilities.updateDynamicText(
                [],
                'Cost of Debt Capital: ',
                'International Fisher Effect Not Required.',
                DynamicHelpText.FisherEffectNotRequired_19a()
            );
        } else {
            dynamicText = DynamicTextUtilities.updateDynamicText(
                [],
                'International Fisher Effect Not Required. ',
                'Cost of Debt Capital: ',
                DynamicHelpText.FisherEffectRequired_19b(estimateSummary.EstimateName),
                'Final Conclusion'
            );
        }

        return dynamicText;
    }

    private static getCostOfDebtSummaryConclusionText(estimateSummary: EstimateSummary, crp: CountryRiskPremia[]): DynamicText[] {
        const euroMoneyRegion = crp.find((x) => x.Type === CountryRiskPremiaType.EuromoneyRegion);
        const msciClassification = crp.find((x) => x.Type === CountryRiskPremiaType.MsciClassification);
        const creditRating = crp.find((x) => x.Type === CountryRiskPremiaType.SpCreditRating);
        let dynamicText: DynamicText[] = [];

        if (estimateSummary.CostOfDebtInputCountryCurrency === estimateSummary.CashFlowCurrency) {
            dynamicText = DynamicTextUtilities.updateDynamicText(
                dynamicText,
                'Final Cost of Debt Results',
                'Final Cost of Debt Results',
                DynamicHelpText.text_20a()
            );
        } else {
            dynamicText = DynamicTextUtilities.updateDynamicText(
                dynamicText,
                'Final Cost of Debt Results',
                'Final Cost of Debt Results (post-International Fisher Effect)',
                DynamicHelpText.text_20b()
            );
        }

        if (estimateSummary.CostOfDebtInputsCountryName !== estimateSummary.InvesteeCountryName
            && euroMoneyRegion != null
            && msciClassification != null
            && creditRating != null
        ) {
            dynamicText = DynamicTextUtilities.updateDynamicText(
                dynamicText,
                'Cost of Debt Conclusions',
                'Cost of Debt Conclusions',
                DynamicHelpText.codCapitalConclusions_21b(
                    estimateSummary.EstimateName,
                    estimateSummary.CostOfDebtInputsCountryName,
                    estimateSummary.InvesteeCountryName,
                    euroMoneyRegion.RegionName,
                    msciClassification.RegionName,
                    creditRating.RegionName,
                    estimateSummary.ValuationDate,
                    DynamicTextUtilities.getCrpModelsAvailable(crp)
                )
            );
        } else {
            dynamicText = DynamicTextUtilities.updateDynamicText(
                dynamicText,
                'Cost of Debt Conclusions',
                'Cost of Debt Conclusions',
                DynamicHelpText.codCapitalConclusions_21a(estimateSummary.EstimateName)
            );
        }

        return dynamicText;
    }

    private static getWaccSummaryCapitalText(estimateSummary: EstimateSummary): DynamicText[] {
        return DynamicTextUtilities.updateDynamicText(
            [],
            'WACC Calculations',
            'WACC Calculations',
            DynamicHelpText.waccCalculations_22a(estimateSummary.EstimateName)
        );
    }

    private static getWaccSummaryConclusionText(crp: CountryRiskPremia[], estimateSummary: EstimateSummary): DynamicText[] {
        const euroMoneyRegion = crp.find((x) => x.Type === CountryRiskPremiaType.EuromoneyRegion);
        const msciClassification = crp.find((x) => x.Type === CountryRiskPremiaType.MsciClassification);
        const creditRating = crp.find((x) => x.Type === CountryRiskPremiaType.SpCreditRating);

        let helpText = DynamicHelpText.waccConclusions_23b(estimateSummary.CashFlowCurrency);

        if (estimateSummary.CostOfDebtInputsCountryName !== estimateSummary.InvesteeCountryName
            && estimateSummary.CountryOfInputsName !== estimateSummary.InvesteeCountryName
            && euroMoneyRegion != null
            && msciClassification != null
            && creditRating != null
        ) {
            helpText = DynamicHelpText.waccConclusions_23a(
                estimateSummary.EstimateName,
                estimateSummary.CashFlowCurrency,
                estimateSummary.InvesteeCountryName,
                estimateSummary.CountryOfInputsName,
                euroMoneyRegion.RegionName,
                msciClassification.RegionName,
                creditRating.RegionName,
                estimateSummary.ValuationDate,
                DynamicTextUtilities.getCrpModelsAvailable(crp)
            );
        }

        return DynamicTextUtilities.updateDynamicText([], 'WACC Conclusions', 'WACC Conclusions', helpText);
    }
}
