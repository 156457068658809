import { Component } from '@angular/core';
import { ContextualNumber } from '@concurrency/core';
import * as moment from 'moment';
import { Observable, of } from 'rxjs';
import { Decile, DecileData } from 'src/app/_api/responses/decile.response';
import { InputType } from 'src/app/_api/responses/input.response';
import { CommonInputConfig } from 'src/app/_navigator/common/inputs/common-user-input.component';
import { DataStore } from 'src/app/_navigator/data/store/data.store';
import { ErrorService } from 'src/app/_navigator/error/error.service';
import { HelpText } from 'src/app/_navigator/help/help-text';
import { EditorComponent } from './editor.component';

// TODO: Rename to DecileSelectionEditorComponent
@Component({
    selector: 'size-premium-editor',
    templateUrl: './size-premium-editor.component.html'
})

export class SizePremiumEditorComponent extends EditorComponent {
    public selection!: ContextualNumber;
    public marketValueCommonEquity!: ContextualNumber;
    public sp!: DecileData;
    public deciles: Decile[] = [];
    public shouldTakeLatestSizePremium = false; // TODO: This is a really awful hack

    public marketValueConfig: CommonInputConfig = {
        name: 'Market Value of Common Equity ($USD in Millions)',
        minimumValue: 0,
        pattern: '(.*?)',
        debounce: { callback: () => this.internalUpdate() },
        help: HelpText.MarketValueOfEquity,
        error: this.errorService.sizeMeasure,
        autofocus: true
    };

    constructor(
        protected dataStore: DataStore,
        private errorService: ErrorService
    ) { super(dataStore); }

    private emitSelection(decile?: Decile): void {
        this.selection = decile == null
            ? new ContextualNumber(null, '')
            : new ContextualNumber(decile.CapM, decile.Description, decile.DataAsOf);
        this.modelChange.emit(this.selection);
    }

    private toDeciles(): void {
        if (this.isValidSplit(this.sp.PrimarySplit)) {
            this.deciles.push(this.sp.PrimarySplit);
        }

        if (this.isValidSplit(this.sp.SecondarySplit)) {
            this.deciles.push(this.sp.SecondarySplit);
        }
    }

    public initialize(): void {
        this.selection = this.settings.data.clone();
        const input = this.estimate.getInput(InputType.MarketValueOfCommonEquity);
        this.marketValueCommonEquity = new ContextualNumber(input.Value, null, null, 2);
        this.update();
    }

    // TODO: This is a bit of a hack to deal with the fact that update can be called externally,
    //   meaning we only automatically take the latest size prem when the update was internally triggered
    public internalUpdate(): void {
        this.shouldTakeLatestSizePremium = true;
        this.update();
    }

    public update(): Observable<void> {
        this.deciles = [];
        if (this.marketValueCommonEquity && this.marketValueCommonEquity.asNumber && this.isMarketCapValid()) {
            const request = this.dataStore.getSizePremium(this.estimate.ValuationDate, this.marketValueCommonEquity.asNumber);
            return request.once((selectedDecile) => {
                this.sp = selectedDecile;
                this.toDeciles();
                if (this.selection.hasValue === false || this.shouldTakeLatestSizePremium) {
                    this.emitSelection(this.sp.Target);
                }
                this.shouldTakeLatestSizePremium = false;
            });
        } else {
            this.sp = {} as any;
            this.emitSelection();
            // tslint-disable-next-line import/no-deprecated
            return of(void 0);
        }
    }

    public save(): void {
        this.estimate.setInput(InputType.MarketValueOfCommonEquity, this.marketValueCommonEquity.asNumberOrNull);
    }

    public cancel(): void {

    }

    public isSelectable(capm: number): boolean {
        return this.sp.Target.CapM === capm;
    }

    public isDecileWithinRange(smallestCap: number, largestCap: number): boolean {
        const isSameOrAfter = moment(this.estimate.ValuationDate).isSameOrAfter('2000-12-31', 'day');
        const isSameOrBefore = moment(this.estimate.ValuationDate).isSameOrBefore('2009-12-30', 'day');

        if (isSameOrAfter && isSameOrBefore) {
            const greaterThanSmallestCap = this.marketValueCommonEquity.asNumberOrNaN >= smallestCap;
            const lowerThanLargestCap = this.marketValueCommonEquity.asNumberOrNaN <= largestCap;

            return greaterThanSmallestCap && lowerThanLargestCap;
        }

        return true;

    }

    // TODO: This is a copy+paste from size measure editor
    public isMarketCapValid(): boolean {
        this.dataStore.triggerEditorValidity(true);
        const investedCapital = this.estimate.getInput(InputType.MarketValueOfInvestedCapital);

        if (investedCapital.Value == null || this.marketValueCommonEquity.asNumber == null) {
            return true;
        }

        if (this.marketValueCommonEquity.asNumber <= investedCapital.Value) {
            return true;
        }

        this.marketValueConfig.error = this.errorService.sizeMeasure;
        this.marketValueConfig.error = {
            text: `Market Value of Equity must be less than or equal Market Value of Invested Capital ($${investedCapital.Value} million).`,
            alwaysShow: true
        };

        this.dataStore.triggerEditorValidity(false);

        return false;
    }

    public select(decile: Decile): void {
        const selectedTarget = this.sp.Target && decile.CapM === this.sp.Target.CapM;
        const selectedGrouping = this.sp.Grouping && decile.CapM === this.sp.Grouping.CapM;

        const selectedPrimarySplit = this.sp.PrimarySplit
            && decile.CapM === this.sp.PrimarySplit.CapM
            && this.isDecileWithinRange(this.sp.PrimarySplit.SmallestCap, this.sp.PrimarySplit.LargestCap);
        const selectedSecondarySplit = this.sp.SecondarySplit
            && decile.CapM === this.sp.SecondarySplit.CapM
            && this.isDecileWithinRange(this.sp.SecondarySplit.SmallestCap, this.sp.SecondarySplit.LargestCap);

        if (selectedTarget || selectedGrouping || selectedPrimarySplit || selectedSecondarySplit) {
            this.emitSelection(decile);
        }
    }

    public isValidSplit(decile: Decile): boolean {
        if (decile == null || decile.CapM === 0 || decile.CapM == null) {
            return false;
        }

        return true;
    }
}
