import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Spinner, SubscriberEntity } from '@concurrency/angular';
import { ContextualNumber, ContextualString } from '@concurrency/core';
import { Select, Store } from '@ngxs/store';
import { combineLatest } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { map, takeUntil } from 'rxjs/operators';
import { IntlData } from 'src/app/_api/responses/intl-data.response';
import { SelectionType } from '../_api/enums/selection-type';
import { CountryRiskPremia } from '../_api/responses/country-risk-premia.response';
import { Country } from '../_api/responses/country.response';
import { CurrencyData } from '../_api/responses/currency-data.response';
import { EstimateSummary } from '../_api/responses/estimate-summary.response';
import { InputType } from '../_api/responses/input.response';
import { CommonInputConfig } from '../_navigator/common/inputs/common-user-input.component';
import { CountryListState } from '../_navigator/country-list-store/country-list-state';
import { CountryRiskPremiaListState } from '../_navigator/country-risk-premia-list-store/country-risk-premia-list-state';
import { CurrencyListState } from '../_navigator/currency-list-store/currency-list-state';
import { Estimate } from '../_navigator/data/model/estimate.model';
import { Suggestion } from '../_navigator/data/model/suggestion.model';
import { UpdateDynamicTextSummary } from '../_navigator/dynamic-text-store/dynamic-text-summary-action';
import { DynamicTextSummaryState } from '../_navigator/dynamic-text-store/dynamic-text-summary-state';
import { UpdateDbEstimate, UpdateLocalEstimate } from '../_navigator/estimate-store/estimate-actions';
import { EstimateState } from '../_navigator/estimate-store/estimate-state';
import { UpdateEstimateSummary } from '../_navigator/estimate-summary/estimate-summary-action';
import { EstimateSummaryState } from '../_navigator/estimate-summary/estimate-summary-state';
import { HelpText } from '../_navigator/help/help-text';
import { GetTaxRateList } from '../_navigator/tax-rate-list-store/tax-rate-list-actions';
import { TaxRateListState } from '../_navigator/tax-rate-list-store/tax-rate-list-state';
import { InputText } from './data/model/input-text';
import { IntlEstimateUtil } from './data/util/intl-estimate.util';
import { DynamicText } from './dynamic-text/model/dynamic-text';
import { DynamicTextSummary } from './dynamic-text/model/dynamic-text-summary';
import { DynamicTextBuilderUtil } from './dynamic-text/util/dynamic-text-builder.util';
import { RouteUtil } from './route/route.util';

@Component({
    selector: 'wacc-inputs',
    templateUrl: './wacc-inputs.component.html'
})
export class WaccInputsComponent extends SubscriberEntity implements OnInit {

    private estimate!: Estimate;
    private currentRoute!: string;
    private countryRiskPremia: CountryRiskPremia[] = [];
    private dynamicTextSummary!: DynamicTextSummary;
    public kdCountry: ContextualString = new ContextualString();
    public kd: ContextualNumber = new ContextualNumber();
    public debtToTotalCapitalRatio: ContextualNumber = new ContextualNumber();
    public taxRate: ContextualNumber = new ContextualNumber();
    public kdCountrySuggestions: Suggestion<string | null>[] = [];
    public isReady = false;
    public dynamicText: DynamicText[] = [];
    public SelectionType = SelectionType;
    public saveBtnText = 'Save & Continue';
    public isKdAvailable = false;
    public taxRateSuggestions: Suggestion<number>[] = [];

    @Select(EstimateState.get) public estimateSelector!: Observable<Estimate | undefined>;
    @Select(CurrencyListState.getAll) public currencySelector!: Observable<CurrencyData[] | undefined>;
    @Select(DynamicTextSummaryState.get) public dynamicTextSummarySelector!: Observable<DynamicTextSummary | undefined>;
    @Select(EstimateSummaryState.get) public estimateSummarySelector!: Observable<EstimateSummary | undefined>;
    @Select(TaxRateListState.get) public taxRateListSelector!: Observable<IntlData[] | undefined>;
    @Select(CountryRiskPremiaListState.get) public countryRiskPremiaSelector!: Observable<CountryRiskPremia[] | undefined>;

    public kdCountryConfig: CommonInputConfig = {
        name: InputText.KdCountryOfInput,
        help: HelpText.KdCountryOfInput,
        required: true,
        readonly: true
    };

    public kdConfig: CommonInputConfig = {
        name: InputText.Kd,
        help: HelpText.Kd,
        required: true,
        mask: `{}%`,
        minimumValue: 0,
        maximumValue: 100,
        pattern: '(.*?)'
    };

    public debtToTotalCapitalRatioConfig: CommonInputConfig = {
        name: InputText.DebtToTotalCapitalRatio,
        help: HelpText.DebtToTotalCapitalRatio,
        required: true,
        mask: `{}%`,
        minimumValue: 0,
        maximumValue: 100,
        pattern: '(.*?)'
    };

    public taxRateConfig: CommonInputConfig = {
        name: InputText.TaxRate,
        help: HelpText.TaxRate,
        required: true,
        mask: `{}%`,
        minimumValue: 0,
        maximumValue: 100,
        pattern: '(.*?)'
    };

    constructor(private router: Router, private store: Store, private spinner: Spinner) {
        super();
    }

    private initialize(
        investor: Country | undefined,
        investee: Country | undefined,
        keInputCountry: Country | undefined,
        cashFlowCountry: Country | undefined,
        taxRate: IntlData[] | undefined
    ): void {
        if (investor == null || investee == null || keInputCountry == null || cashFlowCountry == null || taxRate == null) {
            return;
        }

        this.isKdAvailable = true;

        const isKeInputCountryInvestee = keInputCountry.CountryId === investee.CountryId;
        const areCashFlowsInHomeCountry = cashFlowCountry.CountryId === investor.CountryId;

        const us = 'United States';
        const germany = 'Germany';
        const isInvestorUsOrGermany = investor.CountryName === us || investor.CountryName === germany;
        const isInvesteeUsOrGermany = investee.CountryName === us || investee.CountryName === germany;
        const isKeUsOrGermany = keInputCountry.CountryName === us || keInputCountry.CountryName === germany;
        const isKeProxyCountry = isInvestorUsOrGermany === false && isInvesteeUsOrGermany === false && isKeUsOrGermany;

        let kdCountrySelections: Country[] = [];

        if (isKeInputCountryInvestee || isKeProxyCountry && areCashFlowsInHomeCountry) {
            kdCountrySelections = [keInputCountry];
        } else {
            kdCountrySelections = [investee, keInputCountry];
        }

        this.kd = this.estimate.Scenarios[0].getSelectionAsContextualNumber(SelectionType.PreTaxCostOfDebt, InputType.None);
        this.debtToTotalCapitalRatio = this.estimate.Scenarios[0].getSelectionAsContextualNumber(
            SelectionType.DebtToTotalCapitalRatio,
            InputType.None
        );
        this.taxRate = this.estimate.Scenarios[0].getSelectionAsContextualNumber(SelectionType.TaxRate, InputType.None);
        this.taxRateConfig.name = this.taxRateConfig.name + ` (${investee.CountryName})`;

        this.kdCountrySuggestions = kdCountrySelections.map((x) => IntlEstimateUtil.countryAsSuggestion(x, true));

        const kdCurrencyCountryId = this.estimate.Scenarios[0].getSelection(SelectionType.CostOfDebtCurrency, InputType.None);
        const countryMatch = kdCountrySelections.find((x) => x.CountryId === kdCurrencyCountryId.Value);

        if (countryMatch) {
            const kdCountryName = IntlEstimateUtil.asCashFlowName(countryMatch);
            this.kdCountry = new ContextualString(kdCountryName, kdCountryName, countryMatch.CountryId);
        }

        this.taxRateSuggestions = taxRate.map((x) => IntlEstimateUtil.intlDataAsSuggestion(x.Value, x.Label, x.DataAsOf, x.Source));
        this.isReady = true;
    }

    public ngOnInit(): void {
        this.currentRoute = this.router.url;
        combineLatest([
            this.estimateSelector,
            this.estimateSummarySelector,
            this.dynamicTextSummarySelector,
            this.countryRiskPremiaSelector
        ]).pipe(
            takeUntil(this.destroyed),
            map((x) => ({
                estimate: x[0],
                summary: x[1],
                dynamicText: x[2],
                crp: x[3]
            }))
        ).onceDefined((data) => {
            if (data.estimate == null || data.summary == null || data.dynamicText == null || data.crp == null) {
                return;
            }

            this.estimate = data.estimate;
            this.countryRiskPremia = data.crp;
            const summary = DynamicTextBuilderUtil.getDynamicTextSummary(data.summary, data.dynamicText, data.crp);

            const request = combineLatest([
                this.store.dispatch(new UpdateDynamicTextSummary(summary)),
                this.store.dispatch(new GetTaxRateList(this.estimate.InvesteeCountryId, this.estimate.ValuationDate))
            ]);
            this.spinner.while(request);
            request.once(() => {
                const cashFlowCountry = this.estimate.getInput(InputType.CashFlows);
                const countryOfInputs = this.estimate.getInput(InputType.CountryOfInputs);

                if (cashFlowCountry.Value == null || countryOfInputs.Value == null) {
                    return;
                }

                combineLatest([
                    this.store.select(CountryListState.getById(this.estimate.HomeCountryId)),
                    this.store.select(CountryListState.getById(this.estimate.InvesteeCountryId)),
                    this.store.select(CountryListState.getById(countryOfInputs.Value)),
                    this.store.select(CountryListState.getById(cashFlowCountry.Value)),
                    this.taxRateListSelector
                ]).pipe(
                    takeUntil(this.destroyed),
                    map((x) => ({
                        investor: x[0],
                        investee: x[1],
                        keInputCountry: x[2],
                        cashFlow: x[3],
                        taxRate: x[4]
                    }))
                ).onceDefined((result) => {
                    this.initialize(result.investor, result.investee, result.keInputCountry, result.cashFlow, result.taxRate);
                });

                this.dynamicTextSummarySelector.onceDefined((dt) => {
                    if (dt.KdAndWaccInputsText == null) {
                        return;
                    }
                    this.dynamicText = dt.KdAndWaccInputsText;
                    this.dynamicTextSummary = dt;
                });
            });
        });
    }

    public updateKdCountry(): void {
        const cashFlowId = this.estimate.getInput(InputType.CashFlows);

        if (this.kdCountry == null || cashFlowId.Value == null) {
            return;
        }

        const kdCurrencyCountryId = new ContextualNumber(this.kdCountry.id);
        this.estimate.Scenarios[0].setSelection(kdCurrencyCountryId, SelectionType.CostOfDebtCurrency, InputType.None);
        this.store.dispatch(new UpdateLocalEstimate(this.estimate));
        this.store.dispatch(new UpdateEstimateSummary(this.estimate)).once(() => {
            this.estimateSummarySelector.onceDefined((summary) => {
                const updatedDt = DynamicTextBuilderUtil.getDynamicTextSummary(summary, this.dynamicTextSummary, this.countryRiskPremia);
                this.store.dispatch(new UpdateDynamicTextSummary(updatedDt)).once(() => {
                    this.dynamicTextSummarySelector.onceDefined((dt) => {
                        if (dt.KdAndWaccInputsText == null) {
                            return;
                        }
                        this.dynamicText = dt.KdAndWaccInputsText;
                        this.dynamicTextSummary = dt;
                    });
                });
            });
        });
    }

    public update(model: ContextualNumber, selection: SelectionType): void {
        if (selection === SelectionType.DebtToTotalCapitalRatio && model.asNumber) {
            const we = 100 - model.asNumber;
            this.estimate.Scenarios[0].setSelection(new ContextualNumber(we), SelectionType.WeightedEquity, InputType.None);
        }

        this.estimate.Scenarios[0].setSelection(model, selection, InputType.None);
        this.store.dispatch(new UpdateEstimateSummary(this.estimate));
        this.store.dispatch(new UpdateLocalEstimate(this.estimate));
    }

    public saveAndContinue(): void {
        const estimateRequest = this.store.dispatch(new UpdateDbEstimate());
        this.spinner.while(estimateRequest);
        estimateRequest.once(() => {
            RouteUtil.saveAndContinue(this.router.url, this.router);
        });
    }

    public previous(): void {
        RouteUtil.previous(this.currentRoute, this.router);
    }
}
