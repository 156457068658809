export const KEColumnDetails = [
    { width: 250 }, // A
    { width: 60 }, // B
    { width: 60 },  // C
    { width: 60 }, // D
    { width: 60 }, // E
    { width: 60 },  // F
    { width: 60 }, // G
    { width: 60 }, // H
    { width: 60 }, // I
    { width: 60 }, // J
    { width: 60 }, // K
    { width: 60 }, // L
    { width: 20 }, // M
    { width: 200 }, // N
    { width: 20 }, // O
    { width: 180 }, // P
    { width: 20 }, // Q
    { width: 200 }, // R
    { width: 20 }, // S
    { width: 70 } // T
];

export const CompaniesColumnDetails = [
    { width: 40 }, // A
    { width: 40 }, // B
    { width: 150 },  // C
    { width: 120 }, // D
    { width: 120 }, // E
    { width: 120 },  // F
    { width: 120 }, // G
    { width: 120 }, // H
    { width: 120 }, // I
    { width: 120 }, // J
    { width: 120 }, // K
    { width: 120 }, // L
    { width: 120 }, // M
    { width: 120 }, // N
    { width: 120 }, // O
    { width: 120 }, // P
    { width: 120 }, // Q
    { width: 120 }, // R
    { width: 120 }, // S
    { width: 120 }, // T
    { width: 120 }, // U
    { width: 120 }, // V
    { width: 120 }, // W
];

export const BetaColumnDetails = [
    { width: 60 }, // A
    { width: 200 }, // B
    { width: 40 },  // C
    { width: 110 }, // D
    { width: 110 }, // E
    { width: 110 },  // F
    { width: 110 }, // G
    { width: 110 }, // H
    { width: 110 }, // I
    { width: 110 }, // J
    { width: 110 }, // K
    { width: 110 }, // L
    { width: 110 } // M
];

export const krollblue = '#14487f';
export const krollshadow = '#4d4d4f';

export const headerFontSize = 20;
export const normalFontSize = 16;
export const smallFontSize = 12;

export const headerCellHeight = 30;
export const normalCellHeight = 24;
export const smallCellHeight = 20;

export const betaFontStyle = 'Arial';
export const betaHeaderFontSize = 18.5;
export const betaNormalFontSize = 14.5;
export const betaSmallFontSize = 9;
export const betaSmallCellHeight = 17;
export const betaBackGround = '#f6f6f6';
export const hyperLinkColor = '#1338BE';

// default row height is 12.5 points which is 17 pixels
// default column width is 8.43 points which is 64 pixels

export const betaCompanyCellHeight = 18.4;
export const betaTaxRateLabelHeight = 37;
export const betaCompanySalesCellHeight = 34.6;
