import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Observable } from 'rxjs/internal/Observable';
import { IntlTaxRateClient } from 'src/app/_api/clients/intlTaxRate.client';
import { IntlData } from 'src/app/_api/responses/intl-data.response';
import { GetTaxRateList } from './tax-rate-list-actions';
import { Injectable } from '@angular/core';


export type TaxRateListModel = IntlData[] | undefined;

@State<TaxRateListModel>({
    name: 'taxRateList',
    defaults: undefined
})

@Injectable()
export class TaxRateListState {

    @Selector()
    public static get(state: TaxRateListModel): IntlData[] | undefined {
        return state;
    }
    constructor(private taxRateClient: IntlTaxRateClient) { }

    @Action(GetTaxRateList)
    public getTaxRateList(context: StateContext<TaxRateListModel>, action: GetTaxRateList): Observable<void> {
        return this.taxRateClient.read(action.countryId, action.dataAsOf).once((response) => {
            context.setState(response);
        });
    }
}
