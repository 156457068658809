import { ApiClient } from '@concurrency/angular';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { Inflation } from '../responses/inflation.response';
import { Injectable } from '@angular/core';
import { Auth0ApiClient } from '../api.client';


export class InflationClient extends Auth0ApiClient {
    protected baseUrl = `${environment.urls.navigatorApi}/api`;

    public read(investorCountry: number, investeeCountry: number, dataAsOf: string): Observable<Inflation[]> {
        return this.get(`Inflation?investorCountry=${investorCountry}&investeeCountry=${investeeCountry}&dataAsOf=${dataAsOf}`);
    }
    public GetEight(investorCountry: number, investeeCountry: number, dataAsOf: string): Observable<Inflation[]> {
        return this.get(`Inflation/TrendsOverTime?investorCountry=${investorCountry}&investeeCountry=${investeeCountry}&dataAsOf=${dataAsOf}`);
    }
}
