import { Component, OnInit } from '@angular/core';
import { SubscriberEntity } from '@concurrency/angular';
import { Util } from '@concurrency/core';
import { zip } from 'rxjs';
import { InputType } from 'src/app/_api/responses/input.response';
import { Portfolio } from 'src/app/_api/responses/portfolio.response';
import { Estimate } from 'src/app/_navigator/data/model/estimate.model';
import { DataStore } from 'src/app/_navigator/data/store/data.store';
import { NumberFormatUtil } from 'src/app/_navigator/data/util/number-format.util';
import { HelpText } from 'src/app/_navigator/help/help-text';
import { HelpService } from 'src/app/_navigator/help/help.service';

interface ComparativeRisk {
    name: string;
    subjectLabel: string;
    industryLabel: string;
    inputType: InputType;
    industryValue: string;
    compareFunc: (left: number, right: number) => boolean;
    subjectValue?: string;
    isRisky?: boolean;
}

const comparativeRisks = [
    InputType.MarketValueOfCommonEquity,
    InputType.BookValueOfEquity,
    InputType.FiveYearAverageNetIncome,
    InputType.MarketValueOfInvestedCapital,
    InputType.TotalAssets,
    InputType.FiveYearAverageEbitda,
    InputType.NetSales,
    InputType.NumberOfEmployees
];

@Component({ templateUrl: 'comparative-risk.component.html' })
export class ComparativeRiskComponent extends SubscriberEntity implements OnInit {
    public risks: ComparativeRisk[] = [];
    public isShowRisks = false;

    constructor(
        private dataStore: DataStore,
        private helpService: HelpService
    ) { super(); }

    private setRisks(portfolios: Portfolio[], estimate: Estimate): void {
        this.risks = [
            {
                name: 'Operating Margin',
                subjectLabel: 'Operating Margin of Subject Company',
                industryLabel: 'Average Operating Margin of Companies Across Indicated Guidelines Size Portfolios',
                inputType: InputType.AverageOperatingMargin,
                industryValue: this.getAverageRisk(portfolios.map((x) => x.AverageOperatingMargin)),
                compareFunc: (subjectValue, industryValue) => subjectValue < industryValue
            }, {
                name: 'Coefficient of Variation of Operating Margin',
                subjectLabel: 'Coefficient of Variation of Operating Margin of Subject Company',
                industryLabel: 'Average Coefficient of Variation of Operating Margin of Subject Company of ' +
                    'Companies Across Indicated Guidelines Size Portfolios',
                inputType: InputType.CoefficientOfVariationOfOperatingMargin,
                industryValue: this.getAverageRisk(portfolios.map((x) => x.AverageCovom)),
                compareFunc: (subjectValue, industryValue) => subjectValue > industryValue
            }, {
                name: 'Coefficient of Variation of Return on Equity',
                subjectLabel: 'Coefficient of Variation of Return on Equity of Subject Company',
                industryLabel: 'Average Coefficient of Variation of Return on Equity of ' +
                    'Companies Across Indicated Guidelines Size Portfolios',
                inputType: InputType.CoefficientOfVariationOfReturnOnEquity,
                industryValue: this.getAverageRisk(portfolios.map((x) => x.AverageCovroe)),
                compareFunc: (subjectValue, industryValue) => subjectValue > industryValue
            }
        ];

        for (const risk of this.risks) {
            const subjectValue = estimate.getInput(risk.inputType).Value || 0;
            risk.subjectValue = estimate.getInput(risk.inputType).Value ?
                NumberFormatUtil.numberWithCommas(Util.round(subjectValue, 1), 2, true) : 'N/A';
            const industryValue = parseFloat(risk.industryValue);
            if (subjectValue !== industryValue) {
                risk.isRisky = risk.compareFunc(subjectValue, industryValue);
            }
        }
    }

    private getAverageRisk(risks: number[]): string {
        if (risks.length === 0) {
            return '';
        }

        return NumberFormatUtil.numberWithCommas(Util.average(risks, 4) * 100, 2);
    }
    private getAveragePortpolios(portfolios: Portfolio[], estimate: Estimate): Portfolio[] | [] {
        const portpoliosData: Portfolio[] = [];
        comparativeRisks.forEach((z) => {
            const input = estimate.Inputs.find((x) => x.InputType === z);
            if (estimate && input && input.Value) {
                const value = input.Value;
                const portfolio = portfolios.filter((x) =>
                    x.InputType === z &&
                    x.Lower < value && x.Upper >= value
                )[0];
                if (portfolio) {
                    portpoliosData.push(portfolio);
                }
            }
        });
        return portpoliosData;
    }
    public ngOnInit(): void {
        this.helpService.open('comparativeRiskStudy', HelpText.ComparativeRiskStudy);
        const portfolios = this.dataStore.portfolios.onceDefined((p) => p);
        const estimates = this.dataStore.estimate.onceDefined((e) => e);
        zip(portfolios, estimates).once((data) => {
            const portfoliosdata: Portfolio[] | [] = this.getAveragePortpolios(data[0], data[1]);
            this.isShowRisks = portfoliosdata.length > 0 ? true : false;
            this.setRisks(portfoliosdata, data[1]);
        });
    }
}
