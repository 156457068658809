import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Spinner, SubscriberEntity } from '@concurrency/angular';
import { ContextualNumber } from '@concurrency/core';
import { Select, Store } from '@ngxs/store';
import { combineLatest } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { map, takeUntil } from 'rxjs/operators';
import { SelectionType } from '../_api/enums/selection-type';
import { CountryRiskPremia } from '../_api/responses/country-risk-premia.response';
import { Country } from '../_api/responses/country.response';
import { EstimateSummary } from '../_api/responses/estimate-summary.response';
import { Inflation } from '../_api/responses/inflation.response';
import { InputType } from '../_api/responses/input.response';
import { SubscriptionType, UserResponse } from '../_api/responses/user.response';
import { CommonInputConfig } from '../_navigator/common/inputs/common-user-input.component';
import { CountryRiskPremiaListState } from '../_navigator/country-risk-premia-list-store/country-risk-premia-list-state';
import { Estimate } from '../_navigator/data/model/estimate.model';
import { Suggestion } from '../_navigator/data/model/suggestion.model';
import { UpdateDynamicTextSummary } from '../_navigator/dynamic-text-store/dynamic-text-summary-action';
import { DynamicTextSummaryState } from '../_navigator/dynamic-text-store/dynamic-text-summary-state';
import { UpdateDbEstimate, UpdateLocalEstimate } from '../_navigator/estimate-store/estimate-actions';
import { EstimateState } from '../_navigator/estimate-store/estimate-state';
import { UpdateEstimateSummary } from '../_navigator/estimate-summary/estimate-summary-action';
import { EstimateSummaryState } from '../_navigator/estimate-summary/estimate-summary-state';
import { HelpText } from '../_navigator/help/help-text';
import { GetInflationList, GetInflationListTrendsovertime } from '../_navigator/inflation-list-store/inflation-list-actions';
import { InflationListState } from '../_navigator/inflation-list-store/inflation-list-state';
import { PrimeManager } from '../_navigator/modal/pmodal.manager';
import { UserStore } from '../_navigator/user/store/user.store';
import { TrendsOverTimeFishereffectComponent } from '../home/tear-sheet/modals/trends-over-time-fishereffect/trends-over-time-fishereffect.component';
import { InputText } from './data/model/input-text';
import { IntlEstimateUtil } from './data/util/intl-estimate.util';
import { DynamicText } from './dynamic-text/model/dynamic-text';
import { DynamicTextSummary } from './dynamic-text/model/dynamic-text-summary';
import { DynamicTextBuilderUtil } from './dynamic-text/util/dynamic-text-builder.util';
import { RouteUtil } from './route/route.util';

@Component({
    selector: 'fisher-effect',
    templateUrl: './fisher-effect.component.html'
})
export class FisherEffectComponent extends SubscriberEntity implements OnInit {

    private estimate!: Estimate;
    private currentRoute!: string;
    public inflationInvestor = new ContextualNumber();
    public inflationInvestee = new ContextualNumber();
    public inputCountrytot!: number;
    public cashFlowCountrytot!: number;
    public inputcountryname!: string;
    public cashflowcountryname!: string;
    public inputcountry!: Inflation[];
    public cashflow!: Inflation[];
    public dynamicText: DynamicText[] = [];
    public showFisherEffect = false;
    public saveBtnText = 'Save & Continue';
    public isFisherEffectAvailable = false;
    public inflationInvestorSuggestions: Suggestion<number>[] = [];
    public inflationInvesteeSuggestions: Suggestion<number>[] = [];
    public SelectionType = SelectionType;
    public userdata!: UserResponse;
    @Select(EstimateState.get) public estimateSelector!: Observable<Estimate | undefined>;
    @Select(EstimateSummaryState.get) public estimateSummarySelector!: Observable<EstimateSummary | undefined>;
    @Select(DynamicTextSummaryState.get) public dynamicTextSummarySelector!: Observable<DynamicTextSummary | undefined>;
    @Select(InflationListState.get) public inflationSelector!: Observable<Inflation[] | undefined>;
    @Select(CountryRiskPremiaListState.get) public countryRiskPremiaSelector!: Observable<CountryRiskPremia[] | undefined>;

    public inflationInvestorConfig: CommonInputConfig = {
        name: InputText.InflationInvestor,
        help: HelpText.InflationInvestor,
        required: true,
        mask: `{}%`,
        minimumValue: 0,
        maximumValue: 100,
        pattern: '(.*?)'
    };

    public inflationInvesteeConfig: CommonInputConfig = {
        name: InputText.InflationInvestee,
        help: HelpText.InflationInvestee,
        required: true,
        mask: `{}%`,
        minimumValue: 0,
        maximumValue: 100,
        pattern: '(.*?)'
    };

    constructor(private primeManager: PrimeManager, private router: Router, private store: Store, private spinner: Spinner, private userStore: UserStore) {
        super();
    }

    private initialize(keInputCountry: Country, cashFlowCountry: Country, kdInputCountry: Country | undefined): void {
        const hasKeFisherEffect = cashFlowCountry.Currency.CurrencyId !== keInputCountry.Currency.CurrencyId;

        let hasKdFisherEffect = false;
        if (kdInputCountry) {
            hasKdFisherEffect = cashFlowCountry.Currency.CurrencyId !== kdInputCountry.Currency.CurrencyId;
        }

        this.showFisherEffect = hasKeFisherEffect || hasKdFisherEffect;

        if (this.showFisherEffect === false) {
            return;
        }

        const cashFlowCountryName = cashFlowCountry.CountryName;
        const scenario = this.estimate.Scenarios[0];
        const inflationInvestor = scenario.getSelection(SelectionType.InflationInvestor, InputType.None);
        const inflationInvestee = scenario.getSelection(SelectionType.InflationInvestee, InputType.None);

        this.inflationInvestor = inflationInvestor.Value ? new ContextualNumber(inflationInvestor.Value) : new ContextualNumber();
        this.inflationInvestee = inflationInvestee.Value ? new ContextualNumber(inflationInvestee.Value) : new ContextualNumber();

        let inputCountry: Country;
        if (kdInputCountry && hasKdFisherEffect) {
            inputCountry = kdInputCountry;
        } else {
            inputCountry = keInputCountry;
        }

        this.inflationInvestorConfig.name = `<div class="opentrends">Expected Long-term Inflation<br/><b class="fisher-effect">${inputCountry.CountryName}</b>`;
        this.inflationInvesteeConfig.name = `<div class="opentrends">Expected Long-term Inflation<br/><b class="fisher-effect">${cashFlowCountryName}</b>`;
        this.inputcountryname = inputCountry.CountryName;
        this.cashflowcountryname = cashFlowCountryName;
        this.inputCountrytot = inputCountry.CountryId;
        this.cashFlowCountrytot = cashFlowCountry.CountryId;
        const request = this.store.dispatch(
            new GetInflationList(inputCountry.CountryId, cashFlowCountry.CountryId, this.estimate.ValuationDate)
        );
        this.spinner.while(request);
        request.once(() => {
            this.inflationSelector.onceDefined((inflation) => {
                const suggestedInflationInvestor = inflation.find((x) => x.CountryId === inputCountry.CountryId);
                const suggestedInflationInvestee = inflation.find((x) => x.CountryId === cashFlowCountry.CountryId);

                if (suggestedInflationInvestor) {
                    const suggestion = IntlEstimateUtil.intlDataAsSuggestion(
                        suggestedInflationInvestor.InflationRate,
                        suggestedInflationInvestor.Label,
                        suggestedInflationInvestor.DataAsOf,
                        suggestedInflationInvestor.Source
                    );
                    this.inflationInvestorSuggestions = [suggestion];
                }

                if (suggestedInflationInvestee) {
                    const suggestion = IntlEstimateUtil.intlDataAsSuggestion(
                        suggestedInflationInvestee.InflationRate,
                        suggestedInflationInvestee.Label,
                        suggestedInflationInvestee.DataAsOf,
                        suggestedInflationInvestee.Source
                    );
                    this.inflationInvesteeSuggestions = [suggestion];
                }
            });
        });
    }

    public ngOnInit(): void {
        this.userStore.user.onceDefined((user) => {
            this.userdata = user;
        });
        this.currentRoute = this.router.url;
        combineLatest([
            this.estimateSummarySelector,
            this.dynamicTextSummarySelector,
            this.countryRiskPremiaSelector,
            this.estimateSelector
        ]).pipe(
            takeUntil(this.destroyed),
            map((x) => ({
                summary: x[0],
                dynamicText: x[1],
                crp: x[2],
                estimate: x[3]
            }))
        ).onceDefined((data) => {
            if (data.summary == null
                || data.dynamicText == null
                || data.crp == null
                || data.estimate == null
                || data.summary.InvestorCountryId == null
                || data.summary.InvesteeCountryId == null
                || data.summary.CashFlowCountry == null
                || data.summary.CountryOfInputs == null
            ) {
                return;
            }

            this.estimate = data.estimate;
            this.isFisherEffectAvailable = true;
            const dynamicTextSummary = DynamicTextBuilderUtil.getDynamicTextSummary(data.summary, data.dynamicText, data.crp);
            this.store.dispatch(new UpdateDynamicTextSummary(dynamicTextSummary)).once(() => {
                this.dynamicTextSummarySelector.onceDefined((dt) => {
                    if (dt.FisherEffectText == null) {
                        return;
                    }
                    this.dynamicText = dt.FisherEffectText;
                });
            });
            this.initialize(data.summary.CountryOfInputs, data.summary.CashFlowCountry, data.summary.CostOfDebtInputsCountry);
        });
    }

    public saveAndContinue(): void {
        const estimateRequest = this.store.dispatch(new UpdateDbEstimate());
        this.spinner.while(estimateRequest);
        estimateRequest.once(() => {
            RouteUtil.saveAndContinue(this.router.url, this.router);
        });
    }

    public previous(): void {
        RouteUtil.previous(this.currentRoute, this.router);
    }

    public update(model: ContextualNumber, selection: SelectionType): void {
        this.estimate.Scenarios[0].setSelection(model, selection, InputType.None);
        this.store.dispatch(new UpdateEstimateSummary(this.estimate));
        this.store.dispatch(new UpdateLocalEstimate(this.estimate));
    }
    public openTrendsOverTimeModal(event: string): void {
        if (event === 'opentrends') {
            if (this.userdata.Subscriptions.find((x) => x.Type === SubscriptionType.InePro || x.Type === SubscriptionType.IneEnterprise)) {
                const request = this.store.dispatch(
                    new GetInflationListTrendsovertime(this.inputCountrytot, this.cashFlowCountrytot, this.estimate.ValuationDate)
                );
                this.spinner.while(request);
                request.once(() => {
                    this.inflationSelector.onceDefined((inflation) => {
                        this.primeManager.openDialog(TrendsOverTimeFishereffectComponent, {
                            inputcountry: inflation,
                            inputcountryname: this.inputcountryname,
                            cashflowcountryname: this.cashflowcountryname,
                            valuationdate: this.estimate.ValuationDate
                        });

                    });
                });

            }
        }
    }
}
