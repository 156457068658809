import { Component } from '@angular/core';
import { SelectionType } from 'src/app/_api/enums/selection-type';
import { InputType } from 'src/app/_api/responses/input.response';
import { CommonInputConfig } from 'src/app/_navigator/common/inputs/common-user-input.component';
import { Suggestion } from 'src/app/_navigator/data/model/suggestion.model';
import { DataStore } from 'src/app/_navigator/data/store/data.store';
import { ErrorService } from 'src/app/_navigator/error/error.service';
import { HelpText } from 'src/app/_navigator/help/help-text';
import { SimpleEditorComponent } from './editor.component';

@Component({ templateUrl: './risk-free-rate-editor.component.html' })
export class RiskFreeRateEditorComponent extends SimpleEditorComponent {
    public customLabel = 'Custom Rate';
    public suggestedRates: Suggestion<number>[] = [];
    public inputConfig: CommonInputConfig = {
        name: 'Risk-Free Rate (%)',
        help: HelpText.RiskFreeRateDetailed,
        error: this.errorService.riskFreeRate,
        minimumValue: -5,
        maximumValue: 10,
        pattern: '(^-?[0-4][.]?[0-9]?[0-9]?|10[.]?0?0?|-5[.]?0?0?|[5-9][.]?[0-9]?[0-9]?)',
        autofocus: true
    };

    constructor(protected dataStore: DataStore, private errorService: ErrorService) {
        super(dataStore);
    }

    // Override
    public save(): void {
        const suggestedErps = this.settings.scenario.getSuggestions(this.estimate.Suggestions, SelectionType.EquityRiskPremium);
        const equityRiskPremium = this.settings.scenario.getSelection(SelectionType.EquityRiskPremium, InputType.None);

        const isErpValid = suggestedErps
            .filter((x) => x.disabledText == null)
            .find((x) => x.source === equityRiskPremium.Context) ? true : false;

        if (isErpValid === false) {
            equityRiskPremium.Context = undefined;
            equityRiskPremium.Value = undefined;
        }
    }
}
