import { betaBackGround } from 'src/app/estimate/exports/summary-export/util/summary-helpers';
import { UnleveringInputs } from '../beta.types';
import { valueConversion } from './levered-betas';

export function getBookValue(unlevering: UnleveringInputs[]): any[] {
    const bookVakue: any[] = [];
    if (unlevering.length > 0) {
        bookVakue.push(
            { value: '' },
            {
                value: 'Book Value of Debt'
            },
            { value: '' }
        );
        unlevering.forEach((betas: UnleveringInputs) => {
            bookVakue.push(
                {
                    background: betaBackGround,
                    textAlign: 'right',
                    format: '#,##0.00',
                    value: valueConversion(betas.TotalDebt)
                }
            );
        });
    }
    return bookVakue;
}

export function getMarketValue(unlevering: UnleveringInputs[]): any[] {
    const marketValue: any[] = [];
    if (unlevering.length > 0) {
        marketValue.push(
            { value: '' },
            {
                value: 'Market Value of Equity'
            },
            { value: '' }
        );
        unlevering.forEach((betas: UnleveringInputs) => {
            marketValue.push(
                {
                    background: betaBackGround,
                    textAlign: 'right',
                    format: '#,##0.00',
                    value: betas.MarketCap != null ? parseFloat(betas.MarketCap) : '-'
                }
            );
        });
    }
    return marketValue;
}

export function getDebtEquity(unlevering: UnleveringInputs[]): any[] {
    const debtEquity: any[] = [];
    if (unlevering.length > 0) {
        debtEquity.push(
            { value: '' },
            {
                value: 'Debt/Total Capital'
            },
            { value: '' }
        );
        unlevering.forEach((betas: UnleveringInputs) => {
            debtEquity.push(
                {
                    background: betaBackGround,
                    textAlign: 'right',
                    format: '0.0%',
                    value: valueConversion(betas.Debt_TotalCapital / 100)
                }
            );
        });
    }
    return debtEquity;
}

export function getTaxRate(unlevering: UnleveringInputs[]): any[] {
    const taxRate: any[] = [];
    if (unlevering.length > 0) {
        taxRate.push(
            { value: '' },
            {
                value: 'Tax Rate (t)'
            },
            { value: '' }
        );
        unlevering.forEach((betas: UnleveringInputs) => {
            taxRate.push(
                {
                    background: betaBackGround,
                    textAlign: 'right',
                    format: '0.00%',
                    value: valueConversion(betas.TaxRate / 100)
                }
            );
        });
    }
    return taxRate;
}

export function getTaxRateLabel(unlevering: UnleveringInputs[]): any[] {
    const label: any[] = [];
    if (unlevering.length > 0) {
        label.push(
            { value: '' },
            { value: '' },
            { value: '' }
        );
        unlevering.forEach((betas: UnleveringInputs) => {
            label.push(
                {
                    background: betaBackGround,
                    value: betas.Label,
                    italic: true,
                    wrap: true
                }
            );
        });
    }
    return label;
}
