import { Injectable } from '@angular/core';
import { ApiClient } from '@concurrency/angular';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Country } from '../responses/country.response';
import { Auth0ApiClient } from '../api.client';


@Injectable()
export class CountryClient extends Auth0ApiClient {
    protected baseUrl = `${environment.urls.navigatorApi}/api`;

    public read(): Observable<Country[]> {
        return this.get(`Country`);
    }

    public investors(): Observable<Country[]> {
        return this.get(`Country/Investors`);
    }

    public investee(dataAsOf: string, investorCountryId: number): Observable<Country[]> {
        return this.get(`Country/Investee?dataAsOf=${dataAsOf}&investorCountryId=${investorCountryId}`);
    }
}
