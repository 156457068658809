import * as moment from 'moment';

import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ModalManager, Spinner, SubscriberEntity } from '@concurrency/angular';
import { Select, Store } from '@ngxs/store';
import { combineLatest, Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { EstimateType } from 'src/app/_api/enums/estimate-type';
import { MinimumDate } from 'src/app/_api/responses/minimum-date.response';
import { Analysis } from 'src/app/_navigator/data/model/analysis.model';
import { DataStore } from 'src/app/_navigator/data/store/data.store';
import { CreateBetaEstimate } from 'src/app/_navigator/estimates-list-store/beta-estimates-list-actions';
import { CreateEstimate } from 'src/app/_navigator/estimates-list-store/estimates-list-actions';
import { ProductType } from 'src/app/_navigator/user/enum/product-type';
import { SubscriptionLevel } from 'src/app/_navigator/user/enum/subscription-level';
import { UserStore } from 'src/app/_navigator/user/store/user.store';
import { UserUtil } from 'src/app/_navigator/user/util/user.util';
import { ValuesState } from 'src/app/_navigator/values-store/values-state';
import { NewBetaEstimateModalComponent } from '../_modals/new-beta-estimate-modal.component';
import { NewEstimateModalComponent } from '../_modals/new-estimate-modal.component';
import { DashboardModule } from './dashboard-module.model';
import { ModuleType } from './module-type';

@Component({
    selector: 'dashboard-module',
    templateUrl: './dashboard-module.component.html'
})
export class DashboardModuleComponent extends SubscriberEntity {
    @Input() public dashboardModule!: DashboardModule;
    @Select(ValuesState.get) public valuesSelector!: Observable<MinimumDate | undefined>;
    public usCostOfCapital = ModuleType.UsCostOfCapital;
    public internationalCostOfCapital = ModuleType.InternationalCostOfCapital;

    constructor(
        private modalManager: ModalManager,
        private store: Store,
        private router: Router,
        private spinner: Spinner,
        private dataStore: DataStore,
        private userStore: UserStore
    ) {
        super();
    }


    private openNewEstimateModal(type: EstimateType, route: string): void {
        const modal = this.modalManager.open<string, NewEstimateModalComponent>(NewEstimateModalComponent);
        modal.once((name) => {
            const request = this.store.dispatch(new CreateEstimate(name, type));
            request.once(() => {
                this.dataStore.setIsIndustryAnalysis(false);
                this.router.navigate([route]);
            });
            this.spinner.while(request);
        });
    }

    private openNewBetaEstimateModal(type: EstimateType, route: string): void {
        const modal = this.modalManager.open<string, NewBetaEstimateModalComponent>(NewBetaEstimateModalComponent);
        modal.once((name) => {
            const request = this.store.dispatch(new CreateBetaEstimate(name, type));
            request.once(() => {
                this.router.navigate([route]);
            });
            this.spinner.while(request);
        });
    }

    private startIndustryAnalysis(isInternationalIndustryBenchmarking: boolean = false): void {
        combineLatest([
            this.userStore.user,
            this.valuesSelector
        ]).pipe(
            takeUntil(this.destroyed),
            map((x) => ({
                user: x[0],
                values: x[1]
            }))
        ).onceDefined((data) => {
            if (data.user == null || data.values == null) {
                return;
            }

            let defaultValuationDate = moment().toISOString();

            if (data.user.subscriptionDetails.find((x) => x.productType === ProductType.Usi && x.level === SubscriptionLevel.Trial)) {
                const minimumValuationDate = UserUtil.getSubscriptionMinimumValuationDate(
                    ProductType.Usi,
                    SubscriptionLevel.Trial,
                    data.values.UsIndustries,
                    data.user.subscriptionDetails
                );
                defaultValuationDate = minimumValuationDate.toISOString();
            }

            this.dataStore.setIsIndustryAnalysis(true);
            this.dataStore.setAnalysis(new Analysis(defaultValuationDate, [], true));
            if (isInternationalIndustryBenchmarking) {
                this.router.navigate(['intlindustry-benchmarking']);
            } else {
                this.router.navigate(['industry-benchmarking']);
            }
        });
    }

    public launchModule(): void {
        if (this.dashboardModule.isDisabled) {
            window.open(this.dashboardModule.storeUrl, '_blank');
        } else {
            if (this.dashboardModule.type === ModuleType.UsCostOfCapital) {
                this.openNewEstimateModal(EstimateType.USEstimate, 'estimate');
            } else if (this.dashboardModule.type === ModuleType.UsIndustryBenchmarking) {
                this.startIndustryAnalysis();
            } else if (this.dashboardModule.type === ModuleType.InternationalCostOfCapital) {
                this.openNewEstimateModal(EstimateType.InternationalEstimate, 'international-estimate');
            } else if (this.dashboardModule.type === ModuleType.InternationalIndustryBenchmarking) {
                this.startIndustryAnalysis(true);
            } else if (this.dashboardModule.type === ModuleType.Beta) {
                this.openNewBetaEstimateModal(EstimateType.BetaEstimate, 'beta');
            }
        }
    }

    public reviewEstimates(isDisabled: boolean): void {
        if (isDisabled) {
            return;
        }

        if (this.dashboardModule.type === ModuleType.UsCostOfCapital) {
            this.router.navigate(['estimates/us-estimates']);
        } else if (this.dashboardModule.type === ModuleType.InternationalCostOfCapital) {
            this.router.navigate(['estimates/intl-estimates']);
        }
    }

}

