import { Component, Input } from '@angular/core';
import { Regionrisk } from 'src/app/benchmarking/data/trends-over-time-table-data';

@Component({
    selector: 'trends-over-time-country-risk-table',
    templateUrl: './trends-over-time-country-risk-table.component.html',
    styleUrls: ['./trends-over-time-country-risk-table.component.scss']
})
export class TrendsOverTimeCountryRiskTableComponent {
    @Input() public tableData!: Regionrisk;
    @Input() public creditrating!: boolean;
    @Input() public yeild!: boolean;
    @Input() public volatility!: boolean;
    @Input() public Creditratingline!: boolean;
    @Input() public euroregionline!: boolean;
    @Input() public msciline!: boolean;
    @Input() public sprline!: boolean;
    @Input() public countryriskarray: any[] = [];
    @Input() public CcrAvgarrayeur: any[] = [];
    @Input() public CcrAvgarraymsci: any[] = [];
    @Input() public CcrAvgarrayspc: any[] = [];
    @Input() public dateArray: string[] = [];
    @Input() public YieldSpreadeur: any[] = [];
    @Input() public YieldSpreadmsci: any[] = [];
    @Input() public YieldSpreadspc: any[] = [];
    @Input() public VolatilityAvgeur: any[] = [];
    @Input() public VolatilityAvgmsci: any[] = [];
    @Input() public VolatilityAvgspc: any[] = [];
    @Input() public YieldSpread: any[] = [];
    @Input() public volatality: any[] = [];
    @Input() public title = 'Country Risk';

    constructor() { }

}
