import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SubscriberEntity } from '@concurrency/angular';
import { Select, Store } from '@ngxs/store';
import { combineLatest } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { map, takeUntil } from 'rxjs/operators';
import { SelectionType } from 'src/app/_api/enums/selection-type';
import { CountryCreditRating } from 'src/app/_api/responses/country-credit-rating.response';
import { Country } from 'src/app/_api/responses/country.response';
import { InputType } from 'src/app/_api/responses/input.response';
import { CcrState } from 'src/app/_navigator/ccr-store/ccr-state';
import { CountryListState } from 'src/app/_navigator/country-list-store/country-list-state';
import { Estimate } from 'src/app/_navigator/data/model/estimate.model';
import { DynamicTextSummaryState } from 'src/app/_navigator/dynamic-text-store/dynamic-text-summary-state';
import { EstimateState } from 'src/app/_navigator/estimate-store/estimate-state';
import { DynamicTextSummary } from 'src/app/international-estimate/dynamic-text/model/dynamic-text-summary';
import { SummaryText } from '../data/model/summary-text';
import { DynamicText } from '../dynamic-text/model/dynamic-text';
import { EquationGroup } from '../equation/equation-group.model';
import { EquationGroupUtil } from '../equation/equation-group.util';

@Component({
    selector: 'wacc-summary-capital',
    templateUrl: './wacc-summary-capital.component.html'
})
export class WaccSummaryCapitalComponent extends SubscriberEntity implements OnInit {
    public investeeCountry = '';
    public equations: EquationGroup[] = [];
    public dynamicText: DynamicText[] = [];
    public isKeKdAvailable = false;
    public isInflationAvailable = false;
    public SummaryText = SummaryText;

    @Select(EstimateState.get) public estimateSelector!: Observable<Estimate | undefined>;
    @Select(DynamicTextSummaryState.get) public dynamicTextSummarySelector!: Observable<DynamicTextSummary | undefined>;
    @Select(CcrState.get) public ccrSelector!: Observable<CountryCreditRating | undefined>;

    constructor(private router: Router, private store: Store) {
        super();
    }

    private initialize(
        estimate: Estimate,
        cashFlow: Country | undefined,
        keInputCountry: Country | undefined,
        kdInputCountry: Country | undefined,
        ccr: CountryCreditRating | undefined
    ): void {
        if (cashFlow == null || keInputCountry == null || kdInputCountry == null || ccr == null) {
            return;
        }

        this.isKeKdAvailable = true;

        const scenario = estimate.Scenarios[0];
        const inflationInvestor = scenario.getSelection(SelectionType.InflationInvestor, InputType.None);
        const inflationInvestee = scenario.getSelection(SelectionType.InflationInvestee, InputType.None);
        const useKeFisherEffect = cashFlow.Currency.CurrencyId !== keInputCountry.Currency.CurrencyId;
        const useKdFisherEffect = cashFlow.Currency.CurrencyId !== kdInputCountry.Currency.CurrencyId;

        if ((useKeFisherEffect || useKdFisherEffect) && (!inflationInvestor.Value || !inflationInvestee.Value)) {
            return;
        }

        this.isInflationAvailable = true;
        this.equations = EquationGroupUtil.getWaccCapitalGroup(estimate, ccr, cashFlow, keInputCountry, kdInputCountry);

        this.store.select(CountryListState.getById(estimate.InvesteeCountryId)).onceDefined((investeeCountry) => {
            this.investeeCountry = SummaryText.investeeCountry(investeeCountry.CountryName);
        });
    }

    public ngOnInit(): void {
        this.estimateSelector.onceDefined((estimate) => {
            const scenario = estimate.Scenarios[0];
            const cashFlowCountry = estimate.getInput(InputType.CashFlows);
            const keCountryOfInputs = estimate.getInput(InputType.CountryOfInputs);
            const rf = scenario.getSelection(SelectionType.IntlRiskFreeRate, InputType.None);
            const beta = scenario.getSelection(SelectionType.IntlBeta, InputType.None);
            const erp = scenario.getSelection(SelectionType.IntlEquityRiskPremium, InputType.None);
            const kdCountryOfInputs = scenario.getSelection(SelectionType.CostOfDebtCurrency, InputType.None);
            const preTaxKd = scenario.getSelection(SelectionType.PreTaxCostOfDebt, InputType.None);
            const debtToTotalCapitalRatio = scenario.getSelection(SelectionType.DebtToTotalCapitalRatio, InputType.None);
            const taxRate = scenario.getSelection(SelectionType.TaxRate, InputType.None);

            if (cashFlowCountry.Value == null
                || keCountryOfInputs.Value == null
                || rf.Value == null
                || beta.Value == null
                || erp.Value == null
                || kdCountryOfInputs.Value == null
                || preTaxKd.Value == null
                || debtToTotalCapitalRatio.Value == null
                || taxRate.Value == null) {
                return;
            }

            combineLatest([
                this.store.select(CountryListState.getById(cashFlowCountry.Value)),
                this.store.select(CountryListState.getById(keCountryOfInputs.Value)),
                this.store.select(CountryListState.getById(kdCountryOfInputs.Value)),
                this.ccrSelector
            ]).pipe(
                takeUntil(this.destroyed),
                map((x) => ({
                    cashFlow: x[0],
                    keInputCountry: x[1],
                    kdInputCountry: x[2],
                    ccr: x[3]
                }))
            ).onceDefined((data) => {
                this.initialize(estimate, data.cashFlow, data.keInputCountry, data.kdInputCountry, data.ccr);
            });
        });

        this.dynamicTextSummarySelector.onceDefined((dt) => {
            if (dt.WaccSummaryCapitalText == null) {
                return;
            }
            this.dynamicText = dt.WaccSummaryCapitalText;
        });
    }

    public edit(): void {
        this.router.navigate(['international-estimate/wacc-inputs']);
    }
}
