// Angular Modules
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ConcurrencyModule } from '@concurrency/angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ExcelExportModule } from '@progress/kendo-angular-excel-export';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { ChartModule } from 'angular-highcharts';
import { NavCommonModule } from '../_navigator/common/common.module';
import { NavigatorModule } from '../_navigator/navigator.module';
import { NzTabsModule } from 'ng-zorro-antd/tabs';

// TODO: Should these actually all be in here? e.g. PDF or Excel
export const CommonModules = [
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    RouterModule,
    HttpClientModule,
    NgbModule,
    ConcurrencyModule,
    PDFExportModule,
    ExcelExportModule,
    ChartModule,
    NzTabsModule,
    NavigatorModule,
    NavCommonModule // TODO: Move all this code into Concurrency or Navigator
];
