import { Util } from '@concurrency/core';
import { Chart } from 'angular-highcharts';
import { IndustryMetric } from 'src/app/_api/responses/industry-metric.response';
import { Metric } from '../enums/metric';
import { TearSheetUtil } from './tear-sheet.util';

export class WaccChartUtil {
    public static getWaccChartMetric(industryMetric: IndustryMetric[]): Chart | null {
        const waccCapm = TearSheetUtil.getMetric(Metric.WaccCapm, industryMetric);
        const waccCrspCapm = TearSheetUtil.getMetric(Metric.WaccCrspCapm, industryMetric);
        const waccCrspBuildUp = TearSheetUtil.getMetric(Metric.WaccCrspBuildUp, industryMetric);
        const waccRprCapm = TearSheetUtil.getMetric(Metric.WaccRprCapm, industryMetric);
        const waccRprBuildUp = TearSheetUtil.getMetric(Metric.WaccRprBuildUp, industryMetric);
        const waccCdfOneStage = TearSheetUtil.getMetric(Metric.WaccOneStageDcf, industryMetric);
        const waccDcfThreeStage = TearSheetUtil.getMetric(Metric.WaccThreeStageDcf, industryMetric);
        const waccScFThreeStage = TearSheetUtil.getMetric(Metric.WaccThreeStageFactor, industryMetric);
        const waccFamaFrenchFiveFactorModel = TearSheetUtil.getMetric(Metric.WaccFiveStageFactor, industryMetric);

        const waccValues = [
            waccCapm,
            waccCrspCapm,
            waccCrspBuildUp,
            waccRprCapm,
            waccRprBuildUp,
            waccCdfOneStage,
            waccDcfThreeStage,
            waccScFThreeStage,
            waccFamaFrenchFiveFactorModel
        ];

        if (waccCrspCapm == null
            && waccCrspBuildUp == null
            && waccRprCapm == null
            && waccRprBuildUp == null
            && waccCdfOneStage == null
            && waccDcfThreeStage == null
            && waccFamaFrenchFiveFactorModel == null
        ) {
            return null;
        }

        const averageMetrics: any[] = [];
        waccValues.forEach((value) => {
            if (value != null && value.SICCompositeLatest != null) {
                averageMetrics.push(value.SICCompositeLatest);
            }
        });

        const sicCompositeAverage = Util.average(averageMetrics, 2);
        const sicCompositeMedian = Util.median(averageMetrics, 2);
        const sicCompositeLow = Math.min(...averageMetrics);
        const sicCompositeHigh = Math.max(...averageMetrics);

        return this.buildChart(
            sicCompositeLow,
            sicCompositeHigh,
            sicCompositeAverage,
            sicCompositeMedian
        );
    }

    private static buildChart(low: number, high: number, average: number, median: number): Chart {
        return new Chart({
            chart: {
                type: 'scatter',
                width: 300,
                height: 250
            },
            title: {
                text: 'WACC (%)'
            },
            subtitle: {
                text: 'Industry Composite'
            },
            plotOptions: {
                scatter: {
                    tooltip: {
                        headerFormat: '<b>{series.name}</b><br>',
                        pointFormat: '{point.x}%'
                    },
                    dataLabels: {
                        enabled: true,
                        format: '{point.x}'
                    }
                }
            },
            series: [
                {
                    type: 'scatter',
                    name: 'Average',
                    color: '#14487f',
                    data: [
                        {
                            x: Util.round(average, 1),
                            y: 1,
                            value: Util.round(average, 1)
                        }
                    ]
                },
                {
                    type: 'scatter',
                    name: 'Median',
                    color: '#43b049',
                    data: [
                        {
                            x: Util.round(median, 1),
                            y: 0,
                            value: Util.round(median, 1)
                        }
                    ]
                }
            ],
            credits: {
                enabled: false
            },
            xAxis: {
                min: Util.round(low, 1),
                max: Util.round(high, 1),
                endOnTick: true,
                startOnTick: true,
                tickInterval: 0,
                offset: -50,
                labels: {
                    formatter(): string {
                        if (this.isFirst || this.isLast) {
                            return this.value.toFixed(2);
                        }
                        return '';
                    }
                }
            },
            yAxis: {
                visible: false
            }
        });
    }

}
