import * as moment from 'moment';
import { betaFontStyle, betaHeaderFontSize, betaNormalFontSize, betaSmallCellHeight, hyperLinkColor, krollblue, normalCellHeight, smallCellHeight } from '../../estimate/exports/summary-export/util/summary-helpers';

export function dataSources(): any[] {
    const inputs: any[] = [];
    inputs.push(
        {
            height: normalCellHeight,
            cells: [
                {
                    value: 'Source',
                    color: krollblue,
                    fontSize: betaHeaderFontSize,
                    bold: 'true'
                }
            ]
        },
        {
            height: smallCellHeight,
            fontSize: betaNormalFontSize,
            fontStyle: betaFontStyle,
            cells: [
                {
                    value: 'Kroll Cost of Capital Navigator: Company-level Beta Dataset'
                }
            ]
        },
        {
            height: smallCellHeight,
            fontSize: betaNormalFontSize,
            fontStyle: betaFontStyle,
            cells: [
                {
                    value: `Exported on : ${moment().format('MM/DD/YYYY')}`
                }
            ]
        },
        {
            cells: [{ value: '' }]
        },
        {
            height: normalCellHeight,
            cells: [
                {
                    value: 'Data Sources',
                    color: krollblue,
                    fontSize: betaHeaderFontSize,
                    bold: 'true'
                }
            ]
        },
        {
            height: smallCellHeight,
            fontSize: betaNormalFontSize,
            fontStyle: betaFontStyle,
            cells: [
                {
                    value: `Data Sources used with permission. All rights reserved. Calculations performed by Kroll, LLC.`
                }
            ]
        },
        {
            cells: [{ value: '' }]
        },
        {
            height: normalCellHeight,
            cells: [
                {
                    value: 'Data Type',
                    color: krollblue,
                    fontSize: betaHeaderFontSize,
                    bold: 'true'
                },
                {
                    value: 'Underlying Data Source(s):',
                    color: krollblue,
                    fontSize: betaHeaderFontSize,
                    bold: 'true' 
                }
            ]
        },
        {
            height: smallCellHeight,
            fontSize: betaNormalFontSize,
            fontStyle: betaFontStyle,
            cells: [
                {
                    value: 'Company-level returns', bold: 'true'
                },
                {
                    value: 'S&P Global Market Intelligence'
                }
            ]
        },
        {
            height: smallCellHeight,
            fontSize: betaNormalFontSize,
            fontStyle: betaFontStyle,
            cells: [
                {
                    value: 'Market Indices', bold: 'true'
                },
                {
                    value: 'MSCI'
                }
            ]
        },
        {
            height: smallCellHeight,
            fontSize: betaNormalFontSize,
            fontStyle: betaFontStyle,
            cells: [
                {
                    value: 'Tax Rates', bold: 'true'
                },
                {
                    value: 'Source: Tax Foundation'
                }
            ]
        }, 
        {
        height: betaSmallCellHeight,
        cells: [{ value: '' }]
        },
        {
            height: betaSmallCellHeight,
            cells: [{ value: '' }]
        },
        {
            height: normalCellHeight,
            cells: [
                {
                    value: 'Additional Info & Methodology',
                    color: krollblue,
                    fontSize: betaHeaderFontSize,
                    bold: 'true'
                }
            ]
        },
        {
            height: betaSmallCellHeight,
            cells: [
                {
                    fontSize: betaNormalFontSize,
                    formula: '=HYPERLINK("https://vasdc8grscoc.blob.core.windows.net/files/Levered-Betas.pdf","Levered Betas")',
                    value: 'Levered Betas',
                    color: hyperLinkColor,
                    underline: true
                }
            ]
        },
        {
            height: betaSmallCellHeight,
            cells: [
                {
                    fontSize: betaNormalFontSize,
                    formula: '=HYPERLINK("https://vasdc8grscoc.blob.core.windows.net/files/Beta-Statistics.pdf","Beta Statistics")',
                    value: 'Beta Statistics',
                    color: hyperLinkColor,
                    underline: true
                }
            ]

        },
        {
            height: betaSmallCellHeight,
            cells: [
                {
                    fontSize: betaNormalFontSize,
                    formula: '=HYPERLINK("https://vasdc8grscoc.blob.core.windows.net/files/Unlevering-Inputs.pdf","Unlevering Inputs")',
                    value: 'Unlevering Inputs',
                    color: hyperLinkColor,
                    underline: true
                }
            ]
        },
        {
            height: betaSmallCellHeight,
            cells: [
                {
                    fontSize: betaNormalFontSize,
                    formula: '=HYPERLINK("https://vasdc8grscoc.blob.core.windows.net/files/Unlevered-Betas.pdf","Unlevered Betas")',
                    value: 'Unlevered Betas',
                    color: hyperLinkColor,
                    underline: true
                }
            ]
        },
        {
            height: betaSmallCellHeight,
            cells: [
                {
                    fontSize: betaNormalFontSize,
                    formula: '=HYPERLINK("https://vasdc8grscoc.blob.core.windows.net/files/Relevered-Betas.pdf","Relevered Betas")',
                    value: 'Relevered Betas',
                    color: hyperLinkColor,
                    underline: true
                }
            ]
        },
        {
            height: betaSmallCellHeight,
            cells: [
                {
                    fontSize: betaNormalFontSize,
                    formula: '=HYPERLINK("https://vasdc8grscoc.blob.core.windows.net/files/Market-Index.pdf","Market Index")',
                    value: 'Market Index',
                    color: hyperLinkColor,
                    underline: true
                }
            ]

        }
    );
    return inputs;
}
