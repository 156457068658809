export class EstimateSummaryText {
    // Global
    public static readonly Type = 'Type';
    public static readonly Source = 'Source';
    public static readonly DataAsOf = 'Data as of';
    public static readonly Custom = 'Custom';
    public static readonly sectionLogSource = 'Source(s) of Underlying Data'
    // General Inputs
    public static readonly GeneralInputs = 'General Inputs';
    public static readonly EstimateName = 'Estimate Name';
    public static readonly InvestorCountry = 'Investor Country';
    public static readonly InvesteeCountry = 'Investee Country';
    public static readonly CashFlowCurrency = 'Cash Flow Currency';
    public static readonly CostOfEquityInputsCountry = 'Cost of Equity Inputs Country';
    public static readonly Industry = 'Industry';
    public static readonly ValuationDate = 'Valuation Date';

    // Cost of Equity Inputs
    public static readonly CostOfEquityInputs = 'Cost of Equity Inputs';
    public static readonly RiskFreeRate = 'Risk-free Rate';
    public static readonly Beta = 'Beta';
    public static readonly Erp = 'ERP';
    public static readonly SizePremium = 'Size Premium';
    public static readonly Alpha = 'Alpha (Company Specific Risk Premium)';

    // CRP/RV
    public static readonly CountryRiskPremia = 'Country Risk Premia/RV Factor';
    public static readonly PerspectiveCountry = 'Perspective Country';
    public static readonly CcrModelCrp = 'CCR Model CRP';
    public static readonly CountryYieldSpreadModelCrp = 'Country Yield Spread Model CRP';
    public static readonly RvModel = 'RV Model RV Factor';
    // eslint-disable-next-line max-len
    public static readonly NavigatorSource = `Kroll Cost of Capital Navigator – International Cost of Capital Module<br /><small>For detailed sourcing and methodology, see:<br />Cost of Capital Navigator’s Resources section > International Cost of Capital Module > Chapter 4: “Country Yield Spread Model”, Chapter 5: “Relative Volatility Model, and Chapter 6: “Erb-Harvey-Viskanta Country Credit Rating Model”.</small>`;
    public static readonly NavigatorSourceMain = 'Kroll Cost of Capital Navigator – International Cost of Capital Module';
    public static readonly NavigatorSourceDetails = 'For detailed sourcing and methodology, see:';
    // eslint-disable-next-line max-len
    public static readonly NavigatorSourceReferences = 'Cost of Capital Navigator’s Resources section > International Cost of Capital Module > Chapter 4: “Country Yield Spread Model”, Chapter 5: “Relative Volatility Model, and Chapter 6: “Erb-Harvey-Viskanta Country Credit Rating Model”.';

    // International Fisher Effect Inputs
    public static readonly FisherEffect = 'International Fisher Effect Inputs';
    public static readonly Inflation = 'Inflation';

    // Cost of Debt and WACC Inputs
    public static readonly CostOfDebt = 'Cost of Debt and WACC Inputs';
    public static readonly CostOfDebtInputsCountry = 'Cost of Debt Inputs Country';
    public static readonly TaxRate = 'Tax Rate';
    public static readonly DebtToTotalCapitalRatio = 'Debt to Total Capital Ratio';
    public static readonly PreTaxKd = 'Pre-tax Cost Of Debt (Kd)';
}
