import { Injectable } from '@angular/core';
import { MeasureClient } from 'src/app/_api/clients/measure.client';
import { CrspCharacteristicsData } from '../models/crspCharacteristicData';
import { Spinner } from '@concurrency/angular';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class SizePremiumService {

    public crspCharacteristicData!: CrspCharacteristicsData[];



    constructor(private measureClient: MeasureClient, private spinner: Spinner) {


    }


    public getCharacteristicData(dataAsOf: string): Observable<CrspCharacteristicsData[]> {
        return this.measureClient.getCrspCharcteristicData(dataAsOf)

    }


}