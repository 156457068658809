import * as moment from 'moment';

import { CurrencyPipe, PercentPipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { SubscriberEntity } from '@concurrency/angular';
import { Dictionary } from '@concurrency/core';
import { Dialog } from 'primeng/dialog';
import { EquationType } from 'src/app/_api/responses/equation.response';
import { InputType } from 'src/app/_api/responses/input.response';
import { Portfolio } from 'src/app/_api/responses/portfolio.response';
import { DataStore } from 'src/app/_navigator/data/store/data.store';
import { NumberFormatUtil } from 'src/app/_navigator/data/util/number-format.util';

@Component({
    selector: 'risk-table',
    templateUrl: './risk-table.component.html',
    providers: [CurrencyPipe, PercentPipe]
})
export class RiskTableComponent extends SubscriberEntity implements OnInit {
    @Input() public equationType!: EquationType;
    @Input() public inputType!: InputType;

    public riskViewModel: RiskViewModel = {} as RiskViewModel;
    public display = false;

    @ViewChild(Dialog) public dialog!: Dialog;

    constructor(
        private dataStore: DataStore,
        private currency: CurrencyPipe,
        private percent: PercentPipe
    ) { super(); }

    private toViewModel(portfolios: Portfolio[]): RiskViewModel {
        const riskDataViewModel = {} as RiskViewModel;
        riskDataViewModel.data = [];

        riskDataViewModel.title = portfolios.length
            ? `${TableTypes[this.equationType].modalTitle} ${moment(portfolios[0].DataAsOf).format('LL')}`
            : '';

        riskDataViewModel.metric = portfolios.length ? portfolios[0].Metric : '';
        riskDataViewModel.sizePremiaNameHtml = TableTypes[this.equationType].sizePremiaNameHtml;

        type selector = (portfolio: Portfolio) => number;
        const columns: Dictionary<selector> = {};
        columns['Avg. Debt/MVIC'] = (portfolio) => portfolio.AverageDebtMvic;
        columns['Avg. Op. Margin'] = (portfolio) => portfolio.AverageOperatingMargin;
        columns['Avg. CV (ROE)'] = (portfolio) => portfolio.AverageCovroe;
        columns['Std. Dev. Of Returns'] = (portfolio) => portfolio.StandardDeviationOfReturns;
        // eslint-disable-next-line @typescript-eslint/dot-notation
        columns['RPs'] = (portfolio) => portfolio.Result;

        for (const portfolio of portfolios) {
            const singleMap: Map<string, string | null> = new Map<string, string | null>();

            singleMap.set('Portfolio', portfolio.Name);
            singleMap.set('Portfolio Breakpoint', this.setupBreakpoint(portfolio, portfolios.length));

            // eslint-disable-next-line guard-for-in
            for (const key in columns) {
                const value = columns[key](portfolio);

                if (value != null) {
                    const percent = this.percent.transform(value, '1.1-2');
                    singleMap.set(key, percent);
                }
            }

            riskDataViewModel.data.push(singleMap);
        }

        return riskDataViewModel;
    }
    private setupBreakpoint(portfolio: Portfolio, count: number): string {
        const lower = this.getFormattedValue(portfolio.Lower);
        const upper = this.getFormattedValue(portfolio.Upper);
        const index: number = portfolio.Id - 1;
        const isFirst = index === 0;
        const isLast = index === count - 1;

        let breakPointText = '';

        if (isFirst) {
            breakPointText = `${lower} and Up`;
        } else if (isLast) {
            breakPointText = `Up to ${upper}`;
        } else {
            breakPointText = `${lower} - ${upper}`;
        }

        return breakPointText;
    }
    private getFormattedValue(value: number): string | null {
        const formattedValue: string | null = NumberFormatUtil.numberWithCommas(value, 5);
        const isPercentage = this.getIsPercentage();
        const isNumberOfEmployees = this.getIsNumberOfEmployees();

        if (isPercentage) {
            return this.percent.transform(formattedValue, '1.1-2');
        } else if (isNumberOfEmployees) {
            return formattedValue;
        }

        return this.currency.transform(value);
    }

    private getIsNumberOfEmployees(): boolean {
        return InputType[this.inputType] === InputType.NumberOfEmployees;
    }

    private getIsPercentage(): boolean {
        return InputType[this.inputType] === InputType.OperatingMargin ||
            InputType[this.inputType] === InputType.CoefficientOfVariationOfOperatingMargin ||
            InputType[this.inputType] === InputType.CoefficientOfVariationOfReturnOnEquity;
    }

    public ngOnInit(): void {
        this.dataStore.portfolios
            .onceDefined((result) => {
                const portfolios = result.filter((z) => {
                    const inputType = this.inputType as InputType;
                    return z.EquationType === TableTypes[this.equationType].equationType && z.InputType === inputType;
                });
                this.riskViewModel = this.toViewModel(portfolios);

                setTimeout(() => this.dialog);
                this.display = true;
            });
    }

}

interface RiskViewModel {
    title: string;
    metric: string;
    sizePremiaNameHtml: string | undefined;
    data: Map<string, string | null>[];
}

const TableTypes: Dictionary<{
    equationType: EquationType,
    modalTitle: string,
    sizePremiaNameHtml?: string
}> = {};
TableTypes[EquationType.RprsCapmSizeStudy] = {
    equationType: EquationType.RprsCapmSizeStudy,
    modalTitle: 'Risk Premium Report Size Study Size Premia as of'
};
TableTypes[EquationType.RprsBuildup1Levered] = {
    equationType: EquationType.RprsBuildup1Levered,
    modalTitle: 'Risk Premium Report Size Study Risk Premia Over the Risk - Free Rate as of',
    sizePremiaNameHtml: '<span>RP</span><sub>m+s(levered)</sub>'
};
TableTypes[EquationType.RprsBuildup1Unlevered] = {
    equationType: EquationType.RprsBuildup1Unlevered,
    modalTitle: 'Risk Premium Report Size Study Risk Premia Over the Risk - Free Rate as of',
    sizePremiaNameHtml: '<span>RP</span><sub>m+s(unlevered)</sub>'
};
TableTypes[EquationType.RprsBuildup2] = {
    equationType: EquationType.RprsCapmSizeStudy,
    modalTitle: 'Risk Premium Report Size Study Size Premia as of'
};
TableTypes[EquationType.RprsBuildup3] = {
    equationType: EquationType.RprsBuildup3,
    modalTitle: 'Risk Premium Report Risk Study Risk Premia Over the Risk - Free Rate as of',
    sizePremiaNameHtml: '<span>RP</span><sub>m+s(levered)</sub>'
};
