import { IndustryMetric } from 'src/app/_api/responses/industry-metric.response';
import { SubscriptionType, UserResponse } from 'src/app/_api/responses/user.response';
import { NumberFormatUtil } from 'src/app/_navigator/data/util/number-format.util';
import { Columns } from '../const/columns.const';
import { MetricBeta } from '../const/metric-beta';
import { Metric } from '../enums/metric';

export class TearSheetUtil {

    public static roundTearSheetValue(value: number | null, isBeta: boolean): string {
        if (value == null) {
            return '-';
        }

        const precision = isBeta ? 2 : 1;

        return NumberFormatUtil.numberWithCommas(value, precision);
    }

    public static isBeta(value: IndustryMetric): boolean {
        const betaFound = MetricBeta.filter((x) => x === value.MetricId);

        if (betaFound.length === 0) {
            return false;
        }

        return true;
    }

    public static showAverageValues(data: IndustryMetric): boolean {
        if (data.MedianAverage == null
            && data.SICCompositeAverage == null
            && data.LargeCompositeAverage == null
            && data.SmallCompositeAverage == null
            && data.HighFinancialRiskAverage == null
        ) {
            return false;
        }

        return true;
    }

    public static getMetric(metricId: Metric, metricsPool: IndustryMetric[]): IndustryMetric | undefined {
        return metricsPool.find((x) => x.MetricId === metricId);
    }

    public static getColumnNames(metric: IndustryMetric): string[] {
        let columnNames: string[] = [];

        switch (metric.MetricId) {
            case Metric.ReturnOnAssets:
            case Metric.ReturnOnEquity:
            case Metric.DividendYield:
            case Metric.CurrentRatio:
            case Metric.OperatingMargin:
            case Metric.PriceSales:
            case Metric.PriceEarnings:
            case Metric.MarketBook:
            case Metric.EvSales:
            case Metric.EvEbitda:
            case Metric.DebtMvEquity:
            case Metric.DebtTotalCapital:
            case Metric.PriceTbv:
            case Metric.PriceEbt:
                columnNames = [Columns.Latest, Columns.FiveYrAvg];
                break;
            case Metric.LongTermEps:
                columnNames = [Columns.AnalystEstimates];
                break;
            case Metric.FiveFactorModelBeta:
            case Metric.FiveFactorModelSmbPremium:
            case Metric.FiveFactorModelHmlPremium:
            case Metric.FiveFactorModelRmwPremium:
            case Metric.FiveFactorModelCmaPramium:
            case Metric.Capm:
            case Metric.WaccCapm:
                columnNames = [metric.MetricName];
                break;
            case Metric.CostOfDebt:
                columnNames = [Columns.Latest];
                break;
            case Metric.CrspCapm:
            case Metric.RprCapm:
            case Metric.WaccCrspCapm:
            case Metric.WaccRprCapm:
                columnNames = [Columns.CapmSizePrem];
                break;
            case Metric.CrspBuildUp:
            case Metric.RprBuildUp:
            case Metric.WaccCrspBuildUp:
            case Metric.WaccRprBuildUp:
                columnNames = [Columns.BuildUp];
                break;
            case Metric.OneStageDcf:
            case Metric.WaccOneStageDcf:
                columnNames = [Columns.OneStage];
                break;
            case Metric.ThreeStageDcf:
            case Metric.WaccThreeStageDcf:
            case Metric.ThreeStageFactor:
            case Metric.WaccThreeStageFactor:
                columnNames = [Columns.ThreeStage];
                break;
            case Metric.FiveStageFactor:
            case Metric.WaccFiveStageFactor:
                columnNames = [Columns.FiveFactorModel];
                break;
            default:
                break;
        }

        return columnNames;
    }

    public static hasBothSubscriptions(user: UserResponse, coc: SubscriptionType, usi: SubscriptionType): boolean {
        const hasCoc = user.Subscriptions.find((x) => x.Type === coc);
        const hasUsi = user.Subscriptions.find((x) => x.Type === usi);

        return hasCoc != null && hasUsi != null;
    }

}
