export interface UserResponse {
    ProfileId: string;
    IdentityId: string;
    FullName: string;
    FirstName: string;
    LastName: string;
    Email: string;
    PhoneNumber: string;
    JobTitle: string;
    CountryCode: string;
    Country: string[];
    AcceptTerms: boolean;
    ProductNewsId: string;
    ProductNewsRead: boolean;
    BannerMessageId: string;
    ReadBannerMessage: boolean;
    MarketingOptIn: boolean;
    PartnerId: string;
    CompanyId: string;
    CompanyName: string;
    PartnerName: string;
    FeatureName: string[];
    FeaturesWithExpiration: any[];
    LicenseExpiration: string;
    CustomerName: string;
    IsActive: boolean;
    IsAdmin: boolean;
    Roles: string[];
    Subscriptions: UserSubscription[];
    CreatedOn: string;
    UpdatedOn: string;
    CreatedBy: string;
    UpdatedBy: string;
}

export interface UserSubscription {
    Type: SubscriptionType;
    Start: string;
    End: string;
    OfferRenewal: boolean;
    Reseller: string;
}

export enum SubscriptionType {
    None = 'None',
    CocTrial = 'CocTrial',
    CocBasic = 'CocBasic',
    CocPro = 'CocPro',
    CocEnterprise = 'CocEnterprise',
    UsiTrial = 'UsiTrial',
    UsiBasic = 'UsiBasic',
    UsiPro = 'UsiPro',
    UsiEnterprise = 'UsiEnterprise',
    IneTrial = 'IneTrial',
    IneBasic = 'IneBasic',
    InePro = 'InePro',
    IneEnterprise = 'IneEnterprise',
    IniTrial = 'IniTrial',
    IniBasic = 'IniBasic',
    IniPro = 'IniPro',
    IniEnterprise = 'IniEnterprise',
    BtaTrial = 'BtaTrial',
    BtaBasic = 'BtaBasic',
    BtaPro = 'BtaPro',
    BtaEnterprise = 'BtaEnterprise'
}

export enum UserActivityLogType {
    SignIn = 'signIn',
    SignOut = 'signOut'
}
export interface UserActivityRequest {
    Id: string | null;
    userActivityType: UserActivityLogType;
}


export interface TermsAndConditionsVersions {
    Id: number;
    TermsAndConditionsVersionNumber: string;
    TermsAndConditionsText: string;
    IsActive: boolean;
    CreatedOn: Date;
    UpdatedOn: Date
}


export interface AcceptVersionData {
    marketingOptin: boolean,
    versonNumber: string;
}