import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FooterLinkUtil } from '../footer/footer-link.util';

@Component({
    // selector: 'product-news',
    templateUrl: './product-news.component.html',
    styleUrls: ['./product-news.component.scss']
})
export class ProductNewsComponent implements OnInit {
    public FooterLinkUtil = FooterLinkUtil;
    public productNews = '';
    constructor(private http: HttpClient) { }

    public ngOnInit(): void {
        this.http.get(environment.urls.contentManagement + '/Product-News.html', { responseType: 'text' }).subscribe((res) => {
            this.productNews = res.toString();
        });
    }
}
