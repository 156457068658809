import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SubscriberEntity } from '@concurrency/angular';
import { takeUntil } from 'rxjs/operators';
import { InputType } from 'src/app/_api/responses/input.response';
import { Estimate } from 'src/app/_navigator/data/model/estimate.model';
import { DataStore } from 'src/app/_navigator/data/store/data.store';
import { HelpText } from 'src/app/_navigator/help/help-text';
import { HelpService } from 'src/app/_navigator/help/help.service';

enum Tab { Capm, Buildup1, Buildup2, Buildup3 }

@Component({ templateUrl: './risk.component.html' })
export class RiskComponent extends SubscriberEntity implements OnInit, OnDestroy {
    public estimate!: Estimate; // TODO: Do not use bangs for regular variables
    public activeTab: Tab = Tab.Capm;
    public Tab = Tab;
    public isAvailable = true;

    constructor(
        private dataStore: DataStore,
        private router: Router,
        private helpService: HelpService
    ) { super(); }

    private setRprRecommended(estimate: Estimate): void {
        const forceAllowRpr = this.estimate.getInput(InputType.ForceAllowRpr);
        if (forceAllowRpr.Value === 1) {
            return;
        }

        if (estimate.Industries == null) {
            return;
        }

        if (estimate.hasFinancialIndustry()) {
            this.isAvailable = false;
            this.helpService.open('rprNotRecommended', HelpText.FinancialServicesExcludedRpr, false);
            this.helpService.setAction(() => {
                this.isAvailable = true;
                this.helpService.close();
                estimate.setInput(InputType.ForceAllowRpr, 1);
            });
        }
    }

    // TODO: Levered/Unlevered/Relevered should be part of the routes
    public ngOnInit(): void {
        const setupTab = () => {
            if (this.router.url.includes('/estimate/studies/risk/buildup1')) {
                this.activeTab = Tab.Buildup1;
            } else if (this.router.url.includes('/estimate/studies/risk/buildup2')) {
                this.activeTab = Tab.Buildup2;
            } else if (this.router.url.includes('/estimate/studies/risk/buildup3')) {
                this.activeTab = Tab.Buildup3;
            } else {
                this.activeTab = Tab.Capm;
            }
        };

        setupTab();
        // TODO: Obviate takeUntil by using Async pipes and local Observable streams
        this.router.events.pipe(takeUntil(this.destroyed)).while(() => setupTab());
        this.dataStore.estimate.pipe(takeUntil(this.destroyed)).whileDefined((estimate) => {
            this.estimate = estimate;
            setTimeout(() => this.setRprRecommended(estimate));
        });
    }

    public routeTo(study: Tab): void {
        this.activeTab = study;
        switch (study) {
            case Tab.Buildup1:
                this.router.navigate([`/estimate/studies/risk/buildup1`]);
                break;
            case Tab.Buildup2:
                this.router.navigate([`/estimate/studies/risk/buildup2`]);
                break;
            case Tab.Buildup3:
                this.router.navigate([`/estimate/studies/risk/buildup3`]);
                break;
            case Tab.Capm:
            default:
                this.router.navigate([`/estimate/studies/risk/capm`]);
        }
    }

    public ngOnDestroy(): void {
        this.helpService.closeImmediately();
        super.ngOnDestroy();
    }

}
