import { Component } from '@angular/core';
import { Spinner } from '@concurrency/angular';
import { USCompanyClient } from 'src/app/_api/clients/uscompany.client';
import { SelectionType } from 'src/app/_api/enums/selection-type';
import { CommonInputConfig } from 'src/app/_navigator/common/inputs/common-user-input.component';
import { Suggestion } from 'src/app/_navigator/data/model/suggestion.model';
import { DataStore } from 'src/app/_navigator/data/store/data.store';
import { ErrorService } from 'src/app/_navigator/error/error.service';
import { HelpText } from 'src/app/_navigator/help/help-text';
import { PrimeManager } from 'src/app/_navigator/modal/pmodal.manager';
import { BetaEditorCompanyListComponent } from './beta-editor-company-list.component';
import { SimpleEditorComponent } from './editor.component';

@Component({ templateUrl: './beta-editor.component.html' })
export class BetaEditorComponent extends SimpleEditorComponent {
    public suggestedBetas: Suggestion<number>[] = [];
    public inputConfig: CommonInputConfig = {
        name: 'Beta (Levered)',
        help: HelpText.BetaDetailed,
        error: this.errorService.beta,
        minimumValue: 0,
        maximumValue: 5,
        pattern: '(.*?)',
        autofocus: true
    };

    constructor(
        protected dataStore: DataStore,
        private errorService: ErrorService,
        private primeManager: PrimeManager,
        private spinner: Spinner,
        private usCompanyClient: USCompanyClient
    ) { super(dataStore); }

    // Override
    public initialize(): void {
        if (this.settings.operand.selectionType === SelectionType.RiskPremiumBeta ||
            this.settings.operand.selectionType === SelectionType.HighFinancialRiskBeta
        ) {
            this.inputConfig.help = HelpText.SumBeta;
        }
        this.inputConfig.placeholder = 'N/A';
        this.mask();
    }

    // Override
    public mask(): void {
        this.inputConfig.mask = `{} - ${this.getSource()}`;
    }
    public showCompanies(): void {
        if (this.estimate && this.estimate.Industries.length > 0) {
            const request = this.usCompanyClient.getFibCompanies(this.estimate.ValuationDate, this.estimate.Industries[0].SicId);
            this.spinner.while(request);
            request.once((data) => {
                this.primeManager.openDialog(BetaEditorCompanyListComponent, {
                    companies: data,
                    industries: this.estimate.Industries,
                    dataAsOf: this.estimate.ValuationDate,
                    tabname: document.getElementsByClassName("study-group active")[0].innerHTML
                });
            });
        }
    }
}
