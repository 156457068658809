import { Component, OnInit } from "@angular/core";
import { CocInputsSharedService } from "../../service/cocInputShared.service";
import { CocinputsExcelExportService } from "../../service/cocinputs-excel-export.service";
import { ContextualString } from "@concurrency/core";
import { Suggestion } from "src/app/_navigator/data/model/suggestion.model";
import { BetaLeverage, enumExcelExportSheets } from "../../cocViewInputs";
import { ComboboxDataType } from "src/app/_navigator/data/enum/combobox-data-type.enum";
import { Spinner } from "@concurrency/angular";
import { FilesClient } from "src/app/_api/clients/files.client";
import { ResourceFileType } from "src/app/_api/enums/resource-file-type";
import { CocInputResourcesList } from "../../coc-input-resources-list.model";
import { CommonInputConfig } from "src/app/_navigator/common/inputs/common-user-input.component";
import { BetaIndustryData, USDebtBetasUi } from "../../models/cocInputsTabs";
import { ExcelExportComponent } from "@progress/kendo-angular-excel-export";
import { USDebtBetas } from "src/app/_api/responses/us-industry.response";
import { UserStore } from "src/app/_navigator/user/store/user.store";
import { EnumSubsType } from "../../models/userSubscriptionTypes";
import { DebtBetasTrendsOverTimeComponent } from '../debt-betas-trends-over-time/debt-betas-trends-over-time.component';
import { PrimeManager } from 'src/app/_navigator/modal/pmodal.manager';

@Component({
    selector: 'betas-industry-risk-premium',
    templateUrl: './betas-industry-risk-premium-component.html',
    styleUrls: ['./betas-industry-risk-premium-component.scss']
})

export class BetasandIndustryRiskPremiumComponent implements OnInit {


    constructor(
        private sharedCocInputService: CocInputsSharedService,
        private exportService: CocinputsExcelExportService,
        public spinner: Spinner,
        private filesClient: FilesClient,
        private userStore: UserStore,
        private primeManager: PrimeManager,
    ) { }

    public isvalidScenario!: boolean;
    public betaIndustry = new ContextualString();
    public industryDataOptions: Suggestion<string>[] = [];
    public irpDataOptions: Suggestion<string>[] = [];
    public tabHeaders!: string;
    public irptabHeaders!: string;
    public usDebttabHeaders!: string;
    public tableHeaders!: string[];
    public irpTableHeaders!: string[];
    public usDebtTableHeaders!: string[];
    public industryBetasResourcesList!: CocInputResourcesList;
    public leverageBeta = new ContextualString();
    public industryRiskPremia = new ContextualString();
    public leveredBetas!: BetaIndustryData[];
    public unleveredBetas!: BetaIndustryData[];
    public industryRiskPremiaBetas!: BetaIndustryData[];
    public gicsData!: boolean;
    public sicsData!: boolean;
    public gicsDate: string = "2020-09-30";
    public isBetaDisplayed!: string;
    public erpValueDisplayed!: string;
    //public irpBetasLoaded!: boolean;
    public exportFileName = 'Betas';
    public headresData = {
        'Levered': ['Industry', 'Full Information', 'OLS', 'Sum', 'Vasicek', 'Blume', 'Peer'],
        'Unlevered': ['Industry', 'Full Information', 'OLS', 'Sum', 'Vasicek', 'Blume', 'Peer'],
        'DebtBetas': ['Moodys Ratings', 'Value']
    } as any;
    public displayIndustryCompanyListModal: boolean = false;
    public selectedIndustryId!: number;
    public selectedBetaType!: string;
    public isSubTypeIne!: boolean;

    public ngOnInit(): void {
        //this.sharedCocInputService.setBetaDataOptions();

        this.loadIndustryBetasComponent();
        this.isSubTypeIne = this.sharedCocInputService.usrPerms.SubsType == EnumSubsType.Ine

    }

    public loadIndustryBetasComponent() {
        //this.sharedCocInputService.irpBetasLoaded = false;
        this.sharedCocInputService.irptabHeaders = "Industry Risk Premium (RPi)";
        this.sharedCocInputService.irpTableHeaders = this.sharedCocInputService.betaHeadresData[BetaLeverage.IRP];
        // this.sharedCocInputService.setBetaDataOptions();
        // this.sharedCocInputService.setDebtBetaOptions();
    }

    public betasIndustryConfig: CommonInputConfig = {
        name: 'Beta Leverage',
        placeholder: 'Select Data',
        containerClassList: 'input-flex-display inline-disable-text',
        stackedDisplay: true,
        iconWithLabel: true,
        required: true,
        readonly: true,
    };

    public industryRiskPremiaConfig: CommonInputConfig = {
        name: 'ERP used in RPi',
        placeholder: 'Select Data',
        containerClassList: 'input-flex-display inline-disable-text',
        stackedDisplay: true,
        iconWithLabel: true,
        required: true,
        readonly: true,
    };



    public exportToExcel(component: ExcelExportComponent, betaType: number) {

        let enumdataStudy = enumExcelExportSheets.DebtBetas
        this.exportFileName = 'Debt-Betas-data'
        if (betaType == enumExcelExportSheets.Betas) {
            this.exportFileName = 'Industry-Betas-data';
            enumdataStudy = enumExcelExportSheets.Betas;
        } else if (betaType == enumExcelExportSheets.IndustryRiskPremium) {
            this.exportFileName = 'Industry-RP-data';
            enumdataStudy = enumExcelExportSheets.IndustryRiskPremium;
        }
        this.exportService.generateWorkBook(component, enumdataStudy);
    }

    public showIndustryCompanyList() {
        this.displayIndustryCompanyListModal = !this.displayIndustryCompanyListModal
    }

    public fetchBetaIndustryData() {
        if (this.sharedCocInputService.betaIndustry.value) {
            this.sharedCocInputService.getCompanyNames(this.selectedBetaType, this.sharedCocInputService.betaIndustry.value, this.selectedIndustryId);
        }
    }

    public setIndustryIdAndBetaTypeOnModalLoad() {
        this.selectedBetaType = this.sharedCocInputService.BetaTypeOptions.Fibs;
        this.selectedIndustryId = this.sharedCocInputService.betaIndustryIdList.length > 0 ? this.sharedCocInputService.betaIndustryIdList[0].industryId : 0;

    }

    public onclickExportCompany(component: ExcelExportComponent) {
        this.exportFileName = 'Industry-Betas-Co-List'
        const industry = this.sharedCocInputService.betaIndustryIdList.find(d => d.industryId == this.selectedIndustryId);
        const industryName = industry?.industryCode + "-" + industry?.industryDescription
        this.exportService.generateCompanyExcelSheet(component, this.selectedBetaType, industryName)
    }

    public ViewIndustryTrends() {
        try {
            this.primeManager.openDialog(DebtBetasTrendsOverTimeComponent);
        } catch (e) {
            console.log(e)
        }
    }


    // public onChangeBetaTypeandIndustry() {
    //     if (this.sharedCocInputService.betaIndustryIdList?.length > 0) {
    //         const tempindustry = this.sharedCocInputService.betaIndustryIdList.find(d => d.industryId == this.selectedIndustryId);
    //         const tempindustryName = tempindustry?.industryCode + "-" + tempindustry?.industryDescription
    //         if (this.selectedBetaType == this.sharedCocInputService.BetaTypeOptions.Fibs && tempindustry?.industryDescription == "Energy" && tempindustry?.industryCode == "GICS 10") {
    //             this.isvalidScenario = true;
    //         } else {
    //             this.isvalidScenario = false;
    //         }
    //     }
    // }
}
