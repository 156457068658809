import { Component, Input } from '@angular/core';
import { HelpService } from 'src/app/_navigator/help/help.service';
import { IntlTearsheetHelptext } from 'src/app/_navigator/help/intltear-sheet-helptext';
import { TearsheetHelptext } from 'src/app/_navigator/help/tearsheet-help-text';
import { Categories } from './const/categories.const';
import { StatisticsCategory } from './models/statistics-category.model';

@Component({
    selector: 'statistics-category',
    templateUrl: './statistics-category.component.html'
})
export class StatisticsCategoryComponent {
    @Input() public statisticsCategory!: StatisticsCategory;
    @Input() public region!: string;
    constructor(
        public helpService: HelpService
    ) { }

    public staticcategorytitle(statisticsCategoryName: Categories): void {
        if (statisticsCategoryName === Categories.AnnualizedMonthlyPerformanceStatistics) {
            if (this.region.toLowerCase() !== 'united states') {
                return this.helpService.open(Categories.AnnualizedMonthlyPerformanceStatistics,
                    IntlTearsheetHelptext.AnnualizedMonthlyPerformanceStatistics);
            } else {
                return this.helpService.open(Categories.AnnualizedMonthlyPerformanceStatistics,
                    TearsheetHelptext.AnnualizedMonthlyPerformanceStatistics);
            }

        }
    }
}
