import { Component, Input } from '@angular/core';
import { TrendsOverTimeTableData } from 'src/app/benchmarking/data/trends-over-time-table-data';
import { TrendsOverTimeService } from './data/trends-over-time.service';

@Component({
    selector: 'trends-over-time-table',
    templateUrl: './trends-over-time-table.component.html',
    styleUrls: [ './trends-over-time-table.component.scss' ]
})
export class TrendsOverTimeTableComponent {
    @Input() public tableData!: TrendsOverTimeTableData;
    @Input() public metricTitle!: string;
    @Input() public medianLatestFilter = false;
    @Input() public sicCompositeFilter = false;
    @Input() public largeCompositeFilter = false;
    @Input() public smallCompositeFilter = false;
    @Input() public highFinancialRiskFilter = false;

    constructor(
        public trendsOverTimeService: TrendsOverTimeService
    ) {}
}
