export const crspDecileCharacter = [
    '10w',
    'Market Value of Equity ($M)',
    'Book Value of Equity ($M)',
    'Market/Book',
    'MVIC ($M)',
    'Debt/MVIC',
    'Total Assets($M)',
    'Sales($M)',
    '5-Year Average Net Income ($M)',
    '5-Year Average EBITDA ($M)',
    'ROE',
    'OLS Beta',
    'Sum Beta']


export const spRPRCharacter = [
    'Portfolio 23',
    'Market Value of Equity ($M)',
    'Book Value of Equity ($M)',
    '5-Year Average Net Income ($M)',
    'MVIC ($M)',
    'Total Assets($M)',
    '5-Year Average EBITDA ($M)',
    'Sales($M)',
    'Number of Employees'
]


export const countryRiskSupplyHeaders = [
    'Investee Country',
    'Region',
    'MSCI Market Classification',
    'Country Yield Spread Model Tier',
    'Long Term Expected Inflation(2024-2053)',
    '2023 TaxRate'
]

export const countryRiskPremiumHeaders = [
    'Investee Country',
    'Region',
    'Country Yield Spread Model CRP',
    'Country Credit Rating Model (CCR) CRP',
    'Relative Volatility Model RV Factor'
]


