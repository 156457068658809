import { ContextualString } from '@concurrency/core';
import { CommonInputConfig } from 'src/app/_navigator/common/inputs/common-user-input.component';
import { ComboboxDataType } from 'src/app/_navigator/data/enum/combobox-data-type.enum';
import { Suggestion } from 'src/app/_navigator/data/model/suggestion.model';
import { HelpText } from 'src/app/_navigator/help/help-text';
import { InputText } from './input-text';

export class YieldSpreadSelection {
    public static readonly YieldSpreadSelectionConfig: CommonInputConfig = {
        name: InputText.UseYieldSpreadOnly,
        required: true,
        readonly: true,
        help: HelpText.UseYieldSpreadOnly
    };

    public static readonly YieldSpreadSelections: Suggestion<string>[] = [
        { name: 'No', value: 'No', id: 0, type: ComboboxDataType.String },
        { name: 'Yes', value: 'Yes', id: 1, type: ComboboxDataType.String }
    ];

    public static GetYieldSpreadModel(useYieldSpread: number | undefined): ContextualString {
        return useYieldSpread ? new ContextualString('Yes', 'Yes', 1) : new ContextualString('No', 'No', 0);
    }
}
