export interface BetaEstimateResponse {
    EstimateId: any;
    UserProfileId: any;
    Name: string;
    ValuationDate: string;
    CurrencyOfWacc: string;
    SubjectCompanyLocation: string;
    ReturnFrequency: string;
    LookbackPeriod: string;
    Marketindex: string;
    BetaReleverType: string;
    IsActive: boolean;
    Created: Date;
    Updated: Date;
    Companies: BetaCompanyInfo[];
    Selections: BetaSelections[];
    Values: BetaValues[];
}

export interface BetaCompanyInfo {
    Id: number;
    EstimateId: any;
    GVKEY: string;
    CompanyMetricId: number;
    CompanyName: string;
    CompanyInfoValue: string;
    DataAsOf: string;
    IsHidden: boolean;
}

export interface BetaSelections {
    SelectionId: number;
    EstimateId: any;
    SelectionMetricId: number;
    Context: string;
    DataAsOf: string;
    SelectionValue: number | null;
    GVKEY: string;
}

export interface BetaValues {
    Id: number;
    EstimateId: any;
    GvKey: string;
    BetaMetricId: number;
    DataAsOf: string;
    CompanyName: string;
    BetaValue: number | null;
}

export interface BetaSelectionMetrics {
    SelectionId: number;
    Name: string;
}

export interface BetaCompanyMetrics {
    CompanyMetricId: number;
    Name: string;
}

export enum BetaMetricType {
    NumObservations = 1,
    OLSLevered = 2,
    SumLevered = 3,
    VasicekLevered = 4,
    BlumeLevered = 5,
    PortfolioLevered = 6,
    RSquareOLS = 7,
    RSquareSum = 8,
    StandardError = 9,
    TStat = 10,
    IsSig90 = 11,
    IsSig95 = 12,
    IsSig99 = 13,
    BVDebt = 14,
    MVEquity = 15,
    DTC = 16,
    OLSUnlevered = 17,
    SumUnlevered = 18,
    VasicekUnlevered = 19,
    BlumeUnlevered = 20,
    PortfolioUnlevered = 21,
    MinLevered = 22,
    MinUnlevered = 23,
    MinRelevered = 24,
    MaxLevered = 25,
    MaxUnlevered = 26,
    MaxRelevered = 27,
    Q1Levered = 28,
    Q1Unlevered = 29,
    Q1Relevered = 30,
    Q3Levered = 31,
    Q3Unlevered = 32,
    Q3Relevered = 33,
    AverageLevered = 34,
    AverageUnlevered = 35,
    AverageRelevered = 36,
    MedianLevered = 37,
    MedianUnlevered = 38,
    MedianRelevered = 39,
    Sales = 40,
    EBITDA = 41,
    TotalAssets = 42
}

export enum BetaCompanyMetricsType {
    Industry = 1,
    Keywords = 2,
    HeadquarterCountry = 3,
    IncorporationCountry = 4,
    Exchange = 5,
    Website = 6
}

export enum BetaSelectionsType {
    TaxRateUnlever = 1,
    TargetCapitalStructure = 2,
    TaxRateRelever = 3
}
