import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SubscriberEntity } from '@concurrency/angular';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { BetaEstimate } from 'src/app/_navigator/data/model/beta-estimate.model';
import { Estimate } from 'src/app/_navigator/data/model/estimate.model';
import { BetaEstimatesListState } from 'src/app/_navigator/estimates-list-store/beta-estimates-list-state';
import { EstimatesListState } from 'src/app/_navigator/estimates-list-store/estimates-list-state';
import { EstimateModule } from './estimate-module.model';

@Component({
    templateUrl: './estimates.component.html'
})
export class EstimatesComponent extends SubscriberEntity implements OnInit {
    public estimateModules: EstimateModule[] = [];
    public currentModule!: EstimateModule;

    @Select(EstimatesListState.get) public estimates!: Observable<Estimate[] | undefined>;
    @Select(BetaEstimatesListState.get) public betaEstimates!: Observable<BetaEstimate[] | undefined>;

    constructor(private router: Router) {
        super();
    }

    public ngOnInit(): void {
        this.estimates.onceDefined(() => {
            this.estimateModules.push({
                title: 'My U.S. Cost of Capital Estimates',
                route: '/estimates/us-estimates'
            });
            this.estimateModules.push({
                title: 'My International Cost of Capital Estimates',
                route: '/estimates/intl-estimates'
            });
            this.estimateModules.push({
                title: 'My Company-level Beta Estimates',
                route: '/estimates/beta-estimates'
            });

            if (this.router.url.includes('us-estimates')) {
                this.currentModule = this.estimateModules[0];
            } else if (this.router.url.includes('intl-estimates')) {
                this.currentModule = this.estimateModules[1];
            } else if (this.router.url.includes('beta-estimates')) {
                this.currentModule = this.estimateModules[2];
            }
        });
    }

    public navigate(estimateModule: EstimateModule): void {
        this.currentModule = estimateModule;
        this.router.navigate([estimateModule.route]);
    }
}
