import { Injectable } from '@angular/core';
import { PDFExportComponent } from '@progress/kendo-angular-pdf-export';
import { Group } from '@progress/kendo-drawing';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class TrendsOverTimePDFExportService {
    private pdf?: PDFExportComponent;
    private _pdfExported = new BehaviorSubject<boolean | null | undefined>(undefined);

    public preparePdfExport = false;
    public prepareExcelExport = false;

    public pdfExported = this._pdfExported.asObservable();

    public setPdf(pdf: PDFExportComponent): void {
        this.pdf = pdf;
    }

    public savePdf(): void {
        // Kendo ostensibly solves the prepare export problem itself, but it doesn't work
        this.preparePdfExport = true;

        setTimeout(() => {
            if (this.pdf == null) {
                throw Error(`Expected setPdf to be called before savePdf.`);
            }

            this.pdf.saveAs('trendsovertime.pdf');
            setTimeout(() => {
                this.preparePdfExport = false;
                this._pdfExported.next(true);
            }, 50);
        }, 10);
    }

    public exportPDF(): Promise<Group> {
        if (this.pdf == null) {
            throw Error(`Expected setPdf to be called before savePdf.`);
        }

        return this.pdf.export();
    }
}
