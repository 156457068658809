import { NgModule } from '@angular/core';
import { DashboardDisplayService } from './data/service/dashboard-display.service';
import { EstimateService } from './data/service/estimate.service';
import { ExportService } from './data/service/export.service';
import { IndustryPdfExportService } from './data/service/industry-pdf-export.service';
import { NotificationService } from './data/service/notification.service';
import { TitleService } from './data/service/title.service';
import { DataStore } from './data/store/data.store';
import { ErrorService } from './error/error.service';
import { HelpService } from './help/help.service';
import { IsNotSubscriberGuard } from './is-not-subscriber.guard';
import { IsSubscriberGuard } from './is-subscriber.guard';
import { UserStore } from './user/store/user.store';

const declarationsAndExports: any[] = [

];

@NgModule({
    declarations: [...declarationsAndExports],
    entryComponents: [],
    exports: [...declarationsAndExports],
    imports: [
    ],
    providers: [
        UserStore,
        DataStore,
        ExportService,
        IndustryPdfExportService,
        HelpService,
        TitleService,
        NotificationService,
        ErrorService,
        IsSubscriberGuard,
        IsNotSubscriberGuard,
        EstimateService,
        DashboardDisplayService
    ]
})
export class NavigatorModule { }
