import { ApiClient } from '@concurrency/angular';
import { Observable } from 'rxjs/internal/Observable';
import { IntlData } from 'src/app/_api/responses/intl-data.response';
import { environment } from 'src/environments/environment';
import { Auth0ApiClient } from '../api.client';
import { SuggestionResponse } from '../responses/suggestion.response';
import { ErpSupplymetryDetailData } from '../responses/erp.supplymentary.data';

export class IntlErpClient extends Auth0ApiClient {
    protected baseUrl = `${environment.urls.navigatorApi}/api`;

    public read(countryId: number, dataAsOf: string): Observable<IntlData[]> {
        return this.get(`IntlErp?countryId=${countryId}&dataAsOf=${dataAsOf}`);
    }

    public GetUsErps(dataAsOf: string): Observable<SuggestionResponse[]> {
        return this.get(`IntlErp/USErps?dataAsOf=${dataAsOf}`);
    }


    public GetERPSupplymentartData(dataAsOf: string): Observable<ErpSupplymetryDetailData> {
        return this.get(`IntlErp/getSupplmentaryData?dataAsOf=${dataAsOf}`);
    }



}
