import { Util } from '@concurrency/core';
import { SelectionType } from 'src/app/_api/enums/selection-type';
import { Country } from 'src/app/_api/responses/country.response';
import { InputType } from 'src/app/_api/responses/input.response';
import { ComboboxDataType } from 'src/app/_navigator/data/enum/combobox-data-type.enum';
import { Scenario } from 'src/app/_navigator/data/model/scenario.model';
import { Suggestion } from 'src/app/_navigator/data/model/suggestion.model';
import { NumberFormatUtil } from 'src/app/_navigator/data/util/number-format.util';

export class IntlEstimateUtil {
    public static asCashFlowName(country: Country): string {
        return `${country.CountryName} (${country.Currency.CurrencyName} - ${country.Currency.CurrencyAcronym})`;
    }

    public static countryAsSuggestion(country: Country, useCashFlowName: boolean = false): Suggestion<string> {
        let countryName = country.CountryName;

        if (useCashFlowName) {
            countryName = Object.keys(country).length !== 0 ? this.asCashFlowName(country) : '';
        }

        return {
            id: country.CountryId,
            name: countryName,
            type: ComboboxDataType.Country,
            value: countryName
        };
    }

    public static intlDataAsSuggestion(
        value: number,
        context: string,
        dataAsOf: string,
        source: string,
        isPercentage: boolean = true
    ): Suggestion<number> {
        const name = isPercentage ?
            `${NumberFormatUtil.numberWithCommas(value, 2)}% - ${context}` : `${NumberFormatUtil.numberWithCommas(value, 2)} - ${context}`;

        return {
            context,
            dataAsOf,
            name,
            source,
            value: Util.round(value, 2)
        };
    }

    public static selectionAsStringOrUndefined(scenario: Scenario, selection: SelectionType): string | undefined {
        const selectionNumber = scenario.getValueOrNaN(selection, InputType.None);

        return Number.isNaN(selectionNumber) || selectionNumber == null ? undefined : NumberFormatUtil.numberWithCommas(selectionNumber);
    }

    public static getCostOfDebtSelection(
        useFisherEffect: boolean,
        useSingleCountryKd: boolean,
        useYsModelOnly: boolean,
        kdSelection: SelectionType,
        kdFisherEffectSelection: SelectionType
    ): SelectionType {
        let result: SelectionType;

        if (useSingleCountryKd) {
            result = useFisherEffect ? SelectionType.KdSingleCountryFisherEffect : SelectionType.KdSingleCountryCapm;
        } else {
            if (useYsModelOnly) {
                result = useFisherEffect ? SelectionType.CostOfDebtFisherEffectYieldSpread : SelectionType.CostOfDebtYieldSpread;
            } else {
                result = useFisherEffect ? kdFisherEffectSelection : kdSelection;
            }
        }

        return result;
    }
}
