export const Columns: any = {
    0: 'D',
    1: 'E',
    2: 'F',
    3: 'G',
    4: 'H',
    5: 'I',
    6: 'J',
    7: 'K',
    8: 'L',
    9: 'M',
    10: "N",
    11: "O",
    12: "P",
    13: "Q",
    14: "R",
    15: "S",
    16: "T",
    17: "U",
    18: "V",
    19: "W"
};
