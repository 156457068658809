import { Moment } from 'moment';

export class ResetTexts {
    public static changeValuationDate(day: Moment): string {
        // eslint-disable-next-line max-len
        return `Changing the Valuation Date will reset <b>ALL</b> equation values as of your new valuation date (${day.format('MM/DD/YY')})! Are you sure?`;
    }

    public static changeIndustry(): string {
        return 'Changing the industry will reset <b>ALL</b> industry risk premia and beta values! Are you sure?';
    }
}
