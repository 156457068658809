import { Component, Input, OnInit } from '@angular/core';
import { HelpService } from 'src/app/_navigator/help/help.service';
import { IntlTearsheetHelptext } from 'src/app/_navigator/help/intltear-sheet-helptext';
import { TearsheetHelptext } from 'src/app/_navigator/help/tearsheet-help-text';
import { Category, CategoryName } from './enums/category';
import { CompositeData } from './models/composite-data.model';
import { FlatenMtericCategory, MetricCategory } from './models/metric-category.model';

@Component({
    selector: 'metric-category',
    templateUrl: './metric-category.component.html'
})
export class MetricCategoryComponent implements OnInit {
    @Input() public metricCategory!: FlatenMtericCategory;
    @Input() public compositeData!: CompositeData;
    @Input() public inPDF = false;
    @Input() public region!: string;

    public medianLabel = 'Median';
    public sicCompositeLabel = 'Industry Composite';
    public largeCompositeLabel = 'Large Composite';
    public smallCompositeLabel = 'Small Composite';
    public highFinancialRiskLabel = 'High-Financial-Risk';
    public displaySection = true;
    constructor(
        public helpService: HelpService
    ) { }

    public ngOnInit(): void {


        if (this.metricCategory.metricCategoryId === Category.ReturnRatios) {

            var metricData = this.metricCategory.metrics.metricData;
            metricData[0][0] = `${this.medianLabel}`;
            metricData[1][0] = `${this.sicCompositeLabel}`;
            metricData[2][0] = `${this.largeCompositeLabel}`;
            metricData[3][0] = `${this.smallCompositeLabel}`
            metricData[4][0] = `${this.highFinancialRiskLabel}`

            // this.medianLabel = `${this.medianLabel} (${this.compositeData.numberOfCompanies})**`;
            // this.sicCompositeLabel = `${this.sicCompositeLabel} (${this.compositeData.numberOfCompanies})**`;
            // this.largeCompositeLabel = `${this.largeCompositeLabel} (${this.compositeData.largeComposite})**`;
            // this.smallCompositeLabel = `${this.smallCompositeLabel} (${this.compositeData.smallComposite})**`;
            // this.highFinancialRiskLabel = this.compositeData.highFinancialRisk < 5
            //     ? `${this.highFinancialRiskLabel} (-)**`
            //     : `${this.highFinancialRiskLabel} (${this.compositeData.highFinancialRisk})**`;
        }

        const nonUSBased = this.region.toLowerCase() !== 'united states';
        const isFamaFrench = this.metricCategory.metricCategoryId === Category.FamaFrenchFiveFactor ||
            this.metricCategory.metricCategoryId === Category.FamaFrenchThreeFactor;

        if (isFamaFrench && nonUSBased) {
            this.displaySection = false;
        }
    }
    public metriccategoryname(metriccategoryname: CategoryName): void {
        if (this.region.toLowerCase() !== 'united states') {
            switch (metriccategoryname) {
                case CategoryName.Betas:
                    return this.helpService.open(CategoryName.BetasLevered, IntlTearsheetHelptext.BetasLevered);
                case CategoryName.BetasLevered:
                    return this.helpService.open(CategoryName.BetasLevered, IntlTearsheetHelptext.BetasLevered);
                case CategoryName.BetasUnlevered:
                    return this.helpService.open(CategoryName.BetasUnlevered, IntlTearsheetHelptext.BetasUnlevered);
                case CategoryName.CostOfDebt:
                    return this.helpService.open(CategoryName.CostOfDebt, IntlTearsheetHelptext.CostOfDebts);
                case CategoryName.CostOfEquityCapital:
                    return this.helpService.open(CategoryName.CostOfEquityCapital, IntlTearsheetHelptext.CostOfEquityCapital);
                case CategoryName.EnterpriseValuationMultiples:
                    return this.helpService.open(CategoryName.EnterpriseValuationMultiples, IntlTearsheetHelptext.EntpValMutliples);
                case CategoryName.EquityValuationMultiples:
                    return this.helpService.open(CategoryName.EquityValuationMultiples, IntlTearsheetHelptext.EquValMutliples);
                case CategoryName.GrowthRates:
                    return this.helpService.open(CategoryName.GrowthRates, IntlTearsheetHelptext.GrowthRates);
                case CategoryName.ReturnRatios:
                    return this.helpService.open(CategoryName.ReturnRatios, IntlTearsheetHelptext.ReturnRatios);
                case CategoryName.LiquidtyandProfitability:
                    return this.helpService.open(CategoryName.LiquidityRatio, IntlTearsheetHelptext.LiquidityRatio);
                case CategoryName.LiquidityRatio:
                    return this.helpService.open(CategoryName.LiquidityRatio, IntlTearsheetHelptext.LiquidityRatio);
                case CategoryName.ProfitabilityRatio:
                    return this.helpService.open(CategoryName.ProfitabilityRatio, IntlTearsheetHelptext.ProfitabilityRatio);
                case CategoryName.LeverageRatios:
                    return this.helpService.open(CategoryName.LeverageRatios, IntlTearsheetHelptext.LeverageRatio);
                case CategoryName.Wacc:
                    return this.helpService.open(CategoryName.Wacc, IntlTearsheetHelptext.WACC);
                case CategoryName.FamaFrenchFiveFactor:
                    return undefined;
                case CategoryName.WaccCalculations:
                    return this.helpService.open(CategoryName.WaccCalculations, IntlTearsheetHelptext.WaccCalculations);
                default:
                    throw Error(`Expected a help type to be defined for MetricCategory name: ${CategoryName[metriccategoryname]}`);
            }
        } else {
            switch (metriccategoryname) {
                case CategoryName.Betas:
                    return this.helpService.open(CategoryName.BetasLevered, TearsheetHelptext.BetasLevered);
                case CategoryName.BetasLevered:
                    return this.helpService.open(CategoryName.BetasLevered, TearsheetHelptext.BetasLevered);
                case CategoryName.BetasUnlevered:
                    return this.helpService.open(CategoryName.BetasUnlevered, TearsheetHelptext.BetasUnlevered);
                case CategoryName.CostOfDebt:
                    return this.helpService.open(CategoryName.CostOfDebt, TearsheetHelptext.CostOfDebts);
                case CategoryName.CostOfEquityCapital:
                    return this.helpService.open(CategoryName.CostOfEquityCapital, TearsheetHelptext.CostOfEquityCapital);
                case CategoryName.EnterpriseValuationMultiples:
                    return this.helpService.open(CategoryName.EnterpriseValuationMultiples, TearsheetHelptext.EntpValMutliples);
                case CategoryName.EquityValuationMultiples:
                    return this.helpService.open(CategoryName.EquityValuationMultiples, TearsheetHelptext.EquValMutliples);
                case CategoryName.GrowthRates:
                    return this.helpService.open(CategoryName.GrowthRates, TearsheetHelptext.GrowthRates);
                case CategoryName.ReturnRatios:
                    return this.helpService.open(CategoryName.ReturnRatios, TearsheetHelptext.ReturnRatios);
                case CategoryName.LiquidityRatio:
                    return this.helpService.open(CategoryName.LiquidityRatio, TearsheetHelptext.LiquidityRatio);
                case CategoryName.LiquidtyandProfitability:
                    return this.helpService.open(CategoryName.LiquidityRatio, TearsheetHelptext.LiquidityRatio);
                case CategoryName.ProfitabilityRatio:
                    return this.helpService.open(CategoryName.ProfitabilityRatio, TearsheetHelptext.ProfitabilityRatio);
                case CategoryName.LeverageRatios:
                    return this.helpService.open(CategoryName.LeverageRatios, TearsheetHelptext.LeverageRatio);
                case CategoryName.Wacc:
                    return this.helpService.open(CategoryName.Wacc, TearsheetHelptext.WACC);
                case CategoryName.FamaFrenchFiveFactor:
                    return this.helpService.open(CategoryName.FamaFrenchFiveFactor, TearsheetHelptext.FamaFrenchFiveFactor);
                case CategoryName.WaccCalculations:
                    return this.helpService.open(CategoryName.WaccCalculations, TearsheetHelptext.WaccCalculations);
                default:
                    throw Error(`Expected a help type to be defined for MetricCategory name: ${CategoryName[metriccategoryname]}`);
            }
        }
    }
}
