import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { combineLatest } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { SelectionType } from 'src/app/_api/enums/selection-type';
import { CountryCreditRating } from 'src/app/_api/responses/country-credit-rating.response';
import { CountryRiskPremia } from 'src/app/_api/responses/country-risk-premia.response';
import { Country } from 'src/app/_api/responses/country.response';
import { EstimateSummary } from 'src/app/_api/responses/estimate-summary.response';
import { InputType } from 'src/app/_api/responses/input.response';
import { CcrState } from 'src/app/_navigator/ccr-store/ccr-state';
import { CountryListState } from 'src/app/_navigator/country-list-store/country-list-state';
import { CountryRiskPremiaListState } from 'src/app/_navigator/country-risk-premia-list-store/country-risk-premia-list-state';
import { Estimate } from 'src/app/_navigator/data/model/estimate.model';
import { TableData } from 'src/app/_navigator/data/model/table-data.model';
import { DynamicTextSummaryState } from 'src/app/_navigator/dynamic-text-store/dynamic-text-summary-state';
import { EstimateState } from 'src/app/_navigator/estimate-store/estimate-state';
import { EstimateSummaryState } from 'src/app/_navigator/estimate-summary/estimate-summary-state';
import { DynamicTextSummary } from 'src/app/international-estimate/dynamic-text/model/dynamic-text-summary';
import { SummaryType } from '../data/enum/summary-type';
import { SummaryText } from '../data/model/summary-text';
import { DynamicText } from '../dynamic-text/model/dynamic-text';
import { EquationGroup } from '../equation/equation-group.model';
import { EquationGroupUtil } from '../equation/equation-group.util';
import { CrpTableText } from '../summary-table/crp-table-text';
import { CrpTableUtil } from '../summary-table/crp-table.util';
import { RegionTableUtil } from '../summary-table/region-table.util';

@Component({
    selector: 'coe-summary-conclusion',
    templateUrl: './coe-summary-conclusion.component.html'
})
export class CoeSummaryConclusionComponent implements OnInit {
    public countryPremia!: TableData;
    public isReady = false;
    public crpTables: TableData[] = [];
    public dynamicText: DynamicText[] = [];
    public showCrp = true;
    public equations: EquationGroup[] = [];
    public isKeAvailable = false;
    public showFisherEffect = false;
    public CrpTableText = CrpTableText;
    public SummaryText = SummaryText;
    public alphas: any[] = [];
    @Select(EstimateSummaryState.get) public estimateSummarySelector!: Observable<EstimateSummary | undefined>;
    @Select(EstimateState.get) public estimateSelector!: Observable<Estimate | undefined>;
    @Select(CountryRiskPremiaListState.get) public countryRiskPremiaSelector!: Observable<CountryRiskPremia[] | undefined>;
    @Select(DynamicTextSummaryState.get) public dynamicTextSummarySelector!: Observable<DynamicTextSummary | undefined>;
    @Select(CcrState.get) public ccrSelector!: Observable<CountryCreditRating | undefined>;

    constructor(private store: Store) { }

    private initialize(
        estimateSummary: EstimateSummary | undefined,
        crp: CountryRiskPremia[] | undefined,
        investeeCountry: Country | undefined,
        estimate: Estimate,
        cashFlowCountry: Country | undefined,
        countryOfInput: Country | undefined,
        ccr: CountryCreditRating | undefined
    ): void {
        if (crp == null || investeeCountry == null || cashFlowCountry == null || countryOfInput == null || ccr == null || estimateSummary == null) {
            return;
        }

        this.isKeAvailable = true;
        const inflationInvestor = estimate.Scenarios[0].getSelection(SelectionType.InflationInvestor, InputType.None);
        const inflationInvestee = estimate.Scenarios[0].getSelection(SelectionType.InflationInvestee, InputType.None);

        if ((cashFlowCountry.Currency.CurrencyId !== countryOfInput.Currency.CurrencyId)
            && (!inflationInvestor.Value || !inflationInvestee.Value)
        ) {
            return;
        }

        this.showFisherEffect = true;
        this.showCrp = countryOfInput.CountryId !== estimate.InvesteeCountryId;

        if (this.showCrp) {
            this.countryPremia = CrpTableUtil.getKeCountryPremiaTable(
                estimate.Scenarios[0],
                ccr,
                investeeCountry.CountryName,
                cashFlowCountry,
                countryOfInput
            );
            const avgMedianCrpTable = RegionTableUtil.getAvgMedianCrpTable(
                crp,
                estimate,
                cashFlowCountry.Currency.CurrencyId,
                countryOfInput.Currency.CurrencyId,
                SummaryType.CostOfEquity
            );
            this.alphas = [estimateSummary.InvesteeCountryId, estimateSummary.InvestorCountryId, estimateSummary.ValuationDate];
            this.crpTables = RegionTableUtil.getRegionPremiaTable(avgMedianCrpTable, estimate.Scenarios[0]);
            this.isReady = true;
        } else {
            this.equations = EquationGroupUtil.getCostOfEquityConclusionGroup(estimate, cashFlowCountry, countryOfInput);
        }
    }

    public ngOnInit(): void {
        this.estimateSelector.onceDefined((estimate) => {
            const scenario = estimate.Scenarios[0];
            const countryOfInputs = estimate.getInput(InputType.CountryOfInputs);
            const cashFlowCountry = estimate.getInput(InputType.CashFlows);
            const rf = scenario.getSelection(SelectionType.IntlRiskFreeRate, InputType.None);
            const erp = scenario.getSelection(SelectionType.IntlEquityRiskPremium, InputType.None);
            const beta = scenario.getSelection(SelectionType.IntlBeta, InputType.None);

            if (countryOfInputs.Value == null
                || cashFlowCountry.Value == null
                || rf.Value == null
                || erp.Value == null
                || beta.Value == null
            ) {
                return;
            }

            combineLatest([
                this.store.select(CountryListState.getById(estimate.InvesteeCountryId)),
                this.countryRiskPremiaSelector,
                this.store.select(CountryListState.getById(cashFlowCountry.Value)),
                this.store.select(CountryListState.getById(countryOfInputs.Value)),
                this.ccrSelector,
                this.estimateSummarySelector
            ]).pipe(
                map((x) => ({
                    investeeCountry: x[0],
                    crp: x[1],
                    cashFlowCountry: x[2],
                    countryOfInput: x[3],
                    ccr: x[4],
                    estimateSummary: x[5]
                }))
            ).onceDefined((data) => {
                this.initialize(data.estimateSummary, data.crp, data.investeeCountry, estimate, data.cashFlowCountry, data.countryOfInput, data.ccr);
            });
        });

        this.dynamicTextSummarySelector.onceDefined((dt) => {
            if (dt.CostOfEquitySummaryConclusionText == null) {
                return;
            }
            this.dynamicText = dt.CostOfEquitySummaryConclusionText;
        });
    }
}
