export enum TabRoute {
    Inputs = 'estimate/inputs',
    Studies = 'estimate/studies',
    Results = 'estimate/results',
    TearSheet = 'estimate/tear-sheet',
    BenchmarkingInputs = 'industry-benchmarking/inputs',
    BenchmarkingUSI = 'industry-benchmarking/usi',
    BenchmarkingTearsheet = 'industry-benchmarking/tear-sheet',
    InternationalInputs = 'international-estimate/inputs',
    CostOfEquityInputs = 'international-estimate/coe-inputs',
    CountryRiskPremiaSummary = 'international-estimate/crp-summary',
    FisherEffect = 'international-estimate/fisher-effect',
    CostOfEquitySummary = 'international-estimate/coe-summary',
    WaccInputs = 'international-estimate/wacc-inputs',
    CostOfDebtSummary = 'international-estimate/cod-summary',
    WaccSummary = 'international-estimate/wacc-summary',
    IntlBenchmarkingTearsheet = 'intlindustry-benchmarking/tear-sheet',
    IntlBenchmarkingInputs = 'intlindustry-benchmarking/inputs'
}
