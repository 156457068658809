
import { Component, Input } from '@angular/core';
import { CommonUserInputComponent } from './common-user-input.component';

@Component({
    selector: 'common-checkbox',
    templateUrl: './common-checkbox.component.html'
})
export class CommonCheckboxComponent extends CommonUserInputComponent {
    @Input() public id: string = Math.random().toString(36).substring(2);
}
