import { Component, OnInit } from '@angular/core';
import { TermsComponent } from './term-model.component';
import { UserClient } from 'src/app/_api/clients/user.client';
import { AcceptVersionData, TermsAndConditionsVersions } from 'src/app/_api/responses/user.response';
import { UserStore } from 'src/app/_navigator/user/store/user.store';

@Component({ templateUrl: './accept-terms-modal.component.html' })
export class AcceptTermsModalComponent {
    public acceptTerms = false;
    public acceptMarketing = false;
    public readyForPurchase?: boolean;
    public isTermsValid?: boolean;
    public acceptTermsData?: TermsAndConditionsVersions
    public acceptData!: AcceptVersionData

    constructor(private userStore: UserStore) { }


    // public ngOnInit() {
    //     const request = this.userClient.getTermsAndCondition();
    //     request.once((data: TermsAndConditionsVersions) => {
    //         if (data) {
    //             this.acceptData = { versonNumber: data.TermsAndConditionsVersionNumber, marketingOptin: false };
    //             this.acceptTermsData = data;
    //             this.isTermsValid = true;

    //         }
    //     });
    // }

    public setAcceptance(): void {
        if (this.acceptTerms) {
            this.acceptData = { versonNumber: this.userStore.AcceptTermsData.TermsAndConditionsVersionNumber, marketingOptin: this.acceptMarketing };
            this.readyForPurchase = this.acceptMarketing;
        } else {
            this.readyForPurchase = undefined;
            this.acceptData = { versonNumber: this.userStore.AcceptTermsData.TermsAndConditionsVersionNumber, marketingOptin: false };

        }
    }
}
