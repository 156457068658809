import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserStore } from '../_navigator/user/store/user.store';

@Component({ template: '' })
export class CustomerLoginComponent implements OnInit {
    constructor(
        private userStore: UserStore,
        private route: ActivatedRoute
    ) { }

    private redirect(): void {
        this.route.queryParamMap.once((params) => {
            const policy = params.get('policy');
            const email = params.get('email') || undefined;
            if (policy === 'signup') {
                this.userStore.signupCustomer(email);
            } else {
                this.userStore.loginCustomer();
            }
        });
    }

    public ngOnInit(): void {
        this.userStore.user.subscribe((user) => {
            if (user == null || user.hasExpired() === false) {
                this.redirect();
            }
        });
    }
}
