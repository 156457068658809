// eslint-disable  max-len
import { Component, OnInit } from '@angular/core';
import { saveAs } from 'file-saver';
import { FilesClient } from 'src/app/_api/clients/files.client';
import { ResourceFileType } from 'src/app/_api/enums/resource-file-type';
import { HelpText } from 'src/app/_navigator/help/help-text';
import { ProductType } from 'src/app/_navigator/user/enum/product-type';
import { SubscriptionLevel } from 'src/app/_navigator/user/enum/subscription-level';
import { UserStore } from 'src/app/_navigator/user/store/user.store';
import { ContainerTypeEnum } from './enum/container-type.enum';
import { CocSections, Module, Section, UserAccessType } from './resources-library-model';
import { ResourceLibraryService } from './resources-library.service';

@Component({
    selector: 'resources-library',
    templateUrl: 'resources-library.component.html'
})
export class ResourcesLibraryComponent implements OnInit {

    public static openFile(data: string, fileName: string): void {
        const binaryString = window.atob(data);
        const binaryLen = binaryString.length;
        const bytes = new Uint8Array(binaryLen);
        for (let i = 0; i < binaryLen; i++) {
            const ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        if (fileName.includes('.html')) {
            const blob = new Blob([bytes], { type: 'text/html' });
            const fileUrl = window.URL.createObjectURL(blob);
            window.open(fileUrl);
        } else if (fileName.includes('.pdf')) {
            const blob = new Blob([bytes], { type: 'application/pdf' });
            const fileUrl = window.URL.createObjectURL(blob);
            window.open(fileUrl);
        } else if (fileName.includes('.xlsx')) {
            const blob = new Blob([bytes], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            saveAs(blob, fileName);
        }
    }
    private sections: CocSections[] = [] as CocSections[];

    public userSubscriptionData: UserAccessType[] = new Array();
    public selectedTab = 0;
    public modules: Module[] = [{
        title: 'U.S. Cost of Capital Module',
        sections: [] as Section[],
        folder: '',
        note: `
        <div class="header">Note:</div>
        <p>This section contains theory, methodology, and examples of using the data from the previously published<em> Valuation Handbook &ndash; U.S. Guide to Cost of Capital</em>. Also included is supplementary data from the CRSP Deciles Size Study and the Risk Premium Report Study, and Debt Betas.</p>
        <p>The Resources Library does not include any long-form data tables, industry risk premia, or size premia. To access this data, click on &ldquo;Dashboard&rdquo; in the left navigation panel, and then click to launch the &ldquo;U.S. Cost of Capital Module.&rdquo;</p>
        `,
        productType: ProductType.Coc
    }, {
        title: 'U.S. Industry Benchmarking Module',
        sections: [] as Section[],
        folder: 'US-Industry-Resources-Section',
        note: `
        <div class="header">Note:</div>
        <p>This section contains theory, methodology, and examples of using the data from the previously published&nbsp;<em>Valuation Handbook - U.S. Industry Cost of Capital</em>. Also included is supplementary data from Appendices A and B.</p>
        <p>The Resource Library does not include any industry "tearsheets". To access the industry data, click on "Dashboard" in the left navigation panel, and then click to launch the "U.S. Industry Benchmarking Module."</p>
        `,
        productType: ProductType.Usi
    }, {
        title: `International Cost of Capital Module`,
        sections: [] as Section[],
        folder: ``,
        note: `
        <div class="header">Note:</div>
        <p>This section contains the theory, methodology, and examples of using the data from the previously published&nbsp;<em>Valuation Handbook - International Guide to Cost of Capital</em>.</p>
        <p>To access the data, click on "Dashboard" in the left navigation panel, and then click to launch the "International Cost of Capital Module."</p>
        `,
        productType: ProductType.Ine
    }, {
        title: 'International Industry Benchmarking Module',
        sections: [] as Section[],
        folder: 'US-Industry-Resources-Section',
        note: `
        <div class="header">Note:</div>
        <p>This section contains the theory, methodology, and examples of using the data from the previously published&nbsp;<em>Valuation Handbook - International Industry Cost of Capital</em>.</p>
        <p>The Resource Library does not include any industry "tearsheets". To access the industry data, click on "Dashboard" in the left navigation panel, and then click to launch the "International Industry Benchmarking Module."</p>
        `,
        productType: ProductType.Ini
    }, {
        title: 'Company-level Beta Module',
        sections: [] as Section[],
        folder: '',
        note: `
        <div class="header">Note:</div>
        <p>This section contains the theory, methodology, and examples of using the data from the previously published&nbsp;<em>Valuation Handbook - Beta</em>.</p>
        <p>To access the data, click on "Dashboard" in the left navigation panel, and then click to launch the "Company-level Beta."</p>
        `,
        productType: ProductType.Bta
    }
    ];
    public currentModule = this.modules[0];
    public helpText = HelpText;
    public isActive = true;
    public constructor(private userStore: UserStore, private filesClient: FilesClient, private resourceLibraryService: ResourceLibraryService) {

    }

    public ngOnInit(): void {
        this.resourceLibraryService.getResourceContent(ResourceFileType.ResourceLibrary);
        this.resourceLibraryService.sections.onceDefined((sections) => {
            this.sections = sections;
            console.log(this.sections);

            this.modules[0].sections = (this.sections.length > 0 && this.sections[0].usCoc.length > 0) ? this.sections[0].usCoc : [] as Section[];
            this.modules[1].sections = (this.sections.length > 1 && this.sections[1].usIcoc.length > 0) ? this.sections[1].usIcoc : [] as Section[];
            this.modules[2].sections = (this.sections.length > 2 && this.sections[2].intlCoc.length > 0) ? this.sections[2].intlCoc : [] as Section[];
            this.modules[3].sections = (this.sections.length > 3 && this.sections[3].IntlICoc.length > 0) ? this.sections[3].IntlICoc : [] as Section[];
            this.modules[4].sections = (this.sections.length > 4 && this.sections[4].Beta.length > 0) ? this.sections[4].Beta : [] as Section[];

        });
        this.userStore.user.onceDefined((user) => {
            this.isActive = user.IsActive;
            user.subscriptionDetails.forEach((x) => {
                const accessType: UserAccessType = {
                    productType: x.productType,
                    subscriptionLevel: x.level
                };
                this.userSubscriptionData.push(accessType);
            });
        });
    }

    public toggleCollapse(category: Section): void {
        if (category.collapse === true || category.collapse == null) {
            category.collapse = false;
        } else {
            category.collapse = true;
        }
    }

    public canAccessSection(section: Section): boolean {
        const currentSectionAccessLevel = this.userSubscriptionData.find((x) => x.productType === this.currentModule.productType);
        // if they don't have a subscription, they should still be able to view chapter names

        if (section.subscriptionLevels == null) {
            return false;
        }
        if (section.subscriptionLevels.includes(SubscriptionLevel.All) && this.isActive) {
            return true;
        }

        if (currentSectionAccessLevel == null) {
            return false;
        }

        if (section.subscriptionLevels.includes(currentSectionAccessLevel.subscriptionLevel) && this.isActive) {
            return true;

        }
        return false;
    }

    public getResourceFile(fileName: string): void {
        this.filesClient.getResourceFile(fileName).subscribe((data) => {
            ResourcesLibraryComponent.openFile(data, fileName);
        });
    }

    public getStorageFile(fileName: string): void {
        this.filesClient.getStorageFile(fileName, ContainerTypeEnum.resources).subscribe((data) => {
            ResourcesLibraryComponent.openFile(data, fileName);
        });
    }

    public getFile(fileName: string): void {
        this.filesClient.getFile(fileName).subscribe((data) => {
            ResourcesLibraryComponent.openFile(data, fileName);
        });
    }
}
