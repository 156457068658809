import { Component, ComponentFactoryResolver } from '@angular/core';
import { SelectionType } from 'src/app/_api/enums/selection-type';
import { EquationType } from 'src/app/_api/responses/equation.response';
import { InputType } from 'src/app/_api/responses/input.response';
import { Equation, EquationParameter } from 'src/app/_navigator/data/model/equation.model';
import { OperandUtility } from '../operand.utility';
import { ScenarioMultiStudyComponent } from '../scenario-multi-study.component';
import { ComparativeRiskComponent } from './comparative-risk.component';
import { RiskTableComponent } from './risk-table.component';
import { Risk } from './risk.model';

@Component({ templateUrl: '../scenario-study.component.html' })
export class RiskBuildupThreeComponent extends ScenarioMultiStudyComponent {
    protected equationType = EquationType.RprsBuildup3;
    protected hasRegressionToggle = true;
    protected additionalEquations = [
        {
            type: EquationType.RprsBuildup3ComparativeRisk,
            name: 'Comparative Risk Study Adjustment',
            component: ComparativeRiskComponent
        }
    ];

    public dataTable = RiskTableComponent;

    constructor(
        protected resolver: ComponentFactoryResolver
    ) { super(resolver); }

    private buildEquation(risk: Risk): Equation {
        const costOfEquityCapital = OperandUtility.getCostOfEquityCapital(this.equationType, risk.implicationType);
        const riskOperand = OperandUtility.getBuildup3RiskPremium(risk);

        const ke: EquationParameter = { item: costOfEquityCapital, operator: '=', canEdit: false };
        const rf: EquationParameter = { item: OperandUtility.riskFreeRate, operator: '+', canEdit: true };
        const rp: EquationParameter = { item: riskOperand, operator: '+', canEdit: true };
        const erpa: EquationParameter = { item: OperandUtility.erpAdjusted, operator: '', canEdit: true };

        return {
            name: risk.name,
            equationType: this.equationType,
            implicationType: risk.implicationType,
            parameters: [ke, rf, rp, erpa],
            calculate: (scenario) => {
                const equation = scenario.getEquation(this.equationType, risk.implicationType);
                const riskFreeRate = scenario.getValueOrNaN(SelectionType.RiskFreeRate, InputType.None);
                const erpAdjustment = scenario.getValueOrNaN(SelectionType.EquityRiskPremiumAdjustment, InputType.None);
                const riskPremium = scenario.getValueOrNaN(risk.selectionType, risk.inputType);

                const result = riskFreeRate + erpAdjustment + riskPremium;
                equation.Result = result || undefined;

                return equation.Result != null;
            }
        };
    }

    public buildEquations(): Equation[] {
        const risks = OperandUtility.getBuildup3Risks();
        return risks.map((risk) => this.buildEquation(risk));
    }
}
