import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Spinner } from '@concurrency/angular';

@Component({
    selector: 'beta',
    templateUrl: './beta.component.html',
    styleUrls: ['./beta.component.scss']
})
export class BetaComponent {

    constructor(
        public route: ActivatedRoute,
        public router: Router,
        public spinner: Spinner) { }



}
