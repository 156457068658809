import { OnInit, Directive } from '@angular/core';
import { EquationType } from 'src/app/_api/responses/equation.response';
import { Equation } from 'src/app/_navigator/data/model/equation.model';
import { BaseStudyComponent } from './base-study.component';


@Directive()
export abstract class StudyComponent extends BaseStudyComponent implements OnInit {
    protected abstract equationType: EquationType;

    public isKeyShown = true;
    public equation?: Equation;
    public equations: Equation[] = [];
    public equationTypeName = '';
    public name = false;
    public abstract buildEquation(): Equation;

    public ngOnInit(): void {
        super.ngOnInit();
        this.equation = this.buildEquation();
        if (this.equation) {
            this.equations = [this.equation];
            this.getEquationTypeName(this.equation.equationType);
        }
    }

    public showDataTable(): void {
        this.primeManager.openDialog(this.dataTable);
    }
    public getEquationTypeName(type: EquationType): void {
        switch (type) {
            case EquationType.CrspCapmSizeStudy:
                this.equationTypeName = 'CAPM + Size Premium';
                break;
            case EquationType.CrspBuildup:
                this.equationTypeName = 'Build-up';
                break;
            case EquationType.HfrsCapmHfr:
                this.equationTypeName = 'CAPM + HFR Premium Over CAPM';
                this.name = true;
                break;
            case EquationType.HfrsBuildupHfr:
                this.equationTypeName = 'Build-up HFR';
                this.name = true;
                break;
            default:
                break;
        }
    }
}
