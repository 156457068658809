import * as moment from 'moment';

import { Component, OnInit, ViewChild } from '@angular/core';
import { List, Spinner } from '@concurrency/angular';
import { Util } from '@concurrency/core';
import { Dialog } from 'primeng/dialog';
import { USCompanyClient } from 'src/app/_api/clients/uscompany.client';
import { EstimateIndustries } from 'src/app/_api/responses/estimate-industries.response';
import { DataStore } from 'src/app/_navigator/data/store/data.store';
import { BenchmarkingUtil } from 'src/app/benchmarking/benchmarking.util';
import { BetaCompany, BetaCompanyData, BetaCompanyList, BetaCompanyListItem, BetaCompanyType, BetaCompanyTypeName, FechedCompanyData } from './beta-editor-company-list.model';

@Component({
    templateUrl: './beta-editor-company-list.component.html',
    styleUrls: ['./beta-editor-company-list.component.scss']
})
export class BetaEditorCompanyListComponent implements OnInit {
    public moment = moment;
    public Util = Util;
    public display = true;
    public region = 'United States';
    public currency = 'USD';
    public companies!: BetaCompanyData;
    public tabname!: string;
    public companyList!: List<BetaCompany>;
    public industries!: EstimateIndustries[];
    public companyType: BetaCompanyType = BetaCompanyType.fullInformation;
    public companyListHeaders: BetaCompanyListItem[] = BetaCompanyList.betaCompanyList;
    public companyTypeName: BetaCompanyTypeName = BetaCompanyTypeName.fullInformation;
    public dialogHeader!: string;
    public industry!: EstimateIndustries;
    public dataAsOf!: string;
    public fachedCompanyDataList: FechedCompanyData[] = [];
    public code!: string;

    @ViewChild(Dialog) public dialog!: Dialog;

    constructor(
        protected dataStore: DataStore,
        private usCompanyClient: USCompanyClient,
        private spinner: Spinner
    ) { }

    private setDialogHeader(): void {
        const dataAsOf = this.companies.Companies.length > 0 ? this.companies.DataAsOf : this.dataAsOf;
        this.dialogHeader = BenchmarkingUtil.isSic(dataAsOf, this.region)
            ? `Beta Company List - SIC ${this.industry.SicCode} Data as of ${moment(dataAsOf).format('MM/DD/YYYY')}`
            : `Beta Company List  - GICS ${this.industry.SicCode} Data as of ${moment(dataAsOf).format('MM/DD/YYYY')}`;
    }

    public ngOnInit(): void {
        // this.dataStore.updateCompanyData(this.dataStore.estimate.onceDefined)
        if (this.companies.Companies.length) {
            this.fachedCompanyDataList.push({
                DataAsOf: this.dataAsOf,
                Data: this.companies,
                SicId: this.industries[0].SicId,
                CompanyType: BetaCompanyType.fullInformation
            });
        }

        this.setindustryName(this.industries[0].SicId);
        this.setDialogHeader();
        this.code = BenchmarkingUtil.isSic(this.companies.DataAsOf, this.region)
            ? `SIC`
            : `GICS`;
        if (this.tabname === ' CRSP Deciles Size Study ') {
            this.companyListHeaders = [
                {
                    companyType: BetaCompanyType.fullInformation,
                    companyTypeName: BetaCompanyTypeName.fullInformation
                },
                {
                    companyType: BetaCompanyType.Default,
                    companyTypeName: BetaCompanyTypeName.default
                }
            ];
            this.setCompanyType(BetaCompanyType.fullInformation);
            this.setFullInformationCompanyList();
        } else if (this.tabname === ' Risk Premium Report Study ') {
            this.companyListHeaders = [

                {
                    companyType: BetaCompanyType.Default,
                    companyTypeName: BetaCompanyTypeName.default

                }
            ];
            this.setCompanyType(BetaCompanyType.Default);
            this.setDefaultCompanyList();
            this.fetchBetaCompanyData();
        } else {
            this.companyListHeaders = [
                {
                    companyType: BetaCompanyType.HighFinancialRisk,
                    companyTypeName: BetaCompanyTypeName.highFinancialRisk
                }
            ];
            this.setCompanyType(BetaCompanyType.HighFinancialRisk);
            this.setDefaultCompanyList();
            this.fetchBetaCompanyData();
        }
    }

    public setCompanyType(type: BetaCompanyType): void {
        this.companyType = type;
        this.companyTypeName = BetaCompanyList.FindBetaCompanyType(type);

    }
    public setindustryName(sicId: number): void {
        this.industry = this.industries.filter((x) => x.SicId === Number(sicId))[0];
    }

    public getFullInformationCompanyList(): void {
        if (!(this.fachedCompanyDataList.filter((x) => x.DataAsOf === this.dataAsOf && x.SicId === this.industry.SicId && x.CompanyType === this.companyType && this.tabname !== ' Risk Premium Report Study ').length > 0)) {
            const request = this.usCompanyClient.getFibCompanies(this.dataAsOf, this.industry.SicId);
            this.spinner.while(request);
            request.once((data) => {
                this.companies = data as any as BetaCompanyData;
                this.addFachedCompanyData(BetaCompanyType.fullInformation);
                this.setFullInformationCompanyList();
            }, () => {
                this.spinner.end();
            });
        } else {
            const data = this.fachedCompanyDataList.filter((x) => x.DataAsOf === this.dataAsOf && x.SicId === this.industry.SicId && x.CompanyType === this.companyType)[0].Data;
            this.companies = data;
            this.setFullInformationCompanyList();
        }
    }
    public setFullInformationCompanyList(): void {
        this.companyList = new List(this.companies.Companies, (data) => {
            if (this.companyType === BetaCompanyType.fullInformation) {
                return data.Type !== BetaCompanyType.Default;
            } else {
                return data.Type === this.companyType;
            }
        });
        this.resetList();
    }
    public setDefaultCompanyList(): void {
        this.companyList = new List(this.companies.Companies, (data) => {
            if (this.companyType === BetaCompanyType.Default) {
                return data.Type !== BetaCompanyType.HighFinancialRisk;
            } else {
                return data.Type === this.companyType;
            }
        });
        this.resetList();
    }
    public getDefaultCompanyList(): void {
        if (!(this.fachedCompanyDataList.filter((x) => x.DataAsOf === this.dataAsOf && x.SicId === this.industry.SicId && x.CompanyType === BetaCompanyType.Default).length > 0)) {
            const request = this.usCompanyClient.read(this.dataAsOf, this.industry.SicId);
            this.spinner.while(request);
            request.once((data) => {
                this.companies = data as any as BetaCompanyData;
                this.addFachedCompanyData(BetaCompanyType.Default);
                this.setDefaultCompanyList();
            }, () => {
                this.spinner.end();
            });
        } else {
            const company = this.fachedCompanyDataList.filter((x) => x.DataAsOf === this.dataAsOf && x.SicId === this.industry.SicId && x.CompanyType === BetaCompanyType.Default)[0];
            this.companies = company.Data;
            this.setDefaultCompanyList();
        }

    }

    public getDefaultCompanyGICList(): void {
        if (!(this.fachedCompanyDataList.filter((x) => x.DataAsOf === this.dataAsOf && x.SicId === this.industry.SicId && x.CompanyType === BetaCompanyType.Default).length > 0)) {
            const request = this.usCompanyClient.getGICCompanies(this.dataAsOf, this.industry.SicId, this.region, this.currency);
            this.spinner.while(request);
            request.once((data) => {
                this.companies = data as any as BetaCompanyData;
                this.addFachedCompanyData(BetaCompanyType.Default);
                this.setDefaultCompanyList();
            }, () => {
                this.spinner.end();
            });
        } else {
            const company = this.fachedCompanyDataList.filter((x) => x.DataAsOf === this.dataAsOf && x.SicId === this.industry.SicId && x.CompanyType === BetaCompanyType.Default)[0];
            this.companies = company.Data;
            this.setDefaultCompanyList();
        }

    }

    public resetList(): void {
        if (this.companyList && this.companyList.all.length) {
            this.companyList.filter('-');
        }
        this.sortBy('Name');
        this.setDialogHeader();
    }
    public addFachedCompanyData(companyType: BetaCompanyType): void {
        this.fachedCompanyDataList.push({
            DataAsOf: this.dataAsOf,
            Data: this.companies,
            SicId: this.industry.SicId,
            CompanyType: companyType
        });
    }
    public sortBy(field: string): void {
        this.companyList.sort(field);
    }
    public fetchBetaCompanyData(): void {
        if (this.companyType === BetaCompanyType.fullInformation) {
            this.getFullInformationCompanyList();
        } else {
            this.code === `SIC` ? this.getDefaultCompanyList() : this.getDefaultCompanyGICList();
        }

    }
}
