import { Action, Selector, State, StateContext } from '@ngxs/store';
import { of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { IntlBetaClient } from 'src/app/_api/clients/intlBeta.client';
import { IntlData } from 'src/app/_api/responses/intl-data.response';
import { GetIntlBetaList, ResetBetaList } from './intl-beta-list-actions';
import { Injectable } from '@angular/core';

export type IntlBetaListModel = IntlData[] | undefined;

@State<IntlBetaListModel>({
    name: 'intlBetaList',
    defaults: undefined
})

@Injectable()
export class IntlBetaListState {

    @Selector()
    public static get(state: IntlBetaListModel): IntlData[] | undefined {
        return state;
    }
    constructor(private intlBetaClient: IntlBetaClient) { }

    @Action(GetIntlBetaList)
    public getIntlBetaList(context: StateContext<IntlBetaListModel>, action: GetIntlBetaList): Observable<void> {
        return this.intlBetaClient.read(action.countryId, action.gicId, action.currencyId, action.dataAsOf).once((response) => {
            context.setState(response);
        });
    }

    @Action(ResetBetaList)
    public resetBetaList(context: StateContext<IntlBetaListModel>): Observable<void> {
        context.setState(undefined);
        return of(void 0);
    }
}
