import { Component, HostListener, OnInit } from '@angular/core';
import { HelpText } from 'src/app/_navigator/help/help-text';
import { BetaService } from '../beta.service';

@Component({
    selector: 'unlevered-betas',
    templateUrl: './unlevered-betas.component.html',
    styleUrls: ['./unlevered-betas.component.scss']
})
export class UnleveredBetasComponent implements OnInit {
    public collapse = false;
    public helpText = HelpText;
    public currency = '';
    public isExpanded = false;

    constructor(public betaService: BetaService) { }

    public ngOnInit(): void {

        this.currency = this.betaService.metricInputs.Currency;
    }
    public toggleCollapse(): void {
        // if (!this.isExpanded) {
        // this.betaService.SetUleverdBetas();
        // this.isExpanded = true;
        // }
        this.collapse = !this.collapse;
    }

    @HostListener('window:scroll', [])
    public onWindowScroll(): void {
        const duplicate = document.getElementById('duplicateunlevered');
        const unleveringInputParent = document.getElementById('unleveredBetasParent');
        // to stick collapsed section while scrolling to right
        const collapsedItem = document.getElementById('unleveredBetasCollapsed');
        const scrollLeftValue = 5;

        let top = 0;
        const upperPageHeight = 267;
        if (unleveringInputParent != null) {
            top = unleveringInputParent.clientHeight + unleveringInputParent.getBoundingClientRect().top - upperPageHeight;
            if (!this.collapse) {
                // to stick collapsed section while scrolling to right
                if (document.body.scrollLeft > scrollLeftValue || document.documentElement.scrollLeft > scrollLeftValue) {
                    if (collapsedItem != null) {
                        collapsedItem.classList.add('fixed-column');
                        collapsedItem.style.display = 'inline';
                        collapsedItem.style.top = top + 236 + 'px';
                    }
                } else {
                    if (collapsedItem != null) {
                        collapsedItem.style.display = 'none';
                    }
                }
                // end code
                return;
            }
        }
        const topValue = top - 17 + 'px';
        if (document.body.scrollLeft > scrollLeftValue || document.documentElement.scrollLeft > scrollLeftValue) {
            if (duplicate != null) {
                duplicate.classList.add('fixed-column');
                duplicate.style.display = 'inline';
                duplicate.style.top = topValue;
            }
        } else {
            if (duplicate != null) {
                duplicate.style.display = 'none';
            }
            if (collapsedItem != null) {
                collapsedItem.style.display = 'none';
            }
        }
    }
}
