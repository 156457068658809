import { Util } from '@concurrency/core';
import { Action, createSelector, Selector, State, StateContext, Store } from '@ngxs/store';
import { Observable, of, zip } from 'rxjs';
import { map } from 'rxjs/operators';
import { BetaEstimateClient } from 'src/app/_api/clients/betaEstimate.client';
import { BetaService } from 'src/app/beta/beta.service';
import { BetaEstimateRequest } from '../data/model/beta-estimate-request.model';
import { BetaEstimate } from '../data/model/beta-estimate.model';
import { DataStore } from '../data/store/data.store';
import { User } from '../user/model/user.model';
import { UserStore } from '../user/store/user.store';
import { ArchiveBetaEstimate, CloneBetaEstimate, CreateBetaEstimate, GetBetaEstimate, GetBetaEstimates, RenameBetaEstimate, UpdateDbEstimate, UpdateLocalEstimate } from './beta-estimates-list-actions';
import { Injectable } from '@angular/core';
// import { BetaEstimateRequest } from 'src/app/beta/beta.types';
// import { BetaEstimateResponse } from 'src/app/_api/responses/beta-estimate.response';

export enum SortType { Name = 'Name', Date = 'Date' }

export type BetaEstimatesListModel = BetaEstimate[] | undefined;
export type BetaEstimatesModel = BetaEstimate | undefined;
export type BetaEstimatesRequestModel = BetaEstimateRequest | undefined;

@State<BetaEstimatesListModel>({
    name: 'betaEstimates',
    defaults: undefined
})

@Injectable()
export class BetaEstimatesListState {

    @Selector()
    public static get(state: BetaEstimatesListModel): BetaEstimate[] | undefined {
        return state;
    }

    public static sort(sortType: SortType, sortDescending: boolean = true):
        (betaEstimates: BetaEstimatesListModel) => BetaEstimate[] | undefined {
        const sortByType = (estimates: BetaEstimate[]) => {
            if (sortType === SortType.Name) {
                return Util.sortBy(estimates, (x) => x.Name);
            } else {
                return Util.sortBy(estimates, (x) => x.Updated);
            }
        };

        const sortByDirection = (estimates: BetaEstimate[]) => {
            return sortDescending ? estimates.reverse() : estimates;
        };

        return createSelector([BetaEstimatesListState], (betaEstimates) => {
            if (betaEstimates == null) {
                return undefined;
            }

            let sorted = sortByType(betaEstimates);
            sorted = sortByDirection(sorted);

            // if (estimateType) {
            //     sorted = sorted.filter((x) => x.EstimateType === estimateType);
            // }

            return sorted;
        });
    }
    constructor(
        private betaEstimateClient: BetaEstimateClient,
        private dataStore: DataStore,
        private store: Store,
        private userstore: UserStore,
        private betaService: BetaService
    ) { }

    @Action(GetBetaEstimates)
    public getAll(context: StateContext<BetaEstimatesListModel>): Observable<void> {
        return this.userstore.user.onceDefined((user: User) => {
            if (user.IsActive === false) {

                return of([]).once(() => {
                    context.setState([]);
                });
            } else {
                return this.betaEstimateClient.search().once((betaEstimates) => {
                    context.setState(betaEstimates.map((x) => new BetaEstimate(x)));
                });
            }
        });
    }
    // @Action(GetBetaEstimate)
    // public getEstimate(context: StateContext<BetaEstimatesModel>, action: GetBetaEstimate): Observable<void> {
    //     return this.betaEstimateClient.read(action.id).once((response) => {
    //         const estimate = new BetaEstimate(response);
    //         context.setState(estimate);
    //         // this.dataStore.setupBetaEstimate(estimate.EstimateId);
    //     });
    //     // console.log(context);
    //     // return this.betaEstimateClient.read(action.id).once();
    // }

    @Action(UpdateDbEstimate)
    public updateDb(context: StateContext<BetaEstimatesRequestModel>, action: UpdateDbEstimate): Observable<void> {
        const estimate = context.getState();
        console.log(action);

        if (estimate == null) {
            return of(void 0);
        }

        return this.betaEstimateClient.update(estimate).once(() => {
            return this.betaEstimateClient.read(estimate.EstimateId).once((response) => {
                const estimateResponse = response as any;
                context.setState(estimateResponse);
                this.dataStore.setupBetaEstimate(estimateResponse.Id);

                return of(void 0);
            });
        });
    }

    @Action(UpdateLocalEstimate)
    public updateLocal(context: StateContext<BetaEstimatesModel>, action: UpdateLocalEstimate): Observable<void> {
        const estimate = new BetaEstimate(action.estimate);
        context.setState(estimate);

        return of(void 0);
    }

    @Action(CreateBetaEstimate)
    public create(context: StateContext<BetaEstimatesListModel>, action: CreateBetaEstimate): Observable<void> {
        return this.betaEstimateClient.create(action.name, action.type).once((id) => {
            this.betaService.estimateId = id;
            this.betaService.isBetaEstimate = false;
            const zipped = zip(context.dispatch(new GetBetaEstimates()), this.store.dispatch(new GetBetaEstimate(id)));
            return zipped.pipe(map(() => { }));
        });
    }

    @Action(ArchiveBetaEstimate)
    public archive(context: StateContext<BetaEstimatesListModel>, action: ArchiveBetaEstimate): Observable<void> {
        return this.betaEstimateClient.deactivate(action.id).once(() => context.dispatch(new GetBetaEstimates()));
    }

    @Action(CloneBetaEstimate)
    public clone(context: StateContext<BetaEstimatesListModel>, action: CloneBetaEstimate): Observable<void> {
        return this.betaEstimateClient.clone(action.id).once(() => context.dispatch(new GetBetaEstimates()));
    }

    @Action(RenameBetaEstimate)
    public rename(context: StateContext<BetaEstimatesListModel>, action: RenameBetaEstimate): Observable<void> {
        return this.betaEstimateClient.rename(action.id, action.name).once(() => context.dispatch(new GetBetaEstimates()));
    }
}
