import { HttpClient, HttpHeaders, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthenticationManager } from '@concurrency/angular';
import { EnvironmentManager } from '@concurrency/angular';
import { HttpError } from '@concurrency/angular';
import { HttpOptions, MapperFunction } from '@concurrency/angular';
import { NetworkManager } from '@concurrency/angular';
import { AuthService } from '@auth0/auth0-angular';
import { AuthHttpInterceptor } from '@auth0/auth0-angular';

// type HttpAction<T> = () => Observable<T>;

@Injectable()
export abstract class Auth0ApiClient {
    protected abstract baseUrl: string;
    constructor(protected http: HttpClient, protected auth: AuthService) { }


    protected get<T>(path: string, options?: any): Observable<any> {

        return this.http.get<T>(`${this.baseUrl}/${path}`, options);

    }


    protected post<T>(path: string, body: any, options?: any): Observable<any> {

        return this.http.post<T>(`${this.baseUrl}/${path}`, body, options);

    }


    protected put<T>(path: string, body: any, options?: any): Observable<any> {

        return this.http.put<T>(`${this.baseUrl}/${path}`, body, options);

    }


    protected delete<T>(path: string, options?: any): Observable<any> {

        return this.http.delete<T>(`${this.baseUrl}/${path}`, options);

    }


    protected patch<T>(path: string, body: any, options?: any): Observable<any> {

        return this.http.patch<T>(`${this.baseUrl}/${path}`, body, options);

    }

}


// export abstract class Auth0ApiClient {
//     protected abstract baseUrl: string;

//     constructor(
//         protected environmentManager: EnvironmentManager,
//         private http: HttpClient,
//         // private authenticationManager: AuthenticationManager,
//         // private networkManager: NetworkManager,
//         // private auth: AuthService
//     ) { }

//     protected get<T>(path: string, options?: HttpOptions<T>): Observable<T> {
//         // const action = () =>
//         return this.http.get<T>(`${this.baseUrl}/${path}`,);

//         // return this.doHttpAction(action, options);
//     }

//     protected getit<T>(path: string, options?: HttpOptions<T>): Observable<any> {
//         // const action = () =>
//         return this.http.get(`${this.baseUrl}/${path}`,);

//         // return this.doHttpAction(action, options);
//     }


//     protected post<T>(path: string, body: any, options?: HttpOptions<T>): Observable<T> {
//         //const action = () =>
//         return this.http.post<T>(`${this.baseUrl}/${path}`, body,);

//         //  return this.doHttpAction(action, options);
//     }

//     protected put<T>(path: string, body: any, options?: HttpOptions<T>): Observable<T> {
//         // const action = () =>
//         return this.http.put<T>(`${this.baseUrl}/${path}`, body);

//         // return this.doHttpAction(action, options);
//     }

//     protected patch<T>(path: string, body: any, options?: HttpOptions<T>): Observable<T> {
//         // const action = () =>
//         return this.http.patch<T>(`${this.baseUrl}/${path}`, body);

//         // return this.doHttpAction(action, options);
//     }

//     protected delete<T>(path: string, body: any, options?: HttpOptions<T>): Observable<T> {
//         //  const action = () =>
//         return this.http.request<T>('delete', `${this.baseUrl}/${path}`, { body });

//         // return this.doHttpAction(action, options);
//     }

//     // private doHttpAction<T>(httpAction: HttpAction<T>, options?: HttpOptions<T>): Observable<T> {
//     //     const mapFunction: MapperFunction<T> = options && options.resultMapper
//     //         ? (response: any) => options.resultMapper(response)
//     //         : (response: any) => response;

//     //     //const headerRequest = this.getHeaders();
//     //     // return headerRequest.once((headers: HttpHeaders) => {
//     //     const httpResult = httpAction();
//     //     return httpResult.pipe(
//     //         catchError((error) => this.handleError(error)),
//     //         map(mapFunction)
//     //     ).once((data: T) => {
//     //         this.networkManager.logRequest(Math.round(new Date().getTime() / 1000.0));
//     //         return data;
//     //     });
//     //     // });
//     // }


//     // private getHeaders(): Observable<HttpHeaders> {
//     //     const headers: any = {};
//     //     headers.Accept = 'application/json';
//     //     headers['Content-Type'] = 'application/json';

//     //     const request = this.authenticationManager.getToken();
//     //     return request.once((token: string) => {
//     //         if (token !== null) {
//     //             headers.Authorization = `Bearer ${token}`;
//     //         }
//     //         return new HttpHeaders(headers);
//     //     });
//     // }

//     // private handleError(error: HttpError): Observable<HttpError> {
//     //     if (error.status === 401) {
//     //         this.authenticationManager.handleAuthenticationError(error);
//     //     } else {
//     //         this.networkManager.handleUnexpectedError(error);
//     //     }

//     //     return throwError(error);
//     // }
// }
