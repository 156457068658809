import { Component, HostListener, OnInit } from '@angular/core';
import { ContextualString } from '@concurrency/core';
import { CommonInputConfig } from 'src/app/_navigator/common/inputs/common-user-input.component';
import { ComboboxDataType } from 'src/app/_navigator/data/enum/combobox-data-type.enum';
import { Suggestion } from 'src/app/_navigator/data/model/suggestion.model';
import { HelpText } from 'src/app/_navigator/help/help-text';
import { BetaService } from '../beta.service';
import { ReleveredBetas, ReleveredInputs } from '../beta.types';

@Component({
    selector: 'relevered-betas',
    templateUrl: './relevered-betas.component.html',
    styleUrls: ['./relevered-betas.component.scss']
})
export class ReleveredBetasComponent implements OnInit {
    public collapse = false;
    public betaTypeSelected: any;
    public capitalRateSelected: any;
    public capitalRatelabel: any;
    public taxRateSelected: any;
    public taxRatelabel: any;
    public capitalRatelabelshow = false;
    public taxRatelabelshow = false;
    public helpText = HelpText;
    public currency = '';
    // public isBetaTypeChanged = false;
    // public isCapitalChanged = false;
    // public isTaxRateChanged = false;

    public releveredBetas: ReleveredBetas = {
        BetaType: new ContextualString(),
        TargetCapital: new ContextualString(),
        TaxRate: new ContextualString()
    } as ReleveredBetas;
    public betaTypeconfig: CommonInputConfig = {
        name: 'Beta Type',
        label: 'Beta Type',
        required: true
    };
    public targetCapitalConfig: CommonInputConfig = {
        name: 'Target Capital Structure (D/TC)',
        label: 'Target Capital Structure (D/TC)',
        required: true,
        mask: '{}%'
    };
    public taxRateConfig: CommonInputConfig = {
        name: 'Tax Rate',
        label: 'Tax Rate',
        required: true,
        mask: '{}%'
    };
    public betaTypeOtions: Suggestion<string>[] = [];

    constructor(public betaService: BetaService) { }
    public ngOnInit(): void {
        this.currency = this.betaService.metricInputs.Currency;
        if (this.betaService.isBetaEstimate) {
            this.releveredBetas.BetaType.value = this.betaService.releveredInputs.BetaType;
            this.releveredBetas.TargetCapital.value = this.betaService.releveredInputs.TargetCapitalStructure;
            this.releveredBetas.TargetCapital.context = this.betaService.releveredInputs.TargetLabel;
            this.releveredBetas.TargetCapital.source = this.betaService.releveredInputs.TargetCapitalStructure + ' - ' + this.betaService.releveredInputs.TargetLabel;
            this.releveredBetas.TaxRate.value = this.betaService.releveredInputs.TaxRate;
            this.releveredBetas.TaxRate.context = this.betaService.releveredInputs.TaxRateLabel;
            this.releveredBetas.TaxRate.source = this.betaService.releveredInputs.TaxRate + ' - ' + this.betaService.releveredInputs.TaxRateLabel;
            this.betaService.capitalRateSelectedLabel = this.betaService.releveredInputs.TargetLabel;
            this.betaService.taxRateSelectedLabel = this.betaService.releveredInputs.TaxRateLabel;
            this.capitalRatelabelshow = true;
            this.taxRatelabelshow = true;
        }
        this.betaService.clearReleveredBetas.subscribe((x) => {
            if (x === true) {
                this.betaService.taxRateSelected = '';
                this.betaService.capitalRateSelected = '';
                this.betaService.minimumSummaryValue = '';
                this.betaService.maximumSummaryValue = '';
                this.betaService.medianSummaryValue = '';
                this.betaService.averageSummaryValue = '';
                this.betaService.firstQuartileSummaryValue = '';
                this.betaService.thirdQuartileSummaryValue = '';
                this.betaService.targetCapitalAverageValue = '';
                this.betaService.targetCapitalMedianValue = '';
                this.betaService.releveredAverageTaxRate = '';
                this.betaService.releveredMedianTaxRate = '';
                this.betaService.releveredTaxRate = '';
                this.betaService.leveredminimumSummaryValue = '';
                this.betaService.leveredfirstQuartileSummaryValue = '';
                this.betaService.leveredaverageSummaryValue = '';
                this.betaService.leveredmedianSummaryValue = '';
                this.betaService.leveredthirdQuartileSummaryValue = '';
                this.betaService.leveredmaximumSummaryValue = '';
                this.betaService.unleveredMinimumSummaryValue = '';
                this.betaService.unleveredFirstQuartileSummaryValue = '';
                this.betaService.unleveredAverageSummaryValue = '';
                this.betaService.unleveredMedianSummaryValue = '';
                this.betaService.unleveredThirdQuartileSummaryValue = '';
                this.betaService.unleveredMaximumSummaryValue = '';
                this.betaService.releveredInputs = {} as ReleveredInputs;
                this.releveredBetas.BetaType.value = '';
                this.releveredBetas.TargetCapital.value = '';
                this.releveredBetas.TargetCapital.context = '';
                this.releveredBetas.TargetCapital.source = '';
                this.releveredBetas.TaxRate.value = '';
                this.releveredBetas.TaxRate.context = '';
                this.releveredBetas.TaxRate.source = '';
                this.betaService.capitalRateSelectedLabel = '';
                this.betaService.taxRateSelectedLabel = '';
                this.clearStatistics();

            }
        });
    }
    public toggleCollapse(): void {
        this.collapse = !this.collapse;
        this.insertBetaTypeOtions();
        this.betaService.insertTargetCapitalRateOptions();
        this.betaService.insertTaxRateReleveredOptions();

    }
    public insertBetaTypeOtions(): void {
        this.betaTypeOtions = [];
        this.betaService.betaTypeList.forEach((b: any) => {
            this.betaTypeOtions.push({
                name: b.name,
                source: b.name,
                value: b.value,
                type: ComboboxDataType.String
            });
        });
    }
    public onBetaTypeChange(input: any): void {
        const type = this.betaService.betaTypeSelected;
        this.betaService.betaTypeSelected = input.value;
        this.betaService.getReleveredSummaryStatistcs(this.betaService.betaTypeSelected);
        this.betaService.getLeveredSummaryStatistics(this.betaService.betaTypeSelected);
        this.betaService.getUneveredSummaryStatistics(this.betaService.betaTypeSelected);
        // if (!this.isBetaTypeChanged) {
        //     this.betaService.updateEstimate();
        //     this.isBetaTypeChanged = true;
        // }
        if (type !== input.value && !this.betaService.IsCompanyHiddenStatusChanging && !this.betaService.isDeleteCompanyChange) {
            this.betaService.updateEstimate();
        }
    }

    public onCpitalRateChange(input: any, inputOptions: any[]): void {
        const capital = this.betaService.isBetaEstimate ? this.betaService.releveredInputs.TargetCapitalStructure : this.betaService.releveredInputs.OriginalTargetCapitalStructure;
        const capitalLabel = this.betaService.releveredInputs.TargetLabel;
        this.betaService.capitalRateSelected = input.value;
        this.betaService.originalTargetCapitalStructure = input.value;
        this.capitalRatelabelshow = true;
        const label = inputOptions.find((i) => i.id === input.id);
        const selectedRate = inputOptions.find((i) => i.value === input.value);
        if (label && selectedRate) {
            this.betaService.capitalRateSelectedLabel = label.source;
        } else {
            if (this.betaService.IsCompanyHiddenStatusChanging || this.betaService.isDeleteCompanyChange) {
                // if user is hiding/unhiding comapnies then we want to clear the capital rate and hence the capital rate label as well
                this.betaService.capitalRateSelectedLabel = "";
            } else {
                this.betaService.capitalRateSelectedLabel = "Custom";
            }
        }
        if (this.betaService.capitalRateSelectedLabel.includes("Average")) {
            this.betaService.capitalRateSelected = this.betaService.targetCapitalOriginalAverageValue;
        } else if (this.betaService.capitalRateSelectedLabel.includes("Median")) {
            this.betaService.capitalRateSelected = this.betaService.targetCapitalOriginalMedianValue;
        } else {
            this.betaService.capitalRateSelected = ((this.betaService.capitalRateSelected / (100 - this.betaService.capitalRateSelected)) * 100).toFixed(2);
        }
        if (this.betaService.betaTypeSelected) {
            this.betaService.getReleveredSummaryStatistcs(this.betaService.betaTypeSelected);
        }
        // if (!this.isCapitalChanged) {
        //     this.betaService.updateEstimate();
        //     this.isCapitalChanged = true;
        // }
        if (capital !== input.value && capitalLabel !== this.betaService.capitalRateSelectedLabel && !this.betaService.IsCompanyHiddenStatusChanging && !this.betaService.isDeleteCompanyChange) {
            this.betaService.updateEstimate();
        }
    }

    public onTaxRateChange(input: any, inputOptions: any[]): void {
        const tax = this.betaService.taxRateSelected;
        const taxLabel = this.betaService.releveredInputs.TaxRateLabel;
        this.betaService.taxRateSelected = input.value;
        this.taxRatelabelshow = true;
        const label = inputOptions.find((i) => i.id === input.id);
        const selectedTax = inputOptions.find((i) => i.value === input.value);
        if (label && selectedTax) {
            this.betaService.taxRateSelectedLabel = label.source;
        } else {
            if (this.betaService.IsCompanyHiddenStatusChanging || this.betaService.isDeleteCompanyChange) {
                // if user is hiding/unhiding comapnies then we want to clear the taxrate and hence the taxrate label as well
                this.betaService.taxRateSelectedLabel = "";
            } else {
                this.betaService.taxRateSelectedLabel = "Custom";
            }
        }
        if (this.betaService.betaTypeSelected) {
            this.betaService.getReleveredSummaryStatistcs(this.betaService.betaTypeSelected);
        }
        if (tax !== input.value || taxLabel !== this.betaService.taxRateSelectedLabel && !this.betaService.IsCompanyHiddenStatusChanging && !this.betaService.isDeleteCompanyChange) {
            this.betaService.updateEstimate();
        }

        this.betaService.IsCompanyHiddenStatusChanging = false;
        this.betaService.isDeleteCompanyChange = false;
        // if (!this.isTaxRateChanged) {
        //     this.betaService.updateEstimate();
        //     this.isTaxRateChanged = true;
        // }
    }

    @HostListener('window:scroll', [])
    public onWindowScroll(): void {
        const releveredBetasParent = document.getElementById('releveredBetasParent');
        // to stick collapsed section while scrolling to right
        const collapsedItem = document.getElementById('releveredBetasCollapsed');
        const duplicate = document.getElementById('duplicateRelevered');
        const scrollLeftValue = 5;

        let top = 0;
        const upperPageHeight = 267;
        if (releveredBetasParent != null) {
            top = releveredBetasParent.clientHeight + releveredBetasParent.getBoundingClientRect().top - upperPageHeight;
            if (!this.collapse) {
                // to stick collapsed section while scrolling to right
                if (document.body.scrollLeft > scrollLeftValue || document.documentElement.scrollLeft > scrollLeftValue) {
                    if (collapsedItem != null) {
                        collapsedItem.classList.add('fixed-column');
                        collapsedItem.style.display = 'inline';
                        collapsedItem.style.top = top + 236 + 'px';
                    }
                } else {
                    if (collapsedItem != null) {
                        collapsedItem.style.display = 'none';
                    }
                }
                // end code
                return;
            }
        }
        const topValue = top + 229 + 'px';
        if (document.body.scrollLeft > scrollLeftValue || document.documentElement.scrollLeft > scrollLeftValue) {
            if (duplicate != null) {
                duplicate.classList.add('fixed-column');
                duplicate.style.display = 'inline';
                duplicate.style.top = topValue;
            }
        } else {
            if (duplicate != null) {
                duplicate.style.display = 'none';
            }
            if (collapsedItem != null) {
                collapsedItem.style.display = 'none';
            }
        }
    }

    clearStatistics() {

        this.betaService.releveredStatistics = {
            Minimum: null,
            FirstQuartile: null,
            Average: null,
            Median: null,
            ThirdQuartile: null,
            Maximum: null
        };

        this.betaService.leveredStatistics = {
            Minimum: null,
            FirstQuartile: null,
            Average: null,
            Median: null,
            ThirdQuartile: null,
            Maximum: null
        };

        this.betaService.unleveredStatistics = {
            Minimum: null,
            FirstQuartile: null,
            Average: null,
            Median: null,
            ThirdQuartile: null,
            Maximum: null
        };


    }

}
