import { Component, Input } from '@angular/core';
import { CanGetInfo } from '../data/model/equation.model';
import { HelpService } from '../help/help.service';

@Component({
    selector: 'info-icon',
    templateUrl: './info-icon.component.html'
})
export class InfoIconComponent {
    @Input() public info!: CanGetInfo;

    public id: string = Math.random().toString(36).substring(2);

    constructor(
        public helpService: HelpService
    ) { }

    // TODO: This scheme is hacky, ideally the Help service could render components directly
    public showInfo(): void {
        if (this.helpService.activeHelpId == null) {
            this.info.getInfo().subscribe((info: string) => {
                this.helpService.toggle(this.id, {
                    title: 'Supplementary Sensitivity Data', // could be an Input
                    text: info
                });
            });
        }
    }
}
