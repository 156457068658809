import { Component, HostListener, OnInit } from '@angular/core';
import { HelpText } from 'src/app/_navigator/help/help-text';
import { BetaService } from '../beta.service';
import { PrimeManager } from 'src/app/_navigator/modal/pmodal.manager';
import { BetaTrendsOverTimeComponent } from '../beta-trends-over-time/beta-trends-over-time.component';
import { BetaToT, CompanyReturnsRequest } from '../beta.types';
import { DataStore } from 'src/app/_navigator/data/store/data.store';
import { Spinner } from '@concurrency/angular';
import * as moment from 'moment';

@Component({
    selector: 'levered-betas',
    templateUrl: './levered-betas.component.html',
    styleUrls: ['./levered-betas.component.scss']
})
export class LeveredBetasComponent implements OnInit {

    public collapse = false;
    public helpText = HelpText;
    public currency = '';

    constructor(
        public betaService: BetaService,
        private dataStore: DataStore,
        private primeManager: PrimeManager,
        private spinner: Spinner
    ) { }

    public ngOnInit(): void {
        this.currency = this.betaService.metricInputs.Currency;

    }
    public toggleCollapse(): void {
        this.betaService.isLeverdBetaCollapse = !this.betaService.isLeverdBetaCollapse;
        this.currency = this.betaService.metricInputs.Currency;
    }

    @HostListener('window:scroll', [])
    public onWindowScroll(): void {
        const duplicate = document.getElementById('duplicateLevered');
        const betaStatisticsParent = document.getElementById('leveredBetaParent');
        // to stick collapsed section while scrolling to right
        const collapsedItem = document.getElementById('leveredBetaCollapsed');
        const scrollLeftValue = 5;

        let top = 0;
        const upperPageHeight = 284;
        if (betaStatisticsParent != null) {
            top = betaStatisticsParent.clientHeight + betaStatisticsParent.getBoundingClientRect().top - upperPageHeight;
            if (!this.betaService.isLeverdBetaCollapse) {
                // to stick collapsed section while scrolling to right
                if (document.body.scrollLeft > scrollLeftValue || document.documentElement.scrollLeft > scrollLeftValue) {
                    if (collapsedItem != null) {
                        collapsedItem.classList.add('fixed-column');
                        collapsedItem.style.display = 'inline';
                        collapsedItem.style.top = top + 253 + 'px';
                    }
                } else {
                    if (collapsedItem != null) {
                        collapsedItem.style.display = 'none';
                    }
                    if (duplicate != null) {
                        duplicate.style.display = 'none';
                    }
                }
                // end code
                return;
            }
        }
        const topValue = top + 'px';
        if (document.body.scrollLeft > scrollLeftValue || document.documentElement.scrollLeft > scrollLeftValue) {
            if (duplicate != null) {
                duplicate.classList.add('fixed-column');
                duplicate.style.display = 'inline';
                duplicate.style.top = topValue;
            }
        } else {
            if (duplicate != null) {
                duplicate.style.display = 'none';
            }
            if (collapsedItem != null) {
                collapsedItem.style.display = 'none';
            }
        }
    }

    public openTrendsOverTimeModal(title: string): void {
        this.betaService.betas = [];
        // if (this.betaService.betasToTTitle !== title) {
        const companyRequest: CompanyReturnsRequest = {
            GVKEY: this.betaService.gvKeys,
            Metrics: this.betaService.metricInputs
        };

        const request = this.dataStore.getBetasToT(title, companyRequest);
        this.spinner.while(request);
        request.once(() => {
            const betas = this.dataStore.betasToT;
            betas.onceDefined((_beta) => {
                if (_beta) {
                    companyRequest.GVKEY.forEach((key) => {
                        const betaValues = _beta.filter((x: BetaToT) => x.Gvkey === key);
                        const dates = companyRequest.Metrics.Frequency === "Monthly" ? this.betaService.GetMonthlyDates(companyRequest, new Date(betaValues[0].DataAsOf)) : this.betaService.GetWeeklyDates(companyRequest, new Date(betaValues[0].DataAsOf));

                        if (dates.length > 0) {
                            dates.forEach((date) => {
                                let remainingDates: BetaToT = {
                                    Gvkey: key,
                                    DataAsOf: date,
                                    OlsBeta: null,
                                    SumBeta: null
                                };
                                this.betaService.betas.push(remainingDates)
                            });
                        }
                        betaValues.forEach((data) => {
                            this.betaService.betas.push(data);
                        });
                        this.betaService.betas.sort(function (a, b) {
                            return a.DataAsOf.localeCompare(b.DataAsOf)
                        });
                    });
                    //this.setLineChartOptions(this.betaService.betas);
                    this.primeManager.openDialog(BetaTrendsOverTimeComponent,
                        {
                            title: title,
                            frequency: companyRequest.Metrics.Frequency
                        });
                }
            });
        });
        // }

    }



}
