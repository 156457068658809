import { HttpResponse } from '@angular/common/http';
import { ApiClient } from '@concurrency/angular';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EstimateType } from '../enums/estimate-type';
import { EstimateResponse } from '../responses/estimate.response';
import { Injectable } from '@angular/core';
import { Auth0ApiClient } from '../api.client';

@Injectable()
export class EstimateClient extends Auth0ApiClient {
    protected baseUrl = `${environment.urls.navigatorApi}/api`;

    public search(): Observable<EstimateResponse[]> {
        return this.get(`Estimate`);
    }

    public read(id: string): Observable<EstimateResponse> {
        return this.get(`Estimate/${id}`);
    }

    public create(name: string, type: EstimateType): Observable<string> {
        return this.post(`Estimate/create?name=${encodeURIComponent(name)}&type=${type}`, {});
    }

    public update(estimate: EstimateResponse, needsReview: boolean = false): Observable<void> {
        estimate.Inputs = estimate.Inputs.filter((x) => x.Value != null);
        for (const scenario of estimate.Scenarios) {
            scenario.Selections = scenario.Selections.filter((x) => x.Value != null);
            scenario.Equations = scenario.Equations.filter((x) => x.Result != null);
        }

        return this.post(`Estimate/update?needsReview=${needsReview}`, estimate);
    }

    public rename(id: string, name: string): Observable<void> {
        return this.post(`Estimate/rename?id=${id}&name=${encodeURIComponent(name)}`, {});
    }

    public clone(id: string): Observable<HttpResponse<void>> {
        return this.post(`Estimate/clone/${id}`, null);
    }

    public updateFinancialYear(id: string, year: number): Observable<void> {
        return this.post(`Estimate/updateFinancialYear/${id}?year=${year}`, {});
    }

    public deactivate(id: string): Observable<void> {
        return this.post(`Estimate/deactivate/${id}`, {});
    }
}
