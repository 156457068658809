
export class CocInputsNumberUtil {

    public  static ConvertToFixedFractionDigits(value: number , convertPercentage: boolean, fractionDigits: number): number  {
        if(value !== null && !isNaN(value)) {
            if(convertPercentage){
                return parseFloat((value*100).toFixed(2));
            } else {
                return parseFloat((value).toFixed(2));
            }
        } 
        return value;
    }
}
