import { EquitySummaryGroup } from 'src/app/_navigator/summary/equity-summary-group.model';
import { SummaryCategory, SummaryGroup } from 'src/app/_navigator/summary/summary-group.model';
import { SummaryScenario } from 'src/app/_navigator/summary/summary-scenario.model';
import { SummaryStudyGroup } from 'src/app/_navigator/summary/summary-study-group.model';
import { Risk } from 'src/app/estimate/studies/risk/risk.model';
import { ExcelExportUtil } from '../../util/excel-export.util';
import { ExcelRow } from '../types/excel-row';
import { ExcelSummaryInput } from '../types/excel-summary-input';
import { WaccExcelEquations } from '../types/wacc-excel-equations';
import { normalCellHeight, normalFontSize, smallCellHeight, smallFontSize } from './summary-helpers';

interface StudyGroupConfig {
    studyGroupName: string;
    studyName: string;
}

export class ScenarioHelper {

    private queryScenario(studyGroup: SummaryStudyGroup[], config: StudyGroupConfig): SummaryScenario[] {
        let scenarios: SummaryScenario[] = [];

        studyGroup.forEach((value) => {
            if (value.name.startsWith(config.studyGroupName)) {
                value.studies.forEach((studyValue): SummaryScenario[] | void => {
                    if (studyValue.name.startsWith(config.studyName)) {
                        scenarios = [...scenarios, ...studyValue.scenarios];
                    }
                });
            }
        }
        );

        return scenarios;
    }

    private createNoteRow(displayNotes: boolean): any {
        return displayNotes
            ? { value: '', fontSize: smallFontSize, background: '#fffb7a', colSpan: 3 }
            : {};
    }

    private filterSizeMeasures(sizeMeasures: SummaryGroup, sizeMeasureType: string): SummaryCategory {
        return sizeMeasures.categories.filter((category) => {
            return category.items.filter((item) => item.type === sizeMeasureType)[0].type === sizeMeasureType;
        })[0];
    }

    public createNoteHeaderRow(displayNotes: boolean): any {
        return displayNotes
            ? { value: 'User Notes', bold: 'true', colSpan: 3, fontSize: normalFontSize }
            : {};
    }

    public CRSPCAPMSP(studyGroup: SummaryStudyGroup[], displayNotes: boolean): any[] {

        const scenarioRepeatingRows: any[] = [];

        const getStudyGroupScenarios = this.queryScenario(
            studyGroup, {
            studyGroupName: 'CRSP Deciles',
            studyName: 'CAPM +'
        }
        );

        if (getStudyGroupScenarios) {
            getStudyGroupScenarios.forEach((scenarioVal) => {

                scenarioVal.equations.forEach((eqVal) => {

                    const Ke = eqVal.parameters[0].value;
                    const Rf = eqVal.parameters[2].value;
                    const B = eqVal.parameters[4].value;
                    const ERP = eqVal.parameters[6].value;
                    const RPs = eqVal.parameters[8].value;

                    scenarioRepeatingRows.push(
                        {
                            cells: [
                                {
                                    value: 'Market Value of Common Equity',
                                    bold: 'true'
                                },
                                { value: ExcelExportUtil.toPercent(Ke) || 'Ke', format: '0.00%', textAlign: 'left' },
                                { value: '=', },
                                { value: ExcelExportUtil.toPercent(Rf) || 'Rf', format: '0.00%', textAlign: 'left' },
                                { value: '+', },
                                { value: ExcelExportUtil.toNumber(B) || 'Beta', format: '0.00', textAlign: 'left' },
                                { value: '*', },
                                { value: ExcelExportUtil.toPercent(ERP) || 'ERP', format: '0.00%', textAlign: 'left' },
                                { value: '+', },
                                { value: ExcelExportUtil.toPercent(RPs) || 'RPs', format: '0.00%', textAlign: 'left' },
                                this.createNoteRow(displayNotes)
                            ]
                        }

                    );

                    scenarioRepeatingRows.push({ cells: [{ value: '' }] });

                });
            });
        }

        return scenarioRepeatingRows;

    }

    public CRSPBuildUp(studyGroup: SummaryStudyGroup[], displayNotes: boolean): any[] {

        const scenarioRepeatingRows: any[] = [];

        const getStudyGroupScenarios = this.queryScenario(
            studyGroup, {
            studyGroupName: 'CRSP Deciles',
            studyName: 'Build-up'
        }
        );

        if (getStudyGroupScenarios) {
            getStudyGroupScenarios.forEach((scenarioVal) => {

                scenarioVal.equations.forEach((eqVal) => {

                    // Build-up
                    const Ke = eqVal.parameters[0].value || 'Ke';
                    const Rf = eqVal.parameters[2].value || 'Rf';
                    const ERP = eqVal.parameters[4].value || 'ERP';
                    const RPi = eqVal.parameters[6].value || 'RPi';
                    const RPs = eqVal.parameters[8].value || 'RPs';

                    scenarioRepeatingRows.push(

                        {
                            cells: [
                                {
                                    value: 'Market Value of Common Equity',
                                    bold: 'true'
                                },
                                { value: ExcelExportUtil.toPercent(Ke) || 'Ke', format: '0.00%', textAlign: 'left' },
                                { value: '=', },
                                { value: ExcelExportUtil.toPercent(Rf) || 'Rf', format: '0.00%', textAlign: 'left' },
                                { value: '+', },
                                { value: ExcelExportUtil.toPercent(ERP) || 'ERP', format: '0.00%', textAlign: 'left' },
                                { value: '+', },
                                { value: ExcelExportUtil.toPercent(RPi) || 'RPi', format: '0.00%', textAlign: 'left' },
                                { value: '+', },
                                { value: ExcelExportUtil.toPercent(RPs) || 'RPs', format: '0.00%', textAlign: 'left' },
                                this.createNoteRow(displayNotes)
                            ]
                        }

                    );

                });
                scenarioRepeatingRows.push({ cells: [{ value: '' }] });
            });
        }

        return scenarioRepeatingRows;
    }

    public CRSPDecilesSizePremia(
        studyGroup: SummaryStudyGroup[],
        risks: ExcelSummaryInput[],
        sizeMeasures: SummaryGroup,
        displayNotes: boolean
    ): any[] {
        const scenarioRepeatingRows: any[] = [];

        const getStudyGroupScenarios = this.queryScenario(
            studyGroup, {
            studyGroupName: 'CRSP Deciles Size Study',
            studyName: 'CAPM + Size Premium'
        }
        );

        if (getStudyGroupScenarios) {
            getStudyGroupScenarios.forEach((scenarioVal) => {
                scenarioVal.equations.forEach((eqVal) => {
                    const studyName = 'CRSP Deciles Size Study';
                    const sizeRiskMeasure = 'Market Value of Common Equity';
                    const sizeRiskMeasureType = 'MarketValueOfCommonEquity';

                    const decileContext = eqVal.parameters[8].context || '-';

                    const riskMeasure = risks.filter((risk) => risk.name === eqVal.equation.ImplicationType)[0];

                    const sizeMeasure = this.filterSizeMeasures(sizeMeasures, sizeRiskMeasureType);
                    const filteredSizeMeasure = sizeMeasure.items.filter((item) => item.type === sizeRiskMeasureType)[0];

                    if (decileContext) {
                        const decileNewLines = decileContext.split('\n');

                        let dataPoint = '';
                        let breakPoint = '';

                        if (decileNewLines.length) {
                            dataPoint = decileNewLines[0];
                            breakPoint = decileNewLines[1];
                        }

                        const valueResult = eqVal.parameters[8].value || '-';

                        scenarioRepeatingRows.push(
                            {
                                cells: [
                                    { value: studyName },
                                    { value: sizeRiskMeasure },
                                    { value: `${dataPoint} Size Premium` },
                                    { value: valueResult },
                                    { value: riskMeasure.dataAsOf },
                                    { value: filteredSizeMeasure.value },
                                    { value: breakPoint },
                                    this.createNoteRow(displayNotes)
                                ]
                            }

                        );

                    }
                });

            });
        }

        return scenarioRepeatingRows;
    }

    public CRSPRiskPremiumSizePremia(
        studyGroup: SummaryStudyGroup[],
        risks: ExcelSummaryInput[],
        sizeMeasures: SummaryGroup,
        displayNotes: boolean
    ): any[] {
        const scenarioRepeatingRows: any[] = [];

        const getStudyGroupScenarios = this.queryScenario(
            studyGroup, {
            studyGroupName: 'Risk Premium Report Study',
            studyName: 'CAPM + Size Premium'
        }
        );

        if (getStudyGroupScenarios) {

            getStudyGroupScenarios.forEach((scenarioVal) => {
                scenarioVal.equations.forEach((eqVal) => {
                    const studyName = 'Risk Premium Report Size Study';
                    const sizeRiskMeasure = eqVal.name;

                    const riskMeasure = risks.filter((risk) => risk.name === eqVal.equation.ImplicationType)[0];
                    const sizeRiskMeasureType = riskMeasure.name;

                    const sizeMeasure = this.filterSizeMeasures(sizeMeasures, sizeRiskMeasureType);
                    const filteredSizeMeasure = sizeMeasure.items.filter((item) => item.type === sizeRiskMeasureType)[0];

                    const portfolioContext = eqVal.parameters[8].context || '-';

                    if (portfolioContext) {
                        const portfolioNewLines = portfolioContext.split('\n');

                        let dataPoint = '';
                        let breakPoint = '';

                        if (portfolioNewLines.length) {
                            dataPoint = portfolioNewLines[0];
                            breakPoint = portfolioNewLines[2];
                        }

                        // User Selected Regression Method
                        if (!breakPoint && dataPoint !== '-') {
                            breakPoint = dataPoint;
                            dataPoint = 'Regression Method';
                        }

                        const valueResult = eqVal.parameters[8].value || '-';

                        scenarioRepeatingRows.push(
                            {
                                cells: [
                                    { value: studyName },
                                    { value: sizeRiskMeasure },
                                    { value: `${dataPoint} Size Premium` },
                                    { value: valueResult },
                                    { value: riskMeasure.dataAsOf },
                                    { value: filteredSizeMeasure.value },
                                    { value: breakPoint },
                                    this.createNoteRow(displayNotes)
                                ]
                            }

                        );
                    }
                });

            });
        }

        return scenarioRepeatingRows;
    }

    public CRSPHFRSizePremia(
        studyGroup: SummaryStudyGroup[],
        risks: ExcelSummaryInput[],
        zScore: ExcelSummaryInput,
        displayNotes: boolean
    ): any[] {
        const scenarioRepeatingRows: any[] = [];

        const getStudyGroupScenarios = this.queryScenario(
            studyGroup, {
            studyGroupName: 'High Financial Risk Study',
            studyName: 'CAPM + HFR Size Premium'
        }
        );

        if (getStudyGroupScenarios) {
            getStudyGroupScenarios.forEach((scenarioVal) => {
                scenarioVal.equations.forEach((eqVal) => {
                    const studyName = 'High-Financial Risk Study';
                    const sizeRiskMeasure = 'Service z-Score';

                    const riskMeasure = risks.filter((risk) => risk.name === eqVal.equation.ImplicationType)[0];

                    const zScoreContext = eqVal.parameters[8].context || '-';
                    const valueResult = eqVal.parameters[8].value || '-';

                    scenarioRepeatingRows.push(
                        {
                            cells: [
                                { value: studyName },
                                { value: sizeRiskMeasure },
                                { value: `${zScoreContext} Zone Size Premium` },
                                { value: valueResult },
                                { value: riskMeasure.dataAsOf },
                                { value: zScore.value },
                                { value: `${zScore.name} ${zScore.value}` },
                                this.createNoteRow(displayNotes)
                            ]
                        }

                    );
                });

            });
        }

        return scenarioRepeatingRows;
    }

    public OperatingMarginsCoefficients(
        risks: Risk[],
        operatingMargins: ExcelSummaryInput[],
        riskMeasures: SummaryGroup,
        displayNotes: boolean
    ): any[] {
        const scenarioRepeatingRows: any[] = [];

        operatingMargins.forEach((margin) => {
            const riskMeasure = risks.find((risk) => risk.implicationType === margin.name);
            const riskMeasureName = riskMeasure ? riskMeasure.name : margin.name;

            if (margin.name === 'OperatingMargin') {
                margin.name = 'AverageOperatingMargin';
            }
            const filteredRiskMeasures = this.filterSizeMeasures(riskMeasures, margin.name);
            const filteredRiskMeasure = filteredRiskMeasures.items.find((item) => item.type === margin.name);

            const portfolioContext = margin.type || '-';

            let dataPoint = '';
            let portfolioBreakPoint = '';

            const dataPointNewLines = portfolioContext.split('\n');

            if (dataPointNewLines.length) {

                dataPoint = dataPointNewLines[0];
                portfolioBreakPoint = dataPointNewLines[2];
            }

            // User Selected Regression Method
            if (!portfolioBreakPoint && dataPoint !== '-') {
                portfolioBreakPoint = dataPoint;
                dataPoint = 'Regression Method';
            }

            const valueResult = margin.value;

            scenarioRepeatingRows.push(
                {
                    cells: [
                        { value: 'Risk Premium Report Risk Study' },
                        { value: riskMeasureName },
                        { value: `${dataPoint} Risk Premium Over the Risk-free Rate (Levered)` },
                        { value: valueResult },
                        { value: margin.dataAsOf },
                        { value: filteredRiskMeasure ? filteredRiskMeasure.value : '-' },
                        { value: portfolioBreakPoint },
                        this.createNoteRow(displayNotes)
                    ]
                }

            );
        });

        return scenarioRepeatingRows;
    }

    public RiskPremiumSizePremiaTypes(
        studyGroup: SummaryStudyGroup[],
        risks: ExcelSummaryInput[],
        sizeMeasures: SummaryGroup,
        type: string,
        displayNotes: boolean
    ): any[] {
        const scenarioRepeatingRows: any[] = [];

        const getStudyGroupScenarios = this.queryScenario(
            studyGroup, {
            studyGroupName: 'Risk Premium Report Study',
            studyName: 'Build-up 1'
        }
        );

        if (getStudyGroupScenarios) {
            getStudyGroupScenarios.forEach((scenarioVal) => {
                scenarioVal.equations.forEach((eqVal) => {
                    const studyName = 'Risk Premium Report Size Study';
                    const sizeRiskMeasure = eqVal.name;

                    const riskMeasure = risks.filter((risk) => risk.name === eqVal.equation.ImplicationType)[0];
                    const sizeRiskMeasureType = riskMeasure.name;

                    const sizeMeasure = this.filterSizeMeasures(sizeMeasures, sizeRiskMeasureType);
                    const filteredSizeMeasure = sizeMeasure.items.filter((item) => item.type === sizeRiskMeasureType)[0];

                    const portfolioContext = eqVal.parameters[4].context || '-';

                    if (portfolioContext) {
                        const portfolioNewLines = portfolioContext.split('\n');

                        let dataPoint = '';
                        let breakPoint = '';

                        if (portfolioNewLines.length) {
                            dataPoint = portfolioNewLines[0];
                            breakPoint = portfolioNewLines[2];
                        }

                        // User Selected Regression Method
                        if (!breakPoint && dataPoint !== '-') {
                            breakPoint = dataPoint;
                            dataPoint = 'Regression Method';
                        }

                        const valueResult = eqVal.parameters[4].value || '-';

                        scenarioRepeatingRows.push(
                            {
                                cells: [
                                    { value: studyName },
                                    { value: sizeRiskMeasure },
                                    { value: `${dataPoint} Risk Premium Over the Risk-free Rate (${type})` },
                                    { value: valueResult },
                                    { value: riskMeasure.dataAsOf },
                                    { value: filteredSizeMeasure.value },
                                    { value: breakPoint },
                                    this.createNoteRow(displayNotes)
                                ]
                            }

                        );
                    }
                });

            });
        }

        return scenarioRepeatingRows;
    }

    public HFRRiskPremiaLevered(
        studyGroup: SummaryStudyGroup[],
        risks: ExcelSummaryInput[],
        zScore: ExcelSummaryInput,
        displayNotes: boolean
    ): any[] {
        const scenarioRepeatingRows: any[] = [];

        const getStudyGroupScenarios = this.queryScenario(
            studyGroup, {
            studyGroupName: 'High Financial Risk Study',
            studyName: 'Build-up HFR Size Premium'
        }
        );

        if (getStudyGroupScenarios) {
            getStudyGroupScenarios.forEach((scenarioVal) => {
                scenarioVal.equations.forEach((eqVal) => {
                    const studyName = 'High-Financial Risk Study';
                    const sizeRiskMeasure = 'Service z-Score';

                    const riskMeasure = risks.filter((risk) => risk.name === eqVal.equation.ImplicationType)[0];

                    const zScoreContext = eqVal.parameters[4].context || '-';
                    const valueResult = eqVal.parameters[4].value || '-';

                    scenarioRepeatingRows.push(
                        {
                            cells: [
                                { value: studyName },
                                { value: sizeRiskMeasure },
                                { value: `${zScoreContext} Zone Risk Premium Over the Risk-free Rate (Levered)` },
                                { value: valueResult },
                                { value: riskMeasure.dataAsOf },
                                { value: zScore.value },
                                { value: `${zScore.name} ${zScore.value}` },
                                this.createNoteRow(displayNotes)
                            ]
                        }

                    );
                });

            });
        }

        return scenarioRepeatingRows;
    }

    public RPRCAPM(studyGroup: SummaryStudyGroup[], displayNotes: boolean): any[] {

        const scenarioRepeatingRows: any[] = [];

        const getStudyGroupScenarios = this.queryScenario(
            studyGroup, {
            studyGroupName: 'Risk Premium',
            studyName: 'CAPM +'
        }
        );

        if (getStudyGroupScenarios) {
            getStudyGroupScenarios.forEach((scenarioVal) => {
                scenarioVal.equations.forEach((eqVal) => {

                    // CAPM
                    const Ke = eqVal.parameters[0].value || 'Ke';
                    const Rf = eqVal.parameters[2].value || 'Rf';
                    const B = eqVal.parameters[4].value || 'Beta';
                    const ERP = eqVal.parameters[6].value || 'ERP';
                    const RPs = eqVal.parameters[8].value || 'RPs';

                    scenarioRepeatingRows.push(

                        {
                            cells: [
                                {
                                    value: eqVal.name,
                                    bold: 'true'
                                },
                                { value: ExcelExportUtil.toPercent(Ke) || 'Ke', format: '0.00%', textAlign: 'left' },
                                { value: '=', },
                                { value: ExcelExportUtil.toPercent(Rf) || 'Rf', format: '0.00%', textAlign: 'left' },
                                { value: '+', },
                                { value: ExcelExportUtil.toNumber(B) || 'Beta', format: '0.0', textAlign: 'left' },
                                { value: '*', },
                                { value: ExcelExportUtil.toPercent(ERP) || 'ERP', format: '0.00%', textAlign: 'left' },
                                { value: '+', },
                                { value: ExcelExportUtil.toPercent(RPs) || 'RPs', format: '0.00%', textAlign: 'left' },
                                this.createNoteRow(displayNotes)
                            ]
                        }

                    );
                });

                scenarioRepeatingRows.push({ cells: [{ value: '' }] });
            });
        }

        return scenarioRepeatingRows;
    }

    public RPRBuildUp1(studyGroup: SummaryStudyGroup[], displayNotes: boolean): any[] {

        const scenarioRepeatingRows: any[] = [];

        const getStudyGroupScenarios = this.queryScenario(
            studyGroup, {
            studyGroupName: 'Risk Premium',
            studyName: 'Build-up 1'
        }
        );

        if (getStudyGroupScenarios) {
            getStudyGroupScenarios.forEach((scenarioVal) => {

                scenarioVal.equations.forEach((eqVal) => {

                    // Build-up
                    const Ke = eqVal.parameters[0].value || 'Ke';
                    const Rf = eqVal.parameters[2].value || 'Rf';
                    const RPms = eqVal.parameters[4].value || 'RPm+s';
                    const ERPAdj = eqVal.parameters[6].value || 'ERPAdj';

                    scenarioRepeatingRows.push(
                        {
                            cells: [
                                {
                                    value: eqVal.name,
                                    bold: 'true'
                                },
                                { value: ExcelExportUtil.toPercent(Ke) || 'Ke', format: '0.00%', textAlign: 'left' },
                                { value: '=', },
                                { value: ExcelExportUtil.toPercent(Rf) || 'Rf', format: '0.00%', textAlign: 'left' },
                                { value: '+', },
                                { value: ExcelExportUtil.toPercent(RPms) || 'RPms', format: '0.00%', textAlign: 'left' },
                                { value: '+', },
                                { value: ExcelExportUtil.toPercent(ERPAdj) || 'RPAdj', format: '0.00%', textAlign: 'left' },
                                this.createNoteRow(displayNotes)
                            ]
                        }
                    );
                });

                scenarioRepeatingRows.push({ cells: [{ value: '' }] });
            });
        }

        return scenarioRepeatingRows;
    }

    public RPRBuildUp1Unlevered(studyGroup: SummaryStudyGroup[], displayNotes: boolean): any[] {
        const scenarioRepeatingRows: any[] = [];

        const getStudyGroupScenarios = this.queryScenario(
            studyGroup, {
            studyGroupName: 'Risk Premium',
            studyName: 'Build-up'
        }
        );

        if (getStudyGroupScenarios) {
            getStudyGroupScenarios.forEach((scenarioVal) => {

                scenarioVal.equations.forEach((eqVal) => {

                    // TODO: Change to use the Index array # instead of .context.startsWith since it could vary
                    const Ke = eqVal.parameters[0].value;
                    const Rf = eqVal.parameters[2].value;
                    const RPms = eqVal.parameters[4].value;
                    const ERPAdj = eqVal.parameters[6].value;

                    scenarioRepeatingRows.push(
                        {
                            cells: [
                                {
                                    value: eqVal.name,
                                    bold: 'true'
                                },
                                { value: ExcelExportUtil.toPercent(Ke) || 'Ke', format: '0.00%', textAlign: 'left' },
                                { value: '=', },
                                { value: ExcelExportUtil.toPercent(Rf) || 'Rf', format: '0.00%', textAlign: 'left' },
                                { value: '+', },
                                { value: ExcelExportUtil.toPercent(RPms) || 'RPms', format: '0.00%', textAlign: 'left' },
                                { value: '+', },
                                { value: ExcelExportUtil.toPercent(ERPAdj) || 'RPAdj', format: '0.00%', textAlign: 'left' },
                                this.createNoteRow(displayNotes)
                            ]
                        }
                    );
                });

                scenarioRepeatingRows.push({ cells: [{ value: '' }] });
            });
        }

        return scenarioRepeatingRows;
    }

    public RPRBuildup1Relevered(studyGroup: SummaryStudyGroup[], displayNotes: boolean): any[] {

        const scenarioRepeatingRows: any[] = [];

        const getStudyGroupScenarios = this.queryScenario(
            studyGroup, {
            studyGroupName: 'Risk Premium',
            studyName: 'Build-up'
        }
        );

        if (getStudyGroupScenarios) {
            getStudyGroupScenarios.forEach((scenarioVal) => {

                scenarioVal.equations.forEach((eqVal) => {

                    const Ke = eqVal.parameters[0].value;
                    const Rf = eqVal.parameters[2].value;
                    const RPms = eqVal.parameters[4].value;
                    const ERPAdj = eqVal.parameters[6].value;

                    scenarioRepeatingRows.push(
                        {
                            cells: [
                                {
                                    value: eqVal.name,
                                    bold: 'true'
                                },
                                { value: ExcelExportUtil.toPercent(Ke) || 'Ke', format: '0.00%', textAlign: 'left' },
                                { value: '=', },
                                { value: ExcelExportUtil.toPercent(Rf) || 'Rf', format: '0.00%', textAlign: 'left' },
                                { value: '+', },
                                { value: ExcelExportUtil.toPercent(RPms) || 'RPms', format: '0.00%', textAlign: 'left' },
                                { value: '+', },
                                { value: ExcelExportUtil.toPercent(ERPAdj) || 'RPAdj', format: '0.00%', textAlign: 'left' },
                                this.createNoteRow(displayNotes)
                            ]
                        }
                    );
                });

                scenarioRepeatingRows.push({ cells: [{ value: '' }] });
            });
        }

        return scenarioRepeatingRows;
    }

    public RPRBuildUp2(studyGroup: SummaryStudyGroup[], displayNotes: boolean): any[] {

        const scenarioRepeatingRows: any[] = [];

        const getStudyGroupScenarios = this.queryScenario(
            studyGroup, {
            studyGroupName: 'Risk Premium',
            studyName: 'Build-up 2'
        }
        );

        if (getStudyGroupScenarios) {
            getStudyGroupScenarios.forEach((scenarioVal) => {

                scenarioVal.equations.forEach((eqVal) => {

                    const Ke = eqVal.parameters[0].value;
                    const Rf = eqVal.parameters[2].value;
                    const ERP = eqVal.parameters[4].value;
                    const RPi = eqVal.parameters[6].value;
                    const RPs = eqVal.parameters[8].value;

                    scenarioRepeatingRows.push(
                        {
                            cells: [
                                {
                                    value: eqVal.name,
                                    bold: 'true'
                                },
                                { value: ExcelExportUtil.toPercent(Ke) || 'Ke', format: '0.00%', textAlign: 'left' },
                                { value: '=', },
                                { value: ExcelExportUtil.toPercent(Rf) || 'Rf', format: '0.00%', textAlign: 'left' },
                                { value: '+', },
                                { value: ExcelExportUtil.toPercent(ERP) || 'ERP', format: '0.00%', textAlign: 'left' },
                                { value: '+', },
                                { value: ExcelExportUtil.toPercent(RPi) || 'RPi', format: '0.00%', textAlign: 'left' },
                                { value: '+', },
                                { value: ExcelExportUtil.toPercent(RPs) || 'RPs', format: '0.00%', textAlign: 'left' },
                                this.createNoteRow(displayNotes)
                            ]
                        }
                    );
                });

                scenarioRepeatingRows.push({ cells: [{ value: '' }] });
            });
        }

        return scenarioRepeatingRows;
    }

    public RPRBuildUp3(studyGroup: SummaryStudyGroup[], displayNotes: boolean): any[] {

        const scenarioRepeatingRows: any[] = [];

        const getStudyGroupScenarios = this.queryScenario(
            studyGroup, {
            studyGroupName: 'Risk Premium',
            studyName: 'Build-up 3'
        }
        );

        if (getStudyGroupScenarios) {
            getStudyGroupScenarios.forEach((scenarioVal) => {

                scenarioVal.equations.forEach((eqVal) => {

                    const Ke = eqVal.parameters[0].value || 'Ke';
                    const Rf = eqVal.parameters[2].value || 'Rf';
                    const RPmc = eqVal.parameters[4].value || 'RPm+c';
                    const ERPAdj = eqVal.parameters[6].value || 'ERP';

                    scenarioRepeatingRows.push(
                        {
                            cells: [
                                {
                                    value: eqVal.name,
                                    bold: 'true'
                                },
                                { value: ExcelExportUtil.toPercent(Ke) || 'Ke', format: '0.00%', textAlign: 'left' },
                                { value: '=', },
                                { value: ExcelExportUtil.toPercent(Rf) || 'Rf', format: '0.00%', textAlign: 'left' },
                                { value: '+', },
                                { value: ExcelExportUtil.toPercent(RPmc) || 'RPm+c', format: '0.00%', textAlign: 'left' },
                                { value: '+', },
                                { value: ExcelExportUtil.toPercent(ERPAdj) || 'RPAdj', format: '0.00%', textAlign: 'left' },
                                this.createNoteRow(displayNotes)
                            ]
                        }
                    );
                });

                scenarioRepeatingRows.push({ cells: [{ value: '' }] });
            });
        }

        return scenarioRepeatingRows;
    }

    public createScenarioRowsHFRCAPM(studyGroup: SummaryStudyGroup[], hfrType: string, displayNotes: boolean): any[] {
        const scenarioRepeatingRows: any[] = [];

        const getStudyGroupScenarios = this.queryScenario(
            studyGroup, {
            studyGroupName: 'High Financial',
            studyName: 'CAPM + HFR'
        }
        );

        if (getStudyGroupScenarios) {
            getStudyGroupScenarios.forEach((scenarioVal) => {

                scenarioVal.equations.forEach((eqVal) => {

                    const Ke = eqVal.parameters[0].value;
                    const Rf = eqVal.parameters[2].value;
                    const B = eqVal.parameters[4].value;
                    const ERP = eqVal.parameters[6].value;
                    const RPs = eqVal.parameters[8].value;

                    scenarioRepeatingRows.push(

                        {
                            cells: [
                                {
                                    value: `${hfrType} z-Score`,
                                    bold: 'true'
                                },
                                { value: ExcelExportUtil.toPercent(Ke) || 'Ke', format: '0.00%', textAlign: 'left' },
                                { value: '=', },
                                { value: ExcelExportUtil.toPercent(Rf) || 'Rf', format: '0.00%', textAlign: 'left' },
                                { value: '+', },
                                { value: ExcelExportUtil.toNumber(B) || 'Beta', format: '0.00', textAlign: 'left' },
                                { value: '*', },
                                { value: ExcelExportUtil.toPercent(ERP) || 'ERP', format: '0.00%', textAlign: 'left' },
                                { value: '+', },
                                { value: ExcelExportUtil.toPercent(RPs) || 'RPs', format: '0.00%', textAlign: 'left' },
                                this.createNoteRow(displayNotes)
                            ]
                        }
                    );
                });

                scenarioRepeatingRows.push({ cells: [{ value: '' }] });
            });
        }

        return scenarioRepeatingRows;
    }
    public createScenarioRowsHFRBuildUp(studyGroup: SummaryStudyGroup[], hfrType: string, displayNotes: boolean): any[] {

        const scenarioRepeatingRows: any[] = [];

        const getStudyGroupScenarios = this.queryScenario(
            studyGroup, {
            studyGroupName: 'High Financial',
            studyName: 'Build-up HFR'
        }
        );

        if (getStudyGroupScenarios) {
            getStudyGroupScenarios.forEach((scenarioVal) => {

                scenarioVal.equations.forEach((eqVal) => {

                    const Ke = eqVal.parameters[0].value;
                    const Rf = eqVal.parameters[2].value;
                    const RPmc = eqVal.parameters[4].value;
                    const ERPAdj = eqVal.parameters[6].value;

                    scenarioRepeatingRows.push(

                        {
                            cells: [
                                {
                                    value: `${hfrType} z-Score`,
                                    bold: 'true'
                                },
                                { value: ExcelExportUtil.toPercent(Ke) || 'Ke', format: '0.00%', textAlign: 'left' },
                                { value: '=', },
                                { value: ExcelExportUtil.toPercent(Rf) || 'Rf', format: '0.00%', textAlign: 'left' },
                                { value: '+', },
                                { value: ExcelExportUtil.toPercent(RPmc) || 'RPm+c', format: '0.00%', textAlign: 'left' },
                                { value: '+', },
                                { value: ExcelExportUtil.toPercent(ERPAdj) || 'RPAdj', format: '0.00%', textAlign: 'left' },
                                this.createNoteRow(displayNotes)
                            ]
                        }
                    );
                });

                scenarioRepeatingRows.push({ cells: [{ value: '' }] });
            });
        }

        return scenarioRepeatingRows;
    }

    public createCostOfEquityGroups(equityGroups: EquitySummaryGroup[]): any[] {
        const scenarioRepeatingRows: any[] = [];

        equityGroups.forEach((group) => {
            scenarioRepeatingRows.push({
                cells: [
                    {
                        value: group.name,
                        bold: 'true'
                    },
                    {
                        value: group.average,
                        format: '0.00%'
                    },
                    {
                        value: group.median,
                        format: '0.00%'
                    }
                ]
            }
            );
        });

        scenarioRepeatingRows.push({ cells: [{ value: '' }] });

        return scenarioRepeatingRows;
    }

    public createWaccRow(waccEquations: WaccExcelEquations): any[] {
        const waccRow: any[] = [];

        waccRow.push({
            cells: [
                { value: waccEquations.weighted },
                { value: '=' },
                { value: waccEquations.costOfEquity },
                { value: '*' },
                { value: waccEquations.weightOfEquity },
                { value: '+' },
                { value: waccEquations.costOfDebt },
                { value: '*' },
                { value: waccEquations.weightOfDebt },
                { value: '*' },
                { value: `(1 - ${waccEquations.taxRate})` }
            ]
        });

        waccRow.push({
            cells: [
                { value: '' }
            ]
        },
            {
                cells: [
                    { value: `**based on ${waccEquations.costOfEquitySelectedContext} Ke selection` }
                ]
            },
            {
                cells: [
                    { value: '' }
                ]
            });

        return waccRow;
    }

    public buildExcelRows(rows: ExcelRow[]): any[] {
        let cells: any[] = [];

        rows.forEach((row) => {
            cells = [
                ...cells,
                {
                    height: normalCellHeight,
                    cells: [
                        { value: row.label, fontSize: normalFontSize },
                        { value: row.value, fontSize: normalFontSize, textAlign: 'right' }
                    ]
                }
            ];
        });

        return cells;
    }

    public createSelectionLog(selectionInputs: ExcelSummaryInput[]): any[] {
        const selectionCells: any[] = [];

        selectionInputs.forEach((selectionInput) => {
            selectionCells.push(
                {
                    height: normalCellHeight,
                    cells: [
                        { value: selectionInput.name, bold: 'true', fontSize: normalFontSize },
                        { value: selectionInput.value, fontSize: normalFontSize }
                    ]
                },
                {
                    height: smallCellHeight,
                    cells: [
                        { value: 'Type', bold: 'true', fontSize: normalFontSize },
                        { value: selectionInput.type, fontSize: normalFontSize }
                    ]
                },
                {
                    height: smallCellHeight,
                    cells: [
                        { value: 'Source(s) of Underlying Data', bold: 'true', fontSize: normalFontSize },
                        { value: selectionInput.source, fontSize: normalFontSize }
                    ]
                },
                {
                    height: smallCellHeight,
                    cells: [
                        { value: 'Data as of', bold: 'true', fontSize: normalFontSize },
                        { value: selectionInput.dataAsOf, fontSize: normalFontSize }
                    ]
                },
                {
                    height: smallCellHeight,
                    cells: [
                        { value: 'Notes', bold: 'true', fontSize: normalFontSize },
                        { value: '', fontSize: normalFontSize, background: '#fffb7a' }
                    ]
                },
                { cells: [{ value: '' }] }
            );
            if (selectionInput.name === 'Cost of Equity Capital') {
                selectionCells.splice(3, 1);
            }
        });

        return selectionCells;
    }
}
