import { Component, EventEmitter, OnChanges, Output, ViewChild } from '@angular/core';
import { NgbDate, NgbDatepickerConfig, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { CommonDate, DateStruct } from '../date-struct';
import { CommonUserInputComponent } from './common-user-input.component';

@Component({
    selector: 'common-datepicker',
    templateUrl: './common-datepicker.component.html'
})
export class CommonDatepickerComponent extends CommonUserInputComponent implements OnChanges {
    @ViewChild('d') public d!: NgbInputDatepicker;
    @Output() public dateSelected = new EventEmitter<NgbDate>();

    constructor(
        private datepickerConfig: NgbDatepickerConfig
    ) { super(); }

    // Handles edge cases such as dates outside min/max, or Feb 29th not existing in most years
    private convertToValidDate(struct: DateStruct): CommonDate {
        if (struct.month == null || struct.year == null || struct.day == null) {
            if (this.datepickerConfig.maxDate) {
                return CommonDate.fromStruct(this.datepickerConfig.maxDate);
            } else {
                const today = new Date();
                return CommonDate.fromDate(today);
            }
        }

        const currentTime = CommonDate.fromStruct(struct).asDate().getTime();
        const minimumTime = CommonDate.fromStruct(this.datepickerConfig.minDate).asDate().getTime();
        const maximumTime = CommonDate.fromStruct(this.datepickerConfig.maxDate).asDate().getTime();

        const validTime = Math.min(maximumTime, Math.max(minimumTime, currentTime));
        const validDate = new Date(validTime);

        return CommonDate.fromDate(validDate);
    }

    public ngOnChanges(): void {
        if (this.config == null) {
            return;
        }

        if (this.config.maximumValue != null && typeof this.config.maximumValue !== 'number') {
            this.datepickerConfig.maxDate = this.config.maximumValue;
        }

        if (this.config.minimumValue != null && typeof this.config.minimumValue !== 'number') {
            this.datepickerConfig.minDate = this.config.minimumValue;
        }
    }

    public closeDatepicker(datepicker: NgbInputDatepicker): void {
        datepicker.close();
    }

    // Navigating the month/year does not actually update model, so we force model change
    public navigate(event: { current: DateStruct; next: DateStruct; }): void {
        if (this.model.value == null) {
            return;
        }

        if (event.next.day == null) {
            const date = {
                year: event.next.year,
                month: event.next.month,
                day: this.model.value.day
            };
            this.model.value = this.convertToValidDate(date).asStruct();
            this.emitChange(true);
            if (this.d.isOpen() === false) {
                setTimeout(() => {
                    this.d.open();
                });
            }
        }
    }

    public dateSelection = (ngbDate: NgbDate): void => {
        this.dateSelected.emit(ngbDate);
    }
}
