import { EstimateSummary } from 'src/app/_api/responses/estimate-summary.response';
import { Estimate } from 'src/app/_navigator/data/model/estimate.model';
import { SelectionsUtil } from './selections.util';

export class IntlSummaryUtil {
    public static setSummarySelections(estimate: Estimate, summary: EstimateSummary): void {
        if (summary.CountryOfInputs == null || summary.CashFlowCountry == null) {
            return;
        }

        if (estimate.InvesteeCountryId === summary.CountryOfInputs.CountryId) {
            SelectionsUtil.setSingleCountryCapmSelections(estimate);
        } else {
            SelectionsUtil.setCostOfEquitySelections(estimate);
        }

        if (summary.CostOfDebtInputsCountry == null) {
            return;
        }

        if (estimate.InvesteeCountryId === summary.CostOfDebtInputsCountry.CountryId) {
            SelectionsUtil.setSingleCountryCostOfDebtSelections(estimate);
        } else {
            SelectionsUtil.setCostOfDebtSelections(estimate);
        }

        const applyKeFisherEffect = summary.CashFlowCountry.Currency.CurrencyId !== summary.CountryOfInputs.Currency.CurrencyId;
        const applyKdFisherEffect = summary.CashFlowCountry.Currency.CurrencyId !== summary.CostOfDebtInputsCountry.Currency.CurrencyId;

        if (estimate.InvesteeCountryId === summary.CountryOfInputs.CountryId
            && estimate.InvesteeCountryId === summary.CostOfDebtInputsCountry.CountryId
        ) {
            SelectionsUtil.setSingleCountryWaccSelections(estimate, applyKeFisherEffect, applyKdFisherEffect);
        } else {
            const useSingleCountryKd = estimate.InvesteeCountryId === summary.CostOfDebtInputsCountry.CountryId;
            SelectionsUtil.setWaccSelections(estimate, applyKeFisherEffect, applyKdFisherEffect, useSingleCountryKd);
        }
    }
}
