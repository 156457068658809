import { Injectable } from '@angular/core';
import { Action } from '@concurrency/core';
import { InputType } from 'src/app/_api/responses/input.response';
import { HelpText } from './help-text';
import { Help } from './help.model';

@Injectable()
export class HelpService {
    public activeHelpId?: string;
    public playExitAnimation = false;
    public playEntranceAnimation = false;
    public help?: Help;
    public canClose = true;
    public action: Action<void> = () => { };

    public toggle(id: string, help: Help): void {
        if (this.activeHelpId === id) {
            this.close();
        } else {
            this.open(id, help);
        }
    }

    public open(id: string, help: Help, canClose: boolean = true): void {
        this.activeHelpId = id;
        this.help = help;
        this.playEntranceAnimation = true;
        this.canClose = canClose;
        setTimeout(() => {
            this.playEntranceAnimation = false;
        }, 450);
    }

    public closeImmediately(): void {
        this.playExitAnimation = false;
        this.activeHelpId = undefined;
    }

    public close(): void {
        if (!this.playEntranceAnimation) {
            this.playExitAnimation = true;
            setTimeout(() => {
                this.playExitAnimation = false;
                this.activeHelpId = undefined;
            }, 400); // Wait for css animation
        }
    }

    public setAction(action: Action<void>): void {
        this.action = action;
    }

    public takeAction(): void {
        this.action();
    }

    public closeFromOutside(): void {
        if (this.canClose) {
            this.close();
        }
    }

    public getHelp(inputType: InputType): Help {
        switch (inputType) {
            case InputType.MarketValueOfCommonEquity:
                return HelpText.MarketValueOfEquity;

            case InputType.BookValueOfEquity:
                return HelpText.BookValueOfEquity;

            case InputType.FiveYearAverageNetIncome:
                return HelpText.FiveYearAverageNetIncome;

            case InputType.MarketValueOfInvestedCapital:
                return HelpText.MarketValueOfInvestedCapital;

            case InputType.TotalAssets:
                return HelpText.TotalAssets;

            case InputType.FiveYearAverageEbitda:
                return HelpText.FiveYearAverageEBITDA;

            case InputType.NetSales:
                return HelpText.NetSales;

            case InputType.NumberOfEmployees:
                return HelpText.NumberOfEmployees;

            case InputType.CurrentYearEbit:
                return HelpText.CurrentYearAverageEBITDA;

            case InputType.CurrentAssets:
                return HelpText.CurrentAssets;

            case InputType.CurrentLiabilities:
                return HelpText.CurrentLiabilities;

            case InputType.RetainedEarnings:
                return HelpText.RetainedEarnings;

            case InputType.ZScore:
                return HelpText.YourZScore;

            default:
                throw Error(`Expected a help type to be defined for InputType: ${InputType[inputType]}`);
        }
    }
}
