import { CountryRiskPremia } from 'src/app/_api/responses/country-risk-premia.response';
import { Help } from 'src/app/_navigator/help/help.model';
import { DynamicText } from '../model/dynamic-text';

export class DynamicTextUtilities {
    public static updateDynamicText(
        dynamicText: DynamicText[],
        itemToFind: string,
        shortText: string,
        longText: Help,
        shortTextVariable?: string
    ): DynamicText[] {
        const item = dynamicText.filter((x) => typeof x.shortText === 'string' && x.shortText.indexOf(itemToFind) > -1);

        if (item[0] !== undefined) {
            const index = dynamicText.indexOf(item[0]);
            dynamicText[index] = DynamicTextUtilities.addToDynamicText(`${shortText}`, longText, shortTextVariable);
        } else {
            dynamicText.push(DynamicTextUtilities.addToDynamicText(`${shortText}`, longText, shortTextVariable));
        }

        return dynamicText;
    }

    public static getCrpModelsAvailable(crp: CountryRiskPremia[]): Set<string> {
        const modelsAvailable = new Set<string>();
        const ccr = crp.find((x) => x.CcrAvg !== null && x.CcrMedian !== null);
        const rv = crp.find((x) => x.VolatilityAvg !== null && x.VolatilityMedian !== null);
        const ys = crp.find((x) => x.YieldSpreadAvg !== null && x.YieldSpreadMedian !== null);

        if (ccr) {
            modelsAvailable.add('Country Credit Rating Model');
        }
        if (rv) {
            modelsAvailable.add('Relative Volatility Model');
        }
        if (ys) {
            modelsAvailable.add('Country Yield Spread Model');
        }

        return modelsAvailable;
    }

    private static addToDynamicText(shortText: string, longText: Help, shortTextVariable?: string): DynamicText {
        return {
            shortText,
            shortTextVariable,
            longText
        };
    }
}
