import { ComponentFactoryResolver, ViewChild, ViewContainerRef, Directive } from '@angular/core';
import { AdditionalEquation } from '../additional-equation.model';
import { ScenarioStudyComponent } from './scenario-study.component';


@Directive()
export abstract class ScenarioMultiStudyComponent extends ScenarioStudyComponent {

    protected abstract additionalEquations: AdditionalEquation[];
    protected equationState = 'levered';
    protected equationComponent: any;
    protected equationInstance: any;
    @ViewChild('equationcontainer', { read: ViewContainerRef }) public equationcontainer!: ViewContainerRef;

    constructor(
        protected resolver: ComponentFactoryResolver
    ) { super(); }

    public lever(): void {
        this.equationState = 'levered';
        if (this.equationComponent) {
            this.equationComponent.destroy();
        }
        this.equationInstance = null;
    }

    public gotoEquation(equation: AdditionalEquation): void {
        if (this.equationComponent) {
            this.equationComponent.destroy();
        }
        this.equationState = equation.name;
        const component = this.resolver.resolveComponentFactory(equation.component);
        this.equationComponent = this.equationcontainer.createComponent(component);
        this.equationInstance = this.equationComponent.instance;
        this.equationInstance.equations = this.equations;
    }
}
