import { ContextualNumber, Util } from '@concurrency/core';
import { SelectionType } from 'src/app/_api/enums/selection-type';
import { Equation, EquationType, ImplicationType } from 'src/app/_api/responses/equation.response';
import { InputType } from 'src/app/_api/responses/input.response';
import { ScenarioResponse } from 'src/app/_api/responses/scenario.response';
import { Selection } from 'src/app/_api/responses/selection.response';
import { SuggestionResponse } from 'src/app/_api/responses/suggestion.response';
import { EquationItem, EquationOperand, EquationResult } from './equation.model';
import { Estimate } from './estimate.model';
import { Suggestion } from './suggestion.model';

export class Scenario implements ScenarioResponse {
    public Id!: string;
    public EstimateId!: string;
    public Name!: string;
    public Selections!: Selection[];
    public Equations!: Equation[];
    public UseRegressionCalculation!: boolean;
    public Created!: Date;
    public Updated!: Date;

    private readonly KROLL_RECOMMENDED_STR = '[Kroll-Normalized]';

    constructor(data: ScenarioResponse) {
        Object.assign(this, data);
    }

    private getSuggestedErp(suggested: SuggestionResponse[]): Suggestion<number>[] {
        const riskFreeRate = this.getSelection(SelectionType.RiskFreeRate, InputType.None);
        const suggestedRfr = suggested.filter((x) => x.SelectionType === SelectionType[SelectionType.RiskFreeRate])
            .map(this.mapSuggestedInput);
        const suggestedErp = suggested.filter((x) => x.SelectionType === SelectionType[SelectionType.EquityRiskPremium])
            .map(this.mapSuggestedInput);

        const normalizedRateAvailable = suggestedRfr.find((x) => x.source === 'Duff & Phelps Normalized Rate' || x.source === 'Kroll Normalized Risk-free Rate') || false;
        const isCustomRiskFreeRate = riskFreeRate.Context === 'Custom';
        const isNormalizedRate = riskFreeRate.Context === 'Kroll Normalized Risk-free Rate';

        if (normalizedRateAvailable && isCustomRiskFreeRate) {
            if (normalizedRateAvailable.sourceName?.includes(this.KROLL_RECOMMENDED_STR)) {
                return suggestedErp;
            }

        }
        // TODO: Move this text elsewhere
        if (isNormalizedRate) {
            const disabledText = `The normalized risk-free rate may only be used with the Kroll Recommended ERP. ` + // `
                `If you would like to use a different ERP, please select a different risk-free rate.`; // `

            suggestedErp.filter((x) => ((x.source !== 'Duff & Phelps Recommended') && (x.source !== 'Kroll Recommended')))
                .forEach((x) => x.disabledText = disabledText);
        }

        if (!isNormalizedRate && normalizedRateAvailable && !normalizedRateAvailable.sourceName?.includes(this.KROLL_RECOMMENDED_STR)) {
            const disabledText = `Kroll Recommended ERP may only be used with the normalized risk-free rate ` + // `
                `as of this valuation date. To use the DP Recommended ERP, please select the normalized risk-free rate.`; // `

            suggestedErp.filter((x) => (x.source === 'Duff & Phelps Recommended' || x.source === 'Kroll Recommended'))
                .forEach((x) => x.disabledText = disabledText);
        }

        if (!normalizedRateAvailable && isCustomRiskFreeRate) {
            const disabledText = `Kroll Recommended ERP may only be used with the spot risk-free rate as ` + // `
                `of this valuation date. To use the DP Recommended ERP, please select the spot risk-free rate.`; // `

            suggestedErp.filter((x) => (x.source === 'Duff & Phelps Recommended' || x.source === 'Kroll Recommended'))
                .forEach((x) => x.disabledText = disabledText);
        }

        return suggestedErp;
    }

    private mapSuggestedInput = (apiModel: SuggestionResponse): Suggestion<number> => {
        return {
            name: apiModel.Name,
            value: apiModel.Value,
            source: apiModel.Source,
            sourceName: apiModel.SourceName,
            dataAsOf: apiModel.DataAsOf
        };
    }

    private getIndustryCodeFromSelection(estimate: Estimate, selection: Selection): { sicCode: string | null, gicCode: string | null } {
        const matchingSuggestion: SuggestionResponse | undefined = estimate.Suggestions.find(
            (x) => x.SelectionType === selection.SelectionType && x.Value === selection.Value
        );

        if (matchingSuggestion) {
            return {
                sicCode: matchingSuggestion.SicCode,
                gicCode: matchingSuggestion.GicCode
            };
        }

        return {
            sicCode: null,
            gicCode: null
        };
    }

    public getEquation(equationType: EquationType, implicationType: ImplicationType): Equation {
        if (equationType == null || implicationType == null) {
            throw new Error(`EquationType and ImplicationType must not be null`);
        }

        let equation = this.Equations.find((x) => x.ImplicationType === implicationType &&
            x.EquationType === equationType);
        if (equation == null) {
            equation = {
                ScenarioId: this.Id,
                EquationType: equationType,
                ImplicationType: implicationType,
                Created: new Date(),
                Updated: new Date()
            };
            this.Equations.push(equation);
        }

        return equation;
    }

    public getValueOrNaN(selectionType: SelectionType, inputType: InputType): number {
        if (selectionType == null || inputType == null) {
            throw new Error(`SelectionType and InputType must not be null`);
        }

        return this.getSelectionAsContextualNumber(selectionType, inputType).asNumberOrNaN;
    }

    public hasValue(selectionType: SelectionType): boolean {
        if (selectionType == null) {
            throw new Error(`SelectionType must not be null`);
        }

        const parameter = this.getSelection(selectionType, InputType.None);

        return parameter.Value != null;
    }

    public getSelection(selectionType: SelectionType, inputType: InputType): Selection {
        if (selectionType == null || inputType == null) {
            throw new Error(`SelectionType and InputType must not be null`);
        }

        let selection = this.Selections.find(
            (x) => x.SelectionType === selectionType && x.InputType === inputType
        );
        if (selection == null) {
            selection = {
                SelectionType: selectionType,
                InputType: inputType,
                Created: new Date(),
                Updated: new Date()
            };
            this.Selections.push(selection);
        }
        return selection;
    }

    public getSelectionAsContextualNumber(selectionType: SelectionType, inputType: InputType): ContextualNumber {
        if (selectionType == null || inputType == null) {
            throw new Error(`SelectionType and InputType must not be null`);
        }

        const parameter = this.getSelection(selectionType, inputType);
        const result = new ContextualNumber(parameter.Value, parameter.Context, parameter.SourceDataAsOf);
        result.source = parameter.SourceTable ? parameter.SourceTable : null;

        return result;
    }

    public setSelection(data: ContextualNumber, selectionType: SelectionType, inputType: InputType): void {
        if (selectionType == null || inputType == null) {
            throw new Error(`SelectionType and InputType must not be null`);
        }

        const parameter = this.getSelection(selectionType, inputType || InputType.None);
        parameter.Value = data.asNumber;
        parameter.Context = data.context;
        parameter.SourceDataAsOf = data.dataAsOf;
        parameter.SourceTable = data.source;
    }

    public getSuggestions(suggested: SuggestionResponse[], selectionType: SelectionType): Suggestion<number>[] {
        if (selectionType == null) {
            throw new Error(`SelectionType must not be null`);
        }

        if (selectionType === SelectionType.EquityRiskPremium) {
            return this.getSuggestedErp(suggested || []);
        }

        return (suggested || []).filter((x) => x.SelectionType === selectionType).map(this.mapSuggestedInput);
    }

    public getItemAsContextualNumber(item: EquationItem): ContextualNumber {
        const operand = (item as EquationOperand);
        if (operand.selectionType) {
            return this.getSelectionAsContextualNumber(operand.selectionType, operand.inputType || InputType.None);
        } else {
            const equationResult = item as EquationResult;
            const equation = this.getEquation(equationResult.equationType, equationResult.implicationType);
            return new ContextualNumber(equation.Result);
        }
    }

    public calculateEquityRiskPremiumAdjustment(historicRprErp?: number): void {
        const erp = this.getSelection(SelectionType.EquityRiskPremium, InputType.None);
        const erpa = this.getSelection(SelectionType.EquityRiskPremiumAdjustment, InputType.None);

        if (erp.Value == null || historicRprErp == null) {
            erpa.Value = undefined;
            erpa.Context = undefined;
        } else {
            const calculatedErpa = erp.Value - (historicRprErp * 100);
            erpa.Value = Util.round(calculatedErpa);
            erpa.Context = `Equity Risk Premium Adjustment Based On ${erp.Context}`; // `
            erpa.SourceDataAsOf = erp.SourceDataAsOf;
        }
    }

    // TODO: Cleanup the duplication of effort in this method
    public calculateIndustryRiskPremium(estimate: Estimate): void {
        const erp = this.getSelection(SelectionType.EquityRiskPremium, InputType.None);
        const crspBeta = this.getSelection(SelectionType.CrspDecilesBeta, InputType.None);
        const crspIrp = this.getSelection(SelectionType.CrspIndustryRiskPremium, InputType.None);
        const rprBeta = this.getSelection(SelectionType.RiskPremiumBeta, InputType.None);
        const rprIrp = this.getSelection(SelectionType.RprIndustryRiskPremium, InputType.None);

        if (crspBeta.Value == null || erp.Value == null) {
            crspIrp.Value = undefined;
            crspIrp.Context = undefined;
        } else {
            const isCustom = crspBeta.Context === 'Custom';
            const betaTimesErp = Util.round(crspBeta.Value * erp.Value);
            const value = Util.round(betaTimesErp - erp.Value);
            const industryCodeFromSelection = this.getIndustryCodeFromSelection(estimate, crspBeta);
            const sicCode = industryCodeFromSelection.sicCode;
            const gicCode = industryCodeFromSelection.gicCode;

            let source = '';

            if (isCustom) {
                source = 'Custom';
            } else {
                source = sicCode ? `SIC ${sicCode} Industry Risk Premium` : `GICS ${gicCode} Industry Risk Premium`;
            }

            crspIrp.Value = value;
            crspIrp.Context = source;
            crspIrp.SourceDataAsOf = crspBeta.SourceDataAsOf;
        }

        if (rprBeta.Value == null || erp.Value == null) {
            rprIrp.Value = undefined;
            rprIrp.Context = undefined;
        } else {
            const isCustom = rprBeta.Context === 'Custom';
            const betaTimesErp = Util.round(rprBeta.Value * erp.Value);
            const value = Util.round(betaTimesErp - erp.Value);
            const industryCodeFromSelection = this.getIndustryCodeFromSelection(estimate, rprBeta);
            const sicCode = industryCodeFromSelection.sicCode;
            const gicCode = industryCodeFromSelection.gicCode;

            let source = '';

            if (isCustom) {
                source = 'Custom';
            } else {
                source = sicCode ? `SIC ${sicCode} Industry Risk Premium` : `GICS ${gicCode} Industry Risk Premium`;
            }

            rprIrp.Value = value;
            rprIrp.Context = source;
            rprIrp.SourceDataAsOf = rprBeta.SourceDataAsOf;
        }
    }
}
