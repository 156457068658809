import { Component, HostListener, OnInit } from '@angular/core';
import { HelpText } from 'src/app/_navigator/help/help-text';
import { BetaService } from '../beta.service';

@Component({
    selector: 'beta-statistics',
    templateUrl: './beta-statistics.component.html',
    styleUrls: ['./beta-statistics.component.scss']
})
export class BetaStatisticsComponent {
    public collapse = false;
    public helpText = HelpText;
    constructor(public betaService: BetaService) { }

    public toggleCollapse(): void {
        this.collapse = !this.collapse;

    }

    @HostListener('window:scroll', [])
    public onWindowScroll(): void {
        const duplicate = document.getElementById('duplicateBS');
        const betaStatisticsParent = document.getElementById('betaStatisticsParent');
        // to stick collapsed section while scrolling to right
        const collapsedItem = document.getElementById('betaStatisticsCollapsed');
        const scrollLeftValue = 5;

        let top = 0;
        const upperPageHeight = 389;
        if (betaStatisticsParent != null) {
            top = betaStatisticsParent.clientHeight + betaStatisticsParent.getBoundingClientRect().top - upperPageHeight;
            if (!this.collapse) {
                // to stick collapsed section while scrolling to right
                if (document.body.scrollLeft > scrollLeftValue || document.documentElement.scrollLeft > scrollLeftValue) {
                    if (collapsedItem != null) {
                        collapsedItem.classList.add('fixed-column');
                        collapsedItem.style.display = 'inline';
                        collapsedItem.style.top = top + 358 + 'px';
                    }
                } else {
                    if (collapsedItem != null) {
                        collapsedItem.style.display = 'none';
                    }
                }
                // end code
                return;
            }
        }
        const topValue = top - 9 + 'px';
        if (document.body.scrollLeft > scrollLeftValue || document.documentElement.scrollLeft > scrollLeftValue) {
            if (duplicate != null) {
                duplicate.classList.add('fixed-column');
                duplicate.style.display = 'inline';
                duplicate.style.top = topValue;
            }
        } else {
            if (duplicate != null) {
                duplicate.style.display = 'none';
            }
            if (collapsedItem != null) {
                collapsedItem.style.display = 'none';
            }
        }
    }
}
