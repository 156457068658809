import { Component, Input, OnInit } from '@angular/core';
import { TearSheetContent } from './models/tear-sheet-content.model';
import { TrendsOverTimeService } from './data/trends-over-time.service';

@Component({
    selector: 'industry-input-table',
    templateUrl: './industry-input-table.component.html',
})
export class IndustryInputTableComponent {


    constructor(private trendsOverTimeService: TrendsOverTimeService) {

    }

    @Input() tearsheetData!: TearSheetContent;
}
