import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'datapoints-offerd',
    templateUrl: './datapoints-offerd.component.html',
    styleUrls: ['./datapoints-offerd.component.scss']
})
export class DatapointsOfferdComponent {

    constructor() { }




}
