import { UserSubscriptionDetails } from './dashboard-module.model';

export const usCocSubscriptionDetails: UserSubscriptionDetails = {
    Subscription: 'U.S. Cost of Capital Module',
    Status: 'Inactive',
    Expiration: '',
    GeographicCoverage: 'U.S',
    DatasetsIncluded: 'U.S. Cost of Capital Inputs, U.S. Industry Benchmarking, U.S. Company-level Betas',
    DatapointsOffered: '',
    EarliestCoverage: '1999',
    SampleData: ''

}

export const inCocSubscriptionDetails: UserSubscriptionDetails = {
    Subscription: 'International Cost of Capital Module',
    Status: 'Inactive',
    Expiration: '',
    GeographicCoverage: 'International',
    DatasetsIncluded: 'International Cost of Capital Inputs, International Industry Benchmarking, International Company-level Betas',
    DatapointsOffered: '',
    EarliestCoverage: '2014',
    SampleData: ''

}