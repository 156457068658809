import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { environment } from './environments/environment';

@Injectable()
export class MyMonitoringService {
    private appInsights!: ApplicationInsights;
    constructor() { }
    public startMonitoring(): void {
        this.appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: environment.applicationInsights.key,
                enableAutoRouteTracking: true, // option to log all route changes
                disableTelemetry: !!navigator.webdriver
                // maxBatchInterval: navigator.webdriver ? 1000 : 10000
            }
        });
        if (!this.appInsights.config.disableTelemetry) {
            this.appInsights.loadAppInsights();
        }
    }

    public logException = (exception: Error, severityLevel?: number): void => {
        this.appInsights.trackException({ exception, severityLevel });
    }
}
