import { Component, OnInit } from '@angular/core';
import { Spinner, SubscriberEntity } from '@concurrency/angular';
import { Select } from '@ngxs/store';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { UserClient } from 'src/app/_api/clients/user.client';
import { BetaEstimate } from 'src/app/_navigator/data/model/beta-estimate.model';
import { BetaEstimatesListState } from 'src/app/_navigator/estimates-list-store/beta-estimates-list-state';
import { AccessType } from 'src/app/_navigator/user/enum/access-type';

@Component({
    selector: 'beta-estimates',
    templateUrl: './beta-estimates.component.html'
})
export class BetaEstimatesComponent extends SubscriberEntity implements OnInit {
    private _access = new BehaviorSubject<AccessType | undefined>(undefined);
    public moduleEstimates!: BetaEstimate[] | undefined;
    public access = this._access.asObservable();
    public hideestimates = false;
    @Select(BetaEstimatesListState.get) public estimates!: Observable<BetaEstimate[] | undefined>;

    constructor(private spinner: Spinner, private userProfileClient: UserClient) {
        super();
    }

    public ngOnInit(): void {
        this.estimates.onceDefined((estimates: BetaEstimate[]) => {
            if (estimates.length > 0) {
                this.moduleEstimates = estimates;
            }
        });
        const profileRequest = this.userProfileClient.read();
        this.spinner.while(profileRequest);
        profileRequest.onceDefined((profile) => {
            for (const i of profile.Subscriptions) {
                if (i.Type === 'BtaBasic' || i.Type === 'BtaTrial' || i.Type === 'BtaPro' || i.Type === 'BtaEnterprise') {
                    const isAuthorized = new Date(i.End) > new Date();
                    this.hideestimates = isAuthorized;
                }
            }
        });
    }
}
