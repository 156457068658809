export class SetAllCountries {
    public static type = '[Country API] Set All Countries';
}

export class SetInvestorCountries {
    public static type = '[Country API] Set Investors Countries';
}

export class SetInvesteeCountries {
    public static type = '[Country API] Set Investee Countries';
    constructor(public dataAsOf: string, public investorCountryId: number) { }
}
