import { krollblue, krollshadow } from 'src/app/estimate/exports/summary-export/util/summary-helpers';
import { ReleveredInputs, Statistics } from '../beta.types';
import { valueConversion } from './levered-betas';

export function getBetaType(releveredStats: ReleveredInputs): any[] {
    const betaType: any[] = [];
    if (releveredStats) {
        betaType.push(
            { value: '' },
            {
                value: 'Beta Type'
            },
            { value: '' },
            {
                value: releveredStats.BetaType
            }
        );
    }
    return betaType;
}

export function getTargetCapital(releveredStats: ReleveredInputs): any[] {
    const targetCapital: any[] = [];
    if (releveredStats) {
        targetCapital.push(
            { value: '' },
            {
                value: 'Target Capital Structure (D/TC)'
            },
            { value: '' },
            {
                value: releveredStats.OriginalTargetCapitalStructure != undefined ? valueConversion(releveredStats.OriginalTargetCapitalStructure / 100) : '',
                format: releveredStats.OriginalTargetCapitalStructure != undefined ? '0.00%' : ''
            },
            {
                value: releveredStats.TargetLabel,
                colSpan: 3
            }
        );
    }
    return targetCapital;
}

export function getReleveredTaxRate(releveredStats: ReleveredInputs): any[] {
    const taxRate: any[] = [];
    if (releveredStats) {
        taxRate.push(
            { value: '' },
            {
                value: 'Tax Rate'
            },
            { value: '' },
            {
                value: releveredStats.TaxRate != undefined ? valueConversion(releveredStats.TaxRate / 100) : '',
                format: releveredStats.TaxRate != undefined ? '0.00%' : ''
            },
            {
                value: releveredStats.TaxRateLabel,
                colSpan: 2
            }
        );
    }
    return taxRate;
}

export function getReleveredBetaTitles(): any[] {
    const titles: any[] = [];
    titles.push(
        { value: '' },
        {
            value: '',
            borderBottom: { size: 1, color: krollshadow }
        },
        {
            value: '',
            borderBottom: { size: 1, color: krollshadow }
        },
        {
            value: 'Minimum',
            borderBottom: { size: 1, color: krollshadow }
        },
        {
            value: 'First Quartile',
            borderBottom: { size: 1, color: krollshadow }
        },
        {
            value: 'Average',
            borderBottom: { size: 1, color: krollshadow }
        },
        {
            value: 'Median',
            borderBottom: { size: 1, color: krollshadow }
        },
        {
            value: 'Third Quartile',
            borderBottom: { size: 1, color: krollshadow }
        },
        {
            value: 'Maximum',
            borderBottom: { size: 1, color: krollshadow }
        }
    );
    return titles;
}

export function getStatistics(releveredStats: Statistics, name: string): any[] {
    const stats: any[] = [];
    if (releveredStats) {
        stats.push(
            { value: '' },
            {
                value: name,
                bold: true,
                color: krollblue
            },
            { value: '' },
            {
                value: valueConversion(releveredStats.Minimum),
                bold: true,
                color: krollblue,
                format: '0.00'
            },
            {
                value: valueConversion(releveredStats.FirstQuartile),
                bold: true,
                color: krollblue,
                format: '0.00'
            },
            {
                value: valueConversion(releveredStats.Average),
                bold: true,
                color: krollblue,
                format: '0.00'
            },
            {
                value: valueConversion(releveredStats.Median),
                bold: true,
                color: krollblue,
                format: '0.00'
            },
            {
                value: valueConversion(releveredStats.ThirdQuartile),
                bold: true,
                color: krollblue,
                format: '0.00'
            },
            {
                value: valueConversion(releveredStats.Maximum),
                bold: true,
                color: krollblue,
                format: '0.00'
            }
        );
    }
    return stats;
}
