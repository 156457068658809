import { ComponentFactoryResolver, Injectable, Type, ViewContainerRef } from '@angular/core';
import { Dictionary } from '@concurrency/core';

@Injectable({
    providedIn: 'root'
})
export class PrimeManager {
    private host?: ViewContainerRef;

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver
    ) { }

    public setHost(viewContainerRef: ViewContainerRef): void {
        this.host = viewContainerRef;
    }

    public openDialog(modal: Type<{}>, inputs: Dictionary<any> = {}): void {
        if (this.host == null) {
            throw new Error(`Expected setHost to have been called before attempting to open a modal`);
        }
        this.host.clear();
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(modal);

        const componentRef = this.host.createComponent(componentFactory);
        const inst = componentRef.instance as any;

        // eslint-disable-next-line guard-for-in
        for (const index in inputs) {
            inst[index] = inputs[index];
        }
        // TODO: handle @outputs/event emmiters
    }
}
