// TODO: All this API stuff should be off in its own NPM package
import { Injectable } from '@angular/core';
import { Dictionary, Util } from '@concurrency/core';
import { Select } from '@ngxs/store';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { BetaClient } from 'src/app/_api/clients/beta.client';
import { BetaEstimateClient } from 'src/app/_api/clients/betaEstimate.client';
import { CostOfEquityClient } from 'src/app/_api/clients/costOfEquity.client';
import { CountryClient } from 'src/app/_api/clients/country.client';
import { CurrencyClient } from 'src/app/_api/clients/currency.client';
import { EstimateClient } from 'src/app/_api/clients/estimate.client';
import { IndustryClient } from 'src/app/_api/clients/industry.client';
import { IntlTaxRateClient } from 'src/app/_api/clients/intlTaxRate.client';
import { MeasureClient } from 'src/app/_api/clients/measure.client';
import { RiskClient } from 'src/app/_api/clients/risk.client';
import { ScenarioClient } from 'src/app/_api/clients/scenario.client';
import { USCompanyClient } from 'src/app/_api/clients/uscompany.client';
import { USIndustryClient } from 'src/app/_api/clients/usindustry.client';
import { EstimateType } from 'src/app/_api/enums/estimate-type';
import { SelectionType } from 'src/app/_api/enums/selection-type';
import { BetaEstimateResponse } from 'src/app/_api/responses/beta-estimate.response';
import { CompanyReturns, GicIndustry, OLSBetaCompanyReturns } from 'src/app/_api/responses/company-returns.response';
import { CountryCreditRating } from 'src/app/_api/responses/country-credit-rating.response';
import { CountryRiskPremia } from 'src/app/_api/responses/country-risk-premia.response';
import { Country } from 'src/app/_api/responses/country.response';
import { Currency } from 'src/app/_api/responses/currency.response';
import { Decile, DecileData } from 'src/app/_api/responses/decile.response';
import { EstimateResponse } from 'src/app/_api/responses/estimate.response';
import { IndustryTearSheet } from 'src/app/_api/responses/industry-tear-sheet.response';
import { InputType } from 'src/app/_api/responses/input.response';
import { IntlData, IntlDataUnlevering } from 'src/app/_api/responses/intl-data.response';
import { MetricInputs } from 'src/app/_api/responses/metric-inputs.response';
import { MsciMetrics } from 'src/app/_api/responses/msci-metrics.response';
import { MsciReturns } from 'src/app/_api/responses/msci-returns.response';
import { Portfolio } from 'src/app/_api/responses/portfolio.response';
import { ScenarioResponse } from 'src/app/_api/responses/scenario.response';
import { Selection } from 'src/app/_api/responses/selection.response';
import { SuggestionResponse } from 'src/app/_api/responses/suggestion.response';
import { UsCompanyData } from 'src/app/_api/responses/us-company.response';
import { Industry } from 'src/app/_api/responses/us-industry.response';
import { ZScore } from 'src/app/_api/responses/zscore.response';
import { IndustryScenario } from 'src/app/benchmarking/data/industry-scenario';
import { TrendsOverTimeData } from 'src/app/benchmarking/data/trends-over-time-data';
import { TrendsOverTimeRequest } from 'src/app/benchmarking/data/trends-over-time-request';
import { BetaComparbleCompanyRequest, BetaComparbleCompanyResults, BetaEstimateRequest, BetaStatistics, BetaToT, CompanyInformation, CompanyReturnsRequest, CompanyReturnsRequestWithGVkeyStatus, CompanyselectedChangesResults, CompanyValuationInputs, LeveredBetas, UnleveringTaxRateInputs } from 'src/app/beta/beta.types';
import { Summary } from '../../../estimate/results/summary'; // TODO: Ideally this would be in the _navigator folder
import { EventManager } from '../../event.manager';
import { SuggestionListState } from '../../suggestion-list-store/suggestion-list-state';
import { Analysis } from '../model/analysis.model';
import { BetaEstimate } from '../model/beta-estimate.model';
import { EditorSettings } from '../model/editor-settings.model';
import { EquationOperand } from '../model/equation.model';
import { Estimate } from '../model/estimate.model';
import { Scenario } from '../model/scenario.model';

// TODO: Replace this concrete implementation with a generic implementation (e.g. Ngxs).
@Injectable()
export class DataStore extends EventManager {
    private _betaEstimate = new BehaviorSubject<BetaEstimateResponse | undefined>(undefined);
    private _estimate = new BehaviorSubject<EstimateResponse | undefined>(undefined);
    private _countries = new BehaviorSubject<Country[] | undefined>(undefined);
    private _currencies = new BehaviorSubject<Currency[] | undefined>(undefined);
    private _industries = new BehaviorSubject<Industry[] | undefined>(undefined);
    private _industriesInt = new BehaviorSubject<Industry[] | undefined>(undefined);
    private _CreditTrends = new BehaviorSubject<CountryRiskPremia[] | undefined>(undefined);
    private _ccr = new BehaviorSubject<CountryCreditRating[] | undefined>(undefined);
    private _country = new BehaviorSubject<Country[] | undefined>(undefined);
    private _editorSettings = new BehaviorSubject<EditorSettings | undefined>(undefined);
    private _lastEditorHoveredId = new BehaviorSubject<string | undefined>(undefined);
    private _metrics = new BehaviorSubject<MsciMetrics[] | undefined>(undefined);
    private _returns = new BehaviorSubject<MsciReturns[] | undefined>(undefined);
    private _summary = new BehaviorSubject<Summary | undefined>(undefined);
    private _portfolios = new BehaviorSubject<Portfolio[] | null | undefined>(undefined);
    private _zscore = new BehaviorSubject<ZScore | null | undefined>(undefined);
    private _isIndustryAnalysis = new BehaviorSubject<boolean | undefined>(undefined);
    private _analysis = new BehaviorSubject<Analysis | undefined>(undefined);
    private _companyData = new BehaviorSubject<UsCompanyData | undefined>(undefined);
    private _benchmarkingAnalysis = new BehaviorSubject<IndustryTearSheet[] | undefined>(undefined);
    private _trendsOverTimeData = new BehaviorSubject<TrendsOverTimeData[][] | undefined>(undefined);
    private _selectedCompanyMetrics = new BehaviorSubject<CompanyselectedChangesResults[] | undefined>(undefined);
    private _betasToT = new BehaviorSubject<BetaToT[] | undefined>(undefined);

    public estimate = this._estimate.asObservable().pipe(
        map((x) => x == null ? undefined : new Estimate(x))
    );
    public betaEstimate = this._betaEstimate.asObservable().pipe(
        map((x) => x == null ? undefined : new BetaEstimate(x))
    );

    public metrics = this._metrics.asObservable();
    public returns = this._returns.asObservable();
    public countries = this._countries.asObservable();
    public currencies = this._currencies.asObservable();
    public industries = this._industries.asObservable();
    public industriesInt = this._industriesInt.asObservable();
    public credittrends = this._CreditTrends.asObservable();
    public countrycredit = this._ccr.asObservable();
    public country = this._country.asObservable();
    public editorSettings = this._editorSettings.asObservable();
    public lastEditorHoveredId = this._lastEditorHoveredId.asObservable();
    public summary = this._summary.asObservable();
    public isIndustryAnalysis = this._isIndustryAnalysis.asObservable();
    public analysis = this._analysis.asObservable();
    public companyData = this._companyData.asObservable();
    public benchmarkingAnalysis = this._benchmarkingAnalysis.asObservable();
    public trendsOverTimeData = this._trendsOverTimeData.asObservable();
    public selectedCompanyMetrics = this._selectedCompanyMetrics.asObservable();
    public betasToT = this._betasToT.asObservable();

    // NOTE: Portfolios and ZScore here are being lazy-loaded via API Request as soon as someone attempts to access them.
    // TODO: Using undefined/null to indicate whether the lazy-load is in progress is really lame
    // TODO: Disabling the tslint rules in order to lazily assume estimate won't be null is also really lame
    public portfolios = this._portfolios.asObservable().pipe(
        tap((data) => {
            if (data === undefined) {
                this._portfolios.next(null);
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                const request = this.riskClient.getPortfolios(this._estimate.value!.ValuationDate);
                request.once((portfolios) => this._portfolios.next(portfolios));
            }
        })
    );

    public zscore = this._zscore.asObservable().pipe(
        tap((data) => {
            if (data === undefined) {
                this._zscore.next(null);
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                const request = this.riskClient.getZscore(this._estimate.value!.ValuationDate);
                request.once((zscore) => this._zscore.next(zscore));
            }
        })
    );

    @Select(SuggestionListState.get) public suggestionListSelector!: Observable<SuggestionResponse[] | undefined>;

    constructor(
        // TODO: remove the country client
        private betaClient: BetaClient,
        private betaEstimateClient: BetaEstimateClient,
        private countryClient: CountryClient,
        private currencyClient: CurrencyClient,
        private usindustryClient: USIndustryClient,
        private industryClient: IndustryClient,
        private measureClient: MeasureClient,
        private scenarioClient: ScenarioClient,
        private estimateClient: EstimateClient,
        private riskClient: RiskClient,
        private usCompanyClient: USCompanyClient,
        private costofequity: CostOfEquityClient,
        private intlTaxrateClient: IntlTaxRateClient
    ) { super(); }

    // TODO: Why is this even in here? Can't an estimate review itself? Why does this call to validateScenario need to trigger something?
    // NOTE: Handles reviewing older estimates for missing SourceDataAsOf dates
    private reviewEstimate(estimate: Estimate): boolean {
        const selectionsWithSuggestions = [
            SelectionType[SelectionType.RiskFreeRate],
            SelectionType[SelectionType.EquityRiskPremium],
            SelectionType[SelectionType.CrspDecilesBeta],
            SelectionType[SelectionType.RiskPremiumBeta],
            SelectionType[SelectionType.HighFinancialRiskBeta]
        ];
        const selectionsWithIndirectSuggestions: Dictionary<string> = {};
        selectionsWithIndirectSuggestions[SelectionType[SelectionType.CrspIndustryRiskPremium]]
            = SelectionType[SelectionType.CrspDecilesBeta];
        selectionsWithIndirectSuggestions[SelectionType[SelectionType.RprIndustryRiskPremium]]
            = SelectionType[SelectionType.RiskPremiumBeta];
        selectionsWithIndirectSuggestions[SelectionType[SelectionType.EquityRiskPremiumAdjustment]]
            = SelectionType[SelectionType.EquityRiskPremium];

        let isReviewComplete = true;
        for (const scenario of estimate.Scenarios) {
            this.validateScenario(estimate, scenario);
            const filter = (x: Selection) => x.SourceDataAsOf == null && x.Context != null && x.Context.indexOf('Custom') === -1;
            const selectionsNeedingReview = scenario.Selections.filter(filter);
            for (const selectionNeedingReview of selectionsNeedingReview) {
                if (selectionsWithSuggestions.find((x) => x === selectionNeedingReview.SelectionType)) {
                    const find = (x: SuggestionResponse) => x.SelectionType === selectionNeedingReview.SelectionType &&
                        x.Source === selectionNeedingReview.Context;
                    const suggestion = estimate.Suggestions.find(find);
                    selectionNeedingReview.SourceDataAsOf = suggestion == null ? null : suggestion.DataAsOf;
                } else if (selectionsWithIndirectSuggestions[selectionNeedingReview.SelectionType]) {
                    const indirectSelectionType = selectionsWithIndirectSuggestions[selectionNeedingReview.SelectionType];
                    const indirectSelection = estimate.Suggestions.find((x) => x.SelectionType === indirectSelectionType);
                    selectionNeedingReview.SourceDataAsOf = indirectSelection == null ? null : indirectSelection.DataAsOf;
                } else {
                    isReviewComplete = false;
                }
            }
        }

        return isReviewComplete;
    }

    public init(): Observable<void> {
        const request = this.countryClient.read();
        return request.once((countries) => this._countries.next(countries));
    }

    public refreshEstimate(): Observable<void> {
        return this.estimate.onceDefined((estimate) => this.setupEstimate(estimate.Id));
    }

    // TODO: Don't update industries if valuationDate hasn't changed
    public setupEstimate(id: string): Observable<void> {
        const getEstimate = () => this.estimateClient.read(id)
            .once((estimate) => {
                this._portfolios.next(undefined);
                this._estimate.next(estimate);
                return this.estimate;
            });

        return getEstimate().onceDefined((estimate) => {
            const isReviewComplete = this.reviewEstimate(estimate);
            if (isReviewComplete) {
                return this.updateIndustries(estimate.ValuationDate);
            } else {
                const updateRequest = this.estimateClient.update(estimate as EstimateResponse, true);
                return updateRequest.once(() => {
                    this.updateIndustries(estimate.ValuationDate);
                    return getEstimate().once();
                });
            }
        });
    }

    public setupBetaEstimate(id: string): Observable<void> {
        const getEstimate = () => this.betaEstimateClient.read(id)
            .once((estimate) => {
                this._betaEstimate.next(estimate);
                return this.betaEstimate;
            });
        return getEstimate().once();
    }

    public updateEstimate(estimate: Estimate): Observable<void> {
        return this.portfolios.onceDefined((portfolios) => {
            estimate.cascadeUpdates(portfolios);
            const local = Util.clone(estimate) as EstimateResponse;
            const request = this.estimateClient.update(local);
            return request.once(() => this.refreshEstimate());
        });
    }

    public getBetaEstimate(id: string): Observable<BetaEstimateResponse> {
        return this.betaEstimateClient.read(id);
    }

    public updateBetaEstimate(betaEstimateRequest: BetaEstimateRequest): Observable<void> {
        return this.betaEstimateClient.update(betaEstimateRequest);
    }

    public updateAnalysis(estimate: Estimate): void {
        const analysis = new Analysis(estimate.ValuationDate, estimate.Industries, estimate.IsIndustryApplicable);
        this._analysis.next(analysis);
    }

    public renameScenario(scenario: ScenarioResponse, name: string): Observable<void> {
        const request = this.scenarioClient.rename(scenario.Id, name);
        return request.once(() => this.refreshEstimate());
    }

    public cloneScenario(scenario: ScenarioResponse): Observable<void> {
        const request = this.scenarioClient.clone(scenario.Id);
        return request.once(() => this.refreshEstimate());
    }

    public deleteScenario(scenario: ScenarioResponse): Observable<void> {
        const request = this.scenarioClient.deactivate(scenario.Id);
        return request.once(() => this.refreshEstimate());
    }

    public updateBenchmarking(date: string, dataRequests: IndustryScenario[]): Observable<void> {
        const request = this.industryClient.create(date, dataRequests);
        return request.once((data: IndustryTearSheet[]) => this._benchmarkingAnalysis.next(data));
    }

    public retrieveTrendsOverTime(dataRequests: TrendsOverTimeRequest[]): Observable<void> {
        const request = this.industryClient.retrieveTrendsOverTime(dataRequests);
        return request.once((data: TrendsOverTimeData[][]) => this._trendsOverTimeData.next(data));
    }

    public updateIndustries(date: string, isIndustryAnalysis: boolean = false): Observable<void> {
        const request: Observable<Industry[]> = isIndustryAnalysis
            ? this.usindustryClient.getUsiIndustries(date)
            : this.usindustryClient.read(date);

        return request.once((industries) => this._industries.next(industries));
    }

    public getIndustriesIntl(area: string, currencyCode: string, date: string): Observable<void> {
        return this.industryClient.read(area, currencyCode, date).once((industries) => this._industriesInt.next(industries));
    }

    public getccr(investor: number, investee: number, DataAsOf: string): Observable<void> {
        return this.costofequity.TrendsreadCcr(investor, investee, DataAsOf).once((credit) => this._ccr.next(credit));
    }

    public Getcountries(): Observable<void> {
        return this.countryClient.read().once((credit) => this._country.next(credit));
    }

    public getCurrencies(): Observable<void> {
        return this.currencyClient.getCurrency().once((currency) => this._currencies.next(currency));
    }

    public getMetrics(countryName: string): Observable<void> {
        return this.betaClient.metrics(countryName).once((metric) => this._metrics.next(metric));
    }

    public getReturns(inputs: MetricInputs): Observable<void> {
        const request = this.betaClient.returns(inputs);
        return request.once((data: MsciReturns[]) => this._returns.next(data));
    }

    public getCompanyReturns(companyRequest: CompanyReturnsRequest): Observable<CompanyReturns[]> {
        return this.betaClient.getCompanyReturns(companyRequest);
    }

    public getBetasToT(name: string, companyRequest: CompanyReturnsRequest): Observable<void> {
        const request = this.betaClient.getBetasToT(name, companyRequest);
        return request.once((data: BetaToT[]) => this._betasToT.next(data));
    }


    public GetCountryRiskPremia(investor: number, investee: number, DataAsOf: string): Observable<void> {
        return this.costofequity.GetCountryRiskPremia(investor, investee, DataAsOf).once((credit) => this._CreditTrends.next(credit));
    }

    public setLastEditorHovered(id: string): void {
        this._lastEditorHoveredId.next(id);
    }

    public getSizePremium(valuationDate: string, marketValueCommonEquity: number): Observable<DecileData> {
        return this.measureClient.readSp(valuationDate, marketValueCommonEquity);
    }

    // TODO: Put this directly in component
    public getDeciles(valuationDate: string): Observable<Decile[]> {
        return this.measureClient.getDeciles(valuationDate);
    }

    public setCurrentEditor(operand: EquationOperand, scenario: Scenario): void {
        combineLatest([
            this.estimate,
            this.suggestionListSelector
        ]).pipe(
            map((x) => ({
                estimate: x[0],
                suggestionList: x[1]
            }))
        ).onceDefined((data) => {
            let suggestionResponse: SuggestionResponse[] = [];

            if (data.estimate != null && data.estimate.EstimateType === EstimateType.USEstimate) {
                suggestionResponse = data.estimate.Suggestions;
            } else if (data.suggestionList != null) {
                suggestionResponse = data.suggestionList;
            }

            const suggestions = scenario.getSuggestions(suggestionResponse, operand.suggestionType || operand.selectionType);
            const model = scenario.getSelectionAsContextualNumber(operand.selectionType, operand.inputType || InputType.None);
            this._editorSettings.next({
                data: model,
                operand,
                scenario,
                suggestions
            });
        });
    }

    public setSummary(summary?: Summary): void {
        this._summary.next(summary);
    }

    public validateScenario(estimate: Estimate, scenario: Scenario): void {
        scenario.calculateEquityRiskPremiumAdjustment(estimate.HistoricRprErp || undefined);
        scenario.calculateIndustryRiskPremium(estimate);
        this.triggerRecalculate();
    }

    public setIsIndustryAnalysis(isIndustryAnalysis: boolean): void {
        this._isIndustryAnalysis.next(isIndustryAnalysis);
    }

    public setAnalysis(analysis: Analysis): void {
        this._analysis.next(analysis);
    }

    public updateCompanyData(dataAsOf: string, sicId: number): Observable<void> {
        const request = this.usCompanyClient.read(dataAsOf, sicId);
        return request.once((companyData) => this._companyData.next(companyData));
    }

    public setEstimate(estimate: Estimate): void {
        this._estimate.next(estimate);
    }

    public getComparbleCompanyList(betaComparbleCompanyRequest: BetaComparbleCompanyRequest): Observable<BetaComparbleCompanyResults[]> {
        return this.betaClient.getComparbleCompanyList(betaComparbleCompanyRequest);
    }

    public getLeveredBetas(companyRequest: CompanyReturnsRequestWithGVkeyStatus): Observable<LeveredBetas[]> {
        return this.betaClient.getLeveredBetas(companyRequest);
    }

    public getBetaStatistics(companyRequest: CompanyReturnsRequest): Observable<BetaStatistics[]> {
        return this.betaClient.getBetaStatistics(companyRequest);
    }

    public getTaxRateList(unleveringTaxRateInputs: UnleveringTaxRateInputs[]): Observable<IntlDataUnlevering[]> {
        return this.intlTaxrateClient.GetTaxRateList(unleveringTaxRateInputs);
    }
    public getTaxRate(countryId: number, dataAsOf: string): Observable<IntlData[]> {
        return this.intlTaxrateClient.read(countryId, dataAsOf);
    }

    public getCompanySelectedChanges(companySelectedChnagesRequest: CompanyReturnsRequest): Observable<CompanyselectedChangesResults[]> {
        return this.betaClient.getCompanySelectedMetrics(companySelectedChnagesRequest);
    }

    public getCompanySelectedCurrencyChanges(companySelectedCurrencyChanges: CompanyReturnsRequest): Observable<BetaComparbleCompanyResults[]> {
        return this.betaClient.getCompanySelecteCurrencyChanges(companySelectedCurrencyChanges);
    }

    public GetCompanyInformation(CompInputValues: CompanyValuationInputs): Observable<CompanyInformation> {
        return this.betaClient.getCompanyInformation(CompInputValues);

    }
    public getCompanyOLSBeta(companyReuest: CompanyReturnsRequest): Observable<OLSBetaCompanyReturns[]> {
        return this.betaClient.getCompanyOLSBeta('OLS Betas', companyReuest);
    }

    public getGicList(): Observable<GicIndustry[]> {
        return this.betaClient.getGic();
    }
}
