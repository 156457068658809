import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Chart } from 'angular-highcharts';
// import { Spinner } from '@concurrency/angular';
// import { ContextualString } from '@concurrency/core';
// import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
// import { Chart } from 'angular-highcharts';
// import * as moment from 'moment';
import { Dialog } from 'primeng/dialog';
// import { GeneralInputs } from 'src/app/beta/beta.types';
// import { MetricInputs } from 'src/app/_api/responses/metric-inputs.response';
// import { MsciReturns } from 'src/app/_api/responses/msci-returns.response';
// import { CommonDate } from 'src/app/_navigator/common/date-struct';
// import { DataStore } from 'src/app/_navigator/data/store/data.store';
// import { PrimeManager } from 'src/app/_navigator/modal/pmodal.manager';

@Component({
    templateUrl: './chart-full-size-modal.component.html'

})
export class ChartFullSizeModalComponent implements OnInit {
    @ViewChild('chart') public chartElement!: ElementRef;
    public dialogHeader = "Performance Indices";
    public display = true;
    public chart: any;
    public fullChart: any;
    public showchart = false;
    @ViewChild(Dialog) public dialog!: Dialog;
    constructor(
    ) { }
    public ngOnInit(): void {
        if (this.chart) {
            this.fullChart = new Chart({
                chart: {
                    height: 500,
                    width: 1200,
                    type: 'line',
                    style: {
                        fontFamily: "nunito-sans"
                      }
                },
                title: {
                    text: ''
                },
                credits: {
                    enabled: false
                },
                series: this.chart.options.series,
                xAxis: this.chart.options.xAxis,
                yAxis: this.chart.options.yAxis
            });
            this.showchart = true;
        }

        // this.chart;
        // console.log(this.chart);
    }

}
