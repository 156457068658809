export class InputsTexts {
    public static readonly highLevelEstimate = 'What are the high-level elements of your cost of capital estimate?';
    public static readonly highLevelAnalysis = 'What are the high level elements of your Industry Benchmarking analysis?';
    public static readonly highLevelUSAnalysis = 'What are the high-level elements of your U.S. Industry Benchmarking analysis?';
    public static readonly highLevelAnalysisInternational =
        'What are the high level elements of your International Industry Benchmarking analysis?';
    public static readonly saveAndContinue = 'Save & Continue';
    public static readonly continue = 'Continue';
    public static readonly homeCountry = 'Home Country';
    public static readonly industry = 'Industry';
    public static readonly industryNotInList = 'My industry is not in the list above';
    public static readonly startEstimate = 'Enter the information below to start your estimate.';
    public static readonly startAnalysis = 'Enter the information below to start your analysis.';
}
