export interface Input {
    EstimateId: string;
    InputType: InputType;
    Value?: number;
    RelativeYear: number;
    Created?: string;
    Updated?: string;
}

export enum InputType {
    None = 'None',
    // Company Metrics
    CompanyType = 'CompanyType',
    MarketValueOfCommonEquity = 'MarketValueOfCommonEquity',
    BookValueOfEquity = 'BookValueOfEquity',
    FiveYearAverageNetIncome = 'FiveYearAverageNetIncome',
    MarketValueOfInvestedCapital = 'MarketValueOfInvestedCapital',
    TotalAssets = 'TotalAssets',
    FiveYearAverageEbitda = 'FiveYearAverageEbitda',
    NetSales = 'NetSales',
    NumberOfEmployees = 'NumberOfEmployees',
    CurrentYearEbit = 'CurrentYearEbit',
    CurrentAssets = 'CurrentAssets',
    CurrentLiabilities = 'CurrentLiabilities',
    RetainedEarnings = 'RetainedEarnings',
    DebtRatioEquity = 'DebtRatioEquity',
    NetIncome = 'NetIncome',
    OperatingIncome = 'OperatingIncome',
    RiskInputStartingYear = 'RiskInputStartingYear',
    WaccEquityType = 'WaccEquityType',
    WaccCostOfEquity = 'WaccCostOfEquity',
    WaccCostOfDebt = 'WaccCostOfDebt',
    WaccTaxRate = 'WaccTaxRate',

    // Aggregate Metrics
    ZScore = 'ZScore',
    OperatingMargin = 'OperatingMargin',
    AverageOperatingMargin = 'AverageOperatingMargin',
    CoefficientOfVariationOfOperatingMargin = 'CoefficientOfVariationOfOperatingMargin',
    ReturnOnEquity = 'ReturnOnEquity',
    AverageReturnOnEquity = 'AverageReturnOnEquity',
    CoefficientOfVariationOfReturnOnEquity = 'CoefficientOfVariationOfReturnOnEquity',
    IsDistressed = 'IsDistressed',

    // Overrides
    ForceAllowRpr = 'ForceAllowRpr',
    ForceAllowHfr = 'ForceAllowHfr',

    // International Estimates
    CashFlows = 'CashFlows',
    CountryOfInputs = 'CountryOfInputs',
    Gic = 'Gic'
}

export enum CompanyType {
    None = 0,
    Service = 1,
    Manufacturing = 2
}

export enum WaccType {
    AverageLevered = 1,
    MedianLevered = 2,
    AverageUnlevered = 3,
    MedianUnlevered = 4,
    AverageRelevered = 5,
    MedianRelevered = 6
}
