import { Injectable } from '@angular/core';
import { Chart } from 'angular-highcharts';

@Injectable()
export class TrendsOverTimeService {

    public set setCharts(chart: Chart) {
        this._charts = ([...this._charts, chart]);
    }

    public get charts(): Chart[] {
        return this._charts;
    }
    private _charts = Array<Chart>();

    constructor() { }

    public createIndustryFilterLabel(sicId: string | undefined, gicId: string | undefined): string {
        let label = '';

        if (sicId) {
            label = `SIC ${sicId}`;
        } else {
            label = `GICS ${gicId}`;
        }

        return label;
    }

}
