export class RouteProgression {
    public static readonly Routes = [
        '/international-estimate/inputs',
        '/international-estimate/coe-inputs',
        '/international-estimate/wacc-inputs',
        '/international-estimate/crp-summary',
        '/international-estimate/fisher-effect',
        '/international-estimate/coe-summary/capm',
        '/international-estimate/coe-summary/fisher-effect',
        '/international-estimate/coe-summary/crp',
        '/international-estimate/cod-summary/capital',
        '/international-estimate/cod-summary/fisher-effect',
        '/international-estimate/cod-summary/conclusion',
        '/international-estimate/wacc-summary/capital',
        '/international-estimate/wacc-summary/conclusion'
    ];
}
