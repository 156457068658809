
import { Injectable } from '@angular/core';
import { BroadcastEventListener, ConnectionStatus, IConnectionOptions, ISignalRConnection, SignalR } from '@henkkelder/ng2-signalr';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

enum EventType { logout, forceClose, purchased }
// const logoutEvent: string = EventType[EventType.logout];
// const forceCloseEvent: string = EventType[EventType.forceClose];
const purchasedEvent: string = EventType[EventType.purchased];

@Injectable()
export class NotificationService {
    private connection?: ISignalRConnection;
    private purchaseEventListener?: BroadcastEventListener<void>;
    private _purchaseEvent = new Subject<void>();
    public purchaseEvent = this._purchaseEvent.asObservable();

    constructor(
        private signalR: SignalR
    ) { }

    // TODO: Refactor this
    private beginListen(connection: ISignalRConnection): void {
        // TODO: Use a SignalR status enum
        // 0 connecting
        // 1 connected
        // 2 reconnecting
        // 4 disconnected
        connection
            .status
            .subscribe((status: ConnectionStatus) => {
                if (status.value === 1) {
                    this.purchaseEventListener = new BroadcastEventListener<void>(purchasedEvent);
                    connection.listen(this.purchaseEventListener);
                    this.purchaseEventListener.subscribe(() => {
                        this._purchaseEvent.next();
                    });
                }

                //     // this._isUserAskedToLogout.next(false);

                //     // this.logoutEvent = new BroadcastEventListener<void>(purchasedType);
                //     // this.connection.listen(this.logoutEvent);
                //     // this.logoutEvent.subscribe(() => {
                //     //     this._isUserAskedToLogout.next(true);
                //     // });

                //     // this.forceCloseEvent = new BroadcastEventListener<void>(forceCloseEventType);
                //     // this.connection.listen(this.forceCloseEvent);
                //     // this.forceCloseEvent.subscribe(() => {
                //     //     this._isUserBeingForcedOut.next(true);
                //     //     this._isUserAskedToLogout.next(false);
                //     //     this.UnsubscribeFromForceCloseListener();
                //     // });
                // }
                if (status.value === 4) {
                    this.stop();
                }
            }, () => {
                this.stop();
            });
    }

    public start(token: string, email: string): void {
        const options: IConnectionOptions = {
            hubName: 'SessionHub',
            url: environment.urls.navigatorApi,
            qs: {
                // userToken: token,
                userEmail: email
            }
        };

        this.connection = this.signalR.createConnection(options);
        this.beginListen(this.connection);

        this.connection
            .start()
            .then((connection) => {
                this.connection = connection;
            })
            .catch((error) => {
                console.error(error);
            });
    }

    public stop(): void {
        if (this.connection) {
            this.connection.stop();
        }

        if (this.purchaseEventListener) {
            this.purchaseEventListener.unsubscribe();
        }

        // this.logoutEvent.unsubscribe();
        // this.forceCloseEvent.unsubscribe();
    }
}
