import * as moment from 'moment';
import { MetricInputs } from 'src/app/_api/responses/metric-inputs.response';
import { betaFontStyle, betaHeaderFontSize, betaNormalFontSize, betaSmallCellHeight, krollblue, krollshadow, normalCellHeight } from '../../estimate/exports/summary-export/util/summary-helpers';

export function generalInputs(metricInputs: MetricInputs, marketIndexName: any, betasAsOf: any): any[] {
    const inputs: any[] = [];
    if (metricInputs) {
        inputs.push(
            {
                height: normalCellHeight,
                cells: [
                    {
                        value: 'General Inputs',
                        // width: 30,
                        color: krollblue,
                        fontSize: betaHeaderFontSize,
                        fontFamily: betaFontStyle,
                        bold: 'true'
                    }
                ]
            },
            {
                cells: [{ value: '' }]
            },
            {
                height: betaSmallCellHeight,
                fontSize: betaNormalFontSize,
                fontStyle: betaFontStyle,
                cells: [
                    {
                        value: 'Valuation Date:', bold: 'true'
                    },
                    {
                        value: moment(metricInputs.ValuationDate).format('MM/DD/YYYY')
                    }
                ]
            },
            {
                height: betaSmallCellHeight,
                fontSize: betaNormalFontSize,
                fontStyle: betaFontStyle,
                cells: [
                    {
                        value: 'Currency of WACC:', bold: 'true'
                    },
                    {
                        value: metricInputs.Currency ? metricInputs.Currency : '-'
                    }
                ]
            },
            {
                height: betaSmallCellHeight,
                fontSize: betaNormalFontSize,
                fontStyle: betaFontStyle,
                cells: [
                    {
                        value: 'Subject Company Location: ', bold: 'true'
                    },
                    {
                        value: metricInputs.Location ? metricInputs.Location : '-'
                    }
                ]
            },
            {
                height: betaSmallCellHeight,
                fontSize: betaNormalFontSize,
                fontStyle: betaFontStyle,
                cells: [
                    {
                        value: 'Return Frequency:', bold: 'true'
                    },
                    {
                        value: metricInputs.Frequency ? metricInputs.Frequency : '-'
                    }
                ]
            },
            {
                height: betaSmallCellHeight,
                fontSize: betaNormalFontSize,
                fontStyle: betaFontStyle,
                cells: [
                    {
                        value: 'Lookback Period:', bold: 'true'
                    },
                    {
                        value: metricInputs.LookbackPeriod ? metricInputs.LookbackPeriod : '-'
                    }
                ]
            },
            {
                height: betaSmallCellHeight,
                fontSize: betaNormalFontSize,
                fontStyle: betaFontStyle,
                cells: [
                    {
                        value: 'Market Index:', bold: 'true'
                    },
                    {
                        value: marketIndexName ? marketIndexName : '-'
                    }
                ]
            },
            {
                height: betaSmallCellHeight,
                fontSize: betaNormalFontSize,
                fontStyle: betaFontStyle,
                cells: [
                    {
                        value: 'Betas Calculated as of:', bold: 'true'
                    },
                    {
                        value: betasAsOf ? betasAsOf : '-'
                    }
                ]
            }
        );
    }
    return inputs;
}
