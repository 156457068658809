import { Country } from 'src/app/_api/responses/country.response';
import { CurrencyData } from 'src/app/_api/responses/currency-data.response';
import { EstimateIndustries } from 'src/app/_api/responses/estimate-industries.response';
import { Gic } from 'src/app/_api/responses/gic.response';
import { Industry } from 'src/app/_api/responses/us-industry.response';

export class TypeaheadFormatters {

    public static Country(result: Country): string {
        if (result && result.CountryName) {
            return result.CountryName.toUpperCase();
        } else {
            return '';
        }
    }

    public static Industry(result: Industry): string {
        if (result && result.Sector) {
            const code = (result.SicIndustryCode != null && result.SicIndustryCode !== '')
                ? `SIC ${result.SicIndustryCode}`
                : `GICS ${result.GicIndustryCode}`;

            return `${code} - ${result.Sector.toUpperCase()}`;
        } else {
            return '';
        }
    }

    public static IndustryBasedOnValuationDate(valuationDate: Date, industry: EstimateIndustries): string {
        if (valuationDate && !isNaN(new Date(valuationDate).getTime())) {
            const code = (((new Date(valuationDate).getTime()) <= (new Date('2020-09-30').getTime())))
                ? `SIC ${industry.SicCode}` : `GICS ${industry.SicCode}`;

            return `${code} - ${industry.IndustryName.toUpperCase()}`;
        } else {
            return '';
        }
    }

    public static Currency(result: CurrencyData): string {
        if (result) {
            return `${result.CurrencyAcronym} - ${result.CurrencyName.toUpperCase()}`;
        } else {
            return '';
        }
    }

    public static Gic(result: Gic): string {
        if (result && result.GicDescription) {
            return `${result.GicCode} - ${result.GicDescription.toUpperCase()}`;
        } else {
            return '';
        }
    }

}
