import { CountryRiskPremium } from './models/countryRiskPremium';

export function crpDataMap(data: CountryRiskPremium[]): CountryRiskPremium[] {
    const crpData = data;
    crpData.forEach((x) => {
        x.InvesteeContry,
            x.Region,
            x.MSIClassification = x.MSIClassification !== null ? x.MSIClassification : '',
            x.InflationRate = x.InflationRate !== null ? parseFloat(x.InflationRate).toFixed(2).toString() : 'N/A',
            x.IntlTaxRate = x.IntlTaxRate != null ? parseFloat(x.IntlTaxRate).toFixed(2).toString() : 'N/A',
            x.CysmDp = x.CysmDp != null ? parseFloat(x.CysmDp).toFixed(2).toString() : 'N/A',
            x.CcrCrp = x.CcrCrp != null ? parseFloat(x.CcrCrp).toFixed(2).toString() : 'N/A',
            x.RvComplex = x.RvComplex != null ? parseFloat(x.RvComplex).toFixed(2).toString() : 'N/A',
            x.Tier = 0;
    });

    return crpData;
}