import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FilesClient } from 'src/app/_api/clients/files.client';
import { ResourceFileType } from 'src/app/_api/enums/resource-file-type';
import { CocSections } from './resources-library-model';

@Injectable()
export class ResourceLibraryService {

    private _sections = new BehaviorSubject<CocSections[] | undefined>(undefined);
    public sections = this._sections.asObservable();
    constructor(private filesClient: FilesClient) {

    }
    public getResourceContent(resourceFileType: ResourceFileType): void {
        if (!this._sections.getValue()) {
            this.filesClient.getResources(resourceFileType).subscribe((data) => {
                    this._sections.next(JSON.parse(data));
                }, () => { });
        }
    }

}
