import { Util } from '@concurrency/core';
import { Chart } from 'angular-highcharts';
import { IndustryMetric } from 'src/app/_api/responses/industry-metric.response';
import { Categories } from '../const/categories.const';
import { Category } from '../enums/category';
import { Metric } from '../enums/metric';
import { MetricCategory } from '../models/metric-category.model';
import { TearSheetUtil } from './tear-sheet.util';

export class CapitalStructureChartUtil {
    public static getCapitalStructureChartMetric(industryMetric: IndustryMetric[]): MetricCategory | null {
        const debtTotalCapital = TearSheetUtil.getMetric(Metric.DebtTotalCapital, industryMetric);

        if (debtTotalCapital == null || debtTotalCapital.SICCompositeLatest == null) {
            return null;
        }

        const equityTotalCapital = 100 - debtTotalCapital.SICCompositeLatest;

        return {
            metricCategoryId: Category.CapitalStructure,
            metricCategoryName: Categories.CapitalStructure,
            showCompositeLabels: false,
            metrics: [],
            chart: this.buildChart(debtTotalCapital.SICCompositeLatest, equityTotalCapital),
            position: 4
        };
    }

    private static buildChart(debtTotalCapital: number, equityTotalCapital: number): Chart {
        return new Chart({
            chart: {
                type: 'pie',
                width: 250,
                height: 250
            },
            title: {
                text: 'Industry Composite (%)'
            },
            subtitle: {
                text: 'Latest'
            },
            credits: {
                enabled: false
            },
            tooltip: {
                pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        format: '{point.percentage:.1f}'
                    },
                    showInLegend: true
                }
            },
            series: [{
                type: 'pie',
                name: 'Industry Composite',
                data: [
                    {
                        name: 'D / TC',
                        y: Util.round(debtTotalCapital, 1),
                        color: '#43b059'
                    },
                    {
                        name: 'E / TC',
                        y: Util.round(equityTotalCapital, 1),
                        color: '#14487f'
                    }
                ]
            }]
        });
    }

}
