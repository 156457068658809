
import { Store } from '@ngxs/store';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import * as moment from 'moment';
import { combineLatest } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { SelectionType } from 'src/app/_api/enums/selection-type';
import { Country } from 'src/app/_api/responses/country.response';
import { EstimateSummary } from 'src/app/_api/responses/estimate-summary.response';
import { InputType } from 'src/app/_api/responses/input.response';
import { EstimateSummaryText } from 'src/app/international-estimate/dynamic-text/model/estimate-summary-text';
import { CountryListState } from '../country-list-store/country-list-state';
import { NumberFormatUtil } from '../data/util/number-format.util';
import { IntlIndustryListState } from '../intl-industry-list-store/intl-industry-list.state';
import { UpdateEstimateSummary } from './estimate-summary-action';
import { Injectable } from '@angular/core';


export type EstimateSummaryModel = EstimateSummary | undefined;

@State<EstimateSummaryModel>({
    name: 'estimateSummary',
    defaults: undefined
})

@Injectable()
export class EstimateSummaryState {

    @Selector()
    public static get(state: EstimateSummaryModel): EstimateSummary | undefined {
        return state;
    }
    constructor(private store: Store) { }

    @Action(UpdateEstimateSummary)
    public updateEstimateSummary(context: StateContext<EstimateSummaryModel>, action: UpdateEstimateSummary): Observable<void> {
        const countryOfInputs = action.estimate.getInput(InputType.CountryOfInputs).Value;
        const cashFlowCountryId = action.estimate.getInput(InputType.CashFlows).Value;
        const industryId = action.estimate.getInput(InputType.Gic).Value;
        const scenarioSelections = action.estimate.Scenarios[0].Selections;

        const alpha = scenarioSelections.find((x) => x.SelectionType === SelectionType.IntlAlpha);
        const beta = scenarioSelections.find((x) => x.SelectionType === SelectionType.IntlBeta);
        const kdCountrySelection = scenarioSelections.find((x) => x.SelectionType === SelectionType.CostOfDebtCurrency);
        const debtToCapitalRatio = scenarioSelections.find((x) => x.SelectionType === SelectionType.DebtToTotalCapitalRatio);
        const equityRiskPremium = scenarioSelections.find((x) => x.SelectionType === SelectionType.IntlEquityRiskPremium);
        const preTaxCostOfDebt = scenarioSelections.find((x) => x.SelectionType === SelectionType.PreTaxCostOfDebt);
        const riskFreeRate = scenarioSelections.find((x) => x.SelectionType === SelectionType.IntlRiskFreeRate);
        const sizePremium = scenarioSelections.find((x) => x.SelectionType === SelectionType.IntlSizePremium);
        const taxRate = scenarioSelections.find((x) => x.SelectionType === SelectionType.TaxRate);
        const countryRiskPremium = scenarioSelections.find((x) => x.SelectionType === SelectionType.CountryRiskPremium);
        const relativeVolatility = scenarioSelections.find((x) => x.SelectionType === SelectionType.RelativeVolatility);
        const yieldSpread = scenarioSelections.find((x) => x.SelectionType === SelectionType.YieldSpread);
        const investeeInflation = scenarioSelections.find((x) => x.SelectionType === SelectionType.InflationInvestee);
        const investorInflation = scenarioSelections.find((x) => x.SelectionType === SelectionType.InflationInvestor);
        const ccrSourceInfo = scenarioSelections.find((x) => x.SelectionType ===  SelectionType.SourceInfoCcr);

        return combineLatest([
            this.store.select(CountryListState.getById(action.estimate.HomeCountryId)),
            this.store.select(CountryListState.getById(action.estimate.InvesteeCountryId)),
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            this.store.select(CountryListState.getById(cashFlowCountryId!)),
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            this.store.select(IntlIndustryListState.getByGicId(industryId!)),
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            this.store.select(CountryListState.getById(countryOfInputs!))
        ]).pipe(
            map((x) => ({
                investor: x[0],
                investee: x[1],
                cashFlowCurrencyCountry: x[2],
                industry: x[3],
                inputsCountry: x[4]
            }))
        ).onceDefined((data) => {
            let industryName;
            if (data.industry && data.industry.length > 0) {
                industryName = `GICS ${data.industry[0].GicCode} - ${data.industry[0].GicDescription}`;
            }

            let cashFlowCurrencyAcronym: string | undefined;
            let cashFlowCountryName: string | undefined;
            if (data.cashFlowCurrencyCountry) {
                cashFlowCurrencyAcronym = data.cashFlowCurrencyCountry.Currency.CurrencyAcronym;
                cashFlowCountryName = data.cashFlowCurrencyCountry.CountryName;
            }

            let countryOfInputName: string | undefined;
            let countryOfInputCurrencyAcronym: string | undefined;
            let countryOfInputCurrencyName: string | undefined;
            if (data.inputsCountry) {
                countryOfInputName = data.inputsCountry.CountryName;
                countryOfInputCurrencyAcronym = data.inputsCountry.Currency.CurrencyAcronym;
                countryOfInputCurrencyName = data.inputsCountry.Currency.CurrencyName;
            }

            let investeeCountryName: string | undefined;
            if (data.investee) {
                investeeCountryName = data.investee.CountryName;
            }

            let investorCountryname: string | undefined;
            if (data.investor) {
                investorCountryname = data.investor.CountryName;
            }

            const countries = [];

            if (data.investee) {
                countries.push(data.investee);
            }

            if (data.investee && data.inputsCountry) {
                if (data.investee.CountryId !== data.inputsCountry.CountryId) {
                    countries.push(data.inputsCountry);
                }
            }

            let kdInputCountry: Country | undefined;
            if (kdCountrySelection) {
                // tslint:disable-next-line: no-non-null-assertion
                kdInputCountry = countries.find((x) => x!.CountryId === kdCountrySelection.Value);
            }

            let countryRiskPremia: string | undefined;
            let ccrDataAsOf: string | undefined;
            if (countryRiskPremium != null && countryRiskPremium.Value != null && countryRiskPremium.SourceDataAsOf != null) {
                countryRiskPremia = `${NumberFormatUtil.numberWithCommas(countryRiskPremium.Value)}%`;
                ccrDataAsOf = moment(countryRiskPremium.SourceDataAsOf).format('LL');
            }

            let relativeVolatilityModel: string | undefined;
            let relativeVolatilityModelDataAsOf: string | undefined;
            if (relativeVolatility && relativeVolatility.Value && relativeVolatility.SourceDataAsOf) {
                relativeVolatilityModel = NumberFormatUtil.numberWithCommas(relativeVolatility.Value);
                relativeVolatilityModelDataAsOf = moment(relativeVolatility.SourceDataAsOf).format('LL');
            }

            let countryYieldSpreadModel: string | undefined;
            let countryYieldSpreadModelDataAsOf: string | undefined;
            if (yieldSpread != null && yieldSpread.Value != null && yieldSpread.SourceDataAsOf != null) {
                countryYieldSpreadModel = `${NumberFormatUtil.numberWithCommas(yieldSpread.Value)}%`;
                countryYieldSpreadModelDataAsOf = moment(yieldSpread.SourceDataAsOf).format('LL');
            }

            let fisherEffectInvestorCountry: string | undefined;
            if (kdInputCountry && kdInputCountry.CountryName !== cashFlowCountryName) {
                fisherEffectInvestorCountry = kdInputCountry.CountryName;
            } else if (countryOfInputName !== cashFlowCountryName) {
                fisherEffectInvestorCountry = countryOfInputName;
            }

            context.setState({
                Alpha: alpha == null ? undefined : alpha,
                AlphaValue: alpha && alpha.Value ? `${alpha.Value}%` : undefined,
                AnalysisDate: action.estimate.Created.toString(),
                Beta: beta == null ? undefined : beta,
                BetaDataAsOf: beta && beta.SourceDataAsOf ? moment(beta.SourceDataAsOf).format('LL') : 'N/A',
                BetaSource: beta && beta.Context ? beta.Context : 'Custom',
                BetaType: beta && beta.SourceTable ? beta.SourceTable : 'Custom',
                BetaValue: beta && beta.Value ? `${beta.Value}` : undefined,
                CashFlowCountry: data.cashFlowCurrencyCountry,
                CashFlowCountryName: cashFlowCountryName,
                CashFlowCurrency: cashFlowCurrencyAcronym,
                CostOfDebtInputCountryCurrency: kdInputCountry == null ? undefined : kdInputCountry.Currency.CurrencyAcronym,
                CostOfDebtInputsCountry: kdInputCountry == null ? undefined : kdInputCountry,
                CostOfDebtInputsCountryName: kdInputCountry == null ? undefined : kdInputCountry.CountryName,
                CostOfEquityInputsCountry: `${countryOfInputName} (${countryOfInputCurrencyAcronym} - ${countryOfInputCurrencyName})`,
                CountryOfInputs: data.inputsCountry,
                CountryOfInputsName: countryOfInputName,
                CountryRiskPremium: countryRiskPremia,
                CountryRiskPremiaDataAsOf: ccrDataAsOf,
                CountryYieldSpreadModel: countryYieldSpreadModel,
                CountryYieldSpreadModelDataAsOf: countryYieldSpreadModelDataAsOf,
                DebtToCapitalRatio: debtToCapitalRatio == null ? undefined : `${debtToCapitalRatio.Value}%`,
                EquityRiskPremium: equityRiskPremium == null ? undefined : equityRiskPremium,
                EquityRiskPremiumDataAsOf: equityRiskPremium && equityRiskPremium.SourceDataAsOf
                    ? moment(equityRiskPremium.SourceDataAsOf).format('LL')
                    : 'N/A',
                EquityRiskPremiumSource: equityRiskPremium && equityRiskPremium.Context ? equityRiskPremium.Context : 'Custom',
                EquityRiskPremiumType: equityRiskPremium && equityRiskPremium.SourceTable ? equityRiskPremium.SourceTable : 'Custom',
                EquityRiskPremiumValue: equityRiskPremium && equityRiskPremium.Value ? `${equityRiskPremium.Value}%` : undefined,
                EstimateName: action.estimate.Name,
                FisherEffectInvestorCountry: fisherEffectInvestorCountry,
                IndustryName: industryName,
                InputCountryCurrency: countryOfInputCurrencyAcronym,
                InputCountryCurrencyName: countryOfInputCurrencyName,
                InvesteeCountryId: action.estimate.InvesteeCountryId,
                InvesteeCountryInflation: investeeInflation == null ? undefined : investeeInflation,
                InvesteeCountryInflationDataAsOf: investeeInflation && investeeInflation.SourceDataAsOf
                    ? moment(investeeInflation.SourceDataAsOf).format('LL')
                    : 'N/A',
                InvesteeCountryInflationName: `${cashFlowCountryName} ${EstimateSummaryText.Inflation}`,
                InvesteeCountryInflationSource: investeeInflation && investeeInflation.Context ? investeeInflation.Context : 'Custom',
                InvesteeCountryInflationType: investeeInflation && investeeInflation.SourceTable
                    ? investeeInflation.SourceTable
                    : 'Custom',
                InvesteeCountryInflationValue: investeeInflation && investeeInflation.Value ? `${investeeInflation.Value}%` : undefined,
                InvesteeCountryName: investeeCountryName,
                InvestorCountryId: action.estimate.HomeCountryId,
                InvestorCountryInflation: investorInflation == null ? undefined : investorInflation,
                InvestorCountryInflationDataAsOf: investorInflation && investorInflation.SourceDataAsOf
                    ? moment(investorInflation.SourceDataAsOf).format('LL')
                    : 'N/A',
                InvestorCountryInflationName: `${fisherEffectInvestorCountry} ${EstimateSummaryText.Inflation}`,
                InvestorCountryInflationSource: investorInflation && investorInflation.Context ? investorInflation.Context : 'Custom',
                InvestorCountryInflationType: investorInflation && investorInflation.SourceTable
                    ? investorInflation.SourceTable
                    : 'Custom',
                InvestorCountryInflationValue: investorInflation && investorInflation.Value ? `${investorInflation.Value}%` : undefined,
                InvestorCountryName: investorCountryname,
                PreTaxCostOfDebtKd: preTaxCostOfDebt == null ? undefined : `${preTaxCostOfDebt.Value}%`,
                RelativeVolatilityModel: relativeVolatilityModel,
                RelativeVolatilityModelDataAsOf: relativeVolatilityModelDataAsOf,
                RiskFreeRate: riskFreeRate == null ? undefined : riskFreeRate,
                RiskFreeRateDataAsOf: riskFreeRate && riskFreeRate.SourceDataAsOf ?
                    moment(riskFreeRate.SourceDataAsOf).format('LL')
                    : 'N/A',
                RiskFreeRateSource: riskFreeRate && riskFreeRate.Context ? riskFreeRate.Context : 'Custom',
                RiskFreeRateType: riskFreeRate && riskFreeRate.SourceTable ? riskFreeRate.SourceTable : 'Custom',
                RiskFreeRateValue: riskFreeRate && riskFreeRate.Value ? `${riskFreeRate.Value}%` : undefined,
                SizePremium: sizePremium == null ? undefined : sizePremium,
                SizePremiumValue: sizePremium && sizePremium.Value ? `${sizePremium.Value}%` : undefined,
                TaxRate: taxRate == null ? undefined : taxRate,
                TaxRateName: `${EstimateSummaryText.TaxRate} (${investeeCountryName})`,
                TaxRateSource: taxRate && taxRate.Context ? taxRate.Context : 'Custom',
                TaxRateType: taxRate && taxRate.SourceTable ? taxRate.SourceTable : 'Custom',
                TaxRateValue: taxRate && taxRate.Value ? `${taxRate.Value}%` : undefined,
                ValuationDate: moment(action.estimate.ValuationDate).format('LL'),
                CcrSource: ccrSourceInfo?.SourceTable != null ? ccrSourceInfo?.SourceTable : ''
            });
        });
    }
}
