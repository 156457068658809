import * as moment from 'moment';

import { ComponentFactoryResolver } from '@angular/core';
import { ContextualNumber, ContextualValue, Dictionary, Util } from '@concurrency/core';
import { Chart } from 'angular-highcharts';
import { SelectionType } from 'src/app/_api/enums/selection-type';
import { EquationType } from 'src/app/_api/responses/equation.response';
import { EstimateIndustries } from 'src/app/_api/responses/estimate-industries.response';
import { CompanyType, InputType } from 'src/app/_api/responses/input.response';
import { Selection } from 'src/app/_api/responses/selection.response';
import { Equation } from 'src/app/_navigator/data/model/equation.model';
import { Estimate } from 'src/app/_navigator/data/model/estimate.model';
import { Scenario } from 'src/app/_navigator/data/model/scenario.model';
import { DataStore } from 'src/app/_navigator/data/store/data.store';
import { NumberFormatUtil } from 'src/app/_navigator/data/util/number-format.util';
import { EquitySummaryGroup } from 'src/app/_navigator/summary/equity-summary-group.model';
import { HasShow } from 'src/app/_navigator/summary/has-show.model';
import { SummaryEquation } from 'src/app/_navigator/summary/summary-equation.model';
import { SummaryGroup } from 'src/app/_navigator/summary/summary-group.model';
import { SummaryScenario } from 'src/app/_navigator/summary/summary-scenario.model';
import { SummaryStudyGroup } from 'src/app/_navigator/summary/summary-study-group.model';
import { StudyType, SummaryStudy } from 'src/app/_navigator/summary/summary-study.model';
import { ExcelRow } from '../exports/summary-export/types/excel-row';
import { ExcelSummaryInput } from '../exports/summary-export/types/excel-summary-input';
import { WaccExcelEquations } from '../exports/summary-export/types/wacc-excel-equations';
import { CrspBuildupComponent } from '../studies/crsp/crsp-buildup.component';
import { CrspCapmComponent } from '../studies/crsp/crsp-capm.component';
import { HfrBuildupComponent } from '../studies/hfr/hfr-buildup.component';
import { HfrCapmComponent } from '../studies/hfr/hfr-capm.component';
import { OperandUtility } from '../studies/operand.utility';
import { RiskBuildupOneComponent } from '../studies/risk/risk-buildup1.component';
import { RiskBuildupTwoComponent } from '../studies/risk/risk-buildup2.component';
import { RiskBuildupThreeComponent } from '../studies/risk/risk-buildup3.component';
import { RiskCapmComponent } from '../studies/risk/risk-capm.component';
import { Risk } from '../studies/risk/risk.model';
import { SummaryUtil } from './summary.util';

// TODO: This should not be importing components, the work they do should be in service(s)

export class Summary {

    private leveredEquationTypes = [
        EquationType.CrspCapmSizeStudy,
        EquationType.CrspBuildup,
        EquationType.RprsCapmSizeStudy,
        EquationType.RprsBuildup1Levered,
        EquationType.RprsBuildup2,
        EquationType.RprsBuildup3
    ];

    private unleveredEquationTypes = [
        EquationType.RprsBuildup1Unlevered
    ];

    private releveredEquationTypes = [
        EquationType.RprsBuildup1Relevered
    ];

    private hfrEquationTypes = [
        EquationType.HfrsCapmHfr,
        EquationType.HfrsBuildupHfr
    ];

    public industries?: string[];
    public company?: string;
    public valuationDate?: string;
    public sources: DataSourceSection[] = [];

    public waccEquationValues: WaccExcelEquations = {} as WaccExcelEquations;
    public HFRSelectionLogValues: ExcelRow[] = [];
    public HFRSelectionType = '';

    public costOfEquityInputs: ExcelSummaryInput[] = [];
    public costOfDebtWaccInputs: ExcelSummaryInput[] = [];
    public highFinancialRiskZScore: ExcelSummaryInput = {} as ExcelSummaryInput;
    public riskPremiaLevered: ExcelSummaryInput[] = [];
    public riskPremiaCoefficients: ExcelSummaryInput[] = [];
    public riskPremiaRelevered: ExcelSummaryInput[] = [];
    public riskPremiaUnlevered: ExcelSummaryInput[] = [];
    public riskCRSPCapm: ExcelSummaryInput[] = [];
    public riskRprsCapmSizeStudy: ExcelSummaryInput[] = [];
    public riskHFRCapm: ExcelSummaryInput[] = [];
    public riskHFRBuildupHfr: ExcelSummaryInput[] = [];
    public buildup3Risks: Risk[] = [];

    public levered: EquitySummaryGroup = {
        name: 'Levered Cost of Equity',
        equationTypes: this.leveredEquationTypes,
        studyGroups: [],
        average: '-',
        median: '-',
        isCollapsed: false
    };

    public unlevered: EquitySummaryGroup = {
        name: 'Unlevered Cost of Equity',
        equationTypes: this.unleveredEquationTypes,
        studyGroups: [],
        average: '-',
        median: '-',
        isCollapsed: false
    };

    public relevered: EquitySummaryGroup = {
        name: 'Relevered  Cost of Equity',
        equationTypes: this.releveredEquationTypes,
        studyGroups: [],
        average: '-',
        median: '-',
        isCollapsed: false
    };

    public highFinancialRisk: EquitySummaryGroup = {
        name: 'High-Financial-Risk Cost of Equity',
        equationTypes: this.hfrEquationTypes,
        studyGroups: [],
        average: '-',
        median: '-',
        isCollapsed: false
    };

    public equityGroups: EquitySummaryGroup[] = [
        this.levered,
        this.unlevered,
        this.relevered,
        this.highFinancialRisk
    ];

    public generalInputs: SummaryGroup = {
        name: 'General Inputs',
        categories: [{
            name: '',
            items: [{
                name: 'Valuation Date',
                value: '-'
            }, {
                name: 'Home Country',
                value: '-'
            }, {
                name: 'Investee Country',
                value: '-'
            }, {
                name: 'Industry',
                value: '-'
            }]
        }]
    };

    public sizeMeasures: SummaryGroup = {
        name: 'Size Measures <span class="font-size-12">($USD in millions, except for Number of Employees)</span>',
        categories: [{
            name: '',
            items: []
        }]
    };

    public riskMeasures: SummaryGroup = {
        name: 'Risk Measures',
        categories: [{
            name: '',
            items: []
        }]
    };

    public riskMeasuresSummary: SummaryGroup = {
        name: 'Risk Measures Summary',
        categories: [{
            name: '',
            items: []
        }]
    };

    public crspStudy: SummaryGroup = {
        name: 'CRSP Deciles Size Study',
        categories: [{
            name: 'CAPM + Size Premium / Buildup',
            items: [{
                name: 'CRSP Decile Size Premium',
                value: '-'
            }, {
                name: 'Size Group Size Premium',
                value: '-'
            }]
        }]
    };

    public rprStudy: SummaryGroup = {
        name: 'Risk Premium Report Study',
        categories: [{
            name: '',
            items: []
        }]
    };

    public hfrStudy: SummaryGroup = {
        name: 'High Financial Risk Study',
        categories: [{
            name: '',
            items: []
        }]
    };

    // TODO: This should NOT be of type any
    public assumptions: any = [];
    constructor(
        private resolver: ComponentFactoryResolver,
        private dataStore: DataStore
    ) { }

    private setWaccSummary(estimate: Estimate): void {
        const waccType = estimate.getInput(InputType.WaccEquityType);
        let costOfEquity = estimate.getContextualInput(InputType.WaccCostOfEquity);

        const costOfEquityOptions = [
            new ContextualNumber(this.levered.average, 'Average Levered'),
            new ContextualNumber(this.levered.median, 'Median Levered'),
            new ContextualNumber(this.unlevered.average, 'Average Unlevered'),
            new ContextualNumber(this.unlevered.median, 'Median Unlevered'),
            new ContextualNumber(this.relevered.average, 'Average Relevered'),
            new ContextualNumber(this.relevered.median, 'Median Relevered')
        ];

        if (waccType.Value == null) {
            costOfEquity = estimate.getContextualInput(InputType.WaccCostOfEquity);
            costOfEquity.context = 'Custom';
        } else {
            const selectedCostOfEquityForWacc = costOfEquityOptions[waccType.Value - 1];

            if (selectedCostOfEquityForWacc.hasValue) {
                costOfEquity = selectedCostOfEquityForWacc.clone();
            }
        }

        const costOfDebt = estimate.getContextualInput(InputType.WaccCostOfDebt);
        const taxRate = estimate.getContextualInput(InputType.WaccTaxRate);
        const debtRatioEquity = estimate.getContextualInput(InputType.DebtRatioEquity).asNumberOrNaN / 100;
        const weightOfDebt = Util.round((debtRatioEquity / (1 + debtRatioEquity)) * 100);
        const weightOfEquity = new ContextualNumber((100 - weightOfDebt) || null);
        const calculatedWeightOfDebt = new ContextualNumber(weightOfDebt || null);

        let weightedValue = '';

        this.waccEquationValues.costOfEquity = costOfEquity.asString
            ? `${costOfEquity.asNumber}%`
            : 'Ke**';

        this.waccEquationValues.costOfEquitySelectedContext = costOfEquity.asString
            ? `${costOfEquity.asNumber}% - ${costOfEquity.context}`
            : '________';

        this.waccEquationValues.weightOfEquity = weightOfEquity.asString
            ? `${weightOfEquity.asNumber}%`
            : 'We';

        this.waccEquationValues.costOfDebt = costOfDebt.asString
            ? `${costOfDebt.asNumber}%`
            : 'Kd';

        this.waccEquationValues.weightOfDebt = weightOfDebt
            ? `${weightOfDebt}%`
            : 'Wd';

        this.waccEquationValues.taxRate = taxRate.asString
            ? taxRate.asString
            : '(1-t)';

        if (costOfEquity.asNumber &&
            weightOfEquity.asNumber &&
            costOfDebt.asNumber &&
            calculatedWeightOfDebt.asNumber &&
            taxRate.asNumber) {

            const weighted = (costOfEquity.asNumber / 100 * weightOfEquity.asNumber / 100) +
                (costOfDebt.asNumber / 100 * calculatedWeightOfDebt.asNumber / 100) *
                (1 - taxRate.asNumber / 100);

            weightedValue = NumberFormatUtil.numberWithCommas(weighted * 100, 2);
        }

        this.waccEquationValues.weighted = weightedValue ? `${weightedValue}%` : 'WACC';

        const costOfEquityExcel = this.setExcelWaccSummaryInput(costOfEquity.asString, 'Cost of Equity Capital', costOfEquity.context);
        costOfEquityExcel.value = this.toPercentageOrNotApplicable(parseFloat(costOfEquityExcel.value));

        const taxRateExcel = this.setExcelWaccSummaryInput(taxRate.asString, 'Tax Rate');
        if (taxRateExcel.value !== 'Custom') {
            taxRateExcel.value = this.toPercentageOrNotApplicable(parseFloat(taxRateExcel.value));
        }

        const debtToTotalCapitalRatioExcel = this.setExcelWaccSummaryInput(calculatedWeightOfDebt.asString, 'Debt-to-Total Capital Ratio');
        debtToTotalCapitalRatioExcel.value = this.toPercentageOrNotApplicable(parseFloat(debtToTotalCapitalRatioExcel.value));

        const preTaxCostOfDebtExcel = this.setExcelWaccSummaryInput(costOfDebt.asString, 'Pre-tax Cost Of Debt (Kd)');
        preTaxCostOfDebtExcel.value = this.toPercentageOrNotApplicable(parseFloat(preTaxCostOfDebtExcel.value));

        this.costOfDebtWaccInputs = [
            costOfEquityExcel,
            taxRateExcel,
            debtToTotalCapitalRatioExcel,
            preTaxCostOfDebtExcel
        ];
    }

    private setExcelHFRSelectionLog(estimate: Estimate): void {
        const zScore = estimate.getInput(InputType.ZScore);
        const companyType = estimate.getInput(InputType.CompanyType).Value || 0;

        const zScoreValue = zScore.Value ? `${NumberFormatUtil.numberWithCommas(zScore.Value, 2)}` : 'N/A';
        const zScoreType = zScore.Value ? `${CompanyType[companyType]}` : 'Z-Score';

        this.HFRSelectionLogValues = [
            { label: 'Z-Score', value: zScoreValue },
            { label: 'Z-Score Type', value: zScoreType }
        ];

        this.HFRSelectionType = zScoreType;
    }

    private setDataSources(estimate: Estimate): void {
        const doNotDisplay = [
            SelectionType.EquityRiskPremiumAdjustment,
            SelectionType.CrspIndustryRiskPremium,
            SelectionType.RprIndustryRiskPremium,
            SelectionType.HighFinancialRiskPremiumOverCapM,
            SelectionType.HighFinancialRiskPremiumOverRiskFreeRate
        ];

        const filter = (x: Selection) => x.Value != null && x.Context !== 'Custom' && doNotDisplay.indexOf(x.SelectionType) === -1;
        const selections = Util.selectMany(estimate.Scenarios, (x) => x.Selections).filter(filter);
        const data = selections.map((x) => this.asDataSource(x, estimate.Industries));
        const distinctData = Util.distinctBy(data, (x) => x.source);
        const toSourceGroup = (groupName: string, groupData: { group: string, source: string }[]) => ({
            name: groupName,
            sources: groupData.filter((x) => x.group === groupName).map((x) => x.source)
        });

        this.sources = [{
            name: Constants.Navigator,
            groups: [
                toSourceGroup(Constants.SizePremium, distinctData),
                toSourceGroup(Constants.RiskPremiumReport, distinctData),
                toSourceGroup(Constants.EquityRiskPremia, distinctData),
                toSourceGroup(Constants.RiskFreeRate, distinctData)
            ].filter((x) => x.sources.length > 0)
        }, {
            name: Constants.Handbook,
            groups: [
                toSourceGroup(Constants.Beta, distinctData)
            ].filter((x) => x.sources.length > 0)
        }].filter((x) => x.groups.length > 0);
    }

    // TODO: Move to Selection model?
    private asDataSource(selection: Selection, industries: EstimateIndustries[]): { group: string, source: string } {
        const groups: Dictionary<string> = {};
        groups[SelectionType.CrspSizePremium] = Constants.SizePremium;
        groups[SelectionType.CrspBuildupSizePremium] = Constants.SizePremium;
        groups[SelectionType.RiskPremiumOverCapm] = Constants.RiskPremiumReport;
        groups[SelectionType.RiskPremiumOverRiskFreeRateLevered] = Constants.RiskPremiumReport;
        groups[SelectionType.RiskPremiumOverRiskFreeRateUnlevered] = Constants.RiskPremiumReport;
        groups[SelectionType.RiskPremiumOverRiskFreeRateRelevered] = Constants.RiskPremiumReport;
        groups[SelectionType.Buildup2RiskPremiumOverTheRiskFreeRate] = Constants.RiskPremiumReport;
        groups[SelectionType.Buildup3RiskPremiumOverTheRiskFreeRate] = Constants.RiskPremiumReport;
        groups[SelectionType.CrspDecilesBeta] = Constants.Beta;
        groups[SelectionType.RiskPremiumBeta] = Constants.Beta;
        groups[SelectionType.HighFinancialRiskBeta] = Constants.Beta;
        groups[SelectionType.EquityRiskPremium] = Constants.EquityRiskPremia;
        groups[SelectionType.RiskFreeRate] = Constants.RiskFreeRate;

        const overrides: Dictionary<string> = {};
        overrides[SelectionType.CrspSizePremium] = Constants.SizePremium;
        overrides[SelectionType.CrspBuildupSizePremium] = Constants.SizePremium;
        overrides[SelectionType.RiskPremiumOverCapm] = Constants.RprCapm;
        overrides[SelectionType.RiskPremiumOverRiskFreeRateLevered] = Constants.RprBuildup1;
        overrides[SelectionType.RiskPremiumOverRiskFreeRateUnlevered] = Constants.RprBuildup1;
        overrides[SelectionType.RiskPremiumOverRiskFreeRateRelevered] = Constants.RprBuildup1;
        overrides[SelectionType.Buildup2RiskPremiumOverTheRiskFreeRate] = Constants.RprBuildup2;
        overrides[SelectionType.Buildup3RiskPremiumOverTheRiskFreeRate] = Constants.RprBuildup3;

        const frequencies: Dictionary<string> = {};
        frequencies[Constants.SizePremium] = Constants.AnnualData;
        frequencies[Constants.RprCapm] = Constants.AnnualData;
        frequencies[Constants.RprBuildup1] = Constants.AnnualData;
        frequencies[Constants.RprBuildup2] = Constants.AnnualData;
        frequencies[Constants.RprBuildup3] = Constants.AnnualData;
        frequencies['Historical Long-term (1926–Present)'] = Constants.AnnualData;
        frequencies['Supply-side Long-term (1926–Present)'] = Constants.AnnualData;
        // frequencies['Duff & Phelps Recommended'] = Constants.Data;
        frequencies['Kroll Recommended'] = Constants.Data;
        // frequencies['Duff & Phelps Normalized Rate'] = Constants.Data;
        frequencies['Kroll Normalized Risk-free Rate'] = Constants.Data;
        frequencies['Spot 20-year Treasury Yield: Federal Reserve'] = Constants.DailyData;

        for (const industry of industries) {
            frequencies[`GICS ${industry.SicCode} Median Vasicek-Adjusted Beta`] = Constants.QuarterlyData;
            frequencies[`GICS ${industry.SicCode} Median Sum Beta`] = Constants.QuarterlyData;
            frequencies[`GICS ${industry.SicCode} HFR Composite Beta`] = Constants.QuarterlyData;
            frequencies[`GICS ${industry.SicCode} Full-information Beta`] = Constants.QuarterlyData;
            frequencies[`SIC ${industry.SicCode} Median Vasicek-Adjusted Beta`] = Constants.QuarterlyData;
            frequencies[`SIC ${industry.SicCode} Median Sum Beta`] = Constants.QuarterlyData;
            frequencies[`SIC ${industry.SicCode} HFR Composite Beta`] = Constants.QuarterlyData;
            frequencies[`SIC ${industry.SicCode} Full-information Beta`] = Constants.QuarterlyData;
        }

        const replacements: Dictionary<string> = {};
        replacements['Spot 20-year Treasury Yield'] = 'Spot 20-year Treasury Yield: Federal Reserve';

        let context = overrides[selection.SelectionType] || selection.Context || '';
        context = replacements[context] || context;
        if (selection.SourceDataAsOf == null) {
            throw new Error(`Selection SourceDataAsOf should not have been null`);
        }

        const date = moment(selection.SourceDataAsOf).format('MM/DD/YYYY');
        const frequency = frequencies[context];

        return {
            group: groups[selection.SelectionType],
            source: `${context}: ${frequency} as of ${date}`
        };
    }

    private setEquitySummaryGroups(): void {
        for (const group of this.equityGroups) {
            const studies = Util.selectMany(group.studyGroups, (x) => x.studies);
            const scenarios = Util.selectMany(studies, (x) => x.scenarios);
            const equations = Util.selectMany(scenarios, (x) => x.equations);
            const values = equations.filter((equation) => equation.hasResult && !equation.isExcluded)
                .map((equation) => equation.result.asNumber)
                .filter(Util.notNull);

            const avg = Util.average(values);
            const med = Util.median(values);
            const min = Math.min(...values);
            const max = Math.max(...values);

            group.average = this.toPercentageOrNotApplicable(avg);
            group.median = this.toPercentageOrNotApplicable(med);

            group.chartConfig = {
                name: 'My Range of Levered Estimates',
                average: undefined || avg,
                median: undefined || med,
                low: min,
                high: max,
                rangeMin: min,
                rangeMax: max
            };

            group.chart = this.getGroupChart(values, group, studies);
        }
    }

    // TODO: This code is not acceptable.
    private getGroupChart(values: number[], group: EquitySummaryGroup, studies: SummaryStudy[]): Chart {
        const categoryValues: string[] = [];
        const seriesValues: number[] = [];

        // for equation charts, it depends on what group equals what which graph to produce
        if (group.name === 'Levered' || group.name === 'High Financial Risk') {
            // todo: there is probably a much better way to group and average this code, but since we're not using
            // a 3rd-party tool like lodash... I'll leave that to a js expert
            const stdGroup = studies
                .map((std) =>
                ({
                    name: std.name,
                    studyType: std.studyType,
                    scenarios: std.scenarios.map((sce) => ({
                        values: sce.equations.filter((eq) => eq.hasResult && !eq.isExcluded)
                            .map((eq) => eq.result.asNumber)
                            .filter(Util.notNull),
                        average: sce.equations.filter((eq) => eq.hasResult && !eq.isExcluded)
                            .map((eq) => eq.result.asNumber)
                            .filter(Util.notNull).reduce((p, c, _, a) => Util.round((p + c / a.length)), 0)
                    }))
                })
                );

            // TODO: These should NOT be any
            const groupedData = stdGroup.reduce((l: any, r: any) => {
                // construct a unique key out of the properties we want to group by
                const key = r.name + '|' + r.studyType;

                // check if the key is already known
                if (typeof l[key] === 'undefined') {
                    // init with an "empty" object
                    l[key] = {
                        sum: 0,
                        count: 0
                    };
                }

                // sum up the values and count the occurences
                for (const s of r.scenarios) {
                    for (const v of s.values) {
                        l[key].sum += v;
                        l[key].count += 1;
                    }
                }

                return l;
            }, {});

            // TODO: key should NOT be any
            const avgGroupedData = Object.keys(groupedData)
                .map((key: any) => {
                    // split the constructed key to get the parts
                    const keyParts = key.split(/\|/);

                    // construct the "old" format including the average value
                    return {
                        name: keyParts[0],
                        studyType: keyParts[1],
                        average: (groupedData[key].sum / groupedData[key].count)
                    };
                });

            for (const val of avgGroupedData) {
                if (val.average != null && !isNaN(val.average)) {
                    categoryValues.push(val.name);
                    seriesValues.push(val.average);
                }
            }
        } else {
            categoryValues.push(group.name);
            seriesValues.push(undefined || Util.average(values));
        }

        return new Chart({
            chart: {
                type: 'column'
            },
            title: {
                text: 'Cost Of Equity',
                align: 'left'
            },
            credits: {
                enabled: false
            },
            xAxis: {
                categories: categoryValues
            },
            tooltip: {
                valueDecimals: 2
            },
            plotOptions: {
                column: {
                    showInLegend: false,
                    colorByPoint: true,
                    dataLabels: {
                        enabled: true,
                        crop: false,
                        format: '{point.y:.2f}%'
                    }
                }
            },
            series: [{
                type: 'column',
                data: seriesValues,
                name: 'Cost of Equity'
            }]
        });
    }

    private setInputSummaryGroups(estimate: Estimate): void {
        this.setGeneralInputsSummaryGroup(estimate);
        this.setSizeMeasureSummaryGroup(estimate);
        this.setRiskMeasuresSummaryGroup(estimate);
        this.setCrspStudySummaryGroup(estimate);
        this.setRprStudySummaryGroup(estimate);
        this.setHfrStudySummaryGroup(estimate);
        this.setSelectionLogInputs(estimate);
        this.setRiskSizeMeasures(estimate);
    }

    private setSelectionLogInputs(estimate: Estimate): void {
        const parameters = Util.selectMany(estimate.Scenarios, (scenario) => scenario.Selections);
        const suggestions = estimate.Suggestions;

        // TODO Create a builder method to remove this repeative logic
        const riskFreeRateValue = parameters.filter((x) => SelectionType[x.SelectionType] === SelectionType.RiskFreeRate);
        const riskFreeRateSource = suggestions.find((x) => x.SelectionType === SelectionType.RiskFreeRate &&
            x.Value === riskFreeRateValue[0].Value);
        const rfrSourceName = riskFreeRateSource ? riskFreeRateSource.SourceName : undefined;
        const riskFreeRate = this.setExcelSummarySelection(riskFreeRateValue, 'Risk-Free Rate', rfrSourceName);
        riskFreeRate.value = this.toPercentageOrNotApplicable(parseFloat(riskFreeRate.value));

        const crspDecilesBetaValue = parameters.filter((x) => SelectionType[x.SelectionType] === SelectionType.CrspDecilesBeta);
        const crspDecilesBetaSource = suggestions.find((x) => x.SelectionType === SelectionType.CrspDecilesBeta &&
            x.Value === crspDecilesBetaValue[0].Value);
        const crspDecilesBetaSourceName = crspDecilesBetaSource ? crspDecilesBetaSource.SourceName : undefined;
        const crspDecilesBeta = this.setExcelSummarySelection(
            crspDecilesBetaValue,
            'Beta (CRSP Deciles Size Study)',
            crspDecilesBetaSourceName
        );

        const crspIndustryRiskPremiumValue = parameters
            .filter((x) => SelectionType[x.SelectionType] === SelectionType.CrspIndustryRiskPremium);
        const crspIndustryRiskPremiumSource = suggestions.find((x) => x.SelectionType === SelectionType.CrspIndustryRiskPremium);
        const crspIndustryRiskPremiumSourceName = crspIndustryRiskPremiumSource ? crspIndustryRiskPremiumSource.SourceName : undefined;

        const crspIndustryRiskPremium = this.setExcelSummarySelection(
            crspIndustryRiskPremiumValue,
            'Industry Risk Premium (CRSP Deciles Size Study)',
            crspIndustryRiskPremiumSourceName
        );
        crspIndustryRiskPremium.value = this.toPercentageOrNotApplicable(parseFloat(crspIndustryRiskPremium.value));

        const riskPremiumBetaValue = parameters.filter((x) => SelectionType[x.SelectionType] === SelectionType.RiskPremiumBeta);
        const riskPremiumBetaSource = suggestions.find((x) => x.SelectionType === SelectionType.RiskPremiumBeta &&
            x.Value === riskPremiumBetaValue[0].Value);
        const riskPremiumBetaSourceName = riskPremiumBetaSource ? riskPremiumBetaSource.SourceName : undefined;
        const riskPremiumBeta = this.setExcelSummarySelection(
            riskPremiumBetaValue,
            'Beta (Risk Premium Report Study)',
            riskPremiumBetaSourceName
        );

        const rprIndustryRiskPremiumValue = parameters
            .filter((x) => SelectionType[x.SelectionType] === SelectionType.RprIndustryRiskPremium);
        const rprIndustryRiskPremiumSource = suggestions.find((x) => x.SelectionType === SelectionType.RprIndustryRiskPremium);
        const rprIndustryRiskPremiumSourceName = rprIndustryRiskPremiumSource ? rprIndustryRiskPremiumSource.SourceName : undefined;
        const rprIndustryRiskPremium = this.setExcelSummarySelection(
            rprIndustryRiskPremiumValue,
            'Industry Risk Premium (Risk Premium Report Study)',
            rprIndustryRiskPremiumSourceName
        );
        rprIndustryRiskPremium.value = this.toPercentageOrNotApplicable(parseFloat(rprIndustryRiskPremium.value));

        const highFinancialRiskBetaValue = parameters
            .filter((x) => SelectionType[x.SelectionType] === SelectionType.HighFinancialRiskBeta);
        const highFinancialRiskBetaSource = suggestions.find((x) => x.SelectionType === SelectionType.HighFinancialRiskBeta);
        const highFinancialRiskBetaSourceName = highFinancialRiskBetaSource ? highFinancialRiskBetaSource.SourceName : undefined;
        const highFinancialRiskBeta = this.setExcelSummarySelection(
            highFinancialRiskBetaValue,
            'Beta (High-Financial-Risk Study)',
            highFinancialRiskBetaSourceName
        );

        const equityRiskPremiumValue = parameters.filter((x) => SelectionType[x.SelectionType] === SelectionType.EquityRiskPremium);
        const equityRiskPremiumSource = suggestions.find((x) => x.SelectionType === SelectionType.EquityRiskPremium &&
            x.Value === equityRiskPremiumValue[0].Value);
        const equityRiskPremiumSourceName = equityRiskPremiumSource ? equityRiskPremiumSource.SourceName : undefined;
        const equityRiskPremium = this.setExcelSummarySelection(equityRiskPremiumValue, 'ERP', equityRiskPremiumSourceName);
        equityRiskPremium.value = this.toPercentageOrNotApplicable(parseFloat(equityRiskPremium.value));

        const sizeRiskPremia = {
            value: 'See size and risk premia summary tab',
            name: 'Size Premia & Risk Premia Over the Risk-free Rate',
            dataAsOf: this.valuationDate ? this.valuationDate : '-'
        };

        if (crspDecilesBeta.value !== '-' && equityRiskPremium.value !== '-') {
            crspIndustryRiskPremium.type = `Based on ${crspDecilesBeta.type} and ${equityRiskPremium.type} ERP`;
            crspIndustryRiskPremium.source = crspDecilesBeta.source;
        } else {
            crspIndustryRiskPremium.type = '-';
        }

        if (riskPremiumBeta.value !== '-' && equityRiskPremium.value !== '-') {
            rprIndustryRiskPremium.type = `Based on ${riskPremiumBeta.type} and ${equityRiskPremium.type} ERP`;
            rprIndustryRiskPremium.source = riskPremiumBeta.source;
        } else {
            rprIndustryRiskPremium.type = '-';
        }

        this.costOfEquityInputs = [
            riskFreeRate,
            crspDecilesBeta,
            crspIndustryRiskPremium,
            riskPremiumBeta,
            rprIndustryRiskPremium,
            highFinancialRiskBeta,
            equityRiskPremium,
            sizeRiskPremia
        ];
    }

    private setExcelSummarySelection(selections: Selection[], selectionName: string, sourceName?: string): ExcelSummaryInput {
        const input = selections[0];

        return {
            value: input && input.Value ? input.Value.toString() : '-',
            name: selectionName,
            type: input && input.Value && input.Context ? input.Context : '-',
            source: input && input.Value && sourceName ? sourceName : '-',
            dataAsOf: input && input.SourceDataAsOf ? moment(input.SourceDataAsOf).format('MM/DD/YYYY') : '-'
        };
    }

    private setExcelWaccSummaryInput(waccValue: string | undefined, selectionName: string, type?: string | null): any {
        const excelSummaryInput = {
            value: waccValue ? waccValue : '-',
            name: selectionName,
            type: type && type !== 'Custom' ? type + ' User Estimate' : 'Custom',
            source: 'Custom',
            dataAsOf: '-'
        };
        if (selectionName === 'Cost of Equity Capital') {
            const { dataAsOf, ...rest } = excelSummaryInput;
            const cocExcelSummaryInput = rest;
            return cocExcelSummaryInput;
        } else if (selectionName === 'Tax Rate' && waccValue == null) {
            excelSummaryInput.value = 'Custom';
            return excelSummaryInput;
        } else {
            return excelSummaryInput;
        }
    }

    private setGeneralInputsSummaryGroup(estimate: Estimate): void {
        if (estimate.ValuationDate == null) {
            return;
        }
        const usGICDate = new Date('2020-09-30');

        this.valuationDate = moment(estimate.ValuationDate).format('MM/DD/YYYY');

        if (new Date(this.valuationDate).getTime() >= usGICDate.getTime()) {
            this.industries = estimate.Industries.map((x) => x.SicCode ? `GICS ${x.SicCode} - ${x.IndustryName}` : 'N/A');
        } else {
            this.industries = estimate.Industries.map((x) => x.SicCode ? `SIC ${x.SicCode} - ${x.IndustryName}` : 'N/A');
        }
        this.generalInputs.categories[0].items = SummaryUtil.getGeneralInputsItems(this.valuationDate, this.industries);
    }

    private setSizeMeasureSummaryGroup(estimate: Estimate): void {
        this.sizeMeasures.categories[0].items = [];
        const risks = OperandUtility.getRisks(EquationType.RprsCapmSizeStudy); // EquationType doesn't matter

        for (const risk of risks) {
            const item = estimate.getInput(risk.inputType);
            let value = 'N/A';

            if (item.Value) {
                if (InputType[item.InputType] === InputType.NumberOfEmployees) {
                    value = NumberFormatUtil.numberWithCommas(Util.round(item.Value), 2);
                } else {
                    value = `$${Util.delimitDigitsByComma(item.Value)}m`; // `
                }
            }

            this.sizeMeasures.categories[0].items.push({
                name: risk.name,
                value,
                type: item.InputType
            });
        }
    }

    private setRiskSizeMeasures(estimate: Estimate): void {
        const parameters = Util.selectMany(estimate.Scenarios, (scenario) => scenario.Selections);
        const risksLevered = OperandUtility.getRisks(EquationType.RprsBuildup1Levered);
        const risksUnlevered = OperandUtility.getRisks(EquationType.RprsBuildup1Unlevered);
        const risksRelevered = OperandUtility.getRisks(EquationType.RprsBuildup1Relevered);
        const crspCAPMRisks = OperandUtility.getRisks(EquationType.CrspCapmSizeStudy);
        const rprsCapmSizeStudyRisks = OperandUtility.getRisks(EquationType.CrspBuildup);
        const HFRCapmRisks = OperandUtility.getRisks(EquationType.HfrsCapmHfr);
        const HFRBuildupARisks = OperandUtility.getRisks(EquationType.HfrsBuildupHfr);
        const buildup3Risks = OperandUtility.getBuildup3Risks();

        this.riskPremiaLevered = this.setupRiskMeasures(risksLevered, parameters, 'RiskPremiumOverRiskFreeRateLevered');

        this.buildup3Risks = buildup3Risks;
        this.riskPremiaCoefficients = this.setupRiskMeasures(buildup3Risks, parameters);
        this.riskPremiaCoefficients = this.riskPremiaCoefficients.map((risk) => {
            return {
                ...risk,
                value: this.toPercentageOrNotApplicable(parseFloat(risk.value))
            };
        });

        this.riskPremiaUnlevered = this.setupRiskMeasures(risksUnlevered, parameters, 'RiskPremiumOverRiskFreeRateUnlevered');
        this.riskPremiaRelevered = this.setupRiskMeasures(risksRelevered, parameters, 'RiskPremiumOverRiskFreeRateRelevered');
        this.riskCRSPCapm = this.setupRiskMeasures(crspCAPMRisks, parameters, 'CrspSizePremium');
        this.riskRprsCapmSizeStudy = this.setupRiskMeasures(rprsCapmSizeStudyRisks, parameters, 'RiskPremiumOverCapm');
        this.riskHFRCapm = this.setupRiskMeasures(HFRCapmRisks, parameters, 'HighFinancialRiskPremiumOverCapM');
        this.riskHFRBuildupHfr = this.setupRiskMeasures(HFRBuildupARisks, parameters, 'HighFinancialRiskPremiumOverRiskFreeRate');
    }

    private setupRiskMeasures(risks: Risk[], parameters: Selection[], selectionTypeValue?: string): ExcelSummaryInput[] {
        let excelRiskMeasures: ExcelSummaryInput[] = [];

        if (risks.length) {
            const selectionType = selectionTypeValue ? selectionTypeValue : risks[0].selectionType;
            const riskMeasures = parameters.filter((x) => SelectionType[x.SelectionType] === selectionType);

            for (const risk of riskMeasures) {
                const riskItem = this.setExcelSummarySelection([risk], risk.InputType);

                excelRiskMeasures = [...excelRiskMeasures, riskItem];
            }
        }

        return excelRiskMeasures;
    }

    private setRiskMeasuresSummaryGroup(estimate: Estimate): void {
        const averageOperatingMargin = estimate.getInput(InputType.AverageOperatingMargin);
        const operatingMargin = estimate.getInput(InputType.OperatingMargin);
        const covOperatingMargin = estimate.getInput(InputType.CoefficientOfVariationOfOperatingMargin);
        const covReturnOnEquity = estimate.getInput(InputType.CoefficientOfVariationOfReturnOnEquity);

        this.riskMeasures.categories[0].items = [{
            name: 'Average Operating Margin',
            value: this.toPercentageOrNotApplicable(averageOperatingMargin.Value),
            type: averageOperatingMargin.InputType
        }, {
            name: 'Coefficient of Variation of Operating Margin',
            value: this.toPercentageOrNotApplicable(covOperatingMargin.Value),
            type: covOperatingMargin.InputType
        }, {
            name: 'Coefficient of Variation of Return on Equity',
            value: this.toPercentageOrNotApplicable(covReturnOnEquity.Value),
            type: covReturnOnEquity.InputType
        }];

        this.riskMeasuresSummary.categories[0].items = [...this.riskMeasures.categories[0].items, {
            name: 'Operating Margin',
            value: this.toPercentageOrNotApplicable(operatingMargin.Value),
            type: operatingMargin.InputType
        }];
    }

    // TODO: Clean this up
    private setCrspStudySummaryGroup(estimate: Estimate): void {
        const marketValue = estimate.getInput(InputType.MarketValueOfCommonEquity);

        if (estimate.ValuationDate == null || marketValue.Value == null) {
            return;
        }

        const parameters = Util.selectMany(estimate.Scenarios, (scenario) => scenario.Selections);
        const sizePremiums = parameters.filter((x) => SelectionType[x.SelectionType] === SelectionType.CrspSizePremium ||
            SelectionType[x.SelectionType] === SelectionType.CrspBuildupSizePremium)
            .filter((x) => x.Value != null);

        const request = this.dataStore.getSizePremium(estimate.ValuationDate, marketValue.Value);
        request.subscribe((selectedDecile) => {
            let sp = 'N/A';
            let spgroup = 'N/A';

            const sources = sizePremiums.map((x) => x.Context).filter(Util.notNull);
            if (sources.includes(selectedDecile.Target.Description)) {
                sp = `${this.toPercentageOrNotApplicable(selectedDecile.Target.CapM)}` + // `
                    ` – ${selectedDecile.Target.Name}`; // `

                if (selectedDecile.Target.Description.includes('Decile 10')) {
                    const hasTargetDecile = sources.find((x) => x.indexOf('Decile 10') > -1);
                    const hasPrimarySplit = sources.find((x) => x.indexOf('Decile 10B') > -1 ||
                        x.indexOf('Decile 10A') > -1);
                    const hasSecondarySplit = sources.find((x) => x.indexOf('Decile 10Z') > -1 ||
                        x.indexOf('Decile 10Y') > -1 ||
                        x.indexOf('Decile 10W') > -1);

                    const usedSp = [];

                    if (hasTargetDecile) {
                        usedSp.push(`${this.toPercentageOrNotApplicable(selectedDecile.Target.CapM)}` + // `
                            ` – ${selectedDecile.Target.Name}`); // `
                    }
                    if (hasPrimarySplit) {
                        usedSp.push(`${this.toPercentageOrNotApplicable(selectedDecile.PrimarySplit.CapM)}` + // `
                            ` – ${selectedDecile.PrimarySplit.Name}`); // `
                    }
                    if (hasSecondarySplit) {
                        usedSp.push(`${this.toPercentageOrNotApplicable(selectedDecile.SecondarySplit.CapM)}` + // `
                            ` – ${selectedDecile.SecondarySplit.Name}`); // `
                    }

                    sp = usedSp.join(', ');
                }
            }

            if (selectedDecile.Grouping && sizePremiums.find((x) => x.Context === selectedDecile.Grouping.Description)) {
                spgroup = `${this.toPercentageOrNotApplicable(Math.max(0, selectedDecile.Grouping.CapM))}` + // `
                    ` – ${selectedDecile.Grouping.Name}`; // `
            }

            this.crspStudy.categories[0].items = [{
                name: 'CRSP Decile Size Premium',
                value: sp
            }, {
                name: 'Size Group Size Premium',
                value: spgroup
            }];
        });
    }

    // TODO: Clean all this up
    // TODO: Don't double count values
    private setRprStudySummaryGroup(estimate: Estimate): void {
        const risks = Util.selectMany(estimate.Scenarios, (x) => x.Selections);

        const rpOverRfrBuildup2 =
            risks.filter((x) => SelectionType[x.SelectionType] === SelectionType.Buildup2RiskPremiumOverTheRiskFreeRate)
                .map((x) => x.Value).filter(Util.notNull);

        const rpOverCapm =
            risks.filter((x) => SelectionType[x.SelectionType] === SelectionType.RiskPremiumOverCapm)
                .map((x) => x.Value).filter(Util.notNull).concat(rpOverRfrBuildup2);

        const rpOverRfrLevered =
            risks.filter((x) => SelectionType[x.SelectionType] === SelectionType.RiskPremiumOverRiskFreeRateLevered)
                .map((x) => x.Value).filter(Util.notNull);

        const rpOverRfrUnlevered =
            risks.filter((x) => SelectionType[x.SelectionType] === SelectionType.RiskPremiumOverRiskFreeRateUnlevered)
                .map((x) => x.Value).filter(Util.notNull);

        const rpOverRfrRelevered =
            risks.filter((x) => SelectionType[x.SelectionType] === SelectionType.RiskPremiumOverRiskFreeRateRelevered)
                .map((x) => x.Value).filter(Util.notNull);

        const rpOverRfrBuildup3 =
            risks.filter((x) => SelectionType[x.SelectionType] === SelectionType.Buildup3RiskPremiumOverTheRiskFreeRate)
                .map((x) => x.Value).filter(Util.notNull);

        this.rprStudy.categories = [{
            name: 'CAPM + Size Premium / Build-up 2',
            items: [{
                name: 'Average Risk Premium over CAPM',
                value: this.toPercentageOrNotApplicable(Util.average(rpOverCapm))
            }, {
                name: 'Median Risk Premium over CAPM',
                value: this.toPercentageOrNotApplicable(Util.median(rpOverCapm))
            }]
        }, {
            name: 'Build-up 1 (Levered)',
            items: [{
                name: 'Average Levered Risk Premium over the Risk-free Rate (Size Study)',
                value: this.toPercentageOrNotApplicable(Util.average(rpOverRfrLevered))
            }, {
                name: 'Median Levered Risk Premium over the Risk-free Rate (Size Study)',
                value: this.toPercentageOrNotApplicable(Util.median(rpOverRfrLevered))
            }]
        }, {
            name: 'Build-up 1 (Unlevered)',
            items: [{
                name: 'Average Unlevered Risk Premium over the Risk-free Rate (Size Study)',
                value: this.toPercentageOrNotApplicable(Util.average(rpOverRfrUnlevered))
            }, {
                name: 'Median Unlevered Risk Premium over the Risk-free Rate (Size Study)',
                value: this.toPercentageOrNotApplicable(Util.median(rpOverRfrUnlevered))
            }]
        }, {
            name: 'Build-up 1 (Relevered)',
            items: [{
                name: 'Average Relevered Risk Premium over the Risk-free Rate (Size Study)',
                value: this.toPercentageOrNotApplicable(Util.average(rpOverRfrRelevered))
            }, {
                name: 'Median Relevered Risk Premium over the Risk-free Rate (Size Study)',
                value: this.toPercentageOrNotApplicable(Util.median(rpOverRfrRelevered))
            }]
        }, {
            name: 'Build-up 3',
            items: [{
                name: 'Average Levered Risk Premium over the Risk-free Rate (Risk Study)',
                value: this.toPercentageOrNotApplicable(Util.average(rpOverRfrBuildup3))
            }, {
                name: 'Median Levered Risk Premium over the Risk-free Rate (Risk Study)',
                value: this.toPercentageOrNotApplicable(Util.median(rpOverRfrBuildup3))
            }]
        }];
    }

    // TODO: This seems like it cannot be correct.
    private setHfrStudySummaryGroup(estimate: Estimate): void {
        const zScore = estimate.getInput(InputType.ZScore);
        const companyType = estimate.getInput(InputType.CompanyType).Value || 0;
        const scenario = estimate.Scenarios[0];
        const rpOverCapm = scenario.getSelection(SelectionType.HighFinancialRiskPremiumOverCapM, InputType.None);
        const rpOverRfr = scenario.getSelection(SelectionType.HighFinancialRiskPremiumOverRiskFreeRate, InputType.None);

        const distressZone = rpOverCapm.Context;
        const zscoreTitle = zScore.Value ? `Z-Score (${CompanyType[companyType]})` : 'Z-Score';
        const zscoreString = zScore.Value ?
            `${NumberFormatUtil.numberWithCommas(zScore.Value, 2)} (${distressZone || '(Not Distressed)'})` : 'N/A';

        this.hfrStudy.categories = [{
            name: '',
            items: [{
                name: zscoreTitle,
                value: zscoreString
            }]
        }, {
            name: 'Build-up HFR',
            items: [{
                name: 'Risk Premium over the Risk-Free rate',
                value: this.toPercentageOrNotApplicable(rpOverRfr.Value)
            }]
        }, {
            name: 'CAPM + HFR Size Premium',
            items: [{
                name: 'Risk Premium over CAPM',
                value: this.toPercentageOrNotApplicable(rpOverCapm.Value)
            }]
        }];
    }

    private buildStudyEquation(
        component: typeof CrspCapmComponent | typeof CrspBuildupComponent | typeof HfrCapmComponent | typeof HfrBuildupComponent
    ): Equation {
        const instance = new component();
        return instance.buildEquation();
    }

    private buildStudyEquations(
        component: typeof RiskCapmComponent | typeof RiskBuildupTwoComponent
    ): Equation[] {
        const instance = new component();
        return instance.buildEquations();
    }

    private buildMultiStudyEquations(
        component: typeof RiskBuildupThreeComponent
    ): Equation[] {
        const instance = new component(this.resolver);
        return instance.buildEquations();
    }

    private buildLeveredStudyEquations(
        component: typeof RiskBuildupOneComponent, type: EquationType
    ): Equation[] {
        const instance = new component();
        return instance.buildEquations(type);
    }

    private setStudies(estimate: Estimate): any {
        const crspStudies = [
            this.getStudy(
                estimate,
                [this.buildStudyEquation(CrspCapmComponent)],
                'CAPM + Size Premium',
                StudyType.CrspDecileSizePremiumStudy
            ),
            this.getStudy(
                estimate,
                [this.buildStudyEquation(CrspBuildupComponent)],
                'Build-up + Size Premium',
                StudyType.CrspDecileSizePremiumStudy
            )
        ];

        const riskStudies = [
            this.getStudy(
                estimate,
                this.buildStudyEquations(RiskCapmComponent),
                'CAPM + Size Premium',
                StudyType.RiskPremiumReportStudy
            ),
            this.getStudy(
                estimate,
                this.buildLeveredStudyEquations(RiskBuildupOneComponent, EquationType.RprsBuildup1Levered),
                'Build-up 1',
                StudyType.RiskPremiumReportStudy
            ),
            this.getStudy(
                estimate,
                this.buildStudyEquations(RiskBuildupTwoComponent),
                'Build-up 2',
                StudyType.RiskPremiumReportStudy
            ),
            this.getStudy(
                estimate,
                this.buildMultiStudyEquations(RiskBuildupThreeComponent),
                'Build-up 3',
                StudyType.RiskPremiumReportStudy
            )
        ];

        const unleveredStudies = [
            this.getStudy(
                estimate,
                this.buildLeveredStudyEquations(RiskBuildupOneComponent, EquationType.RprsBuildup1Unlevered),
                'Build-up 1',
                StudyType.RiskPremiumReportStudy
            )
        ];

        const releveredStudies = [
            this.getStudy(
                estimate,
                this.buildLeveredStudyEquations(RiskBuildupOneComponent, EquationType.RprsBuildup1Relevered),
                'Build-up 1',
                StudyType.RiskPremiumReportStudy
            )
        ];

        const hfrStudies = [
            this.getStudy(
                estimate,
                [this.buildStudyEquation(HfrCapmComponent)],
                'CAPM + HFR Size Premium',
                StudyType.HighFinancialRiskStudy
            ),
            this.getStudy(
                estimate,
                [this.buildStudyEquation(HfrBuildupComponent)],
                'Build-up HFR Size Premium',
                StudyType.HighFinancialRiskStudy
            )
        ];

        const crspStudyGroup: SummaryStudyGroup = {
            name: 'CRSP Deciles Size Study',
            studies: crspStudies,
            show: this.hasAnyShown(crspStudies)
        };

        const riskStudyGroup: SummaryStudyGroup = {
            name: 'Risk Premium Report Study',
            studies: riskStudies,
            show: this.hasAnyShown(riskStudies)
        };

        const unleveredRiskStudyGroup: SummaryStudyGroup = {
            name: 'Risk Premium Report Study',
            studies: unleveredStudies,
            show: this.hasAnyShown(unleveredStudies)
        };

        const releveredRiskStudyGroup: SummaryStudyGroup = {
            name: 'Risk Premium Report Study',
            studies: releveredStudies,
            show: this.hasAnyShown(releveredStudies)
        };

        const hfrStudyGroup: SummaryStudyGroup = {
            name: 'High Financial Risk Study',
            studies: hfrStudies,
            show: this.hasAnyShown(hfrStudies)
        };

        this.levered.studyGroups = [crspStudyGroup, riskStudyGroup];
        this.unlevered.studyGroups = [unleveredRiskStudyGroup];
        this.relevered.studyGroups = [releveredRiskStudyGroup];
        this.highFinancialRisk.studyGroups = [hfrStudyGroup];

        const zScore = estimate.getInput(InputType.ZScore);

        this.highFinancialRiskZScore = {
            value: zScore.Value ? zScore.Value.toString() : '-',
            name: 'Z-Score'
        };
    }

    private setDuplicatesAsExcluded(equations: SummaryEquation[]): void {
        const unsetEquations = equations.filter((equation) => equation.isExcluded == null);

        unsetEquations.forEach((equation) => equation.isUnique = false);

        const uniqueEquations = equations.filter((item, position) => {
            const index = equations.findIndex((x) =>
                x.form === item.form &&
                x.equation.EquationType === item.equation.EquationType &&
                x.equation.ImplicationType === item.equation.ImplicationType
            );
            return index === position;
        });

        uniqueEquations.forEach((equation) => equation.isUnique = true);

        unsetEquations.filter((equation) => equation.isUnique === false)
            .forEach((equation) => equation.isExcluded = true);
    }

    private getStudy(
        estimate: Estimate,
        equationStructures: Equation[],
        studyName: string,
        studyType: StudyType
    ): SummaryStudy {
        const scenarios = estimate.Scenarios.map((scenario) => {
            // TODO: Give this a better name or something
            const eqs = equationStructures.map((structure) => this.getEquation(scenario, structure));

            return {
                name: scenario.Name,
                equations: eqs,
                show: this.hasAnyWithResult(eqs)
            } as SummaryScenario;
        });

        const equations = Util.selectMany(scenarios, (x) => x.equations);
        this.setDuplicatesAsExcluded(equations);

        return {
            name: studyName,
            scenarios,
            show: this.hasAnyShown(scenarios),
            studyType
        };
    }

    private getEquation(scenario: Scenario, equationStructure: Equation): SummaryEquation {
        const parameters: ContextualValue<string>[] = [];
        equationStructure.calculate(scenario);
        const equation = scenario.getEquation(equationStructure.equationType, equationStructure.implicationType);
        const result = new ContextualNumber(equation.Result);

        for (const parameter of equationStructure.parameters) {
            const selection = scenario.getItemAsContextualNumber(parameter.item);
            const value = (parameter.item.mask || '{}%').replace('{}', selection.asStringOr(''));
            parameters.push(new ContextualValue<string>(`${value}`, selection.context));
            parameters.push(new ContextualValue<string>(parameter.operator, null));
        }

        const form = parameters.map((parameter) => parameter.value).reduce((prev, curr) => prev || '' + curr, '') || '';
        const ask = scenario.getEquation(equationStructure.equationType, equationStructure.implicationType);

        return {
            name: equationStructure.name,
            form,
            result,
            hasResult: result.hasValue,
            isExcluded: ask.IsExcluded || false,
            parameters,
            equation: ask,
            scenario
        };
    }

    private setAssumptions(estimate: Estimate): void {
        const displayDataSource = (data: ContextualNumber, suffix: string) => {
            return data.asNumber
                ? `${data.asNumber}${suffix} - ${data.context}`
                : `N/A`;
        };

        this.assumptions = [];
        for (const scenario of estimate.Scenarios) {
            const erp = displayDataSource(scenario.getSelectionAsContextualNumber(SelectionType.EquityRiskPremium, InputType.None), '%');
            const crspBeta = displayDataSource(scenario.getSelectionAsContextualNumber(SelectionType.CrspDecilesBeta, InputType.None), '');
            const rprBeta = displayDataSource(scenario.getSelectionAsContextualNumber(SelectionType.RiskPremiumBeta, InputType.None), '');
            const hfrBeta = displayDataSource(
                scenario.getSelectionAsContextualNumber(SelectionType.HighFinancialRiskBeta, InputType.None), '');
            const rfr = displayDataSource(scenario.getSelectionAsContextualNumber(SelectionType.RiskFreeRate, InputType.None), '%');

            this.assumptions.push({
                name: scenario.Name,
                items: [
                    { name: 'Equity Risk Premium (ERP)', value: erp },
                    { name: 'CRSP Beta', value: crspBeta },
                    { name: 'RPR Beta', value: rprBeta },
                    { name: 'HFR Beta', value: hfrBeta },
                    { name: 'Risk-free Rate', value: rfr }]
            });
        }
    }

    private hasAnyShown(entities: HasShow[]): boolean {
        return entities.map((x) => x.show).reduce((c, p) => c || p, false);
    }

    private hasAnyWithResult(entities: SummaryEquation[]): boolean {
        return entities.map((x) => x.hasResult).reduce((c, p) => c || p, false);
    }

    private toPercentageOrNotApplicable(num: number | null | undefined): string {
        return num == null || isNaN(num) ? '-' : `${NumberFormatUtil.numberWithCommas(num)}%`; // `
    }

    public summarize(estimate: Estimate): void {
        this.setStudies(estimate);
        this.setEquitySummaryGroups();
        this.setInputSummaryGroups(estimate);
        this.setAssumptions(estimate);
        this.setDataSources(estimate);
        this.setWaccSummary(estimate);
        this.setExcelHFRSelectionLog(estimate);
    }
}

interface DataSourceSection {
    name: string;
    groups: DataSourceGroup[];
}

interface DataSourceGroup {
    name: string;
    sources: string[];
}

class Constants {
    public static readonly Navigator = 'Kroll Cost of Capital Navigator';
    public static readonly Handbook = 'Cost of Capital Navigator – U.S. Industry Benchmarking Module';

    public static readonly Crsp = 'Crsp Deciles Size Study';
    public static readonly RiskPremiumReport = 'Risk Premium Report Study';
    public static readonly Beta = 'Beta';
    public static readonly EquityRiskPremia = 'Equity Risk Premia';
    public static readonly RiskFreeRate = 'Risk Free Rate';

    public static readonly SizePremium = 'Size Premium';
    public static readonly RprCapm = 'Risk Premium Over Capm';
    public static readonly RprBuildup1 = 'Risk Premium Over the Risk-free Rate (Build-up 1)';
    public static readonly RprBuildup2 = 'Size Premium (Build-up 2)';
    public static readonly RprBuildup3 = 'Risk Premium Over the Risk-free Rate (Build-up 3)';

    public static readonly Data = 'Data';
    public static readonly DailyData = 'Daily Data';
    public static readonly QuarterlyData = 'Quarterly Data';
    public static readonly AnnualData = 'Annual Data';
}
