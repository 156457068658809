import { Injectable } from '@angular/core';
import { Util } from '@concurrency/core';
import { Observable } from 'rxjs/internal/Observable';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Country } from 'src/app/_api/responses/country.response';
import { CurrencyData } from 'src/app/_api/responses/currency-data.response';
import { Gic } from 'src/app/_api/responses/gic.response';
import { ComboboxDataType } from 'src/app/_navigator/data/enum/combobox-data-type.enum';
import { Suggestion } from 'src/app/_navigator/data/model/suggestion.model';

@Injectable()
export class InternationalQueryBuilderService {

    public investorCountries: Country[] = [];
    public investorCountriesOptions: Suggestion<string>[] = [];
    public investeeCountriesOptions: Suggestion<string>[] = [];
    public investeeCountries: Country[] = [];
    public currencies: CurrencyData[] = [];
    public inputCountries: Country[] = [];
    public industries: Gic[] = [];

    public GetInvestorCountryOptions(): Suggestion<string>[] {
        const options: Suggestion<string>[] = [];
        this.investorCountriesOptions = [];
        this.investorCountries.forEach((country) => {
            options.push({
                id: country.CountryId,
                name: country.CountryName,
                value: country.CountryName,
                type: ComboboxDataType.String,
                disabled: false
            });
        });
        const index = options.findIndex((x) => x.name === 'United States');
        const newArray: any = options.splice(index, 1);
        options.unshift(newArray[0]);

        const germIndex = options.findIndex((x) => x.name === 'Germany');
        const germanyArray: any = options.splice(germIndex, 1);
        options.unshift(germanyArray[0]);

        return options;
    }

    public GetInvesteeCountryOptions(): Suggestion<string>[] {
        const options: Suggestion<string>[] = [];
        this.investeeCountriesOptions = [];
        this.investeeCountries.forEach((country) => {
            options.push({
                id: country.CountryId,
                name: country.CountryName,
                value: country.CountryName,
                type: ComboboxDataType.String,
                disabled: false
            });
        });
        // const index = options.findIndex((x) => x.name === 'United States');
        // const newArray: any = options.splice(index, 1);
        // options.unshift(newArray[0]);

        return options;
    }

    public InvestorCountriesTypeahead = (text: Observable<string>) => {
        const termMapper = (term: string) => {
            if (term === '') {
                return [];
            }

            const cleanTerm = term.toLowerCase().trim();

            return this.investorCountries.filter((country) => {
                return country.CountryName.toLowerCase().includes(cleanTerm);
            }).slice(0, 10);
        };

        return text.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            map(termMapper)
        );
    }

    public InvesteeCountriesTypeahead = (text: Observable<string>) => {
        const termMapper = (term: string) => {
            if (term === '') {
                return [];
            }

            const cleanTerm = term.toLowerCase().trim();

            return this.investeeCountries.filter((country) => {
                return country.CountryName.toLowerCase().includes(cleanTerm);
            }).slice(0, 10);
        };

        return text.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            map(termMapper)
        );
    }

    public CurrenciesTypeahead = (text: Observable<string>) => {
        const termMapper = (term: string) => {
            if (term === '') {
                return [];
            }

            const cleanTerm = term.toLowerCase().trim();

            const results = this.currencies.filter((currencyValue) => {
                const displayName = `${currencyValue.CurrencyAcronym} - ${currencyValue.CurrencyName}`.toLowerCase();
                const matchesDisplayName = displayName.includes(cleanTerm);
                const hasDash = cleanTerm.includes('-');
                const matchesCode = currencyValue.CurrencyAcronym.startsWith(cleanTerm);
                const matchesName = currencyValue.CurrencyName.toLowerCase().includes(cleanTerm);

                return matchesCode || matchesName || (hasDash && matchesDisplayName);
            }).slice(0, 10);

            return Util.sortBy(results, (x) => parseInt(x.CurrencyAcronym, 10));
        };

        return text.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            map(termMapper)
        );
    }

    public InputCountriesTypeahead = (text: Observable<string>) => {
        const termMapper = (term: string) => {
            if (term === '') {
                return [];
            }

            const cleanTerm = term.toLowerCase().trim();

            return this.inputCountries.filter((country) => {
                return country.CountryName.toLowerCase().includes(cleanTerm);
            }).slice(0, 10);
        };

        return text.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            map(termMapper)
        );
    }

    public IndustriesTypeahead = (text: Observable<string>) => {
        const termMapper = (term: string) => {
            if (term === '' || this.industries.length < 1) {
                return [];
            }

            const cleanTerm = term.toLowerCase().trim();

            const results = this.industries.filter((industryValue) => {
                const displayName = `${industryValue.GicCode} - ${industryValue.GicDescription}`.toLowerCase();
                const matchesDisplayName = displayName.includes(cleanTerm);
                const hasDash = cleanTerm.includes('-');
                const matchesCode = industryValue.GicCode.startsWith(cleanTerm);
                const matchesName = industryValue.GicDescription.toLowerCase().includes(cleanTerm);

                return matchesCode || matchesName || (hasDash && matchesDisplayName);
            });

            return Util.sortBy(results, (x) => parseInt(x.GicCode, 10)).slice(0, 10);
        };

        return text.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            map(termMapper)
        );
    }
}
