import { TabName } from '../enum/tab-name.enum';
import { TabRoute } from '../enum/tab-route.enum';
import { Tab } from '../enum/tab.enum';
import { Step } from '../model/step.model';

export class Steps {

    public static getBenchmarkingSteps(): Step[] {
        return [
            this.benchMarkingInputs,
            this.benchmarkingAnalysis
        ];
    }

    public static getIntlBenchmarkingSteps(): Step[] {
        return [
            this.intlbenchMarkingInputs,
            this.intlbenchmarkingAnalysis
        ];
    }

    public static getUsiSteps(): Step[] {
        return [
            this.inputs,
            this.analysis
        ];
    }

    public static getUsEstimateSteps(): Step[] {
        return [
            this.inputs,
            this.studies,
            this.results,
            this.analysis
        ];
    }

    public static getInternationalEstimateSteps(): Step[] {
        return [
            this.internationalInputs,
            this.costOfEquityInputs,
            this.waccInputs,
            this.countryRiskPremiaSummary,
            this.fisherEffect,
            this.costOfEquitySummary,
            this.costOfDebtSummary,
            this.waccSummary
        ];
    }

    public static setupTab(routerUrl: string, steps: Step[]): Tab | null {
        const step = steps.find((x) => routerUrl.includes(x.Url));

        if (step == null) {
            return null;
        }

        return step.Tab;
    }
    private static readonly inputs: Step = {
        Tab: Tab.Inputs,
        Name: TabName.Inputs,
        Url: TabRoute.Inputs
    };
    private static readonly studies: Step = {
        Tab: Tab.Studies,
        Name: TabName.Studies,
        Url: TabRoute.Studies
    };
    private static readonly results: Step = {
        Tab: Tab.Results,
        Name: TabName.Results,
        Url: TabRoute.Results
    };

    private static readonly benchMarkingInputs: Step = {
        Tab: Tab.BenchmarkingInputs,
        Name: TabName.BenchmarkingInputs,
        Url: TabRoute.BenchmarkingInputs
    };

    private static readonly intlbenchMarkingInputs: Step = {
        Tab: Tab.BenchmarkingInputs,
        Name: TabName.BenchmarkingInputs,
        Url: TabRoute.IntlBenchmarkingInputs
    };

    private static readonly benchmarkingAnalysis: Step = {
        Tab: Tab.BenchmarkingAnalysis,
        Name: TabName.BenchmarkingAnalysis,
        Url: TabRoute.BenchmarkingTearsheet
    };

    private static readonly intlbenchmarkingAnalysis: Step = {
        Tab: Tab.BenchmarkingAnalysis,
        Name: TabName.BenchmarkingAnalysis,
        Url: TabRoute.IntlBenchmarkingTearsheet
    };

    private static readonly analysis: Step = {
        Tab: Tab.USIndustry,
        Name: TabName.Analysis,
        Url: TabRoute.TearSheet
    };

    private static readonly internationalInputs: Step = {
        Tab: Tab.InternationalInputs,
        Name: TabName.InternationalInputs,
        Url: TabRoute.InternationalInputs
    };
    private static readonly costOfEquityInputs: Step = {
        Tab: Tab.CostOfEquityInputs,
        Name: TabName.CostOfEquityInputs,
        Url: TabRoute.CostOfEquityInputs
    };
    private static readonly countryRiskPremiaSummary: Step = {
        Tab: Tab.CountryRiskPremiaSummary,
        Name: TabName.CountryRiskPremiaSummary,
        Url: TabRoute.CountryRiskPremiaSummary
    };
    private static readonly fisherEffect: Step = {
        Tab: Tab.FisherEffect,
        Name: TabName.FisherEffect,
        Url: TabRoute.FisherEffect
    };
    private static readonly costOfEquitySummary: Step = {
        Tab: Tab.CostOfEquitySummary,
        Name: TabName.CostOfEquitySummary,
        Url: TabRoute.CostOfEquitySummary
    };
    private static readonly waccInputs: Step = {
        Tab: Tab.WaccInputs,
        Name: TabName.WaccInputs,
        Url: TabRoute.WaccInputs
    };
    private static readonly costOfDebtSummary: Step = {
        Tab: Tab.CostOfDebtSummary,
        Name: TabName.CostOfDebtSummary,
        Url: TabRoute.CostOfDebtSummary
    };
    private static readonly waccSummary: Step = {
        Tab: Tab.WaccSummary,
        Name: TabName.WaccSummary,
        Url: TabRoute.WaccSummary
    };
}
