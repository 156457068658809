import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// TODO: Put this in a Header folder and make it HeaderManager
@Injectable()
export class TitleService {
    private _title = new BehaviorSubject<string | undefined>(undefined);
    public title = this._title.asObservable();

    public set(title: string): void {
        this._title.next(title);
    }
}
