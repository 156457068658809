import { Component, OnInit } from '@angular/core';
import { CocInputsSharedService } from '../../service/cocInputShared.service';
import { RiskFreeRateERP, enumExcelExportSheets } from '../../cocViewInputs';
import { ExcelExportComponent } from '@progress/kendo-angular-excel-export';
import { CocinputsExcelExportService } from '../../service/cocinputs-excel-export.service';
import { environment } from 'src/environments/environment';
import { PrimeManager } from 'src/app/_navigator/modal/pmodal.manager';
import { TrendsOverTimeRfrAndErpComponent } from './trends-over-time-rfr-and-erp/trends-over-time-rfr-and-erp.component';
import { UserStore } from 'src/app/_navigator/user/store/user.store';
import { ERPSupplementary, ErpSupplymetryDetailData, LongTermErpData } from 'src/app/_api/responses/erp.supplymentary.data';
import { EnumSubsType } from '../../models/userSubscriptionTypes';


@Component({
    selector: 'risk-free-rates-and-equity-risk-premium',
    templateUrl: './risk-free-rates-and-equity-risk-premium.component.html',
    styleUrls: ['./risk-free-rates-and-equity-risk-premium.component.scss']
})
export class RiskFreeRatesAndEquityRiskPremiumComponent implements OnInit {


    // public rf!: DataValues[];
    // public erp!: DataValues[];
    public arryRiskFreeRateOverERPResources = RiskFreeRateERP;
    public exportFileName = 'Risk-Free Rates';
    public viewErpSupplymentaryDataAndErpHistFile: boolean = false;
    public viewHistErpDownloadLink: boolean = false;
    public availableErpFileStartDate = new Date('2013-12-31');
    public linkForErpFileDownload!: string;
    public isSupplemetryData!: boolean;
    public erpsupplymentryData!: ERPSupplementary[]
    public longTermerpsupplymentryData!: LongTermErpData[]
    public billBondMaturity!: ERPSupplementary[]
    public ErptabIndex!: number
    public subscriptionType!: boolean;
    public region!: string | null;
    public historicalErpInvestorPerspectives : {[key:string]: string} = {"United States": "us", "Australia": "australia","Austria": "austria", "Belgium":"belgium",
                                                    "Canada":"canada", "France":"france", "Germany":"germany", "Ireland":"ireland", "Italy":"italy",
                                                    "Japan":"japan", "Netherlands":"netherlands", "New Zealand": "newzealand", "South Africa": "southafrica",
                                                    "Spain": "spain", "Switzerland": "switzerland", "United Kingdom": "uk"};


    constructor(
        private sharedCocInputService: CocInputsSharedService,
        private exportService: CocinputsExcelExportService,
        private primeManager: PrimeManager,
        private userStore: UserStore) { }

    public ngOnInit(): void {

        var histErpInvstPerspectiveList = Object.keys(this.historicalErpInvestorPerspectives);

        this.region = this.sharedCocInputService.cocInputValues.investorPerspectiveregion
        if (new Date(this.sharedCocInputService.cocInputValues.valuationDate) >= this.availableErpFileStartDate) {
            this.viewErpSupplymentaryDataAndErpHistFile = true;
        }

        if( (this.sharedCocInputService.usrPerms.SubsType == EnumSubsType.Coc) ||

            (this.sharedCocInputService.usrPerms.SubsType == EnumSubsType.Ine && 
            this.sharedCocInputService.cocInputValues.investorPerspectiveregion &&
            histErpInvstPerspectiveList.filter(country => country !== "United States").
            includes(this.sharedCocInputService.cocInputValues.investorPerspectiveregion)) ||

            (this.sharedCocInputService.usrPerms.SubsType == EnumSubsType.IneAndCoc &&
             this.sharedCocInputService.cocInputValues.investorPerspectiveregion &&
             histErpInvstPerspectiveList.includes(this.sharedCocInputService.cocInputValues.investorPerspectiveregion))) {
            this.viewHistErpDownloadLink = true;
        } else {
            this.viewHistErpDownloadLink = false;
        }
        
        if (this.sharedCocInputService.usrPerms.SubsType != EnumSubsType.Ine && this.sharedCocInputService.usrPerms.SubsType != EnumSubsType.IneAndCoc) {
            this.subscriptionType = true;
        } else if (this.sharedCocInputService.usrPerms.SubsType == EnumSubsType.IneAndCoc && this.sharedCocInputService.cocInputValues.investorPerspectiveregion == "United States") {
            this.subscriptionType = true;
        } else {
            this.subscriptionType = false;
        }

        //only when selected Investor Perspective is valid, then generate historial erp file download link
        if(this.viewHistErpDownloadLink) {
            this.GetErpFileDownloadLinkFromValuationDate(this.sharedCocInputService.cocInputValues.valuationDate, this.sharedCocInputService.cocInputValues.investorPerspectiveregion);
        }
        // this.loadRiskFreeRateComponentData();
    }

    public showChart(): void {
        try {
            this.primeManager.openDialog(TrendsOverTimeRfrAndErpComponent);
        } catch (e) {
            console.log(e)
        }

    }

    public loadRiskFreeRateComponentData() {
        this.sharedCocInputService.getInitialTabsData();
    }


    public exportToExcel(component: ExcelExportComponent, type: number) {

        this.exportFileName = type === enumExcelExportSheets.ERP ? 'ERP-data' : 'RFR-data'
        this.exportService.generateWorkBook(component, type);
    }

    public GetErpFileDownloadLinkFromValuationDate(valuationDate: string, investorPerspective: string | null): void {
        let year = new Date(valuationDate).getUTCFullYear();

        //as JS uses 0 indexing for month, hence adding + 1 
        let month = new Date(valuationDate).getUTCMonth() + 1;
        let day = new Date(valuationDate).getUTCDate();
        let selectYearOfFile: number = 1990;//intitalizing with arbitrary year

        if (Number(month) == 12 && Number(day) == 31) {
            selectYearOfFile = year + 1;
        } else if (Number(month) == 12 && Number(day) == 30) {
            selectYearOfFile = year;
        } else {
            selectYearOfFile = year;
        }

        
        if(investorPerspective) {
            var histErpInvstPerspectiveRegion = this.historicalErpInvestorPerspectives[investorPerspective];
            this.linkForErpFileDownload = `${selectYearOfFile}-${histErpInvstPerspectiveRegion}-erp-kroll-local.xlsx`;
        }
        
    }

    public ViewSupplymentryData() {

        this.ErptabIndex = 0;
        this.erpsupplymentryData = this.sharedCocInputService.erpSupplymentryData.lstErpSupplymentry;
        this.longTermerpsupplymentryData = this.sharedCocInputService.erpSupplymentryData.lstLongTermData;
        this.billBondMaturity = this.sharedCocInputService.erpSupplymentryData.lstlongTemBillBonds;
        this.isSupplemetryData = true;
    }

}






