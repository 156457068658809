import {
    Component, ComponentFactoryResolver, ElementRef, Input, NgZone,
    OnDestroy, OnInit, ViewChild, ViewContainerRef
} from '@angular/core';
import { Spinner, SubscriberEntity } from '@concurrency/angular';
import { ContextualNumber, Dictionary } from '@concurrency/core';
import { AppInsights } from 'applicationinsights-js';

import { takeUntil } from 'rxjs/operators';
import { EquationType } from 'src/app/_api/responses/equation.response';
import { EstimateIndustries } from 'src/app/_api/responses/estimate-industries.response';
import { InputType } from 'src/app/_api/responses/input.response';
import { Equation, EquationOperand } from 'src/app/_navigator/data/model/equation.model';
import { Estimate } from 'src/app/_navigator/data/model/estimate.model';
import { Scenario } from 'src/app/_navigator/data/model/scenario.model';
import { DataStore } from 'src/app/_navigator/data/store/data.store';
import { PrimeManager } from 'src/app/_navigator/modal/pmodal.manager';
import { StudyType } from 'src/app/_navigator/summary/summary-study.model';
import { EditorComponent } from '../../../estimate/studies/_editors/editor.component';

@Component({
    selector: 'equation',
    templateUrl: './equation.component.html'
})
export class EquationComponent extends SubscriberEntity implements OnInit, OnDestroy {
    private editor!: EditorComponent;
    private editorComponent: any; // TODO: What should this be
    @ViewChild('container') public container!: ElementRef;
    @ViewChild('editcontainer', { read: ViewContainerRef }) public editcontainer!: ViewContainerRef;
    @Input() public scenario!: Scenario;
    @Input() public equation!: Equation;
    @Input() public dataTable!: any;

    public estimate!: Estimate; // TODO: Do not use bangs for regular variables

    public hasResult = false;
    public isEditorActive = false;
    public isAnyEditorActive = false;
    public isBuildUp1Relevered = false;
    public canClickOutsideToCancel = false;
    public StudyTypeEnum = StudyType;

    constructor(
        private zone: NgZone,
        private resolver: ComponentFactoryResolver,
        private dataStore: DataStore,
        private spinner: Spinner,
        protected primeManager: PrimeManager
    ) { super(); }

    private handleError(): void {
        const selectionType = this.editor.settings.operand.selectionType;
        const inputType = this.editor.settings.operand.inputType || InputType.None;
        const selection = this.scenario.getSelection(selectionType, inputType);
        const initial = new ContextualNumber(this.editor.settings.data.value);
        const properties: Dictionary<string | EstimateIndustries[] | undefined> = {
            ValuationDate: this.estimate.ValuationDate.split('T')[0],
            Industries: this.estimate.Industries,
            SelectionType: selectionType,
            InputType: inputType,
            CurrentValue: initial.asString,
            TargetValue: selection.Value == null ? undefined : selection.Value.toString()
        };

        AppInsights.trackEvent('UpdateError', properties as Dictionary<string>);
        this.cancel();
    }

    private cancel(): void {
        this.scenario.setSelection(
            this.editor.settings.data,
            this.editor.settings.operand.selectionType,
            this.editor.settings.operand.inputType || InputType.None
        );
        this.editor.cancel();
        this.cleanup();
    }

    private cleanup(): void {
        this.dataStore.validateScenario(this.estimate, this.scenario);

        this.isEditorActive = false;
        this.canClickOutsideToCancel = false;
        this.editorComponent.destroy();
    }

    private calculateEquationResult(): void {
        this.hasResult = this.equation.calculate(this.scenario);
    }

    public ngOnInit(): void {
        this.calculateEquationResult();
        this.isBuildUp1Relevered = EquationType.RprsBuildup1Relevered === this.equation.equationType;
        // TODO: Obviate takeUntil by using Async pipes and local Observable streams
        this.dataStore.estimate.pipe(takeUntil(this.destroyed)).whileDefined((estimate) => this.estimate = estimate);
        this.dataStore.recalculate.pipe(takeUntil(this.destroyed)).while(() => this.calculateEquationResult());
        this.dataStore.editorActivity.pipe(takeUntil(this.destroyed)).while((activity) => this.isAnyEditorActive = activity);
    }

    public ngOnDestroy(): void {
        this.dataStore.triggerEditorExit('dismiss');
        super.ngOnDestroy();
    }

    // TODO: This is copy+pasted into Base.study
    // Note: This component is destroyed as part of cloning a scenario, the subscribe below
    //  implicitly will fire no matter what. If we had used the SubscriberEntity's subscribe,
    //  this would not work. We should make an explicit method to handle these scenarios.
    public clone(): void {
        this.spinner.begin();
        const request = this.dataStore.cloneScenario(this.scenario);
        request.subscribe(() => this.spinner.end());
    }

    // TODO: This should not directly access the created component like this,
    //  instead the created component could work through the data store to setup the
    //  current state of the open editor.
    public openEditor(operand: EquationOperand): void {
        this.dataStore.setCurrentEditor(operand, this.scenario);

        const component = this.resolver.resolveComponentFactory(operand.editor as any);
        this.editorComponent = this.editcontainer.createComponent(component);
        this.editor = this.editorComponent.instance as EditorComponent;

        this.editor.modelChange.whileDefined((model) => {
            this.scenario.setSelection(model, operand.selectionType, operand.inputType || InputType.None);
            this.dataStore.validateScenario(this.estimate, this.scenario);
        });

        this.editor.submit.once(() => this.dataStore.triggerEditorExit('save'));

        this.zone.runOutsideAngular(() => {
            setTimeout(() => {
                this.canClickOutsideToCancel = true;
            }, 100);
        });

        this.isEditorActive = true;
    }

    public closeEditor(shouldSave: boolean): void {
        const editor = this.editor;
        if (editor == null) {
            throw Error(`Expected editor to be defined.`);
        }

        if (shouldSave) {
            const request = editor.update().once(() => {
                editor.save();
                return this.dataStore.updateEstimate(this.estimate);
            }, () => this.handleError(), () => this.cleanup());
            this.spinner.while(request);
        } else {
            this.cancel();
        }
    }

    public outsideCancel(): void {
        if (this.canClickOutsideToCancel) {
            this.dataStore.triggerEditorExit('dismiss');
        }
    }

    public showDataTable(): void {
        const inputs: Dictionary<any> = {};
        inputs.equationType = this.equation.equationType;
        inputs.inputType = this.equation.implicationType;
        this.primeManager.openDialog(this.dataTable, inputs);
    }
}
