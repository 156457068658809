import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SubscriberEntity } from '@concurrency/angular';
import { Select, Store } from '@ngxs/store';
import { combineLatest } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { map, takeUntil } from 'rxjs/operators';
import { CountryCreditRating } from '../_api/responses/country-credit-rating.response';
import { CountryRiskPremia } from '../_api/responses/country-risk-premia.response';
import { EstimateSummary } from '../_api/responses/estimate-summary.response';
import { CcrState } from '../_navigator/ccr-store/ccr-state';
import { CountryRiskPremiaListState } from '../_navigator/country-risk-premia-list-store/country-risk-premia-list-state';
import { Estimate } from '../_navigator/data/model/estimate.model';
import { TableData } from '../_navigator/data/model/table-data.model';
import { DataStore } from '../_navigator/data/store/data.store';
import { UpdateDynamicTextSummary } from '../_navigator/dynamic-text-store/dynamic-text-summary-action';
import { DynamicTextSummaryState } from '../_navigator/dynamic-text-store/dynamic-text-summary-state';
import { EstimateState } from '../_navigator/estimate-store/estimate-state';
import { EstimateSummaryState } from '../_navigator/estimate-summary/estimate-summary-state';
import { DynamicText } from './dynamic-text/model/dynamic-text';
import { DynamicTextSummary } from './dynamic-text/model/dynamic-text-summary';
import { DynamicTextBuilderUtil } from './dynamic-text/util/dynamic-text-builder.util';
import { RouteUtil } from './route/route.util';
import { CrpTableText } from './summary-table/crp-table-text';
import { CrpTableUtil } from './summary-table/crp-table.util';
import { RegionTableUtil } from './summary-table/region-table.util';

@Component({
    selector: 'crp-summary',
    templateUrl: './crp-summary.component.html'
})
export class CrpSummaryComponent extends SubscriberEntity implements OnInit, OnDestroy {

    private currentRoute!: string;
    @Select(CountryRiskPremiaListState.get) public countryRiskPremiaSelector!: Observable<CountryRiskPremia[] | undefined>;
    @Select(EstimateSummaryState.get) public estimateSummarySelector!: Observable<EstimateSummary | undefined>;
    @Select(DynamicTextSummaryState.get) public dynamicTextSummarySelector!: Observable<DynamicTextSummary | undefined>;
    @Select(EstimateState.get) public estimateSelector!: Observable<Estimate | undefined>;
    @Select(CcrState.get) public ccrSelector!: Observable<CountryCreditRating | undefined>;

    public crpTables: TableData[] = [];
    public dynamicText: DynamicText[] = [];
    public showCrp = true;
    public countryPremia!: TableData;
    public isReady = false;
    public saveBtnText = 'Continue';
    public alphas!: any[];
    public isCrpAvailable = false;
    public CrpTableText = CrpTableText;

    constructor(private router: Router, private store: Store, private dataStore: DataStore) {
        super();
    }

    public ngOnInit(): void {
        this.dataStore.triggerAllowExport(true);
        this.currentRoute = this.router.url;

        combineLatest([
            this.estimateSelector,
            this.estimateSummarySelector,
            this.dynamicTextSummarySelector,
            this.countryRiskPremiaSelector,
            this.ccrSelector
        ]).pipe(
            takeUntil(this.destroyed),
            map((x) => ({
                estimate: x[0],
                summary: x[1],
                dynamicText: x[2],
                crp: x[3],
                ccr: x[4]
            }))
        ).onceDefined((data) => {
            if (data.estimate == null
                || data.summary == null
                || data.dynamicText == null
                || data.crp == null
                || data.summary.CountryOfInputs == null
            ) {
                return;
            }

            this.isCrpAvailable = true;
            this.showCrp = data.summary.CountryOfInputs.CountryId !== data.estimate.InvesteeCountryId;

            if (this.showCrp === false) {
                return;
            }

            const dynamicTextSummary = DynamicTextBuilderUtil.getDynamicTextSummary(data.summary, data.dynamicText, data.crp);
            this.store.dispatch(new UpdateDynamicTextSummary(dynamicTextSummary)).once(() => {
                if (data.estimate == null
                    || data.summary == null
                    || data.summary.InvesteeCountryName == null
                    || data.dynamicText == null
                    || data.crp == null
                    || data.ccr == null
                    || data.summary.CountryOfInputs == null
                ) {
                    return;
                }

                this.countryPremia = CrpTableUtil.getCountryPremiaTable(
                    data.ccr,
                    data.summary.InvesteeCountryName,
                    data.summary.CountryOfInputs.CountryName
                );
                this.alphas = [data.summary.InvesteeCountryId, data.summary.InvestorCountryId, data.summary.ValuationDate];
                this.crpTables = RegionTableUtil.getRegionPremiaTable(data.crp, data.estimate.Scenarios[0], false, false);
                this.isReady = true;
                this.dynamicTextSummarySelector.onceDefined((dt) => {
                    if (dt.CrpSummaryText == null) {
                        return;
                    }
                    this.dynamicText = dt.CrpSummaryText;
                });
            });
        });
    }

    public ngOnDestroy(): void {
        this.dataStore.triggerAllowExport(false);
        super.ngOnDestroy();
    }

    public continue(): void {
        RouteUtil.saveAndContinue(this.router.url, this.router);
    }

    public previous(): void {
        RouteUtil.previous(this.currentRoute, this.router);
    }
}
