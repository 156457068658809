import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Spinner, SubscriberEntity } from '@concurrency/angular';
import { ContextualNumber, Util } from '@concurrency/core';
import { Store } from '@ngxs/store';
import { Select } from '@ngxs/store';
import { combineLatest, Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { InputType } from '../../_api/responses/input.response';
import { IntlData } from '../../_api/responses/intl-data.response';
import { CommonInputConfig } from '../../_navigator/common/inputs/common-user-input.component';
import { Estimate } from '../../_navigator/data/model/estimate.model';
import { Suggestion } from '../../_navigator/data/model/suggestion.model';
import { DataStore } from '../../_navigator/data/store/data.store';
import { NumberFormatUtil } from '../../_navigator/data/util/number-format.util';
import { HelpText } from '../../_navigator/help/help-text';
import { Help } from '../../_navigator/help/help.model';
import { SummaryGroup } from '../../_navigator/summary/summary-group.model';
import { GetTaxRateList } from '../../_navigator/tax-rate-list-store/tax-rate-list-actions';
import { TaxRateListState } from '../../_navigator/tax-rate-list-store/tax-rate-list-state';
import { InputText } from '../../international-estimate/data/model/input-text';
import { IntlEstimateUtil } from '../../international-estimate/data/util/intl-estimate.util';

interface WaccElement {
    display: string;
    name?: string;
    value?: string;
    slim?: boolean;
    help?: Help;
}

@Component({
    selector: 'wacc',
    templateUrl: './wacc.component.html'
})
export class WaccComponent extends SubscriberEntity implements OnInit, OnDestroy {
    @Select(TaxRateListState.get) public taxRateListSelector!: Observable<IntlData[] | undefined>;
    public estimate!: Estimate;
    public equation: WaccElement[] = [];
    public inputConfigurations: CommonInputConfig[] = [];
    public inputPanes: SummaryGroup[][] = [];
    public weighted = NaN;
    public costOfEquity = new ContextualNumber();
    public weightOfEquity = new ContextualNumber();
    public costOfDebt = new ContextualNumber();
    public weightOfDebt = new ContextualNumber();
    public taxRate: ContextualNumber = new ContextualNumber();
    public equitySelections: Suggestion<string | null>[] = [];
    public processingUserInput = false;
    public costOfEquityOptions: ContextualNumber[] = [];
    public taxRateSuggestions: Suggestion<number>[] = [];
    public showKey = true;

    public taxRateConfig: CommonInputConfig = {
        name: InputText.TaxRate,
        help: HelpText.TaxRate,
        required: true,
        mask: `{}%`,
        minimumValue: 0,
        maximumValue: 100,
        pattern: '(.*?)'
    };

    constructor(
        private store: Store,
        private router: Router,
        private spinner: Spinner,
        private dataStore: DataStore
    ) { super(); }

    public ngOnInit(): void {
        // TODO: Obviate takeUntil by using Async pipes and local Observable streams
        this.dataStore.triggerAllowExport(true);
        const request = combineLatest([this.dataStore.estimate, this.dataStore.summary]).pipe(takeUntil(this.destroyed));
        request.subscribe((data) => {
            const estimate = data[0];
            const summary = data[1];

            if (estimate == null || summary == null) {
                return;
            }

            this.estimate = estimate;

            const request1 = combineLatest([
                this.store.dispatch(new GetTaxRateList(77349, this.estimate.ValuationDate))
            ]);
            request1.once(() => {
                combineLatest([
                    this.taxRateListSelector
                ]).pipe(
                    takeUntil(this.destroyed),
                    map((x) => ({
                        taxRate: x[0]
                    }))
                ).onceDefined((data1) => {
                    if (data1.taxRate != null) {
                        this.taxRateSuggestions = data1.taxRate.map((x) => IntlEstimateUtil.intlDataAsSuggestion(x.Value, x.Label, x.DataAsOf, x.Source));
                    }
                });
            });
            this.costOfEquityOptions = [
                new ContextualNumber(summary.levered.average, 'Average Levered'),
                new ContextualNumber(summary.levered.median, 'Median Levered'),
                new ContextualNumber(summary.unlevered.average, 'Average Unlevered'),
                new ContextualNumber(summary.unlevered.median, 'Median Unlevered'),
                new ContextualNumber(summary.relevered.average, 'Average Relevered'),
                new ContextualNumber(summary.relevered.median, 'Median Relevered')
            ];

            for (const option of this.costOfEquityOptions) {
                if (option.hasValue) {
                    const equityOption: Suggestion<string> = {
                        name: `${option.asString}% - ${option.context}`,
                        source: option.context || undefined,
                        value: option.asStringOr('')
                    };
                    this.equitySelections.push(equityOption);
                }
            }

            const waccType = estimate.getInput(InputType.WaccEquityType);

            if (waccType.Value == null) {
                this.costOfEquity = estimate.getContextualInput(InputType.WaccCostOfEquity);
                this.costOfEquity.context = 'Custom';
            } else {
                const selectedCostOfEquityForWacc = this.costOfEquityOptions[waccType.Value - 1];
                if (selectedCostOfEquityForWacc.hasValue) {
                    this.costOfEquity = selectedCostOfEquityForWacc.clone();
                }
            }

            this.costOfDebt = estimate.getContextualInput(InputType.WaccCostOfDebt);
            this.taxRate = estimate.getContextualInput(InputType.WaccTaxRate);

            const debtRatioEquity = estimate.getContextualInput(InputType.DebtRatioEquity).asNumberOrNaN / 100;
            const weightOfDebt = Util.round((debtRatioEquity / (1 + debtRatioEquity)) * 100);
            this.weightOfDebt = new ContextualNumber(weightOfDebt || null);
            this.weightOfEquity = new ContextualNumber((100 - weightOfDebt) || null);

            this.inputConfigurations.push({
                name: 'Cost of Equity',
                minimumValue: 0,
                maximumValue: 100,
                debounce: { callback: () => this.update(), delay: 0 }
            });

            for (const textInput of ['Weight of Equity', 'Cost of Debt', 'Weight of Debt', 'Tax Rate']) {
                this.inputConfigurations.push({
                    name: textInput,
                    minimumValue: 0,
                    maximumValue: 100,
                    debounce: { callback: () => this.update(), delay: 0 },
                    mask: `{}%`,
                    autofocus: false
                });
            }

            this.inputConfigurations[3].disabled = true;

            this.equation = [
                { display: 'WACC', name: 'Weighted Average Cost of Capital' },
                { display: '=', slim: true },
                { display: '(', slim: true },
                { display: 'K<sub>e</sub>', name: 'Cost of Equity', help: HelpText.WaccCostOfEquity },
                { display: '*', slim: true },
                { display: 'W<sub>e</sub>', name: 'Weight of Equity', help: HelpText.WeightOfEquity },
                { display: ')', slim: true },
                { display: '+', slim: true },
                { display: '(', slim: true },
                { display: 'K<sub>d</sub>', name: 'Cost of Debt', help: HelpText.CostOfDebt },
                { display: '*', slim: true },
                { display: 'W<sub>d</sub>', name: 'Weight of Debt', help: HelpText.WeightOfDebt },
                { display: ')', slim: true },
                { display: '*', slim: true },
                { display: '(', slim: true },
                { display: '1', slim: true },
                { display: '-', slim: true },
                { display: 'T', name: 'Tax Rate', help: HelpText.TaxRate },
                { display: ')', slim: true }
            ];

            this.update();
        });
    }
    // Todo : initialize function not used in anywhere.
    // private initialize(
    //     taxRate: IntlData[] | undefined
    // ): void {
    //     if (taxRate == null) {
    //         return;
    //     }
    //     this.taxRateSuggestions = taxRate.map((x) => IntlEstimateUtil.intlDataAsSuggestion(x.Value, x.Label, x.DataAsOf, x.Source));
    // }
    public updateWeightOfDebt(): void {
        if (this.weightOfEquity.asNumber != null) {
            this.weightOfDebt.value = NumberFormatUtil.numberWithCommas(100 - this.weightOfEquity.asNumber, 2);
        }
        this.update();
    }

    public update(): void {
        this.equation[3].name = `${this.costOfEquity.context} Cost of Equity`;
        this.equation[3].value = this.costOfEquity.asString;
        this.equation[5].value = this.weightOfEquity.asString;
        this.equation[9].value = this.costOfDebt.asString;
        this.equation[11].value = this.weightOfDebt.asString;
        this.equation[17].value = this.taxRate.asString;

        if (this.costOfEquity.asNumber != null &&
            this.weightOfEquity.asNumber != null &&
            this.costOfDebt.asNumber != null &&
            this.weightOfDebt.asNumber != null &&
            this.taxRate.asNumber != null
        ) {
            this.weighted = (this.costOfEquity.asNumber / 100 * this.weightOfEquity.asNumber / 100) +
                (this.costOfDebt.asNumber / 100 * this.weightOfDebt.asNumber / 100) *
                (1 - this.taxRate.asNumber / 100);
            this.equation[0].value = NumberFormatUtil.numberWithCommas(this.weighted * 100, 2);
        } else {
            this.equation[0].value = undefined;
        }

        this.inputConfigurations[0].mask = `{}% - ${this.costOfEquity.context}`; // `

        this.inputConfigurations[2].maximumValue = this.costOfEquity.asNumber;
    }

    public saveAndReturn(estimate: Estimate): void {
        if (estimate == null) {
            this.router.navigate(['estimate/results']);
            return;
        }

        this.spinner.begin();
        const selectedCostOfEquityIndex = this.costOfEquityOptions.findIndex((x) => x.context === this.costOfEquity.context);
        if (selectedCostOfEquityIndex === -1) {
            estimate.setInput(InputType.WaccEquityType, null);
            estimate.setInput(InputType.WaccCostOfEquity, this.costOfEquity.asNumberOrNull);
        } else {
            estimate.setInput(InputType.WaccEquityType, selectedCostOfEquityIndex + 1);
            estimate.setInput(InputType.WaccCostOfEquity, null);
        }
        const weightOfEquity = this.weightOfEquity.asNumberOrNaN / 100;
        const debtRatioEquity = Util.round(((1 - weightOfEquity) / weightOfEquity) * 100);
        estimate.setInput(InputType.DebtRatioEquity, debtRatioEquity || null);
        estimate.setInput(InputType.WaccCostOfDebt, this.costOfDebt.asNumberOrNull);
        estimate.setInput(InputType.WaccTaxRate, this.taxRate.asNumberOrNull);
        const request = this.dataStore.updateEstimate(estimate);
        request.subscribe(() => {
            this.spinner.end();
            this.router.navigate(['estimate/results']);
        });
    }
    public ngOnDestroy(): void {
        this.dataStore.triggerAllowExport(false);
        super.ngOnDestroy();
    }
}
