import { EstimateIndustries } from 'src/app/_api/responses/estimate-industries.response';

export class Analysis {
    public ValuationDate: string;
    public Industries: EstimateIndustries[];
    public IsIndustryApplicable: boolean;

    constructor(valuationDate: string, industries: EstimateIndustries[], isIndustryApplicable: boolean) {
        this.ValuationDate = valuationDate;
        this.Industries = industries;
        this.IsIndustryApplicable = isIndustryApplicable;
    }
}
