import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IntlErpClient } from 'src/app/_api/clients/intlErp.client';
import { RiskPremiumCharacteristicsData } from '../models/rprCharacteristicsData';
import { RiskClient } from 'src/app/_api/clients/risk.client';
import { spRPRCharacter } from '../commonHeaders';

@Injectable({
    providedIn: 'root'
})

export class RPRsharedService {

    public riskPremiumCharacteristicsData!: any;
    public tblHeaders = spRPRCharacter;
    public portfolioTypes = ["Largest Company", "95th Percentile", "75th Percentile", "Median", "25th Percentile", "5th Percentile", "Smallest Company"]
    public portfolios = ['Portfolio  23', 'Portfolio  24', 'Portfolio  25']


    constructor(private riskClient: RiskClient) {

    }


    getRFRCharacteristicsData(dataAsOf: string): Observable<RiskPremiumCharacteristicsData> {
        return this.riskClient.getRFRCharacteristicsData(dataAsOf);
    }

}
