import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

// NOTE: The purpose of this component is to redirect users who have bookmarked the /landing page
//  In practice, it is not clear that any users will have done this; ideally we would track the event
//  but application insights is incapable of tracking events prior to page load as the javascript api
//  has no exposed, working method for sending data in a timely manner.
//  This is not actually a good implementation of this redirect as the entire app will load before
//  the redirect occurs. Ideally the redirect would occur before app load.
@Component({ template: '' })
export class RedirectComponent implements OnInit {
    constructor(
    ) { }

    public ngOnInit(): void {
        window.location.href = environment.urls.marketingPortal;
    }
}
