import { Injectable } from '@angular/core';
import { PDFExportComponent } from '@progress/kendo-angular-pdf-export';

@Injectable()
export class ExportService {
    private pdf?: PDFExportComponent;
    public preparePdfExport = false;
    public prepareExcelExport = false;

    public setPdf(pdf: PDFExportComponent): void {
        this.pdf = pdf;
    }

    public savePdf(): void {
        // Kendo ostensibly solves the prepare export problem itself, but it doesn't work
        this.preparePdfExport = true;

        const valueElements = document.querySelectorAll('.parameter-value');
        const multiplySymbolElements = Array.from(valueElements).filter((x) => x.textContent === '×');

        for (const element of multiplySymbolElements) {
            const htmlElement = element as HTMLElement;
            const imageWidth = (htmlElement instanceof HTMLElement) ? (htmlElement.offsetWidth / 2.25) : 10;
            htmlElement.innerHTML = `<img src="assets/images/multiply-symbol.png" style="width:${imageWidth}px;">`;
        }

        setTimeout(() => {
            if (this.pdf == null) {
                throw Error(`Expected setPdf to be called before savePdf.`);
            }

            this.pdf.saveAs('summary.pdf');
            setTimeout(() => {
                this.preparePdfExport = false;
                for (const element of multiplySymbolElements) {
                    element.innerHTML = `×`;
                }
            }, 50);
        }, 10);
    }
}
