import { Injectable } from '@angular/core';
import { BenchmarkScenario } from 'src/app/benchmarking/data/benchmark-scenario';
import { DateStruct } from '../../common/date-struct';

@Injectable()
export class BenchmarkingService {



    public set setBenchmarkingScenarios(scenarios: BenchmarkScenario[]) {
        this._benchmarkingScenarios = scenarios;
    }

    public get benchmarkingScenarios(): BenchmarkScenario[] {
        return this._benchmarkingScenarios;
    }

    private _benchmarkingScenarios: BenchmarkScenario[] = new Array<BenchmarkScenario>();
    public benchMarkingValuationDate: DateStruct | undefined = undefined;




    constructor() { }
}
