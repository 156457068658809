import { Component, HostListener, OnInit } from '@angular/core';
import { CommonInputConfig } from 'src/app/_navigator/common/inputs/common-user-input.component';
import { NumberFormatUtil } from 'src/app/_navigator/data/util/number-format.util';
import { HelpText } from 'src/app/_navigator/help/help-text';
import { BetaService } from '../beta.service';

@Component({
    selector: 'unlevering-inputs',
    templateUrl: './unlevering-inputs.component.html',
    styleUrls: ['./unlevering-inputs.component.scss']
})
export class UnleveringInputsComponent implements OnInit {
    public collapse = false;
    public NumberFormatUtil = NumberFormatUtil;
    public taxRateconfig: CommonInputConfig[] = [];
    public taxRateOptions: any = [];
    public taxRate: any = {};
    public currency = '';

    public helpText = HelpText;
    constructor(public betaService: BetaService) { }

    public ngOnInit(): void {
        this.betaService.insertTaxRateOptions();
        // this.currency = this.betaService.metricInputs.Currency;
    }

    public toggleCollapse(): void {
        this.collapse = !this.collapse;
    }

    public bounds(boundValue: number, numerator: number = 1, denominator: number = 16): number {
        const frac: number = denominator / numerator;
        return Math.ceil(frac * boundValue) / frac;
    }

    @HostListener('window:scroll', [])
    public onWindowScroll(): void {
        const duplicate = document.getElementById('duplicateunlevering');
        const unleveringInputParent = document.getElementById('unleveringInputParent');
        // to stick collapsed section while scrolling to right
        const collapsedItem = document.getElementById('unleveringInputCollapsed');
        const scrollLeftValue = 5;

        let top = 0;
        const upperPageHeight = 266;
        if (unleveringInputParent != null) {
            top = unleveringInputParent.clientHeight + unleveringInputParent.getBoundingClientRect().top - upperPageHeight;
            if (!this.collapse) {
                // to stick collapsed section while scrolling to right
                if (document.body.scrollLeft > scrollLeftValue || document.documentElement.scrollLeft > scrollLeftValue) {
                    if (collapsedItem != null) {
                        collapsedItem.classList.add('fixed-column');
                        collapsedItem.style.display = 'inline';
                        collapsedItem.style.top = top + 235 + 'px';
                    }
                } else {
                    if (collapsedItem != null) {
                        collapsedItem.style.display = 'none';
                    }
                }
                // end code
                return;
            }
        }
        const topValue = top + 1 + 'px';
        if (document.body.scrollLeft > scrollLeftValue || document.documentElement.scrollLeft > scrollLeftValue) {
            if (duplicate != null) {
                duplicate.classList.add('fixed-column');
                duplicate.style.display = 'inline';
                duplicate.style.top = topValue;
            }
        } else {
            if (duplicate != null) {
                duplicate.style.display = 'none';
            }
            if (collapsedItem != null) {
                collapsedItem.style.display = 'none';
            }
        }
    }
}
