import { Util } from '@concurrency/core';
import * as moment from 'moment';
import { betaCompanyCellHeight, betaCompanySalesCellHeight, betaNormalFontSize, betaSmallFontSize } from '../../estimate/exports/summary-export/util/summary-helpers';
import { BetaComparbleCompanyResults } from '../beta.types';
import { getCompanies } from './companies';

export function comparableCompanies(companies: BetaComparbleCompanyResults[], date: any, currency: any): any[] {
    const inputs: any[] = [];
    if (companies) {
        inputs.push(
            {
                height: betaCompanyCellHeight,
                cells: [{ value: '' }]
            },
            {
                height: 90, // 56
                cells: getCompanies(companies)
            },
            {
                height: betaCompanyCellHeight,
                font: betaNormalFontSize,
                cells: getCells(companies, "# of Observations")
            },
            {
                height: 150, // 135
                font: betaNormalFontSize,
                cells: getCells(companies, "Industry")
            },
            {
                height: 200, // 169
                font: betaNormalFontSize,
                cells: getCells(companies, "Keywords")
            },
            {
                height: betaCompanySalesCellHeight,
                font: betaNormalFontSize,
                cells: getCells(companies, "Sales", date, currency)
            },
            {
                height: betaCompanyCellHeight,
                font: betaSmallFontSize,
                cells: getCells(companies, "data as of", date)
            },
            {
                height: betaCompanySalesCellHeight,
                font: betaNormalFontSize,
                cells: getCells(companies, "EBITDA", date, currency)
            },
            {
                height: betaCompanyCellHeight,
                font: betaSmallFontSize,
                cells: getCells(companies, "data as of", date)
            },
            {
                height: betaCompanySalesCellHeight,
                font: betaNormalFontSize,
                cells: getCells(companies, "Total Assets", date, currency)
            },
            {
                height: betaCompanyCellHeight,
                font: betaSmallFontSize,
                cells: getCells(companies, "data as of", date)
            },
            {
                height: betaCompanySalesCellHeight,
                font: betaNormalFontSize,
                cells: getCells(companies, "Market Cap", date, currency)
            },
            {
                height: betaCompanyCellHeight,
                font: betaSmallFontSize,
                cells: getCells(companies, "mdata as of", date)
            },
            {
                height: betaCompanySalesCellHeight,
                font: betaNormalFontSize,
                cells: getCells(companies, "Headquarter Country")
            },
            {
                height: betaCompanySalesCellHeight,
                font: betaNormalFontSize,
                cells: getCells(companies, "Incorporation Country")
            },
            {
                height: betaCompanyCellHeight,
                font: betaNormalFontSize,
                cells: getCells(companies, "Exchange")
            }
        );
    }

    return inputs;
}

function getCells(companies: BetaComparbleCompanyResults[], name: string, date?: any, currency?: any): any[] {
    const cells: any[] = [];
    cells.push(
        { value: '' },
        { value: '' },
        {
            value: concateCurrency(name, currency),
            bold: true,
            italic: date ? true : false
            // italic: name === 'data as of' ? true : false
        }
    );

    companies.forEach((company: BetaComparbleCompanyResults) => {
        if (name === "# of Observations") {
            cells.push({
                value: company.Observations
            });
        } else if (name === "Industry") {
            cells.push({
                value: company.IndustryCodeDescription,
                wrap: true
            });
        } else if (name === "Keywords") {
            cells.push({
                value: company.KeyWords,
                wrap: true
            });
        } else if (name === "Sales") {
            cells.push({
                value: company.Sales != null ? Util.round(company.Sales, 1) : "",
                format: '#,##0.0',
                textAlign: 'left'
            });
        } else if (name === "EBITDA") {
            cells.push({
                value: company.EBITDA != null ? Util.round(company.EBITDA, 1) : "",
                format: '#,##0.0',
                textAlign: 'left'
            });
        } else if (name === "Total Assets") {
            cells.push({
                value: company.Assets != null ? Util.round(company.Assets, 1) : "",
                format: '#,##0.0',
                textAlign: 'left'
            });
        } else if (name === "Market Cap") {
            cells.push({
                value: company.MarketCap != null ? Util.round(company.MarketCap, 1) : "",
                format: '#,##0.0',
                textAlign: 'left'
            });
        } else if (name === "data as of") {
            cells.push({
                value: moment(company.Dataasof).format('MM/DD/YYYY')
            });
        } else if (name === "mdata as of") {
            cells.push({
                value: moment(company.mAsOf).format('MM/DD/YYYY')
            });
        } else if (name === 'Headquarter Country') {
            cells.push({
                value: company.HeadquarterCountry
            });

        } else if (name === 'Incorporation Country') {
            cells.push({
                value: company.IncorporationCountry
            });

        } else if (name === 'Exchange') {
            cells.push({
                value: company.Exchange ? company.Exchange : "",
                wrap: true
            });
        }
    });

    return cells;
}

function concateCurrency(name: string, currency: string): string {
    let columnName = '';
    if (name === 'Sales') {
        columnName = name + ' (' + currency + ' mm)';
    } else if (name === "EBITDA") {
        columnName = name + ' (' + currency + ' mm)';
    } else if (name === "Total Assets") {
        columnName = name + ' (' + currency + ' mm)';
    } else if (name === "Market Cap") {
        columnName = name + ' (' + currency + ' mm)';
    } else if (name === 'mdata as of') {
        columnName = 'data as of';

    } else {
        columnName = name;
    }
    return columnName;
}
