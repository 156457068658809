import * as moment from 'moment';

import { Component, OnInit, ViewChild } from '@angular/core';
import { SubscriberEntity } from '@concurrency/angular';
import { Util } from '@concurrency/core';
import { Dialog } from 'primeng/dialog';
import { Decile } from 'src/app/_api/responses/decile.response';
import { SuggestionResponse } from 'src/app/_api/responses/suggestion.response';
import { DataStore } from 'src/app/_navigator/data/store/data.store';
import { NumberFormatUtil } from 'src/app/_navigator/data/util/number-format.util';
import { CRSPSuggestion } from './crsp-suggestion-model';

@Component({
    selector: 'crsp-decile',
    templateUrl: './crsp-decile.component.html',
    styleUrls: ['./crsp-decile.component.scss']
})
export class CrspDecileComponent extends SubscriberEntity implements OnInit {
    public Util = Util;
    public decileGrouping!: Decile[];
    public decileData!: Decile[];
    public decileSplit!: Decile[];
    public display = true;
    public riskStudyDate = '';
    public crspSuggestions: CRSPSuggestion[] = [] as CRSPSuggestion[];
    public equityRiskPremiumSuggitions!: SuggestionResponse[];
    public NumberFormatUtil = NumberFormatUtil;
    public valuationDate = '';
    public riskFreeStudyDate = '';
    public riskFreeStudyYearEndDate = '';

    @ViewChild(Dialog) public dialog!: Dialog;

    constructor(
        protected dataStore: DataStore
    ) {
        super();
    }

    public ngOnInit(): void {
        this.dataStore.estimate.onceDefined((estimate) => {
            this.dataStore.getDeciles(estimate.ValuationDate)
                .once((result) => {
                    this.decileGrouping = result.filter((z) => z.Type === 'Grouping');
                    this.decileData = result.filter((z) => z.Type === 'Decile');

                    // TODO: What is going on here? What is 10B and why is it special?
                    const filteredSplit = result.filter((z) => z.Type === 'Split');
                    const tenB = filteredSplit.splice(1, 1)[0];
                    filteredSplit.splice(3, 0, tenB);
                    this.decileSplit = filteredSplit;
                    this.riskStudyDate = result ? `${moment(result[0].DataAsOf).format('LL')}` : '';

                    setTimeout(() => this.dialog);
                });
            this.valuationDate = estimate ? `${moment(estimate.ValuationDate).format('LL')}` : '';
            const riskFreeRateSuggestions = estimate.Suggestions.filter((x) => x.SelectionType === 'RiskFreeRate');
            this.riskFreeStudyDate = riskFreeRateSuggestions.length > 0 ? `${moment(riskFreeRateSuggestions[0].DataAsOf).format('LL')}` : '';
            this.riskFreeStudyYearEndDate = riskFreeRateSuggestions.length > 0 ? `${moment(new Date('12/31/' + (new Date(riskFreeRateSuggestions[0].DataAsOf)).getFullYear())).format('LL')}` : '';
            this.crspSuggestions.push({ typeName: 'Risk Free Rate', suggestions: estimate.Suggestions.filter((x) => x.SelectionType === 'RiskFreeRate') });
            this.crspSuggestions.push({ typeName: 'Equity Risk Premium', suggestions: estimate.Suggestions.filter((x) => x.SelectionType === 'EquityRiskPremium') });
        });
    }
}
