import * as moment from 'moment';

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Util } from '@concurrency/core';
import { BetaService } from '../beta.service';
import { ComaparableCompanyFilter } from '../beta.types';

@Component({
    selector: 'add-or-remove',
    templateUrl: './add-or-remove.component.html',
    styleUrls: ['./add-or-remove.component.scss']
})
export class AddOrRemoveComponent implements OnInit {

    public moment = moment;
    public Util = Util;
    public display = true;
    public disable = true;
    public dialogHeader = 'Add/Remove Columns';
    @Input() public selectedFilters!: ComaparableCompanyFilter[];
    @Input() public comparableFilters!: ComaparableCompanyFilter[];
    @Output() public displayChange = new EventEmitter();

    constructor(
        private betaService: BetaService
    ) { }

    public ngOnInit(): void {
        if (this.comparableFilters && this.selectedFilters) {
            this.comparableFilters.forEach((filter: any) => {
                const containsFilter = this.selectedFilters.find((x) => x.keyOfFilter === filter.keyOfFilter);
                if (containsFilter) {
                    filter.isChecked = true;
                    filter.inputValue = containsFilter.inputValue
                } else {
                    filter.isChecked = false;
                }
            });
        }
    }
    public save(): void {
        this.betaService.selectedFilters = [];
        this.comparableFilters.forEach((filter: any) => {
            if (filter.isChecked) {
                this.betaService.selectedFilters.push(filter);
            }
            if (filter.keyOfFilter === 'Sales') {
                this.betaService.containsSales = true;
            }
        });
        this.display = false;
        this.displayChange.emit(this.display);
    }
    public onCheckboxChange(event: any, filter: any): void {
        if (event.target.checked) {
            filter.isChecked = true;
        } else {
            filter.isChecked = false;
        }
        this.disableValue(filter);
    }
    public disableValue(filter: any): void {
        const count = this.comparableFilters.filter((x) => x.isChecked === true).length;
        if (this.selectedFilters.length !== count || !this.selectedFilters.includes(filter.keyOfFilter)) {
            this.disable = false;
        } else {
            this.disable = true;
        }
    }
    public close(): void {
        this.display = false;
        this.displayChange.emit(this.display);
    }

}
