import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class DashboardDisplayService {
    private _cocUSDisplay = new BehaviorSubject<boolean>(false);
    private _cocIntlDisplay = new BehaviorSubject<boolean>(false);
    private _indUSDisplay = new BehaviorSubject<boolean>(false);
    private _indIntlDisplay = new BehaviorSubject<boolean>(false);
    public cocUSModule = this._cocUSDisplay.asObservable();
    public cocIntlModule = this._cocIntlDisplay.asObservable();
    public benchmarkUSModule = this._indUSDisplay.asObservable();
    public benchmarkIntlModule = this._indIntlDisplay.asObservable();
    constructor() { }

    public DisplayCocUSModule(value: boolean): void {
        this._cocUSDisplay.next(value);
        this._cocIntlDisplay.next(!value);
        this._indUSDisplay.next(!value);
        this._indIntlDisplay.next(!value);
    }

    public DisplayCocIntlModule(value: boolean): void {
        this._cocUSDisplay.next(!value);
        this._cocIntlDisplay.next(value);
        this._indUSDisplay.next(!value);
        this._indIntlDisplay.next(!value);
    }

    public DisplayBenchmarkUSModule(value: boolean): void {
        this._cocUSDisplay.next(!value);
        this._cocIntlDisplay.next(!value);
        this._indUSDisplay.next(value);
        this._indIntlDisplay.next(!value);
    }

    public DisplayBenchmarkIntlModule(value: boolean): void {
        this._cocUSDisplay.next(!value);
        this._cocIntlDisplay.next(!value);
        this._indUSDisplay.next(!value);
        this._indIntlDisplay.next(value);
    }

    public ResetDisplay(): void {
        this._cocUSDisplay.next(false);
        this._cocIntlDisplay.next(false);
        this._indUSDisplay.next(false);
        this._indIntlDisplay.next(false);
    }

}
