import { Component, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, NavigationExtras, Router } from '@angular/router';
import { ContextualString } from '@concurrency/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Select } from '@ngxs/store';
import * as moment from 'moment';
import { Observable, Subject, combineLatest } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { CountryClient } from 'src/app/_api/clients/country.client';
import { CurrencyClient } from 'src/app/_api/clients/currency.client';
import { RegionClient } from 'src/app/_api/clients/region.client';
import { CurrencyData } from 'src/app/_api/responses/currency-data.response';
import { MinimumDate } from 'src/app/_api/responses/minimum-date.response';
import { CommonDate } from 'src/app/_navigator/common/date-struct';
import { CommonInputConfig } from 'src/app/_navigator/common/inputs/common-user-input.component';
import { ComboboxDataType } from 'src/app/_navigator/data/enum/combobox-data-type.enum';
import { Suggestion } from 'src/app/_navigator/data/model/suggestion.model';
import { DataStore } from 'src/app/_navigator/data/store/data.store';
import { HelpText } from 'src/app/_navigator/help/help-text';
import { ProductType } from 'src/app/_navigator/user/enum/product-type';
import { SubscriptionData } from 'src/app/_navigator/user/model/subscription.model';
import { UserStore } from 'src/app/_navigator/user/store/user.store';
import { UserUtil } from 'src/app/_navigator/user/util/user.util';
import { ValuesState } from 'src/app/_navigator/values-store/values-state';
import { CocInputValues, CocInputs } from '../../models/cocInputs';
import { Spinner, ngbDateStructToString } from '@concurrency/angular';
import { CocInputsSharedService } from '../../service/cocInputShared.service';
import { Country } from 'src/app/_api/responses/country.response';
import { SizePremiumComponent } from '../size-premium/size-premium.component';
import { ExcelExportComponent } from '@progress/kendo-angular-excel-export';
import { CocinputsExcelExportService } from '../../service/cocinputs-excel-export.service';
import { TabName } from 'src/app/_navigator/data/enum/tab-name.enum';
import { EnumSubsType } from '../../models/userSubscriptionTypes';
import { BetaLeverage } from '../../cocViewInputs';

@Component({
    selector: 'costof-capital-inputs',
    templateUrl: './costof-capital-inputs.component.html',
    styleUrls: ['./costof-capital-inputs.component.scss']
})
export class CostofCapitalInputsComponent implements OnInit {

    @Select(ValuesState.get) public valuesSelector!: Observable<MinimumDate | undefined>;
    public notifier = new Subject();
    public currencyofwaccoptions: Suggestion<string>[] = [];
    public investorPerspectiveOptions: Suggestion<string>[] = [];
    public minimumDate = -1;
    public subscriptionLevel!: any;
    public ineSubData: SubscriptionData | undefined;
    public usiSubData: SubscriptionData | undefined
    public currencyData!: CurrencyData[];
    public defualttabIndex = 0;
    public exportFileName = 'cofc-inputs-data'
    public countryCurrencies: Country[] = [];
    public investorCountry!: string | null

    @ViewChild(SizePremiumComponent) sizePremium!: SizePremiumComponent;

    constructor(
        public spinner: Spinner,
        private router: Router, private userStore: UserStore, private regionClient: RegionClient, private dataStore: DataStore, private countryClient: CountryClient, private sharedCocInputService: CocInputsSharedService,
        private cocinputsExcelExportService: CocinputsExcelExportService) { this.checkAndRedirect(); }

    tabsData = [
        {
            tabName: 'Risk-Free Rates and Equity Risk Premium',
            path: '/coc-inputs/rfr-and-erp', //if any changes in route path make sure to update same in checkAndRedirect() method logic.
            data: {},
            isActive: false,
            isLocked: false
        },
        {
            tabName: 'Size Premium',
            path: '/coc-inputs/size-premium',
            data: {},
            isActive: false,
            isLocked: false
        },
        {
            tabName: 'Risk Premium Over the Risk-Free Rate',
            path: '/coc-inputs/risk-premium',
            data: {},
            isActive: false,
            isLocked: false
        },
        {
            tabName: 'Betas and Industry Risk Premium',
            path: '/coc-inputs/betas',
            data: {},
            isActive: false,
            isLocked: false
        },
        {
            tabName: 'Country Risk Premium and Relative Volatility',
            path: '/coc-inputs/country-risk-premium',
            data: {},
            isActive: false,
            isLocked: false
        }
    ]

    public valuationDateConfig: CommonInputConfig = {
        readonly: true,
        required: true,
        placeholder: 'Select Valuation Date',
    };

    public investorPerspectiveConfig: CommonInputConfig = {
        name: 'Investor Perspective',
        placeholder: 'Select Investor',
        containerClassList: 'input-flex-display inline-disable-text',
        stackedDisplay: true,
        iconWithLabel: true,
        required: true,
        readonly: true,
    };
    public currencyofwaccconfig: CommonInputConfig = {
        name: 'Currency of WACC',
        label: 'Currency of WACC',
        placeholder: 'Select Currency',
        containerClassList: 'input-flex-display inline-disable-text',
        stackedDisplay: true,
        iconWithLabel: true,
        required: true,
        readonly: true
    };

    public cocInputs: CocInputs = {
        valuationDate: CommonDate.fromDate(new Date()),
        investorPerspectiveregion: new ContextualString(),
        currencyOfWACC: new ContextualString(),
    };

    ngOnInit(): void {

        this.configureInputs();
        this.currencyofwaccoptions = [];
        this.investorPerspectiveOptions = [];
    }



    private configureInputs(): void {
        this.spinner.begin();
        combineLatest([
            this.userStore.user,
            this.valuesSelector
        ]).pipe(
            takeUntil(this.notifier),
            map((x) => ({
                user: x[0],
                values: x[1]
            }))
        ).onceDefined((cocProData) => {
            if (cocProData.user == null || cocProData.values == null) {
                return;
            }
            this.sharedCocInputService.defineUserPermissions(cocProData.user.subscriptionDetails);
            this.ineSubData = cocProData.user.subscriptionDetails.find((sub) => sub.productType === ProductType.Ine);
            this.usiSubData = cocProData.user.subscriptionDetails.find((sub) => sub.productType === ProductType.Usi);
            var productType = (this.ineSubData && !this.usiSubData) ? ProductType.Ine : ProductType.Coc


            if (cocProData.values) {

                if (this.ineSubData && !this.usiSubData) {
                    this.minimumDate = cocProData.values.InternationalEstimates;
                }

                if (this.usiSubData && !this.ineSubData) {
                    this.minimumDate = cocProData.values.UsEstimates;
                }

                if (this.usiSubData && this.ineSubData) {
                    this.minimumDate = cocProData.values.UsEstimates;
                }
            }
            // if (this.ineSubData && this.usiSubData)


            this.updateTabsDataBasedOnUserPerms();
            this.setValuationDateConfigBasedOnSubscription(cocProData, productType);
            this.mapInvestorCountryOptions();
        });
    }

    private updateTabsDataBasedOnUserPerms() {
        const subsType = this.sharedCocInputService.usrPerms.SubsType;

        if (subsType == EnumSubsType.Coc) {
            var tabIndex = this.tabsData.findIndex(tab => tab.tabName == 'Country Risk Premium and Relative Volatility');
            this.tabsData[tabIndex].isLocked = true;
        } else if (subsType == EnumSubsType.Ine) {
            var SPIndex = this.tabsData.findIndex(tab => tab.tabName == 'Size Premium');
            this.tabsData[SPIndex].isLocked = true;
            var RPOIndex = this.tabsData.findIndex(tab => tab.tabName == 'Risk Premium Over the Risk-Free Rate');
            this.tabsData[RPOIndex].isLocked = true;
        }
    }

    private setValuationDateConfigBasedOnSubscription(betaData: any, productType: ProductType): void {
        const date = moment().toISOString();
        this.valuationDateConfig =
            UserUtil.getValuationDateInputConfig(
                CommonDate.fromString(date),
                productType,
                this.minimumDate,
                betaData.user.subscriptionDetails,
                undefined
            );
        // if (config.minimumValue && config.maximumValue && this.valuationDateConfig.minimumValue && this.valuationDateConfig.maximumValue) {
        //     if (CommonDate.fromStruct(config.minimumValue as NgbDateStruct).asDate().setHours(0, 0, 0, 0) <= (CommonDate.fromStruct(this.valuationDateConfig.minimumValue as NgbDateStruct)).asDate().setHours(0, 0, 0, 0)
        //         && CommonDate.fromStruct(config.maximumValue as NgbDateStruct).asDate().setHours(0, 0, 0, 0) >= CommonDate.fromStruct(this.valuationDateConfig.maximumValue as NgbDateStruct).asDate().setHours(0, 0, 0, 0)) {
        //         this.valuationDateConfig = {
        //             ...this.valuationDateConfig,
        //             ...config
        //         };
        //     } else if (CommonDate.fromStruct(config.maximumValue as NgbDateStruct).asDate().setHours(0, 0, 0, 0) >= CommonDate.fromStruct(this.valuationDateConfig.maximumValue as NgbDateStruct).asDate().setHours(0, 0, 0, 0)) {
        //         this.valuationDateConfig.maximumValue = config.maximumValue;
        //     } else if (CommonDate.fromStruct(config.minimumValue as NgbDateStruct).asDate().setHours(0, 0, 0, 0) <= (CommonDate.fromStruct(this.valuationDateConfig.minimumValue as NgbDateStruct)).asDate().setHours(0, 0, 0, 0)) {
        //         this.valuationDateConfig.minimumValue = config.minimumValue;
        //     }
        // }

        this.subscriptionLevel = betaData.user.subscriptionDetails.find((x: any) => x.productType === ProductType.Ini || x.productType === ProductType.Coc);
        if (this.valuationDateConfig.maximumValue && this.subscriptionLevel.level === 'Trial') {
            this.cocInputs.valuationDate = CommonDate.fromStruct(this.valuationDateConfig.maximumValue as NgbDateStruct);
        }
    }

    private getValuationDateConfig(productType: ProductType, cocData: any): CommonInputConfig {
        const defaultValuationDate = moment().toISOString();
        const config =
            UserUtil.getValuationDateInputConfig(
                CommonDate.fromString(defaultValuationDate),
                productType,
                this.minimumDate,
                cocData.user.subscriptionDetails,
                undefined
            );
        return config;
    }

    public mapInvestorCountryOptions() {
        const countryCurrencies = this.countryClient.investors();
        const options: Suggestion<string>[] = [];
        countryCurrencies.onceDefined(countryCurrency => {
            this.countryCurrencies = countryCurrency;

            countryCurrency.forEach((country) => {
                options.push({
                    id: country.CountryId,
                    name: country.CountryName,
                    value: country.CountryName,
                    type: ComboboxDataType.String,
                    disabled: this.checkDisabled(country.CountryName),
                    enableLockIcon: this.checkDisabled(country.CountryName)
                });
            });

            const Ukindex = options.findIndex((x) => x.name === 'United Kingdom');
            const ukAray: any = options.splice(Ukindex, 1);
            options.unshift(ukAray[0]);

            const germIndex = options.findIndex((x) => x.name === 'Germany');
            const germanyArray: any = options.splice(germIndex, 1);
            options.unshift(germanyArray[0]);

            const index = options.findIndex((x) => x.name === 'United States');
            const newArray: any = options.splice(index, 1);

            options.unshift(newArray[0]);



            this.investorPerspectiveOptions = options;
            const objCountry = this.countryCurrencies.find((x) => x.CountryName === 'United States');

            if (objCountry) {
                this.cocInputs.investorPerspectiveregion.value = objCountry.CountryName;
            }

        });

    }


    public setCureenciesofWAC(cuntryCurency: Country) {
        this.cocInputs.currencyOfWACC.value = cuntryCurency.Currency.CurrencyName + ' - ' + cuntryCurency.Currency.CurrencyAcronym;
        this.currencyofwaccconfig.disabled = true;
        const inputValues = this.mapInputValues();
        this.sharedCocInputService.setCocInputValues(inputValues);
        this.refreshTabsData();
        this.spinner.end();

    }


    private checkDisabled(countryName: string): boolean {

        if (this.sharedCocInputService.usrPerms.SubsType == EnumSubsType.Coc) {
            return countryName == 'United States' ? false : true;
        } else {
            return false;
        }

    }






    public generalInputs: any = {
        ValuationDate: CommonDate.fromDate(new Date()),
        investorPerspective: new ContextualString(),
        CurrencyOfWACC: new ContextualString(),
    } as any;


    ngAfterViewInit(): void {
        this.navigate(this.tabsData[0]);
    }


    navigate(tab: any) {
        this.tabsData.map(s => s.isActive = false);
        const inputValues = this.mapInputValues();
        this.sharedCocInputService.setCocInputValues(inputValues);
        tab.isActive = true;
        this.router.navigateByUrl(tab.path);

    }

    onValuationDateChanged(input: any) {
        const inputValues = this.mapInputValues();
        this.sharedCocInputService.setCocInputValues(inputValues);
        this.refreshTabsData()
    }


    private refreshTabsData() {
        this.tabsData.map(s => s.isActive = false);
        this.sharedCocInputService.getInitialTabsData();
        setTimeout(() => {
            this.tabsData[this.defualttabIndex].isActive = true;
        }, 300);
    }


    // private UpdateChanges() {
    //     if (this.tabsData.find(d => d.tabName == 'Country Risk Premium and Relative Volatility')?.isActive) {
    //         this.sharedCocInputService._ValuationDateChanged.next(true);
    //     }
    // }


    onInvestorChanege(investorInput: ContextualString) {
        if (this.investorCountry != investorInput.value) {
            this.investorCountry = investorInput.value;

            const objCountry = this.countryCurrencies.find((x) => x.CountryName === investorInput.value);
            const subscriptionType = this.sharedCocInputService.usrPerms.SubsType;
            if (subscriptionType == EnumSubsType.Ine) {
                this.updateSizePandRiskPTabsbasedOnCntry();
            }

            if (objCountry) {
                this.setCureenciesofWAC(objCountry);
                this.sharedCocInputService.updateGeoCoverage();
                this.sharedCocInputService.industryDataOptions = [];
                const dataOptions = [BetaLeverage.LeveredBetas, BetaLeverage.UnleveredBetas];
                dataOptions.forEach((item) => {
                    this.sharedCocInputService.industryDataOptions.push({
                        name: item,
                        value: item,
                        type: ComboboxDataType.String,
                        disabled: false
                    });
                });
                this.sharedCocInputService.betaIndustry.value = BetaLeverage.LeveredBetas;
                this.sharedCocInputService.tabHeaders = "Industry-level Betas";
                this.sharedCocInputService.tableHeaders = this.sharedCocInputService.betaHeadresData[BetaLeverage.LeveredBetas];
            }
        }
    }

    findCurrencyAcronym(currency: string | null | undefined): string {
        if (currency !== null && currency !== undefined) {
            var index = currency.indexOf('-') ?? -1;
            return currency.substring(index + 2, index + 5)
        }
        return "";
    }

    onCurrencyChange(currencyInput: any) {

    }


    mapInputValues(): CocInputValues {

        const inputValues: CocInputValues = {
            valuationDate: ngbDateStructToString(this.cocInputs.valuationDate).split('T')[0],
            investorPerspectiveregion: this.cocInputs.investorPerspectiveregion.value,
            currencyOfWACC: this.cocInputs.currencyOfWACC.value,
            investorCountryId: this.countryCurrencies.find((x) => x.CountryName === this.cocInputs.investorPerspectiveregion.value)?.CountryId ?? 0,
            currencyAcronym: this.findCurrencyAcronym(this.cocInputs.currencyOfWACC.value)

        }

        return inputValues;
    }




    exportToExcel(component: ExcelExportComponent) {
        this.cocinputsExcelExportService.exportAllData(component)
    }


    public updateSizePandRiskPTabsbasedOnCntry() {

        var SPIndex = this.tabsData.findIndex(tab => tab.tabName == 'Size Premium');
        var RPOIndex = this.tabsData.findIndex(tab => tab.tabName == 'Risk Premium Over the Risk-Free Rate');
        if (this.cocInputs.investorPerspectiveregion.value == "United States") {
            if (this.defualttabIndex == 1 || this.defualttabIndex == 2) {
                this.defualttabIndex = 0;
                this.navigate(this.tabsData[0])
            }
            this.tabsData[SPIndex].isLocked = true;
            this.tabsData[RPOIndex].isLocked = true;
        } else {
            this.tabsData[SPIndex].isLocked = false;
            this.tabsData[RPOIndex].isLocked = false;
        }
    }


    checkAndRedirect() {

        this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((val) => {
            if (val instanceof NavigationEnd && val.url == "/coc-inputs" && val.urlAfterRedirects == '/coc-inputs/rfr-and-erp') {
                if (this.tabsData.find(s => s.isActive == true) && this.tabsData[0].isActive == false) {
                    this.tabsData.map(s => s.isActive = false);
                    this.defualttabIndex = 0;
                    this.tabsData[0].isActive = true;
                }
            }
        })
    }


}
