import { CountryRiskPremiaType } from 'src/app/_api/enums/country-risk-premia-type';
import { CountryRiskPremia } from 'src/app/_api/responses/country-risk-premia.response';
import { Estimate } from 'src/app/_navigator/data/model/estimate.model';
import { SummaryType } from '../data/enum/summary-type';
import { CalculationsUtil } from '../data/util/calculations.util';
import { CrpTableText } from './crp-table-text';

export class SummaryTableCommonUtil {
    public static getHeaders(onlyShowYieldSpread: boolean = false, isWaccConclusion: boolean = false): string[] {
        const a = '';
        const b = CrpTableText.CcrModel;
        const c = CrpTableText.CountryYieldSpreadModel;
        const d = CrpTableText.RelativeVolatilityModel;

        let headers: string[];
        if (isWaccConclusion) {
            headers = [a, b, c, d];
        } else {
            headers = onlyShowYieldSpread ? [a, c] : [a, b, c, d];
        }

        return headers;
    }

    public static getTableName(type: CountryRiskPremiaType, regionName: string): string {
        let name = CrpTableText.NotAvailable;

        if (regionName != null) {
            name = regionName;
        }

        if (type === CountryRiskPremiaType.EuromoneyRegion) {
            return `${CrpTableText.EuromoneyRegion} (${name})`;
        } else if (type === CountryRiskPremiaType.MsciClassification) {
            return `${CrpTableText.McsiClassification} (${name})`;
        } else if (type === CountryRiskPremiaType.SpCreditRating) {
            return `${CrpTableText.SpCreditRating}`;
        } else {
            return CrpTableText.Region;
        }
    }

    public static getAdjustedMetric(
        estimate: Estimate,
        applyFisherEffect: boolean,
        summaryType: SummaryType,
        isVolatilityMetric: boolean,
        metric?: number
    ): number {
        if (metric == null) {
            return 0;
        }

        let capm = 0;

        if (summaryType === SummaryType.CostOfDebt) {
            capm = isVolatilityMetric
                ? CalculationsUtil.calculateCostOfDebtVolatility(estimate, metric)
                : CalculationsUtil.calculateCostOfDebt(estimate, metric);
        } else if (summaryType === SummaryType.CostOfEquity) {
            capm = isVolatilityMetric
                ? CalculationsUtil.calculateCapmVolatility(estimate, metric)
                : CalculationsUtil.calculateCapm(estimate, metric);
        }

        return applyFisherEffect ? CalculationsUtil.calculateFisherEffect(estimate, capm) : capm;
    }

    public static getAdjustedWacc(estimate: Estimate, ke: number | undefined, kd: number | undefined): number | undefined {
        if (ke === undefined || kd === undefined) {
            return undefined;
        }

        return CalculationsUtil.calculateWacc(estimate, ke, kd);
    }

    public static formatCrp(
        estimate: Estimate,
        applyFisherEffect: boolean,
        crp: CountryRiskPremia,
        summaryType: SummaryType
    ): CountryRiskPremia {
        let ccrAvg;
        if (crp.CcrAvg != null) {
            ccrAvg = this.getAdjustedMetric(estimate, applyFisherEffect, summaryType, false, crp.CcrAvg);
        }

        let ccrMedian;
        if (crp.CcrMedian != null) {
            ccrMedian = this.getAdjustedMetric(estimate, applyFisherEffect, summaryType, false, crp.CcrMedian);
        }

        let yieldSpreadAvg;
        if (crp.YieldSpreadAvg != null) {
            yieldSpreadAvg = this.getAdjustedMetric(estimate, applyFisherEffect, summaryType, false, crp.YieldSpreadAvg);
        }

        let yieldSpreadMedian;
        if (crp.YieldSpreadMedian != null) {
            yieldSpreadMedian = this.getAdjustedMetric(estimate, applyFisherEffect, summaryType, false, crp.YieldSpreadMedian);
        }

        let volatilityAvg;
        if (crp.VolatilityAvg != null) {
            volatilityAvg = this.getAdjustedMetric(estimate, applyFisherEffect, summaryType, true, crp.VolatilityAvg);
        }

        let volatilityMedian;
        if (crp.VolatilityMedian != null) {
            volatilityMedian = this.getAdjustedMetric(estimate, applyFisherEffect, summaryType, true, crp.VolatilityMedian);
        }

        return {
            Type: crp.Type,
            RegionName: crp.RegionName,
            CcrAvg: ccrAvg,
            CcrMedian: ccrMedian,
            YieldSpreadAvg: yieldSpreadAvg,
            YieldSpreadMedian: yieldSpreadMedian,
            VolatilityAvg: volatilityAvg,
            VolatilityMedian: volatilityMedian
        };
    }
}
