import * as moment from 'moment';

import { Component, OnInit } from '@angular/core';
import { SubscriberEntity } from '@concurrency/angular';
import { takeUntil } from 'rxjs/operators';
import { User } from 'src/app/_navigator/user/model/user.model';
import { UserStore } from 'src/app/_navigator/user/store/user.store';

@Component({ templateUrl: './expiration-modal.component.html' })
export class ExpirationModalComponent extends SubscriberEntity implements OnInit {
    public user: User = {} as any;
    public expirationDate?: string;
    public titleString?: string;
    public istrue = true;

    constructor(
        private userStore: UserStore
    ) { super(); }

    public ngOnInit(): void {
        // TODO: Obviate takeUntil by using Async pipes and local Observable streams
        this.userStore.user.pipe(takeUntil(this.destroyed)).onceDefined((user) => {
            this.user = user;
            if (user.IsActive === true) {
                this.istrue = false;
                this.titleString = '<div>Welcome!</div> Please Click Continue to Subscribe';
            } else {
                this.expirationDate = moment(user.Subscriptions[0].End).format('MM/DD/YYYY');
                this.titleString = '<div>Notice!</div> Your subscription has ended';
            }
        });
    }

    public signout(): void {
        this.userStore.logoutCustomerOrEmployee();
    }
}
