import { betaBackGround } from 'src/app/estimate/exports/summary-export/util/summary-helpers';
import { BetaComparbleCompanyResults } from '../beta.types';

export function getObservations(companies: BetaComparbleCompanyResults[]): any[] {
    const observations: any[] = [];
    if (companies) {
        observations.push(
            {
                bold: true,
                colSpan: 2,
                value: '# of Observations'
            },
            { value: '' }
            // { value: '' },
        );
        companies.forEach((company: BetaComparbleCompanyResults) => {
            observations.push(
                {
                    background: betaBackGround,
                    textAlign: 'right',
                    value: company.Observations
                }
            );
        });
    }
    return observations;
}
