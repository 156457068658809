import { Action, Select, Selector, State, StateContext } from '@ngxs/store';
import { Observable } from 'rxjs/internal/Observable';
import { DynamicTextSummary } from 'src/app/international-estimate/dynamic-text/model/dynamic-text-summary';
import { UpdateDynamicTextSummary } from './dynamic-text-summary-action';
import { Injectable } from '@angular/core';

export type DynamicTextSummaryModel = DynamicTextSummary;

@State<DynamicTextSummaryModel>({
    name: 'dynamicText',
    defaults: undefined
})

@Injectable()
export class DynamicTextSummaryState {

    @Selector()
    public static get(state: DynamicTextSummaryModel): DynamicTextSummary | undefined {
        return state;
    }

    @Select(DynamicTextSummaryState.get)
    public dynamicTextSelector!: Observable<DynamicTextSummary | undefined>;
    constructor() { }

    @Action(UpdateDynamicTextSummary)
    public updateDynamicTextSummary(context: StateContext<DynamicTextSummaryModel>, action: UpdateDynamicTextSummary): Observable<void> {
        return new Observable<void>(() => {
            const state = context.getState();
            state.GeneralInputsText = action.dynamicText.GeneralInputsText;
            state.CostOfEquityInputsText = action.dynamicText.CostOfEquityInputsText;
            state.CrpSummaryText = action.dynamicText.CrpSummaryText;
            state.FisherEffectText = action.dynamicText.FisherEffectText;
            state.CostOfEquitySummaryCapmText = action.dynamicText.CostOfEquitySummaryCapmText;
            state.CostOfEquitySummaryFisherEffectText = action.dynamicText.CostOfEquitySummaryFisherEffectText;
            state.CostOfEquitySummaryConclusionText = action.dynamicText.CostOfEquitySummaryConclusionText;
            state.KdAndWaccInputsText = action.dynamicText.KdAndWaccInputsText;
            state.CostOfDebtSummaryCapitalText = action.dynamicText.CostOfDebtSummaryCapitalText;
            state.CostOfDebtSummaryFisherEffectText = action.dynamicText.CostOfDebtSummaryFisherEffectText;
            state.CostOfDebtSummaryConclusionText = action.dynamicText.CostOfDebtSummaryConclusionText;
            state.WaccSummaryCapitalText = action.dynamicText.WaccSummaryCapitalText;
            state.WaccSummaryConclusionText = action.dynamicText.WaccSummaryConclusionText;
            context.setState(state);
        });
    }
}
