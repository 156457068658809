import { SelectionType } from 'src/app/_api/enums/selection-type';
import { InputType } from 'src/app/_api/responses/input.response';
import { Estimate } from 'src/app/_navigator/data/model/estimate.model';
import { IntlEstimateUtil } from '../data/util/intl-estimate.util';
import { EquationElement } from './equation-element.model';
import { EquationElementUtil } from './equation-element.util';

export class EquationsUtil {
    public static getCcrEquation(estimate: Estimate, currency: string): EquationElement[] {
        const scenario = estimate.Scenarios[0];
        const beta = scenario.getValueOrNaN(SelectionType.IntlBeta, InputType.None);

        return EquationElementUtil.getCapmCcrEquation(
            currency,
            IntlEstimateUtil.selectionAsStringOrUndefined(scenario, SelectionType.CostOfEquityCapmCcr),
            IntlEstimateUtil.selectionAsStringOrUndefined(scenario, SelectionType.IntlRiskFreeRate),
            IntlEstimateUtil.selectionAsStringOrUndefined(scenario, SelectionType.IntlEquityRiskPremium),
            beta.toString(),
            IntlEstimateUtil.selectionAsStringOrUndefined(scenario, SelectionType.IntlSizePremium),
            IntlEstimateUtil.selectionAsStringOrUndefined(scenario, SelectionType.IntlAlpha),
            IntlEstimateUtil.selectionAsStringOrUndefined(scenario, SelectionType.CountryRiskPremium)
        );
    }

    public static getYieldSpreadEquation(estimate: Estimate, currency: string): EquationElement[] {
        const scenario = estimate.Scenarios[0];
        const beta = scenario.getValueOrNaN(SelectionType.IntlBeta, InputType.None);

        return EquationElementUtil.getCapmYieldSpreadEquation(
            currency,
            IntlEstimateUtil.selectionAsStringOrUndefined(scenario, SelectionType.CostOfEquityCapmYieldSpread),
            IntlEstimateUtil.selectionAsStringOrUndefined(scenario, SelectionType.IntlRiskFreeRate),
            IntlEstimateUtil.selectionAsStringOrUndefined(scenario, SelectionType.IntlEquityRiskPremium),
            beta.toString(),
            IntlEstimateUtil.selectionAsStringOrUndefined(scenario, SelectionType.IntlSizePremium),
            IntlEstimateUtil.selectionAsStringOrUndefined(scenario, SelectionType.IntlAlpha),
            IntlEstimateUtil.selectionAsStringOrUndefined(scenario, SelectionType.YieldSpread)
        );
    }

    public static getVolatilityEquation(estimate: Estimate, currency: string): EquationElement[] {
        const scenario = estimate.Scenarios[0];
        const beta = scenario.getValueOrNaN(SelectionType.IntlBeta, InputType.None);
        const relativeVolatility = scenario.getValueOrNaN(SelectionType.RelativeVolatility, InputType.None);

        return EquationElementUtil.getCapmVolatilityEquation(
            currency,
            IntlEstimateUtil.selectionAsStringOrUndefined(scenario, SelectionType.CostOfEquityCapmVolatility),
            IntlEstimateUtil.selectionAsStringOrUndefined(scenario, SelectionType.IntlRiskFreeRate),
            IntlEstimateUtil.selectionAsStringOrUndefined(scenario, SelectionType.IntlEquityRiskPremium),
            beta.toString(),
            relativeVolatility.toString(),
            IntlEstimateUtil.selectionAsStringOrUndefined(scenario, SelectionType.IntlSizePremium),
            IntlEstimateUtil.selectionAsStringOrUndefined(scenario, SelectionType.IntlAlpha)
        );
    }

    public static getCostOfEquityFisherEffectEquation(
        estimate: Estimate,
        countryOfInputName: string,
        cashFlowCountryName: string,
        capmSelection: SelectionType,
        fisherEffectSelection: SelectionType,
        countryOfInputCurrency: string,
        cashFlowCurrency: string
    ): EquationElement[] {
        const scenario = estimate.Scenarios[0];

        return EquationElementUtil.getCostOfEquityFisherEffectEquation(
            countryOfInputCurrency,
            cashFlowCurrency,
            countryOfInputName,
            cashFlowCountryName,
            IntlEstimateUtil.selectionAsStringOrUndefined(scenario, capmSelection),
            IntlEstimateUtil.selectionAsStringOrUndefined(scenario, fisherEffectSelection),
            IntlEstimateUtil.selectionAsStringOrUndefined(scenario, SelectionType.InflationInvestee),
            IntlEstimateUtil.selectionAsStringOrUndefined(scenario, SelectionType.InflationInvestor)
        );
    }

    public static getCostOfEquityConclusionEquation(estimate: Estimate, keSelection: SelectionType, currency: string): EquationElement[] {
        const scenario = estimate.Scenarios[0];

        return EquationElementUtil.getCostOfEquityConclusionEquation(
            currency,
            IntlEstimateUtil.selectionAsStringOrUndefined(scenario, keSelection)
        );
    }

    public static getCostOfDebtEquation(
        estimate: Estimate,
        crpSelection: SelectionType,
        kdSelection: SelectionType,
        currencyAcronym: string,
        crpType: string,
        investeeCountryName: string
    ): EquationElement[] {
        const scenario = estimate.Scenarios[0];

        return EquationElementUtil.getCostOfDebtEquation(
            currencyAcronym,
            IntlEstimateUtil.selectionAsStringOrUndefined(scenario, kdSelection),
            IntlEstimateUtil.selectionAsStringOrUndefined(scenario, SelectionType.PreTaxCostOfDebt),
            IntlEstimateUtil.selectionAsStringOrUndefined(scenario, crpSelection),
            crpType,
            IntlEstimateUtil.selectionAsStringOrUndefined(scenario, SelectionType.TaxRate),
            investeeCountryName
        );
    }

    public static getCostOfDebtVolatilityEquation(
        estimate: Estimate,
        currencyAcronym: string,
        investeeCountryName: string
    ): EquationElement[] {
        const scenario = estimate.Scenarios[0];
        const rv = scenario.getValueOrNaN(SelectionType.RelativeVolatility, InputType.None);

        return EquationElementUtil.getCostOfDebtVolatilityEquation(
            currencyAcronym,
            IntlEstimateUtil.selectionAsStringOrUndefined(scenario, SelectionType.CostOfDebtVolatility),
            IntlEstimateUtil.selectionAsStringOrUndefined(scenario, SelectionType.PreTaxCostOfDebt),
            rv.toString(),
            IntlEstimateUtil.selectionAsStringOrUndefined(scenario, SelectionType.IntlEquityRiskPremium),
            IntlEstimateUtil.selectionAsStringOrUndefined(scenario, SelectionType.TaxRate),
            investeeCountryName
        );
    }

    public static getCostOfDebtFisherEffectEquation(
        estimate: Estimate,
        countryOfInputName: string,
        cashFlowCountryName: string,
        kdSelection: SelectionType,
        fisherEffectSelection: SelectionType,
        countryOfInputCurrency: string,
        cashFlowCurrency: string
    ): EquationElement[] {
        const scenario = estimate.Scenarios[0];

        return EquationElementUtil.getCostOfDebtFisherEffectEquation(
            countryOfInputCurrency,
            cashFlowCurrency,
            countryOfInputName,
            cashFlowCountryName,
            IntlEstimateUtil.selectionAsStringOrUndefined(scenario, kdSelection),
            IntlEstimateUtil.selectionAsStringOrUndefined(scenario, fisherEffectSelection),
            IntlEstimateUtil.selectionAsStringOrUndefined(scenario, SelectionType.InflationInvestee),
            IntlEstimateUtil.selectionAsStringOrUndefined(scenario, SelectionType.InflationInvestor)
        );
    }

    public static getCostOfDebtConclusionEquation(estimate: Estimate, kdSelection: SelectionType, currency: string): EquationElement[] {
        const scenario = estimate.Scenarios[0];

        return EquationElementUtil.getCostOfDebtConclusionEquation(
            currency,
            IntlEstimateUtil.selectionAsStringOrUndefined(scenario, kdSelection)
        );
    }

    public static getWaccCcrEquation(
        estimate: Estimate,
        useKeFisherEffect: boolean,
        useKdFisherEffect: boolean,
        useSingleCountryKd: boolean,
        useYsModelOnly: boolean
    ): EquationElement[] {
        const scenario = estimate.Scenarios[0];

        const keSelection = useKeFisherEffect ? SelectionType.CostOfEquityFisherEffectCcr : SelectionType.CostOfEquityCapmCcr;
        const kdSelection = IntlEstimateUtil.getCostOfDebtSelection(
            useKdFisherEffect,
            useSingleCountryKd,
            useYsModelOnly,
            SelectionType.CostOfDebtCcr,
            SelectionType.CostOfDebtFisherEffectCcr
        );

        return EquationElementUtil.getWaccEquation(
            IntlEstimateUtil.selectionAsStringOrUndefined(scenario, keSelection),
            IntlEstimateUtil.selectionAsStringOrUndefined(scenario, SelectionType.WeightedEquity),
            IntlEstimateUtil.selectionAsStringOrUndefined(scenario, kdSelection),
            IntlEstimateUtil.selectionAsStringOrUndefined(scenario, SelectionType.DebtToTotalCapitalRatio),
            IntlEstimateUtil.selectionAsStringOrUndefined(scenario, SelectionType.WaccCcr)
        );
    }

    public static getWaccYieldSpreadEquation(
        estimate: Estimate,
        useKeFisherEffect: boolean,
        useKdFisherEffect: boolean,
        useSingleCountryKd: boolean,
        useYsModelOnly: boolean
    ): EquationElement[] {
        const scenario = estimate.Scenarios[0];

        const keSelection = useKeFisherEffect
            ? SelectionType.CostOfEquityFisherEffectYieldSpread
            : SelectionType.CostOfEquityCapmYieldSpread;
        const kdSelection = IntlEstimateUtil.getCostOfDebtSelection(
            useKdFisherEffect,
            useSingleCountryKd,
            useYsModelOnly,
            SelectionType.CostOfDebtYieldSpread,
            SelectionType.CostOfDebtFisherEffectYieldSpread
        );

        return EquationElementUtil.getWaccEquation(
            IntlEstimateUtil.selectionAsStringOrUndefined(scenario, keSelection),
            IntlEstimateUtil.selectionAsStringOrUndefined(scenario, SelectionType.WeightedEquity),
            IntlEstimateUtil.selectionAsStringOrUndefined(scenario, kdSelection),
            IntlEstimateUtil.selectionAsStringOrUndefined(scenario, SelectionType.DebtToTotalCapitalRatio),
            IntlEstimateUtil.selectionAsStringOrUndefined(scenario, SelectionType.WaccYieldSpread)
        );
    }

    public static getWaccVolatilityEquation(
        estimate: Estimate,
        useKeFisherEffect: boolean,
        useKdFisherEffect: boolean,
        useSingleCountryKd: boolean,
        useYsModelOnly: boolean
    ): EquationElement[] {
        const scenario = estimate.Scenarios[0];

        const keSelection = useKeFisherEffect ? SelectionType.CostOfEquityFisherEffectVolatility : SelectionType.CostOfEquityCapmVolatility;
        const kdSelection = IntlEstimateUtil.getCostOfDebtSelection(
            useKdFisherEffect,
            useSingleCountryKd,
            useYsModelOnly,
            SelectionType.CostOfDebtVolatility,
            SelectionType.CostOfDebtFisherEffectVolatility
        );

        return EquationElementUtil.getWaccEquation(
            IntlEstimateUtil.selectionAsStringOrUndefined(scenario, keSelection),
            IntlEstimateUtil.selectionAsStringOrUndefined(scenario, SelectionType.WeightedEquity),
            IntlEstimateUtil.selectionAsStringOrUndefined(scenario, kdSelection),
            IntlEstimateUtil.selectionAsStringOrUndefined(scenario, SelectionType.DebtToTotalCapitalRatio),
            IntlEstimateUtil.selectionAsStringOrUndefined(scenario, SelectionType.WaccVolatility)
        );
    }

    public static getWaccConclusionEquation(estimate: Estimate, waccSelection: SelectionType): EquationElement[] {
        const scenario = estimate.Scenarios[0];

        return EquationElementUtil.getWaccConclusionEquation(IntlEstimateUtil.selectionAsStringOrUndefined(scenario, waccSelection));
    }

    public static getSingleCountryCapmEquation(estimate: Estimate, currency: string): EquationElement[] {
        const scenario = estimate.Scenarios[0];
        const beta = scenario.getValueOrNaN(SelectionType.IntlBeta, InputType.None);

        return EquationElementUtil.getSingleCountryCapmEquation(
            currency,
            IntlEstimateUtil.selectionAsStringOrUndefined(scenario, SelectionType.KeSingleCountryCapm),
            IntlEstimateUtil.selectionAsStringOrUndefined(scenario, SelectionType.IntlRiskFreeRate),
            IntlEstimateUtil.selectionAsStringOrUndefined(scenario, SelectionType.IntlEquityRiskPremium),
            beta.toString(),
            IntlEstimateUtil.selectionAsStringOrUndefined(scenario, SelectionType.IntlSizePremium),
            IntlEstimateUtil.selectionAsStringOrUndefined(scenario, SelectionType.IntlAlpha)
        );
    }

    public static getSingleCountryCostOfDebtEquation(estimate: Estimate, currency: string, investeeCountryName: string): EquationElement[] {
        const scenario = estimate.Scenarios[0];

        return EquationElementUtil.getSingleCountryCostOfDebtEquation(
            currency,
            IntlEstimateUtil.selectionAsStringOrUndefined(scenario, SelectionType.KdSingleCountryCapm),
            IntlEstimateUtil.selectionAsStringOrUndefined(scenario, SelectionType.TaxRate),
            IntlEstimateUtil.selectionAsStringOrUndefined(scenario, SelectionType.PreTaxCostOfDebt),
            investeeCountryName
        );
    }

    public static getSingleCountryWaccEquation(estimate: Estimate, useFisherEffect: boolean): EquationElement[] {
        const scenario = estimate.Scenarios[0];
        const keSelection = useFisherEffect ? SelectionType.KeSingleCountryFisherEffect : SelectionType.KeSingleCountryCapm;
        const kdSelection = useFisherEffect ? SelectionType.KdSingleCountryFisherEffect : SelectionType.KdSingleCountryCapm;

        return EquationElementUtil.getWaccEquation(
            IntlEstimateUtil.selectionAsStringOrUndefined(scenario, keSelection),
            IntlEstimateUtil.selectionAsStringOrUndefined(scenario, SelectionType.WeightedEquity),
            IntlEstimateUtil.selectionAsStringOrUndefined(scenario, kdSelection),
            IntlEstimateUtil.selectionAsStringOrUndefined(scenario, SelectionType.DebtToTotalCapitalRatio),
            IntlEstimateUtil.selectionAsStringOrUndefined(scenario, SelectionType.WaccSingleCountry)
        );
    }
}
