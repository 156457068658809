import { Injectable } from '@angular/core';
import { ApiClient } from '@concurrency/angular';
import { Observable } from 'rxjs';
import { IndustryScenario } from 'src/app/benchmarking/data/industry-scenario';
import { TrendsOverTimeData } from 'src/app/benchmarking/data/trends-over-time-data';
import { TrendsOverTimeRequest } from 'src/app/benchmarking/data/trends-over-time-request';
import { environment } from 'src/environments/environment';
import { IndustryTearSheet } from '../responses/industry-tear-sheet.response';
import { Industry } from '../responses/us-industry.response';
import { Auth0ApiClient } from '../api.client';
import { ViewInputBetasByRegion } from '../responses/industry.response';

@Injectable()
export class IndustryClient extends Auth0ApiClient {
    protected baseUrl = `${environment.urls.navigatorApi}/api`;

    public read(region: string, currencyCode: string, dataAsOf: string): Observable<Industry[]> {
        return this.get(`Industry/Intl?region=${region}&currencyCode=${currencyCode}&dataAsOf=${dataAsOf}`);
    }

    public create(dataAsOf: string, dataRequests: IndustryScenario[]): Observable<IndustryTearSheet[]> {
        return this.post(`Industry/Data?dataAsOf=${dataAsOf}`, dataRequests);
    }

    public retrieveTrendsOverTime(dataRequests: TrendsOverTimeRequest[]): Observable<TrendsOverTimeData[][]> {
        return this.post(`Industry/TrendsOverTime`, dataRequests);
    }
}
