export enum enumViewMoreKey {
    CompanyList = 1,
    Assumptions = 2,
    OptimalCapitalStructure = 3
}

export enum enumViewMoreNames {
    companyList = 'View Industry Companies List',
    Assumptions = 'View Industry Assumptions',
    OptimalCapitalStructure = 'Export Optimal Capital Structure',
}


export enum enumAssumptions {
    DebtBeta = 'Debt Beta Assumptions',
    CostOfDebt = 'Cost of Debt Assumptions',
    CostOfEquity = 'Cost of Equity Assumptions'
}

export const industryAssumptionJSON: { [key: string]: string } = {
    'CostOfEquity': 'Cost of Equity Assumptions', 'CostOfDebt': 'Cost of Debt Assumptions', 'DebtBeta': 'Debt Beta Assumptions'
}
