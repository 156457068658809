import { Injectable } from '@angular/core';
import { ApiClient } from '@concurrency/angular';
import { Observable } from 'rxjs';
import { BetaComparbleCompanyRequest, BetaComparbleCompanyResults, BetaStatistics, BetaToT, CompanyInformation, CompanyReturnsRequest, CompanyReturnsRequestWithGVkeyStatus, CompanyselectedChangesResults, CompanyValuationInputs, LeveredBetas } from 'src/app/beta/beta.types';
import { environment } from 'src/environments/environment';
import { CompanyReturns, GicIndustry, OLSBetaCompanyReturns } from '../responses/company-returns.response';
import { MetricInputs } from '../responses/metric-inputs.response';
import { MsciMetrics } from '../responses/msci-metrics.response';
import { MsciReturns } from '../responses/msci-returns.response';
import { Auth0ApiClient } from '../api.client';


@Injectable()
export class BetaClient extends Auth0ApiClient {
    protected baseUrl = `${environment.urls.navigatorApi}/api`;

    public metrics(countryName: string): Observable<MsciMetrics[]> {
        return this.get(`Beta/Metrics?location=${countryName}`);
    }
    public getComparbleCompanyList(betaComparbleCompanyRequest: BetaComparbleCompanyRequest): Observable<BetaComparbleCompanyResults[]> {
        return this.post(`Beta/getBetaCopmarbleCompanies`, betaComparbleCompanyRequest);
    }
    public returns(inputs: MetricInputs): Observable<MsciReturns[]> {
        return this.post(`Beta/Returns`, inputs);
    }
    public getCompanyReturns(companyRequest: CompanyReturnsRequest): Observable<CompanyReturns[]> {
        return this.post(`Beta/companyReturns`, companyRequest);
    }
    public getCompanySelectedMetrics(companySelectedChnagesRequest: CompanyReturnsRequest): Observable<CompanyselectedChangesResults[]> {
        return this.post(`Beta/getCompanySelectedMetrics`, companySelectedChnagesRequest);
    }
    public getLeveredBetas(companyRequest: CompanyReturnsRequestWithGVkeyStatus): Observable<LeveredBetas[]> {
        return this.post(`Beta/getLeveredBetas`, companyRequest);
    }
    public getBetaStatistics(companyRequest: CompanyReturnsRequest): Observable<BetaStatistics[]> {
        return this.post(`Beta/getBetaStatistics`, companyRequest);
    }
    public getCompanySelecteCurrencyChanges(companySelectedCurrencyChanges: CompanyReturnsRequest): Observable<BetaComparbleCompanyResults[]> {
        return this.post(`Beta/getCompanyCurrencyChanges`, companySelectedCurrencyChanges);
    }
    public getCompanyInformation(compInputValues: CompanyValuationInputs): Observable<CompanyInformation> {
        return this.post(`Beta/getCompanyInformation`, compInputValues);
    }

    public getCompanyOLSBeta(name: string, companyRequest: CompanyReturnsRequest): Observable<OLSBetaCompanyReturns[]> {
        return this.post(`Beta/getBetasToT?name=${name}`, companyRequest);
    }


    public getBetasToT(name: string, companyRequest: CompanyReturnsRequest): Observable<BetaToT[]> {
        return this.post(`Beta/getBetasToT?name=${name}`, companyRequest);
    }

    public getGic(): Observable<GicIndustry[]> {
        return this.get(`Beta/getGicList`);
    }
}
