import { Component } from '@angular/core';
import { Spinner } from '@concurrency/angular';
import { ContextualNumber, Util } from '@concurrency/core';
import { Observable, of } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { USCompanyClient } from 'src/app/_api/clients/uscompany.client';
import { SelectionType } from 'src/app/_api/enums/selection-type';
import { InputType } from 'src/app/_api/responses/input.response';
import { CommonInputConfig } from 'src/app/_navigator/common/inputs/common-user-input.component';
import { Suggestion } from 'src/app/_navigator/data/model/suggestion.model';
import { DataStore } from 'src/app/_navigator/data/store/data.store';
import { NumberFormatUtil } from 'src/app/_navigator/data/util/number-format.util';
import { ErrorService } from 'src/app/_navigator/error/error.service';
import { HelpText } from 'src/app/_navigator/help/help-text';
import { PrimeManager } from 'src/app/_navigator/modal/pmodal.manager';
import { BetaEditorCompanyListComponent } from './beta-editor-company-list.component';
import { EditorComponent } from './editor.component';

@Component({ templateUrl: './industry-risk-editor.component.html' })
export class IndustryRiskEditorComponent extends EditorComponent {
    public irpSuggestions: Suggestion<number>[] = [];
    public betaType!: SelectionType;
    public beta!: ContextualNumber;
    public initialBeta!: ContextualNumber;

    public inputConfig: CommonInputConfig = {
        name: 'Industry Beta',
        help: HelpText.BetaDetailed,
        error: this.errorService.beta,
        minimumValue: 0,
        maximumValue: 5,
        pattern: '(.*?)',
        autofocus: true
    };

    constructor(
        protected dataStore: DataStore,
        private errorService: ErrorService,
        private primeManager: PrimeManager,
        private spinner: Spinner,
        private usCompanyClient: USCompanyClient
    ) { super(dataStore); }

    private getSuggestedBetas(suggestedBetas: Suggestion<number>[]): Suggestion<number>[] {
        const equityRiskPremium = this.settings.scenario.getSelection(SelectionType.EquityRiskPremium, InputType.None);
        if (equityRiskPremium.Value == null) {
            throw new Error(`Expected EquityRiskPremium to have a value on scenario ${this.settings.scenario.Id}`); // `
        }
        const erpValue = equityRiskPremium.Value;

        return suggestedBetas.map((suggestedBeta) => {
            const betaTimesErp = Util.round(Util.round(suggestedBeta.value) * erpValue);
            const industryRiskPremium = NumberFormatUtil.numberWithCommas(betaTimesErp - erpValue);

            return {
                name: `${suggestedBeta.name} (RPi = ${industryRiskPremium}%)`,
                source: suggestedBeta.source,
                value: suggestedBeta.value,
                dataAsOf: suggestedBeta.dataAsOf,
                disabledText: suggestedBeta.disabledText
            };
        });
    }

    public initialize(): void {
        // TODO: Obviate takeUntil by using Async pipes and local Observable streams
        this.dataStore.recalculate.pipe(takeUntil(this.destroyed)).while(() => this.mask());
        this.betaType = this.settings.operand.selectionType === SelectionType.CrspIndustryRiskPremium
            ? SelectionType.CrspDecilesBeta
            : SelectionType.RiskPremiumBeta;

        this.beta = this.settings.scenario.getSelectionAsContextualNumber(this.betaType, InputType.None);
        this.initialBeta = this.beta.clone();
        if (this.settings.suggestions == null) {
            throw new Error(`Expected suggestions to be defined`); // `
        }
        this.irpSuggestions = this.getSuggestedBetas(this.settings.suggestions);
        this.mask();
    }

    public mask(): void {
        const irp = this.settings.scenario.getSelectionAsContextualNumber(this.settings.operand.selectionType, InputType.None);
        this.inputConfig.mask = `{} - ${this.beta.context} (RPi = ${NumberFormatUtil.numberWithCommas(irp.asNumberOrNull || 0)}%)`; // `
    }

    public showCompanies(): void {
        if (this.estimate && this.estimate.Industries.length > 0) {
            const request = this.usCompanyClient.getFibCompanies(this.estimate.ValuationDate, this.estimate.Industries[0].SicId);
            this.spinner.while(request);
            request.once((data) => {
                this.primeManager.openDialog(BetaEditorCompanyListComponent, {
                    companies: data,
                    industries: this.estimate.Industries,
                    dataAsOf: this.estimate.ValuationDate,
                    tabname: document.getElementsByClassName("study-group active")[0].innerHTML
                });
            });
        }

    }

    public update(): Observable<void> {
        this.settings.scenario.setSelection(this.beta, this.betaType, InputType.None);
        this.dataStore.validateScenario(this.estimate, this.settings.scenario);
        const irp = this.settings.scenario.getSelectionAsContextualNumber(this.settings.operand.selectionType, InputType.None);
        this.modelChange.emit(irp);
        this.mask();

        // tslint-disable-next-line import/no-deprecated
        return of(void 0);
    }

    public save(): void { }

    public cancel(): void {
        this.beta = this.initialBeta.clone();
        this.update();
    }
}
