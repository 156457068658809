import { Util } from '@concurrency/core';
import { betaBackGround } from 'src/app/estimate/exports/summary-export/util/summary-helpers';
import { BetaComparbleCompanyResults } from '../beta.types';
import { Columns } from './Columns';

export function getSales(companies: BetaComparbleCompanyResults[]): any[] {
    const sales: any[] = [];
    if (companies.length > 0) {
        const referenceSheet = '=ComparableCompanies!';
        const referenceRow = '6';
        sales.push(
            { value: '' },
            { value: 'Sales' },
            { value: '' }
        );
        let index = 0;
        companies.forEach((company: BetaComparbleCompanyResults) => {
            sales.push({
                background: betaBackGround,
                textAlign: 'right',
                format: '#,##0.0',
                formula: referenceSheet + Columns[index] + referenceRow,
                value: company.Sales !== null ? company.Sales : '-'
            });
            index++;
        });
    }
    return sales;
}

export function getMarketCap(companies: BetaComparbleCompanyResults[]): any[] {
    const marketCaps: any[] = [];
    if (companies.length > 0) {
        const referenceSheet = '=ComparableCompanies!';
        const referenceRow = '12';
        marketCaps.push(
            { value: '' },
            { value: 'Market Cap' },
            { value: '' }
        );
        let index = 0;
        companies.forEach((company: BetaComparbleCompanyResults) => {
            marketCaps.push({
                background: betaBackGround,
                textAlign: 'right',
                format: '#,##0.0',
                formula: referenceSheet + Columns[index] + referenceRow,
                value: company.MarketCap !== null ? company.MarketCap : '-'
            });
            index++;
        });
    }
    return marketCaps;
}

export function getSalesWeighting(companies: BetaComparbleCompanyResults[]): any[] {
    const sales: any[] = [];
    const weighting: any[] = [];
    let sum: any;
    if (companies.length > 0) {
        weighting.push(
            { value: '' },
            { value: 'Sales Weighting' },
            { value: '' }
        );
        companies.forEach((company: BetaComparbleCompanyResults) => {
            if (company.Sales !== null) {
                sales.push(company.Sales);
            }
        });
        if (sales.length > 0) {
            sum = sales.reduce((acc, val) => acc + val);
        }
        companies.forEach((company: BetaComparbleCompanyResults) => {
            weighting.push(
                {
                    background: betaBackGround,
                    textAlign: 'right',
                    format: '0.00%',
                    // value: company.Sales !== null ? Util.round((company.Sales / sum) * 100) + '%' : '-'
                    value: company.Sales !== null ? ((Util.round(company.Sales) / sum * 100) / 100) : '-'
                }
            );
        });
    }
    return weighting;
}

export function getMarketCapWeighting(companies: BetaComparbleCompanyResults[]): any[] {
    const marketCaps: any[] = [];
    const weighting: any[] = [];
    let sum: any;
    if (companies.length > 0) {
        weighting.push(
            { value: '' },
            { value: 'Market Cap Weighting' },
            { value: '' }
        );
        companies.forEach((company: BetaComparbleCompanyResults) => {
            if (company.MarketCap !== null) {
                marketCaps.push(company.MarketCap);
            }
        });
        if (marketCaps.length > 0) {
            sum = marketCaps.reduce((acc, val) => acc + val);
        }
        companies.forEach((company: BetaComparbleCompanyResults) => {
            weighting.push(
                {
                    background: betaBackGround,
                    textAlign: 'right',
                    format: '0.00%',
                    // value: company.MarketCap !== null ? Util.round((company.MarketCap / sum) * 100) + '%' : '-'
                    value: company.MarketCap !== null ? (Util.round((company.MarketCap / sum) * 100) / 100) : '-'
                }
            );
        });
    }
    return weighting;
}
