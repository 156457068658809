import { Injectable } from '@angular/core';
import { Util } from '@concurrency/core';
import { Observable } from 'rxjs/internal/Observable';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Country } from 'src/app/_api/responses/country.response';
import { Industry } from 'src/app/_api/responses/us-industry.response';

@Injectable()
export class QueryBuilderService {
    public countries: Country[] = [];
    public industries: Industry[] = [];

    private MatchesUSZoneNullValues(industry: Industry): boolean {
        return industry.CurrencyCode === null && industry.Area === null;
    }

    private MatchesUSZone(industry: Industry): boolean {
        return industry.CurrencyCode === 'USD' && industry.Area === 'United States';
    }

    private baseFilterTypeAhead(industries: Industry[], cleanTerm: string): Industry[] {
        return industries.filter((industryValue) => {
            if (Util.isNullOrEmpty(industryValue.Sector)) {

                return false;

            }
            const industryCode = (!industryValue.GicIndustryCode) ? industryValue.SicIndustryCode : industryValue.GicIndustryCode;
            const displayName = `${industryCode} - ${industryValue.Sector}`.toLowerCase();
            const matchesDisplayName = displayName.includes(cleanTerm);
            const hasDash = cleanTerm.includes('-');
            const matchesCode = industryCode.startsWith(cleanTerm);
            const matchesName = industryValue.Sector.toLowerCase().includes(cleanTerm);

            return matchesCode || matchesName || (hasDash && matchesDisplayName);
        });
    }

    public CountriesTypeahead = (text: Observable<string>) => {
        const termMapper = (term: string) => {
            if (term === '') {
                return [];
            }

            const cleanTerm = term.toLowerCase().trim();

            return this.countries.filter((country) => {
                return country.CountryName.toLowerCase().includes(cleanTerm);
            }).slice(0, 10);
        };

        return text.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            map(termMapper)
        );
    }

    public IndustriesTypeahead = (text: Observable<string>) => {
        const termMapper = (term: string) => {
            if (term === '') {
                return [];
            }

            const cleanTerm = term.toLowerCase().trim();

            const results = this.baseFilterTypeAhead(this.industries, cleanTerm).sort((a, b) => parseFloat(a.SicIndustryCode) - parseFloat(b.SicIndustryCode) ||
                parseFloat(a.GicIndustryCode) - parseFloat(b.GicIndustryCode))
                .sort((a, b) => parseFloat(a.SicIndustryCode) - parseFloat(b.GicIndustryCode)).slice(0, 10);

            return Util.sortBy(results, (x) => {
                const code = (!x.GicIndustryCode) ? x.SicIndustryCode : x.GicIndustryCode;
                parseInt(code, 10);
            });
        };

        return text.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            map(termMapper)
        );
    }

    public IndustriesUSTypeahead = (text: Observable<string>) => {
        const termMapper = (term: string) => {
            if (term === '') {
                return [];
            }

            const cleanTerm = term.toLowerCase().trim();

            const results = this.baseFilterTypeAhead(this.industries, cleanTerm).filter((filteredIndustry) => {
                const matchesUSZone = this.MatchesUSZoneNullValues(filteredIndustry) || this.MatchesUSZone(filteredIndustry);

                return matchesUSZone;
            }).sort((a, b) => parseFloat(a.SicIndustryCode) - parseFloat(b.SicIndustryCode) ||
                parseFloat(a.GicIndustryCode) - parseFloat(b.GicIndustryCode))
                .sort((a, b) => parseFloat(a.SicIndustryCode) - parseFloat(b.GicIndustryCode)).slice(0, 10);

            return Util.sortBy(results, (x) => {
                const code = (!x.GicIndustryCode) ? x.SicIndustryCode : x.GicIndustryCode;
                parseInt(code, 10);
            });
        };

        return text.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            map(termMapper)
        );
    }
}
