import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Observable } from 'rxjs/internal/Observable';
import { IntlErpClient } from 'src/app/_api/clients/intlErp.client';
import { IntlData } from 'src/app/_api/responses/intl-data.response';
import { GetIntlErp } from './intl-erp-actions';
import { Injectable } from '@angular/core';

export type IntlErpModel = IntlData[] | undefined;

@State<IntlErpModel>({
    name: 'intlErp',
    defaults: undefined
})

@Injectable()
export class IntlErpState {

    @Selector()
    public static get(state: IntlErpModel): IntlData[] | undefined {
        return state;
    }
    constructor(private intlErpClient: IntlErpClient) { }

    @Action(GetIntlErp)
    public getIntlErp(context: StateContext<IntlErpModel>, action: GetIntlErp): Observable<void> {
        return this.intlErpClient.read(action.countryId, action.dataAsOf).once((response) => {
            context.setState(response);
        });
    }
}
