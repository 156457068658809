import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'heat-map-jvector',
    templateUrl: './heat-map-jvector.component.html',
})
export class HeatMapJvectorComponent {

    constructor(private sanitizer: DomSanitizer) { }



    embeddedAppUrl = this.sanitizer.bypassSecurityTrustResourceUrl(environment.urls.heatMap); // URL of the embedded app



    onIframeLoad() {
        const iframe = document.getElementById('embeddedAppIframe') as HTMLIFrameElement;
        const message = { backgroundColor: '#newColorCode' }; // Your desired background color
        iframe.contentWindow ? iframe.contentWindow.postMessage(message, environment.urls.heatMap) : null;
    }
}


