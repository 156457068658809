import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

type ExitState = 'save' | 'dismiss';

// EventManager is for managing events, not data.
// To this end, all events must be of type Subject - not BehaviorSubject.
@Injectable()
export class EventManager {
    private _recalculate = new Subject();
    private _resummarize = new Subject<number>();
    private _editorExitState = new Subject<ExitState>();
    private _editorValidity = new Subject<boolean>();
    private _editorActivity = new Subject<boolean>();
    private _allowExport = new Subject<boolean>();
    private _canScrollRight = new Subject<boolean>();
    private _canScrollLeft = new Subject<boolean>();

    public recalculate = this._recalculate.asObservable();
    public resummarize = this._resummarize.asObservable();
    public editorExitState = this._editorExitState.asObservable();
    public editorValidity = this._editorValidity.asObservable();
    public editorActivity = this._editorActivity.asObservable();
    public allowExport = this._allowExport.asObservable();
    public canScrollRight = this._canScrollRight.asObservable();
    public canScrollLeft = this._canScrollLeft.asObservable();

    public triggerRecalculate(): void {
        this._recalculate.next();
    }

    public triggerResummarize(pageTop: number): void {
        this._resummarize.next(pageTop);
    }

    public triggerEditorExit(state: ExitState): void {
        this._editorExitState.next(state);
    }

    public triggerEditorValidity(valid: boolean): void {
        this._editorValidity.next(valid);
    }

    public triggerEditorActivity(active: boolean): void {
        this._editorActivity.next(active);
    }

    public triggerAllowExport(allow: boolean): void {
        this._allowExport.next(allow);
    }

    public setCanScrollRight(canScrollRight: boolean): void {
        this._canScrollRight.next(canScrollRight);
    }

    public setCanScrollLeft(canScrollLeft: boolean): void {
        this._canScrollLeft.next(canScrollLeft);
    }
}
