import { Injectable } from '@angular/core';
import { ApiClient } from '@concurrency/angular';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Industry, IndustryBetas, USDebtBetas } from '../responses/us-industry.response';
import { Auth0ApiClient } from '../api.client';
import { DebtBetaTrendsOverTime } from 'src/app/costofcapital-inputs/models/debtBetaTrendsOverTime';

@Injectable()
export class USIndustryClient extends Auth0ApiClient {
    protected baseUrl = `${environment.urls.navigatorApi}/api`;

    public read(dataAsOf: string): Observable<Industry[]> {
        return this.get(`usindustry?dataAsOf=${dataAsOf}`);
    }

    public getUsiIndustries(dataAsOf: string): Observable<Industry[]> {
        return this.get(`usindustry/Usi?dataAsOf=${dataAsOf}`);
    }

    public getUSIndustryBetas(dataAsOf: string): Observable<IndustryBetas[]> {
        return this.get(`usindustry/ViewInputBetas?dataAsOf=${dataAsOf}`);
    }

    public getUSDebtBetas(dataAsOf: string): Observable<USDebtBetas[]> {
        return this.get(`usindustry/USDebtBetas?dataAsOf=${dataAsOf}`);
    }

    public getDebtBetaTrendOverTime(dataAsOf: string): Observable<DebtBetaTrendsOverTime[]> {
        return this.get(`usindustry/GetDebtBetasTrendsOverTime?dataAsOf=${dataAsOf}`);
    }

}
