import { Component, Input } from '@angular/core';
import { StatisticsGroup } from './models/statistics-group.model';

@Component({
    selector: 'statistics',
    templateUrl: './statistics.component.html'
})
export class StatisticsComponent {
    @Input() public statistics!: StatisticsGroup;
}
