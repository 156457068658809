import { Injectable } from '@angular/core';
import { ApiClient } from '@concurrency/angular';
import { Observable } from 'rxjs';
import { ContainerTypeEnum } from 'src/app/home/resources-library/enum/container-type.enum';
import { environment } from 'src/environments/environment';
import { Auth0ApiClient } from '../api.client';
import { ResourceFileType } from '../enums/resource-file-type';

@Injectable()
export class FilesClient extends Auth0ApiClient {
    protected baseUrl = `${environment.urls.navigatorApi}`;

    public getResources(resourceFileType: ResourceFileType): Observable<string> {
        return this.get(`files/resources/list?resourceFileType=${resourceFileType}`);
    }

    public getResourceFile(fileName: string): Observable<string> {
        return this.post(`files/resources?fileName=${fileName}`, {});
    }
    public getStorageFile(fileName: string, typeContainer: ContainerTypeEnum): Observable<string> {
        return this.post(`files/storage/file?fileName=${encodeURIComponent(fileName)}&typeContainer=${typeContainer}`, {});
    }
    public getFile(fileName: string): Observable<string> {
        return this.post(`files?fileName=${fileName}`, {});
    }
    public getDashboardFiles(fileName: string): Observable<string> {
        return this.post(`dashboard?fileName=${fileName}`, {});
    }
}
