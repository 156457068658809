import { ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'terms',
    template: `
<div class="modal-container">
    <div class="modal-header">
        <span class="modal-title" [innerHTML]="title"></span>
        <span class="modal-dismiss" *ngIf="hasCancelButton" (click)="activeModal.dismiss()">&times;</span>
    </div>
    <div class="modal-body" #body>
        <ng-content></ng-content>
    </div>
    <div class="modal-footer">
        <button type="submit"
                class="modal-submit"
                (click)="activeModal.close(selection)"
                [disabled]="selection==null">
            {{submitButtonText}}
        </button>
        <button type="reset"
                class="modal-cancel"
                *ngIf="hasCancelButton"
                (click)="activeModal.dismiss()">
            {{cancelButtonText}}
        </button>
        
    </div>
</div>
`
})
export class TermsComponent implements OnInit, OnChanges, OnDestroy {
    @Input() public title?: string;
    @Input() public selection: any;
    @Input() public cancellation: any;
    @Input() public submitButtonText = 'Save';
    @Input() public cancelButtonText = 'Cancel';
    @Input() public hasCancelButton = true;
    @ViewChild('body') public body: ElementRef = new ElementRef(0);

    constructor(
        public activeModal: NgbActiveModal,
        private changeDetector: ChangeDetectorRef
    ) { }

    public ngOnInit(): void {
        const input = this.body.nativeElement.querySelector('input');
        if (input) {
            input.focus();
        }
    }

    public ngOnChanges(): void {
        if (this.cancellation) {
            this.activeModal.dismiss();
        }
    }

    @HostListener('document:keypress', ['$event'])
    public keypress(e: KeyboardEvent): void {
        if (e.key === 'Enter') {
            if (this.selection) {
                this.activeModal.close(this.selection);
            }
        }
    }

    // TODO: Figure out this modal being destroyed causes a view error
    // NOTE: The below code fails to resolve the problem
    public ngOnDestroy(): void {
        this.changeDetector.detach();
    }
}
