import { FooterLink } from './footer-link';

export class FooterLinkUtil {

    public static readonly ExcelAddIn: FooterLink = {
        footerLinkName: 'Excel Add-in',
        footerLinkUrl: 'ExcelAddinEnableInstructions.pdf'
    };

    public static readonly Contact: FooterLink = {
        footerLinkName: 'Contact',
        footerLinkUrl: 'NavigatorDataSources_Final.pdf'
    };

    public static readonly SubmitFeedback: FooterLink = {
        footerLinkName: 'Submit Feedback',
        footerLinkUrl: 'https://www.surveymonkey.com/r/5WXP7TL'
    };

    public static readonly DataSources: FooterLink = {
        footerLinkName: 'Data Sources',
        footerLinkUrl: 'NavigatorDataSources_Final.pdf'
    };

    public static readonly Support: FooterLink = {
        footerLinkName: 'Technology Support',
        footerLinkUrl: 'CostofCapitalNavigatorTechFAQ.pdf'
    };

    public static readonly Security: FooterLink = {
        footerLinkName: 'Security',
        footerLinkUrl: 'Navigator_Security.pdf'
    };

    public static readonly About: FooterLink = {
        footerLinkName: 'About Kroll',
        footerLinkUrl: 'https://www.kroll.com'
    };

    public static readonly Legal: FooterLink = {
        footerLinkName: 'Legal',
        footerLinkUrl: 'https://www.kroll.com/en/terms-of-use'
    };

    public static readonly Privacy: FooterLink = {
        footerLinkName: 'Privacy',
        footerLinkUrl: 'https://www.kroll.com/en/privacy-policy'
    };

    public static readonly Cookies: FooterLink = {
        footerLinkName: 'Cookies',
        footerLinkUrl: 'https://www.kroll.com/en/cookies-policy'
    };

    public static readonly Disclosures: FooterLink = {
        footerLinkName: 'Disclosures',
        footerLinkUrl: 'https://www.kroll.com/en/disclosure'
    };

    public static readonly TermsAndConditions: FooterLink = {
        footerLinkName: 'Terms & Conditions',
        footerLinkUrl: 'terms.html'
    };

    public static readonly ReleaseLog: FooterLink = {
        footerLinkName: 'Data Release Log',
        footerLinkUrl: 'https://vasdc8grscoc.blob.core.windows.net/files/ReleaseLogs/TEMPLATE_NavigatorReleaseUpdate_Master.pdf'
    };

    public static readonly DataReleaseSchedule: FooterLink = {
        footerLinkName: 'Data Release Schedule',
        footerLinkUrl: 'Data-Release-Schedule.pdf'
    };

    public static readonly BannerMessage: FooterLink = {
        footerLinkName: 'Banner Message',
        footerLinkUrl: 'Banner_Message.html'
    };
}
