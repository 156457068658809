import { headerCellHeight, headerFontSize, krollblue, krollshadow } from '../util/summary-helpers';

// TODO figure out this any type
export function summaryExcelGlossary(): any {
    return [
        {
            height: headerCellHeight,
            cells: [
                {
                    bold: 'true',
                    value: 'Glossary',
                    color: krollblue,
                    fontSize: headerFontSize,
                    borderBottom: { size: 1, color: krollshadow },
                    colSpan: 6
                }
            ]
        },
        {
            cells: [
                { value: 'Ke' },
                { value: 'Cost of equity capital' }
            ]
        },
        {
            cells: [
                { value: 'Kd' },
                { value: 'Cost of debt capital' }
            ]
        },
        {
            cells: [
                { value: 'Rf' },
                { value: 'Risk-free rate' }
            ]
        },
        {
            cells: [
                { value: 'ERP' },
                { value: 'Equity (market) risk premium' }
            ]
        },
        {
            cells: [
                { value: 'B' },
                { value: 'Beta' }
            ]
        },
        {
            cells: [
                { value: 'RPi' },
                { value: 'Industry risk premium' }
            ]
        },
        {
            cells: [
                { value: 'RPs' },
                { value: 'Size Premium' }
            ]
        },
        {
            cells: [
                { value: 'RPm+s' },
                { value: 'Risk Premium Over the Risk-Free Rate (size study)' }
            ]
        },
        {
            cells: [
                { value: 'RPm+c' },
                { value: 'Risk Premium Over the Risk-Free Rate (risk study)' }
            ]
        },
        {
            cells: [
                { value: 'We' },
                { value: `Weight of equity in subject company's capital structure` }
            ]
        },
        {
            cells: [
                { value: 'Wd' },
                { value: `Weight of debt in subject company's capital structure` }
            ]
        },
        {
            cells: [
                { value: 'EBITDA' },
                { value: 'Earnings before interest, taxes, depreciation, and amortization' }
            ]
        },
        {
            cells: [
                { value: 'z-Score' },
                {
                    value: `Altman z-Score is an accounting-data-based method designed to assess financial
condition and developed originally for assessing the likelihood of bankruptcy.`
                }
            ]
        },
        {
            cells: [
                { value: 'Coefficient of Variation' },
                { value: 'Standard Deviation divided by the mean' }
            ]
        }
    ];
}
