import { Component, OnInit } from '@angular/core';
import { Spinner, SubscriberEntity } from '@concurrency/angular';
import { Select } from '@ngxs/store';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { UserClient } from 'src/app/_api/clients/user.client';
import { EstimateType } from 'src/app/_api/enums/estimate-type';
import { Estimate } from 'src/app/_navigator/data/model/estimate.model';
import { EstimatesListState } from 'src/app/_navigator/estimates-list-store/estimates-list-state';
import { AccessType } from 'src/app/_navigator/user/enum/access-type';

@Component({
    selector: 'us-estimates',
    templateUrl: './us-estimates.component.html'
})
export class UsEstimatesComponent extends SubscriberEntity implements OnInit {
    private _access = new BehaviorSubject<AccessType | undefined>(undefined);
    public moduleEstimates!: Estimate[] | undefined;
    public access = this._access.asObservable();
    public hideestimates = false;
    @Select(EstimatesListState.get) public estimates!: Observable<Estimate[] | undefined>;

    constructor(private spinner: Spinner, private userProfileClient: UserClient) {
        super();
    }

    public ngOnInit(): void {
        this.estimates.onceDefined((estimates: Estimate[]) => {
            if (estimates.length > 0) {
                this.moduleEstimates = estimates.filter((x) => x.EstimateType === EstimateType.USEstimate);
            }
        });
        const profileRequest = this.userProfileClient.read();
        this.spinner.while(profileRequest);
        profileRequest.onceDefined((profile) => {
            for (const i of profile.Subscriptions) {
                if (i.Type === 'CocBasic' || i.Type === 'CocTrial' || i.Type === 'CocPro' || i.Type === 'CocEnterprise') {
                    const isAuthorized = new Date(i.End) > new Date();
                    this.hideestimates = isAuthorized;
                }
            }
        });
    }
}
