import { Component, Input } from '@angular/core';
import { EquationWrapper } from '../../data/model/equation-wrapper.model';

@Component({
    selector: 'equation-wrapper',
    templateUrl: './equation-wrapper.component.html'
})
export class EquationWrapperComponent {
    @Input() public wrapper!: EquationWrapper;
}
