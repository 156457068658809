import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SubscriberEntity } from '@concurrency/angular';
import { takeUntil } from 'rxjs/operators';

enum Group { Crsp, Risk, Hfr }

@Component({
    selector: 'studies',
    templateUrl: './studies.component.html'
})
export class StudiesComponent extends SubscriberEntity implements OnInit {
    private currentRoute!: string;
    private routeProgression = [
        '/estimate/inputs',
        '/estimate/studies/crsp/capm',
        '/estimate/studies/crsp/buildup',
        '/estimate/studies/risk/capm',
        '/estimate/studies/risk/buildup1',
        '/estimate/studies/risk/buildup2',
        '/estimate/studies/risk/buildup3',
        '/estimate/studies/hfr/capm',
        '/estimate/studies/hfr/buildup',
        '/estimate/results'
    ];

    public activeGroup: Group = Group.Crsp;
    public Group = Group;

    constructor(private router: Router) {
        super();
    }

    private setupTab(): void {
        this.currentRoute = this.router.url;

        if (this.router.url.includes('/estimate/studies/risk')) {
            this.activeGroup = Group.Risk;
        } else if (this.router.url.includes('/estimate/studies/hfr')) {
            this.activeGroup = Group.Hfr;
        } else {
            this.activeGroup = Group.Crsp;
        }
    }

    public ngOnInit(): void {
        this.setupTab();
        // TODO: Obviate takeUntil by using Async pipes and local Observable streams
        this.router.events.pipe(takeUntil(this.destroyed)).while(() => this.setupTab());
    }

    public saveAndContinue(): void {
        const nextRouteIndex = this.routeProgression.indexOf(this.currentRoute) + 1;
        const nextRoute = this.routeProgression[nextRouteIndex];
        this.router.navigate([nextRoute]);
    }

    public finishNow(): void {
        this.router.navigate(['estimate/results']);
    }

    public previous(): void {
        const previousRouteIndex = this.routeProgression.indexOf(this.currentRoute) - 1;
        if (previousRouteIndex < 0) {
            return;
        }

        const previousRoute = this.routeProgression[previousRouteIndex];
        this.router.navigate([previousRoute]);
    }

    public routeTo(group: Group): void {
        if (group === Group.Hfr) {
            this.router.navigate(['/estimate/studies/hfr/']);
        } else if (group === Group.Risk) {
            this.router.navigate(['/estimate/studies/risk/']);
        } else {
            this.router.navigate(['/estimate/studies/crsp/']);
        }
    }
}
