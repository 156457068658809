import * as moment from 'moment';

import { Component, OnInit, ViewChild } from '@angular/core';
import { List } from '@concurrency/angular';
import { Util } from '@concurrency/core';
import { Dialog } from 'primeng/dialog';
import { DataStore } from 'src/app/_navigator/data/store/data.store';
import { CompanyType, UsCompany, UsCompanyData } from '../../_api/responses/us-company.response';
import { CompanyList } from '../data/company-list';
import { CompanyListItem } from '../data/company-list-item.model';
import { CompanyTypeName } from '../data/companyTypeName';

@Component({
    templateUrl: './company-list.component.html',
    styleUrls: ['./company-list.component.scss']
})
export class CompanyListComponent implements OnInit {
    public moment = moment;
    public Util = Util;
    public display = true;
    public sicCode!: string;
    public companyData!: UsCompanyData;
    public companyList!: List<UsCompany>;
    public companyType: CompanyType = CompanyType.Default;
    public companyListHeaders: CompanyListItem[] = CompanyList.companyList;
    public companyTypeName: CompanyTypeName = CompanyTypeName.default;
    public dialogHeader!: string;

    @ViewChild(Dialog) public dialog!: Dialog;

    constructor(
        protected dataStore: DataStore
    ) { }

    public ngOnInit(): void {
        this.companyList = new List(this.companyData.Companies, (data) => {
            if (this.companyType === CompanyType.Default) {
                return data.Type !== CompanyType.HighFinancialRisk;
            } else {
                return data.Type === this.companyType;
            }
        });
        this.dialogHeader = `Company List - SIC ${this.sicCode}`;
        this.setCompanyType(CompanyType.Default);
    }

    public setCompanyType(type: CompanyType): void {
        this.companyType = type;
        this.companyTypeName = CompanyList.find(type);
        this.companyList.filter('-'); // NOTE: Need to pass a non-null non-empty string or it won't filter
        this.sortBy('Sales'); // NOTE: Need to switch sort types or it will just call .reverse()
        this.sortBy('Name');
        if (this.companyList.filtered.length === 0) {
            this.dialogHeader = `Company List - SIC ${this.sicCode}`;
        } else {
            this.dialogHeader = `Company List - SIC ${this.sicCode} Data as of ${moment(this.companyData.DataAsOf).format('MM/DD/YYYY')}`;
        }
    }

    public sortBy(field: string): void {
        this.companyList.sort(field);
    }
}
