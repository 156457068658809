import * as moment from 'moment';

import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CollapsibleTextPosition, Spinner, SubscriberEntity } from '@concurrency/angular';
import { PDFExportComponent } from '@progress/kendo-angular-pdf-export';
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Estimate } from 'src/app/_navigator/data/model/estimate.model';
import { ExportService } from 'src/app/_navigator/data/service/export.service';
import { DataStore } from 'src/app/_navigator/data/store/data.store';
import { SummaryEquation } from 'src/app/_navigator/summary/summary-equation.model';
import { SummaryGroup } from 'src/app/_navigator/summary/summary-group.model';
import { UserStore } from 'src/app/_navigator/user/store/user.store';
import { Summary } from './summary';

@Component({
    selector: 'summary',
    templateUrl: './summary.component.html'
})
export class SummaryComponent extends SubscriberEntity implements OnInit, OnDestroy {
    @ViewChild('pdf') public pdf!: PDFExportComponent;
    public estimate?: Estimate;
    public summary?: Summary;
    public modifiedDate?: string;
    public licenseeName?: string;
    public collapse: any;
    public resummarizing = false;
    public inputPanes: SummaryGroup[][] = [];

    public readonly TextPosition = CollapsibleTextPosition;
    public readonly ObjectKeys = Object.keys;

    constructor(
        private spinner: Spinner,
        private dataStore: DataStore,
        private userStore: UserStore,
        private exportService: ExportService
    ) { super(); }

    // TODO: Implement strong typing for this method's return type
    private getCollapseState(summary: Summary, estimate: Estimate): any {
        const savedState = localStorage.getItem(`${estimate.Id}.collapse`); // `

        if (savedState) {
            return JSON.parse(savedState);
        }

        const collapse: any = {
            Assumptions: false
        };

        for (const equityGroup of summary.equityGroups) {
            collapse[equityGroup.name] = false;
            for (const studyGroup of equityGroup.studyGroups) {
                collapse[`${equityGroup.name} ${studyGroup.name}`] = false; // `
                for (const study of studyGroup.studies) {
                    collapse[`${equityGroup.name} ${studyGroup.name} ${study.name}`] = true; // `
                    for (const scenario of study.scenarios) {
                        collapse[`${equityGroup.name} ${studyGroup.name} ${study.name} ${scenario.name}`] = false; // `
                    }
                }
            }
        }

        return collapse;
    }

    public ngOnInit(): void {
        document.documentElement.style.setProperty('list-style-type', 'none');
        this.dataStore.triggerAllowExport(true);
        this.exportService.setPdf(this.pdf);

        // TODO: Obviate takeUntil by using Async pipes and local Observable streams
        const request = combineLatest([this.dataStore.estimate, this.dataStore.summary]).pipe(takeUntil(this.destroyed));
        request.subscribe((data) => {
            const estimate = data[0];
            const summary = data[1];

            if (estimate == null || summary == null) {
                return;
            }

            this.estimate = estimate;
            this.summary = summary;

            // TODO: Put this in as part of the summary
            // Calculate the modified date for the PDF watermark
            const date = moment(estimate.Updated).format('MM/DD/YYYY');
            const time = moment(estimate.Updated).format('h:mm a');

            this.modifiedDate = `${date} at ${time}`;
            this.collapse = this.getCollapseState(summary, estimate);

            this.inputPanes = [
                [
                    summary.generalInputs,
                    summary.sizeMeasures,
                    summary.riskMeasures
                ], [
                    summary.crspStudy,
                    summary.rprStudy,
                    summary.hfrStudy
                ]
            ];
        });

        // TODO: Obviate takeUntil by using Async pipes and local Observable streams
        this.userStore.user.pipe(takeUntil(this.destroyed)).whileDefined((profile) => {
            this.licenseeName = profile.FullName;
            if (profile.CompanyName) {
                this.licenseeName += `, ${profile.CompanyName || ''}`;
            }
        });

        // TODO: Obviate takeUntil by using Async pipes and local Observable streams
        this.dataStore.resummarize.pipe(takeUntil(this.destroyed)).while((pageTop) => {
            this.spinner.begin(0);
            this.resummarizing = true;
            setTimeout(() => {
                window.scrollTo(0, pageTop);
                this.spinner.end();
                this.resummarizing = false;
            }, 1200);
        });
    }

    public setExclusion(estimate: Estimate, equation: SummaryEquation, isExcluded: boolean): void {
        equation.equation.IsExcluded = isExcluded;
        this.dataStore.updateEstimate(estimate);
        this.dataStore.triggerResummarize(window.pageYOffset);
    }

    public storeCollapseState(estimate: Estimate): void {
        localStorage.setItem(`${estimate.Id}.collapse`, JSON.stringify(this.collapse)); // `
    }

    public ngOnDestroy(): void {
        this.dataStore.triggerAllowExport(false);
        super.ngOnDestroy();
    }
}
