import { Routes as AngularRoutes } from '@angular/router';
import { IsSubscriberGuard } from '../_navigator/is-subscriber.guard';
import { BenchmarkingComponent } from '../benchmarking/benchmarking.component';
import { IndustryInputsComponent } from '../benchmarking/components/industry-inputs.component';
import { IntlBenchmarkingComponent } from '../benchmarking/intlbenchmarking.component';
import { BetaHomeComponent } from '../beta/beta-home/beta-home.component';
import { BetaComponent } from '../beta/beta.component';
import { EstimateComponent } from '../estimate/estimate.component';
import { InputsComponent } from '../estimate/inputs.component';
import { ResultsComponent } from '../estimate/results.component';
import { SummaryComponent } from '../estimate/results/summary.component';
import { WaccComponent } from '../estimate/results/wacc.component';
import { StudiesComponent } from '../estimate/studies.component';
import { CrspBuildupComponent } from '../estimate/studies/crsp/crsp-buildup.component';
import { CrspCapmComponent } from '../estimate/studies/crsp/crsp-capm.component';
import { CrspComponent } from '../estimate/studies/crsp/crsp.component';
import { HfrBuildupComponent } from '../estimate/studies/hfr/hfr-buildup.component';
import { HfrCapmComponent } from '../estimate/studies/hfr/hfr-capm.component';
import { HfrComponent } from '../estimate/studies/hfr/hfr.component';
import { RiskBuildupOneComponent } from '../estimate/studies/risk/risk-buildup1.component';
import { RiskBuildupTwoComponent } from '../estimate/studies/risk/risk-buildup2.component';
import { RiskBuildupThreeComponent } from '../estimate/studies/risk/risk-buildup3.component';
import { RiskCapmComponent } from '../estimate/studies/risk/risk-capm.component';
import { RiskComponent } from '../estimate/studies/risk/risk.component';
import { BuyNowComponent } from '../home/buy-now/buy-now.component';
import { ContactUsComponent } from '../home/contact-us/contact-us.component';
import { CustomerLoginComponent } from '../home/customer-login.component';
import { DashboardComponent } from '../home/dashboard/dashboard.component';
import { BetaEstimatesComponent } from '../home/estimates/beta-estimates.component';
import { EstimatesComponent } from '../home/estimates/estimates.component';
import { IntlEstimatesComponent } from '../home/estimates/intl-estimates.component';
import { UsEstimatesComponent } from '../home/estimates/us-estimates.component';
import { HomeComponent } from '../home/home.component';
import { ProductNewsComponent } from '../home/product-news/product-news.component';
import { PurchaseComponent } from '../home/purchase.component';
import { RedirectComponent } from '../home/redirect/redirect.component';
import { ResourcesLibraryComponent } from '../home/resources-library/resources-library.component';
import { TearSheetComponent } from '../home/tear-sheet/tear-sheet.component';
import { CostOfDebtSummaryComponent } from '../international-estimate/cod-summary.component';
import { CodSummaryCapitalComponent } from '../international-estimate/cod-summary/cod-summary-capital.component';
import { CodSummaryConclusionComponent } from '../international-estimate/cod-summary/cod-summary-conclusion.component';
import { CodSummaryFisherEffectComponent } from '../international-estimate/cod-summary/cod-summary-fisher-effect.component';
import { CostOfEquityInputsComponent } from '../international-estimate/coe-inputs.component';
import { CostOfEquitySummaryComponent } from '../international-estimate/coe-summary.component';
import { CoeSummaryCapmComponent } from '../international-estimate/coe-summary/coe-summary-capm.component';
import { CoeSummaryConclusionComponent } from '../international-estimate/coe-summary/coe-summary-conclusion.component';
import { CoeSummaryFisherEffectComponent } from '../international-estimate/coe-summary/coe-summary-fisher-effect.component';
import { CrpSummaryComponent } from '../international-estimate/crp-summary.component';
import { FisherEffectComponent } from '../international-estimate/fisher-effect.component';
import { InternationalEstimateComponent } from '../international-estimate/international-estimate.component';
import { InternationalInputsComponent } from '../international-estimate/international-inputs.component';
import { WaccInputsComponent } from '../international-estimate/wacc-inputs.component';
import { WaccSummaryComponent } from '../international-estimate/wacc-summary.component';
import { WaccSummaryCapitalComponent } from '../international-estimate/wacc-summary/wacc-summary-capital.component';
import { WaccSummaryConclusionComponent } from '../international-estimate/wacc-summary/wacc-summary-conclusion.component';
import { CostofCapitalInputsComponent } from '../costofcapital-inputs/components/costof-capital-inputs/costof-capital-inputs.component';
import { SizePremiumComponent } from '../costofcapital-inputs/components/size-premium/size-premium.component';
import { RiskPremiumOverRiskFreeRateComponent } from '../costofcapital-inputs/components/risk-premium-over-risk-free-rate/risk-premium-over-risk-free-rate.component';
import { RiskFreeRatesAndEquityRiskPremiumComponent } from '../costofcapital-inputs/components/risk-free-rates-and-equity-risk-premium/risk-free-rates-and-equity-risk-premium.component';
import { BetasandIndustryRiskPremiumComponent } from '../costofcapital-inputs/components/betas-industry-risk-premium-inputs/betas-industry-risk-premium.component';
import { DatapointsOfferdComponent } from '../home/datapoints-offerd/datapoints-offerd.component';
import { HeatMapJvectorComponent } from '../home/heat-map-jvector/heat-map-jvector.component';
import { CountryRiskPremiumRelativeVoltalityComponent } from '../costofcapital-inputs/components/country-risk-premium-relative-voltality/country-risk-premium-relative-voltality.component';

/*
    The Angular compilation process parses this file directly as part of the AoT process.
    As a result, it is strictly necessary that routes be defined as hard-coded strings.
/*

// NOTE: To remove the landing page, comment out the landing route & nothing else

/* Routing */
export const routeConfig: AngularRoutes = [
    // { path: 'landing', component: LandingComponent, canActivate: [IsNotSubscriberGuard] },
    { path: 'landing', component: RedirectComponent },
    { path: 'customer', component: CustomerLoginComponent },

    { path: 'purchase', component: PurchaseComponent },
    {
        path: '', component: HomeComponent, canActivate: [IsSubscriberGuard], children: [
            { path: '', component: DashboardComponent },
            { path: 'dashboard', component: DashboardComponent },
            { path: 'contact-us', component: ContactUsComponent },
            { path: 'buy-now', component: BuyNowComponent },
            { path: 'resources-library', component: ResourcesLibraryComponent },
            { path: 'product-news', component: ProductNewsComponent },
            { path: 'dataPoints-offered', component: DatapointsOfferdComponent },
            { path: 'heatmap', component: HeatMapJvectorComponent },
            {
                path: 'coc-inputs', component: CostofCapitalInputsComponent, children: [
                    { path: '', redirectTo: 'rfr-and-erp', pathMatch: 'prefix' },
                    { path: 'rfr-and-erp', component: RiskFreeRatesAndEquityRiskPremiumComponent },
                    { path: 'size-premium', component: SizePremiumComponent },
                    { path: 'risk-premium', component: RiskPremiumOverRiskFreeRateComponent },
                    { path: 'betas', component: BetasandIndustryRiskPremiumComponent },
                    { path: 'country-risk-premium', component: CountryRiskPremiumRelativeVoltalityComponent }
                ]
            },
            {
                path: 'estimates', component: EstimatesComponent, children: [
                    { path: '', redirectTo: 'us-estimates', pathMatch: 'prefix' },
                    { path: 'us-estimates', component: UsEstimatesComponent },
                    { path: 'intl-estimates', component: IntlEstimatesComponent },
                    { path: 'beta-estimates', component: BetaEstimatesComponent }
                ]
            },
            {
                path: 'estimate', component: EstimateComponent, children: [
                    { path: '', redirectTo: 'inputs', pathMatch: 'prefix' },
                    { path: 'inputs', component: InputsComponent },
                    {
                        path: 'studies', component: StudiesComponent, children: [
                            { path: '', redirectTo: 'crsp', pathMatch: 'prefix' },
                            {
                                path: 'crsp', component: CrspComponent, children: [
                                    { path: '', redirectTo: 'capm', pathMatch: 'prefix' },
                                    { path: 'capm', component: CrspCapmComponent },
                                    { path: 'buildup', component: CrspBuildupComponent, }
                                ]
                            },
                            {
                                path: 'risk', component: RiskComponent, children: [
                                    { path: '', redirectTo: 'capm', pathMatch: 'prefix' },
                                    { path: 'capm', component: RiskCapmComponent },
                                    { path: 'buildup1', component: RiskBuildupOneComponent },
                                    { path: 'buildup2', component: RiskBuildupTwoComponent },
                                    { path: 'buildup3', component: RiskBuildupThreeComponent }
                                ]
                            },
                            {
                                path: 'hfr', component: HfrComponent, children: [
                                    { path: '', redirectTo: 'capm', pathMatch: 'prefix' },
                                    { path: 'capm', component: HfrCapmComponent },
                                    { path: 'buildup', component: HfrBuildupComponent, }
                                ]
                            }
                        ]
                    },
                    {
                        path: 'results', component: ResultsComponent, children: [
                            { path: '', redirectTo: 'summary', pathMatch: 'prefix' },
                            { path: 'summary', component: SummaryComponent },
                            { path: 'wacc', component: WaccComponent }
                        ]
                    },
                    { path: 'tear-sheet', component: TearSheetComponent }
                ]
            },
            {
                path: 'international-estimate', component: InternationalEstimateComponent, children: [
                    { path: '', redirectTo: 'inputs', pathMatch: 'prefix' },
                    { path: 'inputs', component: InternationalInputsComponent },
                    { path: 'coe-inputs', component: CostOfEquityInputsComponent },
                    { path: 'wacc-inputs', component: WaccInputsComponent },
                    { path: 'crp-summary', component: CrpSummaryComponent },
                    { path: 'fisher-effect', component: FisherEffectComponent },
                    {
                        path: 'coe-summary', component: CostOfEquitySummaryComponent, children: [
                            { path: '', redirectTo: 'capm', pathMatch: 'prefix' },
                            { path: 'capm', component: CoeSummaryCapmComponent },
                            { path: 'fisher-effect', component: CoeSummaryFisherEffectComponent },
                            { path: 'crp', component: CoeSummaryConclusionComponent }
                        ]
                    },
                    {
                        path: 'cod-summary', component: CostOfDebtSummaryComponent, children: [
                            { path: '', redirectTo: 'capital', pathMatch: 'prefix' },
                            { path: 'capital', component: CodSummaryCapitalComponent },
                            { path: 'fisher-effect', component: CodSummaryFisherEffectComponent },
                            { path: 'conclusion', component: CodSummaryConclusionComponent }
                        ]
                    },
                    {
                        path: 'wacc-summary', component: WaccSummaryComponent, children: [
                            { path: '', redirectTo: 'capital', pathMatch: 'prefix' },
                            { path: 'capital', component: WaccSummaryCapitalComponent },
                            { path: 'conclusion', component: WaccSummaryConclusionComponent }
                        ]
                    }
                ]
            },
            {
                path: 'industry-benchmarking', component: BenchmarkingComponent, children: [
                    { path: '', redirectTo: 'inputs', pathMatch: 'prefix' },
                    {
                        path: 'inputs', component: IndustryInputsComponent, children: [
                            { path: 'tear-sheet', component: TearSheetComponent }
                        ]
                    },

                ]
            },
            // {
            //     path: 'intlindustry-benchmarking', component: IntlBenchmarkingComponent, children: [
            //         { path: '', redirectTo: 'inputs', pathMatch: 'prefix' },
            //         { path: 'inputs', component: IndustryInputsComponent },
            //         { path: 'tear-sheet', component: TearSheetComponent }
            //     ]
            // },
            {
                path: 'beta', component: BetaComponent, children: [
                    { path: '', redirectTo: 'betas', pathMatch: 'prefix' },
                    { path: 'betas', component: BetaHomeComponent }
                ]
            }
        ]
    },
    { path: '**', redirectTo: '' }
];
