import { ErrorHandlerService } from 'src/error-handler.service';
import { MyMonitoringService } from 'src/logging.service';
import { BetaClient } from '../_api/clients/beta.client';
import { BetaEstimateClient } from '../_api/clients/betaEstimate.client';
import { CompanyClient } from '../_api/clients/company.client';
import { CostOfEquityClient } from '../_api/clients/costOfEquity.client';
import { CountryClient } from '../_api/clients/country.client';
import { CurrencyClient } from '../_api/clients/currency.client';
import { EstimateClient } from '../_api/clients/estimate.client';
import { FilesClient } from '../_api/clients/files.client';
import { IndustryClient } from '../_api/clients/industry.client';
import { InflationClient } from '../_api/clients/inflation.client';
import { IntlBetaClient } from '../_api/clients/intlBeta.client';
import { IntlErpClient } from '../_api/clients/intlErp.client';
import { IntlIndustryClient } from '../_api/clients/intlIndustry.client';
import { IntlRfClient } from '../_api/clients/intlRf.client';
import { IntlTaxRateClient } from '../_api/clients/intlTaxRate.client';
import { MeasureClient } from '../_api/clients/measure.client';
import { RegionClient } from '../_api/clients/region.client';
import { RiskClient } from '../_api/clients/risk.client';
import { ScenarioClient } from '../_api/clients/scenario.client';
import { USCompanyClient } from '../_api/clients/uscompany.client';
import { UserClient } from '../_api/clients/user.client';
import { USIndustryClient } from '../_api/clients/usindustry.client';
import { UsIndustryDataClient } from '../_api/clients/usIndustryData.client';
import { ValuesClient } from '../_api/clients/values.client';

export const ApiClients = [
    ScenarioClient,
    BetaClient,
    BetaEstimateClient,
    CountryClient,
    CurrencyClient,
    MeasureClient,
    RiskClient,
    EstimateClient,
    FilesClient,
    UserClient,
    UsIndustryDataClient,
    USIndustryClient,
    USCompanyClient,
    IntlErpClient,
    IntlRfClient,
    IntlIndustryClient,
    CompanyClient,
    IntlBetaClient,
    InflationClient,
    CostOfEquityClient,
    IntlTaxRateClient,
    ValuesClient,
    RegionClient,
    IndustryClient,
    MyMonitoringService,
    ErrorHandlerService
];
