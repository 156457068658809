export enum SubscriptionLevel {
    Trial = 'Trial',
    Basic = 'Basic',
    Pro = 'Pro',
    Enterprise = 'Enterprise',
    All = 'All'
}


export enum Subscriptionlabels {
    Subscription = 'Subscription',
    Status = 'Status',
    Expiration = 'Expiration',
    GeographicCoverage = 'Geographic Coverage',
    DatasetsIncluded = 'Datasets Included',
    DatapointsOffered = 'Datapoints Offered',
    EarliestCoverage = 'Earliest Coverage',
    SampleData = 'Sample Data'

}



