import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Spinner } from '@concurrency/angular';
import { Store } from '@ngxs/store';
import { SelectionType } from 'src/app/_api/enums/selection-type';
import { GetEstimates } from 'src/app/_navigator/estimates-list-store/estimates-list-actions';
import { ResetType } from 'src/app/estimate/data/reset-type.enum';
import { Estimate } from '../model/estimate.model';
import { DataStore } from '../store/data.store';

@Injectable()
export class EstimateService {
    constructor(
        private dataStore: DataStore,
        private spinner: Spinner,
        private router: Router,
        private store: Store
    ) { }

    public updateEstimate(estimate: Estimate, resetType?: ResetType): void {
        if (estimate.IsIndustryAnalysis) {
            this.dataStore.updateAnalysis(estimate);
            this.router.navigate(['estimate/tear-sheet']);
        } else {
            if (resetType === ResetType.Industry) {
                const industryRelatedSelections = [
                    SelectionType.CrspDecilesBeta,
                    SelectionType.RiskPremiumBeta,
                    SelectionType.HighFinancialRiskBeta,
                    SelectionType.CrspIndustryRiskPremium,
                    SelectionType.RprIndustryRiskPremium
                ];

                for (const scenario of estimate.Scenarios) {
                    // TODO: Check if SourceTable is null (don't clear custom data)
                    scenario.Selections = scenario.Selections.filter(
                        (x) => industryRelatedSelections.find((z) => z === SelectionType[x.SelectionType]) == null);
                }
            }

            if (resetType === ResetType.Estimate) {
                estimate.Inputs = [];
                for (const scenario of estimate.Scenarios) {
                    scenario.Selections = [];
                    scenario.Equations = [];
                }
            }
            const request = this.dataStore.updateEstimate(estimate);
            this.spinner.while(request);
            request.once(() => {
                this.store.dispatch(new GetEstimates());
                this.router.navigate(['estimate/studies']);
            });
        }
    }

}
