import { Component } from '@angular/core';
import { ContextualNumber } from '@concurrency/core';
import { Observable, of } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { InputType } from 'src/app/_api/responses/input.response';
import { CommonInputConfig } from 'src/app/_navigator/common/inputs/common-user-input.component';
import { LabelType } from 'src/app/_navigator/data/model/equation.model';
import { RiskService } from 'src/app/_navigator/data/service/risk.service';
import { DataStore } from 'src/app/_navigator/data/store/data.store';
import { ErrorService } from 'src/app/_navigator/error/error.service';
import { HelpService } from 'src/app/_navigator/help/help.service';
import { EditorComponent } from './editor.component';

@Component({ templateUrl: './portfolio-selector.component.html' })
export class PortfolioSelectorComponent extends EditorComponent {
    private inputType!: InputType;
    // TODO: We should not be using bangs for any of these values.
    public riskInput!: ContextualNumber;
    public inputConfig!: CommonInputConfig;
    public debt!: ContextualNumber;
    public debtConfig!: CommonInputConfig;
    public showDebtEditor = false;

    constructor(
        protected dataStore: DataStore,
        private helpService: HelpService,
        private errorService: ErrorService
    ) { super(dataStore); }

    public initialize(): void {
        if (this.settings.operand.inputType == null) {
            throw new Error(`PortfolioSelector requires an operand with an InputType`);
        }
        this.inputType = this.settings.operand.inputType;

        this.inputConfig = {
            name: this.settings.operand.editorName || '',
            minimumValue: 0,
            debounce: { callback: () => this.update(), delay: 0 },
            help: this.helpService.getHelp(this.inputType),
            error: this.errorService.sizeMeasure,
            autofocus: true
        };

        if (this.settings.operand.labelType === LabelType.ReleveredDebt) {
            this.showDebtEditor = true;

            this.debtConfig = {
                name: 'Debt-to-equity Ratio (%)',
                debounce: { callback: () => this.update(), delay: 0 },
                mask: `{}%`,
                inputType: 'number'
            };
        }

        // TODO: Obviate takeUntil by using Async pipes and local Observable streams
        this.dataStore.estimate.pipe(takeUntil(this.destroyed)).whileDefined((estimate) => {
            this.estimate = estimate;
            const inputType = this.estimate.getInput(this.inputType);
            this.riskInput = new ContextualNumber(inputType.Value, null, null, 2, false);

            if (this.showDebtEditor) {
                const debtRatioEquity = this.estimate.getInput(InputType.DebtRatioEquity);
                this.debt = new ContextualNumber(debtRatioEquity.Value);
            } else {
                this.debt = new ContextualNumber();
            }

            if (this.riskInput) {
                this.update();
            }
        });
    }

    // TODO: Make these DRY
    public isMarketCapValid(): boolean {
        this.dataStore.triggerEditorValidity(true);
        this.inputConfig.error = this.errorService.sizeMeasure;
        const investedCapital = this.estimate.getInput(InputType.MarketValueOfInvestedCapital);

        if (this.settings.operand.inputType !== InputType.MarketValueOfCommonEquity) {
            return true;
        }

        if (this.riskInput.asNumber == null || investedCapital.Value == null || this.riskInput.asNumber <= investedCapital.Value) {
            return true;
        }

        this.inputConfig.error = {
            text: `Market Value of Equity must be less than or equal Market Value of Invested Capital ($${investedCapital.Value} million).`,
            alwaysShow: true
        };

        this.dataStore.triggerEditorValidity(false);

        return false;
    }

    public isInvestedCapitalValid(): boolean {
        this.dataStore.triggerEditorValidity(true);
        this.inputConfig.error = this.errorService.sizeMeasure;
        const marketCap = this.estimate.getInput(InputType.MarketValueOfCommonEquity);

        if (this.settings.operand.inputType !== InputType.MarketValueOfInvestedCapital) {
            return true;
        }

        if (this.riskInput.asNumber == null || marketCap.Value == null || this.riskInput.asNumber >= marketCap.Value) {
            return true;
        }

        this.inputConfig.error = {
            text: `Market Value of Invested Capital must be greater than or equal to Market Value of Equity ($${marketCap.Value} million).`,
            alwaysShow: true
        };

        this.dataStore.triggerEditorValidity(false);

        return false;
    }

    public isTotalAssetsValid(): boolean {
        this.dataStore.triggerEditorValidity(true);
        this.inputConfig.error = this.errorService.sizeMeasure;
        const bookValue = this.estimate.getInput(InputType.BookValueOfEquity);

        if (this.settings.operand.inputType !== InputType.TotalAssets) {
            return true;
        }

        if (this.riskInput.asNumber == null || bookValue.Value == null || this.riskInput.asNumber >= bookValue.Value) {
            return true;
        }

        this.inputConfig.error = {
            text: `Total Assets must be greater than Book Value of Equity ($${bookValue.Value} million).`,
            alwaysShow: true
        };

        this.dataStore.triggerEditorValidity(false);

        return false;
    }

    public isBookValueEquityValid(): boolean {
        this.dataStore.triggerEditorValidity(true);
        this.inputConfig.error = this.errorService.sizeMeasure;
        const totalAsset = this.estimate.getInput(InputType.TotalAssets);

        if (this.settings.operand.inputType !== InputType.BookValueOfEquity) {
            return true;
        }

        if (this.riskInput.asNumber == null || totalAsset.Value == null || this.riskInput.asNumber <= totalAsset.Value) {
            return true;
        }

        this.inputConfig.error = {
            text: `Book Value of Equity must be less than Total Assets ($${totalAsset.Value} million).`,
            alwaysShow: true
        };

        this.dataStore.triggerEditorValidity(false);

        return false;
    }

    public update(): Observable<void> {
        const hasNoValue = this.riskInput == null || this.riskInput.asNumber == null;
        const isValid = this.isMarketCapValid()
            && this.isInvestedCapitalValid()
            && this.isTotalAssetsValid()
            && this.isBookValueEquityValid();
        if (hasNoValue || isValid === false) {
            this.modelChange.emit(new ContextualNumber());
            // tslint-disable-next-line import/no-deprecated
            return of(void 0);
        }

        return this.dataStore.portfolios.onceDefined((portfolios) => {
            const selectionType = this.settings.operand.selectionType;

            const result = RiskService.getPortfolioResult(portfolios, this.riskInput.asNumber, this.inputType, selectionType, {
                debt: this.debt.asNumber,
                historicRprErp: this.estimate.HistoricRprErp || undefined,
                useRegression: this.settings.scenario.UseRegressionCalculation
            });

            this.modelChange.emit(result);
        });
    }

    public save(): void {
        if (this.showDebtEditor) {
            this.estimate.setInput(InputType.DebtRatioEquity, this.debt.asNumberOrNull);
        }

        this.estimate.setInput(this.inputType, this.riskInput.asNumberOrNull);
    }

    public cancel(): void {
    }
}
