import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalManager, Spinner, SubscriberEntity } from '@concurrency/angular';
import { ContextualValue } from '@concurrency/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Select } from '@ngxs/store';
import * as moment from 'moment';
import { combineLatest, Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { CurrencyClient } from 'src/app/_api/clients/currency.client';
import { RegionClient } from 'src/app/_api/clients/region.client';
import { UsIndustryDataClient } from 'src/app/_api/clients/usIndustryData.client';
import { MinimumDate } from 'src/app/_api/responses/minimum-date.response';
import { Industry } from 'src/app/_api/responses/us-industry.response';
import { CommonDate, DateStruct } from 'src/app/_navigator/common/date-struct';
import { CommonInputConfig } from 'src/app/_navigator/common/inputs/common-user-input.component';
import { Analysis } from 'src/app/_navigator/data/model/analysis.model';
import { IndustryDataSelection } from 'src/app/_navigator/data/model/industry-data-request.model';
import { Suggestion } from 'src/app/_navigator/data/model/suggestion.model';
import { BenchmarkingService } from 'src/app/_navigator/data/service/benchmarking.service';
import { DataStore } from 'src/app/_navigator/data/store/data.store';
import { TypeaheadFormatters } from 'src/app/_navigator/data/util/typeahead-formatters.util';
import { HelpText } from 'src/app/_navigator/help/help-text';
import { PrimeManager } from 'src/app/_navigator/modal/pmodal.manager';
import { ProductType } from 'src/app/_navigator/user/enum/product-type';
import { SubscriptionLevel } from 'src/app/_navigator/user/enum/subscription-level';
import { SubscriptionData } from 'src/app/_navigator/user/model/subscription.model';
import { User } from 'src/app/_navigator/user/model/user.model';
import { UserStore } from 'src/app/_navigator/user/store/user.store';
import { UserUtil } from 'src/app/_navigator/user/util/user.util';
import { ValuesState } from 'src/app/_navigator/values-store/values-state';
import { InputsTexts } from 'src/app/estimate/data/inputs-texts';
import { InputsUtil } from 'src/app/estimate/inputs.util';
import { IndustryDataSharingService } from 'src/app/estimate/studies/service/industry-data-sharing.service';
import { TearSheetService } from 'src/app/home/tear-sheet/data/tear-sheet.service';
import { TrendsOverTimeService } from 'src/app/home/tear-sheet/data/trends-over-time.service';
import { environment } from 'src/environments/environment';
import { BenchmarkScenario } from '../data/benchmark-scenario';
import { BenchmarkingQueryBuilderService } from '../data/benchmarking-query-builder.service';
import { IndustryInputDependencies } from '../data/industry-input-dependencies';
import { IndustryScenario } from '../data/industry-scenario';
import { SelectScenarioModalComponent } from '../modals/select-scenario-modal.component';
import { BenchmarkingAddIndustriesComponent } from './benchmarking-add-industries/benchmarking-add-industries.component';

@Component({
    selector: 'app-industry-inputs',
    templateUrl: './industry-inputs.component.html',
    styleUrls: ['./industry-inputs.component.scss']
})
export class IndustryInputsComponent extends SubscriberEntity implements OnInit {

    private iniSubData: SubscriptionData | undefined;
    private usiSubData: SubscriptionData | undefined;
    private subscriptionWithProPlusLevel: SubscriptionData | undefined;

    private preserveValuationDate = {} as DateStruct;
    private IsMod4: boolean | undefined = false;
    private url: string | undefined = undefined;
    @ViewChild('inputsForm') public form!: HTMLFormElement;
    @Select(ValuesState.get) public valuesSelector!: Observable<MinimumDate | undefined>;
    public inputData!: IndustryInputDependencies;
    public valuationDateConfig!: CommonInputConfig;
    public continueBtnText = 'Continue';
    public TypeaheadFormatters = TypeaheadFormatters;
    public promptTitle = InputsTexts.highLevelAnalysis;
    public promptSubtitle = InputsTexts.startAnalysis;
    public minimumDate = -1;
    public showAddScenario = true;
    public maximumNumberOfScenarios = 5;
    public valuationDate: ContextualValue<DateStruct> = new ContextualValue<DateStruct>();
    public industries: ContextualValue<Industry>[] = [];
    public scenarios: IndustryDataSelection[];
    public scenarios1: IndustryDataSelection[];
    public regionStrings = new Array<string>();
    public regionComboOptions: Suggestion<string>[] = [];
    public currencyStrings = new Array<string>();
    public currencyOptions: Suggestion<string>[] = [];
    public productNews: any;
    public errorMessages: SafeHtml[] = [];
    public selectedIndustries: Industry[] = [];
    public regionConfig: CommonInputConfig = {
        name: 'Region',
        label: 'Select Region',
        help: HelpText.BenchmarkingRegion,
        placeholder: 'UNITED STATES',
        containerClassList: 'input-flex-display inline-disable-text',
        stackedDisplay: true,
        iconWithLabel: true,
        required: true
    };

    public currencyConfig: CommonInputConfig = {
        name: 'Currency',
        label: 'Select Currency',
        help: HelpText.BenchmarkingCurrency,
        placeholder: 'USD',
        containerClassList: 'input-flex-display inline-disable-text',
        stackedDisplay: true,
        iconWithLabel: true,
        required: true
    };

    public industryConfig: CommonInputConfig = {
        name: InputsTexts.industry,
        help: HelpText.BenchmarkingIndustry,
        disabled: true,
        placeholder: '2020 - MODULE INDUSTRY',
        containerClassList: 'input-flex-display',
        iconWithLabel: true,
        stackedDisplay: true,
        required: true
    };

    public isFirstLoad = true;
    public prevDate!: Date;
    public currentDate!: Date;

    constructor(
        private http: HttpClient,
        private sanitizer: DomSanitizer,
        private dataStore: DataStore,
        private router: Router,
        private userStore: UserStore,
        private spinner: Spinner,
        private regionClient: RegionClient,
        private currencyClient: CurrencyClient,
        private modalManager: ModalManager,
        private primeManager: PrimeManager,
        public tearSheetService: TearSheetService,
        public benchmarkingService: BenchmarkingService,
        public benchmarkingQueryBuilderService: BenchmarkingQueryBuilderService,
        public usIndustryDataClient: UsIndustryDataClient,
        private activeRoute: ActivatedRoute,
        public trendsOverTimeService: TrendsOverTimeService,
        public industryDataSharingService: IndustryDataSharingService
    ) {
        super();
        if (this.activeRoute.parent && this.activeRoute.parent.url) {
            this.activeRoute.parent.url.subscribe((url: any) => {
                this.url = url[0].path;
            });
        }
        const persistedScenarios = this.benchmarkingService.benchmarkingScenarios;

        this.scenarios = new Array<IndustryDataSelection>();
        this.scenarios1 = new Array<IndustryDataSelection>();
        if (persistedScenarios.length >= 5) {
            this.showAddScenario = false;
        }
        if (persistedScenarios.length) {
            persistedScenarios.forEach((scenario, index) => {
                this.scenarios = [
                    ...this.scenarios,
                    {
                        Currency: { value: scenario.Currency },
                        Region: { value: scenario.Region },
                        Industry: { value: scenario.Industry },
                        IndustryConfig: {
                            ...this.industryConfig,
                            help: index === 0 ? HelpText.BenchmarkingIndustry : undefined,
                            disabled: false
                        },
                        RegionConfig: { ...this.regionConfig, help: index === 0 ? HelpText.BenchmarkingRegion : undefined },
                        CurrencyConfig: { ...this.currencyConfig, help: index === 0 ? HelpText.BenchmarkingCurrency : undefined }
                    } as IndustryDataSelection
                ];
            });
        } else {
            this.scenarios.push({
                Currency: { value: null },
                Region: { value: null },
                Industry: { value: null },
                IndustryConfig: { ...this.industryConfig },
                RegionConfig: { ...this.regionConfig },
                CurrencyConfig: { ...this.currencyConfig }
            } as IndustryDataSelection);
        }

    }

    private persistBenchmarkData(validScenarios: IndustryDataSelection[]): BenchmarkScenario[] {
        return validScenarios.map((scenario) => {
            return {
                Region: scenario.Region.value,
                Currency: scenario.Currency.value,
                Industry: scenario.Industry.value
            };
        });
    }

    private setToDefaultOptions(): void {
        this.regionComboOptions = this.regionComboOptions.map((regionOption) => {
            return {
                ...regionOption,
                disabledText: this.disabledTextBasedOnSubscription(regionOption.value, 'United States', false)
            };
        });

        this.currencyOptions = this.currencyOptions.map((currencyOption) => {
            return {
                ...currencyOption,
                disabledText: this.disabledTextBasedOnSubscription(currencyOption.value, 'USD', true)
            };
        });
    }

    private disabledTextBasedOnValue(item: string, USValue: string, disabledText: string): string | undefined {
        if (item !== USValue) {
            return disabledText;
        }

        return undefined;
    }

    private disabledTextBasedOnSubscription(item: string, USValue: string, currency: boolean): string | undefined {
        if (!this.iniSubData && item !== USValue) {
            return '- An International subscription is required for this item.';
        }
        if (this.iniSubData && !this.usiSubData && item === USValue && currency !== true) {
            return '- A US subscription is required for this item.';
        }

        return undefined;
    }

    private setValuationDateConfigBasedOnSubscription(config: CommonInputConfig): void {
        if (config.minimumValue && config.maximumValue && this.valuationDateConfig.minimumValue && this.valuationDateConfig.maximumValue) {
            if (CommonDate.fromStruct(config.minimumValue as NgbDateStruct).asDate().setHours(0, 0, 0, 0) <= (CommonDate.fromStruct(this.valuationDateConfig.minimumValue as NgbDateStruct)).asDate().setHours(0, 0, 0, 0)
                && CommonDate.fromStruct(config.maximumValue as NgbDateStruct).asDate().setHours(0, 0, 0, 0) >= CommonDate.fromStruct(this.valuationDateConfig.maximumValue as NgbDateStruct).asDate().setHours(0, 0, 0, 0)) {
                this.valuationDateConfig = {
                    ...this.valuationDateConfig,
                    ...config
                };
            } else if (CommonDate.fromStruct(config.maximumValue as NgbDateStruct).asDate().setHours(0, 0, 0, 0) >= CommonDate.fromStruct(this.valuationDateConfig.maximumValue as NgbDateStruct).asDate().setHours(0, 0, 0, 0)) {
                this.valuationDateConfig.maximumValue = config.maximumValue;
            } else if (CommonDate.fromStruct(config.minimumValue as NgbDateStruct).asDate().setHours(0, 0, 0, 0) <= (CommonDate.fromStruct(this.valuationDateConfig.minimumValue as NgbDateStruct)).asDate().setHours(0, 0, 0, 0)) {
                this.valuationDateConfig.minimumValue = config.minimumValue;
            }
        }
    }

    private isInBetweenDate(minDate: NgbDateStruct, maxDate: NgbDateStruct): boolean {
        const start = (CommonDate.fromStruct(minDate)).asDate().setHours(0, 0, 0, 0);
        const end = CommonDate.fromStruct(maxDate).asDate().setHours(0, 0, 0, 0);
        let compare = new Date().setHours(0, 0, 0, 0);
        if (this.valuationDate && this.valuationDate.value) {
            const value = this.valuationDate.value;
            compare = new CommonDate(value.year, value.month, value.day).asDate().setHours(0, 0, 0, 0);
        }
        if (compare >= start && compare <= end) {
            return true;
        } else {
            return false;
        }
    }

    private getValuationDateConfig(productType: ProductType): CommonInputConfig {
        const defaultValuationDate = moment().toISOString();
        const config =
            UserUtil.getValuationDateInputConfig(
                CommonDate.fromString(defaultValuationDate),
                productType,
                this.minimumDate,
                this.inputData.user.subscriptionDetails,
                HelpText.BenchmarkingValuationDate
            );
        return config;
    }

    private restrictUSRegionBasedOnValidationDate(): void {
        if (this.usiSubData) {
            const config = this.getValuationDateConfig(this.usiSubData.productType);
            const validation = this.isInBetweenDate(config.minimumValue as NgbDateStruct, config.maximumValue as NgbDateStruct);
            if (!validation) {
                this.regionComboOptions = this.regionComboOptions.map((regionOption) => {
                    return {
                        ...regionOption,
                        disabledText: (regionOption.value === 'United States') ? ' - Unavailable by Valuation Date' : undefined
                    };
                });
            }
        }
    }

    private restrictInternationalRegionBasedOnValidationDate(): void {
        if (this.iniSubData) {
            const config = this.getValuationDateConfig(this.iniSubData.productType);
            const validation = this.isInBetweenDate(config.minimumValue as NgbDateStruct, config.maximumValue as NgbDateStruct);
            if (!validation) {
                this.regionComboOptions = this.regionComboOptions.map((regionOption) => {
                    return {
                        ...regionOption,
                        disabledText: (regionOption.value !== 'United States') ? ' - Unavailable by Valuation Date' : undefined
                    };
                });
            }
        }
    }

    private restrictBothRegionBasedOnValidationDate(): void {
        if (this.usiSubData && this.iniSubData) {
            const configMod2 = this.getValuationDateConfig(this.usiSubData.productType);
            const configMod4 = this.getValuationDateConfig(this.iniSubData.productType);

            const validation = (!(this.isInBetweenDate(configMod2.minimumValue as NgbDateStruct, configMod2.maximumValue as NgbDateStruct))
                && (!this.isInBetweenDate(configMod4.minimumValue as NgbDateStruct, configMod4.maximumValue as NgbDateStruct)));
            if (validation) {
                this.regionComboOptions = this.regionComboOptions.map((regionOption) => {
                    return {
                        ...regionOption,
                        disabledText: ' - Unavailable by Valuation Date'
                    };
                });
            }
        }
    }

    private persistBenchmarkDataFromIndustry(addIndustries: Industry[]): BenchmarkScenario[] {
        return addIndustries.map((scenario) => {
            if (scenario !== undefined) {
                return {
                    Region: scenario.Area ? scenario.Area : null,
                    Currency: scenario.CurrencyCode ? scenario.CurrencyCode : null,
                    Industry: scenario
                };
            } else {
                return {
                    Region: null,
                    Currency: null,
                    Industry: null
                };
            }
        });
    }

    public ngOnInit(): void {
        combineLatest([
            this.userStore.user,
            this.regionClient.read(),
            this.currencyClient.readIndustry(),
            this.dataStore.analysis,
            this.valuesSelector
        ]).pipe(
            takeUntil(this.destroyed),
            map((x) => ({
                user: x[0],
                regions: x[1],
                currencyIndustries: x[2],
                analysis: x[3],
                values: x[4]
            }))
        ).onceDefined((data) => {

            this.industryDataSharingService.sharedBenchmarkingIndustries$.subscribe((value) => {
                if (value.length > 0) {
                    if (value.length === 1 && value[0].CodeId === 0) {
                        this.selectedIndustries = [];
                    } else {
                        this.selectedIndustries = value;
                    }
                }
            });

            const persistedScenarios = this.benchmarkingService.benchmarkingScenarios;

            this.scenarios = new Array<IndustryDataSelection>();

            if (persistedScenarios.length) {

                persistedScenarios.forEach((scenario) => {
                    this.scenarios = [
                        ...this.scenarios,
                        {
                            Currency: { value: scenario.Currency },
                            Region: { value: scenario.Region },
                            Industry: { value: scenario.Industry },
                            RegionConfig: { ...this.regionConfig },
                            CurrencyConfig: { ...this.currencyConfig }
                        } as IndustryDataSelection
                    ];
                });
            } else {
                this.scenarios.push({
                    Currency: { value: null },
                    Region: { value: null },
                    Industry: { value: null },
                    IndustryConfig: { ...this.industryConfig },
                    RegionConfig: { ...this.regionConfig },
                    CurrencyConfig: { ...this.currencyConfig }
                } as IndustryDataSelection);
            }

            const persistedValuationDate = this.benchmarkingService.benchMarkingValuationDate;

            this.preserveValuationDate = persistedValuationDate ? persistedValuationDate : new CommonDate(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate());
            const Mod4 = 'intlindustry-benchmarking';
            this.IsMod4 = this.url === Mod4 ? true : false;
            if (data.analysis == null || data.user == null) {
                return;
            }

            this.inputData = {} as IndustryInputDependencies;
            this.inputData.user = data.user as User;

            this.iniSubData = data.user.subscriptionDetails.find((sub) => sub.productType === ProductType.Ini);
            this.usiSubData = data.user.subscriptionDetails.find((sub) => sub.productType === ProductType.Usi);
            this.subscriptionWithProPlusLevel = data.user.subscriptionDetails.find(
                (sub) => {
                    return sub.level === SubscriptionLevel.Pro ||
                        sub.level === SubscriptionLevel.Enterprise;
                }
            );

            if (data.values) {
                if (this.iniSubData && !this.usiSubData) {
                    this.minimumDate = data.values.InternationalEstimates;
                }

                if (this.usiSubData && !this.iniSubData) {
                    this.minimumDate = data.values.UsIndustries;
                }

                if (this.usiSubData && this.iniSubData) {
                    this.minimumDate = data.values.InternationalEstimates;
                }
            }

            if (persistedValuationDate) {
                this.valuationDate.value = persistedValuationDate;
            } else {
                this.valuationDate.value = CommonDate.fromString(data.analysis.ValuationDate);
            }

            const analysis = data.analysis as Analysis;
            const date = CommonDate.fromString(analysis.ValuationDate);
            this.valuationDateConfig =
                UserUtil.getValuationDateInputConfig(
                    date,
                    this.iniSubData ? ProductType.Ini : ProductType.Usi,
                    this.minimumDate,
                    this.inputData.user.subscriptionDetails,
                    HelpText.BenchmarkingValuationDate
                );
            if (this.iniSubData && this.iniSubData.level && this.usiSubData && this.usiSubData.level) {
                this.setValuationDateConfigBasedOnSubscription(this.getValuationDateConfig(this.usiSubData.productType));
                this.setValuationDateConfigBasedOnSubscription(this.getValuationDateConfig(this.iniSubData.productType));
            }
            this.valuationDateConfig.stackedDisplay = true;
            this.valuationDateConfig.iconWithLabel = true;
            this.valuationDateConfig.containerClassList = 'input-flex-display';

            if (this.usiSubData && !this.iniSubData) {

                if (!persistedScenarios.length) {
                    this.scenarios[0].Region.value = 'United States';
                    this.scenarios[0].Currency.value = 'USD';
                    this.scenarios[0].IndustryConfig.disabled = false;
                }

                this.currencyOptions = this.currencyOptions.map((currencyOption) => {
                    return {
                        ...currencyOption,
                        disabledText: this.disabledTextBasedOnValue(currencyOption.value, 'USD', '- Currency Unavailable')
                    };
                });

                this.promptTitle = InputsTexts.highLevelUSAnalysis;
            } else if (!this.usiSubData && this.iniSubData) {
                this.promptTitle = InputsTexts.highLevelAnalysisInternational;
            }

            this.http.get(environment.urls.contentManagement + '/Mod1-ProductNews.html', { responseType: 'text' }).subscribe((res) => {
                this.productNews = this.sanitizer.bypassSecurityTrustHtml(res.toString());
            });
        });

        this.http.get(environment.urls.contentManagement + '/Mod1-ProductNews.html', { responseType: 'text' }).subscribe((res) => {
            this.productNews = this.sanitizer.bypassSecurityTrustHtml(res.toString());
        });
    }

    public saveAndContinue(industries: Industry[]): void {
        const validScenarios = new Array<IndustryDataSelection>();
        industries.forEach((scenario) => {
            validScenarios.push({
                Region: { value: scenario.Area },
                Currency: { value: scenario.CurrencyCode },
                Industry: { value: scenario },
                IndustryConfig: { ... this.industryConfig },
                RegionConfig: { ...this.regionConfig },
                CurrencyConfig: { ...this.currencyConfig }
            } as IndustryDataSelection);
        });

        if (!validScenarios.length || !this.valuationDate.value) {
            this.modalManager.open<void, SelectScenarioModalComponent>(SelectScenarioModalComponent, {
                mayDismiss: false
            });

            return;
        }

        const date = CommonDate.fromStruct(this.valuationDate.value).asString();
        const mappedScenarioData = validScenarios.map((scenario): IndustryScenario => {
            return {
                Region: scenario.Region.value,
                Currency: scenario.Currency.value,
                IndustryCode: scenario.Industry.value ? Number(scenario.Industry.value.CodeId) : undefined
            };
        });

        this.industryDataSharingService.setSharedBenchmarkingIndustries = industries;
        this.benchmarkingService.setBenchmarkingScenarios = this.persistBenchmarkData(validScenarios);
        this.benchmarkingService.benchMarkingValuationDate = this.valuationDate.value;

        const request = this.dataStore.updateBenchmarking(date, mappedScenarioData);

        this.spinner.while(request);

        request.once(() => {
            const benchmarkingAnalysis = this.dataStore.benchmarkingAnalysis;

            benchmarkingAnalysis.onceDefined((benchmarkingData) => {

                this.tearSheetService.setTearSheetUserSubscription(this.subscriptionWithProPlusLevel);
                this.tearSheetService.setTearSheetContent(benchmarkingData[0]);

                if (this.subscriptionWithProPlusLevel) {
                    this.tearSheetService.setTrendsOverTimeIndustry([...benchmarkingData]);
                }

                this.tearSheetService.setTearSheetData(benchmarkingData);

                // this.router.navigate([`${this.url ? this.url : 'industry-benchmarking'}/tear-sheet`]);
                this.router.navigate(['/industry-benchmarking/inputs/tear-sheet']);
            });
        });
    }

    public reflectDate(valuationDate: DateStruct): void {
        const str1 = "" + valuationDate.year + "-" + ('0' + valuationDate.month).slice(-2) + "-" + ('0' + valuationDate.day).slice(-2) + "T12:00:00";
        const str2 = this.preserveValuationDate.year + "-" + ('0' + this.preserveValuationDate.month).slice(-2) + "-" + ('0' + this.preserveValuationDate.day).slice(-2) + "T12:00:00";
        const newDate = new Date(str1);
        const perservedValuationDate = new Date(str2);
        this.setToDefaultOptions();
        this.validateValuationDateForAllScenarios();
        this.restrictRegionBasedOnValidationDate();

        if (this.isFirstLoad) {
            this.prevDate = perservedValuationDate;
            this.currentDate = newDate;
            this.isFirstLoad = false;
        } else {
            this.prevDate = this.currentDate;
            this.currentDate = newDate;
        }

        if (this.prevDate.getTime() !== this.currentDate.getTime()) {
            this.selectedIndustries = [];
            this.benchmarkingService.setBenchmarkingScenarios = [];
            this.tearSheetService.setIsTearSheetReady(false);
        }
    }

    public validateValuationDateForAllScenarios(): void {
        if (this.usiSubData && this.iniSubData) {
            this.errorMessages = [];
            let errorMessagesTemp: string[] = [];
            this.scenarios.forEach((scenario) => {
                if (this.usiSubData && this.usiSubData.level && this.iniSubData && this.iniSubData.level) {
                    let subsciption: SubscriptionData | undefined;
                    if (this.IsMod4) {
                        subsciption = this.iniSubData;
                    } else if (!this.IsMod4) {
                        subsciption = this.usiSubData;
                    }
                    if (subsciption) {
                        const config = this.getValuationDateConfig(subsciption.productType);
                        if (config.minimumValue && config.maximumValue) {
                            const validation = this.isInBetweenDate(config.minimumValue as NgbDateStruct, config.maximumValue as NgbDateStruct);
                            if (!validation) {
                                scenario.CurrencyConfig = {
                                    ...this.currencyConfig,
                                    disabled: true,
                                    readonly: true
                                };
                                scenario.IndustryConfig = {
                                    ...this.industryConfig,
                                    disabled: true
                                };
                                errorMessagesTemp.push(this.setErrorMessage(scenario));
                            }
                        }
                    }
                }
            });
            errorMessagesTemp = errorMessagesTemp.filter((elem: string, index: number, self: string[]) => {
                return index === self.indexOf(elem);
            });
            errorMessagesTemp.forEach((x) => {
                this.errorMessages.push(this.sanitizer.bypassSecurityTrustHtml(x));
            });
        }
    }

    public setErrorMessage(scenario?: IndustryDataSelection): string {
        let errorMessage = "";
        if (this.iniSubData && this.usiSubData) {
            if ((this.usiSubData.level === SubscriptionLevel.Pro && this.iniSubData.level === SubscriptionLevel.Basic)
                || (this.usiSubData.level === SubscriptionLevel.Enterprise && this.iniSubData.level === SubscriptionLevel.Basic)) {
                errorMessage = "Only dates of <strong>12/31/2019</strong> and forward are available to International Industry Benchmarking Basic Subscribers";
            } else if ((this.usiSubData.level === SubscriptionLevel.Basic && this.iniSubData.level === SubscriptionLevel.Pro)
                || (this.usiSubData.level === SubscriptionLevel.Basic && this.iniSubData.level === SubscriptionLevel.Enterprise)) {
                errorMessage = "Only dates of <strong>12/31/2019</strong> and forward are available to US Industry Benchmarking Basic Subscribers";
            } else if ((this.usiSubData.level === SubscriptionLevel.Trial && this.iniSubData.level === SubscriptionLevel.Pro)
                || (this.usiSubData.level === SubscriptionLevel.Trial && this.iniSubData.level === SubscriptionLevel.Enterprise)
                || (this.usiSubData.level === SubscriptionLevel.Trial && this.iniSubData.level === SubscriptionLevel.Basic)) {
                errorMessage = "Only dates of <strong> 03/31/2016 - 07/31/2016</strong> are available to US Industry Benchmarking Trial Subscribers";
            } else if ((this.usiSubData.level === SubscriptionLevel.Pro && this.iniSubData.level === SubscriptionLevel.Trial)
                || (this.usiSubData.level === SubscriptionLevel.Enterprise && this.iniSubData.level === SubscriptionLevel.Trial)) {
                errorMessage = "Only dates of <strong> 03/31/2016 - 07/31/2016</strong> are available to International Industry Benchmarking Trial Subscribers";
            } else if (this.usiSubData.level === SubscriptionLevel.Basic && this.iniSubData.level === SubscriptionLevel.Trial && scenario && scenario.Region.value === 'United States') {
                errorMessage = "Only dates of <strong>12/31/19</strong>  and forward are available to US Industry Benchmarking Basic Subscribers.";
            } else if (this.usiSubData.level === SubscriptionLevel.Basic && this.iniSubData.level === SubscriptionLevel.Trial && scenario && scenario.Region.value !== 'United States') {
                errorMessage = "Only dates of <strong> 03/31/2016 - 07/31/2016</strong> are available to International Industry Benchmarking Trial Subscribers";
            } else if ((this.usiSubData.level === SubscriptionLevel.Pro || this.usiSubData.level === SubscriptionLevel.Enterprise)
                && (this.iniSubData.level === SubscriptionLevel.Pro || this.iniSubData.level === SubscriptionLevel.Enterprise)) {
                errorMessage = "Valuation dates prior to <strong> 03/31/2015</strong> are limited to US only options.";
            }
        } else if (!this.IsMod4 && !this.iniSubData) {
            errorMessage = "Only  available to International Industry Benchmarking Subscribers";
        } else if (this.IsMod4 && this.usiSubData) {
            if (this.usiSubData.level === SubscriptionLevel.Basic) {
                errorMessage = "Only dates of <strong>12/31/2019</strong> and forward are available to US Industry Benchmarking Basic Subscribers";
            }
        }
        return errorMessage;

    }

    public restrictRegionBasedOnValidationDate(): void {
        this.restrictUSRegionBasedOnValidationDate();
        this.restrictInternationalRegionBasedOnValidationDate();
        this.restrictBothRegionBasedOnValidationDate();
    }

    public isGeneralInputsValid(): boolean {
        if (this.selectedIndustries.length >= this.maximumNumberOfScenarios) {
            return false;
        } else {
            return true;
        }
    }

    public pullIndustries(valuationDate: DateStruct): void {
        const date = CommonDate.fromStruct(valuationDate);
        this.primeManager.openDialog(BenchmarkingAddIndustriesComponent, {
            valuationDate: date,
            moduleName: this.url,
            userSelectedIndustries: this.selectedIndustries,
            SaveAndContinue: (data: Industry[]) => this.saveAndContinue(data)
        });
    }

    public removeIndustry = (CodeId: number, Area: string, CurrencyCode: string): void => {
        this.selectedIndustries = this.selectedIndustries.filter((item) => Number(item.CodeId) !== Number(CodeId)
            || (item.Area !== Area) || (item.CurrencyCode !== CurrencyCode));
        this.benchmarkingService.setBenchmarkingScenarios = this.persistBenchmarkDataFromIndustry(this.selectedIndustries);
        this.showAddScenario = InputsUtil.isAddIndustryAvailable(this.selectedIndustries.length, this.maximumNumberOfScenarios);
        if (this.selectedIndustries && this.selectedIndustries.length === 0) {
            this.benchmarkingService.setBenchmarkingScenarios = [];
        }
    }

}
