import { ContextualNumber } from '@concurrency/core';
import { SelectionType } from 'src/app/_api/enums/selection-type';
import { CountryCreditRating } from 'src/app/_api/responses/country-credit-rating.response';
import { InputType } from 'src/app/_api/responses/input.response';
import { Estimate } from 'src/app/_navigator/data/model/estimate.model';
import { CalculationsUtil } from './calculations.util';

export class CcrUtil {
    public static setCcr(ccr: CountryCreditRating, estimate: Estimate): void {
        const scenario = estimate.Scenarios[0];

        if (ccr.CountryRiskPremia != null) {
            const crp = new ContextualNumber(ccr.CountryRiskPremia * 100, 'Country Risk Premia', ccr.DataAsOf);
            scenario.setSelection(crp, SelectionType.CountryRiskPremium, InputType.None);
        }

        if (ccr.YieldSpread != null) {
            const ys = new ContextualNumber(ccr.YieldSpread * 100, 'Yield Spread', ccr.DataAsOf);
            scenario.setSelection(ys, SelectionType.YieldSpread, InputType.None);
        }

        if (ccr.RelativeVolatility != null) {
            const rv = new ContextualNumber(ccr.RelativeVolatility, 'Relative Volatility', ccr.DataAsOf);
            scenario.setSelection(rv, SelectionType.RelativeVolatility, InputType.None);
        }
    }

    public static setCostOfEquityCcr(estimate: Estimate, crpSelection: SelectionType, keSelection: SelectionType): void {
        const scenario = estimate.Scenarios[0];
        const countryRiskPremium = scenario.getValueOrNaN(crpSelection, InputType.None);
        const result = CalculationsUtil.calculateCapm(estimate, countryRiskPremium);
        if (result) {
            scenario.setSelection(new ContextualNumber(result), keSelection, InputType.None);
        }
    }

    public static setCostOfEquityVolatility(estimate: Estimate): void {
        const scenario = estimate.Scenarios[0];
        const rv = scenario.getValueOrNaN(SelectionType.RelativeVolatility, InputType.None);
        const result = CalculationsUtil.calculateCapmVolatility(estimate, rv);
        if (result) {
            scenario.setSelection(new ContextualNumber(result), SelectionType.CostOfEquityCapmVolatility, InputType.None);
        }
    }

    public static setFisherEffect(estimate: Estimate, capmSelection: SelectionType, fisherEffectSelection: SelectionType): void {
        const scenario = estimate.Scenarios[0];
        const ke = scenario.getValueOrNaN(capmSelection, InputType.None);
        const result = CalculationsUtil.calculateFisherEffect(estimate, ke);
        if (result) {
            scenario.setSelection(new ContextualNumber(result), fisherEffectSelection, InputType.None);
        }
    }

    public static setCostOfDebtCcr(estimate: Estimate, crpSelection: SelectionType, kdSelection: SelectionType): void {
        const scenario = estimate.Scenarios[0];
        const countryRiskPremium = scenario.getValueOrNaN(crpSelection, InputType.None);
        const result = CalculationsUtil.calculateCostOfDebt(estimate, countryRiskPremium);
        if (result) {
            scenario.setSelection(new ContextualNumber(result), kdSelection, InputType.None);
        }
    }

    public static setCostOfDebtVolatility(estimate: Estimate): void {
        const scenario = estimate.Scenarios[0];
        const volatility = scenario.getValueOrNaN(SelectionType.RelativeVolatility, InputType.None);
        const result = CalculationsUtil.calculateCostOfDebtVolatility(estimate, volatility);
        if (result) {
            scenario.setSelection(new ContextualNumber(result), SelectionType.CostOfDebtVolatility, InputType.None);
        }
    }

    public static setSingleCountryCostOfDebt(estimate: Estimate): void {
        const scenario = estimate.Scenarios[0];
        const result = CalculationsUtil.calculateSingleCountryCostOfDebt(estimate);
        if (result) {
            scenario.setSelection(new ContextualNumber(result), SelectionType.KdSingleCountryCapm, InputType.None);
        }
    }

    public static setWacc(estimate: Estimate, keSelection: SelectionType, kdSelection: SelectionType, waccSelection: SelectionType): void {
        const scenario = estimate.Scenarios[0];
        const ke = scenario.getValueOrNaN(keSelection, InputType.None);
        const kd = scenario.getValueOrNaN(kdSelection, InputType.None);
        const result = CalculationsUtil.calculateWacc(estimate, ke, kd);
        if (result) {
            scenario.setSelection(new ContextualNumber(result), waccSelection, InputType.None);
        }
    }

    public static setSingleCountryCapm(estimate: Estimate): void {
        const scenario = estimate.Scenarios[0];
        const result = CalculationsUtil.calculateSingleCountryCapm(estimate);
        if (result) {
            scenario.setSelection(new ContextualNumber(result), SelectionType.KeSingleCountryCapm, InputType.None);
        }
    }
}
