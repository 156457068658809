export class InputText {
    public static readonly ValuationDate = `Valuation Date`;
    public static readonly Region = `Region`;
    public static readonly Currency = `Currency`;
    public static readonly InvestorCountry = `Investor Country`;
    public static readonly InvesteeCountry = `Investee Country`;
    public static readonly CashFlowCountry = `Currency of Cash Flows`;
    public static readonly KeCountryOfInput = `Which country's inputs would you like to use to develop your cost of equity?`;
    public static readonly Industry = `Industry`;
    public static readonly InflationInvestor = `Expected Inflation (Inputs country)`;
    public static readonly InflationInvestee = `Expected Inflation (Cash Flow country)`;
    public static readonly KdCountryOfInput = `Input Country (Cost of Debt)`;
    public static readonly Kd = `Pre-tax Cost of Debt (Kd)`;
    public static readonly DebtToTotalCapitalRatio = `Debt to Total Capital Ratio`;
    public static readonly TaxRate = `Tax Rate`;
    public static readonly UseYieldSpreadOnly = `I would like to use only the Country Yield Spread Model CRP to apply to my Cost of Debt`;
    public static readonly RiskFreeRate = `Risk-Free Rate`;
    public static readonly Beta = `Beta`;
    public static readonly EquityRiskPremium = `Equity Risk Premium`;
    public static readonly SizePremium = `Size Premium`;
    public static readonly Alpha = `Alpha (Company Specific Risk Premium)`;
    public static readonly BetaValuationDate = `Valuation Date`;
    public static readonly ReturnFrequency = `Return Frequency`;
    public static readonly CurrencyOfWACC = `Currency of WACC`;
    public static readonly LookbackPeriod = `Lookback Period`;
    public static readonly SubjectCompanyLocation = `Subject Company Location`;
    public static readonly MarketIndex = `Market Index`;
}
