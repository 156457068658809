import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SubscriberEntity } from '@concurrency/angular';
import { Select, Store } from '@ngxs/store';
import { combineLatest } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { SelectionType } from 'src/app/_api/enums/selection-type';
import { CountryCreditRating } from 'src/app/_api/responses/country-credit-rating.response';
import { Country } from 'src/app/_api/responses/country.response';
import { EstimateSummary } from 'src/app/_api/responses/estimate-summary.response';
import { InputType } from 'src/app/_api/responses/input.response';
import { CcrState } from 'src/app/_navigator/ccr-store/ccr-state';
import { CountryListState } from 'src/app/_navigator/country-list-store/country-list-state';
import { Estimate } from 'src/app/_navigator/data/model/estimate.model';
import { DynamicTextSummaryState } from 'src/app/_navigator/dynamic-text-store/dynamic-text-summary-state';
import { EstimateState } from 'src/app/_navigator/estimate-store/estimate-state';
import { EstimateSummaryState } from 'src/app/_navigator/estimate-summary/estimate-summary-state';
import { DynamicTextSummary } from 'src/app/international-estimate/dynamic-text/model/dynamic-text-summary';
import { SummaryText } from '../data/model/summary-text';
import { DynamicText } from '../dynamic-text/model/dynamic-text';
import { EquationGroup } from '../equation/equation-group.model';
import { EquationGroupUtil } from '../equation/equation-group.util';

@Component({
    selector: 'coe-summary-capm',
    templateUrl: './coe-summary-capm.component.html'
})
export class CoeSummaryCapmComponent extends SubscriberEntity implements OnInit {
    public equations: EquationGroup[] = [];
    public dynamicText: DynamicText[] = [];
    public showFisherEffect = false;
    public isKeAvailable = false;
    public SummaryText = SummaryText;

    @Select(EstimateState.get) public estimateSelector!: Observable<Estimate | undefined>;
    @Select(EstimateSummaryState.get) public estimateSummarySelector!: Observable<EstimateSummary | undefined>;
    @Select(DynamicTextSummaryState.get) public dynamicTextSummarySelector!: Observable<DynamicTextSummary | undefined>;
    @Select(CcrState.get) public ccrSelector!: Observable<CountryCreditRating | undefined>;

    constructor(private router: Router, private store: Store) {
        super();
    }

    private initialize(
        estimate: Estimate,
        countryOfInputs: Country | undefined,
        cashFlowCountry: Country | undefined,
        ccr: CountryCreditRating | undefined
    ): void {
        if (countryOfInputs == null || cashFlowCountry == null || ccr == null) {
            return;
        }

        this.showFisherEffect = cashFlowCountry.Currency.CurrencyId !== countryOfInputs.Currency.CurrencyId;
        this.equations = EquationGroupUtil.getCostOfEquityCapmGroup(estimate, ccr, countryOfInputs);
        this.isKeAvailable = true;
    }

    public ngOnInit(): void {
        this.estimateSelector.onceDefined((estimate) => {
            const scenario = estimate.Scenarios[0];
            const countryOfInputs = estimate.getInput(InputType.CountryOfInputs);
            const cashFlowCountry = estimate.getInput(InputType.CashFlows);
            const rf = scenario.getSelection(SelectionType.IntlRiskFreeRate, InputType.None);
            const erp = scenario.getSelection(SelectionType.IntlEquityRiskPremium, InputType.None);
            const beta = scenario.getSelection(SelectionType.IntlBeta, InputType.None);

            if (countryOfInputs.Value == null
                || cashFlowCountry.Value == null
                || rf.Value == null
                || erp.Value == null
                || beta.Value == null
            ) {
                return;
            }

            combineLatest([
                this.store.select(CountryListState.getById(countryOfInputs.Value)),
                this.store.select(CountryListState.getById(cashFlowCountry.Value)),
                this.ccrSelector
            ]).pipe(
                map((x) => ({
                    countryOfInputs: x[0],
                    cashFlow: x[1],
                    ccr: x[2]
                }))
            ).onceDefined((data) => {
                this.initialize(estimate, data.countryOfInputs, data.cashFlow, data.ccr);
            });
        });

        this.dynamicTextSummarySelector.onceDefined((dt) => {
            if (dt.CostOfEquitySummaryCapmText == null) {
                return;
            }
            this.dynamicText = dt.CostOfEquitySummaryCapmText;
        });
    }

    public edit(): void {
        this.router.navigate(['international-estimate/coe-inputs']);
    }
}
