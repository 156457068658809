import { Pipe, PipeTransform } from '@angular/core';
import { Util } from '@concurrency/core';

@Pipe({
    name: 'percentageWithCommas'
})
export class PercentageWithCommasPipe implements PipeTransform {

    transform(value: number, fractionDigits: number = 2): string {
        if (value) {
            let ab = value.toString();
            if (ab.includes('.') === false) {
                ab = ab + '.00';
                value = parseFloat(ab);
            }
        }
        if (Util.isNumber(value)) {
            const parts = value.toFixed(fractionDigits).toString().split('.');
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            return parts.join('.') + '%';
        } else if (value) {
            return value.toString() + '%';
        } else {
            return '';
        }
    }

}
