import { betaNormalFontSize, krollblue, krollshadow } from '../../estimate/exports/summary-export/util/summary-helpers';
import { BetaComparbleCompanyResults } from '../beta.types';

export function getCompanies(companies: BetaComparbleCompanyResults[]): any[] {
    const _companies: any[] = [];
    if (companies.length > 0) {
        _companies.push(
            { value: '' },
            { value: '' },
            { value: '' }
        );
        companies.forEach((company: BetaComparbleCompanyResults) => {
            _companies.push(
                {
                    bold: 'true',
                    borderBottom: { size: 1, color: krollshadow },
                    color: krollblue,
                    fontSize: betaNormalFontSize,
                    value: company.CompanyName.toLocaleUpperCase(),
                    wrap: true
                }
            );
        });
    }

    return _companies;
}
