import { SummaryScenario } from './summary-scenario.model';

export interface SummaryStudy {
    name: string;
    show: boolean;
    scenarios: SummaryScenario[];
    studyType: StudyType;
}

export enum StudyType {
    Unknown = 0,
    CrspDecileSizePremiumStudy = 1,
    RiskPremiumReportStudy = 2,
    HighFinancialRiskStudy = 3
}
