import { Util } from '@concurrency/core';
import { Chart } from 'angular-highcharts';
import { PointOptionsObject, SeriesOptionsType } from 'highcharts';
import { IndustryMetric } from 'src/app/_api/responses/industry-metric.response';
import { Metric } from '../enums/metric';
import { TearSheetUtil } from './tear-sheet.util';

export class CostOfEquityCapitalChartUtil {
    public static getCostOfEquityCapitalChartMetric(industryMetric: IndustryMetric[]): Chart | null {
        const crspCapm = TearSheetUtil.getMetric(Metric.CrspCapm, industryMetric);
        const crspBuildUp = TearSheetUtil.getMetric(Metric.CrspBuildUp, industryMetric);
        const rprCapm = TearSheetUtil.getMetric(Metric.RprCapm, industryMetric);
        const rprBuildUp = TearSheetUtil.getMetric(Metric.RprBuildUp, industryMetric);
        const oneStage = TearSheetUtil.getMetric(Metric.OneStageDcf, industryMetric);
        const threeStageFactor = TearSheetUtil.getMetric(Metric.ThreeStageFactor, industryMetric);
        const threeStageDcf = TearSheetUtil.getMetric(Metric.ThreeStageDcf, industryMetric);
        const fiveFactorModel = TearSheetUtil.getMetric(Metric.FiveStageFactor, industryMetric);
        const capm = TearSheetUtil.getMetric(Metric.Capm, industryMetric);

        const threeStage = threeStageFactor || threeStageDcf;

        const cocValues = [
            crspCapm,
            crspBuildUp,
            rprCapm,
            rprBuildUp,
            oneStage,
            threeStage,
            fiveFactorModel,
            capm
        ];

        if (crspCapm == null
            && crspBuildUp == null
            && rprCapm == null
            && rprBuildUp == null
            && oneStage == null
            && threeStage == null
            && fiveFactorModel == null
            && capm == null
        ) {
            return null;
        }

        const averageCrsp: any[] = [];
        const averageRpr: any[] = [];
        cocValues.forEach((value) => {
            if (value != null) {
                if (value.MetricId === 36 && value.SICCompositeLatest != null) {
                    averageCrsp.push(value.SICCompositeLatest);
                } else if (value.MetricId === 37 && value.SICCompositeLatest != null) {
                    averageCrsp.push(value.SICCompositeLatest);
                } else if (value.MetricId === 38 && value.SICCompositeLatest != null) {
                    averageRpr.push(value.SICCompositeLatest);
                } else if (value.MetricId === 39 && value.SICCompositeLatest != null) {
                    averageRpr.push(value.SICCompositeLatest);
                }
            }
        });

        const avgCrsp = Util.average(averageCrsp, 2);
        const avgRpr = Util.average(averageRpr, 2);
        let data: (number | [(number | string), (number | null)] | null | PointOptionsObject)[] = [];
        data = this.setSeries(industryMetric,
            avgCrsp,
            avgRpr,
            oneStage != null && oneStage.SICCompositeLatest != null ? oneStage.SICCompositeLatest : 0,
            threeStage != null && threeStage.SICCompositeLatest != null ? threeStage.SICCompositeLatest : 0,
            fiveFactorModel != null && fiveFactorModel.SICCompositeLatest != null ? fiveFactorModel.SICCompositeLatest : 0,
            capm != null && capm.SICCompositeLatest != null ? capm.SICCompositeLatest : 0
        );
        let series: SeriesOptionsType[] | undefined;
        series = [
            {
                type: 'column',
                name: 'Cost of Equity Capital',
                data
            }];
        return this.buildChart(series);
    }

    private static buildChart(series: SeriesOptionsType[] | undefined): Chart {

        return new Chart({
            chart: {
                type: 'column',
                width: 350,
                height: 300
            },
            title: {
                text: 'Cost of Equity Capital (%)'
            },
            subtitle: {
                text: 'Industry Composite'
            },
            xAxis: {
                type: 'category'
            },
            legend: {
                enabled: false
            },
            plotOptions: {
                series: {
                    dataLabels: {
                        enabled: true,
                        format: '{point.y:.1f}'
                    }
                }
            },
            tooltip: {
                headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b>'
            },

            series,
            credits: {
                enabled: false
            }
        });
    }
    private static setSeries(industryMetric: IndustryMetric[],
        avgCRSP: number,
        avgRPR: number,
        oneStage: number,
        threeStage: number,
        fiveFactorModel: number,
        capm: number): (number | [(number | string), (number | null)] | null | PointOptionsObject)[] {
        industryMetric = industryMetric.filter((x) => x.MetricCategory === 'Cost of Equity Capital');
        const data: (number | [(number | string), (number | null)] | null | PointOptionsObject)[] = [];
        if (industryMetric.filter((x) => x.MetricId === Metric.Capm).length > 0) {
            data.push({
                name: 'CAPM',
                y: Util.round(capm, 1),
                color: '#4c9fc8'
            });
        }
        if (industryMetric.
            filter((x) => (x.MetricId === Metric.CrspCapm
                || x.MetricId === Metric.CrspBuildUp)).length > 0) {
            data.push({
                name: 'Avg CRSP',
                y: Util.round(avgCRSP, 1),
                color: '#4d4d4f'
            });
        }

        if (industryMetric.
            filter(((x) => x.MetricId === Metric.RprCapm
                || x.MetricId === Metric.RprBuildUp)).length > 0) {
            data.push({
                name: 'Avg RPR',
                y: Util.round(avgRPR, 1),
                color: '#a7a8a9'
            });
        }

        if (industryMetric.filter((x) => x.MetricId === Metric.OneStageDcf).length > 0) {
            data.push({
                name: '1-Stage',
                y: Util.round(oneStage, 1),
                color: '#14487f'
            });
        }

        if (industryMetric.
            filter((x) => (x.MetricId === Metric.ThreeStageFactor
                || x.MetricId === Metric.ThreeStageDcf)).length > 0) {
            data.push({
                name: '3-Stage',
                y: Util.round(threeStage, 1),
                color: '#43b059'
            });
        }

        if (industryMetric.filter((x) => x.MetricId === Metric.FiveStageFactor).length > 0) {
            data.push({
                name: '5-Factor Model',
                y: Util.round(fiveFactorModel, 1),
                color: '#67d2df'
            });
        }
        return data;
    }

}
