import * as moment from 'moment';

import { Component, OnInit, ViewChild } from '@angular/core';
import { Spinner, SubscriberEntity } from '@concurrency/angular';
import { Util } from '@concurrency/core';
import { Select } from '@ngxs/store';
import { PDFExportComponent } from '@progress/kendo-angular-pdf-export';
import { combineLatest, Observable, of } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { IndustryClient } from 'src/app/_api/clients/industry.client';
import { UsIndustryDataClient } from 'src/app/_api/clients/usIndustryData.client';
import { EstimateIndustries } from 'src/app/_api/responses/estimate-industries.response';
import { IndustryTearSheet } from 'src/app/_api/responses/industry-tear-sheet.response';
import { MinimumDate } from 'src/app/_api/responses/minimum-date.response';
import { SubscriptionType } from 'src/app/_api/responses/user.response';
import { CommonDate, DateStruct } from 'src/app/_navigator/common/date-struct';
import { BenchmarkingService } from 'src/app/_navigator/data/service/benchmarking.service';
import { IndustryPdfExportService } from 'src/app/_navigator/data/service/industry-pdf-export.service';
import { DataStore } from 'src/app/_navigator/data/store/data.store';
import { ProductType } from 'src/app/_navigator/user/enum/product-type';
import { SubscriptionLevel } from 'src/app/_navigator/user/enum/subscription-level';
import { FastSpringUrl } from 'src/app/_navigator/user/model/fast-spring-url.model';
import { SubscriptionData } from 'src/app/_navigator/user/model/subscription.model';
import { UserStore } from 'src/app/_navigator/user/store/user.store';
import { UserUtil } from 'src/app/_navigator/user/util/user.util';
import { ValuesState } from 'src/app/_navigator/values-store/values-state';
import { IndustryScenario } from 'src/app/benchmarking/data/industry-scenario';
import { Notices } from './const/notices.const';
import { TearSheetService } from './data/tear-sheet.service';
import { CompositeData } from './models/composite-data.model';
import { StatisticsCategory } from './models/statistics-category.model';
import { TearSheetUtil } from './util/tear-sheet.util';
import { TrendsOverTimeService } from './data/trends-over-time.service';

import { ExcelExportComponent } from '@progress/kendo-angular-excel-export';
import { BenchmarkingExcelExportService } from 'src/app/estimate/exports/benchmarkingexcel-export.service';
import { EnumSubsType, UserSubscriptionTypes } from 'src/app/costofcapital-inputs/models/userSubscriptionTypes';


@Component({
    selector: 'tear-sheet',
    templateUrl: './tear-sheet.component.html'
})
export class TearSheetComponent extends SubscriberEntity implements OnInit {

    private subscriptionWithProPlusLevel: SubscriptionData | undefined;
    @ViewChild('usipdf') public usipdf!: PDFExportComponent;
    @Select(ValuesState.get) public valuesSelector!: Observable<MinimumDate | undefined>;
    public Util = Util;
    public statisticsCategories: StatisticsCategory[] = [];
    public dataThroughDate = '';
    public sicCode = '';
    public compositeData!: CompositeData;
    public sector = '';
    public isTearSheetReady = false;
    public isTearSheetAvailable = false;
    public notice = '';
    public modifiedDate?: string;
    public licenseeName?: string;
    public valuationDate?: string;
    public canScrollRight = false;
    public canScrollLeft = false;
    public showNavigationArrows = false;
    public modName = 'usiBenchmarking';
    public exportFileName!: string;
    public SubscriptionType: UserSubscriptionTypes = {} as UserSubscriptionTypes;


    constructor(
        private usIndustryDataClient: UsIndustryDataClient,
        private intlIndustryDataClient: IndustryClient,
        private dataStore: DataStore,
        private userStore: UserStore,
        private spinner: Spinner,
        public industryPdfExportService: IndustryPdfExportService,
        public tearSheetService: TearSheetService,
        public trendsOverTimeService: TrendsOverTimeService,
        private benchmarkingExcelExportService: BenchmarkingExcelExportService,
        public benchmarkingService: BenchmarkingService,
    ) {
        super();
    }

    private initialize(isIndustryApplicable: boolean, industries: EstimateIndustries[], valuationDate: string): Observable<void> {
        if (isIndustryApplicable === false) {
            this.tearSheetService.setNotice(Notices.IndustryNotInListChecked);
            return of(void 0);
        }

        if (industries.length === 0) {
            this.tearSheetService.setNotice(Notices.NoIndustrySelected);
            return of(void 0);
        }

        const codeIds: number[] = industries.map((x) => x.SicId);
        const internationalDataStart = new Date('09/30/2020').getTime();

        if (new Date(valuationDate).getTime() >= internationalDataStart) {
            const tearSheetDataScenarios: IndustryScenario[] = codeIds.map((codeId) => {
                return {
                    Currency: 'USD',
                    Region: 'United States',
                    IndustryCode: codeId
                };
            });

            return this.intlIndustryDataClient.create(valuationDate, tearSheetDataScenarios).onceDefined((data) => {
                this.initializeEstimateTearSheet(data, valuationDate);
            });
        }

        return this.usIndustryDataClient.tearSheet(valuationDate, codeIds).onceDefined((data) => {
            this.initializeEstimateTearSheet(data, valuationDate);
        });
    }

    private initializeEstimateTearSheet(data: IndustryTearSheet[], valuationDate: string): void {
        if (data.length === 0) {
            this.tearSheetService.setNotice(Notices.NoAnalysis);
            return;
        }

        if (data.length > 1) {
            this.dataStore.setCanScrollRight(true);
        }

        this.showNavigationArrows = data.length > 1;
        this.modifiedDate = `${moment().format('MM/DD/YYYY')}`;
        this.valuationDate = `${moment(valuationDate).format('MM/DD/YYYY')}`;

        this.tearSheetService.setTearSheetUserSubscription(this.subscriptionWithProPlusLevel);
        this.tearSheetService.setTearSheetContent(data[0]);
        this.tearSheetService.setTearSheetData(data);

        if (this.subscriptionWithProPlusLevel) {
            this.tearSheetService.setTrendsOverTimeIndustry([...data]);
        }

        if (this.tearSheetService && this.tearSheetService.getTearSheetContent() == null) {
            this.tearSheetService.setNotice(Notices.NoAnalysis);
            return;
        }

        this.tearSheetService.setIsTearSheetReady(true);

    }


    public ngOnInit(): void {

        // this.tearSheetService.setNotice(null);
        this.tearSheetService.setIsTearSheetReady(false);
        this.dataStore.triggerAllowExport(true);
        this.industryPdfExportService.setPdf(this.usipdf);


        combineLatest([
            this.userStore.user,
            this.valuesSelector
        ]).pipe(
            takeUntil(this.destroyed),
            map((x) => ({
                user: x[0],
                values: x[1]
            }))
        ).onceDefined((data) => {
            if (data.user == null || data.values == null) {
                return;
            }

            this.licenseeName = data.user.FullName;

            if (data.user.CompanyName) {
                this.licenseeName += `, ${data.user.CompanyName || ''}`;
            }

            // const usiSubData = data.user.subscriptionDetails.find((sub) => sub.productType === ProductType.Usi);
            // const iniSubData = data.user.subscriptionDetails.find((sub) => sub.productType === ProductType.Ini);

            const subscriptionFeatures = data.user.subscriptionDetails.filter(item => (item.productType === ProductType.Coc && item.isExpired === false) || (item.productType === ProductType.Ine && item.isExpired === false));
            const isCocProExists = subscriptionFeatures.find(s => s.productType === ProductType.Coc);
            const ineProExist = subscriptionFeatures.find(d => d.productType === ProductType.Ine);

            if (isCocProExists && ineProExist) {
                this.SubscriptionType.SubsType = EnumSubsType.IneAndCoc;
            }
            else if (isCocProExists) {
                this.SubscriptionType.SubsType = EnumSubsType.Coc;
            }
            else if (ineProExist) {
                this.SubscriptionType.SubsType = EnumSubsType.Ine;
            }


            this.subscriptionWithProPlusLevel = data.user.subscriptionDetails.find(
                (sub) => {
                    return sub.level === SubscriptionLevel.Pro ||
                        sub.level === SubscriptionLevel.Enterprise;
                }
            );

            // if (usiSubData == null && iniSubData == null) {
            //     const industry = this.tearSheetService.getTearSheetContent();
            //     // const usiFastSpringUrl: FastSpringUrl = {
            //     //     half: 'mod250',
            //     //     quarter: 'mod275',
            //     //     base: 'mod2'
            //     // };
            //     // const iniFastSpringUrl: FastSpringUrl = {
            //     //     half: 'mod450',
            //     //     quarter: 'mod475',
            //     //     base: 'mod4'
            //     // };
            //     // if (industry) {
            //     //     if (industry.Region === 'United States') {
            //     //         const storeUrl = data.user.getProRatedLink(ProductType.Usi, usiFastSpringUrl);
            //     //         const notice: string = Notices.PurchaseModuel2(storeUrl, 'US');
            //     //         this.tearSheetService.setNotice(notice);
            //     //     } else {
            //     //         const storeUrl = data.user.getProRatedLink(ProductType.Ini, iniFastSpringUrl);
            //     //         const notice: string = Notices.PurchaseModuel2(storeUrl, 'International');
            //     //         this.tearSheetService.setNotice(notice);
            //     //     }
            //     // }

            //     return;
            // }

            // this.dataStore.canScrollRight.pipe(takeUntil(this.destroyed)).while((canScrollRight) => {
            //     this.canScrollRight = canScrollRight;
            // });
            // this.dataStore.canScrollLeft.pipe(takeUntil(this.destroyed)).while((canScrollLeft) => {
            //     this.canScrollLeft = canScrollLeft;
            // });

            const initializing = this.dataStore.isIndustryAnalysis.onceDefined((isIndustryAnalysis) => {
                if (isIndustryAnalysis) {

                    if (this.benchmarkingService.benchmarkingScenarios.length) {

                        if (this.benchmarkingService.benchmarkingScenarios.length > 1) {
                            this.showNavigationArrows = true;
                            this.dataStore.setCanScrollRight(true);

                            if (this.tearSheetService.tearSheetIndex > 0) {
                                this.dataStore.setCanScrollLeft(true);
                            }
                        }

                        this.tearSheetService.setIsTearSheetReady(true);
                        return;
                    }

                    return this.dataStore.analysis.onceDefined(
                        (analysis) => this.initialize(analysis.IsIndustryApplicable, analysis.Industries, analysis.ValuationDate)
                    );
                }

                return this.dataStore.estimate.onceDefined((estimate) => {
                    if (data.user == null || data.values == null) {
                        return of(void 0);
                    }

                    const estimateValuationDate = CommonDate.fromString(estimate.ValuationDate).asMoment();
                    const basicUsiMinValuationDate = UserUtil.getSubscriptionMinimumValuationDate(
                        ProductType.Usi,
                        SubscriptionLevel.Basic,
                        data.values.UsIndustries,
                        data.user.subscriptionDetails
                    );
                    const proUsiMinValuationDate = UserUtil.getSubscriptionMinimumValuationDate(
                        ProductType.Usi,
                        SubscriptionLevel.Pro,
                        data.values.UsIndustries,
                        data.user.subscriptionDetails
                    );
                    const hasCocProUsiBasic = TearSheetUtil.hasBothSubscriptions(
                        data.user,
                        SubscriptionType.CocPro,
                        SubscriptionType.UsiBasic
                    );
                    const hasCocProUsiPro = TearSheetUtil.hasBothSubscriptions(data.user, SubscriptionType.CocPro, SubscriptionType.UsiPro);
                    const isUsiBasicOutOfRange = hasCocProUsiBasic && estimateValuationDate.isBefore(basicUsiMinValuationDate);
                    const isUsiProOutOfRange = hasCocProUsiPro && estimateValuationDate.isBefore(proUsiMinValuationDate);

                    if (isUsiBasicOutOfRange || isUsiProOutOfRange) {
                        this.tearSheetService.setNotice(Notices.DateOutOfRange);
                        return of(void 0);
                    }

                    return this.initialize(estimate.IsIndustryApplicable, estimate.Industries, estimate.ValuationDate);
                });
            });
            this.spinner.while(initializing);
        });
    }



    public exportExcel(component: ExcelExportComponent) {

        // if (this.SubscriptionType.SubsType == EnumSubsType.Coc) {
        //     this.exportFileName = 'usi-benchmarking-summary.xlsx';
        //     this.benchmarkingExcelExportService.benchMarkAnalysisName = 'usiBenchmarking'
        // } else {
        //     this.exportFileName = 'ini-benchmarking-summary.xlsx';
        //     this.benchmarkingExcelExportService.benchMarkAnalysisName = 'intlBenchmarking'
        // }
        this.exportFileName = 'industry-benchmarking.xlsx'
        this.benchmarkingExcelExportService.save(component);
    }


}
