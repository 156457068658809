import { Component, Input, OnInit } from '@angular/core';
import { ContextualNumber, ContextualString } from '@concurrency/core';
import { ComboboxDataType } from '../../data/enum/combobox-data-type.enum';
import { Direction } from '../../data/enum/direction.enum';
import { ComboboxData } from '../../data/model/combobox-data.model';
import { CommonUserInputComponent } from './common-user-input.component';

@Component({
    selector: 'common-combobox',
    templateUrl: './common-combobox.component.html'
})
export class CommonComboboxComponent extends CommonUserInputComponent implements OnInit {
    @Input() public comboboxData: ComboboxData[] = [];
    public isDropdownActive = false;
    public Direction = Direction;
    public placeholder!: string;
    public ngOnInit(): void {
        const c = this.config;
        if (c.placeholder != null) {
            this.placeholder = c.placeholder;
        } else {
            this.placeholder = '';
        }
    }
    public arrowSelectItem(direction: Direction): void {
        const index = this.comboboxData.findIndex((x) => x.source === this.model.context);

        if (index == null) {
            return;
        }

        let targetIndex = index;
        let keepTrying = true;
        while (keepTrying) {
            targetIndex = direction === Direction.Down
                ? targetIndex + 1
                : targetIndex - 1;

            const targetItem = this.comboboxData[targetIndex];

            if (targetItem) {
                if (targetItem.disabledText == null) {
                    this.selectItem(targetItem);
                    keepTrying = false;
                }
            } else {
                keepTrying = false;
            }
        }
    }

    public selectItem(item: ComboboxData): void {
        if (!item.name.includes('N/A')) {
            if (item.type === ComboboxDataType.Country || item.type === ComboboxDataType.String) {
                this.model = new ContextualString(item.value, null, item.id);
            } else {
                this.model = new ContextualNumber(item.value, item.source, item.dataAsOf, 2, true, item.context);
            }

            this.modelChange.emit(this.model);
            this.input.nativeElement.focus();
        }

    }
    public clearSource(): void {
        this.model.context = null;
        this.model.source = null;
        this.model.dataAsOf = null;
    }
}
