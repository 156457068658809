import { Router } from '@angular/router';
import { Group } from './group';
import { RouteProgression } from './route-progression';

export class RouteUtil {
    public static routeTo(group: Group, router: Router): void {
        if (group === Group.Capm) {
            router.navigate(['/international-estimate/coe-summary/capm']);
        } else if (group === Group.FisherEffect) {
            router.navigate(['/international-estimate/coe-summary/fisher-effect']);
        } else if (group === Group.Crp) {
            router.navigate(['/international-estimate/coe-summary/crp']);
        } else if (group === Group.CostOfDebtCapital) {
            router.navigate(['/international-estimate/cod-summary/capital']);
        } else if (group === Group.CostOfDebtFisherEffect) {
            router.navigate(['/international-estimate/cod-summary/fisher-effect']);
        } else if (group === Group.CostOfDebtConclusion) {
            router.navigate(['/international-estimate/cod-summary/conclusion']);
        } else if (group === Group.WaccCapital) {
            router.navigate(['/international-estimate/wacc-summary/capital']);
        } else if (group === Group.WaccConclusion) {
            router.navigate(['/international-estimate/wacc-summary/conclusion']);
        } else {
            router.navigate(['/international-estimate']);
        }
    }

    public static previous(currentRoute: string, router: Router): void {
        const previousRouteIndex = RouteProgression.Routes.indexOf(currentRoute) - 1;
        if (previousRouteIndex < 0) {
            return;
        }

        const previousRoute = RouteProgression.Routes[previousRouteIndex];
        router.navigate([previousRoute]);
    }

    public static saveAndContinue(currentRoute: string, router: Router): void {
        const nextRouteIndex = RouteProgression.Routes.indexOf(currentRoute) + 1;
        const nextRoute = RouteProgression.Routes[nextRouteIndex];
        router.navigate([nextRoute]);
    }

    public static getActiveGroup(router: Router): Group {
        if (router.url.includes('/international-estimate/coe-summary/capm')) {
            return Group.Capm;
        } else if (router.url.includes('/international-estimate/coe-summary/fisher-effect')) {
            return Group.FisherEffect;
        } else if (router.url.includes('/international-estimate/coe-summary/crp')) {
            return Group.Crp;
        } else if (router.url.includes('/international-estimate/cod-summary/capital')) {
            return Group.CostOfDebtCapital;
        } else if (router.url.includes('/international-estimate/cod-summary/fisher-effect')) {
            return Group.CostOfDebtFisherEffect;
        } else if (router.url.includes('/international-estimate/cod-summary/conclusion')) {
            return Group.CostOfDebtConclusion;
        } else if (router.url.includes('/international-estimate/wacc-summary/capital')) {
            return Group.WaccCapital;
        } else if (router.url.includes('/international-estimate/wacc-summary/conclusion')) {
            return Group.WaccConclusion;
        } else {
            return Group.None;
        }
    }
}
