import { Component, Input, OnInit } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { TrendsOverTimeService } from './data/trends-over-time.service';

@Component({
    selector: 'line-chart',
    templateUrl: 'line-chart.component.html'
})
export class LineChartComponent implements OnInit {
    @Input() public chart!: Chart;
    @Input() public chartOptions!: Highcharts.Options;

    constructor(
        private trendsOverTimeService: TrendsOverTimeService
    ) {}

    public ngOnInit(): void {
        this.chart = new Chart(this.chartOptions);

        this.trendsOverTimeService.setCharts = this.chart;
    }
}
