import { Injectable } from '@angular/core';
import {
    ExcelExportComponent,
    Workbook,
    WorkbookSheetColumn,
    WorkbookSheetRow,
    WorkbookSheetRowCell
} from '@progress/kendo-angular-excel-export';
import { ExcelExportUtil } from 'src/app/estimate/exports/util/excel-export.util';
import { Regionrisk } from '../../../benchmarking/data/trends-over-time-table-data';
import { TearSheetService } from '../../tear-sheet/data/tear-sheet.service';

@Injectable()
export class TrendsOverTimecountryriskExcelExportService {
    private moduleName = 'International-estimate'; // TODO rename this based on subscription or url
    public columnWidth = 125;       // width of each cell in grid system
    public krollblue = '#14487f'; // color of all blue items
    public numberFormat = '0.00';   // number format for cells
    constructor(
        private tearSheetService: TearSheetService
    ) { }

    private saveWorksheet(component: ExcelExportComponent, tableData: Regionrisk, countryriskarray: any[] = [], CcrAvgarrayeur: any[] = [], CcrAvgarraymsci: any[] = [], CcrAvgarrayspc: any[] = [], dateArray: any[] = [], YieldSpreadeur: any[] = [], YieldSpreadmsci: any[] = [], YieldSpreadspc: any[] = [], VolatilityAvgeur: any[] = [], VolatilityAvgmsci: any[] = [], VolatilityAvgspc: any[] = [], YieldSpread: any[] = [], volatality: any[] = []): void {
        let excelExport = this.getWorksheet(tableData, countryriskarray, CcrAvgarrayeur, CcrAvgarraymsci, CcrAvgarrayspc, dateArray, YieldSpreadeur, YieldSpreadmsci, YieldSpreadspc, VolatilityAvgeur, VolatilityAvgmsci, VolatilityAvgspc, YieldSpread, volatality);
        excelExport = this.tearSheetService.setDefaultExcel(excelExport);
        component.save(excelExport);
    }

    private getWorksheet(tableData: Regionrisk, countryriskarray: any[] = [], CcrAvgarrayeur: any[] = [], CcrAvgarraymsci: any[] = [], CcrAvgarrayspc: any[] = [], dateArray: any[] = [], YieldSpreadeur: any[] = [], YieldSpreadmsci: any[] = [], YieldSpreadspc: any[] = [], VolatilityAvgeur: any[] = [], VolatilityAvgmsci: any[] = [], VolatilityAvgspc: any[] = [], YieldSpread: any[] = [], volatality: any[] = []): Workbook {
        const workbook = new Workbook();

        workbook.sheets = [{
            name: `Trends Over Time`,
            columns: Array(8).fill({ width: this.columnWidth }),
            rows: [
                ...this.getHeader(),
                ...this.buildTableData(tableData, countryriskarray, CcrAvgarrayeur, CcrAvgarraymsci, CcrAvgarrayspc, dateArray, YieldSpreadeur, YieldSpreadmsci, YieldSpreadspc, VolatilityAvgeur, VolatilityAvgmsci,
                    VolatilityAvgspc, YieldSpread, volatality),
                ...ExcelExportUtil.getFooter(this.moduleName)
            ]
        }];

        return workbook;
    }

    private getHeader(): WorkbookSheetRow[] {

        const row: WorkbookSheetRow[] = [{
            cells: [{
                value: 'Trends Over Time',
                bold: true,
                colSpan: 6,
                borderBottom: { size: 2 }
            }]
        }];

        return row;
    }

    private getCompLabels(tabledata: Regionrisk): WorkbookSheetRow[] {
        const country = 'Country';
        const EuromoneyRegion = 'EuromoneyRegion';
        const MSCI = 'MSCI';
        const Sp = 'Sp';

        // TODO create workbook sheet row builder
        const row: WorkbookSheetRow[] = [
            {
                cells: [{
                    value: '',
                    colSpan: 2
                }]
            },
            {
                cells: [{
                    value: '',
                    colSpan: 2
                }]
            },
            {
                cells: [{
                    value: country + '(' + tabledata.trendsOverTimeData[0] + ')',
                    colSpan: 2
                }]
            },
            {
                cells: [{
                    value: EuromoneyRegion + '(' + tabledata.trendsOverTimeData[1] + ')',
                    colSpan: 2
                }]
            },
            {
                cells: [{
                    value: MSCI + '(' + tabledata.trendsOverTimeData[2] + ')',
                    colSpan: 2
                }]
            },
            {
                cells: [{
                    value: Sp + '(' + tabledata.trendsOverTimeData[3] + ')',
                    colSpan: 2
                }]
            }];

        return row;
    }

    private buildMetricTableRow(tableData: string): WorkbookSheetRow[] {
        const tableRow: WorkbookSheetRow[] = [];
        let row: WorkbookSheetRow = {};
        row.cells = [];
        let cl: WorkbookSheetColumn = {};

        // TODO construct builder to build this out and return the entire result row
        cl = ExcelExportUtil.createCell(tableData, 1, this.numberFormat);
        row.cells.push(cl);

        tableRow.push(row);

        row = {};
        row.cells = [];

        return tableRow;
    }

    private buildScenarioHeader(table: string): WorkbookSheetRow[] {

        const row: WorkbookSheetRow[] = [{
            cells: [{
                value: table,
                colSpan: 4,
                borderBottom: { size: 1, color: this.krollblue },
                bold: true
            }
            ]
        }];

        return row;
    }

    private buildDataAsOfTitle(title: string, width: number): WorkbookSheetRow[] {

        const row: WorkbookSheetRow[] = [{
            cells: [{
                value: title,
                colSpan: width,
                borderBottom: { size: 3 },
                bold: true
            }]
        }];

        return row;
    }

    private buildTableData(tableData: Regionrisk, countryriskarray: any[] = [], CcrAvgarrayeur: any[] = [], CcrAvgarraymsci: any[] = [], CcrAvgarrayspc: any[] = [], dateArray: any[] = [], YieldSpreadeur: any[] = [], YieldSpreadmsci: any[] = [], YieldSpreadspc: any[] = [], VolatilityAvgeur: any[] = [], VolatilityAvgmsci: any[] = [], VolatilityAvgspc: any[] = [], YieldSpread: any[] = [], volatality: any[] = []): WorkbookSheetRow[] {
        let resultData: WorkbookSheetRow[] = [];
        let scenarioHeader: WorkbookSheetRow[] = [];
        let rightSideTableData: WorkbookSheetRow[] = [];
        let combineCompLables: WorkbookSheetRow[] = [];
        const titleWidth = 1;
        let tableTitle;
        let tableRow;
        for (let i = 0; i < 3; i++) {
            if (i === 0) {
                scenarioHeader = this.buildScenarioHeader('Country Credit Rating Model');
                for (let j = 0; j < dateArray.length; j++) {
                    tableTitle = this.buildDataAsOfTitle(dateArray[j], titleWidth);
                    if (countryriskarray.length > 0) {
                        tableRow = this.buildMetricTableRow(countryriskarray[j].y);
                        tableTitle = tableTitle.concat(tableRow);
                    }
                    if (CcrAvgarrayeur.length > 0) {
                        const ccreur = this.buildMetricTableRow(CcrAvgarrayeur[j].y);
                        tableTitle = tableTitle.concat(ccreur);
                    }
                    if (CcrAvgarraymsci.length > 0) {
                        const ccrmsci = this.buildMetricTableRow(CcrAvgarraymsci[j].y);
                        tableTitle = tableTitle.concat(ccrmsci);
                    }
                    if (CcrAvgarrayspc.length > 0) {
                        const ccrspc = this.buildMetricTableRow(CcrAvgarrayspc[j].y);
                        tableTitle = tableTitle.concat(ccrspc);
                    }
                    if (rightSideTableData.length > 0) {
                        rightSideTableData = this.concatenateRows(rightSideTableData, tableTitle);
                    } else {
                        rightSideTableData = tableTitle;
                    }

                }
            }
            if (i === 1) {
                scenarioHeader = this.buildScenarioHeader('Country Yield Spread Model');
                for (let j = 0; j < dateArray.length; j++) {

                    tableTitle = this.buildDataAsOfTitle(dateArray[j], titleWidth);
                    if (YieldSpread.length > 0) {
                        tableRow = this.buildMetricTableRow(YieldSpread[j].y);
                        tableTitle = tableTitle.concat(tableRow);
                    }
                    if (YieldSpreadeur.length > 0) {
                        const ccreur = this.buildMetricTableRow(YieldSpreadeur[j].y);
                        tableTitle = tableTitle.concat(ccreur);
                    }
                    if (YieldSpreadmsci.length > 0) {
                        const ccrmsci = this.buildMetricTableRow(YieldSpreadmsci[j].y);
                        tableTitle = tableTitle.concat(ccrmsci);
                    }
                    if (YieldSpreadspc.length > 0) {
                        const ccrspc = this.buildMetricTableRow(YieldSpreadspc[j].y);

                        tableTitle = tableTitle.concat(ccrspc);
                    }
                    if (rightSideTableData.length > 0) {
                        rightSideTableData = this.concatenateRows(rightSideTableData, tableTitle);
                    } else {
                        rightSideTableData = tableTitle;
                    }

                }
            }
            if (i === 2) {
                scenarioHeader = this.buildScenarioHeader('Relative Volatility Model');
                for (let j = 0; j < dateArray.length; j++) {

                    tableTitle = this.buildDataAsOfTitle(dateArray[j], titleWidth);
                    if (volatality.length > 0) {
                        tableRow = this.buildMetricTableRow(volatality[j].y);
                        tableTitle = tableTitle.concat(tableRow);
                    }
                    if (VolatilityAvgeur.length > 0) {
                        const ccreur = this.buildMetricTableRow(VolatilityAvgeur[j].y);
                        tableTitle = tableTitle.concat(ccreur);
                    }
                    if (VolatilityAvgmsci.length > 0) {
                        const ccrmsci = this.buildMetricTableRow(VolatilityAvgmsci[j].y);
                        tableTitle = tableTitle.concat(ccrmsci);
                    }
                    if (VolatilityAvgspc.length > 0) {
                        const ccrspc = this.buildMetricTableRow(VolatilityAvgspc[j].y);
                        tableTitle = tableTitle.concat(ccrspc);
                    }
                    if (rightSideTableData.length > 0) {
                        rightSideTableData = this.concatenateRows(rightSideTableData, tableTitle);
                    } else {
                        rightSideTableData = tableTitle;
                    }

                }
            }
            rightSideTableData = scenarioHeader.concat(rightSideTableData);
            combineCompLables = this.concatenateRows(this.getCompLabels(tableData), rightSideTableData);

            resultData = resultData.concat(combineCompLables);
            rightSideTableData = [];
        }

        return resultData;

    }

    private concatenateRows(rowDataLeft: WorkbookSheetRow[], rowDataRight: WorkbookSheetRow[]): WorkbookSheetRow[] {
        const leftWidth: number = ExcelExportUtil.getWidthOfWidestRow(rowDataLeft);

        const rowData: WorkbookSheetRow[] = [];
        for (let i = 0; i < rowDataLeft.length; ++i) {
            if (i < rowDataRight.length) { // row in right side, add it to the left
                const leftCells = rowDataLeft[i].cells;
                const rightCells = rowDataRight[i].cells;

                if (leftCells && rightCells) {
                    let newCells: WorkbookSheetRowCell[] = [];

                    newCells = leftCells.concat(rightCells);
                    rowDataLeft[i].cells = newCells;
                }

                rowData.push(rowDataLeft[i]);
            }
        }

        //
        //  process any rows on right beyond the left
        //
        for (let j: number = rowDataLeft.length; j < rowDataRight.length; ++j) {
            const rightCells = rowDataRight[j].cells;

            if (rightCells) {
                let newCells: WorkbookSheetRowCell[] = ExcelExportUtil.getEmptyCell(leftWidth);
                newCells = newCells.concat(rightCells);
                rowDataRight[j].cells = newCells;
                rowData.push(rowDataRight[j]);
            }

        }

        return rowData;
    }

    public save(component: ExcelExportComponent, tabledata: Regionrisk, countryriskarray: any[] = [], CcrAvgarrayeur: any[] = [], CcrAvgarraymsci: any[] = [], CcrAvgarrayspc: any[] = [], dateArray: any[] = [], YieldSpreadeur: any[] = [], YieldSpreadmsci: any[] = [], YieldSpreadspc: any[] = [], VolatilityAvgeur: any[] = [], VolatilityAvgmsci: any[] = [], VolatilityAvgspc: any[] = [], YieldSpread: any[] = [], volatality: any[] = []): void {
        this.saveWorksheet(component, tabledata, countryriskarray, CcrAvgarrayeur, CcrAvgarraymsci, CcrAvgarrayspc, dateArray, YieldSpreadeur, YieldSpreadmsci, YieldSpreadspc, VolatilityAvgeur, VolatilityAvgmsci, VolatilityAvgspc, YieldSpread, volatality);
    }
}
