import { OnInit, Directive } from '@angular/core';
import { ModalManager, Spinner, SubscriberEntity } from '@concurrency/angular';
import { takeUntil } from 'rxjs/operators';
import { Estimate } from 'src/app/_navigator/data/model/estimate.model';
import { Scenario } from 'src/app/_navigator/data/model/scenario.model';
import { DataStore } from 'src/app/_navigator/data/store/data.store';
import { HelpService } from 'src/app/_navigator/help/help.service';
import { PrimeManager } from 'src/app/_navigator/modal/pmodal.manager';
import { AppInjector } from 'src/app/app-injector';

@Directive()
export abstract class BaseStudyComponent extends SubscriberEntity implements OnInit {
    protected abstract hasRegressionToggle: boolean;

    protected dataStore: DataStore;
    protected spinner: Spinner;
    protected modalManager: ModalManager;
    protected helpService: HelpService;
    protected primeManager: PrimeManager;
    public estimate?: Estimate;
    public scenarios: Scenario[] = [];
    public isHidden: any = {};
    public isRename: any = {};
    public rename = '';
    public isKeyShown = true;
    public dataTable?: any;

    // TODO: Consider implementing a decorator to automatically handle property injection
    constructor() {
        super();
        const injector = AppInjector.getInjector();

        // NOTE: There is no documented alternative to this method call
        /* tslint-disable import/no-deprecated */
        this.dataStore = injector.get(DataStore);
        this.spinner = injector.get(Spinner);
        this.modalManager = injector.get(ModalManager);
        this.helpService = injector.get(HelpService);
        this.primeManager = injector.get(PrimeManager);
    }

    public ngOnInit(): void {
        // TODO: Obviate takeUntil by using Async pipes and local Observable streams
        this.dataStore.estimate.pipe(takeUntil(this.destroyed)).whileDefined((estimate) => {
            this.estimate = estimate;
            this.scenarios = estimate.Scenarios;
        });
    }

    public delete(scenario: Scenario): void {
        this.spinner.begin();
        const request = this.dataStore.deleteScenario(scenario);
        request.once(() => this.spinner.end());
    }

    public beginRename(scenario: Scenario): void {
        this.isRename = {};
        this.isRename[scenario.Id] = true;
        this.rename = scenario.Name || '';
    }

    public cancelRename(): void {
        this.isRename = {};
        this.rename = '';
    }

    public submitRename(scenario: Scenario): void {
        if (this.rename === '' || scenario.Name === this.rename) {
            this.isRename = {};
            return;
        }

        if (this.scenarios.some((x) => x.Name.toLowerCase() === this.rename.toLowerCase())) {
            return;
        }

        const name = this.rename;

        this.spinner.begin();
        const request = this.dataStore.renameScenario(scenario, name);
        request.once(() => {
            this.isRename = {};
            scenario.Name = name;
            this.spinner.end();
        });
    }

    // TODO: This is copy+pasted from equation
    // Note: This component (may be?) is destroyed as part of cloning a scenario, the subscribe below
    //  implicitly will fire no matter what. If we had used the SubscriberEntity's subscribe,
    //  this would not work. We should make an explicit method to handle these scenarios.
    public duplicate(scenario: Scenario): void {
        this.spinner.begin();
        const request = this.dataStore.cloneScenario(scenario);
        request.subscribe(() => this.spinner.end());
    }

    public update(estimate: Estimate): void {
        const request = this.dataStore.updateEstimate(estimate);
        this.spinner.while(request);
    }
}
