import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { Industry } from 'src/app/_api/responses/us-industry.response';
import { IndustryDataSharingService } from 'src/app/estimate/studies/service/industry-data-sharing.service';
import { TrendsOverTimeService } from 'src/app/home/tear-sheet/data/trends-over-time.service';

@Component({
    selector: 'child-industries',
    templateUrl: './child-industries.component.html'
})

export class ChildIndustriesComponent implements OnInit, OnChanges {
    private _sharedIndustries = new Subject<void>();
    public industryName: Industry[] = new Array(3);
    public sharedIndustries = this._sharedIndustries.asObservable();
    public userSelectionListLocal!: Industry[];
    public SicGicCode = 'GICS';

    @Input() public userSelectedList!: Industry[];
    @Output() public removeIndustrySelection = new EventEmitter<Industry>();

    constructor(
        public industryDataSharingService: IndustryDataSharingService,
        public trendsOverTimeService: TrendsOverTimeService
    ) { }

    public ngOnInit(): void {
        this.userSelectionListLocal = this.userSelectedList;
    }

    public ngOnChanges(): void {
        this.userSelectionListLocal = this.userSelectedList;
    }

    public removeSelectedIndustry = (codeId: number): void => {
        this.removeIndustrySelection.emit({
            CodeId: codeId,
            GicIndustryCode: '',
            Sector: '',
            SicIndustryCode: ''
        });
    }
}
