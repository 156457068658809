import { MenuDetails } from './home.menu.model';
import { MenuType } from './menu.type';

const MenuData: MenuDetails[] = [
    {
        optionName: 'Dashboard',
        path: '/dashboard',
        children: [],
        icon: 'ng-zorro:dashboard',
        type: MenuType.DashBoard,
        isDisabled: false,
    },
    {
        optionName: 'View Data',
        path: '',
        icon: 'ng-zorro:pieChart',

        children: [
            {
                optionName: 'Cost of Capital Inputs',
                path: '/coc-inputs',
                children: [],
                type: MenuType.CostofCapitalInputs,
                isDisabled: true,
            },
            {
                optionName: 'Industry Benchmarking',
                path: '',
                children: [],
                type: MenuType.UsIndustryBenchmarking,
                isDisabled: true,

            }
        ]
    },
    {
        optionName: 'Cost Of Capital Tools',
        path: '',
        children: [
            {
                optionName: 'U.S. Cost of Capital Estimates',
                path: '',
                children: [],
                type: MenuType.UsCostOfCapitalEstimates,
                isDisabled: true,
            },
            {
                optionName: 'International Cost of Capital Estimates',
                path: '',
                children: [],
                type: MenuType.InternationalCostOfCapitalEstimate,
                isDisabled: true,
            },
            {
                optionName: 'Company Level Betas',
                path: '',
                children: [],
                type: MenuType.CompanyLevelBeta,
                isDisabled: true


            },
            {
                optionName: 'My Previously Created Estimates',
                path: '/estimates',
                children: [],
                type: MenuType.PreviousEstimates,
                isDisabled: true
            }
        ],
        icon: 'ng-zorro:tools'
    },
    {
        optionName: 'Resource Library',
        path: '/resources-library',
        children: [],
        icon: 'ng-zorro:file',
        isDisabled: true
    },

    {
        optionName: 'Helpful Links',
        path: '',
        icon: 'ng-zorro:link',
        children: []
    }


]


export default MenuData;