import * as moment from 'moment';

import { ComponentFactoryResolver, Injectable, ViewChild } from '@angular/core';
import { Util } from '@concurrency/core';
import { PDFExportComponent } from '@progress/kendo-angular-pdf-export';
import { combineLatest } from 'rxjs';
import { TearSheetService } from 'src/app/home/tear-sheet/data/tear-sheet.service';
import { Estimate } from '../../../_navigator/data/model/estimate.model';
import { DataStore } from '../../../_navigator/data/store/data.store';
import { User } from '../../../_navigator/user/model/user.model';
import { UserStore } from '../../../_navigator/user/store/user.store';
import { Summary } from '../../results/summary';
import { ExcelExportUtil } from '../util/excel-export.util';
import { ScenarioHelper } from './util/scenario-helper.util';
import { summaryOptions } from './util/summary-export.util';
import { headerCellHeight, headerFontSize, krollblue, normalCellHeight, normalFontSize, smallFontSize } from './util/summary-helpers';
import { CommonExcelExportUtil } from 'src/app/_navigator/common/common-excel-export-util';

// TODO: Clean-up this file. For historical reasons, this file contains gross violations of
//    both strict mode and tslint rules, so we disable tslint.

/* eslint-disable */
@Injectable()
export class ExcelExportService {
    @ViewChild('pdf') public pdf!: PDFExportComponent;
    public scenarioHelper: ScenarioHelper = new ScenarioHelper();
    public estimate: Estimate = <any>{};
    public user!: User;
    public modifiedDate?: string;
    public header: any = [];

    public summary = new Summary(this.resolver, this.dataStore);

    public inputPanes = [
        [
            this.summary.generalInputs,
            this.summary.sizeMeasures,
            this.summary.riskMeasures
        ], [
            this.summary.crspStudy,
            this.summary.rprStudy
        ]
    ];

    constructor(
        private resolver: ComponentFactoryResolver,
        private dataStore: DataStore,
        private userStore: UserStore,
        private tearSheetService: TearSheetService
    ) { }

    public save(component: any): void {
        const dataRequest = this.dataStore.estimate.whileDefined((estimate) => {
            this.estimate = estimate;
            // Calculate the modified date for the PDF watermark
            const date = moment(this.estimate.Updated).format('MM/DD/YYYY');
            const time = moment(this.estimate.Updated).format('h:mm a');

            this.modifiedDate = `${date} at ${time}`; // `
            this.summary.summarize(estimate);
        });

        const userRequest = this.userStore.user.onceDefined((user) => this.user = user);

        combineLatest([dataRequest, userRequest]).while(() => this.generateExcelExport(component));
    }

    // TODO possibly rename any type
    private buildSummaryHeader(): any[] {
        const header: any[] = [];

        header.push(
            {
                height: headerCellHeight,
                cells: [
                    {
                        value: 'General Inputs',
                        colSpan: 2,
                        color: krollblue,
                        fontSize: headerFontSize,
                        bold: 'true',
                        borderBottom: { size: 1, color: krollblue }
                    }
                ]
            },
            {
                height: normalCellHeight,
                cells: [
                    {
                        value: 'Estimate Name', fontSize: normalFontSize, bold: 'true'
                    },
                    {
                        value: this.estimate.Name, fontSize: normalFontSize, bold: 'true'
                    },
                ]
            }
        )

        if (this.summary.industries) {
            this.summary.industries.forEach((industry) => {
                header.push({
                    height: normalCellHeight,
                    cells: [
                        { value: 'Industry', fontSize: normalFontSize, bold: 'true' },
                        { value: industry, fontSize: normalFontSize },
                    ]
                });
            });
        }

        header.push({
            height: normalCellHeight,
            cells: [
                { value: 'Valuation Date', fontSize: normalFontSize, bold: 'true' },
                { value: moment(this.estimate.ValuationDate).format('MM/DD/YYYY'), fontSize: normalFontSize },
            ]
        });

        return header;
    }

    private generateExcelExport(component: any): void {
        this.header = [];

        // Loop through to get Size Measure and Risk Measure Inputs
        const estSummarySizeMeasureInputs: any[] = [];
        const estSummaryRiskMeasureInputs: any[] = [];

        for (let i = 0; i < this.inputPanes.length; i++) {
            for (let x = 0; x < this.inputPanes[i].length; x++) {
                const items = Util.selectMany(this.inputPanes[i][x].categories, x => x.items);
                // Size Measures
                if (this.inputPanes[i][x].name.startsWith('Size Measures')) {
                    items.forEach(function (theItemVal) {
                        estSummarySizeMeasureInputs.push(
                            {
                                height: normalCellHeight,
                                cells: [
                                    { value: theItemVal.name, fontSize: normalFontSize },
                                    { textAlign: 'right', value: theItemVal.value, fontSize: normalFontSize }
                                ]
                            }
                        );
                    });
                }
                // Risk Measures
                if (this.inputPanes[i][x].name.startsWith('Risk Measures')) {
                    items.forEach(function (theItemVal) {
                        estSummaryRiskMeasureInputs.push(
                            {
                                height: normalCellHeight,
                                cells: [
                                    { value: theItemVal.name, fontSize: normalFontSize },
                                    { textAlign: 'right', value: theItemVal.value, format: '#.00%', fontSize: normalFontSize }
                                ]
                            }
                        );

                    });
                }
            }
        }

        const applyBottomBorder = (cell: any): void => {
            cell.bold = true;
            cell.color = '#F00';
            cell.borderBottom = 'size:2';
        }

        const sources = ExcelExportUtil.getSheet('Data Sources', 40, 600);
        sources.rows.push(ExcelExportUtil.getRow(''));

        for (let category of this.summary.sources) {

            const categoryRow = ExcelExportUtil.getRow('', category.name)
            applyBottomBorder(categoryRow.cells[1]);
            sources.rows.push(categoryRow);
            sources.rows.push(ExcelExportUtil.getRow('', ''));

            for (let group of category.groups) {

                const groupRow = ExcelExportUtil.getRow('', group.name)
                groupRow.cells[1].bold = true;
                sources.rows.push(groupRow);

                for (let item of group.sources) {

                    sources.rows.push(ExcelExportUtil.getRow('', item));
                }

                sources.rows.push(ExcelExportUtil.getRow('', ''));
            }
        }

        let options = summaryOptions(this.header, {
            scenarioHelper: this.scenarioHelper,
            summary: this.summary,
            generalInputsHeader: this.buildSummaryHeader(),
            estimateSummaries: {
                estSummaryRiskMeasureInputs,
                estSummarySizeMeasureInputs,
            }
        });
        options = CommonExcelExportUtil.getUpdatedCopyRigtsSheet(options);
        options = this.tearSheetService.setDefaultExcel(options);
        component.save(options);
    }
}

