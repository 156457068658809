import { SummaryOptions } from '../types/summary-options';
import { headerCellHeight, headerFontSize, krollblue, krollshadow } from '../util/summary-helpers';

export function keRPRRows(options: SummaryOptions, displayNotes: boolean): any[] {
    const rows: any[] = [];

    return rows.concat([
        {
            height: headerCellHeight,
            cells: [
                {
                    value: 'RPR CAPM + Size Premium',
                    color: krollblue,
                    fontSize: headerFontSize,
                    bold: 'true',
                    borderBottom: { size: 1, color: krollshadow },
                    colSpan: 10
                }
            ]
        },
        {
            cells: [
                { value: 'Size Measure', bold: 'true' },
                { value: 'Ke', bold: 'true' },
                { value: '=', bold: 'true' },
                { value: 'Rf', bold: 'true' },
                { value: '+', bold: 'true' },
                { value: 'B', bold: 'true' },
                { value: '*', bold: 'true' },
                { value: 'ERP', bold: 'true' },
                { value: '+', bold: 'true' },
                { value: 'RPs', bold: 'true' },
                options.scenarioHelper.createNoteHeaderRow(displayNotes)
            ]
        }
    ]).concat(options.scenarioHelper.RPRCAPM(options.summary.levered.studyGroups, displayNotes))
        .concat(
            {
                height: headerCellHeight,
                cells: [
                    {
                        value: 'Build-up 1 (Levered)',
                        color: krollblue,
                        fontSize: headerFontSize,
                        bold: 'true',
                        borderBottom: { size: 1, color: krollshadow },
                        colSpan: 10
                    }
                ]
            },
            {
                cells: [
                    // TODO Make this a array of const strings for the value
                    { value: 'Size Measure', bold: 'true' },
                    { value: 'Ke', bold: 'true' },
                    { value: '=', bold: 'true' },
                    { value: 'Rf', bold: 'true' },
                    { value: '+', bold: 'true' },
                    { value: 'RPm+s', bold: 'true' },
                    { value: '+', bold: 'true' },
                    { value: 'ERP Adjustment', bold: 'true' },
                    options.scenarioHelper.createNoteHeaderRow(displayNotes)
                ]
            }
        ).concat(options.scenarioHelper.RPRBuildUp1(options.summary.levered.studyGroups, displayNotes))
        .concat(
            {
                height: headerCellHeight,
                cells: [
                    {
                        value: 'Build-up 1 (Unlevered)',
                        color: krollblue,
                        fontSize: headerFontSize,
                        bold: 'true',
                        borderBottom: { size: 1, color: krollshadow },
                        colSpan: 10
                    }
                ]
            },
            {
                cells: [
                    { value: 'Size Measure', bold: 'true' },
                    { value: 'Ke', bold: 'true' },
                    { value: '=', bold: 'true' },
                    { value: 'Rf', bold: 'true' },
                    { value: '+', bold: 'true' },
                    { value: 'RPm+s', bold: 'true' },
                    { value: '+', bold: 'true' },
                    { value: 'ERP Adjustment', bold: 'true' },
                    options.scenarioHelper.createNoteHeaderRow(displayNotes)
                ]
            }
        ).concat(options.scenarioHelper.RPRBuildUp1Unlevered(options.summary.unlevered.studyGroups, displayNotes))
        .concat(
            {
                height: headerCellHeight,
                cells: [
                    {
                        value: 'Build-up 1 (Relevered) *',
                        color: krollblue,
                        fontSize: headerFontSize,
                        bold: 'true',
                        borderBottom: { size: 1, color: krollshadow },
                        colSpan: 10
                    }
                ]
            },
            {
                cells: [
                    { value: 'Size Measure', bold: 'true' },
                    { value: 'Ke', bold: 'true' },
                    { value: '=', bold: 'true' },
                    { value: 'Rf', bold: 'true' },
                    { value: '+', bold: 'true' },
                    { value: 'RPm+s', bold: 'true' },
                    { value: '+', bold: 'true' },
                    { value: 'ERP Adjustment', bold: 'true' },
                    options.scenarioHelper.createNoteHeaderRow(displayNotes)
                ]
            }
        ).concat(options.scenarioHelper.RPRBuildup1Relevered(options.summary.relevered.studyGroups, displayNotes))
        .concat([
            {
                cells: [
                    {
                        value: 'relevered using a d/e ratio of __%”?'
                    }
                ]
            },
            {
                cells: [
                    { value: '' }
                ]
            }
        ])
        .concat(
            {
                height: headerCellHeight,
                cells: [
                    {
                        value: 'Build-up 2',
                        color: krollblue,
                        fontSize: headerFontSize,
                        bold: 'true',
                        borderBottom: { size: 1, color: krollshadow },
                        colSpan: 10
                    }
                ]
            },
            {
                cells: [
                    { value: 'Size Measure', bold: 'true' },
                    { value: 'Ke', bold: 'true' },
                    { value: '=', bold: 'true' },
                    { value: 'Rf', bold: 'true' },
                    { value: '+', bold: 'true' },
                    { value: 'ERP', bold: 'true' },
                    { value: '+', bold: 'true' },
                    { value: 'RPi', bold: 'true' },
                    { value: '+', bold: 'true' },
                    { value: 'RPs', bold: 'true' },
                    options.scenarioHelper.createNoteHeaderRow(displayNotes)
                ]
            }
        ).concat(options.scenarioHelper.RPRBuildUp2(options.summary.levered.studyGroups, displayNotes))
        .concat(
            {
                height: headerCellHeight,
                cells: [
                    {
                        value: 'Build-up 3',
                        color: krollblue,
                        fontSize: headerFontSize,
                        bold: 'true',
                        borderBottom: { size: 1, color: krollshadow },
                        colSpan: 10
                    }
                ]
            },
            {
                cells: [
                    { value: 'Risk Measure', bold: 'true' },
                    { value: 'Ke', bold: 'true' },
                    { value: '=', bold: 'true' },
                    { value: 'Rf', bold: 'true' },
                    { value: '+', bold: 'true' },
                    { value: 'RPm+c', bold: 'true' },
                    { value: '+', bold: 'true' },
                    { value: 'ERP Adjustment', bold: 'true' },
                    options.scenarioHelper.createNoteHeaderRow(displayNotes)
                ]
            }
        ).concat(options.scenarioHelper.RPRBuildUp3(options.summary.levered.studyGroups, displayNotes));
}
