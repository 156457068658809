import { SummaryItem } from 'src/app/_navigator/summary/summary-group.model';

export class SummaryUtil {
    public static getGeneralInputsItems(valuationDate: string, industries: string[]): SummaryItem[] {
        const items: SummaryItem[] = [{
            name: 'Valuation Date',
            value: valuationDate
        }, {
            name: 'Home Country',
            value: 'United States'
        }, {
            name: 'Investee Country',
            value: 'United States'
        }];

        for (let i = 0; i < industries.length; i++) {
            const industryObject = {
                name: `Industry ${i + 1}`,
                value: industries[i]
            };
            items.push(industryObject);
        }

        return items;
    }

}
