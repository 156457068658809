import { CountryRiskPremiaType } from 'src/app/_api/enums/country-risk-premia-type';
import { SelectionType } from 'src/app/_api/enums/selection-type';
import { CountryRiskPremia } from 'src/app/_api/responses/country-risk-premia.response';
import { InputType } from 'src/app/_api/responses/input.response';
import { Estimate } from 'src/app/_navigator/data/model/estimate.model';
import { Scenario } from 'src/app/_navigator/data/model/scenario.model';
import { TableData } from 'src/app/_navigator/data/model/table-data.model';
import { NumberFormatUtil } from 'src/app/_navigator/data/util/number-format.util';
import { SummaryType } from '../data/enum/summary-type';
import { SummaryTableCommonUtil } from './summary-table-common.util';

export class RegionTableUtil {
    public static getRegionPremiaTable(
        crp: CountryRiskPremia[],
        scenario: Scenario,
        allowYieldSpreadOnly: boolean = false,
        isVolatilityPercentage: boolean = true,
        isWaccConclusion: boolean = false
    ): TableData[] {
        const tableData: TableData[] = [];

        const useYieldSpreadSelection = scenario.getSelection(SelectionType.UseYieldSpreadOnly, InputType.None);
        const useYieldSpreadSelectionAsBoolean = useYieldSpreadSelection.Value ? true : false;
        const useYieldSpreadModelOnly = useYieldSpreadSelectionAsBoolean && allowYieldSpreadOnly;

        for (const premium of crp) {
            const name = SummaryTableCommonUtil.getTableName(premium.Type, premium.RegionName);
            const headers = SummaryTableCommonUtil.getHeaders(useYieldSpreadModelOnly, isWaccConclusion);

            let ccrAvg = 'N/A';
            if (premium.CcrAvg != null) {
                ccrAvg = `${NumberFormatUtil.numberWithCommas(premium.CcrAvg)}%`;
            }

            let ccrMedian = 'N/A';
            if (premium.CcrMedian != null) {
                ccrMedian = `${NumberFormatUtil.numberWithCommas(premium.CcrMedian)}%`;
            }

            let yieldSpreadAvg = 'N/A';
            if (premium.YieldSpreadAvg != null) {
                yieldSpreadAvg = `${NumberFormatUtil.numberWithCommas(premium.YieldSpreadAvg)}%`;
            }

            let yieldSpreadMedian = 'N/A';
            if (premium.YieldSpreadMedian != null) {
                yieldSpreadMedian = `${NumberFormatUtil.numberWithCommas(premium.YieldSpreadMedian)}%`;
            }

            let volatilityAvg = 'N/A';
            if (premium.VolatilityAvg != null) {
                const roundedVolatility = NumberFormatUtil.numberWithCommas(premium.VolatilityAvg);
                volatilityAvg = isVolatilityPercentage ? `${roundedVolatility}%` : roundedVolatility;
            }

            let volatilityMedian = 'N/A';
            if (premium.VolatilityMedian != null) {
                const roundedVolatility = NumberFormatUtil.numberWithCommas(premium.VolatilityMedian);
                volatilityMedian = isVolatilityPercentage ? `${roundedVolatility}%` : roundedVolatility;
            }

            let averageRow: string[];
            if (isWaccConclusion) {
                averageRow = ['Average', ccrAvg, yieldSpreadAvg, volatilityAvg];
            } else {
                averageRow = useYieldSpreadModelOnly ? ['Average', yieldSpreadAvg] : ['Average', ccrAvg, yieldSpreadAvg, volatilityAvg];
            }

            let medianRow: string[];
            if (isWaccConclusion) {
                medianRow = ['Median', ccrMedian, yieldSpreadMedian, volatilityMedian];
            } else {
                medianRow = useYieldSpreadModelOnly
                    ? ['Median', yieldSpreadMedian]
                    : ['Median', ccrMedian, yieldSpreadMedian, volatilityMedian];
            }

            const content = [averageRow, medianRow];
            const data: TableData = {
                name,
                headers,
                content
            };
            tableData.push(data);
        }

        return tableData;
    }

    public static getAvgMedianCrpTable(
        crp: CountryRiskPremia[],
        estimate: Estimate,
        cashFlowCountryCurrencyId: number,
        inputCountryCurrencyId: number,
        summaryType: SummaryType
    ): CountryRiskPremia[] {
        const applyFisherEffect = cashFlowCountryCurrencyId !== inputCountryCurrencyId;

        return crp.map((x) => SummaryTableCommonUtil.formatCrp(estimate, applyFisherEffect, x, summaryType));
    }

    public static getAvgMedianWaccTable(
        estimate: Estimate,
        costOfEquityCrp: CountryRiskPremia | undefined,
        costOfDebtCrp: CountryRiskPremia | undefined,
        useYieldSpreadModelOnly: boolean
    ): CountryRiskPremia | null {
        if (costOfEquityCrp == null || costOfDebtCrp == null) {
            return null;
        }

        const kdCrpAvg = useYieldSpreadModelOnly ? costOfDebtCrp.YieldSpreadAvg : costOfDebtCrp.CcrAvg;
        const kdCrpMedian = useYieldSpreadModelOnly ? costOfDebtCrp.YieldSpreadMedian : costOfDebtCrp.CcrMedian;
        const kdVolatilityAvg = useYieldSpreadModelOnly ? costOfDebtCrp.YieldSpreadAvg : costOfDebtCrp.VolatilityAvg;
        const kdVolatilityMedian = useYieldSpreadModelOnly ? costOfDebtCrp.YieldSpreadMedian : costOfDebtCrp.VolatilityMedian;

        return {
            Type: costOfEquityCrp.Type,
            RegionName: costOfEquityCrp.RegionName,
            CcrAvg: SummaryTableCommonUtil.getAdjustedWacc(estimate, costOfEquityCrp.CcrAvg, kdCrpAvg),
            CcrMedian: SummaryTableCommonUtil.getAdjustedWacc(estimate, costOfEquityCrp.CcrMedian, kdCrpMedian),
            YieldSpreadAvg: SummaryTableCommonUtil.getAdjustedWacc(estimate, costOfEquityCrp.YieldSpreadAvg, costOfDebtCrp.YieldSpreadAvg),
            YieldSpreadMedian: SummaryTableCommonUtil.getAdjustedWacc(
                estimate,
                costOfEquityCrp.YieldSpreadMedian,
                costOfDebtCrp.YieldSpreadMedian
            ),
            VolatilityAvg: SummaryTableCommonUtil.getAdjustedWacc(estimate, costOfEquityCrp.VolatilityAvg, kdVolatilityAvg),
            VolatilityMedian: SummaryTableCommonUtil.getAdjustedWacc(estimate, costOfEquityCrp.VolatilityMedian, kdVolatilityMedian)
        };
    }

    public static buildWaccConclusionCrpTables(
        estimate: Estimate,
        crp: CountryRiskPremia[],
        cashFlowCountryCurrencyId: number,
        keInputCountryCurrencyId: number,
        kdInputCountryCurrencyId: number
    ): TableData[] {
        const costOfEquityCrp = this.getAvgMedianCrpTable(
            crp,
            estimate,
            cashFlowCountryCurrencyId,
            keInputCountryCurrencyId,
            SummaryType.CostOfEquity
        );
        const costOfDebtCrp = this.getAvgMedianCrpTable(
            crp,
            estimate,
            cashFlowCountryCurrencyId,
            kdInputCountryCurrencyId,
            SummaryType.CostOfDebt
        );

        const useYieldSpread = estimate.Scenarios[0].getSelection(SelectionType.UseYieldSpreadOnly, InputType.None).Value ? true : false;

        const keEuromoney = costOfEquityCrp.find((x) => x.Type === CountryRiskPremiaType.EuromoneyRegion);
        const kdEuromoney = costOfDebtCrp.find((x) => x.Type === CountryRiskPremiaType.EuromoneyRegion);
        const waccEuromoney = this.getAvgMedianWaccTable(estimate, keEuromoney, kdEuromoney, useYieldSpread);

        const keMsci = costOfEquityCrp.find((x) => x.Type === CountryRiskPremiaType.MsciClassification);
        const kdMsci = costOfDebtCrp.find((x) => x.Type === CountryRiskPremiaType.MsciClassification);
        const waccMsci = this.getAvgMedianWaccTable(estimate, keMsci, kdMsci, useYieldSpread);

        const keSp = costOfEquityCrp.find((x) => x.Type === CountryRiskPremiaType.SpCreditRating);
        const kdSp = costOfDebtCrp.find((x) => x.Type === CountryRiskPremiaType.SpCreditRating);
        const waccSp = this.getAvgMedianWaccTable(estimate, keSp, kdSp, useYieldSpread);

        const waccTables: CountryRiskPremia[] = [];
        if (waccEuromoney) {
            waccTables.push(waccEuromoney);
        }
        if (waccMsci) {
            waccTables.push(waccMsci);
        }
        if (waccSp) {
            waccTables.push(waccSp);
        }

        return this.getRegionPremiaTable(waccTables, estimate.Scenarios[0], false, true, true);
    }
}
