import { Action, Selector, State, StateContext } from '@ngxs/store';
import { of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { EstimateClient } from 'src/app/_api/clients/estimate.client';
import { Estimate } from '../data/model/estimate.model';
import { DataStore } from '../data/store/data.store';
import { GetEstimate, UpdateDbEstimate, UpdateLocalEstimate } from './estimate-actions';
import { Injectable } from '@angular/core';


export type EstimateModel = Estimate | undefined;

@State<EstimateModel>({
    name: 'estimate',
    defaults: undefined
})

@Injectable()
export class EstimateState {

    @Selector()
    public static get(state: EstimateModel): Estimate | undefined {
        return state;
    }
    constructor(
        private estimateClient: EstimateClient,
        // TODO: this needs to go away. The only reason why this is here
        // is because the equation components and their editors still depend on the DataStore to get the estimate
        private dataStore: DataStore
    ) { }

    @Action(GetEstimate)
    public getEstimate(context: StateContext<EstimateModel>, action: GetEstimate): Observable<void> {
        return this.estimateClient.read(action.id).once((response) => {
            const estimate = new Estimate(response);
            context.setState(estimate);
            this.dataStore.setupEstimate(estimate.Id);
        });
    }

    @Action(UpdateDbEstimate)
    public updateDb(context: StateContext<EstimateModel>): Observable<void> {
        const estimate = context.getState();

        if (estimate == null) {
            return of(void 0);
        }

        return this.estimateClient.update(estimate).once(() => {
            return this.estimateClient.read(estimate.Id).once((response) => {
                const estimateResponse = new Estimate(response);
                context.setState(estimateResponse);
                this.dataStore.setupEstimate(estimateResponse.Id);

                return of(void 0);
            });
        });
    }

    @Action(UpdateLocalEstimate)
    public updateLocal(context: StateContext<EstimateModel>, action: UpdateLocalEstimate): Observable<void> {
        const estimate = new Estimate(action.estimate);
        context.setState(estimate);

        return of(void 0);
    }

}
