export enum AssetsTypes {
    EUR = 'Assets in Eur',
    USD = 'Assets in Usd',
    GBP = 'Assets in Gbp'
}

export enum SalesTypes {
    EUR = 'Sales in Eur',
    USD = 'Sales in Usd',
    GBP = 'Sales in Gbp'
}

export enum CurrencyTypes {
    EUR = 'eur',
    USD = 'usd',
    GBP = 'gbp'
}

export enum IntlCompanySalesFields {
    EUR = 'SalesEur',
    USD = 'SalesUsd',
    GBP = 'SalesGbp',
    DEFAULT = 'Sales'
}

export enum IntlCompanyAssetsFields {
    EUR = 'AssetsEur',
    USD = 'AssetsUsd',
    GBP = 'AssetsGbp',
    DEFAULT = 'Assets'
}