import { Component, OnInit } from '@angular/core';
import { SubscriberEntity } from '@concurrency/angular';
import { Select, Store } from '@ngxs/store';
import { combineLatest } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { map, takeUntil } from 'rxjs/operators';
import { SelectionType } from 'src/app/_api/enums/selection-type';
import { CountryCreditRating } from 'src/app/_api/responses/country-credit-rating.response';
import { CountryRiskPremia } from 'src/app/_api/responses/country-risk-premia.response';
import { Country } from 'src/app/_api/responses/country.response';
import { EstimateSummary } from 'src/app/_api/responses/estimate-summary.response';
import { InputType } from 'src/app/_api/responses/input.response';
import { CcrState } from 'src/app/_navigator/ccr-store/ccr-state';
import { CountryListState } from 'src/app/_navigator/country-list-store/country-list-state';
import { CountryRiskPremiaListState } from 'src/app/_navigator/country-risk-premia-list-store/country-risk-premia-list-state';
import { Estimate } from 'src/app/_navigator/data/model/estimate.model';
import { TableData } from 'src/app/_navigator/data/model/table-data.model';
import { DynamicTextSummaryState } from 'src/app/_navigator/dynamic-text-store/dynamic-text-summary-state';
import { EstimateState } from 'src/app/_navigator/estimate-store/estimate-state';
import { EstimateSummaryState } from 'src/app/_navigator/estimate-summary/estimate-summary-state';
import { DynamicTextSummary } from 'src/app/international-estimate/dynamic-text/model/dynamic-text-summary';
import { SummaryText } from '../data/model/summary-text';
import { DynamicText } from '../dynamic-text/model/dynamic-text';
import { EquationGroup } from '../equation/equation-group.model';
import { EquationGroupUtil } from '../equation/equation-group.util';
import { CrpTableText } from '../summary-table/crp-table-text';
import { CrpTableUtil } from '../summary-table/crp-table.util';
import { RegionTableUtil } from '../summary-table/region-table.util';

@Component({
    selector: 'wacc-summary-conclusion',
    templateUrl: './wacc-summary-conclusion.component.html'
})
export class WaccSummaryConclusionComponent extends SubscriberEntity implements OnInit {
    public isReady = false;
    public countryPremia!: TableData;
    public crpTables: TableData[] = [];
    public dynamicText: DynamicText[] = [];
    public showCrp = true;
    public equations: EquationGroup[] = [];
    public isKeKdAvailable = false;
    public isInflationAvailable = false;
    public CrpTableText = CrpTableText;
    public SummaryText = SummaryText;
    public alphas: any[] = [];

    @Select(EstimateState.get) public estimateSelector!: Observable<Estimate | undefined>;
    @Select(CountryRiskPremiaListState.get) public countryRiskPremiaSelector!: Observable<CountryRiskPremia[] | undefined>;
    @Select(DynamicTextSummaryState.get) public dynamicTextSummarySelector!: Observable<DynamicTextSummary | undefined>;
    @Select(EstimateSummaryState.get) public estimateSummarySelector!: Observable<EstimateSummary | undefined>;
    @Select(CcrState.get) public ccrSelector!: Observable<CountryCreditRating | undefined>;

    constructor(private store: Store) {
        super();
    }

    private initialize(
        estimate: Estimate,
        estimateSummary: EstimateSummary | undefined,
        investee: Country | undefined,
        crp: CountryRiskPremia[] | undefined,
        keInputCountry: Country | undefined,
        cashFlowCountry: Country | undefined,
        kdInputCountry: Country | undefined,
        ccr: CountryCreditRating | undefined
    ): void {
        if (investee == null || crp == null || keInputCountry == null || cashFlowCountry == null || kdInputCountry == null || ccr == null || estimateSummary == null) {
            return;
        }

        this.isKeKdAvailable = true;
        const inflationInvestor = estimate.Scenarios[0].getSelection(SelectionType.InflationInvestor, InputType.None);
        const inflationInvestee = estimate.Scenarios[0].getSelection(SelectionType.InflationInvestee, InputType.None);
        const hasKeFisherEffect = cashFlowCountry.Currency.CurrencyId !== keInputCountry.Currency.CurrencyId;
        const hasKdFisherEffect = cashFlowCountry.Currency.CurrencyId !== kdInputCountry.Currency.CurrencyId;

        if ((hasKeFisherEffect || hasKdFisherEffect) && (!inflationInvestor.Value || !inflationInvestee.Value)) {
            return;
        }

        this.isInflationAvailable = true;
        this.showCrp = kdInputCountry.CountryId !== estimate.InvesteeCountryId;

        if (this.showCrp) {
            this.countryPremia = CrpTableUtil.getWaccCountryPremiaTable(
                estimate.Scenarios[0],
                ccr,
                investee.CountryName,
                cashFlowCountry.Currency.CurrencyAcronym
            );
            this.alphas = [estimateSummary.InvesteeCountryId, estimateSummary.InvestorCountryId, estimateSummary.ValuationDate];
            this.crpTables = RegionTableUtil.buildWaccConclusionCrpTables(
                estimate,
                crp,
                cashFlowCountry.Currency.CurrencyId,
                keInputCountry.Currency.CurrencyId,
                kdInputCountry.Currency.CurrencyId
            );
            this.isReady = true;
        } else {
            this.equations = EquationGroupUtil.getWaccConclusionGroup(estimate, keInputCountry);
        }
    }

    public ngOnInit(): void {
        this.estimateSelector.onceDefined((estimate) => {
            this.estimateSummarySelector.onceDefined((estimateSummary) => {
                const scenario = estimate.Scenarios[0];
                const cashFlowCountry = estimate.getInput(InputType.CashFlows);
                const keCountryOfInputs = estimate.getInput(InputType.CountryOfInputs);
                const rf = scenario.getSelection(SelectionType.IntlRiskFreeRate, InputType.None);
                const beta = scenario.getSelection(SelectionType.IntlBeta, InputType.None);
                const erp = scenario.getSelection(SelectionType.IntlEquityRiskPremium, InputType.None);
                const kdCountryOfInputs = scenario.getSelection(SelectionType.CostOfDebtCurrency, InputType.None);
                const preTaxKd = scenario.getSelection(SelectionType.PreTaxCostOfDebt, InputType.None);
                const debtToTotalCapitalRatio = scenario.getSelection(SelectionType.DebtToTotalCapitalRatio, InputType.None);
                const taxRate = scenario.getSelection(SelectionType.TaxRate, InputType.None);

                if (cashFlowCountry.Value == null
                    || keCountryOfInputs.Value == null
                    || rf.Value == null
                    || beta.Value == null
                    || erp.Value == null
                    || kdCountryOfInputs.Value == null
                    || preTaxKd.Value == null
                    || debtToTotalCapitalRatio.Value == null
                    || taxRate.Value == null) {
                    return;
                }

                combineLatest([
                    this.store.select(CountryListState.getById(estimate.InvesteeCountryId)),
                    this.countryRiskPremiaSelector,
                    this.store.select(CountryListState.getById(keCountryOfInputs.Value)),
                    this.store.select(CountryListState.getById(cashFlowCountry.Value)),
                    this.store.select(CountryListState.getById(kdCountryOfInputs.Value)),
                    this.ccrSelector
                ]).pipe(
                    takeUntil(this.destroyed),
                    map((x) => ({
                        investee: x[0],
                        crp: x[1],
                        keInputCountry: x[2],
                        cashFlow: x[3],
                        kdInputCountry: x[4],
                        ccr: x[5]
                    }))
                ).onceDefined((data) => {
                    this.initialize(estimate, estimateSummary, data.investee, data.crp, data.keInputCountry, data.cashFlow, data.kdInputCountry, data.ccr);
                });
            });
        });

        this.dynamicTextSummarySelector.onceDefined((dt) => {
            if (dt.WaccSummaryConclusionText == null) {
                return;
            }
            this.dynamicText = dt.WaccSummaryConclusionText;
        });
    }
}
