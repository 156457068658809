import { Component, OnInit } from '@angular/core';
import { SubscriberEntity } from '@concurrency/angular';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs/internal/Observable';
import { BetaEstimate } from 'src/app/_navigator/data/model/beta-estimate.model';
import { BetaEstimatesListState } from 'src/app/_navigator/estimates-list-store/beta-estimates-list-state';
// import { IndustryDataSharingService } from 'src/app/estimate/studies/service/industry-data-sharing.service';

@Component({ templateUrl: './new-estimate-modal.component.html' })
export class NewBetaEstimateModalComponent extends SubscriberEntity {
    public name = '';
    public isDuplicate = false;
    public selection?: string;
    @Select(BetaEstimatesListState.get) public estimates!: Observable<BetaEstimate[] | undefined>;

    constructor(
        // public industryDataSharingService: IndustryDataSharingService
    ) {
        super();
    }

    // public ngOnInit(): void {
    //     // const emptyIndustry: Industry[] = new Array<Industry>();
    //     // emptyIndustry.push({
    //     //     CodeId: 0,
    //     //     GicIndustryCode: '',
    //     //     SicIndustryCode: '',
    //     //     Sector: ''
    //     // } as Industry);
    //     // this.industryDataSharingService.setSharedIndustries = emptyIndustry;
    // }

    public validateInput(): void {
        this.estimates.onceDefined((estimates: BetaEstimate[]) => {
            this.isDuplicate = estimates.length > 0 && estimates.some((x) => x.Name.toLowerCase() === this.name.toLowerCase());
            // this.selection = this.isDuplicate ? undefined : this.name = this.name.replace(/[^a-zA-Z0-9]{1,}/g, "");
            this.selection = this.isDuplicate ? undefined : this.name = this.name;
        });
    }
}
