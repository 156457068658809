import { Workbook, WorkbookSheetRow, WorkbookSheetRowCell } from '@progress/kendo-angular-excel-export';
import { CocInputExcelUtill } from './cocinputs-export-utill';
import { CountryRiskPremium } from '../models/countryRiskPremium';
import * as moment from 'moment';
import { CommonExcelExportUtil } from 'src/app/_navigator/common/common-excel-export-util';

export class crpExcelSheet {



    private static tblHeaders = ['Investee Country', 'Region', 'Country Yield Spread Model CRP', 'Country Credit Rating Model (CCR) CRP', 'Relative Volatility Model RV Factor'];
    private static tblSupplyHeaders = ['Investee Country', 'Region', 'MSCI Market Classification', 'Country Yield Spread Model Tier', 'Long Term Expected Inflation (2024−2053)', '2023 Tax Rate'];
    private static crpRequiredDataFields = ["InvesteeContry", "Region", "CysmDp", "CcrCrp", "RvComplex"]
    private static crpSupplyReqFields = ["InvesteeContry", "Region", "MSIClassification", "Tier", "InflationRate", 'IntlTaxRate']

    private static mapCrpKeytoCellMethods = {
        "InvesteeContry": (value: any) => CocInputExcelUtill.textCell(value, 'left'),
        "Region": (value: any) => CocInputExcelUtill.textCell(value),
        "CysmDp": (value: any) => CocInputExcelUtill.PercentileCell(value / 100),
        "CcrCrp": (value: any) => CocInputExcelUtill.PercentileCell(value / 100),
        "RvComplex": (value: any) => CocInputExcelUtill.decimalCell(value),
        "textCell": (value: any) => CocInputExcelUtill.textCell(value),
    } as any


    private static mapSupllyKeytoCellMethods = {
        "InvesteeContry": (value: any) => CocInputExcelUtill.textCell(value, 'left'),
        "Region": (value: any) => CocInputExcelUtill.textCell(value),
        "MSIClassification": (value: any) => CocInputExcelUtill.textCell(value),
        "Tier": (value: any) => CocInputExcelUtill.numberCell(value),
        "InflationRate": (value: any) => CocInputExcelUtill.PercentileCell(value / 100),
        "IntlTaxRate": (value: any) => CocInputExcelUtill.PercentileCell(value / 100),
        "textCell": (value: any) => CocInputExcelUtill.textCell(value),
    } as any

    public static getCrpSheet(data: CountryRiskPremium[]): WorkbookSheetRow[] {

        const inflationLabel = data[0]?.InflationLabel;
        const startIndex = inflationLabel.indexOf('Expected');
        const inflationLabelHeader = startIndex !== -1 ? `Long Term ${inflationLabel.substring(startIndex)}` : "";
        const index = data[0]?.IntlTaxRateLabel.search(/\d/);
        const taxRate = data[0]?.IntlTaxRateLabel.substring(index);
        const taxRateDataAsOf = data[0]?.IntlTaxRateDataAsOf;

        //Overiding Inflation and  TaxRate based on Data 
        this.tblSupplyHeaders[4] = inflationLabelHeader + " - Data as of " + moment(data[0].InflationDataAsOf).format('MM/DD/YYYY');
        this.tblSupplyHeaders[5] = taxRate + " - Data as of " + moment(taxRateDataAsOf).format('MM/DD/YYYY');


        const crpSheetRows: WorkbookSheetRow[] = [

            {
                cells: [CocInputExcelUtill.styleMainHeader("Country Risk Premium and Relative Volatility - Data as of " + moment(data[0].MSICDataAsOf).format('MM/DD/YYYY'), 3)]
            },
            CocInputExcelUtill.getEmptyRow(),
            {
                cells: CocInputExcelUtill.tableHeadCellFormater(this.tblHeaders),
            },
            ...this.mapCrpDataToRow(data)


        ]

        return crpSheetRows

    }


    private static mapCrpDataToRow(data: CountryRiskPremium[]): WorkbookSheetRow[] {
        const sheetRows = data.map(item => { return this.getCrpEachRow(item) });
        return sheetRows;
    }


    public static getCrpEachRow(item: CountryRiskPremium | any): WorkbookSheetRow {
        return {
            cells: Object.keys(item).filter(key => this.crpRequiredDataFields.find(s => s == key)).map((eachKey) => {
                return (item[eachKey] && item[eachKey] != 'N/A') ? this.mapCrpKeytoCellMethods[eachKey](item[eachKey]) : this.mapCrpKeytoCellMethods["textCell"](item[eachKey] ?? 'N/A');
            })
        } as WorkbookSheetRow

    }


    public static getCrpSupplySheetData(data: any): WorkbookSheetRow[] {
        const crpSheetRows: WorkbookSheetRow[] = [

            {
                cells: [CocInputExcelUtill.styleMainHeader("Country Risk Premium and Relative Volatility - Data as of " + moment(data[0].MSICDataAsOf).format('MM/DD/YYYY'), 3)]
            },
            CocInputExcelUtill.getEmptyRow(),
            {
                cells: CocInputExcelUtill.tableHeadCellFormater(this.tblSupplyHeaders),
            },
            ...this.mapCrpSupplyDataToRow(data)


        ]

        return crpSheetRows

    }

    private static mapCrpSupplyDataToRow(data: CountryRiskPremium[]): WorkbookSheetRow[] {
        const sheetRows = data.map(item => { return this.getCrpSupplyEachRow(item) });
        return sheetRows;
    }

    public static getCrpSupplyEachRow(item: CountryRiskPremium | any): WorkbookSheetRow {
        return {
            cells: this.crpSupplyReqFields.map((eachKey) => {
                return (item[eachKey] && item[eachKey] != 'N/A') ? this.mapSupllyKeytoCellMethods[eachKey](item[eachKey]) : this.mapSupllyKeytoCellMethods["textCell"](item[eachKey] ?? 'N/A');
            })
        } as WorkbookSheetRow

    }







}