import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalManager, Spinner, SubscriberEntity } from '@concurrency/angular';
import { ContextualNumber } from '@concurrency/core';
import { ContextualValue, Util } from '@concurrency/core';
import { Select } from '@ngxs/store';
import { Store } from '@ngxs/store';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ViewIndustriesComponent } from '../../app/benchmarking/components/view-industries/view-industries.component';
import { InputText } from '../../app/international-estimate/data/model/input-text';
import { IntlEstimateUtil } from '../../app/international-estimate/data/util/intl-estimate.util';
import { USCompanyClient } from '../_api/clients/uscompany.client';
import { USIndustryClient } from '../_api/clients/usindustry.client';
import { EstimateType } from '../_api/enums/estimate-type';
import { Country } from '../_api/responses/country.response';
import { EstimateResponse } from '../_api/responses/estimate.response';
import { IntlData } from '../_api/responses/intl-data.response';
import { MinimumDate } from '../_api/responses/minimum-date.response';
import { Industry } from '../_api/responses/us-industry.response';
import { CommonDate, DateStruct } from '../_navigator/common/date-struct';
import { CommonInputConfig } from '../_navigator/common/inputs/common-user-input.component';
import { Estimate } from '../_navigator/data/model/estimate.model';
import { Suggestion } from '../_navigator/data/model/suggestion.model';
import { EstimateService } from '../_navigator/data/service/estimate.service';
import { DataStore } from '../_navigator/data/store/data.store';
import { TypeaheadFormatters } from '../_navigator/data/util/typeahead-formatters.util';
import { HelpText } from '../_navigator/help/help-text';
import { PrimeManager } from '../_navigator/modal/pmodal.manager';
import { GetTaxRateList } from '../_navigator/tax-rate-list-store/tax-rate-list-actions';
import { TaxRateListState } from '../_navigator/tax-rate-list-store/tax-rate-list-state';
import { ProductType } from '../_navigator/user/enum/product-type';
import { User } from '../_navigator/user/model/user.model';
import { UserStore } from '../_navigator/user/store/user.store';
import { UserUtil } from '../_navigator/user/util/user.util';
import { ValuesState } from '../_navigator/values-store/values-state';
import { BenchmarkingUtil } from '../benchmarking/benchmarking.util';
import { AddIndustriesComponent } from '../benchmarking/components/add-industries/add-industries.component';
import { TrendsOverTimeService } from '../home/tear-sheet/data/trends-over-time.service';
import { CompanyListComponent } from './company-list/company-list.component';
import { InputDependencies } from './data/input-dependencies.model';
import { InputsTexts } from './data/inputs-texts';
import { QueryBuilderService } from './data/query-builder.service';
import { ResetTexts } from './data/reset-texts';
import { ResetType } from './data/reset-type.enum';
import { InputsUtil } from './inputs.util';
import { ResetEstimateModalComponent } from './modals/reset-estimate-modal.component';
import { SelectIndustryModalComponent } from './modals/select-industry-modal.component';
import { IndustryDataSharingService } from './studies/service/industry-data-sharing.service';
import { BenchmarkingQueryBuilderService } from '../benchmarking/data/benchmarking-query-builder.service';

class Constants {
    public static readonly FilterIndustryCodeDate = '2020-09-30T12:00:00';
}

// TODO: This class needs some kind of concept of whether anything has actually changed
//   its pointless to go round-trip to the server if nothing has changed.

// TODO: This class is kind of a mess.

@Component({
    templateUrl: './inputs.component.html',
    styleUrls: ['./inputs.component.scss']
})
export class InputsComponent extends SubscriberEntity implements OnInit {
    private lastSelectedIndustries: ContextualValue<Industry>[] = [];
    public Constants = Constants;
    public taxRate: ContextualNumber = new ContextualNumber();
    @ViewChild('inputsForm') public form!: HTMLFormElement;
    @Select(ValuesState.get) public valuesSelector!: Observable<MinimumDate | undefined>;
    @Select(TaxRateListState.get) public taxRateListSelector!: Observable<IntlData[] | undefined>;
    public TypeaheadFormatters = TypeaheadFormatters;
    public data!: InputDependencies;
    public continueBtnText = '';
    // TODO: These variables should be initialized from the estimate, not initialized to arbitary values.
    public valuationDate: ContextualValue<DateStruct> = new ContextualValue<DateStruct>();
    public homeCountry: ContextualValue<Country> = new ContextualValue<Country>();
    public industries: ContextualValue<Industry>[] = [];
    public selectedIndustries: Industry[] = [];
    public taxRateSuggestions: Suggestion<number>[] = [];
    public noIndustry: ContextualValue<boolean> = new ContextualValue<boolean>();
    public valuationDateConfig!: CommonInputConfig;
    public homeCountryConfig: CommonInputConfig = {
        name: InputsTexts.homeCountry,
        help: HelpText.HomeCountry,
        disabled: true,
        required: true
    };
    public industryConfig: CommonInputConfig = {
        name: InputsTexts.industry,
        help: HelpText.Industry,
        required: true
    };
    public noIndustryConfig: CommonInputConfig = {
        name: '',
        label: InputsTexts.industryNotInList
    };

    public showAddIndustry = true;
    public maximumNumberOfIndustries = 3;
    public isIndustryAnalysis: boolean | undefined = false;
    public promptTitle = '';
    public promptSubtitle = '';
    public minimumDate = -1;

    public isFirstLoad = true;
    public prevDate!: DateStruct;
    public currentDate!: DateStruct;

    public taxRateConfig: CommonInputConfig = {
        name: InputText.TaxRate,
        help: HelpText.TaxRate,
        required: true,
        mask: `{}%`,
        minimumValue: 0,
        maximumValue: 100,
        pattern: '(.*?)'
    };

    public completed$ = new Subject<any>();
    constructor(
        private store: Store,
        private dataStore: DataStore,
        private userStore: UserStore,
        private spinner: Spinner,
        private modalManager: ModalManager,
        private primeManager: PrimeManager,
        private estimateService: EstimateService,
        private usCompanyClient: USCompanyClient,
        private usIndustryClient: USIndustryClient,
        public queryBuilderService: QueryBuilderService,
        public industryDataSharingService: IndustryDataSharingService,
        public trendsOverTimeService: TrendsOverTimeService,
        public benchMarkBuilderService: BenchmarkingQueryBuilderService
    ) { super(); }

    private initialize(
        estimate: Estimate | undefined,
        user: User | undefined,
        countries: Country[] | undefined,
        industries: Industry[] | undefined,
        minimumDate: number,
        taxRate: IntlData[] | undefined
    ): void {
        if (user == null || estimate == null || countries == null || industries == null || taxRate == null) {
            return;
        }
        const date = CommonDate.fromString(estimate.ValuationDate);
        const productType = this.isIndustryAnalysis ? ProductType.Usi : ProductType.Coc;
        this.valuationDate.value = date.asStruct();
        this.queryBuilderService.countries = countries;
        this.queryBuilderService.industries = industries;
        this.benchMarkBuilderService.industries = industries;

        this.data = {
            estimate,
            user,
            countries,
            industries
        };

        this.industryDataSharingService.sharedIndustries$.subscribe((value) => {
            if (value.length > 0) {
                if (value.length === 1 && value[0].CodeId === 0) {
                    this.selectedIndustries = [];
                } else {
                    this.selectedIndustries = value;
                }
            } else {
                const isSicGic = BenchmarkingUtil.isSic(this.valuationDate.toString(), 'United States');
                this.data.estimate.Industries.forEach((item) => {
                    this.selectedIndustries.push({
                        CodeId: item.SicId,
                        GicIndustryCode: isSicGic === false ? item.SicCode : '',
                        Sector: item.IndustryName,
                        SicIndustryCode: isSicGic === true ? item.SicCode : ''
                    });
                });
            }
        });
        this.homeCountry.value = countries.find((x) => x.CountryId === estimate.HomeCountryId) || null;
        this.noIndustry = new ContextualValue(!estimate.IsIndustryApplicable);
        this.industryConfig.disabled = this.noIndustry.value || undefined;
        this.valuationDateConfig = UserUtil.getValuationDateInputConfig(date, productType, minimumDate, user.subscriptionDetails);
        this.industries = estimate.getIndustryValues(industries);
        this.showAddIndustry = InputsUtil.isAddIndustryAvailable(this.industries.length, this.maximumNumberOfIndustries);
        this.minimumDate = minimumDate;
        if (this.valuationDate.value) {
            const _date = CommonDate.fromStruct(this.valuationDate.value);
            const condition = _date.asString() < Constants.FilterIndustryCodeDate;
            const filteredIndustries = condition ? industries.filter((x) => x.SicIndustryCode != null) :
                industries.filter((x) => x.GicIndustryCode != null);
            this.queryBuilderService.industries = filteredIndustries;
            this.benchMarkBuilderService.industries = filteredIndustries;
        }
        this.taxRateSuggestions = taxRate.map((x) => IntlEstimateUtil.intlDataAsSuggestion(x.Value, x.Label, x.DataAsOf, x.Source));
    }

    public ngOnInit(): void {
        const request = combineLatest([
            this.store.dispatch(new GetTaxRateList(77349, '2019-12-31 00:00:00.000'))
        ]);
        request.once(() => {
            this.dataStore.countries.onceDefined((countries) => {
                combineLatest([
                    this.dataStore.isIndustryAnalysis,
                    this.userStore.user,
                    this.valuesSelector,
                    this.taxRateListSelector
                ]).pipe(
                    takeUntil(this.destroyed),
                    map((x) => ({
                        isIndustryAnalysis: x[0],
                        user: x[1],
                        values: x[2],
                        taxRate: x[3]
                    }))
                ).onceDefined((data) => {
                    this.isIndustryAnalysis = data.isIndustryAnalysis;
                    this.continueBtnText = this.isIndustryAnalysis ? InputsTexts.continue : InputsTexts.saveAndContinue;
                    this.promptTitle = this.isIndustryAnalysis ? InputsTexts.highLevelAnalysis : InputsTexts.highLevelEstimate;
                    this.promptSubtitle = this.isIndustryAnalysis ? InputsTexts.startAnalysis : InputsTexts.startEstimate;
                    if (this.isIndustryAnalysis) {
                        this.dataStore.analysis.onceDefined((analysis) => {
                            const estimateResponse: EstimateResponse = {
                                Id: '',
                                Name: '',
                                ValuationDate: analysis.ValuationDate,
                                Industries: analysis.Industries,
                                IsIndustryApplicable: true,
                                HomeCountryId: 77349,
                                InvesteeCountryId: 77349,
                                Scenarios: [],
                                Inputs: [],
                                Created: new Date(),
                                Updated: new Date(),
                                Suggestions: [],
                                HistoricRprErp: null,
                                EstimateType: EstimateType.USEstimate
                            };
                            const estimate = new Estimate(estimateResponse);
                            const request1 = combineLatest([
                                this.dataStore.updateIndustries(estimate.ValuationDate, true)
                            ]);
                            this.spinner.while(request1);
                            request1.once(() => {
                                this.dataStore.industries.onceDefined((industries) => {
                                    if (data.values == null) {
                                        return;
                                    }
                                    this.initialize(estimate, data.user, countries, industries, data.values.UsIndustries, data.taxRate);
                                });
                            });
                        });
                    } else {
                        this.dataStore.estimate.onceDefined((estimate) => {
                            this.dataStore.industries.onceDefined((industries) => {
                                if (data.values == null) {
                                    return;
                                }
                                this.initialize(estimate, data.user, countries, industries, data.values.UsEstimates, data.taxRate);
                            });
                        });
                    }
                });
            });
        });

    }

    public onDateSelection = (struct: DateStruct): void => {
        if (this.isFirstLoad) {
            this.prevDate = CommonDate.fromString(this.data.estimate.ValuationDate);
            this.currentDate = CommonDate.fromStruct(struct);
            this.isFirstLoad = false;
        } else {
            this.prevDate = this.currentDate;
            this.currentDate = CommonDate.fromStruct(struct);
        }

        const currDateBool = BenchmarkingUtil.isSic(CommonDate.fromStruct(this.currentDate).asString(), 'United States');
        const prevDateBool = BenchmarkingUtil.isSic(CommonDate.fromStruct(this.prevDate).asString(), 'United States');

        if ((currDateBool && !prevDateBool) || (prevDateBool && !currDateBool)) {
            // reset estimate industries to GICS
            const emptyIndustry: Industry[] = new Array<Industry>();
            emptyIndustry.push({
                CodeId: 0,
                GicIndustryCode: '',
                SicIndustryCode: '',
                Sector: ''
            } as Industry);
            this.industryDataSharingService.setSharedIndustries = emptyIndustry;
        }
    }
    public reflectDate(user: User, struct: DateStruct): void {
        const date = CommonDate.fromStruct(struct);

        const request = combineLatest([
            this.store.dispatch(new GetTaxRateList(77349, date.asString())),
            this.dataStore.updateIndustries(date.asString(), this.isIndustryAnalysis)
        ]);
        const productType = this.isIndustryAnalysis ? ProductType.Usi : ProductType.Coc;
        this.valuationDateConfig = UserUtil.getValuationDateInputConfig(date, productType, this.minimumDate, user.subscriptionDetails);
        this.valuationDate.value = date.asStruct();
        this.spinner.while(request);
        request.once(() => {
            const currentIndustries = this.dataStore.industries;
            this.spinner.while(currentIndustries);
            currentIndustries.onceDefined((industries) => {
                const condition = date.asString() < Constants.FilterIndustryCodeDate;
                const filteredIndustries = condition ? industries.filter((x) => x.SicIndustryCode != null) :
                    industries.filter((x) => x.GicIndustryCode != null);
                this.data.industries = filteredIndustries;
                this.queryBuilderService.industries = filteredIndustries;
                this.benchMarkBuilderService.industries = filteredIndustries;
                this.industries.forEach((x) => x.value = InputsUtil.validateIndustry(filteredIndustries, x.value));
            });
            this.dataStore.countries.onceDefined(() => {
                combineLatest([
                    this.dataStore.isIndustryAnalysis,
                    this.userStore.user,
                    this.valuesSelector,
                    this.taxRateListSelector
                ]).pipe(
                    takeUntil(this.destroyed),
                    map((x) => ({
                        isIndustryAnalysis: x[0],
                        user: x[1],
                        values: x[2],
                        taxRate: x[3]
                    }))
                ).onceDefined((data) => {
                    this.isIndustryAnalysis = data.isIndustryAnalysis;
                    this.continueBtnText = this.isIndustryAnalysis ? InputsTexts.continue : InputsTexts.saveAndContinue;
                    this.promptTitle = this.isIndustryAnalysis ? InputsTexts.highLevelAnalysis : InputsTexts.highLevelEstimate;
                    this.promptSubtitle = this.isIndustryAnalysis ? InputsTexts.startAnalysis : InputsTexts.startEstimate;
                    this.dataStore.industries.onceDefined(() => {
                        if (data.values == null) {
                            return;
                        }
                        if (data.taxRate != null) {
                            this.taxRateSuggestions = data.taxRate.map((x) => IntlEstimateUtil.intlDataAsSuggestion(x.Value, x.Label, x.DataAsOf, x.Source));
                        }
                    });
                });
            });
        });
    }

    public updateIndustryUsage(noIndustry: boolean): void {
        const temp: ContextualValue<Industry>[] = [];

        if (noIndustry) {
            this.industryConfig.disabled = true;
            this.industries.forEach((x) => {
                temp.push(new ContextualValue(x.value, x.context));
                x.value = null;
            });
            this.lastSelectedIndustries = temp;
        } else {
            this.industryConfig.disabled = false;
            if (this.lastSelectedIndustries.length > 0) {
                this.lastSelectedIndustries.forEach((x) => {
                    temp.push(new ContextualValue(x.value, x.context));
                });
                this.lastSelectedIndustries = temp;
                this.industries.forEach((x) => x.value = InputsUtil.validateIndustry(this.data.industries, x.value));
            }
        }
    }

    public saveAndContinue(estimate: Estimate, struct: DateStruct, industries: Industry[], home?: Country): void {
        if (industries.length === 0) {
            const emptyIndustry: Industry[] = new Array<Industry>();
            emptyIndustry.push({
                CodeId: 0,
                GicIndustryCode: '',
                SicIndustryCode: '',
                Sector: ''
            } as Industry);
            this.industryDataSharingService.setSharedIndustries = emptyIndustry;
        } else {
            this.industryDataSharingService.setSharedIndustries = industries;
        }

        if (InputsUtil.hasIndustrySelected(industries) === false && this.isIndustryAnalysis) {
            this.modalManager.open<void, SelectIndustryModalComponent>(SelectIndustryModalComponent, {
                mayDismiss: false
            });
            return;
        }
        const date = CommonDate.fromStruct(struct);
        const codeIds = industries.map((x) => x.CodeId);
        const hasDateChanged = date.equals(estimate.ValuationDate) === false;
        let resetType: ResetType | undefined;
        let resetText: string;
        const hasResettableData = estimate.Inputs.length > 0 ||
            Util.selectMany(estimate.Scenarios, (x) => x.Selections).filter((x) => x.Value != null).length > 0;
        // TODO: Support automatic selection of new values based on the changes
        if (hasResettableData && hasDateChanged) {
            const day = date.asMoment();
            resetType = ResetType.Estimate;
            resetText = ResetTexts.changeValuationDate(day);
        } else if (hasResettableData && InputsUtil.hasIndustryChanged(estimate.Industries, codeIds)) {
            resetType = ResetType.Industry;
            resetText = ResetTexts.changeIndustry();
        }

        const modal = resetType == null
            // tslint-disable-next-line import/no-deprecated
            ? of(void 0)
            : this.modalManager.open<void, ResetEstimateModalComponent>(ResetEstimateModalComponent, {
                mayDismiss: false, modalFunc: (x) => {
                    x.title = `Reset ${resetType}`;
                    x.text = resetText;
                }
            });

        modal.once(() => {
            estimate.Industries = estimate.getEstimateIndustries(industries);
            estimate.IsIndustryApplicable = this.noIndustry.value ? false : true;
            estimate.HomeCountryId = home == null ? -1 : home.CountryId;
            estimate.ValuationDate = date.asString();
            estimate.IsIndustryAnalysis = this.isIndustryAnalysis ? this.isIndustryAnalysis : false;
            this.estimateService.updateEstimate(estimate, resetType);
        });
    }

    public showCompanies(valuationDate: DateStruct, industry: Industry): void {
        const date = CommonDate.fromStruct(valuationDate).asString();
        const request = this.usCompanyClient.read(date, industry.CodeId);
        this.spinner.while(request);
        request.once((companyData) => {
            this.primeManager.openDialog(CompanyListComponent, {
                companyData,
                sicCode: industry.SicIndustryCode
            });
        });
    }

    public showIndustries(valuationDate: DateStruct): void {
        const date = CommonDate.fromStruct(valuationDate).asString();
        const request = this.usIndustryClient.read(date);
        this.spinner.while(request);
        request.once((industries) => {
            this.primeManager.openDialog(ViewIndustriesComponent, {
                valuationDate: date,
                moduleName: 'USCOC',
                industries
            });
        });
    }

    public isGeneralInputsValid(): boolean {
        if (this.selectedIndustries.length >= this.maximumNumberOfIndustries) {
            return false;
        } else {
            return true;
        }
    }

    public pullIndustries(valuationDate: DateStruct): void {
        const date = CommonDate.fromStruct(valuationDate).asString();
        const request = this.usIndustryClient.read(date);
        this.spinner.while(request);
        request.once((industries) => {
            this.primeManager.openDialog(AddIndustriesComponent, {
                valuationDate: date,
                moduleName: 'USCOC',
                allIndustries: industries,
                userSelectedIndustries: this.selectedIndustries,
            });
        });
    }

    public removeIndustry = (codeId: number): void => {
        this.selectedIndustries = this.selectedIndustries.filter((item) => item.CodeId !== codeId);
        this.showAddIndustry = InputsUtil.isAddIndustryAvailable(this.selectedIndustries.length, this.maximumNumberOfIndustries);
    }
}
