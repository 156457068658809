/* eslint-disable max-len */
export class SummaryText {
    public static readonly PreFinalConclusion = 'Pre-Final Conclusion ‒ International Fisher Effect Required';
    public static readonly CompleteKeSummaryNotice = 'In order to complete <i>Step 6 – Cost of Equity Summary</i>, all required inputs from <i>Step 2 – Cost of Equity Inputs</i> must be filled out.';
    public static readonly CompleteKeFisherEffectNotice = 'In order to complete <i>Step 6 - Cost of Equity Summary – 2. International Fisher Effect</i>, all inputs from <i>Step 5 – Intl Fisher Effect Inputs</i> must be filled out.';
    public static readonly FisherEffectNotNeeded = 'The International Fisher Effect is not needed.';
    public static readonly Edit = 'Edit';
    public static readonly CompleteKdSummaryNotice = 'In order to complete <i>Step 7 – Cost of Debt Summary</i>, all inputs from <i>Step 3 – Kd and WACC Inputs</i> must be filled out.';
    public static readonly CompleteKdFisherEffectNotice = 'In order to complete <i>Step 7 - Cost of Equity Summary – 2. International Fisher Effect</i>, all inputs from <i>Step 5 – Intl Fisher Effect Inputs</i> must be filled out.';
    public static readonly CompleteWaccSummaryNotice = 'In order to complete <i>Step 8 – WACC Summary</i>, all inputs from <i>Step 2 - Cost of Equity Inputs</i> and <i>Step 3 – Kd and WACC Inputs</i> must be filled out.';
    public static readonly CompleteWaccFisherEffectNotice = 'In order to complete <i>Step 8 - WACC Summary – 1. WACC Estimates</i>, all inputs from <i>Step 5 – Intl Fisher Effect Inputs</i> must be filled out.';
    public static readonly CompleteWaccConclusionNotice = 'In order to complete <i>Step 8 - WACC Summary – 2. WACC Conclusion</i>, all inputs from <i>Step 5 – Intl Fisher Effect Inputs</i> must be filled out.';

    public static translateCurrencies(countryOfInputCurrency: string, cashFlowCurrency: string): string {
        return `Translating from ${countryOfInputCurrency} to ${cashFlowCurrency}`;
    }

    public static investeeCountry(investeeCountry: string, isExcel: boolean | undefined = false): string {
        return isExcel ? `Investee Country : ${investeeCountry}` : `<div><b>Investee Country</b>: ${investeeCountry}</div>`;
    }
}
