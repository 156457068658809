import { Component } from '@angular/core';
import { ContextualNumber } from '@concurrency/core';
import { SelectionType } from 'src/app/_api/enums/selection-type';
import { InputType } from 'src/app/_api/responses/input.response';
import { CommonInputConfig } from 'src/app/_navigator/common/inputs/common-user-input.component';
import { DataStore } from 'src/app/_navigator/data/store/data.store';
import { ErrorService } from 'src/app/_navigator/error/error.service';
import { HelpText } from 'src/app/_navigator/help/help-text';
import { SimpleEditorComponent } from './editor.component';

@Component({ templateUrl: './equity-risk-premium-editor.component.html' })
export class EquityRiskPremiumEditorComponent extends SimpleEditorComponent {
    public usesNormalizedRate = false;
    public inputConfig: CommonInputConfig = {
        name: 'Equity Risk Premium (%)',
        help: HelpText.EquityRiskPremiumDetailed,
        error: this.errorService.equityRiskPremium,
        minimumValue: 0,
        maximumValue: 10,
        pattern: '(.*?)',
        autofocus: true
    };

    constructor(
        protected dataStore: DataStore,
        private errorService: ErrorService
    ) { super(dataStore); }

    // Override
    public initialize(): void {
        const riskFreeRate = this.settings.scenario.getSelection(SelectionType.RiskFreeRate, InputType.None);

        if (riskFreeRate.Context === 'Duff & Phelps Normalized Rate' || riskFreeRate.Context === 'Kroll Normalized Risk-free Rate') {
            this.inputConfig.readonly = true;
            this.usesNormalizedRate = true;
            setTimeout(() => {
                if (this.settings.suggestions == null) {
                    throw new Error(`Suggestions should not have been null`); // `
                }
                this.local = new ContextualNumber(
                    this.settings.suggestions[0].value,
                    this.settings.suggestions[0].source,
                    this.settings.suggestions[0].dataAsOf
                );
                this.update();
            });
        }

        this.mask();
    }
}
