import { Injector } from '@angular/core';

// TODO: Move this to @concurrency/core or perhaps @concurrency/angular
export class AppInjector {

    public static setInjector(injector: Injector): void {
        AppInjector.injector = injector;
    }

    public static getInjector(): Injector {
        return AppInjector.injector;
    }
    private static injector: Injector;
}
