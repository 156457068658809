import { Component, OnInit } from '@angular/core';
import { ContextualString } from '@concurrency/core';
import { Suggestion } from 'src/app/_navigator/data/model/suggestion.model';
import { CocInputValues } from '../../models/cocInputs';
import { DecilesPortfolio, HighFinancialRiskStudy, LeveredPortfolio, RiskPremiumPortfolio, RiskPremiumSizeStudyPortfolio, UnleveredPortfolio } from '../../models/cocInputsTabs';
import { NumberFormatUtil } from 'src/app/_navigator/data/util/number-format.util';
import { Portfolio } from 'src/app/_api/responses/portfolio.response';
import { DataStudy, RiskFreeRateOVerRP, RiskPremiumDataStudy, RiskPremiumReportRiskStudy, RiskPremiumReportSizeStudy, enumExcelExportSheets, zscoredata } from '../../cocViewInputs';
import { CommonInputConfig } from 'src/app/_navigator/common/inputs/common-user-input.component';
import { CocInputsSharedService } from '../../service/cocInputShared.service';
import { MeasureClient } from 'src/app/_api/clients/measure.client';
import { RiskClient } from 'src/app/_api/clients/risk.client';
import { EquationType } from 'src/app/_api/responses/equation.response';
import { InputType } from 'src/app/_api/responses/input.response';
import { ComboboxDataType } from 'src/app/_navigator/data/enum/combobox-data-type.enum';
import { Rpr } from 'src/app/_api/responses/rpr.response';
import { Spinner } from '@concurrency/angular';
import { FilesClient } from 'src/app/_api/clients/files.client';
import { ResourceFileType } from 'src/app/_api/enums/resource-file-type';
import { CocInputResourcesList } from '../../coc-input-resources-list.model';
import { ExcelExportComponent } from '@progress/kendo-angular-excel-export';
import { CocinputsExcelExportService } from '../../service/cocinputs-excel-export.service';
import { RegressionValues } from 'src/app/_api/responses/RprRegressionData.response';
import { UserStore } from 'src/app/_navigator/user/store/user.store';
import { RPRsharedService } from '../../service/rpr-shared-service';
import { RiskPremiumCharacteristicsData } from '../../models/rprCharacteristicsData';
import { spRPRCharacter } from '../../commonHeaders';
import { EnumSubsType } from '../../models/userSubscriptionTypes';

@Component({
    selector: 'risk-premium-over-risk-free-rate',
    templateUrl: './risk-premium-over-risk-free-rate.component.html',
    styleUrls: ['./risk-premium-over-risk-free-rate.component.scss']
})
export class RiskPremiumOverRiskFreeRateComponent implements OnInit {
    exportFileName!: string;
    isineCoc!: boolean;

    constructor(
        public spinner: Spinner,
        private sharedCocInputService: CocInputsSharedService,
        private exportService: CocinputsExcelExportService,
        private rPRsharedService: RPRsharedService,
        private userStore: UserStore) { }

    public riskPremiumDataStudy = new ContextualString();
    public companyRankedBy = new ContextualString();
    public dataStudyoptions: Suggestion<string>[] = [];
    public companyRankedByoptions: Suggestion<string>[] = [];
    public tblHeadrers!: string[];
    public cocInputs!: CocInputValues;
    public arraydeciles!: DecilesPortfolio[];
    public numberFormatUtil!: NumberFormatUtil;
    public arraySpecifiTypePortfolio!: DecilesPortfolio[];
    public arrayRPRSSLeveredTypePortfoio!: LeveredPortfolio[];
    public arrayRPRSSUnleveredTypePortfolio!: UnleveredPortfolio[];
    public arrayRPRRSLeveredTypePortfolio!: LeveredPortfolio[];
    public arrayRPRRSUnleveredTypePortfolio!: UnleveredPortfolio[];
    public arrayRPSTypePortfolio!: RiskPremiumPortfolio[];
    public arrayRPRTypePortfolio!: RiskPremiumPortfolio[];
    public arrayHFRSZscore!: HighFinancialRiskStudy[];
    public riskPremiumOverRfrResourcesList!: CocInputResourcesList;
    public riskPremiumOverRFRValuationDate!: string;
    public riskStudyRegressionValues!: RegressionValues;
    public sizeStudyLeveredRegressionValues!: RegressionValues;
    public sizeStudyUnleveredRegressionValues!: RegressionValues;
    public isSupplemetryData!: boolean;
    public arraySuppRiskStudyData!: any[];
    public arraySuppRiskSizeStudyData!: RiskPremiumSizeStudyPortfolio[];
    public supplementaryCmpnyRankedBy = new ContextualString();
    RPrftabIndex!: number
    public RPRRSsupplyDataHeaders =
        ['Portfolio', 'Low End Breakpoint ($M)', 'High End Breakpoint ($M)', 'Number of companies', 'Arithmetic Mean Return', 'Geometric Mean Return', 'Standard Deviation of Returns', 'Sum Beta (Since 1963)', 'Avg.Debt/MVIC'];


    public HFRSupplyDataHeaders = ['Z-Score Portfolio', 'Zone', 'Industry', 'Artihmetic Mean Return', 'Geometric Mean Return', 'Standard Deviation of Returns', 'Sum Beta(Since 1963)', 'Avg. Debt/MVIC', 'Avg. Debt/Equity', 'Avg. Operating Margin'];

    public commonSupplyHeaders!: [] | any;


    public headresData = {
        'Risk Premium Report Size Study': ['Portfolio', 'Low End Breakpoint ($M)', 'High End Breakpoint ($M)', 'Levered Risk Premium over the Risk-free Rate', 'Unlevered Risk Premium over the Risk-free Rate'],
        'Risk Premium Report Risk Study': ['Portfolio', 'Low End Breakpoint ($M)', 'High End Breakpoint ($M)', 'Levered Risk Premium over the Risk-free Rate'],

        'High-Financial-Risk Study': ['Z-Score Portfolio', 'Zone', 'Industry', 'Risk Premium over the Risk-free Rate']
    } as any;

    public companyRankedData = {
        'Risk Premium Report Size Study': RiskPremiumReportSizeStudy,
        'Risk Premium Report Risk Study': RiskPremiumReportRiskStudy,
        'High-Financial-Risk Study': 'z-score',
    } as any;

    public dataStudyConfig: CommonInputConfig = {
        name: 'Data Study',
        placeholder: 'Select Data',
        containerClassList: 'input-flex-display inline-disable-text',
        stackedDisplay: true,
        iconWithLabel: true,
        required: true,
        readonly: true,
    };

    public companyRankedByConfig: CommonInputConfig = {
        name: 'Companies Ranked By',
        placeholder: 'Select Company ',
        containerClassList: 'input-flex-display inline-disable-text',
        stackedDisplay: true,
        iconWithLabel: true,
        required: true,
        readonly: true
    };

    public suplyCompanyRankedByConfig: CommonInputConfig = {
        name: '',
        placeholder: 'Select Company ',
        containerClassList: 'input-flex-display inline-disable-text',
        stackedDisplay: true,
        iconWithLabel: false,
        required: true,
        readonly: true
    };




    ngOnInit(): void {
        this.isineCoc = (this.sharedCocInputService.usrPerms.SubsType == EnumSubsType.IneAndCoc || this.sharedCocInputService.usrPerms.SubsType == EnumSubsType.Ine) && this.sharedCocInputService.cocInputValues.investorPerspectiveregion != "United States";
        this.loadRiskFreeRateOverRiskPremium();
    }
    public loadRiskFreeRateOverRiskPremium() {
        this.cocInputs = this.sharedCocInputService.getCocInputValues();

        // this.onCompanyChange('Market Value of Common Equity');
        // this.sharedCocInputService.setDataOptions();
    }


    // public settblHeader() {
    //     this.tblHeadrers = this.headresData[DataStudy.RiskPremiumReportSizeStudy];
    // }




    public onCompanyChange(companyRankedType: any) {
        if (this.sharedCocInputService.riskPremiumDataStudy.value === RiskPremiumDataStudy.RiskPremiumReportSizeStudy) {
            this.sharedCocInputService.onCompanyRankedByChnage(companyRankedType);
        } else {
            this.sharedCocInputService.onCompanyRankedbyRiskChange(companyRankedType);
        }
    }

    public exportToExcel(component: ExcelExportComponent) {
        let enumdataStudy = enumExcelExportSheets.HighFinancialRiskStudy
        this.exportFileName = 'RPOverRfr-HFR-data'


        if (this.sharedCocInputService.riskPremiumDataStudy.value == RiskPremiumDataStudy.RiskPremiumReportSizeStudy) {

            this.exportFileName = 'RPOverRfr-RPRS-data'
            enumdataStudy = enumExcelExportSheets.RiskPremiumReportSizeStudy;
        }
        if (this.sharedCocInputService.riskPremiumDataStudy.value == RiskPremiumDataStudy.RiskPremiumReportRiskStudy) {
            this.exportFileName = 'RPOverRfr-RPRR-data'
            enumdataStudy = enumExcelExportSheets.RiskPremiumReportRiskStudy;
        }
        this.exportService.generateWorkBook(component, enumdataStudy);

    }

    public ocClickViewSupplementryData(dataStudy: string) {


        if (this.sharedCocInputService.riskPremiumDataStudy.value == RiskPremiumDataStudy.RiskPremiumReportSizeStudy) {
            this.commonSupplyHeaders = this.sharedCocInputService.RPRSSsupplyDataHeaders;
            this.supplementaryCmpnyRankedBy.value = 'Market Value of Common Equity';
            this.mapRPSizeStudySupplyData(InputType.MarketValueOfCommonEquity);
            this.getRFRcharacteristicData(this.sharedCocInputService.cocInputValues.valuationDate)
        }
        if (this.sharedCocInputService.riskPremiumDataStudy.value == RiskPremiumDataStudy.RiskPremiumReportRiskStudy) {
            this.isSupplemetryData = true;
            this.commonSupplyHeaders = this.RPRRSsupplyDataHeaders;
            this.supplementaryCmpnyRankedBy.value = 'Operating Margin';
            this.arraySuppRiskStudyData = this.sharedCocInputService.arraySuppRiskStudyPortfollios.filter((item: any) => item.InputType === InputType.OperatingMargin);
        }
        if (this.sharedCocInputService.riskPremiumDataStudy.value == RiskPremiumDataStudy.HighFinancialRiskStudy) {
            this.commonSupplyHeaders = this.HFRSupplyDataHeaders;
            this.supplementaryCmpnyRankedBy.value = 'z-score'
            this.suplyCompanyRankedByConfig.disabled = true;
            this.isSupplemetryData = true;
        }

    }

    public onChangeSupplyComapanyRankedBy(companyRankedBy: any,) {
        let type: any;
        if (this.sharedCocInputService.riskPremiumDataStudy.value == RiskPremiumDataStudy.RiskPremiumReportSizeStudy) {
            type = RiskPremiumReportSizeStudy[companyRankedBy.value] as InputType;
            this.mapRPSizeStudySupplyData(type);
        }

        if (this.sharedCocInputService.riskPremiumDataStudy.value == RiskPremiumDataStudy.RiskPremiumReportRiskStudy) {
            type = RiskPremiumReportRiskStudy[companyRankedBy.value] as InputType;
            if (type) {
                this.arraySuppRiskStudyData = this.sharedCocInputService.arraySuppRiskStudyPortfollios.filter((item: any) => item.InputType === type);
            }
        }
    }


    public mapRPSizeStudySupplyData(companyRankedBy: InputType) {
        this.arraySuppRiskSizeStudyData = this.sharedCocInputService.arrayLeveredPortfolios.filter(item => item.InputType === companyRankedBy).map((port: any) => {
            return {
                id: port.Id,
                name: port.Name,
                dataAsOf: port.DataAsOf,
                lowBreakPoint: port.Lower,
                highBreakPoint: port.Upper,
                leveredRPORFR: port.Result * 100,
                numberOfCompanies: port.NumberOfCompanies,
                arithameticMeanReturn: port.ArithmeticMeanReturn,
                geometricMeanReturn: port.GeometricMeanReturn,
                sumBetaSince1963: port.SumBetaSince1963,
                standardDeviation: port.StandardDeviationOfReturns,
                avgUnleverdBeta: port.UnleveredBeta,
                avgOperatingMarigin: port.AverageOperatingMargin,
                avgDebtEquity: port.AverageDebtToMvoe,
                avgDebtMvc: port.AverageDebtMvic,
                avgCvOfOperatingMarigin: port.AverageCovom,
                avgCeOfRoe: port.AverageCovroe,
                PortofolioSize: port.PortofolioSize
            } as RiskPremiumSizeStudyPortfolio
        });
    }

    public getRFRcharacteristicData(dataAsof: string) {


        this.spinner.begin();
        this.rPRsharedService.getRFRCharacteristicsData(dataAsof).subscribe((data: RiskPremiumCharacteristicsData) => {
            this.spinner.end();
            data = this.mapRfrCharacteristicData(data);
            this.rPRsharedService.riskPremiumCharacteristicsData = data;
            this.isSupplemetryData = true;
            this.RPrftabIndex = 0;


        });

    }


    public mapRfrCharacteristicData(data: any) {
        Object.keys(data).filter(s => s != 'dataAsOf').forEach(key => {
            if (data[key] !== null) {
                data[key] = Object.entries(data[key])
            } else {
                data[key] = null
            }
        });

        return data;
    }

}