import { ContextualNumber, ContextualValue, Util } from '@concurrency/core';
import { EstimateType } from 'src/app/_api/enums/estimate-type';
import { EstimateIndustries } from 'src/app/_api/responses/estimate-industries.response';
import { EstimateResponse } from 'src/app/_api/responses/estimate.response';
import { Input, InputType } from 'src/app/_api/responses/input.response';
import { Portfolio } from 'src/app/_api/responses/portfolio.response';
import { Selection } from 'src/app/_api/responses/selection.response';
import { SuggestionResponse } from 'src/app/_api/responses/suggestion.response';
import { Industry } from 'src/app/_api/responses/us-industry.response';
import { ZScore } from 'src/app/_api/responses/zscore.response';
import { RiskService } from '../service/risk.service';
import { Scenario } from './scenario.model';

export class Estimate {
    public Id: string;
    public Name: string;
    public ValuationDate: string;
    public Industries: EstimateIndustries[];
    public IsIndustryApplicable: boolean;
    public HomeCountryId: number;
    public InvesteeCountryId: number;
    public Scenarios: Scenario[];
    public Inputs: Input[];
    public Created: Date;
    public Updated: Date;
    public Suggestions: SuggestionResponse[];
    public HistoricRprErp: number | null;
    public IsIndustryAnalysis: boolean;
    public EstimateType: EstimateType;

    constructor(data: EstimateResponse) {
        this.Id = data.Id;
        this.Name = data.Name;
        this.ValuationDate = data.ValuationDate;
        this.Industries = data.Industries;
        this.IsIndustryApplicable = data.IsIndustryApplicable;
        this.HomeCountryId = data.HomeCountryId;
        this.InvesteeCountryId = data.InvesteeCountryId;
        this.Inputs = data.Inputs;
        this.Created = data.Created;
        this.Updated = data.Updated;
        this.Suggestions = data.Suggestions;
        this.HistoricRprErp = data.HistoricRprErp;
        this.IsIndustryAnalysis = false;
        this.EstimateType = data.EstimateType;
        this.Scenarios = (Util.sortBy(data.Scenarios || [], (x) => x.Created)).map((x) => new Scenario(x));
    }

    public getYearlyInputs(inputType: InputType, years: number): Input[] {
        if (inputType == null) {
            throw new Error(`InputType must not be null`);
        }

        const result = [];
        for (let i = 0; i < years; i++) {
            const input = this.getInput(inputType, -i);
            result.push(input);
        }

        return result;
    }

    public getInput(inputType: InputType, relativeYear: number = 0): Input {
        if (inputType == null) {
            throw new Error(`InputType must not be null`);
        }

        let input = this.Inputs.find((x) => x.InputType === inputType && x.RelativeYear === relativeYear);
        if (input == null) {
            input = {
                EstimateId: this.Id,
                InputType: inputType,
                RelativeYear: relativeYear,
                Created: new Date().toISOString(),
                Updated: new Date().toISOString()
            };

            this.Inputs.push(input);
        }

        return input;
    }

    public setInput(inputType: InputType, value?: number | null, relativeYear: number = 0): void {
        if (inputType == null) {
            throw new Error(`InputType must not be null`);
        }

        const input = this.getInput(inputType, relativeYear);
        input.Value = value || undefined;
    }

    public getContextualInput(inputType: InputType, precision?: number): ContextualNumber {
        const input = this.getInput(inputType);
        return new ContextualNumber(input.Value, undefined, undefined, precision);
    }

    public cascadeUpdates(portfolios?: Portfolio[], zscore?: ZScore): void {
        const debt = this.getInput(InputType.DebtRatioEquity);
        const companyType = this.getInput(InputType.CompanyType);
        const forceDistressed = this.getInput(InputType.IsDistressed).Value === 1 ? true : false;

        for (const input of this.Inputs) {
            for (const scenario of this.Scenarios) {
                const related = scenario.Selections
                    .filter((x) => x.InputType === input.InputType)
                    .filter(Util.notNull)
                    .filter((x) => x.Value != null);

                const riskPremiaRelated = related.filter((x) => RiskService.RiskPremia.indexOf(x.SelectionType) > -1);
                for (const item of riskPremiaRelated) {
                    if (portfolios == null) {
                        throw new Error(`Portfolios are required to cascade these updates`);
                    }

                    // TODO: Why do we have to do a divide by 100 for Buildup3 and nothing else...?
                    let inputValue = input.Value;
                    if (item.SelectionType === 'Buildup3RiskPremiumOverTheRiskFreeRate') {
                        let intendedInput = input;

                        // TODO: The list of portfolios incorrectly lists this
                        if (item.InputType === 'OperatingMargin') {
                            intendedInput = this.getInput(InputType.AverageOperatingMargin);
                        }

                        inputValue = intendedInput.Value == null ? undefined : intendedInput.Value / 100;
                    }

                    const portfolioResult = RiskService.getPortfolioResult(portfolios, inputValue, item.InputType, item.SelectionType, {
                        debt: debt.Value,
                        historicRprErp: this.HistoricRprErp || undefined,
                        useRegression: scenario.UseRegressionCalculation
                    });

                    if (portfolioResult.value) {
                        scenario.setSelection(portfolioResult, item.SelectionType, item.InputType);
                    }
                }

                const hfrPremiaRelated = related.filter((x) => RiskService.HighFinancialRiskPremia.indexOf(x.SelectionType) > -1);
                for (const item of hfrPremiaRelated) {
                    if (zscore == null) {
                        throw new Error(`Zscore data is required to cascade these updates`);
                    }

                    const zscoreResult = RiskService.getZScoreResult(
                        zscore, input.Value, companyType.Value, item.SelectionType, forceDistressed
                    );
                    scenario.setSelection(zscoreResult, item.SelectionType, item.InputType);
                }
            }
        }
    }

    public getZScore(): number {
        const marketValueOfEquity = this.getContextualInput(InputType.MarketValueOfCommonEquity).asNumberOrNaN;
        const bookValueOfEquity = this.getContextualInput(InputType.BookValueOfEquity).asNumberOrNaN;
        const totalAssets = this.getContextualInput(InputType.TotalAssets).asNumberOrNaN;
        const currentYearEBIT = this.getContextualInput(InputType.CurrentYearEbit).asNumberOrNaN;
        const currentYearSales = this.getContextualInput(InputType.NetSales).asNumberOrNaN;
        const currentAssets = this.getContextualInput(InputType.CurrentAssets).asNumberOrNaN;
        const currentLiabilities = this.getContextualInput(InputType.CurrentLiabilities).asNumberOrNaN;
        const retainedEarnings = this.getContextualInput(InputType.RetainedEarnings).asNumberOrNaN;
        const companyType = this.getInput(InputType.CompanyType).Value || -1;

        return RiskService.calculateZScore({
            marketValueOfEquity,
            bookValueOfEquity,
            totalAssets,
            currentYearEBIT,
            currentYearSales,
            currentAssets,
            currentLiabilities,
            retainedEarnings,
            companyType
        });
    }

    public hasFinancialIndustry(): boolean {
        for (const industry of this.Industries) {
            if (industry && industry.SicCode && industry.SicCode.toString()[0] === '6') {
                return true;
            }
        }

        return false;
    }

    public getEstimateIndustries(industries: Industry[]): EstimateIndustries[] {
        const estimateIndustries: EstimateIndustries[] = [];

        industries.forEach((x) => {
            estimateIndustries.push({
                EstimateId: this.Id,
                SicId: x.CodeId,
                SicCode: x.SicIndustryCode ? x.SicIndustryCode : x.GicIndustryCode,
                IndustryName: x.Sector
            });
        });

        return estimateIndustries;
    }

    // TODO: Why doesn't this contain a getSources implementation?

    public getIndustryValues(industryList: Industry[]): ContextualValue<Industry>[] {
        let industryValues: ContextualValue<Industry>[] = [];

        if (this.Industries.length > 0) {
            industryValues = this.Industries.map((x) => new ContextualValue<Industry>(
                {
                    CodeId: x.SicId,
                    SicIndustryCode: x.SicCode,
                    // TODO replace this empty string value with the reflected industry response
                    GicIndustryCode: '',
                    Sector: x.IndustryName
                }
            ));
            for (let i = 0; i < industryValues.length; i++) {
                /* eslint-disable */
                industryValues[i].value = industryList.find((x) => x.CodeId === this.Industries[i].SicId &&
                    (x.SicIndustryCode == this.Industries[i].SicCode || x.GicIndustryCode == this.Industries[i].SicCode)) || null;

                /* eslint-enable */
            }
        } else {
            industryValues.push(new ContextualValue<Industry>());
        }

        return industryValues;
    }

    public setSelection(scenario: Scenario, selection: Selection): void {
        const scenarioFound = this.Scenarios.find((x) => x.Id === scenario.Id);

        if (scenarioFound == null) {
            return;
        }

        const selectionFound = scenarioFound.Selections.find((x) => x.SelectionType === selection.SelectionType);

        if (selectionFound == null) {
            scenarioFound.Selections.push(selection);
            return;
        }

        if (selectionFound.Value === selection.Value) {
            return;
        }

        selectionFound.Value = selection.Value;
    }

}
