// TODO: Code re-use between this and the accept-terms modal?
// Probably I would keep the templates the same with the variable text defined by the component
// and make the names more similar
import { Component } from '@angular/core';

@Component({ templateUrl: './pre-purchase-modal.component.html' })
export class PrePurchaseModalComponent {
    public acceptTerms = false;
    public acceptMarketing = false;
    public readyForPurchase?: boolean;

    public setAcceptance(): void {
        if (this.acceptTerms) {
            this.readyForPurchase = this.acceptMarketing;
        } else {
            this.readyForPurchase = undefined;
        }
    }
}
