import { EstimateIndustries } from '../_api/responses/estimate-industries.response';
import { Industry } from '../_api/responses/us-industry.response';
import { IndustryDataSelection } from '../_navigator/data/model/industry-data-request.model';

export class InputsUtil {
    public static hasIndustryChanged(industries: EstimateIndustries[], sicIds: number[]): boolean {
        for (let i = 0; i < industries.length; i++) {
            if (industries[i].SicId != null && industries[i].SicId !== sicIds[i]) {
                return true;
            }
        }

        return false;
    }

    public static validateIndustry(industries: Industry[], industryValue: Industry | null = null): Industry | null {
        if (industryValue == null) {
            return industryValue;
        }

        const industry = industries.find((x) => x.CodeId === industryValue.CodeId);
        if (industry == null) {
            return null;
        }

        return industryValue;
    }

    public static isAddIndustryAvailable(industriesLength: number, maxIndustries: number): boolean {
        return industriesLength !== maxIndustries;
    }

    public static hasIndustrySelected(industries: Industry[]): boolean {
        const selectedIndustry = industries.find(
            (x) => x.Sector != null && x.SicIndustryCode != null && x.CodeId != null
        );

        if (selectedIndustry == null) {
            return false;
        }

        return true;
    }

    public static retrieveValidScenarios(scenarios: IndustryDataSelection[]): IndustryDataSelection[] {
        const validScenarios = scenarios.filter((scenario) => {
            return scenario.Currency.value != null && scenario.Region.value != null && scenario.Industry.value != null;
        });

        return validScenarios;
    }
}
