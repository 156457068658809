import { Injectable } from '@angular/core';
import { PDFExportComponent } from '@progress/kendo-angular-pdf-export';

@Injectable()
export class IndustryPdfExportService {
    private pdf?: PDFExportComponent;
    public preparePdfExport = false;
    public prepareExcelExport = false;

    public setPdf(pdf: PDFExportComponent): void {
        this.pdf = pdf;
    }

    public savePdf(): void {
        // Kendo ostensibly solves the prepare export problem itself, but it doesn't work
        this.preparePdfExport = true;

        setTimeout(() => {
            if (this.pdf == null) {
                throw Error(`Expected setPdf to be called before savePdf.`);
            }

            this.pdf.saveAs('usisummary.pdf');
            setTimeout(() => {
                this.preparePdfExport = false;
            }, 50);
        }, 10);
    }
}
