import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CanActivate } from '@angular/router';
import { AccessType } from './user/enum/access-type';
import { UserStore } from './user/store/user.store';

@Injectable()
export class IsNotSubscriberGuard implements CanActivate {
    constructor(
        private router: Router,
        private userStore: UserStore
    ) { }

    // TODO: Why does this only work with a promise and not an Observable?
    // see: https://stackoverflow.com/questions/41131476/emptyerror-no-elements-in-sequence
    public canActivate(): Promise<boolean> {
        return new Promise((resolve) => {
            this.userStore.access.onceDefined((access: AccessType) => {
                if (access === 'Authorized') {
                    this.router.navigate(['']);
                    resolve(false);
                } else {
                    resolve(true);
                }
            });
        });
    }
}
