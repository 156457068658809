export enum Group {
    Capm,
    FisherEffect,
    Crp,
    CostOfDebtCapital,
    CostOfDebtFisherEffect,
    CostOfDebtConclusion,
    WaccCapital,
    WaccConclusion,
    None
}
