import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { CommonUserInputComponent } from './common-user-input.component';

@Component({
    selector: 'common-typeahead',
    templateUrl: './common-typeahead.component.html'
})
export class CommonTypeaheadComponent extends CommonUserInputComponent {
    @Input() public typeahead!: (text: Observable<string>) => Observable<any>;
    @Input() public typeaheadValue!: string;
    @Input() public typeaheadFormatter!: (item: any) => string;

    constructor(
    ) { super(); }

    public emitChange(isValid: boolean): void {
        const hasTypeaheadResult = this.model.value && this.model.value[this.typeaheadValue];
        const hasStandardInput = this.typeahead == null && this.model.value;

        if (hasStandardInput || hasTypeaheadResult) {
            super.emitChange(isValid);
        }
    }

    public emitFocus(event: FocusEvent): void {
        super.emitFocus(event);
    }
}
