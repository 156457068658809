import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { ModalManager, SubscriberEntity } from '@concurrency/angular';
import { SubscriptionLevel } from 'src/app/_navigator/user/enum/subscription-level';
import { environment } from 'src/environments/environment';
import { UserStore } from '../../_navigator/user/store/user.store';
import { PurchaseRedirectModalComponent } from '../_modals/purchase-redirect-modal.component';

// TODO: Re-use this component on the landing page
@Component({ templateUrl: 'buy-now.component.html' })
export class BuyNowComponent extends SubscriberEntity implements OnInit, OnDestroy {
    public showBasic = false;
    public showPro = false;

    constructor(
        private router: Router,
        private modalManager: ModalManager,
        private userStore: UserStore
    ) { super(); }

    private showPurchaseRedirectModal(signupElection: string): void {
        const modal = this.modalManager.open(PurchaseRedirectModalComponent, { mayDismiss: false });
        modal.subscribe(() => {
            switch (signupElection) {
                case 'enterprise':
                    window.open(`${environment.urls.fastSpring}/entsub`, '_blank');
                    break;
                case 'basic':
                    window.open(`${environment.urls.fastSpring}/basicsub`, '_blank');
                    break;
                case 'pro':
                default:
                    window.open(`${environment.urls.fastSpring}/prosub`, '_blank');
                    break;
            }
        });
    }

    public ngOnInit(): void {
        this.userStore.user.onceDefined((user) => {
            this.showBasic = true;
            this.showPro = true;

            if (user.subscriptionDetails.some((sub) => sub.level === SubscriptionLevel.Pro)) {
                this.showBasic = false;
            }

            if (user.subscriptionDetails.some((sub) => sub.level === SubscriptionLevel.Enterprise)) {
                this.showBasic = false;
                this.showPro = false;
            }
        });

        // TODO: Fix the styling the scaffolding so that this embarassing hack isn't necessary
        const main: any = window.document.getElementsByClassName('main')[0];
        main.style['min-width'] = '1100px';
    }

    // TODO: Fix the styling the scaffolding so that this embarassing hack isn't necessary
    public ngOnDestroy(): void {
        const main: any = window.document.getElementsByClassName('main')[0];
        main.style['min-width'] = '';
        super.ngOnDestroy();
    }

    public signupBasic(): void {
        this.showPurchaseRedirectModal('basic');
    }

    public signupPro(): void {
        this.showPurchaseRedirectModal('pro');
    }

    public contactUsEnterprise(): void {
        const extras: NavigationExtras = {
            queryParams: {
                subject: 'Enterprise License Inquiries'
            }
        };

        this.router.navigate(['contact-us'], extras);
    }
}
