import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Spinner, SubscriberEntity } from '@concurrency/angular';
import { Select, Store } from '@ngxs/store';
import { combineLatest } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { map, takeUntil } from 'rxjs/operators';
import { CountryRiskPremia } from '../_api/responses/country-risk-premia.response';
import { EstimateSummary } from '../_api/responses/estimate-summary.response';
import { CountryRiskPremiaListState } from '../_navigator/country-risk-premia-list-store/country-risk-premia-list-state';
import { Estimate } from '../_navigator/data/model/estimate.model';
import { DataStore } from '../_navigator/data/store/data.store';
import { UpdateDynamicTextSummary } from '../_navigator/dynamic-text-store/dynamic-text-summary-action';
import { DynamicTextSummaryState } from '../_navigator/dynamic-text-store/dynamic-text-summary-state';
import { UpdateDbEstimate, UpdateLocalEstimate } from '../_navigator/estimate-store/estimate-actions';
import { EstimateState } from '../_navigator/estimate-store/estimate-state';
import { EstimateSummaryState } from '../_navigator/estimate-summary/estimate-summary-state';
import { SummaryTabs } from './data/enum/summary-tabs';
import { IntlSummaryUtil } from './data/util/intl-summary.util';
import { DynamicTextSummary } from './dynamic-text/model/dynamic-text-summary';
import { DynamicTextBuilderUtil } from './dynamic-text/util/dynamic-text-builder.util';
import { Group } from './route/group';
import { RouteUtil } from './route/route.util';

@Component({
    selector: 'wacc-summary',
    templateUrl: './wacc-summary.component.html'
})
export class WaccSummaryComponent extends SubscriberEntity implements OnInit, OnDestroy {

    private currentRoute!: string;
    public activeGroup: Group = Group.Capm;
    public Group = Group;
    public saveBtnText = 'Continue';
    public SummaryTabs = SummaryTabs;

    @Select(EstimateState.get) public estimateSelector!: Observable<Estimate | undefined>;
    @Select(EstimateSummaryState.get) public estimateSummarySelector!: Observable<EstimateSummary | undefined>;
    @Select(DynamicTextSummaryState.get) public dynamicTextSummarySelector!: Observable<DynamicTextSummary | undefined>;
    @Select(CountryRiskPremiaListState.get) public countryRiskPremiaSelector!: Observable<CountryRiskPremia[] | undefined>;

    constructor(private router: Router, private store: Store, private spinner: Spinner, private dataStore: DataStore) {
        super();
    }

    private setupTab(): void {
        this.currentRoute = this.router.url;
        this.activeGroup = RouteUtil.getActiveGroup(this.router);
    }

    public ngOnInit(): void {
        this.dataStore.triggerAllowExport(true);
        this.setupTab();
        this.router.events.pipe(takeUntil(this.destroyed)).while(() => this.setupTab());
        combineLatest([
            this.estimateSelector,
            this.estimateSummarySelector,
            this.dynamicTextSummarySelector,
            this.countryRiskPremiaSelector
        ]).pipe(
            takeUntil(this.destroyed),
            map((x) => ({
                estimate: x[0],
                summary: x[1],
                dynamicText: x[2],
                crp: x[3]
            }))
        ).onceDefined((data) => {
            if (data.estimate == null || data.summary == null || data.dynamicText == null || data.crp == null) {
                return;
            }

            IntlSummaryUtil.setSummarySelections(data.estimate, data.summary);
            const dynamicTextSummary = DynamicTextBuilderUtil.getDynamicTextSummary(data.summary, data.dynamicText, data.crp);
            this.store.dispatch(new UpdateDynamicTextSummary(dynamicTextSummary));
            this.store.dispatch(new UpdateLocalEstimate(data.estimate));
        });
    }

    public ngOnDestroy(): void {
        this.dataStore.triggerAllowExport(false);
        super.ngOnDestroy();
    }

    public saveAndContinue(): void {
        const request = this.store.dispatch(new UpdateDbEstimate());
        this.spinner.while(request);
        request.once(() => {
            RouteUtil.saveAndContinue(this.currentRoute, this.router);
        });
    }

    public previous(): void {
        RouteUtil.previous(this.currentRoute, this.router);
    }

    public routeTo(group: Group): void {
        RouteUtil.routeTo(group, this.router);
    }
}
