import { EstimateSummary } from 'src/app/_api/responses/estimate-summary.response';
import { EstimateSummaryText } from '../dynamic-text/model/estimate-summary-text';
import { SelectionLogGroup } from './selection-log-group';

export class SelectionLogUtil {
    public static convertToSelectionLogEntities(estimateSummary: EstimateSummary): SelectionLogGroup[] {
        const generalInputsGroup: SelectionLogGroup = {
            name: EstimateSummaryText.GeneralInputs,
            members: [
                { name: EstimateSummaryText.EstimateName, value: estimateSummary.EstimateName },
                { name: EstimateSummaryText.InvestorCountry, value: estimateSummary.InvestorCountryName },
                { name: EstimateSummaryText.InvesteeCountry, value: estimateSummary.InvesteeCountryName },
                { name: EstimateSummaryText.CashFlowCurrency, value: estimateSummary.CashFlowCurrency },
                { name: EstimateSummaryText.CostOfEquityInputsCountry, value: estimateSummary.CostOfEquityInputsCountry },
                { name: EstimateSummaryText.Industry, value: estimateSummary.IndustryName },
                { name: EstimateSummaryText.ValuationDate, value: estimateSummary.ValuationDate }
            ]
        };

        const costOfEquityInputsGroup: SelectionLogGroup = {
            name: EstimateSummaryText.CostOfEquityInputs,
            members: [
                {
                    name: EstimateSummaryText.RiskFreeRate,
                    value: estimateSummary.RiskFreeRateValue,
                    properties: [
                        { name: EstimateSummaryText.Type, value: estimateSummary.RiskFreeRateType },
                        { name: EstimateSummaryText.sectionLogSource, value: estimateSummary.RiskFreeRateSource },
                        { name: EstimateSummaryText.DataAsOf, value: estimateSummary.RiskFreeRateDataAsOf }
                    ]
                },
                {
                    name: EstimateSummaryText.Beta,
                    value: estimateSummary.BetaValue,
                    properties: [
                        { name: EstimateSummaryText.Type, value: estimateSummary.BetaType },
                        { name: EstimateSummaryText.sectionLogSource, value: estimateSummary.BetaSource },
                        { name: EstimateSummaryText.DataAsOf, value: estimateSummary.BetaDataAsOf }
                    ]
                },
                {
                    name: EstimateSummaryText.Erp,
                    value: estimateSummary.EquityRiskPremiumValue,
                    properties: [
                        { name: EstimateSummaryText.Type, value: estimateSummary.EquityRiskPremiumType },
                        { name: EstimateSummaryText.sectionLogSource, value: estimateSummary.EquityRiskPremiumSource },
                        { name: EstimateSummaryText.DataAsOf, value: estimateSummary.EquityRiskPremiumDataAsOf }
                    ]
                },
                {
                    name: EstimateSummaryText.SizePremium,
                    value: estimateSummary.SizePremiumValue,
                    properties: [
                        { name: EstimateSummaryText.sectionLogSource, value: EstimateSummaryText.Custom }
                    ]
                },
                {
                    name: EstimateSummaryText.Alpha,
                    value: estimateSummary.AlphaValue,
                    properties: [
                        { name: EstimateSummaryText.sectionLogSource, value: EstimateSummaryText.Custom }
                    ]
                }
            ]
        };

        const crpRvGroup: SelectionLogGroup = {
            name: EstimateSummaryText.CountryRiskPremia,
            members: [
                { name: EstimateSummaryText.PerspectiveCountry, value: estimateSummary.CountryOfInputsName },
                { name: EstimateSummaryText.InvesteeCountry, value: estimateSummary.InvesteeCountryName },
                {
                    name: EstimateSummaryText.CcrModelCrp,
                    value: estimateSummary.CountryRiskPremium,
                    properties: [
                        { name: EstimateSummaryText.DataAsOf, value: estimateSummary.CountryRiskPremiaDataAsOf }
                    ]
                },
                // {
                //     name: EstimateSummaryText.CountryYieldSpreadModelCrp,
                //     value: estimateSummary.CountryYieldSpreadModel,
                //     properties: [
                //         { name: EstimateSummaryText.DataAsOf, value: estimateSummary.CountryYieldSpreadModelDataAsOf }
                //     ]
                // },
                {
                    name: EstimateSummaryText.RvModel,
                    value: estimateSummary.RelativeVolatilityModel,
                    properties: [
                        { name: EstimateSummaryText.DataAsOf, value: estimateSummary.RelativeVolatilityModelDataAsOf }
                    ]
                },
                {
                    name: EstimateSummaryText.sectionLogSource,
                    value: estimateSummary.CcrSource
                }
            ]
        };

        const fisherEffectGroup: SelectionLogGroup = {
            name: EstimateSummaryText.FisherEffect,
            members: [
                {
                    name: estimateSummary.InvesteeCountryInflationName,
                    value: estimateSummary.InvesteeCountryInflationValue,
                    properties: [
                        { name: EstimateSummaryText.Type, value: estimateSummary.InvesteeCountryInflationType },
                        { name: EstimateSummaryText.sectionLogSource, value: estimateSummary.InvesteeCountryInflationSource },
                        { name: EstimateSummaryText.DataAsOf, value: estimateSummary.InvesteeCountryInflationDataAsOf }
                    ]
                },
                {
                    name: estimateSummary.InvestorCountryInflationName,
                    value: estimateSummary.InvestorCountryInflationValue,
                    properties: [
                        { name: EstimateSummaryText.Type, value: estimateSummary.InvestorCountryInflationType },
                        { name: EstimateSummaryText.sectionLogSource, value: estimateSummary.InvestorCountryInflationSource },
                        { name: EstimateSummaryText.DataAsOf, value: estimateSummary.InvestorCountryInflationDataAsOf }
                    ]
                }
            ]
        };

        const kdWaccGroup: SelectionLogGroup = {
            name: EstimateSummaryText.CostOfDebt,
            members: [
                { name: EstimateSummaryText.CostOfDebtInputsCountry, value: estimateSummary.CostOfDebtInputsCountryName },
                {
                    name: estimateSummary.TaxRateName,
                    value: estimateSummary.TaxRateValue,
                    properties: [
                        { name: EstimateSummaryText.Type, value: estimateSummary.TaxRateType },
                        { name: EstimateSummaryText.sectionLogSource, value: estimateSummary.TaxRateSource }
                    ]
                },
                {
                    name: EstimateSummaryText.DebtToTotalCapitalRatio,
                    value: estimateSummary.DebtToCapitalRatio,
                    properties: [
                        { name: EstimateSummaryText.sectionLogSource, value: EstimateSummaryText.Custom }
                    ]
                },
                {
                    name: EstimateSummaryText.PreTaxKd,
                    value: estimateSummary.PreTaxCostOfDebtKd,
                    properties: [
                        { name: EstimateSummaryText.sectionLogSource, value: EstimateSummaryText.Custom }
                    ]
                }
            ]
        };

        return [generalInputsGroup, costOfEquityInputsGroup, crpRvGroup, fisherEffectGroup, kdWaccGroup];
    }
}
