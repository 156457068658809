import { ApiClient } from '@concurrency/angular';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { CurrencyData } from '../responses/currency-data.response';
import { Currency } from '../responses/currency.response';
import { Injectable } from '@angular/core';
import { Auth0ApiClient } from '../api.client';


@Injectable()
export class CurrencyClient extends Auth0ApiClient {
    protected baseUrl = `${environment.urls.navigatorApi}/api`;

    public read(investorCountryId?: number, investeeCountryId?: number): Observable<CurrencyData[]> {
        let request = `Currency`;

        if (investorCountryId && investeeCountryId) {
            request = request.concat(`?investorCountryId=${investorCountryId}&investeeCountryId=${investeeCountryId}`);
        }
        return this.get(request);
    }

    public readIndustry(): Observable<string[]> {
        const request = `Currency/Industry`;

        return this.get(request);
    }

    public getCurrency(): Observable<Currency[]> {
        return this.get(`Currency/Beta`);
    }
}
