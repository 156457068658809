import { SelectionType } from 'src/app/_api/enums/selection-type';
import { CountryCreditRating } from 'src/app/_api/responses/country-credit-rating.response';
import { Country } from 'src/app/_api/responses/country.response';
import { InputType } from 'src/app/_api/responses/input.response';
import { Scenario } from 'src/app/_navigator/data/model/scenario.model';
import { TableData } from 'src/app/_navigator/data/model/table-data.model';
import { NumberFormatUtil } from 'src/app/_navigator/data/util/number-format.util';
import { SummaryTableCommonUtil } from './summary-table-common.util';

export class CrpTableUtil {
    public static getCountryPremiaTable(ccr: CountryCreditRating, investeeName: string, countryOfInputName: string): TableData {
        const name = `Country Risk Indications from a ${countryOfInputName} Perspective`;
        const headers = SummaryTableCommonUtil.getHeaders(false, false);

        const a = investeeName;
        let b = 'N/A';
        if (ccr.CountryRiskPremia != null) {
            b = `${NumberFormatUtil.numberWithCommas(ccr.CountryRiskPremia * 100)}%`;
        }

        let c = 'N/A';
        if (ccr.YieldSpread != null) {
            c = `${NumberFormatUtil.numberWithCommas(ccr.YieldSpread * 100)}%`;
        }

        let d = 'N/A';
        if (ccr.RelativeVolatility != null) {
            d = NumberFormatUtil.numberWithCommas(ccr.RelativeVolatility);
        }

        const content = [[a, b, c, d]];

        return { name, headers, content };
    }

    public static getKeCountryPremiaTable(
        scenario: Scenario,
        ccr: CountryCreditRating,
        investeeName: string,
        cashFlowCountry: Country,
        countryOfInput: Country
    ): TableData {
        const headers = SummaryTableCommonUtil.getHeaders(false, false);
        const name = `Cost of Equity Conclusion (Denominated in ${cashFlowCountry.Currency.CurrencyName})`;

        const useFisherEffect = cashFlowCountry.Currency.CurrencyId !== countryOfInput.Currency.CurrencyId;
        const crpSelection = useFisherEffect ? SelectionType.CostOfEquityFisherEffectCcr : SelectionType.CostOfEquityCapmCcr;
        const ysSelection = useFisherEffect ? SelectionType.CostOfEquityFisherEffectYieldSpread : SelectionType.CostOfEquityCapmYieldSpread;
        const rvSelection = useFisherEffect ? SelectionType.CostOfEquityFisherEffectVolatility : SelectionType.CostOfEquityCapmVolatility;

        const crpValue = scenario.getSelection(crpSelection, InputType.None);
        const ysValue = scenario.getSelection(ysSelection, InputType.None);
        const rvValue = scenario.getSelection(rvSelection, InputType.None);

        const a = investeeName;

        let b = 'N/A';
        if (ccr.CountryRiskPremia != null && crpValue.Value != null) {
            b = `${NumberFormatUtil.numberWithCommas(crpValue.Value)}%`;
        }

        let c = 'N/A';
        if (ccr.YieldSpread != null && ysValue.Value != null) {
            c = `${NumberFormatUtil.numberWithCommas(ysValue.Value)}%`;
        }

        let d = 'N/A';
        if (ccr.RelativeVolatility != null && rvValue.Value != null) {
            d = `${NumberFormatUtil.numberWithCommas(rvValue.Value)}%`;
        }

        const content = [[a, b, c, d]];

        return { name, headers, content };
    }

    public static getKdCountryPremiaTable(
        scenario: Scenario,
        ccr: CountryCreditRating,
        investeeName: string,
        cashFlowCountryCurrencyAcronym: string,
        cashFlowCountryCurrencyId: number,
        kdInputCountryCurrencyId: number
    ): TableData {
        const useYieldSpreadSelection = scenario.getSelection(SelectionType.UseYieldSpreadOnly, InputType.None);
        const useYieldSpread = useYieldSpreadSelection.Value ? true : false;
        const useFisherEffect = cashFlowCountryCurrencyId !== kdInputCountryCurrencyId;

        const headers = SummaryTableCommonUtil.getHeaders(useYieldSpread, false);
        const name = `After-tax Cost of Debt Conclusion (Denominated in ${cashFlowCountryCurrencyAcronym})`;

        const crpSelection = useFisherEffect ? SelectionType.CostOfDebtFisherEffectCcr : SelectionType.CostOfDebtCcr;
        const ysSelection = useFisherEffect ? SelectionType.CostOfDebtFisherEffectYieldSpread : SelectionType.CostOfDebtYieldSpread;
        const rvSelection = useFisherEffect ? SelectionType.CostOfDebtFisherEffectVolatility : SelectionType.CostOfDebtVolatility;

        const crpValue = scenario.getSelection(crpSelection, InputType.None);
        const ysValue = scenario.getSelection(ysSelection, InputType.None);
        const rvValue = scenario.getSelection(rvSelection, InputType.None);

        const a = investeeName;

        let b = 'N/A';
        if (ccr.CountryRiskPremia != null && crpValue.Value != null) {
            b = `${NumberFormatUtil.numberWithCommas(crpValue.Value)}%`;
        }

        let c = 'N/A';
        if (ccr.YieldSpread != null && ysValue.Value != null) {
            c = `${NumberFormatUtil.numberWithCommas(ysValue.Value)}%`;
        }

        let d = 'N/A';
        if (ccr.RelativeVolatility != null && rvValue.Value != null) {
            d = `${NumberFormatUtil.numberWithCommas(rvValue.Value)}%`;
        }

        const content = useYieldSpread ? [[a, c]] : [[a, b, c, d]];

        return { name, headers, content };
    }

    public static getWaccCountryPremiaTable(
        scenario: Scenario,
        ccr: CountryCreditRating,
        investeeName: string,
        cashFlowCountryCurrencyAcronym: string
    ): TableData {
        const crpValue = scenario.getSelection(SelectionType.WaccCcr, InputType.None);
        const ysValue = scenario.getSelection(SelectionType.WaccYieldSpread, InputType.None);
        const rvValue = scenario.getSelection(SelectionType.WaccVolatility, InputType.None);
        const name = `WACC Conclusion (Denominated in ${cashFlowCountryCurrencyAcronym})`;
        const useYieldSpread = scenario.getSelection(SelectionType.UseYieldSpreadOnly, InputType.None).Value ? true : false;
        const headers = SummaryTableCommonUtil.getHeaders(useYieldSpread, true);

        const a = investeeName;

        let b = 'N/A';
        if (ccr.CountryRiskPremia != null && crpValue.Value != null) {
            b = `${NumberFormatUtil.numberWithCommas(crpValue.Value)}%`;
        }

        let c = 'N/A';
        if (ccr.YieldSpread != null && ysValue.Value != null) {
            c = `${NumberFormatUtil.numberWithCommas(ysValue.Value)}%`;
        }

        let d = 'N/A';
        if (ccr.RelativeVolatility != null && rvValue.Value != null) {
            d = `${NumberFormatUtil.numberWithCommas(rvValue.Value)}%`;
        }

        const content = [[a, b, c, d]];

        return { name, headers, content };
    }
}
