import { EventEmitter } from '@angular/core';
import { SubscriberEntity } from '@concurrency/angular';
import { ContextualNumber } from '@concurrency/core';
import { Observable, of } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CommonInputConfig } from 'src/app/_navigator/common/inputs/common-user-input.component';
import { EditorSettings } from 'src/app/_navigator/data/model/editor-settings.model';
import { Estimate } from 'src/app/_navigator/data/model/estimate.model';
import { DataStore } from 'src/app/_navigator/data/store/data.store';

export abstract class EditorComponent extends SubscriberEntity {
    public estimate!: Estimate;
    public settings: EditorSettings = {} as any;
    public local!: ContextualNumber;
    // TODO: These should be communicated via the event manager
    public modelChange = new EventEmitter<ContextualNumber>();
    public submit = new EventEmitter<ContextualNumber>();

    constructor(
        protected dataStore: DataStore
    ) {
        super();
        // TODO: Obviate takeUntil by using Async pipes and local Observable streams
        this.dataStore.estimate.pipe(takeUntil(this.destroyed)).onceDefined((estimate) => {
            this.estimate = estimate;
            this.dataStore.editorSettings.onceDefined((settings) => {
                this.settings = settings;
                this.local = this.settings.data.clone();
                this.initialize();
            });
        });
    }

    public abstract initialize(): void;
    public abstract update(): Observable<void>;
    public abstract save(): void;
    public abstract cancel(): void;

    public isValid(isValid: boolean): void {
        this.dataStore.triggerEditorValidity(isValid);
    }
}

export abstract class SimpleEditorComponent extends EditorComponent {
    public abstract inputConfig: CommonInputConfig;
    public customLabel = 'Custom';
    public initialize(): void {
        this.mask();
    }
    public mask(): void {
        this.inputConfig.mask = this.inputConfig.name === 'Beta'
            ? `{} - ${this.getSource()}`
            : `{}% - ${this.getSource()}`; // `
    }
    public save(): void { }
    public cancel(): void { }
    public update(): Observable<void> {
        const result = new ContextualNumber(this.local.asNumber, this.getSource(), this.local.dataAsOf);
        this.modelChange.emit(result);
        this.mask();
        // tslint-disable-next-line import/no-deprecated
        return of(void 0);
    }
    protected getSource(): string {
        if (this.local.context == null) {
            return this.customLabel;
        } else {
            return this.local.context;
        }
    }
}
