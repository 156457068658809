export class ViewMoreOptions {
    key: number;
    name: string


    constructor(key: number, name: string) {
        this.key = key;
        this.name = name
    }


}