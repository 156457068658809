import { NgModule } from '@angular/core';
import { DialogModule } from 'primeng/dialog';
import { CommonModules } from '../_config/common-modules';
import { BenchmarkingService } from '../_navigator/data/service/benchmarking.service';
import { BenchmarkingComponent } from './benchmarking.component';
import { AddIndustriesComponent } from './components/add-industries/add-industries.component';
import { BenchmarkingAddIndustriesComponent } from './components/benchmarking-add-industries/benchmarking-add-industries.component';
import { BenchmarkingChildIndustriesComponent } from './components/benchmarking-child-industries/benchmarking-child-industries.component';
import { ChildIndustriesComponent } from './components/child-industries/child-industries.component';
import { IndustryInputsComponent } from './components/industry-inputs.component';
import { ViewIndustriesComponent } from './components/view-industries/view-industries.component';
import { BenchmarkingQueryBuilderService } from './data/benchmarking-query-builder.service';
import { IntlBenchmarkingComponent } from './intlbenchmarking.component';
import { CompanyListNoIndustryModalComponent } from './modals/company-list-no-industry-modal.component';
import { IndustryListNoDetailsSelectedComponent } from './modals/industry-list-no-details-selected.component';
import { IntlCompanyListComponent } from './modals/intl-company-list/intl-company-list.component';
import { SelectScenarioModalComponent } from './modals/select-scenario-modal.component';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { IndustryAssumptionsComponent } from './components/industry-assumptions/industry-assumptions.component';
import { NzTableModule } from 'ng-zorro-antd/table';

export const BenchmarkingDefinitions: NgModule = {
    declarations: [
        BenchmarkingComponent,
        IntlBenchmarkingComponent,
        IndustryInputsComponent,
        SelectScenarioModalComponent,
        CompanyListNoIndustryModalComponent,
        IndustryListNoDetailsSelectedComponent,
        ViewIndustriesComponent,
        IntlCompanyListComponent,
        AddIndustriesComponent,
        ChildIndustriesComponent,
        BenchmarkingAddIndustriesComponent,
        BenchmarkingChildIndustriesComponent,
        IndustryAssumptionsComponent
    ],
    entryComponents: [
        SelectScenarioModalComponent,
        CompanyListNoIndustryModalComponent,
        IndustryListNoDetailsSelectedComponent,
        ViewIndustriesComponent,
        IntlCompanyListComponent,
        AddIndustriesComponent,
        IndustryAssumptionsComponent,
        ChildIndustriesComponent,
        BenchmarkingAddIndustriesComponent,
        BenchmarkingChildIndustriesComponent
    ],
    imports: [
        ...CommonModules,
        DialogModule,
        NzGridModule,
        NzIconModule,
        NzTableModule,


    ],
    providers: [
        BenchmarkingService,
        BenchmarkingQueryBuilderService
    ]
};

@NgModule(BenchmarkingDefinitions)
export class BenchmarkingModule { }
