// eslint-disable  max-len
import { EstimateSummary } from 'src/app/_api/responses/estimate-summary.response';
import { Help } from './help.model';

export class DynamicHelpText {
    public static GeneralInformation_1(estimateSummary: EstimateSummary | undefined): Help {
        if (estimateSummary == null) {
            return {
                title: `General Information`,
                text: ``
            };
        }
        const helpText: Help = {
            title: `General Information`,
            text: `<p>The data and information in the Cost of Capital Navigator was used to estimate cost of capital for ${estimateSummary.EstimateName} reported herein. The Cost of Capital Navigator – International Cost of Capital Module can be used to develop cost of capital estimates in over 180 countries, from the perspective of investors in any one of 56 different countries (depending on data availability). For more information visit dpcostofcapital.com.</p>
                <p><h4>Estimate Name:</h4> ${estimateSummary.EstimateName}</p>
                <p>The individual business, business ownership interest, security, or intangible asset for which the cost of capital is being estimated.</p>
                <p>Valuation Date: ${estimateSummary.ValuationDate}</p>
                <p>The date on which the opinion of value applies.</p>
                <p>Analysis Date: ${estimateSummary.AnalysisDate}</p>
                <p>The Analysis Date is the date on which the analysis was performed. The Analysis Date is not necessarily the same as the Valuation Date.</p>
                <p>Investor Country: ${estimateSummary.InvestorCountryName}</p>
                <p>The Investor Country is the country in which the investor is based. For example, if a U.S. investor is evaluating an investment located in Germany, the “investor country” is the U.S., and the “investee country” is Germany.</p>
                <p>This term is closely related to the concept of “investor perspective”. In the previous example, the German investment is being evaluated from the “perspective” of a United States investor.</p>
                <p>Investee Country: ${estimateSummary.InvesteeCountryName}</p>
                <p>The “investee country” is the country in which the investment is located. For example, if a U.S. investor is evaluating an investment located in Germany, the “investee country” is Germany, and the “investor country” is the U.S.</p>
                <p>Cash Flows: Denominated in ${estimateSummary.CashFlowCurrency}</p>
                <p>The currency of the cash flow projections should always be consistent with the currency of the discount rate. In practice, this means that the inputs used to derive a discount rate (the denominator in a discounted cash flow model) should be in the same currency used to project cash flows (the numerator in a discounted cash flow model).</p>
                <p>• For detailed information about international cost of capital, see the Cost of Capital Navigator at dpcostofcapital.com > Resources > International Cost of Capital Module</p>
                `
        };
        helpText.excelText = this.stripHtmlForExcelExport(helpText.text);
        return helpText;
    }
    public static CostOfEquityInputsCountry_2(countryOfInputs: string | undefined | null): Help {
        const helpText: Help = {
            title: `Inputs Country: ${countryOfInputs}`,
            text: `<p>The Inputs Country is the country selected as the source of capital asset pricing model (CAPM) inputs (risk-free rate, beta, equity risk premium, size premium, etc.).</p>
                <p>Some countries have the information and data needed to develop risk-free rates, betas, equity risk premia, and other commonly used valuation inputs, and some countries do not have enough information and data to develop these inputs. Analysts can select from the Cost of Capital Navigator's "off the shelf" CAPM inputs (when available), or type in their own independently-developed CAPM inputs.</p>
                <p>All CAPM inputs provided by the Cost of Capital Navigator and used in this report will be labeled as such; all CAPM inputs developed independently by the analyst and used in this report are labeled “Custom” (i.e., supplied by the analyst).</p>
                `
        };
        helpText.excelText = this.stripHtmlForExcelExport(helpText.text);
        return helpText;
    }

    public static CrpRvFactorNotRequired_3a(countryOfInputs: string | null, estimateName: string | undefined): Help {
        const helpText: Help = {
            title: `CRP/RV Factor: Not Required`,
            text: `<p>In the Cost of Capital Navigator’s “International Cost of Capital Module”, CRPs and RV factors are incorporated into the capital asset pricing model (CAPM) framework, if they are needed to complete the analysis.</p>
                <p>CRPs and RV factors are only necessary when inputs from a country other than the Investee Country are used to develop cost of capital estimates. In the analysis in this report, inputs from ${countryOfInputs} are being used to develop cost of capital estimate(s) for ${estimateName}, which is also located in ${countryOfInputs}.</p>
                <p><b>Conclusion:</b> Because the Inputs Country is the same as the Investee Country, an additional CRP and/or RV factor adjustment are <b>not needed</b> to complete the analysis in this report.</p>`
        };
        helpText.excelText = this.stripHtmlForExcelExport(helpText.text);
        return helpText;
    }
    public static CrpRvFactorRequired_3b(countryOfInputs: string | null, estimateName: string | undefined, investeeCountry: string | undefined): Help {
        const helpText: Help = {
            title: `CRP/RV Factor: Required`,
            text: `<p>In the Cost of Capital Navigator’s “International Cost of Capital Module”, CRPs and RV factors are incorporated into the capital asset pricing model (CAPM) framework, if they are needed to complete the analysis.</p>
                <p>CRPs and RV factors are only necessary when inputs from a country other than the Investee Country are used to develop cost of capital estimates. In the analysis in this report, inputs from ${countryOfInputs} are being used to develop cost of capital estimate(s) for ${estimateName}, which is located in ${investeeCountry}.</p>
                <p><b>Conclusion:</b> Because the Inputs Country is not the same as the Investee Country, an additional CRP and/or RV factor adjustment is required to complete the analysis in this report.</p>
                <p>Additional Notes:</p>
                <p>A <b>country risk premium (CRP)</b> attempts to isolate the incremental risk premium associated with investing in the Investee Country (i.e., the country in which the investment is located) rather than investing in the Investor Country (i.e., the country in which the investor is based). The models used in the Cost of Capital Navigator’s “International Cost of Capital Module” to estimate country risk premia (CRPs) are the Country Yield Spread Model and the Erb-Harvey-Viskanta Country Credit Rating Model.</p>
                <p><b>A relative volatility (RV) factor</b> attempts to isolate the incremental risk premium associated with investing in the Investee Country as a function of the relative volatility of the Investee Country’s equity market compared to the Investor Country’s equity market. The model used to estimate relative volatility (RV) factors is the Relative Volatility Model.</p>
        `
        };
        helpText.excelText = this.stripHtmlForExcelExport(helpText.text);
        return helpText;
    }

    public static ModelsApplicableSingleCountryCapm_4a(countryOfInputs: string | null | undefined): Help {
        const helpText: Help = {
            title: `Model(s) Applicable: Single Country CAPM`,
            text: `Conclusion: As previously documented, because the Inputs Country (${countryOfInputs}) is the same as the Investee Country (${countryOfInputs}), an additional CRP and/or RV factor adjustment are not needed to complete the analysis in this report.</p>
                <p>Because this estimate does not require an additional CRP and/or RV factor adjustment, cost of equity capital estimate can be developed using the <b>Single Country CAPM</b>.</p>
                <p><i><small>•For detailed information about Single Country CAPM, see the Cost of Capital Navigator at dpcostofcapital.com > Resources > International Cost of Capital Module > “Chapter 2: Strengths and Weaknesses of Commonly Used Models”.</small></i></p>`
        };
        helpText.excelText = this.stripHtmlForExcelExport(helpText.text);
        return helpText;
    }
    public static ModelsAvailable_4b(modelsAvailable: Set<string>, models: string, investeeCountry: string | undefined, countryOfInput: string | undefined | null, valuationDate: string | undefined): Help {
        let modelsList = ``;
        let modelDescriptions = ``;
        if (modelsAvailable !== undefined) {
            modelsAvailable.forEach((element) => {
                modelsList += `<li>${element}</li>`;
            });
            modelDescriptions = this.setAvailableModelsDescriptions(modelsAvailable);
        }
        const helpText: Help = {
            title: `Model(s) Available: ${models}`,
            text: `<p>As previous noted, an additional CRP and/or RV factor adjustment is required to complete the analysis in this report.</p>
                The model(s) available which have (i) CRPs or RV factors calculated for the Investee Country (in this report, the Investee Country is ${investeeCountry}), (ii) from the perspective of an investor in the country whose inputs are being used to develop cost of capital estimates (in this report, the Inputs Country is ${countryOfInput}), (iii) as of the valuation date (${valuationDate}) is (or are) as follows:</p>
                <p><ul>${modelsList}</ul></p>
                <p>A description of the available model(s) is as follows:</p>
                ${modelDescriptions}
                `
        };
        helpText.excelText = this.stripHtmlForExcelExport(helpText.text);
        return helpText;
    }

    public static FisherEffectCostOfEquityNotRequired_5a(countryOfInputs: string | null | undefined, cashFlowCurrency: string | null, inputsCountryCurrency: string | undefined): Help {
        const helpText: Help = {
            title: `International Fisher Effect (Cost of Equity and/or Cost of Debt): Not Required`,
            text: `<p>The International Fisher Effect suggests that countries with high inflation rates should expect to see higher interest rates relative to countries with lower inflation rates. This relationship can be extended from interest rates into discount rates, thereby allowing us to translate a home currency cost of capital estimate into a foreign currency indication.</p>
                <p>An International Fisher Effect translation is necessary when the currency of the Inputs Country (in this report, the Inputs Country is ${countryOfInputs}) is different from the currency of the cash flows being discounted.</p>
                <p>The currency of the Inputs Country (${countryOfInputs}) in this report is ${inputsCountryCurrency}, and the cash flows being discounted are also denominated in ${cashFlowCurrency}.</p>
                <p>Because the currency of the Inputs Country is the same as the currency of the cash flows being discounted, an International Fisher Effect translation is <b>not required</b>.</p>
                <p><i><small>• For detailed information about the International Fisher Effect, see the Cost of Capital Navigator at dpcostofcapital.com > Resources > International Cost of Capital Module > “Chapter 1: International Cost of Capital – Overview”.</small></i></p>
                `
        };
        helpText.excelText = this.stripHtmlForExcelExport(helpText.text);
        return helpText;
    }
    public static FisherEffectCostOfEquityRequired_5b(countryOfInputs: string | null | undefined, cashFlowCurrency: string | null, inputsCountryCurrency: string | undefined): Help {
        const helpText: Help = {
            title: `International Fisher Effect (Cost of Equity and/or Cost of Debt): Required`,
            text: `<p>The International Fisher Effect suggests that countries with high inflation rates should expect to see higher interest rates relative to countries with lower inflation rates. This relationship can be extended from interest rates into discount rates, thereby allowing us to translate a home currency cost of capital estimate into a foreign currency indication.</p>
                <p>An International Fisher Effect translation is necessary when the currency of the Inputs Country (in this report, the Inputs Country is ${countryOfInputs}) is different from the currency of the cash flows being discounted.</p>
                <p>The currency of the Inputs Country (${countryOfInputs}) in this report is ${inputsCountryCurrency}, and the cash flows being discounted are denominated in ${cashFlowCurrency}.</p>
                <p>Because the currency of the Inputs Country is different from the currency of the cash flows being discounted, an International Fisher Effect translation is <b>required</b>.</p>
                <p><i><small>• For detailed information about the International Fisher Effect, see the Cost of Capital Navigator at dpcostofcapital.com > Resources > International Cost of Capital Module > “Chapter 1: International Cost of Capital – Overview”.</p></i></small>
                `
        };
        helpText.excelText = this.stripHtmlForExcelExport(helpText.text);
        return helpText;
    }

    public static CostOfCapitalDenominatedIn_6(cashFlowCurrency: string | null, estimateName: string | undefined): Help {
        const helpText: Help = {
            title: `Currency`,
            text: `<p>Based upon the selections made in Step 1 – General Inputs, the cost of capital estimates developed using the Cost of Capital Navigator for ${estimateName} are denominated in ${cashFlowCurrency} and then used independently by the analyst to discount cash flows that are also denominated in ${cashFlowCurrency}.</p>
                <p>This produces a Value Conclusion that is also denominated in ${cashFlowCurrency}. To see the value conclusion in another currency, the analyst can independently convert the Value Conclusion using the spot exchange rate.</p>`
        };
        helpText.excelText = this.stripHtmlForExcelExport(helpText.text);
        return helpText;
    }

    public static CostOfEquityInputsCountry_7(coeInputsCountry: string | null): Help {
        const helpText: Help = {
            title: `Cost of Equity Inputs Country: ${coeInputsCountry}`,
            text: `<p>The Cost of Equity Cost of Equity Inputs Country is the country selected as the source of capital asset pricing model (CAPM) inputs (risk-free rate, beta, equity risk premium, size premium, etc.).</p>
            <p>In “Step 1: General Information”, ${coeInputsCountry} was selected as the “Cost of Equity Inputs Country” in response to the question “Which country's inputs would you like to use to develop your cost of equity estimate?”</p>
            <p>In this step (''Step 2: Cost of Equity Inputs'') you can:</p>
            <p>• Select from the pre-calculated the inputs that the Cost of Capital Navigator has for ${coeInputsCountry} (if available),</p>
            <p>OR</p>
            <p>• Enter your own custom inputs for the ${coeInputsCountry} if you have developed these inputs independently.</p>
            <p><b>NOTE:</b> Many countries do not have enough data (or a long enough history of data) to develop risk-free rates, betas, equity risk premia, and other commonly used valuation inputs. THIS DOES NOT MEAN THAT YOU CANNOT DEVELOP COST OF CAPITAL ESTIMATES FOR THEM. In cases where inputs are not available, a “Proxy Country” that DOES have inputs (the United States or Germany) can be used to develop cost of capital estimates, and the results can be translated into the desired currency using the International Fisher Effect.</p>
            `
        };
        helpText.excelText = this.stripHtmlForExcelExport(helpText.text);
        return helpText;
    }

    public static CountryRiskPremiumNotRequired_8a(countryOfInputs: string | undefined, estimateName: string | undefined): Help {
        const helpText: Help = {
            title: `Country Risk Premium: Not Required, proceed to the next step.`,
            text: `<p>In the Cost of Capital Navigator’s “International Cost of Capital Module”, CRPs and RV factors are incorporated into the capital asset pricing model (CAPM) framework, if they are needed to complete the analysis.</p>
            <p>CRPs and RV factors are only necessary when inputs from a country other than the Investee Country are used to develop cost of capital estimates. In the analysis in this report, inputs from ${countryOfInputs} are being used to develop cost of capital estimate(s) for ${estimateName}, which is also located in ${countryOfInputs}.</p>
            <p>Because the Inputs Country is the same as the Investee Country, an additional CRP and/or RV factor adjustment are not needed to complete the analysis in this report.</p>
            <p>The “Country Risk Premium (CRP)/Relative Volatility (RV) Factor Summary” table is intentionally blank because CRP and/or RV factors are not required to complete the analysis in this report. In the analysis in this report, inputs from ${countryOfInputs} are being used to develop cost of capital estimate(s) for ${estimateName}, which is also located in ${countryOfInputs}. As previous noted, a CRP and/or RV factor are not needed to perform this analysis because the Inputs Country is the same as the Investee Country. </p>
            `
        };
        helpText.excelText = this.stripHtmlForExcelExport(helpText.text);
        return helpText;
    }
    public static CountryRiskPremiumRequired_8b(
        countryOfInputs: string | undefined,
        investeeCountry: string | undefined,
        valuationDate: string | undefined,
        euroMoneyRegion: string | undefined,
        msciClassification: string | undefined,
        creditRating: string | undefined,
        modelsAvailable: Set<string> | undefined,
        estimateName: string | undefined
    ): Help {
        let models = ``;
        if (modelsAvailable !== undefined) {
            modelsAvailable.forEach((element) => {
                models += `<li>${element}</li>`;
            });
        }
        const helpText: Help = {
            title: `Country Risk Premium: Required, see CRP/RV Summary.`,
            text: `<p>As previous noted, an additional CRP and/or RV factor adjustment is required to complete the analysis in this report.</p>
                <p>The model(s) available which have (i) CRPs or RV factors calculated for the Investee Country (in this report, the Investee Country is ${investeeCountry}), (ii) from the perspective of an investor in the country whose inputs are being used to develop cost of capital estimates (in this report, the Inputs Country is ${countryOfInputs}), (iii) as of the valuation date (${valuationDate}) is (or are) as follows:</p>
                <p><ul>${models}</ul></p>
                <p>The Country Risk Premia (CRP) and Relative Volatility (RV) Factor table provides the CRPs and/or RV factors for the following for each model available for this estimate:</p>
                <p><ol type="1">
                    <li><b>Subject Company:</b> From the perspective of an investor in ${countryOfInputs} (the “Cost of Equity Inputs Country”) evaluating an investment located in ${investeeCountry} (the “Investee Country”). These are the CRP and or RV factors that will be used as inputs in the development of cost of capital estimates for ${estimateName}.</li>
                    </ol></p>
                <p>If available, additional contextual information is supplied for comparison and support purposes:</p>
                <p><ol type="1" start="2">
                    <li><b>Geographic Region:</b> From the perspective of an investor in ${countryOfInputs} (the “Cost of Equity Inputs Country”) evaluating an investment similar to ${estimateName} in ${euroMoneyRegion} as a whole. Regional classification is as of ${valuationDate}, as defined by Euromoney. To learn more, visit: http://www.euromoneyplc.com/.</li>
                    <li><b>MSCI Classification:</b> From the perspective of an investor in ${countryOfInputs} (the “Cost of Equity Inputs Country”) evaluating an investment comparable to ${estimateName} in the ${msciClassification} MSCI Classification as a whole. MSCI level of economic development classification is as of ${valuationDate}, as defined by the MSCI Market Classification Framework. To learn more, visit: http://www.msci.com/products/indexes/market_classification.html.</li>
                    <li><b>Credit Rating:</b> From the perspective of an investor in ${countryOfInputs} (the “Investor Country”) evaluating an investment comparable to ${estimateName} in countries with an S&P credit rating of ${creditRating}, as a whole. Credit rating is as of ${valuationDate}, as defined by S&P. To learn more, visit: http://www.standardandpoors.com/.</li>
                    </ol></p>
                <p>The CRPs and/or RV factors listed in the Country Risk Premia (CRP) and Relative Volatility (RV) Factor table are NOT cost of capital estimates.</p>
                <p>The CRPs and/or RV factors listed in the Country Risk Premia (CRP) and Relative Volatility (RV) Factor table are just one of the inputs required to develop cost of capital estimates for ${estimateName} in this report.</p>
                <p>The schedule herein provides country risk premia and/or relative volatility factors from the perspective of ${countryOfInputs} for:</p>
                <p><ol type="1" start="6">
                    <li>${investeeCountry} (the investee country)</li>
                    <li>${euroMoneyRegion} – The investee country’s region as defined in the CCR Model</li>
                    <li>MSCI Classification: ${msciClassification} – the investee country’s MSCI Market Classification based on MSCI Market Classification Framework as of ${valuationDate}. See http://www.msci.com/products/indexes/market_classification.html.</li>
                    <li>S&P Credit Rating: ${creditRating} – the investee country’s S&P sovereign credit rating as of ${valuationDate}</li>
                    </ol></p>
                `
        };
        helpText.excelText = this.stripHtmlForExcelExport(helpText.text);
        return helpText;
    }

    public static InternationalFisherEffectNotRequired_9a(countryOfInputs: string | undefined, inputsCountryCurrency: string | undefined, cashFlowCurrency: string | undefined): Help {
        const helpText: Help = {
            title: `International Fisher Effect (Cost of Equity and/or Cost of Debt): Not Required`,
            text: `<p>The International Fisher Effect suggests that countries with high inflation rates should expect to see higher interest rates relative to countries with lower inflation rates. This relationship can be extended from interest rates into discount rates, thereby allowing us to translate a home currency cost of capital estimate into a foreign currency indication.</p>
                <p>The currency of the Cost of Equity Inputs Country (${countryOfInputs}) in this report is ${inputsCountryCurrency}, and and based upon the selections made in “Step 1 – General Inputs”, the cash flows being discounted are <i>also</i> denominated in ${cashFlowCurrency}.</p>
                <p>Because the currency of the Cost of Equity Inputs Country is the same as the currency of the cash flows being discounted, an International Fisher Effect translation is <b>not required</b> to complete the analysis in this report.</p>
                <p><small><i>• For detailed information about the International Fisher Effect, see the Cost of Capital Navigator at dpcostofcapital.com > Resources > International Cost of Capital Module > “Chapter 1: International Cost of Capital – Overview”.</i></small></p>
                `
        };
        helpText.excelText = this.stripHtmlForExcelExport(helpText.text);
        return helpText;
    }

    public static InternationalFisherEffectRequired_9b(
        countryOfInputs: string | undefined,
        inputsCountryCurrency: string | undefined,
        cashFlowCurrency: string | undefined,
        investorCountry: string | undefined,
        investeeCountry: string | undefined): Help {
        const helpText: Help = {
            title: `International Fisher Effect (Cost of Equity and/or Cost of Debt): Required`,
            text: `<p>The International Fisher Effect suggests that countries with high inflation rates should expect to see higher interest rates relative to countries with lower inflation rates. This relationship can be extended from interest rates into discount rates, thereby allowing us to translate a home currency cost of capital estimate into a foreign currency indication.</p>
                <p>The currency of the Cost of Equity Inputs Country (${countryOfInputs}) in this report is ${inputsCountryCurrency}, and based upon the selections made in “Step 1 – General Inputs”, the cash flows being discounted are denominated in ${cashFlowCurrency}.</p>
                <p>Because the currency of the Cost of Equity Inputs Country is different from the currency of the cash flows being discounted, an International Fisher Effect translation is required to complete the analysis in this report, and so expected inflation estimates inputs for ${investorCountry} and ${investeeCountry} are required.</p>
                <p><small><i>• For detailed information about the International Fisher Effect, see the Cost of Capital Navigator at dpcostofcapital.com > Resources > International Cost of Capital Module > “Chapter 1: International Cost of Capital – Overview”.</i></small></p>
                `
        };
        helpText.excelText = this.stripHtmlForExcelExport(helpText.text);
        return helpText;
    }

    public static coeEstimateSingleCountryCapm_10a(estimateName: string | undefined): Help {
        const helpText: Help = {
            title: `Cost of Equity Estimate: Single Country CAPM`,
            text: `<p>As previously documented, this estimate does not require an additional CRP and/or RV factor adjustment. Cost of equity capital estimate can be developed using the <b>Single Country CAPM</b</p>
            <p>Cost of equity capital estimates for ${estimateName} using Single Country CAPM are shown here.</p>
            `
        };
        helpText.excelText = this.stripHtmlForExcelExport(helpText.text);
        return helpText;
    }

    public static capmWithCrpAndRvFactorAdjustments_10b(estimateName: string | undefined, modelsAvailable: Set<string>): Help {
        let models = ``;
        if (modelsAvailable !== undefined) {
            modelsAvailable.forEach((element) => {
                models += `<li>${element}</li>`;
            });
        }
        const helpText: Help = {
            title: `CAPM with CRP and/or RV Factor Adjustments`,
            text: `<p>As previously documented, the model(s) available to complete the analysis in this report is (or are) as follows:</p>
                <p><ul>${models}</ul></p>
                <p>Calculations of cost of equity capital estimates for ${estimateName} using all available models are shown in Step 6 – Cost of Equity Summary.</p>
            `
        };
        helpText.excelText = this.stripHtmlForExcelExport(helpText.text);
        return helpText;
    }

    public static finalCoeConclusionNoFisherEffect_11a(): Help {
        const helpText: Help = {
            title: `Final Cost of Equity Conclusion ‒ International Fisher Effect not Required `,
            text: `<p>Because an International Fisher Effect translation is not required to complete the analysis in this report, (i) the “International Fisher Effect Translation” table is unnecessary (and therefore blank), and (ii) the cost of equity capital estimate(s) shown in the CAPM Results tab are the “final” estimates that are also reported in the Cost of Equity Conclusion tab of Step 6 – Cost of Equity Summary.</p>`
        };
        helpText.excelText = this.stripHtmlForExcelExport(helpText.text);
        return helpText;
    }

    public static finalCoeConclusionWithFisherEffect_11b(estimateName: string | undefined): Help {
        const helpText: Help = {
            title: `Pre-Final Cost of Equity Conclusion ‒ International Fisher Effect Required `,
            text: `<p>Because an International Fisher Effect translation is required to complete the analysis in this report, the CAPM result(s) are preliminary (i.e., before the required International Fisher Effect translation is applied), and are not the final conclusion.</p>
                <p>Final (i.e., after the required International Fisher Effect translation is applied) calculations of cost of equity capital estimates for ${estimateName} are shown in the Cost of Equity Conclusion tab of Step 6 – Cost of Equity Summary.</p>
            `
        };
        helpText.excelText = this.stripHtmlForExcelExport(helpText.text);
        return helpText;
    }

    public static internationalFisherEffect_12a(): Help {
        const helpText: Help = {
            title: `International Fisher Effect Not Required `,
            text: `<p>The International Fisher Effect suggests that countries with high inflation rates should expect to see higher interest rates relative to countries with lower inflation rates. This relationship can be extended from interest rates into discount rates, thereby allowing us to translate a home currency cost of capital estimate into a foreign currency indication.</p>
                <p>As previously documented, an International Fisher Effect translation is not required to complete the analysis in this report. For this reason the International Fisher Effect Cost of Equity translation is unnecessary, and is intentionally blank.</p>
            `
        };
        helpText.excelText = this.stripHtmlForExcelExport(helpText.text);
        return helpText;
    }

    public static coeCapitalFinalConclusion_12b(estimateName: string | undefined): Help {
        const helpText: Help = {
            title: `Cost of Equity Capital: Final Conclusion `,
            text: `<p>The International Fisher Effect suggests that countries with high inflation rates should expect to see higher interest rates relative to countries with lower inflation rates. This relationship can be extended from interest rates into discount rates, thereby allowing us to translate a home currency cost of capital estimate into a foreign currency indication.</p>
                <p>As previously documented, an International Fisher Effect translation is required to complete the analysis in this report.</p>
                <p>The International Fisher Effect Translation for the Cost of Equity estimate(s) is (or are) shown here. The final (i.e., after the required International Fisher Effect translation is applied) calculations of cost of equity capital estimates for ${estimateName} are shown here and are also displayed in the Cost of Equity Conclusion tab of Step 6 – Cost of Equity Summary.</p>
            `
        };
        helpText.excelText = this.stripHtmlForExcelExport(helpText.text);
        return helpText;
    }

    public static text_13a(): Help {
        const helpText: Help = {
            title: `Final Cost of Equity Results`,
            text: `<p>As previously documented in the section entitled “International Fisher Effect: Required”, an International Fisher Effect translation was not required to complete the analysis in this report. The final Cost of Equity results developed in the CAPM Results Tab of Step 6 – Cost of Equity Summary are also displayed here.</p>`
        };
        helpText.excelText = this.stripHtmlForExcelExport(helpText.text);
        return helpText;
    }

    public static text_13b(estimateName: string | undefined): Help {
        const helpText: Help = {
            title: `Final Cost of Equity Results (post-International Fisher Effect)`,
            text: `<p>As previously documented in the section entitled “International Fisher Effect: Required”, an International Fisher Effect translation was required to complete the analysis in this report.</p>
                <p>Final (i.e., after the required International Fisher Effect translation was applied) calculations of cost of equity capital estimates for ${estimateName} are displayed here.</p>
            `
        };
        helpText.excelText = this.stripHtmlForExcelExport(helpText.text);
        return helpText;
    }

    public static coeCapitalConclusions_14a(estimateName: string | undefined): Help {
        const helpText: Help = {
            title: `Cost of Equity Capital Conclusions`,
            text: `<p>As previously documented, this estimate does not require an additional CRP and/or RV factor adjustment. Cost of equity capital estimate can be developed using the <b>Single Country CAPM.</b></p>
            <p>The final cost of equity capital estimates for ${estimateName} using Single Country CAPM are shown here.</p>`
        };
        helpText.excelText = this.stripHtmlForExcelExport(helpText.text);
        return helpText;
    }

    public static coeCapitalConclusions_14b(
        estimateName: string | undefined,
        costOfEquityInputsCountry: string | undefined,
        investeeCountry: string | undefined,
        euroMoneyRegion: string | null,
        msciClassification: string | null,
        creditRating: string | null,
        valuationDate: string | undefined,
        modelsAvailable: Set<string>): Help {
        let models = ``;
        if (modelsAvailable !== undefined) {
            modelsAvailable.forEach((element) => {
                models += `<li>${element}</li>`;
            });
        }
        const helpText: Help = {
            title: `Cost of Equity Capital Conclusions`,
            text: `<p>As previously documented, the model(s) available to complete the analysis in this report is (or are) as follows:</p>
            <p><ul>${models}</ul></p>
            <p>The Cost of Equity Capital Conclusions table provides cost of equity capital conclusions for the following (for each model available for this estimate):</p>
            <p><ol type="1">
                    <li><b>Subject Company:</b> Cost of equity capital estimate(s) from the perspective of an investor in ${costOfEquityInputsCountry} (the “Cost of Equity Inputs Country”) evaluating an investment located in ${investeeCountry} (the “Investee Country”). These are/this is the final cost of equity estimate(s) for ${estimateName}.</li>
                </ol></p>
            <p>If available, additional contextual information is supplied for comparison and support purposes:</p>
            <p><ol type="1" start="2">
                    <li><b>Geographic Region:</b> Cost of equity estimates from the perspective of an investor in ${costOfEquityInputsCountry} (the “Cost of Equity Inputs Country”) evaluating an investment comparable to ${estimateName} located in ${euroMoneyRegion} as a whole. Regional classification is as of ${valuationDate}, as defined by Euromoney. To learn more, visit: http://www.euromoneyplc.com/.</li>
                    <li><b>MSCI Classification:</b> Cost of equity capital estimates from the perspective of an investor in ${costOfEquityInputsCountry} (the “Cost of Equity Inputs Country”) evaluating an investment comparable to ${estimateName} located in the ${msciClassification} MSCI Classification countries as a whole. MSCI level of economic development classification (“developed”, “emerging” or “frontier”) is as of ${valuationDate}, as defined by the MSCI Market Classification Framework. To learn more, visit: http://www.msci.com/products/indexes/market_classification.html.</li>
                    <li><b>Credit Rating:</b> Cost of equity capital estimates from the perspective of an investor in ${costOfEquityInputsCountry} (the “Cost of Equity Inputs Country”) evaluating an investment comparable to ${estimateName} in countries with an S&P credit rating of ${creditRating}, as a whole. Credit rating is as of ${valuationDate}, as defined by S&P. To learn more, visit: http://www.standardandpoors.com/.</li>
                    </ol></p>
                    `
        };
        helpText.excelText = this.stripHtmlForExcelExport(helpText.text);
        return helpText;
    }

    public static intlFisherEffectCoDNotRequired_15a(
        estimateName: string | undefined,
        inputsCountryForCoEEstimates: string | undefined,
        investeeCountry: string | undefined,
        inputCountryCurrencyForCoDEstimate: string | undefined,
        cashFlowCurrency: string | undefined,
        investeeCountryCurrency: string | undefined
    ): Help {
        const helpText: Help = {
            title: `International Fisher Effect (Cost of Debt): Not Required`,
            text: `<p>In “Step 1: General Information”, ${inputsCountryForCoEEstimates} was selected as the “Inputs Country for Cost of Equity Estimates” in response to the question “Which country's inputs would you like to use to develop your cost of equity capital estimate?”.</p>
                <p>In “Step 3: Cost of Debt and WACC Inputs”, the currency in which the cost of debt is denominated must be selected. The choices are (i) the currency of the Investee Country (${investeeCountry}), or (ii) the currency of the Inputs Country selected to develop cost of equity capital estimates, ${inputsCountryForCoEEstimates} (if the cost of equity capital Inputs Country is different than the Investee Country).</p>
                <p>The reason for this is as follows: Estimating cost of debt is typically more straightforward than estimating the cost of equity capital. This is because the cost of debt capital (risk) of fixed-income securities (bonds) are usually directly observable in the market, while the cost of equity capital is not. In other words, the cost of debt for ${estimateName} denominated in the currency of ${investeeCountry} ${investeeCountryCurrency} may be directly known to the analyst. It is generally preferable to use direct inputs, if available.</p>
                <p>In “Step 3: Cost of Debt and WACC Inputs”, ${inputCountryCurrencyForCoDEstimate} was selected as the currency in response to the question “In which currency is your cost of debt capital denominated?”</p>
                <p>Because the currency of the Cost of Debt Inputs Country is the same as the currency of the cash flows being discounted (${cashFlowCurrency}), an International Fisher Effect translation is not required</p>
            `
        };
        helpText.excelText = this.stripHtmlForExcelExport(helpText.text);
        return helpText;
    }

    public static intlFisherEffectCoDRequired_15b(
        estimateName: string | undefined,
        inputsCountryForCoEEstimates: string | undefined,
        investeeCountry: string | undefined,
        inputCountryCurrencyForCoDEstimate: string | undefined,
        cashFlowCurrency: string | undefined,
        investeeCountryCurrency: string | undefined
    ): Help {
        const helpText: Help = {
            title: `International Fisher Effect (Cost of Debt): Required`,
            text: `<p>In “Step 1: General Information”, ${inputsCountryForCoEEstimates} was selected as the “Inputs Country for Cost of Equity Estimates” in response to the question “Which country's inputs would you like to use to develop your cost of equity capital estimate?”.</p>
                <p>In “Step 3: Cost of Debt and WACC Inputs”, the currency in which the cost of debt is denominated must be selected. The choices are (i) the currency of the Investee Country (${investeeCountry}), or (ii) the currency of the Inputs Country selected to develop cost of equity capital estimates, ${inputsCountryForCoEEstimates} (if the cost of equity capital Inputs Country is different than the Investee Country).</p>
                <p>The reason for this is as follows: Estimating cost of debt is typically more straightforward than estimating the cost of equity capital. This is because the cost of debt capital (risk) of fixed-income securities (bonds) are usually directly observable in the market, while the cost of equity capital is not. In other words, the cost of debt for ${estimateName} denominated in the currency of ${investeeCountry} ${investeeCountryCurrency} may be directly known to the analyst. It is generally preferable to use direct inputs, if available.</p>
                <p>In “Step 3: Cost of Debt and WACC Inputs”, ${inputCountryCurrencyForCoDEstimate} was selected as the currency in response to the question “In which currency is your cost of debt capital denominated?”</p>
                <p>Because the currency of the Cost of Debt Inputs Country is different from the currency of the cash flows being discounted (${cashFlowCurrency}) an International Fisher Effect translation is required.</p>
            `
        };
        helpText.excelText = this.stripHtmlForExcelExport(helpText.text);
        return helpText;
    }

    public static crpRvFactorNotRequired_16a(estimateName: string | undefined, costOfDebtInputsCountry: string | undefined, investeeCountry: string | undefined): Help {
        const helpText: Help = {
            title: `CRP/RV Factor: Not Required `,
            text: `<p>A CRP and/or RV factor adjustment is applied to cost of debt only when the cost of debt value is derived from a country other than the Investee Country.</p>
                <p>In the analysis in this report, a cost of debt estimate from ${costOfDebtInputsCountry} is being used for ${estimateName}, which is located in ${investeeCountry}. Because the cost of debt value is derived from the Investee Country (${investeeCountry}), an additional CRP and/or RV factor adjustment is not required to complete the cost of debt analysis in this report.</p>
                `
        };
        helpText.excelText = this.stripHtmlForExcelExport(helpText.text);
        return helpText;
    }

    public static crpRvFactorRequired_16b(estimateName: string | undefined, costOfDebtInputsCountry: string | undefined, investeeCountry: string | undefined): Help {
        const helpText: Help = {
            title: `CRP/RV Factor: Required`,
            text: `<p>A CRP and/or RV factor adjustment is applied to cost of debt only when the cost of debt value is derived from a country other than the Investee Country.</p>
                <p>In the analysis in this report, a cost of debt estimate from ${costOfDebtInputsCountry} is being used for ${estimateName}, which is located in ${investeeCountry}. Because the cost of debt value is derived from a country other than the Investee Country (${investeeCountry}) an additional CRP and/or RV factor adjustment is required to complete the cost of debt analysis in this report.</p>
                <p><b>Additional Notes:</b></p>
                <p>A <b>country risk premium (CRP)</b> attempts to isolate the incremental risk premium associated with investing in the Investee Country (i.e., the country in which the investment is located) rather than investing in the Investor Country (i.e., the country in which the investor is based). The models used in the Cost of Capital Navigator’s “International Cost of Capital Module” to estimate country risk premia (CRPs) are the Country Yield Spread Model and the Erb-Harvey-Viskanta Country Credit Rating Model.</p>
                <p>A <b>relative volatility (RV)</b> factor attempts to isolate the incremental risk premium associated with investing in the Investee Country as a function of the relative volatility of the Investee Country’s equity market compared to the Investor Country’s equity market. The model used to estimate relative volatility (RV) factors is the Relative Volatility Model.</p>
                `
        };
        helpText.excelText = this.stripHtmlForExcelExport(helpText.text);
        return helpText;
    }

    public static codEstimateCrpRvFactorNotRequired_17a(costOfDebtInputsCountry: string | undefined, investeeCountry: string | undefined): Help {
        const helpText: Help = {
            title: `Cost of Debt Estimate: CRP/RV Factor Not Required`,
            text: `<p>In “Step 3: Cost of Debt and WACC Inputs”, ${costOfDebtInputsCountry} was selected as the Cost of Debt Input Country in response to the question “Which country's inputs would you like to use to develop your cost of debt?”</p>
                <p>Because the cost of debt value is derived from the Investee Country (${investeeCountry}), an additional CRP and/or RV factor adjustment is not required to complete the cost of debt analysis in this report.</p>
            `
        };
        helpText.excelText = this.stripHtmlForExcelExport(helpText.text);
        return helpText;
    }

    public static codEstimateCrpRvFactorRequired_17b(
        estimateName: string | undefined,
        costOfDebtInputsCountry: string | undefined,
        investeeCountry: string | undefined,
        modelsAvailable: Set<string>
    ): Help {
        let models = ``;
        if (modelsAvailable !== undefined) {
            modelsAvailable.forEach((element) => {
                models += `<li>${element}</li>`;
            });
        }
        const helpText: Help = {
            title: `Cost of Debt Estimate: CRP/RV Factor Required`,
            text: `<p>In “Step 3: Cost of Debt and WACC Inputs”, ${costOfDebtInputsCountry} was selected as the Cost of Debt Input Country in response to the question “Which country's inputs would you like to use to develop your cost of debt?”</p>
                <p>Because the cost of debt value is derived from a country other than the Investee Country (${investeeCountry}), an additional CRP and/or RV factor adjustment is required to complete the cost of debt analysis in this report.</p>
                <p>As previously documented, the model(s) available to complete the analysis in this report is (or are) as follows:</p>
                <p><ul>${models}</ul></p>
                <p>By default, all available models are used to adjust cost of debt capital for country risk.  However, in cases in which the Country Yield Spread Model is one of the available models, the analyst can opt into using only the Country Yield Spread Model to adjust cost of debt capital for country risk</p>
                <p>Calculations of cost of debt capital estimates for ${estimateName} using all applicable models are shown in the “Cost of Debt Capital” table.</p>
            `
        };
        helpText.excelText = this.stripHtmlForExcelExport(helpText.text);
        return helpText;
    }

    public static codCapitalPreFinalConclusionFisherEffectNotRequired_18a(): Help {
        const helpText: Help = {
            title: `Cost of Debt Capital: Final Conclusion ‒ International Fisher Effect not Required`,
            text: `<p>Because an International Fisher Effect translation is not required to complete the analysis in this report, (i) the “International Fisher Effect” table is unnecessary and is intentionally blank, and (ii) the cost of debt capital estimate(s) shown in the Cost of Debt Capital” table are the final estimates, and are reported in the “Cost of Debt Conclusion” table.</p>`
        };
        helpText.excelText = this.stripHtmlForExcelExport(helpText.text);
        return helpText;
    }

    public static codCapitalPreFinalConclusionFisherEffectRequired_18b(estimateName: string | undefined): Help {
        const helpText: Help = {
            title: `Cost of Debt Capital: Pre-Final Conclusion ‒ International Fisher Effect Required`,
            text: `<p>Because an International Fisher Effect translation is required to complete the analysis in this report, the Cost of Debt Capital table result(s) are preliminary (i.e., before the required International Fisher Effect translation is applied). </p>
                <p>Final cost of debt capital estimates (i.e., after the required International Fisher Effect translation is applied) for ${estimateName} are shown in the “Cost of Debt Capital Conclusion” table.</p>
            `
        };
        helpText.excelText = this.stripHtmlForExcelExport(helpText.text);
        return helpText;
    }

    public static FisherEffectNotRequired_19a(): Help {
        const helpText: Help = {
            title: `International Fisher Effect: Not Required`,
            text: `<p>As previously documented, an International Fisher Effect translation is not required to complete the cost of debt analysis in this report. For this reason an International Fisher Effect translation is unnecessary, and the “International Fisher Effect” tab is intentionally blank.</p>`
        };
        helpText.excelText = this.stripHtmlForExcelExport(helpText.text);
        return helpText;
    }

    public static FisherEffectRequired_19b(estimateName: string | undefined): Help {
        const helpText: Help = {
            title: `International Fisher Effect: Required`,
            text: `<p>As previously documented, an International Fisher Effect translation is required to complete the cost of debt analysis in this report.</p>
            <p>Cost of Debt Capital estimates for ${estimateName} are shown here after applying an International Fisher Effect translation. The results shown here are the results reported in the “Cost of Debt Conclusion” table.</p>
            `
        };
        helpText.excelText = this.stripHtmlForExcelExport(helpText.text);
        return helpText;
    }

    public static text_20a(): Help {
        const helpText: Help = {
            title: `Final Cost of Debt Results`,
            text: `<p>As previously documented, an International Fisher Effect translation was not required to complete the cost of debt analysis in this report, and so the “International Fisher Effect” tab is intentionally blank. The results from the Cost of Debt Capital tab are the results reported here.</p>`
        };
        helpText.excelText = this.stripHtmlForExcelExport(helpText.text);
        return helpText;
    }

    public static text_20b(): Help {
        const helpText: Help = {
            title: `Final Cost of Debt Results (post-International Fisher Effect)`,
            text: `<p>As previously documented in the section entitled “International Fisher Effect: Required”, an International Fisher Effect translation was required to complete the cost of debt analysis in this report, and so the results from the “International Fisher Effect” tab are the results reported here.</p>`
        };
        helpText.excelText = this.stripHtmlForExcelExport(helpText.text);
        return helpText;
    }

    public static codCapitalConclusions_21a(estimateName: string | undefined): Help {
        const helpText: Help = {
            title: `Cost of Debt Capital Conclusions`,
            text: `<p>As previously documented, this estimate does not require an additional CRP and/or RV factor adjustment. The final cost of debt capital estimate for ${estimateName} is shown here.</p>`
        };
        helpText.excelText = this.stripHtmlForExcelExport(helpText.text);
        return helpText;
    }

    public static codCapitalConclusions_21b(
        estimateName: string | undefined,
        costOfDebtInputsCountry: string | undefined,
        investeeCountry: string | undefined,
        euroMoneyRegion: string | null,
        msciClassification: string | null,
        creditRating: string | null,
        valuationDate: string | undefined,
        modelsAvailable: Set<string>): Help {
        let models = ``;
        if (modelsAvailable !== undefined) {
            modelsAvailable.forEach((element) => {
                models += `<li>${element}</li>`;
            });
        }
        const helpText: Help = {
            title: `Cost of Debt Capital Conclusion `,
            text: `<p>As previously documented, the model(s) available to complete the analysis in this report is (or are) as follows:</p>
                <p><ul>${models}</ul></p>
                <p>By default, all available models are used to adjust cost of debt capital for country risk.  However, in cases in which the Country Yield Spread Model is one of the available models, the analyst can opt to use only the Country Yield Spread Model to adjust cost of debt capital for country risk.</p>
                <p>The Cost of Debt Capital Conclusion table provides cost of equity capital conclusions for ${estimateName} using all applicable models, as follows: </p>
                <p><ol type="1">
                    <li><b>Subject Company:</b> Cost of debt capital estimate(s) from the perspective of an investor in ${costOfDebtInputsCountry} (the “Cost of Debt Inputs Country”) evaluating an investment located in ${investeeCountry} (the “Investee Country”). These are/this is the final cost of debt estimate(s) for ${estimateName}.</li>
                    </ol></p>
                <p>If available, additional contextual information is supplied for comparison and support purposes:</p>
                <p><ol type="1" start="2">
                    <li><b>Geographic Region:</b> Cost of debt capital estimates from the perspective of an investor in ${costOfDebtInputsCountry} (the “Cost of Debt Inputs Country”) evaluating an investment comparable to ${estimateName} located in ${euroMoneyRegion} as a whole. Regional classification is as of ${valuationDate}, as defined by Euromoney. To learn more, visit: http://www.euromoneyplc.com/.</li>
                    <li><b>MSCI Classification:</b> Cost of debt capital estimates from the perspective of an investor in ${costOfDebtInputsCountry} (the “Cost of Debt Inputs Country”) evaluating an investment comparable to ${estimateName} located in the ${msciClassification} MSCI Classification countries as a whole. MSCI level of economic development classification (“developed”, “emerging” or “frontier”) is as of ${valuationDate}, as defined by the MSCI Market Classification Framework. To learn more, visit: http://www.msci.com/products/indexes/market_classification.html.</li>
                    <li><b>Credit Rating:</b> Cost of debt capital estimates from the perspective of an investor in ${costOfDebtInputsCountry} (the “Cost of Debt Inputs Country”) evaluating an investment comparable to ${estimateName} in countries with an S&P credit rating of ${creditRating}, as a whole. Credit rating is as of ${valuationDate}, as defined by S&P. To learn more, visit: http://www.standardandpoors.com/.</li>
                    </ol></p>
                    `
        };
        helpText.excelText = this.stripHtmlForExcelExport(helpText.text);
        return helpText;
    }

    public static waccCalculations_22a(estimateName: string | undefined): Help {
        const helpText: Help = {
            title: `WACC Calculation(s)`,
            text: `<p>In the weighted average cost of capital (WACC) Calculations Table, WACC is calculated for ${estimateName} using the final cost of equity and final cost of debt derived using all applicable models, and the indicated capital structure and tax rate.</p>`
        };
        helpText.excelText = this.stripHtmlForExcelExport(helpText.text);
        return helpText;
    }

    public static waccConclusions_23a(
        estimateName: string | undefined,
        cashFlowCurrency: string | undefined,
        investeeCountry: string | undefined,
        countryOfInputs: string | undefined,
        euroMoneyRegion: string | null,
        msciClassification: string | null,
        creditRating: string | null,
        valuationDate: string | undefined,
        modelsAvailable: Set<string>): Help {
        let models = ``;
        if (modelsAvailable !== undefined) {
            modelsAvailable.forEach((element) => {
                models += `<li>${element}</li>`;
            });
        }
        const helpText: Help = {
            title: `WACC Conclusion(s)`,
            text: `<p>The results shown in the WACC Conclusions Table are denominated in ${cashFlowCurrency}, and should be used to discount expected cash flows that are also in ${cashFlowCurrency}.</p>
                <p>As previously documented, the model(s) available to complete the WACC analysis in this report is (or are) as follows:</p>
                <p><ul>${models}</ul></p>
                <p>In this report, ${countryOfInputs} was used to develop both cost of equity capital and cost of debt capital, and is referred to as the “Inputs Country” below. The WACC Conclusions table provides WACC conclusions for the following (for each model available for this estimate).</p>
                <p><ol type="1">
                        <li><b>Subject Company:</b> WACC estimate(s) from the perspective of an investor in ${countryOfInputs} (the “Inputs Country”) evaluating an investment located in ${investeeCountry} (the “Investee Country”). These are the final WACC estimate(s) for ${estimateName}</li>
                    </ol></p>
                <p>If available, additional contextual information is supplied for comparison and support purposes:</p>
                <p><ol type="1" start="2">
                        <li><b>Geographic Region:</b> WACC estimates from the perspective of an investor in ${countryOfInputs} (the “Inputs Country”) evaluating an investment comparable to ${estimateName} located in ${euroMoneyRegion} as a whole. Regional classification is as of ${valuationDate}, as defined by Euromoney. To learn more, visit: http://www.euromoneyplc.com/.</li>
                        <li><b>MSCI Classification:</b> WACC estimates from the perspective of an investor in ${countryOfInputs} (the “Inputs Country”) evaluating an investment comparable to ${estimateName} located in the ${msciClassification} MSCI Classification countries as a whole. MSCI level of economic development classification (“developed”, “emerging” or “frontier”) is as of ${valuationDate}, as defined by the MSCI Market Classification Framework. To learn more, visit: http://www.msci.com/products/indexes/market_classification.html.</li>
                        <li><b>Credit Rating:</b> WACC estimates from the perspective of an investor in ${countryOfInputs} (the “Inputs Country”) evaluating an investment comparable to ${estimateName} in countries with an S&P credit rating of ${creditRating}, as a whole. Credit rating is as of ${valuationDate}, as defined by S&P. To learn more, visit: http://www.standardandpoors.com/.</li>
                    </ol></p>
                `
        };
        helpText.excelText = this.stripHtmlForExcelExport(helpText.text);
        return helpText;
    }

    public static waccConclusions_23b(cashFlowCurrency: string | undefined): Help {
        const helpText: Help = {
            title: `WACC Conclusion(s)`,
            text: `<p>The results shown in the WACC Conclusions Table are denominated in ${cashFlowCurrency}, and should be used to discount expected cash flows that are also in ${cashFlowCurrency}.</p>`
        };
        helpText.excelText = this.stripHtmlForExcelExport(helpText.text);
        return helpText;
    }

    private static setAvailableModelsDescriptions(modelsAvailable: Set<string>): string {
        let text = ``;
        if (modelsAvailable.has(`Country Credit Rating Model`)) {
            text += `<p><b>Country Credit Rating Model</b><p/>
                <p>The CRPs developed using the Country Credit Rating (CCR) Model attempt to isolate the incremental risk premium associated with investing in the Investee Country (i.e., the country in which the investment is located) rather than investing in the Investor Country (i.e. the country in which the investor is based). This model is based on the assumption that countries with lower creditworthiness, which is translated into lower credit ratings, are associated with higher costs of equity capital, and vice versa. This model was originally developed in 1996 by academics Claude Erb, Campbell Harvey, and Tadas Viskanta.</p>
                <p>The CRPs developed using the CCR Model are from 56 Investor Country perspectives into approximately 180 Investee Countries (depending on data availability).</p>
                <p>The Country Credit Rating Model regresses all available CCRs (as of the month of calculation) for all countries in a given period t against all the available (equivalent) equity returns (for all countries that have returns) in the next period t + 1.</p.
                <p><i><small>• For detailed information about the Country Credit Rating Model, see the Cost of Capital Navigator at dpcostofcapital.com > Resources > International Cost of Capital Module > “Chapter 6: Erb-Harvey-Viskanta Country Credit Rating Model”.</small></i></p>
                `;
        }
        if (modelsAvailable.has(`Country Yield Spread Model`)) {
            text += `<p><b>Country Yield Spread Model</b></p>
                <p>The CRPs developed using the Country Yield Spread Model attempt to isolate the incremental risk premium associated with investing in the Investee Country (the country in which the investment is located) rather than investing in the Investor Country (the country in which the investor is based). The incremental risk premium is a function of the spread between the Investee Country’s sovereign yields and the Investor Country’s sovereign yields (both denominated in the Investor’s Country currency). This model was originally developed in U.S. Dollars (USD) in 1993 by researchers at investment bank Goldman Sachs.</p>
                <p>The CRPs developed using the Country Yield Spread Model are from two Investor Country perspectives (the U.S. and Germany), into approximately 180 Investee Countries (depending on data availability).</p>
                <p><b>Tier 1:</b> S&P sovereign credit rating = AAA, CRP assumed to be 0.0%. The United States is treated as a AAA-rated country.</p>
                <p><b>Tier 2:</b> Observed Yield Spread</p>
                <p><b>Tier 3:</b> S&P Regression Yield Spread</p>
                <p><b>Tier 4:</b> Euromoney (ECR) Regression Yield Spread.</p>
                <p><i><small>• For detailed information about the Country Yield Spread Model, see the Cost of Capital Navigator at dpcostofcapital.com > Resources > International Cost of Capital Module > “Chapter 4: Country Yield Spread Model”.</small></i></p>
                `;
        }
        if (modelsAvailable.has(`Relative Volatility Model`)) {
            text += `<p><b>Relative Volatility Model</b></p>
                <p>The relative volatility (RV) factors developed using the Relative Volatility Model attempt to isolate the incremental risk premium associated with investing in the Investee Country as a function of the relative volatility of the Investee Country’s equity market and the Investor Country’s equity market.</p>
                <p>The RV factors developed using the Relative Volatility Model are from two Investor Country perspectives (the U.S. and Germany), into approximately 70 Investee Countries (depending on data availability).</p>
                <p>The RV factors derived using the Relative Volatility Model are calculated in the following fashion:</p>
                <p>Step 1: In all cases in which a foreign country has an S&P credit rating of AAA, the country is assumed to have an RV factor of 1.0. The United States is treated as a AAA-rated country.</p>
                <p>Step 2: Trailing 60-months of equity returns (denominated in either USD or EUR, depending on the investor perspective) are used to calculate annualized monthly standard deviations for each of the approximately 70 MSCI equity indices (in turn).</p>
                <p>Step 3: The annualized monthly standard deviation results from Step 2 (based upon equity returns denominated in either USD or EUR, depending on the investor perspective) for each of the approximately 70 MSCI equity indices is divided (in turn) by the annualized monthly standard deviation from Step 2 for the U.S. or Germany (depending on the investor perspective). The resulting ratio is the RV factor.</p>
                <p><i><small>• For detailed information about the Relative Volatility Model, see the Cost of Capital Navigator at dpcostofcapital.com > Resources > International Cost of Capital Module > “Chapter 5: Relative Volatility Model”.</small></i></p>
                `;
        }
        return text;
    }

    private static stripHtmlForExcelExport(rawString: string): string {
        const regex = /(<([^>]+)>)/ig;

        return rawString.replace(regex, ``);
    }
}
