import { SummaryOptions } from '../types/summary-options';
import { headerCellHeight, headerFontSize, krollblue, krollshadow } from '../util/summary-helpers';

// TODO figure out this any type
export function summaryExcelWACC(options: SummaryOptions): any {
    const rows: any[] = [];

    return rows.concat([
            {
                height: headerCellHeight,
                cells: [
                    {
                        value: 'Cost Of Equity Summary',
                        color: krollblue,
                        fontSize: headerFontSize,
                        bold: 'true',
                        borderBottom: { size: 1, color: krollshadow },
                        colSpan: 11
                    }
                ]
            },
            {
                cells: [
                    { value: '' },
                    { value: 'Average*', bold: 'true' },
                    { value: 'Median*', bold: 'true' }
                ]
            }
        ]).concat(options.scenarioHelper.createCostOfEquityGroups(options.summary.equityGroups))
        .concat([
            {
                cells: [
                    { value: `*averages and medians calculated using all equations of that type
 (Levered, Unlevered, Relevered, High-Financial-Risk) completed by the user.`}
                ]
            },
            {
                cells: [
                    {
                        value: ` High-Financial-Risk Estimates are not included in
 average/median for Levered, Unlevered, or Relevered Estimates.`
                    }
                ]
            },
            {
                cells: [
                    { value: '' }
                ]
            }
        ])
        .concat({
                height: headerCellHeight,
                cells: [
                    {
                        value: 'Weighted Average Cost of Capital (WACC)',
                        color: krollblue,
                        fontSize: headerFontSize,
                        bold: 'true',
                        borderBottom: { size: 1, color: krollshadow },
                        colSpan: 11
                    }
                ]
            },
            {
                cells: [
                    { value: 'WACC', bold: 'true' },
                    { value: '=', bold: 'true' },
                    { value: 'Ke**', bold: 'true' },
                    { value: '*', bold: 'true' },
                    { value: 'We', bold: 'true' },
                    { value: '+', bold: 'true' },
                    { value: 'Kd', bold: 'true' },
                    { value: '*', bold: 'true' },
                    { value: 'Wd', bold: 'true' },
                    { value: '*', bold: 'true' },
                    { value: '(1-t)', bold: 'true' }
                ]
            }
        ).concat(options.scenarioHelper.createWaccRow(options.summary.waccEquationValues));
}
