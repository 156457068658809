import { ContextualString } from "@concurrency/core";
import { MetricInputs } from '../_api/responses/metric-inputs.response';
import { CommonDate } from "../_navigator/common/date-struct";
import { ComparableCompanyFieldTypes } from "./beta.constants";

export interface MarketIndex {
    id: string;
    value: string;
}
export interface ReturnFrequency {
    id: string;
    value: string;
}
export interface GeneralInputs {
    ValuationDate: CommonDate;
    ReturnFrequency: ContextualString;
    CurrencyOfWACC: ContextualString;
    LookbackPeriod: ContextualString;
    SubjectCompanyLocation: ContextualString;
    MarketIndex: ContextualString;
}
export interface SearchGeneralInputs {
    ValuationDate: Date;
    ReturnFrequency: string;
    CurrencyOfWACC: string;
    LookbackPeriod: string;
    SubjectCompanyLocation: string;
    MarketIndex: string;
}

export interface ComaparableCompany {
    GVKEY: string;
    CompanyName: string;
    Observations: number;
    Ticker: string;
    IndustryCodeDescription: string;
    KeyWords: string;
    Sales: number | null;
    EBITDA: number | null;
    Assets: number | null;
    MarketCap: number | null;
    IncorporationCountry: string | null;
    HeadquarterCountry: string | null;
    Exchange: string | null;
    IsHidden: boolean | null;
    TrendsOverTimeDisplay: boolean | true;
}

export interface ComaparableCompanyFilter {
    keyOfFilter: string;
    type: ComparableCompanyFieldTypes;
    inputValue: string;
    isInfo: boolean;
    isCanDelete?: boolean;
    isChecked?: boolean;
    isRequired: boolean;
    isDisabled?: boolean;
    column: number;
    minimumWidth?: string;
    isFicLoc: boolean;
    isInputDisabled?: boolean;
}

export enum OparatorEnum {
    Equal = '=',
    GreaterThan = '>',
    LessThan = '<'
}
export interface BetaComparbleCompanyRequest {
    valuationDate: Date | string;
    lookbackPeriod: string;
    isMonthly: boolean;
    currency: string;
    companyName: string | null;
    sales: number | null;
    salesOperatorName: string | null;
    EBITDA: number | null;
    EBITDAOperatorName: string | null;
    observations: number | null;
    observationsOperatorName: string | null;
    assets: number | null;
    assetsOperatorName: string | null;
    keyWords: string | null;
    ticker: string | null;
    incorporationCountry: string | null;
    headquarterCountry: string | null;
    industry: string | null;
    marketCap: number | null;
    marketCapOperatorName: string | null;
    exchange: string | null;
    website: string | null;
    PAGE: number;
    SIZE: number;
}

export interface BetaEstimateRequest {
    EstimateId: any;
    MetricInputs: MetricInputs;
    MarketIndexName: string;
    BetasDataAsOf: string;
    Companies: BetaComparbleCompanyResults[];
    LeveredBetas: LeveredBetas[];
    BetaStatistics: BetaStatistics[];
    UnleveredBetas: LeveredBetas[];
    UnleveringBetas: UnleveringInputs[];
    ReleveredInputs: ReleveredInputs;
    ReleveredStatistics: Statistics;
    LeveredStatistics: Statistics;
    UnleveredStatistics: Statistics;
}

export interface BetaComparbleCompanyResults {
    GVKEY: string;
    CompanyName: string;
    Observations: number;
    Ticker: string;
    IncorporationCountry: string;
    HeadquarterCountry: string | null;
    IndustryCodeDescription: string | null;
    Dataasof: string;
    mAsOf: string;
    Currency: string;
    Sales: number | null;
    Assets: number | null;
    EBITDA: number | null;
    TotalDebt: number | null;
    MarketCap: number | null;
    KeyWords: string;
    Exchange: string | null;
    IsHidden: boolean;
    PageCount: number;
    PageSize: number;
    TotalCount: number;
    Website?: string;
    TrendsOverTimeDisplay: boolean | true;
}
export interface CompanyReturnsRequest {
    GVKEY: string[];
    Metrics: MetricInputs;
}

export interface CompanyReturnsRequestWithGVkeyStatus {
    GVKEYS: GVKeyStatus[];
    Metrics: MetricInputs;
}

export interface GVKeyStatus {
    GVKEY: string;
    IsGVKEYHidden: boolean;
}

export interface CompanyselectedChangesResults {
    GVKEY: string[];
    Observations: number[];
}

export interface LeveredBetas {
    [key: string]: any;
    GVKEY: string;
    OLSBeta: any;
    SumBeta: any;
    VasicekAdjusted: any | null;
    // Downside?: any;
    BlumeAdjusted: any;
    PeerGroup: any;
    IsHidden?: boolean;
}
export interface BetaStatistics {
    GVKey: string;
    AdjustedOlsRSquare: any;
    AdjustedSumRSquare: any;
    StandardError: any;
    TStat: any;
    PValue: any;
    SignificantLevel1: string;
    SignificantLevel2: string;
    SignificantLevel3: string;
    IsHidden?: boolean;
}
export interface UnleveringTaxRate {
    TaxRateValue: number;
    GVKEY: string;
    Label: any;
}
export interface AllSummaryStatistics {
    [key: string]: any;
    LeveredAverageOLSBeta: any;
    LeveredFirstQuartileOLSBeta: any;
    LeveredMedianOLSBeta: any;
    LeveredThirdQuartileOLSBeta: any;
    LeveredMaximumOLSBeta: any;
    LeveredMinimumOLSBeta: any;
    LeveredSalesWeightedOLSBeta: any;
    LeveredMarketCapWeightedOLSBeta: any;
    LeveredAverageSumBeta: any;
    LeveredFirstQuartileSumBeta: any;
    LeveredMedianSumBeta: any;
    LeveredThirdQuartileSumBeta: any;
    LeveredMaximumSumBeta: any;
    LeveredMinimumSumBeta: any;
    LeveredSalesWeightedSumBeta: any;
    LeveredMarketCapWeightedSumBeta: any;
    LeveredAverageVasicekAdjusted: any | null;
    LeveredFirstQuartileVasicekAdjusted: any | null;
    LeveredMedianVasicekAdjusted: any | null;
    LeveredThirdQuartileVasicekAdjusted: any | null;
    LeveredMaximumVasicekAdjusted: any | null;
    LeveredMinimumVasicekAdjusted: any | null;
    LeveredSalesWeightedVasicekAdjusted: any;
    LeveredMarketCapWeightedVasicekAdjusted: any;
    LeveredAverageBlumeAdjusted: any;
    LeveredFirstQuartileBlumeAdjusted: any;
    LeveredMedianBlumeAdjusted: any;
    LeveredThirdQuartileBlumeAdjusted: any;
    LeveredMaximumBlumeAdjusted: any;
    LeveredMinimumBlumeAdjusted: any;
    LeveredSalesWeightedBlumeAdjusted: any;
    LeveredMarketCapWeightedBlumeAdjusted: any;
    LeveredAveragePortfolioBeta: any;
    LeveredFirstQuartilePortfolioBeta: any;
    LeveredMedianPortfolioBeta: any;
    LeveredThirdQuartilePortfolioBeta: any;
    LeveredMaximumPortfolioBeta: any;
    LeveredMinimumPortfolioBeta: any;
    LeveredSalesWeightedPortfolioBeta: any;
    LeveredMarketCapWeightedPortfolioBeta: any;

    UnleveredAverageOLSBeta: any;
    UnleveredFirstQuartileOLSBeta: any;
    UnleveredMedianOLSBeta: any;
    UnleveredThirdQuartileOLSBeta: any;
    UnleveredMaximumOLSBeta: any;
    UnleveredMinimumOLSBeta: any;
    UnleveredSalesWeightedOLSBeta: any;
    UnleveredMarketCapWeightedOLSBeta: any;
    UnleveredAverageSumBeta: any;
    UnleveredFirstQuartileSumBeta: any;
    UnleveredMedianSumBeta: any;
    UnleveredThirdQuartileSumBeta: any;
    UnleveredMaximumSumBeta: any;
    UnleveredMinimumSumBeta: any;
    UnleveredSalesWeightedSumBeta: any;
    UnleveredMarketCapWeightedSumBeta: any;
    UnleveredAverageVasicekAdjusted: any | null;
    UnleveredFirstQuartileVasicekAdjusted: any | null;
    UnleveredMedianVasicekAdjusted: any | null;
    UnleveredThirdQuartileVasicekAdjusted: any | null;
    UnleveredMaximumVasicekAdjusted: any | null;
    UnleveredMinimumVasicekAdjusted: any | null;
    UnleveredSalesWeightedVasicekAdjusted: any;
    UnleveredMarketCapWeightedVasicekAdjusted: any;
    UnleveredAverageBlumeAdjusted: any;
    UnleveredFirstQuartileBlumeAdjusted: any;
    UnleveredMedianBlumeAdjusted: any;
    UnleveredThirdQuartileBlumeAdjusted: any;
    UnleveredMaximumBlumeAdjusted: any;
    UnleveredMinimumBlumeAdjusted: any;
    UnleveredSalesWeightedBlumeAdjusted: any;
    UnleveredMarketCapWeightedBlumeAdjusted: any;
    UnleveredAveragePortfolioBeta: any;
    UnleveredFirstQuartilePortfolioBeta: any;
    UnleveredMedianPortfolioBeta: any;
    UnleveredThirdQuartilePortfolioBeta: any;
    UnleveredMaximumPortfolioBeta: any;
    UnleveredMinimumPortfolioBeta: any;
    UnleveredSalesWeightedPortfolioBeta: any;
    UnleveredMarketCapWeightedPortfolioBeta: any;
    MaximumAdjustedOlsRSquare: any;
    MinimumAdjustedOlsRSquare: any;
    AverageAdjustedOlsRSquare: any;
    MedianAdjustedOlsRSquare: any;
    FirstQuartileAdjustedOlsRSquare: any;
    ThirdQuartileAdjustedOlsRSquare: any;
    SalesWeightedAdjustedOlsRSquare: any;
    MarketCapWeightedAdjustedOlsRSquare: any;

    MaximumAdjustedSumRSquare: any;
    MinimumAdjustedSumRSquare: any;
    AverageAdjustedSumRSquare: any;
    MedianAdjustedSumRSquare: any;
    FirstQuartileAdjustedSumRSquare: any;
    ThirdQuartileAdjustedSumRSquare: any;
    SalesWeightedAdjustedSumRSquare: any;
    MarketCapWeightedAdjustedSumRSquare: any;

    MaximumStandardError: any;
    MinimumStandardError: any;
    AverageStandardError: any;
    MedianStandardError: any;
    FirstQuartileStandardError: any;
    ThirdQuartileStandardError: any;
    SalesWeightedStandardError: any;
    MarketCapWeightedStandardError: any;

    MaximumTStat: any;
    MinimumTStat: any;
    AverageTStat: any;
    MedianTStat: any;
    FirstQuartileTStat: any;
    ThirdQuartileTStat: any;
    SalesWeightedTStat: any;
    MarketCapWeightedTStat: any;

    MaximumPValue: any;
    MinimumPValue: any;
    AveragePValue: any;
    MedianPValue: any;
    FirstQuartilePValue: any;
    ThirdQuartilePValue: any;
    SalesWeightedPValue: any;
    MarketCapWeightedPValue: any;

    MaximumTotalDebt: any | null;
    MinimumTotalDebt: any | null;
    AverageTotalDebt: any | null;
    MedianTotalDebt: any | null;
    FirstQuartileTotalDebt: any | null;
    ThirdQuartileTotalDebt: any | null;
    SalesWeightedTotalDebt: any | null;
    MarketCapWeightedTotalDebt: any | null;

    MaximumMarketCap: any | null;
    MinimumMarketCap: any | null;
    AverageMarketCap: any | null;
    MedianMarketCap: any | null;
    FirstQuartileMarketCap: any | null;
    ThirdQuartileMarketCap: any | null;
    SalesWeightedMarketCap: any | null;
    MarketCapWeightedMarketCap: any | null;

    MaximumDebt_Equity: any | null;
    MinimumDebt_Equity: any | null;
    AverageDebt_Equity: any | null;
    MedianDebt_Equity: any | null;
    AverageDebt_TotalCapital: any | null;
    MedianDebt_TotalCapital: any | null;
    FirstQuartileDebt_Equity: any | null;
    ThirdQuartileDebt_Equity: any | null;
    SalesWeightedDebt_Equity: any | null;
    MarketCapWeightedDebt_Equity: any | null;

    MaximumTaxRate: any | null;
    MinimumTaxRate: any | null;
    AverageTaxRate: any | null;
    MedianTaxRate: any | null;
    FirstQuartileTaxRate: any | null;
    ThirdQuartileTaxRate: any | null;
    SalesWeightedTaxRate: any | null;
    MarketCapWeightedTaxRate: any | null;
}

export interface UnleveringInputs {
    GVKEY: string;
    TotalDebt: any | null;
    MarketCap: any | null;
    Debt_Equity: any | null;
    Debt_TotalCapital: any | null;
    TaxRate: any | null;
    DataAsOf?: any | null;
    mAsOf?: any | null;
    Label: any;
    IsHidden?: boolean;
}
export interface WeightingInputs {
    GVKEY: string;
    Sales: any;
    MarketCap: any;
}
export interface WeightResults {
    [key: string]: any;
    GVKEY: string;
    SalesWeighting: any;
    MarketCapWeighting: any;
    IsHidden?: boolean;
}

export class BetaColors {
    public static Colors: any = [
        'rgb(20, 72, 127)',
        'rgb(67, 176, 73)',
        'rgb(76, 159, 200)',
        'rgb(0, 48, 87)',
        'rgb(103, 210, 223)',
        'rgb(167, 168, 169)',
        'rgb(208, 0, 112)',
        'rgb(226, 102, 92)',
        'rgb(33, 87, 50)',
        'rgb(196, 214, 0)',
        'rgb(227, 228, 141)',
        'rgb(77, 77, 79)',
        'rgb(187, 221, 230)',
        'rgb(102, 131, 154)', // 'rgb(237, 237, 237)',
        'rgb(191, 13, 62)',
        'rgb(0, 20, 36)',
        'rgb(114, 145, 178)',
        'rgb(164, 228, 236)',
        'rgb(184, 184, 185)',
        'rgb(148, 197, 222)'
    ];
}
export interface ReleveredBetas {
    BetaType: ContextualString;
    TargetCapital: ContextualString;
    TaxRate: ContextualString;
}
export interface ReleveredInputs {
    BetaType: string;
    TargetCapitalStructure: any;
    OriginalTargetCapitalStructure: any;
    TaxRate: any;
    TargetLabel: string;
    TaxRateLabel: string;
}
export interface Statistics {
    Minimum: any;
    FirstQuartile: any;
    Average: any;
    Median: any;
    ThirdQuartile: any;
    Maximum: any;
}
export interface UnleveringTaxRateInputs {
    GVKEY: string;
    CountryCode: string;
    DataAsOf: Date;
}

export interface BetaOptions {
    MarketIndexName: any;
    BetasAsOf: any;
    GeneralInputs: MetricInputs;
    Companies: BetaComparbleCompanyResults[];
    LeveredBetas: LeveredBetas[];
    BetaStatitics: BetaStatistics[];
    UnleveredBetas: LeveredBetas[];
    UnleveringBetas: UnleveringInputs[];
    ReleveredInputs: ReleveredInputs;
    ReleveredStatistics: Statistics;
    LeveredStatistics: Statistics;
    UnleveredStatistics: Statistics;
    SummaryStatistics: AllSummaryStatistics;
}

export interface CompanyInformation {
    lstBusinessSegments: BusinessSegment[];
    lstGeographySegment: GeographySegment[];

}

export interface CompanyValuationInputs {
    GVKEY: string;
    ValuationDate: Date;
}
export interface BusinessSegment {
    BusinessSeg: string;
    GICID: number;
    BusinessSales: number;
    DateasOf: Date;
    BusinesssSalesPrc: string;
}

export interface GeographySegment {
    GeoSales: number;
    GeoSeg: string;
    GeoSalesprc: string;

}

// export interface IndustryCodeDescription {
//     IndustryCode: string;
//     Sector: string;
// }

export interface BetaToT {
    Gvkey: string;
    DataAsOf: string;
    OlsBeta: number | null;
    SumBeta: number | null;
}


