import * as moment from 'moment';

import { Component, OnInit, ViewChild } from '@angular/core';
import { Util } from '@concurrency/core';
import { Dialog } from 'primeng/dialog';
import { of } from 'rxjs';
import { Industry } from 'src/app/_api/responses/us-industry.response';
import { CommonDate } from 'src/app/_navigator/common/date-struct';
import { DataStore } from 'src/app/_navigator/data/store/data.store';
import { TypeaheadFormatters } from 'src/app/_navigator/data/util/typeahead-formatters.util';
import { TrendsOverTimeService } from 'src/app/home/tear-sheet/data/trends-over-time.service';
import { BenchmarkingQueryBuilderService } from '../../data/benchmarking-query-builder.service';

@Component({
    templateUrl: './view-industries.component.html',
    styleUrls: ['./view-industries.component.scss']
})
export class ViewIndustriesComponent implements OnInit {
    public moment = moment;
    public Util = Util;
    public display = true;
    public sicCode!: string;
    public searchTerm = '';
    public industries!: Industry[];
    public filteredIndustries: Industry[] = new Array<Industry>();
    public region!: string;
    public currency!: string;
    public valuationDate!: CommonDate;
    public moduleName!: string;
    public dialogHeader = 'List of Industries';
    public TypeaheadFormatters = TypeaheadFormatters;

    @ViewChild(Dialog) public dialog!: Dialog;

    constructor(
        protected dataStore: DataStore,
        public trendsOverTimeService: TrendsOverTimeService,
        public benchmarkingQueryBuilderService: BenchmarkingQueryBuilderService
    ) { }

    public ngOnInit(): void {
        if (this.moduleName === 'USCOC' && this.industries.length > 0) {
            this.industries = this.benchmarkingQueryBuilderService.filterCOCIndustries(this.industries);
            this.benchmarkingQueryBuilderService.industries = this.industries;
        } else {
            this.filteredIndustries = this.benchmarkingQueryBuilderService.filterIndustriesBasedOnGeographic(this.industries);
        }
    }

    public onTypeAheadChange(value: string): void {
        this.benchmarkingQueryBuilderService.IndustriesZoneFilterTypeahead(of(value), this.moduleName, true).onceDefined((results) => {
            if (results.length && this.moduleName === 'Benchmarking') {
                this.filteredIndustries = results;
            } else if (results.length && this.moduleName === 'USCOC') {
                this.industries = results;
            } else if (!results.length && this.moduleName === 'USCOC') {
                this.industries = this.benchmarkingQueryBuilderService.industries;
            } else {
                this.filteredIndustries = this.industries;
            }
        });
    }
}
