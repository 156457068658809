import { Observable } from 'rxjs';
import { SelectionType } from 'src/app/_api/enums/selection-type';
import { EquationType, ImplicationType } from 'src/app/_api/responses/equation.response';
import { InputType } from 'src/app/_api/responses/input.response';
import { Scenario } from 'src/app/_navigator/data/model/scenario.model';
import { Help } from 'src/app/_navigator/help/help.model';

// TODO: Break all this up
export interface Equation {
    name: string;
    equationType: EquationType;
    implicationType: ImplicationType;
    parameters: EquationParameter[];
    // TODO: Calculate should not return a boolean, and anything that relies on this
    //   should instead lookup the appropriate Result themselves to verify if a scenario
    //   produced a result
    calculate: (scenario: Scenario) => boolean;
}

export interface CanGetInfo {
    getInfo: () => Observable<string>;
}

export interface EquationParameter {
    item: EquationOperand | EquationResult;
    operator: string;
    canEdit: boolean;
}

export interface EquationItem {
    name: string;
    placeholderHTML: string;
    help?: Help;
    info?: CanGetInfo; // TODO: Why is this type called CanGetInfo
    mask?: string;
}

export interface EquationResult extends EquationItem {
    implicationType: ImplicationType;
    equationType: EquationType;
}

export interface EquationOperand extends EquationItem {
    selectionType: SelectionType;
    dependentType?: SelectionType;
    suggestionType?: SelectionType;
    inputType?: InputType;
    equationType?: EquationType;
    editorName?: string;
    editor?: any; // TODO: Why can't this be an EditorComponent
    labelType?: LabelType;
    notReadyWarning?: string;
}

export enum LabelType { LeveredDebt = 1, UnleveredDebt = 2, ReleveredDebt = 3, ZScore = 4 }
