import * as moment from 'moment';
import { headerCellHeight, headerFontSize, krollblue, krollshadow } from '../util/summary-helpers';

// TODO figure out this any type
export function summaryExcelAbout(): any {
    return [
        {
                height: headerCellHeight,
                cells: [
                    {
                        value: 'Source',
                        color: krollblue,
                        fontSize: headerFontSize,
                        bold: 'true'
                    }
                ]
        },
        {
            cells: [
                { value: 'Kroll Cost of Capital Navigator: U.S. Cost of Capital Dataset' }
            ]
        },
        {
            cells:[
                {value: `Exported on: ${moment().format('MM/DD/YYYY')}`}
            ]
        },
        {
            cells: [
                { value: 'Template Version:' },
                { value: 'CofC-v2.0' }
            ]
        },
        {
            cells:[
                {value:''}
            ]
        },
        {
            height: headerCellHeight,
            cells: [
                {
                    value: 'Data Sources',
                    color: krollblue,
                    fontSize: headerFontSize,
                    bold: 'true'
                }
            ]
        },
        {
            cells:[
                {value:'Data Sources used with permission. All rights reserved. Calculations performed by Kroll, LLC.'}
            ]
        }
    ];
}
