
// import { ExcelExportUtil } from 'src/app/estimate/exports/util/excel-export.util';
import { BetaColumnDetails, CompaniesColumnDetails } from 'src/app/estimate/exports/summary-export/util/summary-helpers';
import { BetaOptions } from '../beta.types';
import { companyLevelBetas } from '../export/company-level-betas';
import { comparableCompanies } from '../export/comparable-companies';
import { generalInputs } from '../export/general-inputs';
import { dataSources } from '../export/about';
import { CommonExcelExportUtil } from 'src/app/_navigator/common/common-excel-export-util';

export function betaModuleOptions(options: BetaOptions): any {
    // const moduleName = 'Company-level Beta Module';

    return {
        sheets: [
            {
                name: 'GeneralInputs',
                columns: [
                    { width: 210 },//A
                    { width: 150 } //B
                ],
                rows: generalInputs(options.GeneralInputs, options.MarketIndexName, options.BetasAsOf)
                // .concat(ExcelExportUtil.getFooter(moduleName))
            },
            {
                name: 'ComparableCompanies',
                columns: CompaniesColumnDetails,
                rows: comparableCompanies(options.Companies, options.GeneralInputs.ValuationDate, options.GeneralInputs.Currency)
            },
            {
                name: 'Company-LevelBetas',
                columns: BetaColumnDetails,
                rows: companyLevelBetas(options)
            },
            {
                name: 'About',
                columns: [
                    { width: 300 },//A
                    { width: 300 } //B
                ],
                rows: dataSources()
            }
        ]
    };
}
