import { Injectable } from '@angular/core';
import { ApiClient } from '@concurrency/angular';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { CountryCreditRating } from '../responses/country-credit-rating.response';
import { CountryRiskPremia } from '../responses/country-risk-premia.response';
import { Auth0ApiClient } from '../api.client';
import { CountryRiskPremium, TierMethodologyData } from 'src/app/costofcapital-inputs/models/countryRiskPremium';


@Injectable()
export class CostOfEquityClient extends Auth0ApiClient {
    protected baseUrl = `${environment.urls.navigatorApi}/api`;

    public readCcr(investor: number, investee: number, dataAsOf: string): Observable<CountryCreditRating> {
        return this.get(
            `CostOfEquity/Ccr?investorCountryId=${investor}&investeeCountryId=${investee}&dataAsOf=${dataAsOf}`
        );
    }

    public TrendsreadCcr(investor: number, investee: number, dataAsOf: string): Observable<CountryCreditRating[]> {
        return this.get(
            `CostOfEquity/Getccrlist?investorCountryId=${investor}&investeeCountryId=${investee}&dataAsOf=${dataAsOf}`
        );
    }

    public readCountryRiskPremia(investor: number, investee: number, dataAsOf: string): Observable<CountryRiskPremia[]> {
        return this.get(`CostOfEquity/CountryRiskPremia?investorCountryId=${investor}&investeeCountryId=${investee}&dataAsOf=${dataAsOf}`);
    }

    public GetCountryRiskPremia(investor: number, investee: number, dataAsOf: string): Observable<CountryRiskPremia[]> {
        return this.get(`CostOfEquity/TrendsovertimeCountryRiskPremia?investorCountryId=${investor}&investeeCountryId=${investee}&dataAsOf=${dataAsOf}`);
    }

    public getCountryRiskPremiumData(investee: number, dataAsOf: string): Observable<CountryRiskPremium[]> {
        return this.get(`CostOfEquity/GetIntlCOCAddinExportlist?investeeCountryId=${investee}&dataAsOf=${dataAsOf}`)
    }

    public getTirerMethodologyData(investee: number, dataAsOf: string): Observable<TierMethodologyData[]> {
        return this.get(`CostOfEquity/GetTierMethodologyData?investeeCountryId=${investee}&dataAsOf=${dataAsOf}`)
    }
}
