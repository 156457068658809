import { DashboardModule } from './dashboard-module.model';
import { LaunchText } from './launch-text';
import { ModuleDescription } from './module-description.model';
import { ModuleStartText } from './module-start-text.model';
import { ModuleType } from './module-type';
import { StoreUrlPrompt } from './store-url-prompt';

export class ModuleData {

    public usCostOfCapital: DashboardModule = {
        type: ModuleType.UsCostOfCapital,
        description: ModuleDescription.UsCostOfCapital,
        isDisabled: true,
        startText: ModuleStartText.StartUsCostOfCapital,
        storeUrl: 'https://www.kroll.com/',
        moduleTitle: 'U.S. Cost of Capital Module',
        launchText: LaunchText.Start,
        storeUrlPrompt: StoreUrlPrompt.SubscribeNow,
        faqLink: '',
        helpLink: '',
        moduleIcon: './assets/images/us-coc-module.png',
        productNews: 'TODO Get some actual product news to display here',
        resourcesLink: '',
        viewDemoLink: '',
        subscriptionExpiration: new Date(0),
        featureName: ''
    };
    public usIndustryBenchmarking: DashboardModule = {
        type: ModuleType.UsIndustryBenchmarking,
        description: ModuleDescription.UsIndustryBenchmarking,
        isDisabled: true,
        startText: ModuleStartText.StartUsIndustryBenchmarking,
        storeUrl: 'https://www.kroll.com/',
        moduleTitle: 'U.S. Industry Benchmarking Module',
        launchText: LaunchText.Start,
        storeUrlPrompt: StoreUrlPrompt.SubscribeNow,
        faqLink: '',
        helpLink: '',
        moduleIcon: './assets/images/us-ind-bench-module.png',
        productNews: 'TODO Get some actual product news to display here',
        resourcesLink: '',
        viewDemoLink: '',
        subscriptionExpiration: new Date(0),
        featureName: ''
    };
    public internationalCostOfCapital: DashboardModule = {
        type: ModuleType.InternationalCostOfCapital,
        description: ModuleDescription.InternationalCostOfCapital,
        isDisabled: true,
        startText: ModuleStartText.StartInternationalCostOfCapital,
        storeUrl: 'https://www.kroll.com/',
        moduleTitle: 'International Cost of Capital Module',
        launchText: LaunchText.Start,
        storeUrlPrompt: StoreUrlPrompt.SubscribeNow,
        faqLink: '',
        helpLink: '',
        moduleIcon: './assets/images/in-coc-module.png',
        productNews: 'TODO Get some actual product news to display here',
        resourcesLink: '',
        viewDemoLink: '',
        subscriptionExpiration: new Date(0),
        featureName: ''
    };
    public internationalIndustryBenchmarking: DashboardModule = {
        type: ModuleType.InternationalIndustryBenchmarking,
        description: ModuleDescription.InternationalBenchmarking,
        isDisabled: true,
        startText: ModuleStartText.StartInternationalCostOfCapital,
        storeUrl: 'https://www.kroll.com/',
        moduleTitle: 'International Industry Benchmarking Module',
        launchText: LaunchText.Start,
        storeUrlPrompt: StoreUrlPrompt.SubscribeNow,
        faqLink: '',
        helpLink: '',
        moduleIcon: './assets/images/int-ind-bench-module.png',
        productNews: 'TODO Get some actual product news to display here',
        resourcesLink: '',
        viewDemoLink: '',
        subscriptionExpiration: new Date(0),
        featureName: ''
    };
    public Beta: DashboardModule = {
        type: ModuleType.Beta,
        description: ModuleDescription.Beta,
        isDisabled: true,
        startText: ModuleStartText.StartBeta,
        storeUrl: 'https://www.kroll.com/',
        moduleTitle: 'Company-level Beta Module',
        launchText: LaunchText.Start,
        storeUrlPrompt: StoreUrlPrompt.SubscribeNow,
        faqLink: '',
        helpLink: '',
        moduleIcon: './assets/images/int-ind-bench-module.png',
        productNews: 'TODO Get some actual product news to display here',
        resourcesLink: '',
        viewDemoLink: '',
        subscriptionExpiration: new Date(0),
        featureName: ''
    };
}
