import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

// TODO: This is an entirely useless component as there is no reason whatsoever
// For any external site (e.g. the Sitecore landing page) to link to our site rather than
// directly to FastSpring, if that is the intended destination. This should be deleted
// in the event that it is (correctly) not used. Moreover, this isn't the greatest way
// of implementing this, as if we really did need to bounce off of our site before going
// to FastSpring, we should handle that in main.ts before loading the app. Alas...
@Component({ template: '' })
export class PurchaseComponent implements OnInit {
    constructor(
        private route: ActivatedRoute,
        private router: Router
    ) { }

    public ngOnInit(): void {
        this.route.queryParamMap.once((params) => {
            const product = params.get('product');
            if (product) {
                if (product === 'trialsub') {
                    window.location.href = `${environment.urls.fastSpring}/${product}`;
                } else {
                    window.location.href = `${environment.urls.fastSpring}/all`;
                }
            } else {
                this.router.navigate(['']);
            }
        });
    }
}
