import { Injectable } from '@angular/core';
import { TearSheetService } from 'src/app/home/tear-sheet/data/tear-sheet.service';
import { CommonExcelExportUtil } from '../_navigator/common/common-excel-export-util';
import { BetaService } from './beta.service';
import { BetaOptions } from './beta.types';
import { betaModuleOptions } from './util/betamodule-export.util';

@Injectable({
    providedIn: 'root'
})
export class BetaModuleExcelExportService {

    constructor(
        private betaService: BetaService,
        private tearSheetService: TearSheetService
    ) { }

    private generateExcelExport(component: any, betaOptions: BetaOptions): void {
        let options = betaModuleOptions(betaOptions);
        options = this.tearSheetService.setDefaultExcel(options);
        options = CommonExcelExportUtil.getUpdatedCopyRigtsSheet(options);
        component.save(options);
    }

    // public options!: BetaOptions;

    public save(component: any): void {
        const betaOptions: BetaOptions = {
            MarketIndexName: this.betaService.marketIndexName,
            BetasAsOf: this.betaService.betasAsOf,
            GeneralInputs: this.betaService.metricInputs,
            Companies: this.betaService.finalSelectedCompanies,
            LeveredBetas: this.betaService.leveredBetas,
            BetaStatitics: this.betaService.betaStatistics,
            UnleveringBetas: this.betaService.unleveringInputs,
            UnleveredBetas: this.betaService.unleveredBetas,
            ReleveredInputs: this.betaService.releveredInputs,
            ReleveredStatistics: this.betaService.releveredStatistics,
            LeveredStatistics: this.betaService.leveredStatistics,
            UnleveredStatistics: this.betaService.unleveredStatistics,
            SummaryStatistics: this.betaService.summaryStatisticsValues
        };
        this.generateExcelExport(component, betaOptions);
    }

}
