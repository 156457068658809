import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { BetaService } from '../beta.service';

@Component({
    selector: 'operators',
    templateUrl: './operators.component.html',
    styleUrls: ['./operators.component.scss']
})
export class OperatorsComponent implements OnChanges {

    @Input() public filter: any;
    @Input() public operatorValue: any;
    @Input() public operators: any;

    constructor(
        public betaService: BetaService
    ) { }


    ngOnChanges() {
        if (this.operators.length == 0) {
            this.operatorValue = "";
        }

        this.operators = this.betaService.filterOperators;
    }



    public onOperatorChange(event: any): void {
        if (this.filter.keyOfFilter === 'Observations') {
            this.betaService.betaComparbleCompanyRequest.observationsOperatorName = event.value;
        } else if (this.filter.keyOfFilter === 'Sales') {
            this.betaService.betaComparbleCompanyRequest.salesOperatorName = event.value;
        } else if (this.filter.keyOfFilter === 'EBITDA') {
            this.betaService.betaComparbleCompanyRequest.EBITDAOperatorName = event.value;
        } else if (this.filter.keyOfFilter === 'Assets') {
            this.betaService.betaComparbleCompanyRequest.assetsOperatorName = event.value;
        } else if (this.filter.keyOfFilter === 'MarketCap') {
            this.betaService.betaComparbleCompanyRequest.marketCapOperatorName = event.value;
        }
        this.filter.isInputDisabled = false;
        this.filter.inputValue = '';
    }

    public onClick(): void {
        this.operators = this.betaService.filterOperators;
    }

}
