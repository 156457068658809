import { Action, Select, Selector, State, StateContext } from '@ngxs/store';
import { Observable } from 'rxjs/internal/Observable';
import { CostOfEquityClient } from 'src/app/_api/clients/costOfEquity.client';
import { CountryCreditRating } from 'src/app/_api/responses/country-credit-rating.response';
import { SelectionsUtil } from 'src/app/international-estimate/data/util/selections.util';
import { Estimate } from '../data/model/estimate.model';
import { EstimateState } from '../estimate-store/estimate-state';
import { SetCcr } from './ccr-actions';
import { Injectable } from '@angular/core';

export type CcrModel = CountryCreditRating | undefined;

@State<CcrModel>({
    name: 'ccr',
    defaults: undefined
})


@Injectable()
export class CcrState {

    @Selector()
    public static get(state: CcrModel): CountryCreditRating | undefined {
        return state;
    }
    @Select(EstimateState.get) public estimateSelector!: Observable<Estimate | undefined>;

    constructor(private costOfEquityClient: CostOfEquityClient) { }

    @Action(SetCcr)
    public setCcr(context: StateContext<CcrModel>, action: SetCcr): Observable<void> {
        return this.costOfEquityClient.readCcr(action.investorCountryId, action.investeeCountryId, action.dataAsOf).once((response) => {
            this.estimateSelector.onceDefined((estimate) => {
                context.setState(response);
                SelectionsUtil.setCcrSelections(estimate, response);
            });
        });
    }
}
