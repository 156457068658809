// TODO: Re-use this on the landing page
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SubscriberEntity } from '@concurrency/angular';
import { UserStore } from '../../_navigator/user/store/user.store';

@Component({ templateUrl: 'contact-us.component.html' })
export class ContactUsComponent extends SubscriberEntity implements OnInit {
    public emailHref = '';
    public contactName = '';
    public contactEmail = '';
    public contactSubject = '';
    public contactMessage = '';

    constructor(
        private route: ActivatedRoute,
        private userStore: UserStore
    ) { super(); }

    public ngOnInit(): void {
        this.route.queryParams.once((params) => this.contactSubject = params.subject);
        this.userStore.user.onceDefined((profile) => {
            this.contactName = profile.FullName;
            this.contactEmail = profile.Email;
        });
    }

    public updateEmail(): void {
        const message = this.contactMessage + '\n\n' + `Sent by ${this.contactName}` + '\n' + `${this.contactEmail}`;
        const body = encodeURI(message);

        this.emailHref = `mailto:CostofCapital.Support@kroll.com?subject=${this.contactSubject}&body=${body}`;
    }
}
