import { betaBackGround } from 'src/app/estimate/exports/summary-export/util/summary-helpers';
import { LeveredBetas } from '../beta.types';

export function getOlsBeta(leveredBetas: LeveredBetas[]): any[] {
    const olsBetas: any[] = [];
    if (leveredBetas.length > 0) {
        olsBetas.push(
            { value: '' },
            {
                value: 'OLS'
            },
            { value: '' }
        );
        leveredBetas.forEach((betas: LeveredBetas) => {
            olsBetas.push(
                {
                    background: betaBackGround,
                    value: valueConversion(betas.OLSBeta),
                    format: '0.00',
                    textAlign: 'right'
                }
            );
        });
    }

    return olsBetas;
}

export function getSumBeta(leveredBetas: LeveredBetas[]): any[] {
    const sumBetas: any[] = [];
    if (leveredBetas.length > 0) {
        sumBetas.push(
            { value: '' },
            {
                value: 'Sum'
            },
            { value: '' }
        );
        leveredBetas.forEach((betas: LeveredBetas) => {
            sumBetas.push(
                {
                    background: betaBackGround,
                    value: valueConversion(betas.SumBeta),
                    format: '0.00',
                    textAlign: 'right'
                }
            );
        });
    }

    return sumBetas;
}

export function getVasicekBeta(leveredBetas: LeveredBetas[]): any[] {
    const vasicekBetas: any[] = [];
    if (leveredBetas.length > 0) {
        vasicekBetas.push(
            { value: '' },
            {
                value: 'Vasicek Adjusted'
            },
            { value: '' }
        );
        leveredBetas.forEach((betas: LeveredBetas) => {
            vasicekBetas.push(
                {
                    background: betaBackGround,
                    value: valueConversion(betas.VasicekAdjusted),
                    format: '0.00',
                    textAlign: 'right'
                }
            );
        });
    }
    return vasicekBetas;
}

export function getPortfolioBeta(leveredBetas: LeveredBetas[]): any[] {
    const portfolioBetas: any[] = [];
    if (leveredBetas.length > 0) {
        portfolioBetas.push(
            { value: '' },
            {
                value: 'Portfolio'
            },
            { value: '' }
        );
        leveredBetas.forEach((betas: LeveredBetas) => {
            portfolioBetas.push(
                {
                    background: betaBackGround,
                    value: valueConversion(betas.PeerGroup),
                    format: '0.00',
                    textAlign: 'right'
                }
            );
        });
    }
    return portfolioBetas;
}

export function getBlumeBeta(leveredBetas: LeveredBetas[]): any[] {
    const blumeBetas: any[] = [];
    if (leveredBetas.length > 0) {
        blumeBetas.push(
            { value: '' },
            {
                value: 'Blume Adjusted'
            },
            { value: '' }
        );
        leveredBetas.forEach((betas: LeveredBetas) => {
            blumeBetas.push(
                {
                    background: betaBackGround,
                    value: valueConversion(betas.BlumeAdjusted),
                    format: '0.00',
                    textAlign: 'right'
                }
            );
        });
    }
    return blumeBetas;
}

export function valueConversion(value: any): string | number {
    if (value === '-' || value === null) {
        return value;
    } else {
        return parseFloat(value);
    }
}
