import { Injectable } from '@angular/core';
import { ApiClient } from '@concurrency/angular';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Portfolio } from '../responses/portfolio.response';
import { ZScore } from '../responses/zscore.response';
import { Auth0ApiClient } from '../api.client';
import { RprRegressionData } from '../responses/RprRegressionData.response';
import { RiskPremiumCharacteristicsData } from 'src/app/costofcapital-inputs/models/rprCharacteristicsData';

@Injectable()
export class RiskClient extends Auth0ApiClient {
    protected baseUrl = `${environment.urls.navigatorApi}/api`;

    public getPortfolios(targetDate: string): Observable<Portfolio[]> {
        return this.get(`Risk/Portfolios?targetDate=${targetDate}`);
    }

    public getZscore(targetDate: string): Observable<ZScore> {
        return this.get(`Risk/ZScore?targetDate=${targetDate}`);
    }

    public GetRprRegressionData(dateAsOf: string): Observable<RprRegressionData> {
        return this.get(`Risk/RprRegressionData?dateAsOf=${dateAsOf}`)
    }

    public getRFRCharacteristicsData(dateAsOf: string): Observable<RiskPremiumCharacteristicsData> {
        return this.get(`Risk/RiskPremiumCharacteristics?targetDate=${dateAsOf}`);
    }
}
