import { Injectable } from '@angular/core';
import { Select } from '@ngxs/store';
import {
    ExcelExportComponent,
    Workbook,
    WorkbookSheetRow,
    WorkbookSheetRowCell,
    WorkbookSheetRowCellBorderBottom
} from '@progress/kendo-angular-excel-export';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CountryCreditRating } from 'src/app/_api/responses/country-credit-rating.response';
import { CountryRiskPremia } from 'src/app/_api/responses/country-risk-premia.response';
import { EstimateSummary } from 'src/app/_api/responses/estimate-summary.response';
import { Selection } from 'src/app/_api/responses/selection.response';
import { CcrState } from 'src/app/_navigator/ccr-store/ccr-state';
import { CountryRiskPremiaListState } from 'src/app/_navigator/country-risk-premia-list-store/country-risk-premia-list-state';
import { TabName } from 'src/app/_navigator/data/enum/tab-name.enum';
import { Estimate } from 'src/app/_navigator/data/model/estimate.model';
import { Scenario } from 'src/app/_navigator/data/model/scenario.model';
import { TableData } from 'src/app/_navigator/data/model/table-data.model';
import { DynamicTextSummaryState } from 'src/app/_navigator/dynamic-text-store/dynamic-text-summary-state';
import { EstimateState } from 'src/app/_navigator/estimate-store/estimate-state';
import { EstimateSummaryState } from 'src/app/_navigator/estimate-summary/estimate-summary-state';
import { TearSheetService } from 'src/app/home/tear-sheet/data/tear-sheet.service';
import { DynamicTextSummary } from 'src/app/international-estimate/dynamic-text/model/dynamic-text-summary';
import { SummaryTabs } from '../data/enum/summary-tabs';
import { SummaryType } from '../data/enum/summary-type';
import { SummaryText } from '../data/model/summary-text';
import { DynamicText } from '../dynamic-text/model/dynamic-text';
import { EquationGroup } from '../equation/equation-group.model';
import { EquationGroupUtil } from '../equation/equation-group.util';
import { SelectionLogGroup } from '../selection-log/selection-log-group';
import { SelectionLogUtil } from '../selection-log/selection-log.util';
import { CrpTableText } from '../summary-table/crp-table-text';
import { CrpTableUtil } from '../summary-table/crp-table.util';
import { RegionTableUtil } from '../summary-table/region-table.util';
import * as moment from 'moment';
import { CommonExcelExportUtil } from 'src/app/_navigator/common/common-excel-export-util';

@Injectable()
export class InternationalEstimateExcelExportService {
    // Colors
    public grayColor = '#465661';
    public krollblue = '#14487f';
    public krollgreen = '#43b049';
    public krollshadow = '#4d4d4f';
    // Font sizes
    public titleFontSize = 24;
    public headerFontSize = 20;
    public mediumFontSize = 16;
    public smallFontSize = 12;


    // public selectionLognonHeaderFontSize = 14.5;

    // Cell heights
    public headerCellHeight = 30;
    public mediumCellHeight = 26;

    // Borders
    public blueBorderBottom: WorkbookSheetRowCellBorderBottom = { size: 1, color: this.krollblue };
    public grayBorderBottom: WorkbookSheetRowCellBorderBottom = { size: 1, color: this.grayColor };
    public shadowBorderBottom: WorkbookSheetRowCellBorderBottom = { size: 1, color: this.krollshadow };

    // Summary formatting
    public summaryColumnNumber = 13;
    public summaryColumnWidth = 150;
    public keSummaryHeaderColSpan = 13;
    public kdSummaryHeaderColspan = 13;
    public waccSummaryHeaderColspan = 9;

    // Selection Log formating
    public selectionLogColumnWidth = 300;
    public selectionLogColumnNumber = 2;

    // CCR table formatting
    public ccrColumnWidth = 200;
    public ccrColumnNumber = 4;

    // Dynamic text formatting
    public dynamicTextColumnWidth = 1500;
    public dynamictextColumnNumber = 1;

    // Class objects
    public selections: Selection[] = [];
    public summary!: EstimateSummary;
    public countryCreditRating!: CountryCreditRating;
    public countryRiskPremia: CountryRiskPremia[] = [];
    public scenario!: Scenario;
    public estimate!: Estimate;
    public dynamicTextSummary!: DynamicTextSummary;

    // Ngxs selectors
    @Select(EstimateState.get) public estimateSelector!: Observable<Estimate | undefined>;
    @Select(EstimateSummaryState.get) public estimateSummarySelector!: Observable<EstimateSummary | undefined>;
    @Select(CcrState.get) public ccrSelector!: Observable<CountryCreditRating | undefined>;
    @Select(CountryRiskPremiaListState.get) public countryRiskPremiaSelector!: Observable<CountryRiskPremia[] | undefined>;
    @Select(DynamicTextSummaryState.get) public dynamicTextSummarySelector!: Observable<DynamicTextSummary | undefined>;

    constructor(
        private tearSheetService: TearSheetService
    ) { }
    private createWorksheets(): Workbook {
        const workbook = new Workbook();
        workbook.sheets = [
            {
                name: 'Selection Log',
                columns: Array(this.selectionLogColumnNumber).fill({ width: this.selectionLogColumnWidth }),
                rows: this.getSelectionLogRows()
            },
            {
                name: 'CRP and RV Summary',
                columns: Array(this.ccrColumnNumber).fill({ width: this.ccrColumnWidth }),
                rows: this.getCountryRiskPremiaRows()
            },
            {
                name: TabName.CostOfEquitySummary,
                columns: Array(this.summaryColumnNumber).fill({ width: this.summaryColumnWidth }),
                rows: this.getCostOfEquityRows()
            },
            {
                name: TabName.CostOfDebtSummary,
                columns: Array(this.summaryColumnNumber).fill({ width: this.summaryColumnWidth }),
                rows: this.getCostOfDebtRows()
            },
            {
                name: TabName.WaccSummary,
                columns: Array(this.summaryColumnNumber).fill({ width: this.summaryColumnWidth }),
                rows: this.getWaccRows()
            },
            {
                name: 'Notes',
                columns: Array(this.dynamictextColumnNumber).fill({ width: this.dynamicTextColumnWidth }),
                rows: this.getDynamicTextSummary()
            },

            {
                name: 'About',
                columns: Array(1).fill({ width: 300 }),
                rows: this.getAboutSheetRows()
            }
        ];

        return workbook;
    }

    private getCountryRiskPremiaRows(): WorkbookSheetRow[] {
        if (this.summary.InvesteeCountryName == null || this.summary.CountryOfInputsName == null) {
            return [];
        }

        const ccrTable = CrpTableUtil.getCountryPremiaTable(
            this.countryCreditRating,
            this.summary.InvesteeCountryName,
            this.summary.CountryOfInputsName
        );
        const crpTable = RegionTableUtil.getRegionPremiaTable(this.countryRiskPremia, this.scenario, false, false);

        return [
            ...this.tableDataToWorkbookSheetRow([ccrTable]),
            ...this.getHeader(CrpTableText.CountryRiskPremiaComparisons, this.titleFontSize, this.headerCellHeight, this.ccrColumnNumber),
            ...this.getEmptyRow(),
            ...this.tableDataToWorkbookSheetRow(crpTable)
        ];
    }

    private tableDataToWorkbookSheetRow(tableData: TableData[]): WorkbookSheetRow[] {
        const rows: WorkbookSheetRow[] = [];

        for (const table of tableData) {
            const tableTitleRow: WorkbookSheetRow = {
                height: this.headerCellHeight,
                cells: [{
                    value: table.name,
                    bold: true,
                    colSpan: 4,
                    borderBottom: this.shadowBorderBottom,
                    fontSize: this.headerFontSize,
                    color: this.krollblue
                }]
            };
            rows.push(tableTitleRow);

            const headerCells: WorkbookSheetRowCell[] = [];
            for (const header of table.headers) {
                headerCells.push({ value: header, bold: true });
            }
            rows.push({ cells: headerCells });

            for (const ccrRow of table.content) {
                const contentCells: WorkbookSheetRowCell[] = [];
                for (const ccrCell of ccrRow) {
                    contentCells.push({ value: ccrCell });
                }
                rows.push({ cells: contentCells });
            }
            rows.push({ cells: [{ value: '' }] });
        }

        return rows;
    }

    private equationGroupToWorkbookSheetRow(equationGroup: EquationGroup[], colspan: number): WorkbookSheetRow[] {
        const rows: WorkbookSheetRow[] = [];

        for (const group of equationGroup) {
            const equationTitleRow: WorkbookSheetRow = {
                height: this.mediumCellHeight,
                cells: [{
                    value: group.name,
                    bold: true,
                    colSpan: colspan,
                    borderBottom: this.grayBorderBottom,
                    fontSize: this.mediumFontSize,
                    color: this.grayColor
                }]
            };
            rows.push(equationTitleRow);

            if (group.isApplicable === false) {
                rows.push({ cells: [{ value: 'Not Applicable' }] });
                continue;
            }

            const valueCells: WorkbookSheetRowCell[] = [];
            const labelCells: WorkbookSheetRowCell[] = [];
            let cellValue = '';

            for (let i = 0; i < group.equation.length; i++) {
                const element = group.equation[i];
                const bold = element === group.equation[0] || element.isCrpElem;
                const color = element === group.equation[0] ? this.krollblue : (element.isCrpElem ? this.krollblue : undefined);

                if (element.slim && element.display !== '=') {
                    cellValue = cellValue.concat(`${element.display} `);
                    const hasNextElement = i + 1 < group.equation.length;

                    if (hasNextElement) {
                        const nextElement = group.equation[i + 1];
                        if (nextElement.slim) {
                            continue;
                        }
                    }

                    const operandCell: WorkbookSheetRowCell = {
                        value: cellValue,
                        textAlign: 'center',
                        color,
                        bold
                    };
                    valueCells.push(operandCell);
                    labelCells.push({ value: '' });
                    cellValue = '';
                } else {
                    if (element.display === '=') {
                        labelCells.push({ value: '' });
                        valueCells.push({ value: '=', textAlign: 'center' });
                        continue;
                    }

                    const format = element.mask ? `0.00\\%` : undefined;
                    labelCells.push({ value: element.name, textAlign: 'center', color, bold });
                    valueCells.push({ value: element.value ? parseFloat(element.value) : 0, textAlign: 'center', color, bold, format });
                }
            }

            rows.push({ cells: valueCells });
            rows.push({ cells: labelCells });
        }

        return rows;
    }

    private getCostOfEquityRows(): WorkbookSheetRow[] {
        return [
            ...this.getHeader(
                SummaryTabs.CapmEstimates,
                this.titleFontSize,
                this.headerCellHeight,
                this.keSummaryHeaderColSpan,
                this.blueBorderBottom,
                this.krollblue
            ),
            ...this.getCapmEstimates(),
            ...this.getEmptyRow(),
            ...this.getHeader(
                SummaryTabs.InternationalFisherEffect,
                this.titleFontSize,
                this.headerCellHeight,
                this.keSummaryHeaderColSpan,
                this.blueBorderBottom,
                this.krollblue
            ),
            ...this.getKeFisherEffect(),
            ...this.getEmptyRow(),
            ...this.getHeader(
                SummaryTabs.CostOfEquityConclusion,
                this.titleFontSize,
                this.headerCellHeight,
                this.keSummaryHeaderColSpan,
                this.blueBorderBottom,
                this.krollblue
            ),
            ...this.getCostOfEquityConclusion()
        ];
    }

    private getCapmEstimates(): WorkbookSheetRow[] {
        if (this.summary.CountryOfInputs == null || this.summary.CashFlowCountry == null) {
            return [];
        }

        let capmHeader: WorkbookSheetRow[] = [];
        if (this.summary.CountryOfInputs.Currency.CurrencyId !== this.summary.CashFlowCountry.Currency.CurrencyId) {
            capmHeader = this.getHeader(
                SummaryText.PreFinalConclusion,
                this.headerFontSize,
                this.headerCellHeight,
                this.keSummaryHeaderColSpan,
                this.shadowBorderBottom,
                this.krollblue
            );
        }

        const equations = EquationGroupUtil.getCostOfEquityCapmGroup(this.estimate, this.countryCreditRating, this.summary.CountryOfInputs);

        return [
            ...capmHeader,
            ...this.equationGroupToWorkbookSheetRow(equations, this.keSummaryHeaderColSpan)
        ];
    }

    private getKeFisherEffect(): WorkbookSheetRow[] {
        if (this.summary.CountryOfInputs == null || this.summary.CashFlowCountry == null) {
            return [];
        }

        if (this.summary.CashFlowCountry.Currency.CurrencyId === this.summary.CountryOfInputs.Currency.CurrencyId) {
            return [...this.getFisherEffectNotNeededRow()];
        }

        const equations = EquationGroupUtil.getCostOfEquityFisherEffectGroup(
            this.estimate,
            this.summary.CountryOfInputs,
            this.summary.CashFlowCountry,
            this.countryCreditRating
        );
        const currencyTranslationLabel = SummaryText.translateCurrencies(
            this.summary.CountryOfInputs.Currency.CurrencyAcronym,
            this.summary.CashFlowCountry.Currency.CurrencyAcronym
        );

        return [
            ...this.getHeader(
                currencyTranslationLabel,
                this.headerFontSize,
                this.headerCellHeight,
                this.keSummaryHeaderColSpan,
                this.shadowBorderBottom,
                this.krollblue
            ),
            ...this.equationGroupToWorkbookSheetRow(equations, this.keSummaryHeaderColSpan)
        ];
    }

    private getCostOfEquityConclusion(): WorkbookSheetRow[] {
        if (this.summary.InvesteeCountryName == null || this.summary.CashFlowCountry == null || this.summary.CountryOfInputs == null) {
            return [];
        }

        if (this.summary.CountryOfInputs.CountryId === this.estimate.InvesteeCountryId) {
            const equations = EquationGroupUtil.getCostOfEquityConclusionGroup(
                this.estimate,
                this.summary.CashFlowCountry,
                this.summary.CountryOfInputs
            );

            return [...this.equationGroupToWorkbookSheetRow(equations, this.keSummaryHeaderColSpan)];
        }

        const countryPremiaTable = CrpTableUtil.getKeCountryPremiaTable(
            this.scenario,
            this.countryCreditRating,
            this.summary.InvesteeCountryName,
            this.summary.CashFlowCountry,
            this.summary.CountryOfInputs
        );
        const avgMedianCrpTable = RegionTableUtil.getAvgMedianCrpTable(
            this.countryRiskPremia,
            this.estimate,
            this.summary.CashFlowCountry.Currency.CurrencyId,
            this.summary.CountryOfInputs.Currency.CurrencyId,
            SummaryType.CostOfEquity
        );
        const comparisonTable = RegionTableUtil.getRegionPremiaTable(avgMedianCrpTable, this.scenario);

        return [
            ...this.tableDataToWorkbookSheetRow([countryPremiaTable]),
            ...this.getHeader(CrpTableText.CostOfEquityCapitalComparisons, this.titleFontSize, this.headerCellHeight, this.ccrColumnNumber),
            ...this.getEmptyRow(),
            ...this.tableDataToWorkbookSheetRow(comparisonTable)
        ];
    }

    private getCostOfDebtRows(): WorkbookSheetRow[] {
        return [
            ...this.getHeader(
                SummaryTabs.CostOfDebtEstimates,
                this.titleFontSize,
                this.headerCellHeight,
                this.kdSummaryHeaderColspan,
                this.blueBorderBottom,
                this.krollblue
            ),
            ...this.getKdEstimates(),
            ...this.getEmptyRow(),
            ...this.getHeader(
                SummaryTabs.InternationalFisherEffect,
                this.headerFontSize,
                this.headerCellHeight,
                this.kdSummaryHeaderColspan,
                this.blueBorderBottom,
                this.krollblue
            ),
            ...this.getKdFisherEffect(),
            ...this.getEmptyRow(),
            ...this.getHeader(
                SummaryTabs.CostOfDebtConclusion,
                this.headerFontSize,
                this.headerCellHeight,
                this.kdSummaryHeaderColspan,
                this.blueBorderBottom,
                this.krollblue
            ),
            ...this.getCostOfDebtConclusion()
        ];
    }

    private getKdEstimates(): WorkbookSheetRow[] {
        if (this.summary.CostOfDebtInputsCountry == null
            || this.summary.InvesteeCountryName == null
            || this.summary.CashFlowCountry == null
        ) {
            return [];
        }

        let conclusionHeader: WorkbookSheetRow[] = [];
        if (this.summary.CostOfDebtInputsCountry.Currency.CurrencyId !== this.summary.CashFlowCountry.Currency.CurrencyId) {
            conclusionHeader = this.getHeader(
                SummaryText.PreFinalConclusion,
                this.headerFontSize,
                this.headerCellHeight,
                this.kdSummaryHeaderColspan,
                this.shadowBorderBottom,
                this.krollblue
            );
        }
        const equations = EquationGroupUtil.getCostOfDebtCapitalGroup(
            this.estimate,
            this.summary.CostOfDebtInputsCountry,
            this.summary.InvesteeCountryName,
            this.countryCreditRating
        );

        return [
            ...conclusionHeader,
            ...this.equationGroupToWorkbookSheetRow(equations, this.kdSummaryHeaderColspan)
        ];
    }

    private getKdFisherEffect(): WorkbookSheetRow[] {
        if (this.summary.CostOfDebtInputsCountry == null || this.summary.CashFlowCountry == null) {
            return [];
        }

        if (this.summary.CashFlowCountry.Currency.CurrencyId === this.summary.CostOfDebtInputsCountry.Currency.CurrencyId) {
            return [...this.getFisherEffectNotNeededRow()];
        }

        const translatedCurrenciesLabel = SummaryText.translateCurrencies(
            this.summary.CostOfDebtInputsCountry.Currency.CurrencyAcronym,
            this.summary.CashFlowCountry.Currency.CurrencyAcronym
        );
        const equations = EquationGroupUtil.getCostOfDebtFisherEffectGroup(
            this.estimate,
            this.summary.CostOfDebtInputsCountry,
            this.summary.CashFlowCountry,
            this.countryCreditRating
        );

        return [
            ...this.getHeader(
                translatedCurrenciesLabel,
                this.headerFontSize,
                this.headerCellHeight,
                this.kdSummaryHeaderColspan,
                this.shadowBorderBottom,
                this.krollblue
            ),
            ...this.equationGroupToWorkbookSheetRow(equations, this.kdSummaryHeaderColspan)
        ];
    }

    private getCostOfDebtConclusion(): WorkbookSheetRow[] {
        if (this.summary.InvesteeCountryName == null
            || this.summary.CashFlowCurrency == null
            || this.summary.CashFlowCountry == null
            || this.summary.CostOfDebtInputsCountry == null
        ) {
            return [];
        }

        if (this.summary.CostOfDebtInputsCountry.CountryId === this.estimate.InvesteeCountryId) {
            const equations = EquationGroupUtil.getCostOfDebtConclusionGroup(
                this.estimate,
                this.summary.CashFlowCountry,
                this.summary.CostOfDebtInputsCountry
            );

            return [...this.equationGroupToWorkbookSheetRow(equations, this.kdSummaryHeaderColspan)];
        }

        const countryPremiaTable = CrpTableUtil.getKdCountryPremiaTable(
            this.scenario,
            this.countryCreditRating,
            this.summary.InvesteeCountryName,
            this.summary.CashFlowCurrency,
            this.summary.CashFlowCountry.Currency.CurrencyId,
            this.summary.CostOfDebtInputsCountry.Currency.CurrencyId
        );
        const avgMedianCrpTable = RegionTableUtil.getAvgMedianCrpTable(
            this.countryRiskPremia,
            this.estimate,
            this.summary.CashFlowCountry.Currency.CurrencyId,
            this.summary.CostOfDebtInputsCountry.Currency.CurrencyId,
            SummaryType.CostOfDebt
        );
        const comparisonTable = RegionTableUtil.getRegionPremiaTable(avgMedianCrpTable, this.scenario, true);

        return [
            ...this.tableDataToWorkbookSheetRow([countryPremiaTable]),
            ...this.getHeader(CrpTableText.CostOfDebtCapitalComparisons, this.titleFontSize, this.headerCellHeight, this.ccrColumnNumber),
            ...this.getEmptyRow(),
            ...this.tableDataToWorkbookSheetRow(comparisonTable)
        ];
    }

    private getWaccRows(): WorkbookSheetRow[] {
        return [
            ...this.getHeader(
                SummaryTabs.WaccEstimates,
                this.titleFontSize,
                this.headerCellHeight,
                this.waccSummaryHeaderColspan,
                this.blueBorderBottom,
                this.krollblue
            ),
            ...this.getWaccEstimates(),
            ...this.getEmptyRow(),
            ...this.getHeader(
                SummaryTabs.WaccConclusion,
                this.titleFontSize,
                this.headerCellHeight,
                this.waccSummaryHeaderColspan,
                this.blueBorderBottom,
                this.krollblue
            ),
            ...this.getWaccConclusion()
        ];
    }

    private getWaccEstimates(): WorkbookSheetRow[] {
        if (this.summary.CashFlowCountry == null
            || this.summary.CountryOfInputs == null
            || this.summary.CostOfDebtInputsCountry == null
            || this.summary.InvesteeCountryName == null
        ) {
            return [];
        }

        const equations = EquationGroupUtil.getWaccCapitalGroup(
            this.estimate,
            this.countryCreditRating,
            this.summary.CashFlowCountry,
            this.summary.CountryOfInputs,
            this.summary.CostOfDebtInputsCountry
        );

        return [
            ...this.getHeader(
                SummaryText.investeeCountry(this.summary.InvesteeCountryName, true),
                this.headerFontSize,
                this.headerCellHeight,
                this.waccSummaryHeaderColspan,
                this.shadowBorderBottom,
                this.krollblue
            ),
            ...this.equationGroupToWorkbookSheetRow(equations, this.waccSummaryHeaderColspan)
        ];
    }

    private getWaccConclusion(): WorkbookSheetRow[] {
        if (this.summary.CashFlowCountry == null
            || this.summary.InvesteeCountryName == null
            || this.summary.CountryOfInputs == null
            || this.summary.CostOfDebtInputsCountry == null
        ) {
            return [];
        }

        if (this.summary.CostOfDebtInputsCountry.CountryId === this.estimate.InvesteeCountryId) {
            const equations = EquationGroupUtil.getWaccConclusionGroup(this.estimate, this.summary.CountryOfInputs);

            return [...this.equationGroupToWorkbookSheetRow(equations, this.waccSummaryHeaderColspan)];
        }

        const countryPremiaTable = CrpTableUtil.getWaccCountryPremiaTable(
            this.scenario,
            this.countryCreditRating,
            this.summary.InvesteeCountryName,
            this.summary.CashFlowCountry.Currency.CurrencyAcronym
        );
        const waccConclusionCrpTables = RegionTableUtil.buildWaccConclusionCrpTables(
            this.estimate,
            this.countryRiskPremia,
            this.summary.CashFlowCountry.Currency.CurrencyId,
            this.summary.CountryOfInputs.Currency.CurrencyId,
            this.summary.CostOfDebtInputsCountry.Currency.CurrencyId
        );

        return [
            ...this.tableDataToWorkbookSheetRow([countryPremiaTable]),
            ...this.getHeader(CrpTableText.WaccComparisons, this.titleFontSize, this.headerCellHeight, this.ccrColumnNumber),
            ...this.getEmptyRow(),
            ...this.tableDataToWorkbookSheetRow(waccConclusionCrpTables)
        ];
    }

    private getSelectionLogRows(): WorkbookSheetRow[] {
        const selectionLog: SelectionLogGroup[] = SelectionLogUtil.convertToSelectionLogEntities(this.summary);
        const rows: WorkbookSheetRow[] = [];

        for (const group of selectionLog) {
            const groupTitleRow: WorkbookSheetRow = {
                height: this.mediumCellHeight,
                cells: [{
                    value: group.name,
                    bold: true,
                    colSpan: 2,
                    borderBottom: this.blueBorderBottom,
                    fontSize: this.headerFontSize,
                    color: this.krollblue
                }]
            };
            rows.push(groupTitleRow);

            for (const member of group.members) {
                if (member.name == null || member.value == null) {
                    continue;
                }

                const memberRow: WorkbookSheetRow = {
                    cells: [
                        { value: member.name, bold: true, },
                        { value: member.value }
                    ]
                };
                rows.push(memberRow);

                if (member.properties == null) {
                    continue;
                }

                for (const property of member.properties) {
                    if (property.value == null) {
                        continue;
                    }

                    const propertyRow: WorkbookSheetRow = {
                        cells: [
                            { value: property.name, bold: true },
                            { value: property.value }
                        ]
                    };
                    rows.push(propertyRow);
                }
            }

            const emptyRow: WorkbookSheetRow = {
                cells: [{ value: '' }]
            };
            rows.push(emptyRow);
        }

        return rows;
    }

    private getHeader(
        title: string,
        fontSize: number,
        height: number,
        colSpan: number,
        borderBottom?: WorkbookSheetRowCellBorderBottom,
        color?: string
    ): WorkbookSheetRow[] {
        return [{ height, cells: [{ value: title, bold: true, colSpan, borderBottom, fontSize, color }] }];
    }

    private getEmptyRow(): WorkbookSheetRow[] {
        return [{ cells: [{ value: '' }] }];
    }

    private getFisherEffectNotNeededRow(): WorkbookSheetRow[] {
        return [{ cells: [{ value: 'The International Fisher Effect is not needed.' }] }];
    }

    private getDynamicTextSummary(): WorkbookSheetRow[] {
        return [
            ...this.getHeader(
                TabName.InternationalInputs,
                this.titleFontSize,
                this.headerCellHeight,
                this.dynamictextColumnNumber,
                this.blueBorderBottom,
                this.krollblue
            ),
            ...this.dynamicTextToWorkbookSheetRow(this.dynamicTextSummary.GeneralInputsText),
            ...this.getEmptyRow(),
            ...this.getHeader(
                TabName.WaccInputs,
                this.titleFontSize,
                this.headerCellHeight,
                this.dynamictextColumnNumber,
                this.blueBorderBottom,
                this.krollblue
            ),
            ...this.dynamicTextToWorkbookSheetRow(this.dynamicTextSummary.KdAndWaccInputsText),
            ...this.getEmptyRow(),
            ...this.getHeader(
                TabName.CountryRiskPremiaSummary,
                this.titleFontSize,
                this.headerCellHeight,
                this.dynamictextColumnNumber,
                this.blueBorderBottom,
                this.krollblue
            ),
            ...this.dynamicTextToWorkbookSheetRow(this.dynamicTextSummary.CrpSummaryText),
            ...this.getEmptyRow(),
            ...this.getHeader(
                TabName.CostOfEquitySummary,
                this.titleFontSize,
                this.headerCellHeight,
                this.dynamictextColumnNumber,
                this.blueBorderBottom,
                this.krollblue
            ),
            ...this.dynamicTextToWorkbookSheetRow(this.dynamicTextSummary.CostOfEquitySummaryCapmText),
            ...this.dynamicTextToWorkbookSheetRow(this.dynamicTextSummary.CostOfEquitySummaryFisherEffectText),
            ...this.dynamicTextToWorkbookSheetRow(this.dynamicTextSummary.CostOfEquitySummaryConclusionText),
            ...this.getEmptyRow(),
            ...this.getHeader(
                TabName.CostOfDebtSummary,
                this.titleFontSize,
                this.headerCellHeight,
                this.dynamictextColumnNumber,
                this.blueBorderBottom,
                this.krollblue
            ),
            ...this.dynamicTextToWorkbookSheetRow(this.dynamicTextSummary.CostOfDebtSummaryCapitalText),
            ...this.dynamicTextToWorkbookSheetRow(this.dynamicTextSummary.CostOfDebtSummaryFisherEffectText),
            ...this.dynamicTextToWorkbookSheetRow(this.dynamicTextSummary.CostOfDebtSummaryConclusionText),
            ...this.getEmptyRow(),
            ...this.getHeader(
                TabName.WaccSummary,
                this.titleFontSize,
                this.headerCellHeight,
                this.dynamictextColumnNumber,
                this.blueBorderBottom,
                this.krollblue
            ),
            ...this.dynamicTextToWorkbookSheetRow(this.dynamicTextSummary.WaccSummaryCapitalText),
            ...this.dynamicTextToWorkbookSheetRow(this.dynamicTextSummary.WaccSummaryConclusionText)
        ];
    }

    private dynamicTextToWorkbookSheetRow(dynamicText: DynamicText[] | undefined): WorkbookSheetRow[] {
        if (dynamicText == null) {
            return [];
        }

        const rows: WorkbookSheetRow[] = [];
        for (const text of dynamicText) {
            rows.push({ cells: [{ value: text.longText.title, bold: true }] });
            rows.push({ cells: [{ value: text.longText.excelText, wrap: true }] });
        }

        return rows;
    }

    public save(component: ExcelExportComponent): void {
        combineLatest([
            this.estimateSelector,
            this.estimateSummarySelector,
            this.ccrSelector,
            this.countryRiskPremiaSelector,
            this.dynamicTextSummarySelector
        ]).pipe(
            map((x) => ({
                estimate: x[0],
                estimateSummary: x[1],
                ccr: x[2],
                crp: x[3],
                dynamicTextSummary: x[4]
            }))
        ).onceDefined((data) => {
            if (data.estimate == null
                || data.estimateSummary == null
                || data.ccr == null
                || data.crp == null
                || data.dynamicTextSummary == null
            ) {
                return;
            }

            this.selections = data.estimate.Scenarios[0].Selections;
            this.summary = data.estimateSummary;
            this.countryCreditRating = data.ccr;
            this.countryRiskPremia = data.crp;
            this.scenario = data.estimate.Scenarios[0];
            this.estimate = data.estimate;
            this.dynamicTextSummary = data.dynamicTextSummary;
            let excelExport = this.createWorksheets();
            excelExport = this.tearSheetService.setDefaultExcel(excelExport);
            excelExport = CommonExcelExportUtil.getUpdatedCopyRigtsSheet(excelExport);
            component.save(excelExport);
        });
    }


    public getAboutSheetRows(): WorkbookSheetRow[] {


        const Aboutsheetrows: WorkbookSheetRow[] = [
            {
                cells: [this.styleAboutHeaders('Source', 0)]
            },
            {
                cells: [{
                    color: this.krollshadow,
                    value: 'Kroll Cost of Capital Navigator: International Cost of Capital Dataset'

                }]
            },
            {
                cells: [{
                    color: this.krollshadow,
                    value: 'Exported on' + ": " + moment().format('MM/DD/YYYY')
                }]
            }, {

            },
            {
                cells: [this.styleAboutHeaders('Data Sources', 0)]
            },
            {
                cells: [{
                    color: this.krollshadow,
                    value: 'Data Sources used with permission. All rights reserved. Calculations performed by Kroll, LLC.'
                }]
            }
        ]

        return Aboutsheetrows;

    }

    private styleAboutHeaders(title: string, colSpan: number): WorkbookSheetRowCell {

        const headerCell: WorkbookSheetRowCell = {
            value: title,
            colSpan: colSpan,
            color: this.krollblue,
            bold: true,
            fontSize: 18.5,
            verticalAlign: 'center'
        }

        return headerCell;
    }
}
