import { formatNumber } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'formatNumberWithComma' })
export class FormatNumberWithCommaPipe implements PipeTransform {
    public transform(value: any): any {
        if (value === null || value === "-") {
            return "-";
        }

        return formatNumber(Number(value), 'en-US', '0.2-2');
    }
}
