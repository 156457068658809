import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SubscriberEntity } from '@concurrency/angular';
import { takeUntil } from 'rxjs/operators';

enum Tab { Capm, Buildup }

@Component({ templateUrl: './crsp.component.html' })
export class CrspComponent extends SubscriberEntity implements OnInit {
    public activeTab: Tab = Tab.Capm;
    public Tab = Tab;

    constructor(
        private router: Router
    ) { super(); }

    public ngOnInit(): void {
        const setupTab = () => {
            if (this.router.url.includes('/estimate/studies/crsp/buildup')) {
                this.activeTab = Tab.Buildup;
            } else {
                this.activeTab = Tab.Capm;
            }
        };

        setupTab();
        // TODO: Obviate takeUntil by using Async pipes and local Observable streams
        this.router.events.pipe(takeUntil(this.destroyed)).while(() => setupTab());
    }

    public routeTo(study: Tab): void {
        this.activeTab = study;

        switch (study) {
            case Tab.Buildup:
                this.router.navigate([`/estimate/studies/crsp/buildup`]);
                break;
            case Tab.Capm:
            default:
                this.router.navigate([`/estimate/studies/crsp/capm`]);
        }
    }
}
