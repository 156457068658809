import { Injectable } from '@angular/core';
import { ApiClient } from '@concurrency/angular';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AcceptVersionData, TermsAndConditionsVersions, UserActivityRequest, UserResponse } from '../responses/user.response';
import { Auth0ApiClient } from '../api.client';

@Injectable()
export class UserClient extends Auth0ApiClient {
    protected baseUrl = `${environment.urls.navigatorApi}/api`;

    public read(): Observable<UserResponse> {
        return this.get<UserResponse>('UserProfile');
    }

    public acceptTerms(AcceptData: AcceptVersionData): Observable<UserResponse> {
        return this.post(`UserProfile/AcceptTerms?marketingOptIn=${AcceptData.marketingOptin}&version=${AcceptData.versonNumber}`, {});
    }

    public productNewsReadStatusUpdate(productNewsId: string, bannerStatus: boolean): Observable<UserResponse> {
        return this.post(`UserProfile/ProductNews?productNewsId=${productNewsId}&bannerStatus=${bannerStatus}`, {});
    }

    public productNewsReadStatusGet(): Observable<boolean> {
        return this.get(`UserProfile/ProductNewsReadStatus`);
    }

    public userActivityLog(userActivity: UserActivityRequest): Observable<any> {
        return this.post(`UserProfile/UserActivityLog`, userActivity);
    }

    public getTermsAndCondition(): Observable<TermsAndConditionsVersions> {
        return this.get(`UserProfile/GetTermsandConditionData`);
    }

}
