import { Component, Input } from '@angular/core';
import { SubscriberEntity } from '@concurrency/angular';
import { DynamicText } from './model/dynamic-text';

@Component({
    selector: 'dynamic-text',
    templateUrl: './dynamic-text.component.html'
})
export class DynamicTextComponent extends SubscriberEntity {

    @Input() public dynamicText!: DynamicText[];
    constructor() {
        super();
    }
}
