export enum ProductType {
    Coc = 'Coc',
    Usi = 'Usi',
    Ine = 'Ine',
    Ini = 'Ini',
    Bta = 'Bta'
}



