import { Injectable } from '@angular/core';
import { Workbook } from '@progress/kendo-ooxml';
import * as moment from 'moment';
import { IndustryTearSheet } from 'src/app/_api/responses/industry-tear-sheet.response';
import { DataStore } from 'src/app/_navigator/data/store/data.store';
import { SubscriptionData } from 'src/app/_navigator/user/model/subscription.model';
import { TrendsOverTimeIndustry } from 'src/app/benchmarking/data/trends-over-time-industry';
import { Notices } from '../const/notices.const';
import { TearSheetContent } from '../models/tear-sheet-content.model';
import { MetricsUtil } from '../util/metrics.util';
import { StatisticsUtil } from '../util/statistics.util';

@Injectable()
export class TearSheetService {
    private _tearSheetContent!: TearSheetContent;
    private _tearSheetData: IndustryTearSheet[] = [];
    private _tearSheetTrendsOverTimeIndustry: TrendsOverTimeIndustry[] = [];
    private _isTearSheetReady = false;
    private _notice: Notices | null = null;
    private _tearSheetUserSubscription: SubscriptionData | undefined;
    public tabIndex: number = 0;

    public tearSheetIndex = 0;

    constructor(private dataStore: DataStore) { }

    public setTearSheetContent(data: IndustryTearSheet): void {
        if (data.Metrics.length === 0) {
            this._notice = Notices.NoAnalysis;
            return;
        }

        this._tearSheetContent = {
            DataThroughDate: moment(data.DataAsOf).format('LL'),
            SicId: data.SicId,
            SicCode: data.SicCode,
            GicId: data.GicId,
            GicCode: data.GicCode,
            Currency: data.Currency,
            Region: data.Region,
            CompositeData: {
                numberOfCompanies: data.NumberOfCompanies,
                largeComposite: data.LargeComposite,
                smallComposite: data.SmallComposite,
                highFinancialRisk: data.HighFinancialRisk
            },
            Sector: data.Sector,
            StatisticsCategory: StatisticsUtil.getFlattendData(data.Metrics),
            MetricRows: !!data.SicCode
                ? MetricsUtil.getFlattenData(data.Metrics, data.SicCode, data.DataAsOf, this._tearSheetUserSubscription, data.Region)
                : MetricsUtil.getFlattenData(data.Metrics, data.GicCode, data.DataAsOf, this._tearSheetUserSubscription, data.Region),
            Companies: data.Companies,
            IndustryAssumptions: data.IndustryAssumptions

        };

        this._isTearSheetReady = true;
        this._notice = null;
    }

    public setTrendsOverTimeIndustry(data: IndustryTearSheet[]): void {
        this._tearSheetTrendsOverTimeIndustry = data.map((industry) => {
            return {
                IndustryCodeId: industry.GicId ? industry.GicId : industry.SicId,
                ValuationDate: moment(industry.DataAsOf).format('LL'),
                CurrencyCode: industry.Currency,
                Area: industry.Region,
                Sector: industry.Sector,
                TrendsOverTimeDisplay: true,
                SicId: industry.SicCode,
                GicId: industry.GicCode
            };
        });
    }


    public onClickIndustryTab(index: number): void {
        this.setTearSheetContent(this._tearSheetData[index]);
    }

    public scrollRight(): void {
        if (this.tearSheetIndex === this._tearSheetData.length - 1) {
            return;
        }

        this.dataStore.setCanScrollLeft(true);

        this.tearSheetIndex++;
        this.setTearSheetContent(this._tearSheetData[this.tearSheetIndex]);

        if (this.tearSheetIndex === this._tearSheetData.length - 1) {
            this.dataStore.setCanScrollRight(false);
        }
    }

    public scrollLeft(): void {
        if (this.tearSheetIndex === 0) {
            return;
        }

        this.dataStore.setCanScrollRight(true);

        this.tearSheetIndex--;
        this.setTearSheetContent(this._tearSheetData[this.tearSheetIndex]);

        if (this.tearSheetIndex === 0) {
            this.dataStore.setCanScrollLeft(false);
        }
    }

    public setIsTearSheetReady(isReady: boolean): void {
        this._isTearSheetReady = isReady;
    }

    public setTearSheetUserSubscription(userSubscription: SubscriptionData | undefined): void {
        this._tearSheetUserSubscription = userSubscription;
    }

    public setNotice(notice: Notices | null): void {
        this._notice = notice;
    }

    public setTearSheetData(tearSheetData: IndustryTearSheet[]): void {
        this._tearSheetData = tearSheetData;
        this.tabIndex = 0;
    }

    public getTrendsOverTimeIndustry(): TrendsOverTimeIndustry[] {
        return this._tearSheetTrendsOverTimeIndustry;
    }

    public getIsTearSheetReady(): boolean {
        return this._isTearSheetReady;
    }

    public getTearSheetContent(): TearSheetContent {
        return this._tearSheetContent;
    }

    public getNotice(): Notices | null {
        return this._notice;
    }

    public getTearSheetData(): IndustryTearSheet[] {
        return this._tearSheetData;
    }
    public setFontFamily(excelWorkBook: Workbook): Workbook {
        excelWorkBook.sheets.forEach((sheet) => {
            if (sheet.rows) {
                sheet.rows.forEach((row) => {
                    if (row.cells) {
                        row.cells.forEach((cell) => {
                            cell.fontFamily = 'Arial';

                        });
                    }
                });
            }
        });
        return excelWorkBook;
    }


    public setCellColor(excelWorkBook: Workbook): Workbook {
        excelWorkBook.sheets.forEach((sheet) => {
            if (sheet.rows) {
                sheet.rows.forEach((row) => {
                    if (row.cells) {
                        row.cells.forEach((cell) => {
                            if (!cell.color) {
                                cell.color = '#4d4d4f';
                            }
                        });
                    }
                });
            }
        });
        return excelWorkBook;
    }
    public setDefaultExcel(excelWorkBook: Workbook): Workbook {
        excelWorkBook = this.setFontFamily(excelWorkBook);
        excelWorkBook = this.setCellColor(excelWorkBook);
        return excelWorkBook;
    }




}
