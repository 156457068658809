import { SummaryOptions } from '../types/summary-options';
import { headerCellHeight, headerFontSize, krollblue, krollshadow } from '../util/summary-helpers';

export function keHFRRows(options: SummaryOptions, displayNotes: boolean): any {
    const rows: any[] = [];

    return rows.concat([
            {
                height: headerCellHeight,
                cells: [
                    {
                        value: 'CAPM + HFR Size Premium',
                        color: krollblue,
                        fontSize: headerFontSize,
                        bold: 'true',
                        borderBottom: { size: 1, color: krollshadow },
                        colSpan: 10
                    }
                ]
            },
            {
                cells: [
                    { value: 'Risk Measure', bold: 'true' },
                    { value: 'Ke', bold: 'true' },
                    { value: '=', bold: 'true' },
                    { value: 'Rf', bold: 'true' },
                    { value: '+', bold: 'true' },
                    { value: 'B', bold: 'true' },
                    { value: '*', bold: 'true' },
                    { value: 'ERP', bold: 'true' },
                    { value: '+', bold: 'true' },
                    { value: 'RPs', bold: 'true' },
                    options.scenarioHelper.createNoteHeaderRow(displayNotes)
                ]
            }
        ]).concat(options.scenarioHelper.createScenarioRowsHFRCAPM(
            options.summary.highFinancialRisk.studyGroups,
            options.summary.HFRSelectionType,
            displayNotes
        ))
            .concat(
                {
                    height: headerCellHeight,
                    cells: [
                        {
                            value: 'Build-up HFR',
                            color: krollblue,
                            fontSize: headerFontSize,
                            bold: 'true',
                            borderBottom: { size: 1, color: krollshadow },
                            colSpan: 10
                        }
                    ]
                },
                // Data Rows
                {
                    cells: [
                        { value: 'Risk Measure', bold: 'true' },
                        { value: 'Ke', bold: 'true' },
                        { value: '=', bold: 'true' },
                        { value: 'Rf', bold: 'true' },
                        { value: '+', bold: 'true' },
                        { value: 'RPm+s', bold: 'true' },
                        { value: '+', bold: 'true' },
                        { value: 'ERP Adjustment', bold: 'true' },
                        options.scenarioHelper.createNoteHeaderRow(displayNotes)
                    ]
                }
        ).concat(options.scenarioHelper.createScenarioRowsHFRBuildUp(
            options.summary.highFinancialRisk.studyGroups,
            options.summary.HFRSelectionType,
            displayNotes
        ));
}
