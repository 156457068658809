import { Component, Input } from '@angular/core';
import { Equation, EquationParameter } from '../../data/model/equation.model';

@Component({
    selector: 'equation-key',
    templateUrl: './equation-key.component.html'
})
export class EquationKeyComponent {
    @Input() public equations: Equation[] = [];

    public getParameters(): EquationParameter[] {
        if (this.equations && this.equations[0]) {
            return this.equations[0].parameters;
        }
        return [];
    }
}
