import { NgModule } from '@angular/core';
import { DialogModule } from 'primeng/dialog';
import { CommonModules } from '../_config/common-modules';
import { CostOfDebtSummaryComponent } from './cod-summary.component';
import { CodSummaryCapitalComponent } from './cod-summary/cod-summary-capital.component';
import { CodSummaryConclusionComponent } from './cod-summary/cod-summary-conclusion.component';
import { CodSummaryFisherEffectComponent } from './cod-summary/cod-summary-fisher-effect.component';
import { CostOfEquityInputsComponent } from './coe-inputs.component';
import { CostOfEquitySummaryComponent } from './coe-summary.component';
import { CoeSummaryCapmComponent } from './coe-summary/coe-summary-capm.component';
import { CoeSummaryConclusionComponent } from './coe-summary/coe-summary-conclusion.component';
import { CoeSummaryFisherEffectComponent } from './coe-summary/coe-summary-fisher-effect.component';
import { CrpSummaryComponent } from './crp-summary.component';
import { InternationalEstimateService } from './data/service/international-estimate.service';
import { InternationalQueryBuilderService } from './data/service/international-query-builder.service';
import { DynamicTextComponent } from './dynamic-text/dynamic-text.component';
import { EstimateSummaryComponent } from './dynamic-text/estimate-summary.component';
import { InternationalEstimateExcelExportService } from './export/international-estimate-excel-export.service';
import { FisherEffectComponent } from './fisher-effect.component';
import { InterAddIndustriesComponent } from './inter-add-industries/inter-add-industries.component';
import { InterChildIndustriesComponent } from './inter-child-industries/inter-child-industries.component';
import { InternationalEstimateComponent } from './international-estimate.component';
import { InternationalInputsComponent } from './international-inputs.component';
import { WaccInputsComponent } from './wacc-inputs.component';
import { WaccSummaryComponent } from './wacc-summary.component';
import { WaccSummaryCapitalComponent } from './wacc-summary/wacc-summary-capital.component';
import { WaccSummaryConclusionComponent } from './wacc-summary/wacc-summary-conclusion.component';

export const InternationalEstimateDefinition: NgModule = {
    declarations: [
        CodSummaryCapitalComponent,
        CodSummaryConclusionComponent,
        CodSummaryFisherEffectComponent,
        CoeSummaryCapmComponent,
        CoeSummaryConclusionComponent,
        CoeSummaryFisherEffectComponent,
        CostOfDebtSummaryComponent,
        CostOfEquityInputsComponent,
        CostOfEquitySummaryComponent,
        CrpSummaryComponent,
        DynamicTextComponent,
        EstimateSummaryComponent,
        FisherEffectComponent,
        InternationalEstimateComponent,
        InternationalInputsComponent,
        WaccInputsComponent,
        WaccSummaryComponent,
        WaccSummaryCapitalComponent,
        WaccSummaryConclusionComponent,
        InterAddIndustriesComponent,
        InterChildIndustriesComponent
    ],
    entryComponents: [
        InterAddIndustriesComponent,
        InterChildIndustriesComponent
    ],
    imports: [
        ...CommonModules,
        DialogModule
    ],
    providers: [
        InternationalQueryBuilderService,
        InternationalEstimateService,
        InternationalEstimateExcelExportService
    ]
};

@NgModule(InternationalEstimateDefinition)
export class InternationalEstimateModule { }
