import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Observable } from 'rxjs/internal/Observable';
import { CostOfEquityClient } from 'src/app/_api/clients/costOfEquity.client';
import { CountryRiskPremia } from 'src/app/_api/responses/country-risk-premia.response';
import { GetCountryRiskPremiaList } from './country-risk-premia-list-actions';
import { Injectable } from '@angular/core';

export type CountryRiskPremiaListModel = CountryRiskPremia[] | undefined;

@State<CountryRiskPremiaListModel>({
    name: 'countryRiskPremiaList',
    defaults: undefined
})


@Injectable()
export class CountryRiskPremiaListState {

    @Selector()
    public static get(state: CountryRiskPremiaListModel): CountryRiskPremia[] | undefined {
        return state;
    }
    constructor(private costOfEquityClient: CostOfEquityClient) { }

    @Action(GetCountryRiskPremiaList)
    public getCountryRiskPremiaList(context: StateContext<CountryRiskPremiaListModel>, action: GetCountryRiskPremiaList): Observable<void> {
        return this.costOfEquityClient
            .readCountryRiskPremia(action.inputCountryId, action.investeeCountryId, action.dataAsOf)
            .once((response) => {
                context.setState(response);
            });
    }
}
