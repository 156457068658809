import { Component, OnInit, ViewChild } from '@angular/core';
import { Dialog } from 'primeng/dialog';
import { of } from 'rxjs';
import { Gic } from 'src/app/_api/responses/gic.response';
import { IndustryDataSharingService } from 'src/app/estimate/studies/service/industry-data-sharing.service';
import { InternationalQueryBuilderService } from '../data/service/international-query-builder.service';

@Component({
    selector: 'inter-add-industries',
    templateUrl: './inter-add-industries.component.html',
    styleUrls: ['./inter-add-industries.component.scss']
})
export class InterAddIndustriesComponent implements OnInit {

    public display = true;
    public searchTerm = '';
    public allIndustries!: Gic[];
    public dialogHeader = 'Select an Industry (choose 1)';
    public totalIndustries!: number;
    public maximumNumberOfIndustries = 1;
    public userSelectedIndustries: Gic[] = new Array<Gic>();
    public userSelectedGicId: any;
    public _check!: string;

    @ViewChild(Dialog) public dialog!: Dialog;

    constructor(
        public industryDataSharingService: IndustryDataSharingService,
        public internationalQueryBuilderService: InternationalQueryBuilderService) {
    }

    public ngOnInit(): void {
        if (this.allIndustries.length > 0) {
            this.totalIndustries = this.allIndustries.length;
        }
        this.allIndustries.forEach((x) => {
            x.IsSelected = false;
            x.IsDisabled = false;
        });
    }

    public onTypeAheadChange(value: string): void {
        this.internationalQueryBuilderService.IndustriesTypeahead(of(value))
            .onceDefined((results) => {
                if (results.length > 0) {
                    this.allIndustries = results;
                } else if (!results.length) {
                    this.allIndustries = this.internationalQueryBuilderService.industries;
                }
            });
    }

    public checkCheckBoxvalue = ($event: any): void => {
        const checkValues = $event.target.value;
        const codeId = $event.target.id;
        const isChecked = $event.target.checked;
        let tempArray: any;
        tempArray = checkValues.split('-');

        if (isChecked) {
            this.userSelectedIndustries.push({
                GicId: codeId,
                GicCode: tempArray[0],
                GicDescription: tempArray[1],
                IsSelected: true
            });

            this.allIndustries = this.internationalQueryBuilderService.industries;
            this.allIndustries.forEach((x) => {
                this.userSelectedIndustries.forEach((y) => {
                    if (Number(x.GicId) === Number(y.GicId)) {
                        x.IsSelected = true;
                    }
                });
            });

            this.searchTerm = '';
            this.onTypeAheadChange('');
        } else {
            this.userSelectedIndustries = this.userSelectedIndustries.filter((item) => Number(item.GicId) !== Number(codeId));
        }
        this.disabledRemainingCheckbox(this.userSelectedIndustries);
    }

    public disabledRemainingCheckbox = (codeId: Gic[]): any => {
        const indCodeId = codeId.map((item) => Number(item.GicId));
        if (this.userSelectedIndustries.length >= 1) {
            this.allIndustries.forEach((x) => {
                if (indCodeId.indexOf(x.GicId) === -1) {
                    x.IsDisabled = true;
                }
            });
        } else {
            this.allIndustries.forEach((x) => {
                x.IsDisabled = false;
            });
        }
    }

    public isDisableSubmit(): boolean {
        if (this.userSelectedIndustries.length > 0) {
            return false;
        } else {
            return true;
        }
    }

    public removeSelectedIndustryFromChild = ($event: any): any => {
        this.searchTerm = '';
        this.onTypeAheadChange('');

        this.allIndustries.forEach((x) => {
            if (Number(x.GicId) === Number($event.GicId)) {
                x.IsSelected = false;
            }
            x.IsDisabled = false;
        });
        this.userSelectedIndustries = this.userSelectedIndustries.filter((item) => Number(item.GicId) !== Number($event.GicId));
    }

    public addIndustries = (addIndustries: Gic[]): void => {
        this.industryDataSharingService.setSharedGicIndustries = addIndustries;
        this._check = 'button';
        this.display = false;
    }
}
