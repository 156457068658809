import { Component } from '@angular/core';
import { ContextualNumber, Util } from '@concurrency/core';
import { Observable, of } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SelectionType } from 'src/app/_api/enums/selection-type';
import { InputType } from 'src/app/_api/responses/input.response';
import { CommonInputConfig } from 'src/app/_navigator/common/inputs/common-user-input.component';
import { DataStore } from 'src/app/_navigator/data/store/data.store';
import { ErrorService } from 'src/app/_navigator/error/error.service';
import { HelpText } from 'src/app/_navigator/help/help-text';
import { EditorComponent } from './editor.component';

// TODO: This and IRP Editor seem poorly thought out
@Component({ templateUrl: './equity-risk-adjustment-editor.component.html' })
export class EquityRiskAdjustmentEditorComponent extends EditorComponent {
    public equityRiskPremium!: ContextualNumber;
    public initialErp!: ContextualNumber;

    public inputConfig: CommonInputConfig = {
        name: 'Equity Risk Premium (%)',
        help: HelpText.EquityRiskPremiumDetailed,
        error: this.errorService.equityRiskPremium,
        minimumValue: 0,
        maximumValue: 10,
        pattern: '(.*?)',
        autofocus: true
    };

    constructor(
        protected dataStore: DataStore,
        private errorService: ErrorService
    ) { super(dataStore); }

    public initialize(): void {
        // TODO: Obviate takeUntil by using Async pipes and local Observable streams
        this.dataStore.recalculate.pipe(takeUntil(this.destroyed)).while(() => this.mask());
        this.equityRiskPremium = this.settings.scenario.getSelectionAsContextualNumber(SelectionType.EquityRiskPremium, InputType.None);
        this.initialErp = this.equityRiskPremium.clone();
        const riskFreeRate = this.settings.scenario.getSelection(SelectionType.RiskFreeRate, InputType.None);
        if (riskFreeRate.Context === 'Duff & Phelps Normalized Rate' || riskFreeRate.Context === 'Kroll Normalized Risk-free Rate') {
            this.inputConfig.readonly = true;
        }
        this.mask();
    }

    public mask(): void {
        const equityRiskPremiumAdjustment = this.settings.scenario.getSelection(SelectionType.EquityRiskPremiumAdjustment, InputType.None);

        this.inputConfig.mask =
            `{}% - ${this.equityRiskPremium.context} ` + // `
            `(ERP Adjustment = ${Util.round(equityRiskPremiumAdjustment.Value || 0)}%)`; // `
    }

    public update(): Observable<void> {
        this.settings.scenario.setSelection(this.equityRiskPremium, SelectionType.EquityRiskPremium, InputType.None);
        this.dataStore.validateScenario(this.estimate, this.settings.scenario);
        const erpa = this.settings.scenario.getSelectionAsContextualNumber(this.settings.operand.selectionType, InputType.None);
        this.modelChange.emit(erpa);
        this.mask();

        // tslint-disable-next-line import/no-deprecated
        return of(void 0);
    }

    public save(): void {
    }

    public cancel(): void {
        this.equityRiskPremium = this.initialErp.clone();
        this.update();
    }
}
