import * as moment from 'moment';

import { Component, OnInit, ViewChild } from '@angular/core';
import { Dialog } from 'primeng/dialog';
import { of } from 'rxjs';
import { Industry } from 'src/app/_api/responses/us-industry.response';
import { DataStore } from 'src/app/_navigator/data/store/data.store';
import { TypeaheadFormatters } from 'src/app/_navigator/data/util/typeahead-formatters.util';
import { TrendsOverTimeService } from 'src/app/home/tear-sheet/data/trends-over-time.service';
import { IndustryDataSharingService } from '../../../../app/estimate/studies/service/industry-data-sharing.service';
import { InputsComponent } from '../../../estimate/inputs.component';
import { BenchmarkingQueryBuilderService } from '../../data/benchmarking-query-builder.service';


@Component({
    templateUrl: './add-industries.component.html',
    styleUrls: ['./add-industries.component.scss']
})

// class Constants {
//     public static readonly FilterIndustryCodeDate = '2020-09-30T12:00:00';
// }

export class AddIndustriesComponent implements OnInit {
    public moment = moment;
    public searchTerm = '';
    public allIndustries!: Industry[];
    public userSelectedIndustries!: Industry[];
    public notSelected!: Industry[];
    public filteredIndustries: Industry[] = new Array<Industry>();
    public moduleName!: string;
    public IndustriesFromDB: Industry[] = new Array<Industry>();
    public dialogHeader = 'Available Industries (Select up to 3)';
    public TypeaheadFormatters = TypeaheadFormatters;
    public maximumNumberOfIndustries = 3;
    public showAddIndustry = true;
    public totalIndustries!: number;
    public display = true;
    public _check!: string;
    public _inputClass!: InputsComponent;
    public valuationDate!: string
    public readonly FilterIndustryCodeDate = '2020-09-30T12:00:00';

    @ViewChild(Dialog) public dialog!: Dialog;

    constructor(
        protected dataStore: DataStore,
        public trendsOverTimeService: TrendsOverTimeService,
        public benchmarkingQueryBuilderService: BenchmarkingQueryBuilderService,
        public industryDataSharingService: IndustryDataSharingService
    ) { }



    public ngOnInit(): void {
        if (this.moduleName === 'USCOC' && this.allIndustries.length > 0) {
            this.allIndustries = this.benchmarkingQueryBuilderService.filterCOCIndustries(this.allIndustries);
            if (this.valuationDate) {
                const condition = this.valuationDate < this.FilterIndustryCodeDate;
                this.sortAllIdustries(condition ? true : false);
                // this.benchmarkingQueryBuilderService.industries = this.allIndustries;
            }
        } else {
            this.filteredIndustries = this.benchmarkingQueryBuilderService.filterIndustriesBasedOnGeographic(this.allIndustries);
        }

        this.totalIndustries = this.allIndustries.length;
        this.updateSelectedIndustries();
        this.userSelectedIndustries.forEach((item) => {
            this.IndustriesFromDB.push({
                CodeId: item.CodeId,
                GicIndustryCode: item.GicIndustryCode,
                Sector: item.Sector,
                SicIndustryCode: item.SicIndustryCode
            });
        });


    }


    public updateSelectedIndustries() {
        this.allIndustries.forEach((x) => {
            this.userSelectedIndustries.forEach((y) => {
                if (Number(x.CodeId) === Number(y.CodeId)) {
                    x.IsSelected = true;
                }
            });
        });


    }

    public onTypeAheadChange(value: string): void {
        this.benchmarkingQueryBuilderService.IndustriesZoneFilterTypeahead(of(value), this.moduleName, true)
            .onceDefined((results) => {
                if (results.length && this.moduleName === 'Benchmarking') {
                    this.filteredIndustries = results;
                } else if (results.length && this.moduleName === 'USCOC') {
                    this.allIndustries = results;
                    this.updateSelectedIndustries();
                } else if (!results.length && this.moduleName === 'USCOC') {
                    this.allIndustries = this.benchmarkingQueryBuilderService.industries;
                    this.updateSelectedIndustries();
                } else {
                    this.filteredIndustries = this.allIndustries;
                }
            });
    }

    public checkCheckBoxvalue = ($event: any): void => {
        const checkValues = $event.target.value;
        const codeId = $event.target.id;
        const isChecked = $event.target.checked;
        let tempArray: any;
        tempArray = checkValues.split('-');
        const SICGICCode = tempArray[0].split(' ');

        if (isChecked && this.userSelectedIndustries.length < this.maximumNumberOfIndustries) {
            this.userSelectedIndustries.push({
                CodeId: codeId,
                GicIndustryCode: SICGICCode[0] === 'GICS' ? SICGICCode[1] : '',
                SicIndustryCode: SICGICCode[0] === 'SIC' ? SICGICCode[1] : '',
                Sector: tempArray[1],
                IsSelected: true
            });

            this.allIndustries.forEach((x) => {
                this.userSelectedIndustries.forEach((y) => {
                    if (Number(x.CodeId) === Number(y.CodeId)) {
                        x.IsSelected = true;
                    }
                });
            });

            this.searchTerm = '';
            this.onTypeAheadChange('');
        } else {
            this.userSelectedIndustries = this.userSelectedIndustries.filter((item) => Number(item.CodeId) !== Number(codeId));
        }
        this.disabledRemainingCheckbox(this.userSelectedIndustries);
        // this.industryDataSharingService.setSharedIndustries = this.userSelectedIndustries;
    }

    public disabledRemainingCheckbox = (codeId: Industry[]): any => {
        const indCodeId = codeId.map((item) => Number(item.CodeId));
        if (this.userSelectedIndustries.length >= 3) {
            this.allIndustries.forEach((x) => {
                if (indCodeId.indexOf(x.CodeId) === -1) {
                    x.IsDisabled = true;
                }
            });
        } else {
            this.allIndustries.forEach((x) => {
                x.IsDisabled = false;
            });
        }
    }

    public isDisableSubmit(): boolean {
        if (this.userSelectedIndustries.length > 0) {
            return false;
        } else {
            return true;
        }
    }

    public removeSelectedIndustryFromChild = ($event: any): any => {
        this.searchTerm = '';
        this.onTypeAheadChange('');
        this.benchmarkingQueryBuilderService.industries.forEach((x) => {
            if (Number(x.CodeId) === Number($event.CodeId)) {
                x.IsSelected = false;
            }
            x.IsDisabled = false;
        });

        this.userSelectedIndustries = this.userSelectedIndustries.filter((item) => Number(item.CodeId) !== Number($event.CodeId));
    }

    public addIndustries = (addIndustries: Industry[]): void => {
        this.industryDataSharingService.setSharedIndustries = addIndustries;
        this._check = 'button';
        this.display = false;
    }

    public close(): void {
        if (this._check === undefined) {
            if (this.IndustriesFromDB.length > 0) {
                this.industryDataSharingService.setSharedIndustries = this.IndustriesFromDB;
            } else {
                const emptyIndustry: Industry[] = new Array<Industry>();
                emptyIndustry.push({
                    CodeId: 0,
                    GicIndustryCode: '',
                    SicIndustryCode: '',
                    Sector: ''
                } as Industry);
                this.industryDataSharingService.setSharedIndustries = emptyIndustry;
            }

        } else if (this._check === 'button') {
            this.display = false;
        }
    }

    private sortAllIdustries(isSic: boolean = false): void {
        if (isSic) {
            this.allIndustries = this.allIndustries.sort((a, b) => {
                for (let i = 0; i < Math.max(a.SicIndustryCode.length, b.SicIndustryCode.length); i++) {
                    const digitA = parseInt(a.SicIndustryCode[i]) || 0; // If a[i] is undefined, use 0
                    const digitB = parseInt(b.SicIndustryCode[i]) || 0; // If b[i] is undefined, use 0
                    if (digitA !== digitB) { return digitA - digitB; }
                }
                return a.SicIndustryCode.length - b.SicIndustryCode.length;
            });

            var stringSicCodeList = this.allIndustries.filter(s => !(!isNaN(+s.SicIndustryCode)));
            this.allIndustries = [...this.allIndustries.filter(s => !isNaN(+s.SicIndustryCode)), ...stringSicCodeList]


        } else {
            this.allIndustries = this.allIndustries.sort((a, b) => {
                for (let i = 0; i < Math.max(a.GicIndustryCode.length, b.GicIndustryCode.length); i++) {
                    const digitA = parseInt(a.GicIndustryCode[i]) || 0; // If a[i] is undefined, use 0
                    const digitB = parseInt(b.GicIndustryCode[i]) || 0; // If b[i] is undefined, use 0
                    if (digitA !== digitB) { return digitA - digitB; }
                }
                return a.GicIndustryCode.length - b.GicIndustryCode.length;
            });
            var stringGicCodeList = this.allIndustries.filter(s => !(!isNaN(+s.GicIndustryCode)));
            this.allIndustries = [...this.allIndustries.filter(s => !isNaN(+s.GicIndustryCode)), ...stringGicCodeList]
        }

    }


}
