import { Injectable } from '@angular/core';
import { ApiClient } from '@concurrency/angular';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Auth0ApiClient } from '../api.client';

@Injectable()
export class ScenarioClient extends Auth0ApiClient {
    protected baseUrl = `${environment.urls.navigatorApi}/api`;

    public rename(id: string, name: string): Observable<void> {
        return this.post(`calculation/rename/${id}?name=${name}`, {});
    }

    public deactivate(id: string): Observable<void> {
        return this.post(`calculation/deactivate/${id}`, null);
    }

    public clone(id: string): Observable<void> {
        return this.post(`calculation/clone/${id}`, null);
    }
}
