import { ImplicationType } from '../_api/responses/equation.response';
import { InputType } from '../_api/responses/input.response';
import { CocInputsResource, HighFinancialRiskStudy } from './models/cocInputsTabs';

export enum DataStudy {
    CRSPDecilesSizeStudy = 'CRSP Deciles Size Study',
    RiskPremiumReportSizeStudy = 'Risk Premium Report Size Study',
    HighFinancialRiskStudy = 'High-Financial-Risk Study'
}

export enum RiskPremiumDataStudy {
    RiskPremiumReportSizeStudy = 'Risk Premium Report Size Study',
    RiskPremiumReportRiskStudy = 'Risk Premium Report Risk Study',
    HighFinancialRiskStudy = 'High-Financial-Risk Study'
}

export enum BetaLeverage {
    LeveredBetas = 'Levered',
    UnleveredBetas = 'Unlevered',
    IRP = 'ERP used in RPi',
    USDebtBetas = 'DebtBetas'
}
export enum enumExcelExportSheets {
    RFR = 1,
    ERP = 2,
    CRSPDecilesSizeStudy = 3,
    SPRiskPremiumReportSizeStudy = 4,
    SPHighFinancialRiskStudy = 5,
    RiskPremiumReportSizeStudy = 6,
    RiskPremiumReportRiskStudy = 7,
    HighFinancialRiskStudy = 8,
    Betas = 9,
    IndustryRiskPremium = 10,
    DebtBetas = 11,
    CRP = 12
}


export const RiskPremiumReportSizeStudy =
    {
        'Market Value of Common Equity': InputType.MarketValueOfCommonEquity,
        'Book Value of Equity': InputType.BookValueOfEquity,
        '5-year Average Net Income': InputType.FiveYearAverageNetIncome,
        'Market Value of Invested Capital': InputType.MarketValueOfInvestedCapital,
        'Total Assets': InputType.TotalAssets,
        '5-year Average EBITDA': InputType.FiveYearAverageEbitda,
        'Net Sales': InputType.NetSales,
        'Employees': InputType.NumberOfEmployees
    } as any

export const RiskPremiumReportRiskStudy =
    {
        'Operating Margin': InputType.OperatingMargin,
        'Coefficient of Variation of Operating Margin': InputType.CoefficientOfVariationOfOperatingMargin,
        'Coefficient of Variation of Return on Equity': InputType.CoefficientOfVariationOfReturnOnEquity
    } as any

export const zscoredata: HighFinancialRiskStudy[] = [
    {
        zScorePortfolio: '1.8 to 2.99',
        zone: 'Gray Zone',
        industry: 'Manufacturing',
        sizePremium: 0
    },
    {
        zScorePortfolio: '<1.8',
        zone: 'Distress Zone',
        industry: 'Manufacturing',
        sizePremium: 0
    },
    {
        zScorePortfolio: '1.1 to 2.59',
        zone: 'Gray Zone',
        industry: 'Service',
        sizePremium: 0
    },
    {
        zScorePortfolio: '<1.1',
        zone: 'Distress Zone',
        industry: 'Service',
        sizePremium: 0
    },
]

export const zscoredataRPORFR: HighFinancialRiskStudy[] = [
    {
        zScorePortfolio: '1.8 to 2.99',
        zone: 'Gray Zone',
        industry: 'Manufacturing',
        sizePremium: 0
    },
    {
        zScorePortfolio: '<1.8',
        zone: 'Distress Zone',
        industry: 'Manufacturing',
        sizePremium: 0
    },
    {
        zScorePortfolio: '1.1 to 2.59',
        zone: 'Gray Zone',
        industry: 'Service',
        sizePremium: 0
    },
    {
        zScorePortfolio: '<1.1',
        zone: 'Distress Zone',
        industry: 'Service',
        sizePremium: 0
    },
]


export const sizePremiumResourcesData: CocInputsResource[] = [

    {
        "resources": "CRSP Deciles Size Study",
        "type": "Methodology",
        'updated': "08/17/2021",
        "bloblink": "https://vasdc8grscoc.blob.core.windows.net/resources/crsp-deciles-size-study.pdf",
        'fileName': 'crsp-deciles-size-study.pdf'
    },
    {
        "resources": "Risk Premium Report Size Study",
        "type": "Methodology",
        "updated": "08/17/2021",
        "bloblink": "https://vasdc8grscoc.blob.core.windows.net/resources/risk-premium-report-study.pdf",
        "fileName": 'risk-premium-report-study.pdf',
    },
    {
        "resources": "Differences Between the Two Size Studies",
        "type": "Methodology",
        "updated": "08/17/2021",
        "bloblink": "https://vasdc8grscoc.blob.core.windows.net/resources/differences-between-the-two-size-studies.pdf",
        "fileName": 'differences-between-the-two-size-studies.pdf',
    },
    {
        "resources": "High-Financial-Risk Study",
        "type": "Methodology",
        "updated": "08/18/2023",
        "bloblink": "https://vasdc8grscoc.blob.core.windows.net/resources/high-financial-risk.pdf",
        "fileName": 'high-financial-risk-study.pdf',
    },
    {
        "resources": "Why is Decile 8 Size Premium Lower than Decile 7?",
        "type": "Methodology",
        "updated": "02/14/2022",
        "bloblink": "https://vasdc8grscoc.blob.core.windows.net/resources/the-size-premia-can-be-non-monotonic.pdf",
        "fileName": 'the-size-premia-can-be-non-monotonic.pdf',
    }
]

export const RiskFreeRateOVerRP: CocInputsResource[] = [
    {
        "resources": "Risk Premium Report Size Study",
        "type": "Methodology",
        'updated': "08/17/2021",
        "bloblink": "https://vasdc8grscoc.blob.core.windows.net/resources/risk-premium-report-study.pdf ",
        'fileName': 'crsp-deciles-size-study.pdf'
    },
    {
        "resources": "High-Financial-Risk Study",
        "type": "Methodology",
        'updated': "08/18/2023",
        "bloblink": "https://vasdc8grscoc.blob.core.windows.net/resources/high-financial-risk.pdf",
        'fileName': 'high-financial-risk.pdf'
    },
    {
        "resources": "Risk Premium Report Risk Study",
        "type": "Methodology",
        'updated': "08/17/2021",
        "bloblink": "https://vasdc8grscoc.blob.core.windows.net/resources/risk-study.pdf",
        'fileName': 'risk-study.pdf'
    }
]
export const RiskFreeRateERP: CocInputsResource[] = [
    {
        "resources": "Kroll Global Cost of Capital Inputs - Current Guidance",
        "type": "Methodology",
        'updated': "12/31/2023",
        "bloblink": "https://www.kroll.com/en/insights/publications/cost-of-capital",
        'fileName': 'cost-of-capital'
    },
    {
        "resources": "Risk-free Rate Methodology",
        "type": "Methodology",
        'updated': "1/31/2024",
        "bloblink": "https://vasdc8grscoc.blob.core.windows.net/resources/risk-free-rate-methodology.pdf",
        'fileName': 'risk-free-rate-methodology.pdf'
    },
    {
        "resources": "Equity Risk Premium Methodology",
        "type": "Methodology",
        'updated': "1/31/2024",
        "bloblink": "https://vasdc8grscoc.blob.core.windows.net/resources/equity-risk-premium-methodology.pdf",
        'fileName': 'equity-risk-premium-methodology.pdf'
    }
]


