import { Action, createSelector, Selector, State, StateContext } from '@ngxs/store';
import { Observable } from 'rxjs/internal/Observable';
import { IntlIndustryClient } from 'src/app/_api/clients/intlIndustry.client';
import { Gic } from 'src/app/_api/responses/gic.response';
import { GetIntlIndustries } from './intl-industry-list.actions';
import { Injectable } from '@angular/core';
export type IntlIndustryListModel = Gic[] | undefined;

@State<IntlIndustryListModel>({
    name: 'intlIndustries',
    defaults: undefined
})

@Injectable()
export class IntlIndustryListState {

    public static getByGicId(gicId: number): (industries: IntlIndustryListModel) => Gic[] | undefined {
        const filteredIndustries = (industries: Gic[]) => {
            return industries.filter((x) => x.GicId === gicId);
        };

        return createSelector([IntlIndustryListState], (industries) => {
            if (industries == null) {
                return undefined;
            }

            return filteredIndustries(industries);
        });
    }

    @Selector()
    public static get(state: IntlIndustryListModel): Gic[] | undefined {
        return state;
    }
    constructor(private intlIndustryCient: IntlIndustryClient) { }

    @Action(GetIntlIndustries)
    public getIntlIndustryList(context: StateContext<IntlIndustryListModel>, action: GetIntlIndustries): Observable<void> {
        return this.intlIndustryCient.read(action.countryId, action.currencyId, action.dataAsOf).once((response) => {
            context.setState(response);
        });
    }

}
