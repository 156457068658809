import { Component, ElementRef, HostListener, Input, OnChanges, ViewChild } from '@angular/core';
import { Util } from '@concurrency/core';
import { RangeChartConfig } from 'src/app/_navigator/summary/range-chart-config.model';

@Component({
    selector: 'range-chart',
    templateUrl: './range-chart.component.html'
})
export class RangeChartComponent implements OnChanges {
    @ViewChild('chart') public chartElement!: ElementRef;
    @Input() public config!: RangeChartConfig;
    @Input() public reset!: number;

    public ticksArray = Array(13);
    public visibility?: string;
    public innerWidth?: string;
    public outerWidth?: string;
    public transformScale?: string;
    public lowLeft?: string;
    public highLeft?: string;
    public medianLeft?: string;
    public averageLeft?: string;
    public maximum?: number;
    public minimum?: number;

    private toRangePercentage(num?: number): string {
        if (num == null) {
            return 'N/A';
        }
        const value = Util.round(num);

        this.minimum = Math.floor(this.config.rangeMin);
        this.maximum = Math.ceil(this.config.rangeMax);

        const rangeWidth = this.maximum - this.minimum;
        const realPercentage = (value - this.minimum) / rangeWidth;
        const scaledPercentage = 0.95 * realPercentage;

        return (scaledPercentage * 100).toString();
    }

    public ngOnChanges(): void {
        if (this.config == null) {
            return;
        }

        this.onResize();

        this.lowLeft = `${this.toRangePercentage(this.config.low)}%`;
        this.highLeft = `${this.toRangePercentage(this.config.high)}%`;
        this.medianLeft = `${this.toRangePercentage(this.config.median)}%`;
        this.averageLeft = `${this.toRangePercentage(this.config.average)}%`;
    }

    @HostListener('window:resize')
    public onResize(): void {
        this.visibility = 'hidden';
        this.innerWidth = undefined;
        this.outerWidth = undefined;
        this.transformScale = undefined;

        setTimeout(() => {
            const actualWidth = this.chartElement.nativeElement.clientWidth;
            const scale = actualWidth / 500;

            this.outerWidth = `${actualWidth}px`;
            this.innerWidth = '500px';

            this.transformScale = `scale(${scale})`;
            this.visibility = undefined;
        });
    }
}
