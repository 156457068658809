import { Util } from '@concurrency/core';
import { Action, createSelector, Selector, State, StateContext, Store } from '@ngxs/store';
import { Observable, of, zip } from 'rxjs';
import { map } from 'rxjs/operators';
import { EstimateClient } from 'src/app/_api/clients/estimate.client';
import { EstimateType } from 'src/app/_api/enums/estimate-type';
import { Estimate } from '../data/model/estimate.model';
import { DataStore } from '../data/store/data.store';
import { GetEstimate } from '../estimate-store/estimate-actions';
import { User } from '../user/model/user.model';
import { UserStore } from '../user/store/user.store';
import { ArchiveEstimate, CloneEstimate, CreateEstimate, GetEstimates, RenameEstimate } from './estimates-list-actions';
import { Injectable } from '@angular/core';


export enum SortType { Name = 'Name', Date = 'Date' }

export type EstimatesListModel = Estimate[] | undefined;

@State<EstimatesListModel>({
    name: 'estimates',
    defaults: undefined
})

@Injectable()
export class EstimatesListState {

    @Selector()
    public static get(state: EstimatesListModel): Estimate[] | undefined {
        return state;
    }

    public static sort(sortType: SortType, sortDescending: boolean = true, estimateType?: EstimateType):
        (estimates: EstimatesListModel) => Estimate[] | undefined {
        const sortByType = (estimates: Estimate[]) => {
            if (sortType === SortType.Name) {
                return Util.sortBy(estimates, (x) => x.Name);
            } else {
                return Util.sortBy(estimates, (x) => x.Updated);
            }
        };

        const sortByDirection = (estimates: Estimate[]) => {
            return sortDescending ? estimates.reverse() : estimates;
        };

        return createSelector([EstimatesListState], (estimates) => {
            if (estimates == null) {
                return undefined;
            }

            let sorted = sortByType(estimates);
            sorted = sortByDirection(sorted);

            if (estimateType) {
                sorted = sorted.filter((x) => x.EstimateType === estimateType);
            }

            return sorted;
        });
    }
    constructor(
        private estimateClient: EstimateClient,
        private dataStore: DataStore,
        private store: Store,
        private userstore: UserStore
    ) { }

    @Action(GetEstimates)
    public getAll(context: StateContext<EstimatesListModel>): Observable<void> {
        return this.userstore.user.onceDefined((user: User) => {
            if (user.IsActive === false) {

                return of([]).once(() => {
                    context.setState([]);
                });
            } else {
                return this.estimateClient.search().once((estimates) => {
                    context.setState(estimates.map((x) => new Estimate(x)));
                });
            }
        });
    }

    @Action(CreateEstimate)
    public create(context: StateContext<EstimatesListModel>, action: CreateEstimate): Observable<void> {
        return this.estimateClient.create(action.name, action.type).once((id) => {
            if (action.type === EstimateType.USEstimate) {
                const zipped = zip(context.dispatch(new GetEstimates()), this.dataStore.setupEstimate(id));
                return zipped.pipe(map(() => { }));
            } else {
                const zipped = zip(context.dispatch(new GetEstimates()), this.store.dispatch(new GetEstimate(id)));
                return zipped.pipe(map(() => { }));
            }
        });
    }

    @Action(ArchiveEstimate)
    public archive(context: StateContext<EstimatesListModel>, action: ArchiveEstimate): Observable<void> {
        return this.estimateClient.deactivate(action.id).once(() => context.dispatch(new GetEstimates()));
    }

    @Action(CloneEstimate)
    public clone(context: StateContext<EstimatesListModel>, action: CloneEstimate): Observable<void> {
        return this.estimateClient.clone(action.id).once(() => context.dispatch(new GetEstimates()));
    }

    @Action(RenameEstimate)
    public rename(context: StateContext<EstimatesListModel>, action: RenameEstimate): Observable<void> {
        return this.estimateClient.rename(action.id, action.name).once(() => context.dispatch(new GetEstimates()));
    }
}
