
import { Injectable } from '@angular/core';
import { Spinner } from '@concurrency/angular';
import { ContextualString, Util } from '@concurrency/core';
import { Store } from '@ngxs/store';
import { Observable, Subject, of } from 'rxjs';
import { CompanyReturns, GicIndustry, OLSBetaCompanyReturns } from '../_api/responses/company-returns.response';
import { IntlData, IntlDataUnlevering } from '../_api/responses/intl-data.response';
import { MetricInputs } from '../_api/responses/metric-inputs.response';
import { MsciMetrics } from '../_api/responses/msci-metrics.response';
import { CommonDate } from '../_navigator/common/date-struct';
import { CommonInputConfig } from '../_navigator/common/inputs/common-user-input.component';
import { ComboboxDataType } from '../_navigator/data/enum/combobox-data-type.enum';
import { Suggestion } from '../_navigator/data/model/suggestion.model';
import { DataStore } from '../_navigator/data/store/data.store';
import { NumberFormatUtil } from '../_navigator/data/util/number-format.util';
import { GetBetaEstimates } from '../_navigator/estimates-list-store/beta-estimates-list-actions';
import { UserStore } from '../_navigator/user/store/user.store';
import { ComparableCompanyFieldTypes, ComparableCompanyHeaders } from './beta.constants';
import { AllSummaryStatistics, BetaColors, BetaComparbleCompanyRequest, BetaComparbleCompanyResults, BetaEstimateRequest, BetaStatistics, BetaToT, ComaparableCompany, ComaparableCompanyFilter, CompanyInformation, CompanyReturnsRequest, CompanyReturnsRequestWithGVkeyStatus, CompanyValuationInputs, GVKeyStatus, LeveredBetas, MarketIndex, ReleveredInputs, ReturnFrequency, Statistics, UnleveringInputs, UnleveringTaxRate, UnleveringTaxRateInputs, WeightingInputs, WeightResults } from './beta.types';
import * as moment from 'moment';
import { Chart } from 'angular-highcharts';

@Injectable({
    providedIn: 'root'
})
export class BetaService {
    public returnFrequencyOptions: Suggestion<string>[] = [];
    public marketIndexOptions: Suggestion<string>[] = [];
    public currencyofwaccoptions: Suggestion<string>[] = [];
    public lookbackPeriodOptions: Suggestion<string>[] = [];
    public subjectCompanyLocationOptions: Suggestion<string>[] = [];

    public betalocallocationOptions: any[] = [];
    public betalocalmarketIndexOptions: any[] = [];
    public betalocallookbackOptions: any[] = [];
    public betalocalcurrencyOptions: any[] = [];
    public betalocalfrequencyOptions: any[] = [];
    public isCompanyDisplay = false;
    public CompanyInfo?: CompanyInformation | any;
    public isDeleteCompanyChange = false;
    // public lstIndustryCodeDescription: IndustryCodeDescription[] = [];
    // public tempIndustryCodeDescription: IndustryCodeDescription[] = [];

    public UserHiddenCompanies: string[] = [];

    public isFormDisable = new Subject();
    public clearReleveredBetas = new Subject();
    public frequency: any;
    public returnFrequencyList = [
        { id: '1', value: 'Monthly' },
        { id: '2', value: 'Weekly' }
    ];

    public lookbackPeriodList = [
        { id: '1', value: '1-Year' },
        { id: '2', value: '2-Year' },
        { id: '3', value: '3-Year' },
        { id: '4', value: '4-Year' },
        { id: '5', value: '5-Year' }
    ];

    public betaTypeList = [
        { id: '1', name: 'OLS Beta', value: 'OLSBeta' },
        { id: '2', name: 'Sum Beta', value: 'SumBeta' },
        { id: '3', name: 'Vasicek Adjusted', value: 'VasicekAdjusted' },
        { id: '4', name: 'Blume Adjusted', value: 'BlumeAdjusted' },
        { id: '5', name: 'Portfolio Beta', value: 'PortfolioBeta' }
    ];

    public filterOperators = [
        { id: '1', name: 'equalTo', value: '=', isRequiredField: false },
        { id: '2', name: 'lessThan', value: '<', isRequiredField: true },
        { id: '3', name: 'greaterThan', value: '>', isRequiredField: true }
    ];

    public marketIndex: MarketIndex = {} as MarketIndex;
    public returnFrequency: ReturnFrequency = {} as ReturnFrequency;
    public unleveringTaxRateoptions: UnleveringTaxRate = {
        TaxRateValue: 0,
        GVKEY: '',
        Label: ''
    };



    public lookBackKeyValue = {

        '1-Year': 1,
        '2-Year': 2,
        '3-Year': 3,
        '4-Year': 4,
        '5-Year': 5

    }

    public isClickableAllowed = true;
    public editClicked = false;
    public betasAsOf!: any;
    public marketIndexName!: any;
    public unleveringSaveClick = false;
    public generalInputsModified = false;
    public taxRateconfig: CommonInputConfig[] = [];
    public taxRateOptions: any = [];
    public taxRate: any = {};
    public marketIndexList: MsciMetrics[] = [];
    public summaryStatisticsValues: AllSummaryStatistics = {} as AllSummaryStatistics;
    public betaTypeSelected: any;
    public unleveredMinimumSummaryValue: any;
    public unleveredMaximumSummaryValue: any;
    public unleveredAverageSummaryValue: any;
    public unleveredFirstQuartileSummaryValue: any;
    public unleveredThirdQuartileSummaryValue: any;
    public unleveredMedianSummaryValue: any;
    public medianSummaryValue: any;
    public minimumSummaryValue: any;
    public maximumSummaryValue: any;
    public averageSummaryValue: any;
    public firstQuartileSummaryValue: any;
    public thirdQuartileSummaryValue: any;
    public leveredmedianSummaryValue: any;
    public leveredminimumSummaryValue: any;
    public leveredmaximumSummaryValue: any;
    public leveredaverageSummaryValue: any;
    public leveredfirstQuartileSummaryValue: any;
    public leveredthirdQuartileSummaryValue: any;
    public capitalRateSelectedLabel: any;
    public taxRateSelectedLabel: any;
    public capitalRateSelected: any;
    public taxRateSelected: any;
    public releveredInputType: any;
    public leveredInputType: any;
    public unleveredInputType: any;
    public addTooltipDisabled = true;
    public containsSales = false;
    public isSearchClicked = false;
    public isBasicSubscriber = false;
    public locationName: any;
    public targetCapitalAverageValue: any;
    public targetCapitalMedianValue: any;
    public targetCapitalOriginalAverageValue: any;
    public targetCapitalOriginalMedianValue: any;
    public originalTargetCapitalStructure: any;
    public releveredAverageTaxRate: any;
    public releveredMedianTaxRate: any;
    public releveredTaxRate: any;
    public betaValuationDate: any;
    public selectedCompany?: ComaparableCompany;
    public betas: BetaToT[] = [];
    public betasToTTitle: string = '';
    public isEnterpriseorPro = false;
    public totChart!: any;
    public OlsBetaChart!: any;
    public startSpinner = false;
    public endSpinner = false;
    public isSubmitClicked = false;

    // public fullCurrencyName: any;
    public betasUpdated = false;
    public operators: any;
    public unleveringtaxRateInputs: UnleveringTaxRateInputs[] = [];
    public comparableCompanyList: ComaparableCompany[] = [];
    public comparableFilters: ComaparableCompanyFilter[] = [
        { keyOfFilter: 'CompanyName', type: ComparableCompanyFieldTypes.TypeHeader, inputValue: '', isCanDelete: false, isInfo: false, column: 1, isDisabled: true, isRequired: false, isFicLoc: true },
        { keyOfFilter: 'Observations', type: ComparableCompanyFieldTypes.ComparativeOparator, inputValue: '', isCanDelete: false, isInfo: true, column: 1, isDisabled: true, isRequired: false, isFicLoc: true, isInputDisabled: true },
        // { keyOfFilter: 'Ticker', type: ComparableCompanyFieldTypes.TypeHeader, isInfo: false, isCanDelete: false, column: 1, isDisabled: true, isRequired: false },
        { keyOfFilter: 'IndustryCodeDescription', type: ComparableCompanyFieldTypes.TypeHeader, inputValue: '', isCanDelete: false, isInfo: false, column: 1, isDisabled: true, isRequired: false, minimumWidth: '300px', isFicLoc: true },
        { keyOfFilter: 'KeyWords', type: ComparableCompanyFieldTypes.TypeHeader, isCanDelete: false, inputValue: '', isInfo: true, column: 1, isDisabled: true, isRequired: false, isFicLoc: true },
        { keyOfFilter: 'Sales', type: ComparableCompanyFieldTypes.ComparativeOparator, inputValue: '', isCanDelete: true, isInfo: false, column: 1, isRequired: true, isFicLoc: true, isInputDisabled: true },
        { keyOfFilter: 'EBITDA', type: ComparableCompanyFieldTypes.ComparativeOparator, inputValue: '', isCanDelete: true, isInfo: false, column: 2, isRequired: true, isFicLoc: true, isInputDisabled: true },
        { keyOfFilter: 'Assets', type: ComparableCompanyFieldTypes.ComparativeOparator, inputValue: '', isCanDelete: true, isInfo: false, column: 2, isRequired: true, isFicLoc: true, isInputDisabled: true },
        { keyOfFilter: 'MarketCap', type: ComparableCompanyFieldTypes.ComparativeOparator, inputValue: '', isCanDelete: true, isInfo: false, column: 2, isRequired: true, isFicLoc: true, isInputDisabled: true },
        { keyOfFilter: 'IncorporationCountry', type: ComparableCompanyFieldTypes.TypeHeader, inputValue: '', isCanDelete: true, isInfo: false, column: 2, isRequired: true, minimumWidth: '160px', isFicLoc: false },
        { keyOfFilter: 'HeadquarterCountry', type: ComparableCompanyFieldTypes.TypeHeader, inputValue: '', isCanDelete: true, isInfo: false, column: 2, isRequired: true, minimumWidth: '160px', isFicLoc: false },
        { keyOfFilter: 'Exchange', type: ComparableCompanyFieldTypes.TypeHeader, inputValue: '', isCanDelete: true, isInfo: false, column: 2, isRequired: true, isFicLoc: false }

    ];
    public selectedFilters = [
        { keyOfFilter: 'CompanyName', type: ComparableCompanyFieldTypes.TypeHeader, inputValue: '', isCanDelete: false, isInfo: false, column: 1, isDisabled: true, isRequired: false, isFicLoc: true },
        { keyOfFilter: 'Observations', type: ComparableCompanyFieldTypes.ComparativeOparator, inputValue: '', isCanDelete: false, isInfo: true, column: 1, isDisabled: true, isRequired: false, isFicLoc: true, isInputDisabled: true },
        // { keyOfFilter: 'Ticker', type: ComparableCompanyFieldTypes.TypeHeader, isInfo: false, isCanDelete: false, column: 1, isDisabled: true, isRequired: false },
        { keyOfFilter: 'IndustryCodeDescription', type: ComparableCompanyFieldTypes.TypeHeader, inputValue: '', isCanDelete: false, isInfo: false, column: 1, isDisabled: true, isRequired: false, minimumWidth: '300px', isFicLoc: true },
        { keyOfFilter: 'KeyWords', type: ComparableCompanyFieldTypes.TypeHeader, inputValue: '', isCanDelete: false, isInfo: true, column: 1, isDisabled: true, isRequired: false, isFicLoc: true }
    ];
    public selectedCompanies = [] as any;
    public finalSelectedCompanies = [] as any;
    public filteredCompanyList = [] as any;
    // public selectedGVKeys = [] as any;
    public companyHeaders = new ComparableCompanyHeaders();
    public betaComparbleCompanyRequest: BetaComparbleCompanyRequest = {} as BetaComparbleCompanyRequest;
    // public companySelectedChnagesRequest: CompanyReturnsRequest = {} as CompanyReturnsRequest;
    public pageCount = 1;
    public size = 100;
    public totalCount = 0;
    public pageStart = 0;
    public pageEnd = 0;
    public IntlTaxRate: IntlData[] = [];
    public IntlTaxRates: IntlDataUnlevering[] = [];
    public leveredBetas: LeveredBetas[] = [];
    public betaStatistics: BetaStatistics[] = [];
    public gvKeys: string[] = [];
    public weightInputs: WeightingInputs[] = [];
    public weightSMResults: WeightResults[] = [];
    public leveredBetasCollapse = false;
    public releveringCollapse = false;
    public unleveringCollapse = false;
    public unleveredBetasCollapse = false;
    public metricInputs: MetricInputs = {} as MetricInputs;
    public Debt: any | undefined;
    public TaxRate: any | undefined;
    public UnleveredDenominatorValue: any | undefined;
    public unleveredBetas: LeveredBetas[] = [];
    public unleveringTaxRateValues: UnleveringTaxRate[] = [];
    public unleveringInputs: UnleveringInputs[] = [];
    public companyReturns: CompanyReturns[] = [];
    public chart!: any;
    public subjectCompanyLocationId: any | undefined;
    public seriesLength = 0;
    public taxRateReleveredOptions: Suggestion<string>[] = [];
    public targetCapitalRateOptions: Suggestion<string>[] = [];
    public releveredInputs!: ReleveredInputs;
    public releveredStatistics!: Statistics;
    public leveredStatistics!: Statistics;
    public unleveredStatistics!: Statistics;
    public isBetaEstimate = false;
    public updateEstimateCalled = false;
    public betaEstimateRequest!: BetaEstimateRequest;
    public isLeverdBetaCollapse = false;
    public HiddenComapnies = false;
    public isSerchByValue = false;

    public estimateId: any;
    public IsCompanyHiddenStatusChanging = false;

    constructor(
        private dataStore: DataStore,
        private spinner: Spinner,
        private userStore: UserStore,
        private store: Store
    ) {
        this.filteredCompanyList = this.comparableCompanyList;
    }
    private getRequest(): CompanyReturnsRequest {
        this.gvKeys = [];
        this.finalSelectedCompanies.forEach((company: any) => {
            this.gvKeys.push(company.GVKEY);
        });
        const companyRequest: CompanyReturnsRequest = {
            GVKEY: this.gvKeys,
            Metrics: this.metricInputs
        };
        return companyRequest;
    }

    // Sending GVKeys status (hidden or not hidden) of the comapny to GetLeveredBeta api call so that we can get correct values for
    // VasicekAdjusted and Portfolio Beta field
    private transformCompanyReturnsRequest(companyRequest: CompanyReturnsRequest): CompanyReturnsRequestWithGVkeyStatus {
        let companyRequestWithGVKeysStatus: CompanyReturnsRequestWithGVkeyStatus;
        const gvKeyStatus: GVKeyStatus[] = [];
        companyRequest.GVKEY.forEach((GVKEY) => {
            if (this.UserHiddenCompanies.includes(GVKEY)) {
                gvKeyStatus.push(
                    {
                        GVKEY,
                        IsGVKEYHidden: true
                    }
                );
            } else {
                gvKeyStatus.push(
                    {
                        GVKEY,
                        IsGVKEYHidden: false
                    }
                );
            }
        });

        companyRequestWithGVKeysStatus = { GVKEYS: gvKeyStatus, Metrics: companyRequest.Metrics } as CompanyReturnsRequestWithGVkeyStatus;
        return companyRequestWithGVKeysStatus;
    }
    public RoundDecimalValue(value: any, precision: number): string {
        return value.toFixed(precision) !== '-0.00' ? value.toFixed(precision) : '0.00';
    }

    public getMetrics(countryName: string): MsciMetrics[] {
        const request = this.dataStore.getMetrics(countryName);
        this.spinner.while(request);
        request.once(() => {
            this.dataStore.metrics.subscribe((metrics) => {
                if (metrics) {
                    metrics.forEach((metric) => {
                        this.marketIndexList.push({
                            MSCIMetricID: metric.MSCIMetricID,
                            MSCIIndexName: metric.MSCIIndexName
                        });
                    });
                }
            });
        });
        return this.marketIndexList;
    }
    public OnfilterValueChange(): void {
    }
    public selectCompany(company: ComaparableCompany): void {
        if (this.selectedCompanies.length < 20) {
            this.selectedCompanies.push(company);
        }
        this.tooltipDisabled();
    }
    public removeCompany(company: ComaparableCompany): void {
        this.selectedCompanies = this.selectedCompanies.filter((x: any) => x.GVKEY !== company.GVKEY);
        this.tooltipDisabled();
    }
    public isTickShow(company: ComaparableCompany): boolean {
        const val = this.selectedCompanies.filter((x: ComaparableCompany) => x.GVKEY === company.GVKEY).length > 0;
        return val;
    }
    public isAddShow(company: ComaparableCompany): boolean {
        const val = !(this.selectedCompanies.filter((x: ComaparableCompany) => x.GVKEY === company.GVKEY).length > 0);
        return val;
    }
    public removeFilter(selctedFilter: ComaparableCompanyFilter): void {
        this.selectedFilters = this.selectedFilters.filter((x: ComaparableCompanyFilter) => x.keyOfFilter !== selctedFilter.keyOfFilter);
        this.containsSales = false;
        if (selctedFilter.inputValue) {
            if (this.selectedFilters.filter(s => s.inputValue !== '').length > 0) {
                selctedFilter.keyOfFilter.charAt(0).toLowerCase();
                (this.betaComparbleCompanyRequest as any)[selctedFilter.keyOfFilter] = '';
                this.searchComparableCompanies(true);
            } else {
                this.clearFields();
            }

            let obj = this.comparableFilters.find(s => s.keyOfFilter == selctedFilter.keyOfFilter);
            if (obj) {
                obj.inputValue = '';
            }
        }
    }
    public removeCompanyFromParentComponent(isUserDelete: boolean, company: ComaparableCompany): void {
        // const graphIndex = this.selectedCompanies.findIndex((x: any) => x.GVKEY === company.GVKEY);

        this.selectedCompanies = this.selectedCompanies.filter((x: ComaparableCompany) => x.GVKEY !== company.GVKEY);
        this.finalSelectedCompanies = this.finalSelectedCompanies.filter((x: any) => x.GVKEY !== company.GVKEY);
        if (this.finalSelectedCompanies.length === 0) {
            this.isFormDisable.next(false);
        }
        if (this.taxRate) {
            delete this.taxRate[company.GVKEY];
        }
        const index = this.leveredBetas.findIndex((x) => x.GVKEY === company.GVKEY);
        if (index > -1) {
            this.leveredBetas.splice(index, 1);
            this.weightSMResults.splice(index, 1);
            this.betaStatistics.splice(index, 1);
            this.unleveredBetas.splice(index, 1);
            this.unleveringInputs.splice(index, 1);
            this.taxRateOptions.splice(index, 1);
            this.getAllSummaryStatistics();
            this.insertTargetCapitalRateOptions();
            this.insertTaxRateReleveredOptions();
            if (this.releveredInputType) {
                this.getReleveredSummaryStatistcs(this.releveredInputType);
            }

        }
        this.chart.removeSeries(index + 1);
        this.chart.options.series.splice(index + 1, 1);
        if (this.finalSelectedCompanies.length > 0 && isUserDelete) {
            const request = this.getRequest();
            this.getLeveredBetas(request, this.unleveredBetas, this.unleveringInputs);
            this.isDeleteCompanyChange = true;
            this.clearReleveredBetas.next(true)
            this.updateEstimate();
        }
    }

    public HideCompanyFromParentComponent(company: ComaparableCompany): void {
        this.IsCompanyHiddenStatusChanging = true;
        const companyRequest: CompanyReturnsRequest = {
            GVKEY: this.finalSelectedCompanies.map((beta: BetaComparbleCompanyResults) => beta.GVKEY).filter((value: any, index: any, self: any) => self.indexOf(value) === index),
            Metrics: this.metricInputs
        };
        this.getCompanyReturns(companyRequest);

        this.betasUpdated = false;

        if (this.UserHiddenCompanies.findIndex((GVKEY) => GVKEY === company.GVKEY) > -1) {
            this.UserHiddenCompanies = this.UserHiddenCompanies.filter((GVKEY) => GVKEY !== company.GVKEY);
        } else {
            this.UserHiddenCompanies.push(company.GVKEY);
        }

        const cmpIndex = this.finalSelectedCompanies.findIndex((x: ComaparableCompany) => x.GVKEY === company.GVKEY);

        const IsCompanyHidden = this.finalSelectedCompanies[cmpIndex].IsHidden;

        if (IsCompanyHidden === false) {
            this.finalSelectedCompanies[cmpIndex].IsHidden = true;
            this.finalSelectedCompanies[cmpIndex].TrendsOverTimeDisplay = false;
        } else if (IsCompanyHidden === true) {
            this.finalSelectedCompanies[cmpIndex].IsHidden = false;
        }

        if (this.finalSelectedCompanies.length === 0) {
            this.isFormDisable.next(false);
        }

        const CmpIndex = this.leveredBetas.findIndex((x) => x.GVKEY === company.GVKEY);
        if (CmpIndex > -1 && !IsCompanyHidden) {
            this.leveredBetas[this.leveredBetas.findIndex((x) => x.GVKEY === company.GVKEY)].IsHidden = true;
            // this.weightSMResults[this.weightSMResults.findIndex((x) => x.GVKEY === company.GVKEY)].IsHidden = true;
            this.betaStatistics[this.betaStatistics.findIndex((x) => x.GVKey === company.GVKEY)].IsHidden = true;
            this.unleveredBetas[this.unleveredBetas.findIndex((x) => x.GVKEY === company.GVKEY)].IsHidden = true;
            this.unleveringInputs[this.unleveringInputs.findIndex((x) => x.GVKEY === company.GVKEY)].IsHidden = true;
            this.taxRateconfig[CmpIndex].disabled = true;
            this.taxRateOptions[CmpIndex][0].disabled = true;
            this.getAllSummaryStatistics();
            this.insertTargetCapitalRateOptions();
            this.insertTaxRateReleveredOptions();
            if (this.releveredInputType) {
                this.getReleveredSummaryStatistcs(this.releveredInputType);
            }

        } else {
            this.leveredBetas[this.leveredBetas.findIndex((x) => x.GVKEY === company.GVKEY)].IsHidden = false;
            // this.weightSMResults[this.weightSMResults.findIndex((x) => x.GVKEY === company.GVKEY)].IsHidden = false;
            this.betaStatistics[this.betaStatistics.findIndex((x) => x.GVKey === company.GVKEY)].IsHidden = false;
            this.unleveredBetas[this.betaStatistics.findIndex((x) => x.GVKey === company.GVKEY)].IsHidden = false;
            this.unleveringInputs[this.betaStatistics.findIndex((x) => x.GVKey === company.GVKEY)].IsHidden = false;
            this.taxRateconfig[CmpIndex].disabled = false;
            this.taxRateOptions[CmpIndex][0].disabled = false;
            this.getAllSummaryStatistics();
            this.insertTargetCapitalRateOptions();
            this.insertTaxRateReleveredOptions();
            if (this.releveredInputType) {
                this.getReleveredSummaryStatistcs(this.releveredInputType);
            }
        }

        if (this.finalSelectedCompanies.length > 0) {
            const request = this.getRequest();
            this.getLeveredBetas(request, this.unleveredBetas, this.unleveringInputs);
            this.updateEstimate();
        }
        this.clearReleveredBetas.next(true);
    }

    public getComparbleCompanyList(): Observable<BetaComparbleCompanyResults[]> {
        const request = this.dataStore.getComparbleCompanyList(this.betaComparbleCompanyRequest);
        this.spinner.begin();
        return request.once((results: BetaComparbleCompanyResults[]) => {
            this.filteredCompanyList = [];
            // this.tempIndustryCodeDescription = [];
            if (results.length > 0) {
                results.forEach((result: BetaComparbleCompanyResults) => {
                    if (this.filteredCompanyList.filter((x: BetaComparbleCompanyResults) => x.GVKEY === result.GVKEY).length <= 0) {
                        this.filteredCompanyList.push({
                            Assets: result.Assets ? Util.round(result.Assets, 1) : result.Assets,
                            CompanyName: result.CompanyName,
                            EBITDA: result.EBITDA ? Util.round(result.EBITDA, 1) : result.EBITDA,
                            GVKEY: result.GVKEY,
                            IndustryCodeDescription: result.IndustryCodeDescription,
                            KeyWords: result.KeyWords,
                            MarketCap: result.MarketCap ? Util.round(result.MarketCap, 1) : result.MarketCap,
                            Observations: result.Observations,
                            Sales: result.Sales ? Util.round(result.Sales, 1) : result.Sales,
                            Ticker: result.Ticker,
                            Dataasof: result.Dataasof,
                            TotalDebt: result.TotalDebt,
                            IncorporationCountry: result.IncorporationCountry,
                            HeadquarterCountry: result.HeadquarterCountry,
                            mAsOf: result.mAsOf,
                            Exchange: result.Exchange,
                            Website: result.Website,
                            IsHidden: false // setting IsHidden to false when initially values are loaded
                        });
                        // const splitData = result.IndustryCodeDescription ? result.IndustryCodeDescription.split("–") : [];
                        // if (!this.tempIndustryCodeDescription.some((ind) => ind.IndustryCode === splitData[0])) {
                        //     this.tempIndustryCodeDescription.push({
                        //         IndustryCode: splitData[0],
                        //         Sector: splitData[1]
                        //     });
                        // }
                    }
                });

                // this.lstIndustryCodeDescription = this.tempIndustryCodeDescription;
                this.pageCount = results[0].PageCount;
                this.totalCount = results[0].TotalCount;
                this.pageStart = (results[0].PageCount - 1) * 100 + 1;
                this.pageEnd = (results[0].PageCount) * (results[0].TotalCount < 100 ? results[0].TotalCount : 100);
            } else {
                this.pageCount = 1;
                this.totalCount = 0;
            }
            if (this.isBetaEstimate) {

                if (this.filteredCompanyList.length > 0) {
                    this.finalSelectedCompanies.forEach((company: BetaComparbleCompanyResults) => {
                        const companyDetails = this.filteredCompanyList.find((x: BetaComparbleCompanyResults) => x.GVKEY === company.GVKEY);
                        if (companyDetails) {
                            company.Ticker = companyDetails.Ticker;
                            company.IncorporationCountry = companyDetails.IncorporationCountry;
                            company.HeadquarterCountry = companyDetails.HeadquarterCountry;
                            company.IndustryCodeDescription = companyDetails.IndustryCodeDescription;
                            company.Dataasof = companyDetails.Dataasof;
                            company.mAsOf = companyDetails.mAsOf;
                            company.Sales = companyDetails.Sales;
                            company.Assets = companyDetails.Assets;
                            company.EBITDA = companyDetails.EBITDA;
                            company.TotalDebt = companyDetails.TotalDebt;
                            company.MarketCap = companyDetails.MarketCap;
                            company.KeyWords = companyDetails.KeyWords;
                            company.Exchange = companyDetails.Exchange
                            company.Website = companyDetails.Website
                        }
                    });


                }
                // this.finalSelectedCompanies.forEach((company: BetaComparbleCompanyResults) => {
                //     if (this.filteredCompanyList.length > 0) {
                //         const companyDetails = this.filteredCompanyList.find((x: BetaComparbleCompanyResults) => x.GVKEY === company.GVKEY);
                //         company.Ticker = companyDetails.Ticker;
                //         company.IncorporationCountry = companyDetails.IncorporationCountry;
                //         company.HeadquarterCountry = companyDetails.HeadquarterCountry;
                //         company.IndustryCodeDescription = companyDetails.IndustryCodeDescription;
                //         company.Dataasof = companyDetails.Dataasof;
                //         company.mAsOf = companyDetails.mAsOf;
                //         company.Sales = companyDetails.Sales;
                //         company.Assets = companyDetails.Assets;
                //         company.EBITDA = companyDetails.EBITDA;
                //         company.TotalDebt = companyDetails.TotalDebt;
                //         company.MarketCap = companyDetails.MarketCap;
                //         company.KeyWords = companyDetails.KeyWords;
                //         company.Exchange = companyDetails.Exchange
                //         company.Website = companyDetails.Website
                //     }
                // });
            }

            if (this.selectedFilters.filter(s => s.inputValue !== '').length > 0) {
                this.isSerchByValue = true;
            } else {
                this.isSerchByValue = false;
            }
            this.spinner.end();
            return results;
        });
    }


    public setPageCount() {
        // if (this.finalSelectedCompanies.length > 0) {
        //     let totalCount = 0;
        //     this.pageCount = 0;
        //     this.totalCount = 0;
        //     this.pageStart = (1 - 1) * 100 + 1;
        //     this.pageEnd = (1) * (totalCount < 100 ? totalCount : 100);
        // } 

        this.pageCount = 0;
        this.totalCount = 0;

    }



    public getPageResult(isLeft: boolean): void {
        if (isLeft) {
            this.pageCount = this.pageCount <= 1 ? 1 : this.pageCount - 1;
        } else if (!isLeft) {
            this.pageCount = this.pageCount + 1;
        }
        this.betaComparbleCompanyRequest.PAGE = this.pageCount;
        if (this.isSearchClicked) {
            this.getComparbleCompanyList();
        }
    }
    public tooltipDisabled(): void {
        if (this.selectedCompanies.length < 20) {
            this.addTooltipDisabled = true;
        } else {
            this.addTooltipDisabled = false;
        }
    }
    public getRoundValuvationDate(date: CommonDate): Date | string {
        if (date.day < 15) {
            return new Date(date.year, date.month - 1, 0).toDateString();
        } else {
            return new Date(date.year, date.month, 0).toDateString();
        }
    }
    public getRoundValuationDateforSelectedCompany(date: CommonDate): CommonDate {
        if (date.day < 15) {
            return new CommonDate(date.year, date.month - 1, 0);
        } else {
            return new CommonDate(date.year, date.month, 0);
        }
    }
    public getTaxRateList(unleveringTaxRateInputs: UnleveringTaxRateInputs[]): void {
        const request = this.dataStore.getTaxRateList(unleveringTaxRateInputs);
        // this.spinner.while(request);
        this.unleveringCollapse = false;
        this.unleveredBetasCollapse = false;
        request.once((results: IntlDataUnlevering[]) => {
            this.IntlTaxRates = results;
            this.unleveringCollapse = true;
            this.unleveredBetasCollapse = true;
        });
    }
    public getTaxRate(countryId: number, dataAsOf: string): void {
        const request = this.dataStore.getTaxRate(countryId, dataAsOf);

        this.releveringCollapse = false;
        request.once((results: IntlData[]) => {
            this.IntlTaxRate = results;
            this.releveringCollapse = true;

        });
    }

    public getLeveredBetas(companyRequest: CompanyReturnsRequest, unleveredBetas: LeveredBetas[], unleveringInputs: UnleveringInputs[]): void {

        const companyRequestWithGVKeysStatus = this.transformCompanyReturnsRequest(companyRequest);

        const request = this.dataStore.getLeveredBetas(companyRequestWithGVKeysStatus);
        // this.spinner.while(request);
        if (this.startSpinner) {
            this.spinner.begin();
        }
        this.leveredBetasCollapse = true;
        this.isLeverdBetaCollapse = false;
        request.once((betas: LeveredBetas[]) => {
            this.leveredBetas = [];
            this.unleveredBetas = [];
            this.weightInputs = [];
            this.unleveringInputs = [];
            const lBetas: LeveredBetas[] = [];
            const ulBetas: LeveredBetas[] = [];
            const weightInputs: WeightingInputs[] = [];
            const unlInputs: UnleveringInputs[] = [];
            // this.leveredBetasCollapse = true;
            if (betas.length > 0) {
                betas.forEach((beta: LeveredBetas) => {
                    const ulBetasExists = unleveredBetas.find((x: LeveredBetas) => x.GVKEY === beta.GVKEY);
                    const company = this.finalSelectedCompanies.find((x: ComaparableCompany) => x.GVKEY === beta.GVKEY);
                    const taxExists: any = this.unleveringTaxRateValues.find((x: UnleveringTaxRate) => x.GVKEY === beta.GVKEY);
                    const ulInputsExists = unleveringInputs.find((x: UnleveringInputs) => x.GVKEY === beta.GVKEY);
                    if (company) {
                        weightInputs.push({
                            GVKEY: beta.GVKEY,
                            Sales: company.sales === null ? "-" : Math.round(company.Sales),
                            MarketCap: company.MarketCap === null ? "-" : Math.round(company.MarketCap)
                        });
                    }

                    if (!ulInputsExists) {
                        unlInputs.push({
                            GVKEY: company.GVKEY,
                            TotalDebt: company.TotalDebt !== null ? this.RoundDecimalValue(company.TotalDebt, 1) : "-",
                            MarketCap: company.MarketCap !== null ? this.RoundDecimalValue(company.MarketCap, 1) : "-",
                            Debt_Equity: company.MarketCap !== null && (company.TotalDebt !== null && company.TotalDebt !== '-') ? this.RoundDecimalValue((Util.round(company.TotalDebt, 1) / Util.round(company.MarketCap, 1)) * 100, 2) : "-",
                            Debt_TotalCapital: company.MarketCap !== null && company.TotalDebt !== null ? this.RoundDecimalValue((Util.round(company.TotalDebt, 1) / (Util.round(company.TotalDebt, 1) + Util.round(company.MarketCap, 1))) * 100, 1) : "-",
                            TaxRate: taxExists ? taxExists.TaxRateValue : "-",
                            Label: taxExists ? taxExists.Label : "-"
                        });
                    } else {
                        unlInputs.push({
                            GVKEY: ulInputsExists.GVKEY,
                            TotalDebt: ulInputsExists.TotalDebt,
                            MarketCap: ulInputsExists.MarketCap,
                            Debt_Equity: ulInputsExists.Debt_Equity,
                            Debt_TotalCapital: ulInputsExists.Debt_TotalCapital,
                            TaxRate: ulInputsExists.TaxRate,
                            Label: taxExists ? taxExists.Label : "-"
                        });
                    }
                    if (!ulBetasExists) {
                        ulBetas.push({
                            GVKEY: beta.GVKEY,
                            OLSBeta: '-',
                            SumBeta: '-',
                            VasicekAdjusted: beta.VasicekAdjusted != null ? '-' : '-',
                            BlumeAdjusted: '-',
                            PeerGroup: '-'
                        });
                    } else {
                        ulBetas.push({
                            GVKEY: ulBetasExists.GVKEY,
                            OLSBeta: ulBetasExists.OLSBeta,
                            SumBeta: ulBetasExists.SumBeta,
                            VasicekAdjusted: ulBetasExists.VasicekAdjusted,
                            BlumeAdjusted: ulBetasExists.BlumeAdjusted,
                            PeerGroup: ulBetasExists.PeerGroup
                        });
                    }
                    lBetas.push({
                        GVKEY: beta.GVKEY,
                        OLSBeta: this.RoundDecimalValue(beta.OLSBeta, 2),
                        SumBeta: this.RoundDecimalValue(beta.SumBeta, 2),
                        VasicekAdjusted: beta.VasicekAdjusted != null ? this.RoundDecimalValue(beta.VasicekAdjusted, 2) : null,
                        BlumeAdjusted: this.RoundDecimalValue(beta.BlumeAdjusted, 2),
                        PeerGroup: this.RoundDecimalValue(beta.PeerGroup, 2)
                    });
                });
                this.gvKeys.forEach((key: any) => {
                    const item: any = lBetas.find((x) => x.GVKEY === key);
                    if (this.UserHiddenCompanies.includes(item.GVKEY)) {
                        item.IsHidden = true;
                    }
                    if (item) {
                        this.leveredBetas.push(item);
                    }
                });
                this.gvKeys.forEach((key: any) => {
                    const item: any = ulBetas.find((x) => x.GVKEY === key);
                    if (this.UserHiddenCompanies.includes(item.GVKEY)) {
                        item.IsHidden = true;
                    }
                    if (item) {
                        this.unleveredBetas.push(item);
                    }
                });
                this.gvKeys.forEach((key: any) => {
                    const item: any = weightInputs.find((x) => x.GVKEY === key);
                    if (this.UserHiddenCompanies.includes(item.GVKEY)) {
                        item.IsHidden = true;
                    }
                    if (item) {
                        this.weightInputs.push(item);
                    }
                });
                this.gvKeys.forEach((key: any) => {
                    const item: any = unlInputs.find((x) => x.GVKEY === key);
                    if (this.UserHiddenCompanies.includes(item.GVKEY)) {
                        item.IsHidden = true;
                    }
                    if (item) {
                        this.unleveringInputs.push(item);
                    }

                });
                const weightingResults = this.getSalesWeighted(this.weightInputs);
                if (this.leveredBetas.length > 0 && weightingResults.length > 0) {
                    this.getAllSummaryStatistics();
                }
            }
            this.unleveringInputs.forEach((beta: any) => {
                const company = this.finalSelectedCompanies.filter((x: any) => x.GVKEY === beta.GVKEY);
                beta.DataAsOf = company[0].Dataasof;
                beta.mAsOf = company[0].mAsOf;
            });

            this.insertTaxRateOptions();
            // if (this.finalSelectedCompanies.length > 0 && this.leveredBetas.length > 0) {
            //     this.updateEstimate('levered');
            // }
            if (this.finalSelectedCompanies.length > 0 && this.leveredBetas.length > 0) {
                this.getBetaStatistics(companyRequest);
            }
            // this.updateEstimate();
            if (this.endSpinner) {
                this.spinner.end();
            }
        });

    }
    public getAllSummaryStatistics(): void {
        try {
            const LeveredOLSBeta = this.leveredBetas.filter((x) => x.IsHidden !== true).map((x) => x.OLSBeta);
            const LeveredSumBeta = this.leveredBetas.filter((x) => x.IsHidden !== true).map((x) => x.SumBeta);
            const LeveredVasicekAdjusted = this.leveredBetas.filter((x) => x.IsHidden !== true).map((x) => x.VasicekAdjusted);
            const LeveredBlumeAdjusted = this.leveredBetas.filter((x) => x.IsHidden !== true).map((x) => x.BlumeAdjusted);
            const LeveredPeerGroup = this.leveredBetas.filter((x) => x.IsHidden !== true).map((x) => x.PeerGroup);

            const UnleveredOLSBeta = this.unleveredBetas.filter((x) => x.IsHidden !== true).map((x) => x.OLSBeta);
            const UnleveredSumBeta = this.unleveredBetas.filter((x) => x.IsHidden !== true).map((x) => x.SumBeta);
            const UnleveredVasicekAdjusted = this.unleveredBetas.filter((x) => x.IsHidden !== true).map((x) => x.VasicekAdjusted);
            const UnleveredBlumeAdjusted = this.unleveredBetas.filter((x) => x.IsHidden !== true).map((x) => x.BlumeAdjusted);
            const UnleveredPeerGroup = this.unleveredBetas.filter((x) => x.IsHidden !== true).map((x) => x.PeerGroup);

            const StatisticOLSBeta = this.betaStatistics.filter((x) => x.IsHidden !== true).map((x) => x.AdjustedOlsRSquare);
            const StatisticSumBeta = this.betaStatistics.filter((x) => x.IsHidden !== true).map((x) => x.AdjustedSumRSquare);
            const StatisticStdError = this.betaStatistics.filter((x) => x.IsHidden !== true).map((x) => x.StandardError);
            const StatisticTStat = this.betaStatistics.filter((x) => x.IsHidden !== true).map((x) => x.TStat);
            const StatisticPVal = this.betaStatistics.filter((x) => x.IsHidden !== true).map((x) => x.PValue);

            const UnleveringTotalDebt = this.unleveringInputs.filter((x) => x.IsHidden !== true).map((x) => x.TotalDebt);
            const UnleveringMarketCap = this.unleveringInputs.filter((x) => x.IsHidden !== true).map((x) => x.MarketCap);
            const UnleveringDebtEquity = this.unleveringInputs.filter((x) => x.IsHidden !== true).map((x) => x.Debt_Equity);
            const UnleveringDebtTotalCapital = this.unleveringInputs.filter((x) => x.IsHidden !== true).map((x) => x.Debt_TotalCapital);
            const UnleveringInputTaxRate = this.unleveringInputs.filter((x) => x.IsHidden !== true).map((x) => x.TaxRate);

            // const weightSalesResults = this.weightSMResults.map((x) => x.SalesWeighting);
            // const weightMarketResults = this.weightSMResults.map((x) => x.MarketCapWeighting);
            if (this.leveredBetas.length > 0) {
                this.summaryStatisticsValues.LeveredMaximumOLSBeta = Math.max(...LeveredOLSBeta).toFixed(2);
                this.summaryStatisticsValues.LeveredMaximumSumBeta = Math.max(...LeveredSumBeta).toFixed(2);
                this.summaryStatisticsValues.LeveredMaximumVasicekAdjusted = LeveredVasicekAdjusted.length === 0 || LeveredVasicekAdjusted.every((v) => v === null) ? "-" : Math.max(...LeveredVasicekAdjusted.filter((v) => v !== null)).toFixed(2);
                this.summaryStatisticsValues.LeveredMaximumBlumeAdjusted = Math.max(...LeveredBlumeAdjusted).toFixed(2);
                this.summaryStatisticsValues.LeveredMaximumPortfolioBeta = Math.max(...LeveredPeerGroup).toFixed(2);
                this.summaryStatisticsValues.LeveredMinimumOLSBeta = Math.min(...LeveredOLSBeta).toFixed(2);
                this.summaryStatisticsValues.LeveredMinimumSumBeta = Math.min(...LeveredSumBeta).toFixed(2);
                this.summaryStatisticsValues.LeveredMinimumVasicekAdjusted = LeveredVasicekAdjusted.length === 0 || LeveredVasicekAdjusted.every((v) => v === null) ? "-" : Math.min(...LeveredVasicekAdjusted.filter((v) => v !== null)).toFixed(2);
                this.summaryStatisticsValues.LeveredMinimumBlumeAdjusted = Math.min(...LeveredBlumeAdjusted).toFixed(2);
                this.summaryStatisticsValues.LeveredMinimumPortfolioBeta = Math.min(...LeveredPeerGroup).toFixed(2);
                this.summaryStatisticsValues.LeveredAverageOLSBeta = this.getAverageofleveredBetas(LeveredOLSBeta, true);
                this.summaryStatisticsValues.LeveredAverageSumBeta = this.getAverageofleveredBetas(LeveredSumBeta, true);
                this.summaryStatisticsValues.LeveredAverageVasicekAdjusted = this.getAverageofleveredBetas(LeveredVasicekAdjusted.map((v) => v === null ? null : v), true);
                this.summaryStatisticsValues.LeveredAverageBlumeAdjusted = this.getAverageofleveredBetas(LeveredBlumeAdjusted, true);
                this.summaryStatisticsValues.LeveredAveragePortfolioBeta = this.getAverageofleveredBetas(LeveredPeerGroup, true);
                this.summaryStatisticsValues.LeveredMedianOLSBeta = this.getMedianofleveredBetas(LeveredOLSBeta, true);
                this.summaryStatisticsValues.LeveredMedianSumBeta = this.getMedianofleveredBetas(LeveredSumBeta, true);
                this.summaryStatisticsValues.LeveredMedianVasicekAdjusted = this.getMedianofleveredBetas(LeveredVasicekAdjusted.map((v) => v === null ? null : v), true);
                this.summaryStatisticsValues.LeveredMedianBlumeAdjusted = this.getMedianofleveredBetas(LeveredBlumeAdjusted, true);
                this.summaryStatisticsValues.LeveredMedianPortfolioBeta = this.getMedianofleveredBetas(LeveredPeerGroup, true);
                this.summaryStatisticsValues.LeveredFirstQuartileOLSBeta = this.getFirstQuartileleveredBetas(LeveredOLSBeta, true);
                this.summaryStatisticsValues.LeveredFirstQuartileSumBeta = this.getFirstQuartileleveredBetas(LeveredSumBeta, true);
                this.summaryStatisticsValues.LeveredFirstQuartileVasicekAdjusted = this.getFirstQuartileleveredBetas(LeveredVasicekAdjusted.map((v) => v === null ? null : v), true);
                this.summaryStatisticsValues.LeveredFirstQuartileBlumeAdjusted = this.getFirstQuartileleveredBetas(LeveredBlumeAdjusted, true);
                this.summaryStatisticsValues.LeveredFirstQuartilePortfolioBeta = this.getFirstQuartileleveredBetas(LeveredPeerGroup, true);
                this.summaryStatisticsValues.LeveredThirdQuartileOLSBeta = this.getThirdQuartileleveredBetas(LeveredOLSBeta, true);
                this.summaryStatisticsValues.LeveredThirdQuartileSumBeta = this.getThirdQuartileleveredBetas(LeveredSumBeta, true);
                this.summaryStatisticsValues.LeveredThirdQuartileVasicekAdjusted = this.getThirdQuartileleveredBetas(LeveredVasicekAdjusted.map((v) => v === null ? null : v), true);
                this.summaryStatisticsValues.LeveredThirdQuartileBlumeAdjusted = this.getThirdQuartileleveredBetas(LeveredBlumeAdjusted, true);
                this.summaryStatisticsValues.LeveredThirdQuartilePortfolioBeta = this.getThirdQuartileleveredBetas(LeveredPeerGroup, true);
                // this.summaryStatisticsValues.LeveredSalesWeightedOLSBeta = this.getSumProductofWeightedInputs(LeveredOLSBeta, weightSalesResults, true);
                // this.summaryStatisticsValues.LeveredSalesWeightedSumBeta = this.getSumProductofWeightedInputs(LeveredSumBeta, weightSalesResults, true);
                // this.summaryStatisticsValues.LeveredSalesWeightedVasicekAdjusted = this.getSumProductofWeightedInputs(LeveredVasicekAdjusted.map((v) => v === null ? null : v), weightSalesResults, true);
                // this.summaryStatisticsValues.LeveredSalesWeightedBlumeAdjusted = this.getSumProductofWeightedInputs(LeveredBlumeAdjusted, weightSalesResults, true);
                // this.summaryStatisticsValues.LeveredSalesWeightedPortfolioBeta = this.getSumProductofWeightedInputs(LeveredPeerGroup, weightSalesResults, true);
                // this.summaryStatisticsValues.LeveredMarketCapWeightedOLSBeta = this.getSumProductofWeightedInputs(LeveredOLSBeta, weightMarketResults, true);
                // this.summaryStatisticsValues.LeveredMarketCapWeightedSumBeta = this.getSumProductofWeightedInputs(LeveredSumBeta, weightMarketResults, true);
                // this.summaryStatisticsValues.LeveredMarketCapWeightedVasicekAdjusted = this.getSumProductofWeightedInputs(LeveredVasicekAdjusted.map((v) => v === null ? null : v), weightMarketResults, true);
                // this.summaryStatisticsValues.LeveredMarketCapWeightedBlumeAdjusted = this.getSumProductofWeightedInputs(LeveredBlumeAdjusted, weightMarketResults, true);
                // this.summaryStatisticsValues.LeveredMarketCapWeightedPortfolioBeta = this.getSumProductofWeightedInputs(LeveredPeerGroup, weightSalesResults, true);

                this.summaryStatisticsValues.UnleveredMaximumOLSBeta = UnleveredOLSBeta.every((v) => v === "-") ? "-" : Math.max(...UnleveredOLSBeta.filter((v) => v !== "-")).toFixed(2);
                this.summaryStatisticsValues.UnleveredMaximumSumBeta = UnleveredSumBeta.every((v) => v === "-") ? "-" : Math.max(...UnleveredSumBeta.filter((v) => v !== "-")).toFixed(2);
                this.summaryStatisticsValues.UnleveredMaximumVasicekAdjusted = UnleveredVasicekAdjusted.length === 0 || UnleveredVasicekAdjusted.filter((v) => v !== "-").length < 1 || UnleveredVasicekAdjusted.filter((v) => v !== null).length < 1 || UnleveredVasicekAdjusted.every((v) => v === "-") ? "-" : Math.max(...UnleveredVasicekAdjusted.filter((v) => v !== "-")).toFixed(2);
                this.summaryStatisticsValues.UnleveredMaximumBlumeAdjusted = UnleveredBlumeAdjusted.every((v) => v === "-") || UnleveredBlumeAdjusted.filter((v) => v !== null).length < 1 ? "-" : Math.max(...UnleveredBlumeAdjusted.filter((v) => v !== "-")).toFixed(2);
                this.summaryStatisticsValues.UnleveredMaximumPortfolioBeta = UnleveredPeerGroup.every((v) => v === "-") || UnleveredPeerGroup.filter((v) => v !== null).length < 1 ? "-" : Math.max(...UnleveredPeerGroup.filter((v) => v !== "-")).toFixed(2);
                this.summaryStatisticsValues.UnleveredMinimumOLSBeta = UnleveredOLSBeta.every((v) => v === "-") ? "-" : Math.min(...UnleveredOLSBeta.filter((v) => v !== "-")).toFixed(2);
                this.summaryStatisticsValues.UnleveredMinimumSumBeta = UnleveredSumBeta.every((v) => v === "-") ? "-" : Math.min(...UnleveredSumBeta.filter((v) => v !== "-")).toFixed(2);
                this.summaryStatisticsValues.UnleveredMinimumVasicekAdjusted = UnleveredVasicekAdjusted.length === 0 || UnleveredVasicekAdjusted.filter((v) => v !== "-").length < 1 || UnleveredVasicekAdjusted.filter((v) => v !== null).length < 1 || UnleveredVasicekAdjusted.every((v) => v === "-") ? "-" : Math.min(...UnleveredVasicekAdjusted.filter((v) => v !== "-")).toFixed(2);
                this.summaryStatisticsValues.UnleveredMinimumBlumeAdjusted = UnleveredBlumeAdjusted.every((v) => v === "-") || UnleveredBlumeAdjusted.filter((v) => v !== null).length < 1 ? "-" : Math.min(...UnleveredBlumeAdjusted.filter((v) => v !== "-")).toFixed(2);
                this.summaryStatisticsValues.UnleveredMinimumPortfolioBeta = UnleveredPeerGroup.every((v) => v === "-") || UnleveredPeerGroup.filter((v) => v !== null).length < 1 ? "-" : Math.min(...UnleveredPeerGroup.filter((v) => v !== "-")).toFixed(2);
                this.summaryStatisticsValues.UnleveredAverageOLSBeta = this.getAverageofleveredBetas(UnleveredOLSBeta, false);
                this.summaryStatisticsValues.UnleveredAverageSumBeta = this.getAverageofleveredBetas(UnleveredSumBeta, false);
                this.summaryStatisticsValues.UnleveredAverageVasicekAdjusted = this.getAverageofleveredBetas(UnleveredVasicekAdjusted.map((v) => v === null ? null : v), false);
                this.summaryStatisticsValues.UnleveredAverageBlumeAdjusted = this.getAverageofleveredBetas(UnleveredBlumeAdjusted, false);
                this.summaryStatisticsValues.UnleveredAveragePortfolioBeta = this.getAverageofleveredBetas(UnleveredPeerGroup, false);
                this.summaryStatisticsValues.UnleveredMedianOLSBeta = this.getMedianofleveredBetas(UnleveredOLSBeta, false);
                this.summaryStatisticsValues.UnleveredMedianSumBeta = this.getMedianofleveredBetas(UnleveredSumBeta, false);
                this.summaryStatisticsValues.UnleveredMedianVasicekAdjusted = this.getMedianofleveredBetas(UnleveredVasicekAdjusted.map((v) => v === null ? null : v), false);
                this.summaryStatisticsValues.UnleveredMedianBlumeAdjusted = this.getMedianofleveredBetas(UnleveredBlumeAdjusted, false);
                this.summaryStatisticsValues.UnleveredMedianPortfolioBeta = this.getMedianofleveredBetas(UnleveredPeerGroup, false);
                this.summaryStatisticsValues.UnleveredFirstQuartileOLSBeta = this.getFirstQuartileleveredBetas(UnleveredOLSBeta, false);
                this.summaryStatisticsValues.UnleveredFirstQuartileSumBeta = this.getFirstQuartileleveredBetas(UnleveredSumBeta, false);
                this.summaryStatisticsValues.UnleveredFirstQuartileVasicekAdjusted = this.getFirstQuartileleveredBetas(UnleveredVasicekAdjusted.map((v) => v === null ? null : v), false);
                this.summaryStatisticsValues.UnleveredFirstQuartileBlumeAdjusted = this.getFirstQuartileleveredBetas(UnleveredBlumeAdjusted, false);
                this.summaryStatisticsValues.UnleveredFirstQuartilePortfolioBeta = this.getFirstQuartileleveredBetas(UnleveredPeerGroup, false);
                this.summaryStatisticsValues.UnleveredThirdQuartileOLSBeta = this.getThirdQuartileleveredBetas(UnleveredOLSBeta, false);
                this.summaryStatisticsValues.UnleveredThirdQuartileSumBeta = this.getThirdQuartileleveredBetas(UnleveredSumBeta, false);
                this.summaryStatisticsValues.UnleveredThirdQuartileVasicekAdjusted = this.getThirdQuartileleveredBetas(UnleveredVasicekAdjusted.map((v) => v === null ? null : v), false);
                this.summaryStatisticsValues.UnleveredThirdQuartileBlumeAdjusted = this.getThirdQuartileleveredBetas(UnleveredBlumeAdjusted, false);
                this.summaryStatisticsValues.UnleveredThirdQuartilePortfolioBeta = this.getThirdQuartileleveredBetas(UnleveredPeerGroup, false);
                // this.summaryStatisticsValues.UnleveredSalesWeightedOLSBeta = this.getSumProductofWeightedInputs(UnleveredOLSBeta, weightSalesResults, false);
                // this.summaryStatisticsValues.UnleveredSalesWeightedSumBeta = this.getSumProductofWeightedInputs(UnleveredSumBeta, weightSalesResults, false);
                // this.summaryStatisticsValues.UnleveredSalesWeightedVasicekAdjusted = this.getSumProductofWeightedInputs(UnleveredVasicekAdjusted.map((v) => v === null ? null : v), weightSalesResults, false);
                // this.summaryStatisticsValues.UnleveredSalesWeightedBlumeAdjusted = this.getSumProductofWeightedInputs(UnleveredBlumeAdjusted, weightSalesResults, false);
                // this.summaryStatisticsValues.UnleveredSalesWeightedPortfolioBeta = this.getSumProductofWeightedInputs(UnleveredPeerGroup, weightSalesResults, false);
                // this.summaryStatisticsValues.UnleveredMarketCapWeightedOLSBeta = this.getSumProductofWeightedInputs(UnleveredOLSBeta, weightMarketResults, false);
                // this.summaryStatisticsValues.UnleveredMarketCapWeightedSumBeta = this.getSumProductofWeightedInputs(UnleveredSumBeta, weightMarketResults, false);
                // this.summaryStatisticsValues.UnleveredMarketCapWeightedVasicekAdjusted = this.getSumProductofWeightedInputs(UnleveredVasicekAdjusted.map((v) => v === null ? null : v), weightMarketResults, false);
                // this.summaryStatisticsValues.UnleveredMarketCapWeightedBlumeAdjusted = this.getSumProductofWeightedInputs(UnleveredBlumeAdjusted, weightMarketResults, false);
                // this.summaryStatisticsValues.UnleveredMarketCapWeightedPortfolioBeta = this.getSumProductofWeightedInputs(UnleveredPeerGroup, weightMarketResults, false);
            }

            if (this.betaStatistics.length > 0) {
                this.summaryStatisticsValues.MaximumAdjustedOlsRSquare = Math.max(...StatisticOLSBeta).toFixed(2);
                this.summaryStatisticsValues.MaximumAdjustedSumRSquare = Math.max(...StatisticSumBeta).toFixed(2);
                this.summaryStatisticsValues.MaximumStandardError = Math.max(...StatisticStdError).toFixed(2);
                this.summaryStatisticsValues.MaximumTStat = Math.max(...StatisticTStat).toFixed(2);
                this.summaryStatisticsValues.MaximumPValue = Math.max(...StatisticPVal).toFixed(2);

                this.summaryStatisticsValues.MinimumAdjustedOlsRSquare = Math.min(...StatisticOLSBeta).toFixed(2);
                this.summaryStatisticsValues.MinimumAdjustedSumRSquare = Math.min(...StatisticSumBeta).toFixed(2);
                this.summaryStatisticsValues.MinimumStandardError = Math.min(...StatisticStdError).toFixed(2);
                this.summaryStatisticsValues.MinimumTStat = Math.min(...StatisticTStat).toFixed(2);
                this.summaryStatisticsValues.MinimumPValue = Math.min(...StatisticPVal).toFixed(2);

                this.summaryStatisticsValues.AverageAdjustedOlsRSquare = this.getAverageofleveredBetas(StatisticOLSBeta, true);
                this.summaryStatisticsValues.AverageAdjustedSumRSquare = this.getAverageofleveredBetas(StatisticSumBeta, true);
                this.summaryStatisticsValues.AverageStandardError = this.getAverageofleveredBetas(StatisticStdError, true);
                this.summaryStatisticsValues.AverageTStat = this.getAverageofleveredBetas(StatisticTStat, true);
                this.summaryStatisticsValues.AveragePValue = this.getAverageofleveredBetas(StatisticPVal, true);

                this.summaryStatisticsValues.FirstQuartileAdjustedOlsRSquare = this.getFirstQuartileleveredBetas(StatisticOLSBeta, true);
                this.summaryStatisticsValues.FirstQuartileAdjustedSumRSquare = this.getFirstQuartileleveredBetas(StatisticSumBeta, true);
                this.summaryStatisticsValues.FirstQuartileStandardError = this.getFirstQuartileleveredBetas(StatisticStdError, true);
                this.summaryStatisticsValues.FirstQuartileTStat = this.getFirstQuartileleveredBetas(StatisticTStat, true);
                this.summaryStatisticsValues.FirstQuartilePValue = this.getFirstQuartileleveredBetas(StatisticPVal, true);

                this.summaryStatisticsValues.ThirdQuartileAdjustedOlsRSquare = this.getThirdQuartileleveredBetas(StatisticOLSBeta, true);
                this.summaryStatisticsValues.ThirdQuartileAdjustedSumRSquare = this.getThirdQuartileleveredBetas(StatisticSumBeta, true);
                this.summaryStatisticsValues.ThirdQuartileStandardError = this.getThirdQuartileleveredBetas(StatisticStdError, true);
                this.summaryStatisticsValues.ThirdQuartileTStat = this.getThirdQuartileleveredBetas(StatisticTStat, true);
                this.summaryStatisticsValues.ThirdQuartilePValue = this.getThirdQuartileleveredBetas(StatisticPVal, true);

                this.summaryStatisticsValues.MedianAdjustedOlsRSquare = this.getMedianofleveredBetas(StatisticOLSBeta, true);
                this.summaryStatisticsValues.MedianAdjustedSumRSquare = this.getMedianofleveredBetas(StatisticSumBeta, true);
                this.summaryStatisticsValues.MedianStandardError = this.getMedianofleveredBetas(StatisticStdError, true);
                this.summaryStatisticsValues.MedianTStat = this.getMedianofleveredBetas(StatisticTStat, true);
                this.summaryStatisticsValues.MedianPValue = this.getMedianofleveredBetas(StatisticPVal, true);

                // this.summaryStatisticsValues.SalesWeightedAdjustedOlsRSquare = this.getSumProductofWeightedInputs(StatisticOLSBeta, weightSalesResults, true);
                // this.summaryStatisticsValues.SalesWeightedAdjustedSumRSquare = this.getSumProductofWeightedInputs(StatisticSumBeta, weightSalesResults, true);
                // this.summaryStatisticsValues.SalesWeightedStandardError = this.getSumProductofWeightedInputs(StatisticStdError, weightSalesResults, true);
                // this.summaryStatisticsValues.SalesWeightedTStat = this.getSumProductofWeightedInputs(StatisticTStat, weightSalesResults, true);
                // this.summaryStatisticsValues.SalesWeightedPValue = this.getSumProductofWeightedInputs(StatisticPVal, weightSalesResults, true);

                // this.summaryStatisticsValues.MarketCapWeightedAdjustedOlsRSquare = this.getSumProductofWeightedInputs(StatisticOLSBeta, weightMarketResults, true);
                // this.summaryStatisticsValues.MarketCapWeightedAdjustedSumRSquare = this.getSumProductofWeightedInputs(StatisticSumBeta, weightMarketResults, true);
                // this.summaryStatisticsValues.MarketCapWeightedStandardError = this.getSumProductofWeightedInputs(StatisticStdError, weightMarketResults, true);
                // this.summaryStatisticsValues.MarketCapWeightedTStat = this.getSumProductofWeightedInputs(StatisticTStat, weightMarketResults, true);
                // this.summaryStatisticsValues.MarketCapWeightedPValue = this.getSumProductofWeightedInputs(StatisticPVal, weightMarketResults, true);
            }
            if (this.unleveringInputs.length > 0) {
                this.summaryStatisticsValues.MaximumTotalDebt = UnleveringTotalDebt.length === 0 || UnleveringTotalDebt.filter((v) => v !== "-").length < 1 || UnleveringTotalDebt.filter((v) => v !== null).length < 1 || UnleveringTotalDebt.every((v) => v === "-") ? "-" : Math.max(...UnleveringTotalDebt.filter((v) => v !== "-")).toFixed(2);
                this.summaryStatisticsValues.MinimumTotalDebt = UnleveringTotalDebt.length === 0 || UnleveringTotalDebt.filter((v) => v !== "-").length < 1 || UnleveringTotalDebt.filter((v) => v !== null).length < 1 || UnleveringTotalDebt.every((v) => v === "-") ? "-" : Math.min(...UnleveringTotalDebt.filter((v) => v !== "-")).toFixed(2);
                this.summaryStatisticsValues.AverageTotalDebt = this.getAverageofleveredBetas(UnleveringTotalDebt.map((v) => v === null ? null : v), false);
                this.summaryStatisticsValues.MedianTotalDebt = this.getMedianofleveredBetas(UnleveringTotalDebt.map((v) => v === null ? null : v), false);
                this.summaryStatisticsValues.FirstQuartileTotalDebt = this.getFirstQuartileleveredBetas(UnleveringTotalDebt.map((v) => v === null ? null : v), false);
                this.summaryStatisticsValues.ThirdQuartileTotalDebt = this.getThirdQuartileleveredBetas(UnleveringTotalDebt.map((v) => v === null ? null : v), false);
                // this.summaryStatisticsValues.SalesWeightedTotalDebt = this.getSumProductofWeightedInputs(UnleveringTotalDebt.map((v) => v === null ? null : v), weightSalesResults, false);
                // this.summaryStatisticsValues.MarketCapWeightedTotalDebt = this.getSumProductofWeightedInputs(UnleveringTotalDebt.map((v) => v === null ? null : v), weightMarketResults, false);

                this.summaryStatisticsValues.MaximumMarketCap = UnleveringMarketCap.length === 0 || UnleveringMarketCap.filter((v) => v !== "-").length < 1 || UnleveringMarketCap.filter((v) => v !== null).length < 1 || UnleveringMarketCap.every((v) => v === "-") ? "-" : Math.max(...UnleveringMarketCap.filter((v) => v !== "-")).toFixed(2);
                this.summaryStatisticsValues.MinimumMarketCap = UnleveringMarketCap.length === 0 || UnleveringMarketCap.filter((v) => v !== "-").length < 1 || UnleveringMarketCap.filter((v) => v !== null).length < 1 || UnleveringMarketCap.every((v) => v === "-") ? "-" : Math.min(...UnleveringMarketCap.filter((v) => v !== "-")).toFixed(2);
                this.summaryStatisticsValues.AverageMarketCap = this.getAverageofleveredBetas(UnleveringMarketCap.map((v) => v === null ? null : v), false);
                this.summaryStatisticsValues.MedianMarketCap = this.getMedianofleveredBetas(UnleveringMarketCap.map((v) => v === null ? null : v), false);
                this.summaryStatisticsValues.FirstQuartileMarketCap = this.getFirstQuartileleveredBetas(UnleveringMarketCap.map((v) => v === null ? null : v), false);
                this.summaryStatisticsValues.ThirdQuartileMarketCap = this.getThirdQuartileleveredBetas(UnleveringMarketCap.map((v) => v === null ? null : v), false);
                // this.summaryStatisticsValues.SalesWeightedMarketCap = this.getSumProductofWeightedInputs(UnleveringMarketCap.map((v) => v === null ? null : v), weightSalesResults, false);
                // this.summaryStatisticsValues.MarketCapWeightedMarketCap = this.getSumProductofWeightedInputs(UnleveringMarketCap.map((v) => v === null ? null : v), weightMarketResults, false);

                this.summaryStatisticsValues.MaximumDebt_Equity = UnleveringDebtEquity.length === 0 || UnleveringDebtEquity.filter((v) => v !== "-").length < 1 || UnleveringDebtEquity.filter((v) => v !== null).length < 1 || UnleveringDebtEquity.every((v) => v === "-") ? "-" : Math.max(...UnleveringDebtEquity.filter((v) => v !== "-")).toFixed(2);
                this.summaryStatisticsValues.MinimumDebt_Equity = UnleveringDebtEquity.length === 0 || UnleveringDebtEquity.filter((v) => v !== "-").length < 1 || UnleveringDebtEquity.filter((v) => v !== null).length < 1 || UnleveringDebtEquity.every((v) => v === "-") ? "-" : Math.min(...UnleveringDebtEquity.filter((v) => v !== "-")).toFixed(2);
                this.summaryStatisticsValues.AverageDebt_Equity = this.getAverageofleveredBetas(UnleveringDebtEquity.map((v) => v === null ? null : v), false);
                this.summaryStatisticsValues.MedianDebt_Equity = this.getMedianofleveredBetas(UnleveringDebtEquity.map((v) => v === null ? null : v), false);
                this.summaryStatisticsValues.AverageDebt_TotalCapital = this.getAverageofleveredBetas(UnleveringDebtTotalCapital.map((v) => v === null ? null : v), false);
                this.summaryStatisticsValues.MedianDebt_TotalCapital = this.getMedianofleveredBetas(UnleveringDebtTotalCapital.map((v) => v === null ? null : v), false);
                this.summaryStatisticsValues.FirstQuartileDebt_Equity = this.getFirstQuartileleveredBetas(UnleveringDebtEquity.map((v) => v === null ? null : v), false);
                this.summaryStatisticsValues.ThirdQuartileDebt_Equity = this.getThirdQuartileleveredBetas(UnleveringDebtEquity.map((v) => v === null ? null : v), false);
                // this.summaryStatisticsValues.SalesWeightedDebt_Equity = this.getSumProductofWeightedInputs(UnleveringDebtEquity.map((v) => v === null ? null : v), weightSalesResults, false);
                // this.summaryStatisticsValues.MarketCapWeightedDebt_Equity = this.getSumProductofWeightedInputs(UnleveringDebtEquity.map((v) => v === null ? null : v), weightMarketResults, false);

                this.summaryStatisticsValues.MaximumTaxRate = UnleveringInputTaxRate.length === 0 || UnleveringInputTaxRate.filter((v) => v !== "-").length < 1 || UnleveringInputTaxRate.filter((v) => v !== null).length < 1 || UnleveringInputTaxRate.every((v) => v === "-") ? "-" : Math.max(...UnleveringInputTaxRate.filter((v) => v !== "-")).toFixed(2);
                this.summaryStatisticsValues.MinimumTaxRate = UnleveringInputTaxRate.length === 0 || UnleveringInputTaxRate.filter((v) => v !== "-").length < 1 || UnleveringInputTaxRate.filter((v) => v !== null).length < 1 || UnleveringInputTaxRate.every((v) => v === "-") ? "-" : Math.min(...UnleveringInputTaxRate.filter((v) => v !== "-")).toFixed(2);
                this.summaryStatisticsValues.AverageTaxRate = this.getAverageofleveredBetas(UnleveringInputTaxRate.map((v) => v === null ? null : v), false);
                this.summaryStatisticsValues.MedianTaxRate = this.getMedianofleveredBetas(UnleveringInputTaxRate.map((v) => v === null ? null : v), false);
                this.summaryStatisticsValues.FirstQuartileTaxRate = this.getFirstQuartileleveredBetas(UnleveringInputTaxRate.map((v) => v === null ? null : v), false);
                this.summaryStatisticsValues.ThirdQuartileTaxRate = this.getThirdQuartileleveredBetas(UnleveringInputTaxRate.map((v) => v === null ? null : v), false);
                // this.summaryStatisticsValues.SalesWeightedTaxRate = this.getSumProductofWeightedInputs(UnleveringInputTaxRate.map((v) => v === null ? null : v), weightSalesResults, false);
                // this.summaryStatisticsValues.MarketCapWeightedTaxRate = this.getSumProductofWeightedInputs(UnleveringInputTaxRate.map((v) => v === null ? null : v), weightMarketResults, false);
            }
        } catch (error) {
            console.log("Error", error);

        }

    }

    // public getSumProductofWeightedInputs(weightInputs: any[], weightResults: any[], isLevered: boolean): any {
    //     const data = isLevered === true ? weightInputs.filter((x) => x !== null) : weightInputs.filter((x) => x !== "-");
    //     console.log(weightInputs.length, weightResults.length);

    //     if (data.length < 1) {
    //         return "-";
    //     }
    //     if (weightInputs.length !== weightResults.length) {
    //         throw new Error("weightInputs and weightResults must be of same length");
    //     }
    //     const newWeightInputs: any[] = [];
    //     const newWeightResults: any[] = [];
    //     if (isLevered === true) {
    //         for (let i = 0; i < weightInputs.length; i++) {
    //             if (weightInputs[i] !== null) {
    //                 newWeightInputs.push(weightInputs[i]);
    //                 newWeightResults.push(weightResults[i]);
    //             }
    //         }
    //         const result = newWeightInputs.map((x, i) => x * newWeightResults[i]).reduce((a, b) => a + b);
    //         return result.toFixed(2);
    //     } else {
    //         for (let i = 0; i < weightInputs.length; i++) {
    //             if (weightInputs[i] !== "-") {
    //                 newWeightInputs.push(weightInputs[i]);
    //                 newWeightResults.push(weightResults[i]);
    //             }
    //         }
    //         const result = newWeightInputs.map((x, i) => x * newWeightResults[i]).reduce((a, b) => a + b);
    //         return result.toFixed(2);
    //     }

    // }

    public getMedianofleveredBetas(values: any[], isLevered: boolean): any {
        const data = isLevered === true ? values.filter((x) => x !== null) : values.filter((x) => x !== "-");

        if (data.length < 1) {
            return "-";
        }

        //making sure that we don't have any null, '-' or undefined values in array that can result in NaN values
        for (var i = 0; i < data.length; i++) {
            if (data[i] == null || data[i] == '-' || data[i] == undefined) {
                data[i] = 0;
            }
        }
        // Sort the array
        data.sort((a, b) => a - b);
        // Find the median
        const middle = Math.floor(data.length / 2);
        if (data.length % 2 === 1) {
            return data[middle];
        } else {
            const result = (parseFloat(data[middle - 1]) + parseFloat(data[middle])) / 2;
            return result.toFixed(2);
        }
    }
    public getAverageofleveredBetas(values: any[], isLevered: boolean): any {
        let sum = 0;

        const newvalues = isLevered === true ? values.filter((x) => x !== null) : values.filter((x) => x !== "-");
        if (newvalues.length < 1) {
            return "-";
        }

        //making sure that we don't have any null, '-' or undefined values in array that can result in NaN values
        for (var i = 0; i < newvalues.length; i++) {
            if (newvalues[i] == null || newvalues[i] == '-' || newvalues[i] == undefined) {
                newvalues[i] = 0;
            }
        }
        for (const value of newvalues) {
            sum += parseFloat(value);
        }

        const avg = sum / newvalues.length;
        return avg.toFixed(2);

    }

    public getFirstQuartileleveredBetas(arr: any[], isLevered: boolean): any {
        const values = isLevered === true ? arr.filter((x) => x !== null) : arr.filter((x) => x !== "-");
        const isSameValues = values.every((v) => v === values[0]);
        if (values.length < 1) {
            return "-";
        }
        if (values.length === 1 || isSameValues) {
            return values[0];
        }
        const sortedvalues = values.sort((a, b) => a - b);
        if (sortedvalues.length % 2 === 1) {
            const Q2 = sortedvalues[Math.floor(sortedvalues.length / 2)];
            // const Q0 = sortedvalues[0];
            const lowerHalf = sortedvalues.slice(0, sortedvalues.indexOf(Q2));
            if (lowerHalf.length === 1) {
                const lowerHalfnew = sortedvalues.slice(0, 2);
                const result = this.getMedianofleveredBetas(lowerHalfnew, true);
                return result;
            }
            if (lowerHalf.length > 1) {
                const result = this.getMedianofleveredBetas(lowerHalf, true);
                return result;
            }
            if (lowerHalf.length === 0) {
                const result = Q2;
                return result;
            }
        } else {
            const position = (sortedvalues.length + 1) / 4;
            const nearestposition = Math.round(position);
            const floorposition = Math.floor(position);
            const ceilposition = Math.ceil(position);
            const left = parseFloat(sortedvalues[nearestposition - 1]);
            const right = parseFloat(sortedvalues[nearestposition]);
            const Q1 = (left * (position - floorposition) + right * (ceilposition - position));
            return Q1.toFixed(2);
        }

    }
    public getThirdQuartileleveredBetas(arr: any[], isLevered: boolean): any {
        const values = isLevered === true ? arr.filter((x) => x !== null) : arr.filter((x) => x !== "-");
        const isSameValues = values.every((v) => v === values[0]);
        if (values.length < 1) {
            return "-";
        }
        if (values.length === 1 || isSameValues) {
            return values[0];
        }
        const sortedvalues = values.sort((a, b) => a - b);
        if (sortedvalues.length % 2 === 1) {
            const Q4position = sortedvalues[sortedvalues.length];
            const Q2 = sortedvalues[Math.floor(sortedvalues.length / 2)];
            // const Q0 = sortedvalues[0];
            const lowerHalf = sortedvalues.slice(sortedvalues.indexOf(Q2), Q4position);
            if (lowerHalf.length === 1) {
                const lowerHalfnew = sortedvalues.slice(sortedvalues.indexOf(Q2), 4);
                const result = this.getMedianofleveredBetas(lowerHalfnew, true);
                return result;
            }
            if (lowerHalf.length > 1) {
                const result = this.getMedianofleveredBetas(lowerHalf, true);
                return result;
            }
        } else {
            const position = (sortedvalues.length + 1) * 3 / 4;
            const nearestposition = Math.round(position);
            const floorposition = Math.floor(position);
            const ceilposition = Math.ceil(position);
            const left = parseFloat(sortedvalues[nearestposition - 2]);
            const right = parseFloat(sortedvalues[nearestposition - 1]);
            const Q1 = (left * (position - floorposition) + right * (ceilposition - position));
            return Q1.toFixed(2);
        }
    }

    public getSalesWeighted(weightSales: WeightingInputs[]): WeightResults[] {
        const weightSalesInputs = weightSales.map((x) => x.Sales);
        const weightMarketInputs = weightSales.map((x) => x.MarketCap);
        this.weightSMResults = [];
        const weightResults: WeightResults[] = [];
        this.finalSelectedCompanies.forEach((company: any) => {
            if (this.leveredBetas.find((x: { GVKEY: any }) => x.GVKEY === company.GVKEY)) {
                weightResults.push({
                    GVKEY: company.GVKEY,
                    SalesWeighting: this.weightSMCalculations(company.Sales, weightSalesInputs),
                    MarketCapWeighting: this.weightSMCalculations(company.MarketCap, weightMarketInputs)
                });
            }
        });
        this.gvKeys.forEach((key: any) => {
            const item: any = weightResults.find((x) => x.GVKEY === key);
            if (item) {
                this.weightSMResults.push(item);
            }
        });
        return this.weightSMResults;
    }

    public weightSMCalculations(company: any, weightInputs: any[]): any {
        const weightInput = company;
        if (weightInput === null) {
            return "-";
        }
        const values = weightInputs.filter((v) => v != null);
        if (values.length < 1) {
            return "-";
        }
        const sum = weightInputs.reduce((acc, val) => acc + val);
        const result = (sum === 0) ? "-" : (weightInput / sum).toFixed(2);
        return result;
    }

    public getBetaStatistics(companyRequest: CompanyReturnsRequest): void {
        const request = this.dataStore.getBetaStatistics(companyRequest);
        // this.spinner.while(request);
        request.once((betas: BetaStatistics[]) => {
            this.betaStatistics = [];
            if (betas.length > 0) {
                const stats: BetaStatistics[] = [];
                betas.forEach((beta: BetaStatistics) => {
                    stats.push({
                        GVKey: beta.GVKey,
                        AdjustedOlsRSquare: this.RoundDecimalValue(beta.AdjustedOlsRSquare, 2),
                        AdjustedSumRSquare: this.RoundDecimalValue(beta.AdjustedSumRSquare, 2),
                        StandardError: this.RoundDecimalValue(beta.StandardError, 2),
                        TStat: this.RoundDecimalValue(beta.TStat, 2),
                        PValue: this.RoundDecimalValue(beta.PValue, 2),
                        SignificantLevel1: beta.SignificantLevel1,
                        SignificantLevel2: beta.SignificantLevel2,
                        SignificantLevel3: beta.SignificantLevel3
                    });
                });
                this.gvKeys.forEach((key: any) => {
                    const item: any = stats.find((x) => x.GVKey === key);
                    if (this.UserHiddenCompanies.includes(item.GVKey)) {
                        item.IsHidden = true;
                    }
                    if (item) {
                        this.betaStatistics.push(item);
                    }
                });
                if (this.betaStatistics.length > 0) {
                    this.getAllSummaryStatistics();
                }
                if (this.finalSelectedCompanies.length > 0 && this.leveredBetas.length > 0 && this.betaStatistics.length > 0 && !this.betasUpdated) {
                    this.updateEstimate('statistics');
                }

            }
        });

    }
    public unleveringTaxInputs(unleveringInputTax: UnleveringTaxRate): void {
        const ulInput = this.unleveringInputs.find((x: { GVKEY: string }) => x.GVKEY === unleveringInputTax.GVKEY);
        if (ulInput) {
            ulInput.TaxRate = unleveringInputTax.TaxRateValue;
            ulInput.Label = unleveringInputTax.Label;
        }
    }
    public getCompanyReturns(companyRequest: CompanyReturnsRequest): void {
        const request = this.dataStore.getCompanyReturns(companyRequest);
        //this.spinner.while(request);
        request.once((returns: CompanyReturns[]) => {
            if (this.seriesLength >= 1) {
                for (let i = this.seriesLength; i >= 1; i--) {
                    this.chart.removeSeries(i);
                }
                this.chart.options.series.splice(1, this.seriesLength);
            }
            this.companyReturns = returns;
            if (returns.length > 0) {
                let index = 0;
                this.finalSelectedCompanies.forEach((company: ComaparableCompany) => {
                    let VisibleSeriesStatus = false;
                    VisibleSeriesStatus = this.UserHiddenCompanies.includes(company.GVKEY) ? false : true;
                    let comparableCompanyReturns: any[] = [];
                    const companyName = company.CompanyName;
                    const companyReturn = returns.filter((x) => x.GVKEY === company.GVKEY);
                    if (companyReturn.length > 0) {
                        companyReturn.forEach((_returns) => {
                            comparableCompanyReturns = [...comparableCompanyReturns, _returns.TR];
                        });
                    }
                    this.chart.addSeries({
                        type: 'line',
                        name: companyName,
                        color: BetaColors.Colors[index],
                        data: [...comparableCompanyReturns],
                        marker: {
                            enabled: false
                        },
                        visible: VisibleSeriesStatus
                    });
                    this.chart.options.series.push({
                        type: 'line',
                        name: companyName,
                        color: BetaColors.Colors[index],
                        data: [...comparableCompanyReturns],
                        marker: {
                            enabled: false
                        },
                        visible: VisibleSeriesStatus
                    });
                    index++;
                });
                this.seriesLength = index;
            }
            if (!this.isSubmitClicked) {
                this.spinner.end();
            }

        });
    }
    public getUnleveredBetas(unleveringTaxRate: UnleveringTaxRate): void {
        // const ulBetas: UnLeveredBetas[] = [];
        const taxRateValues: UnleveringTaxRate[] = [];
        const company = this.finalSelectedCompanies.find((x: { GVKEY: string; }) => x.GVKEY === unleveringTaxRate.GVKEY);

        if (unleveringTaxRate) {
            taxRateValues.push({
                GVKEY: unleveringTaxRate.GVKEY,
                TaxRateValue: unleveringTaxRate.TaxRateValue,
                Label: unleveringTaxRate.Label
            });
        }
        if (!unleveringTaxRate.TaxRateValue) {
            const ulbeta = this.unleveredBetas.find((x: { GVKEY: string }) => x.GVKEY === unleveringTaxRate.GVKEY);
            if (ulbeta) {
                ulbeta.GVKEY = unleveringTaxRate.GVKEY;
                ulbeta.OLSBeta = '-';
                ulbeta.SumBeta = '-';
                ulbeta.VasicekAdjusted = '-';
                ulbeta.BlumeAdjusted = '-';
                ulbeta.PeerGroup = '-';
            }
        } else {
            if ((company.TotalDebt !== null || company.TotalDebt !== undefined) && company.MarketCap) {
                this.Debt = (company.TotalDebt / company.MarketCap);
            }
            if (company.GVKEY === unleveringTaxRate.GVKEY) {
                this.TaxRate = unleveringTaxRate.TaxRateValue;
            }
            if ((this.Debt !== null || this.Debt !== undefined) && this.TaxRate) {
                this.UnleveredDenominatorValue = (100 + (100 - this.TaxRate) * this.Debt);
            }
            const beta = this.leveredBetas.find((x: { GVKEY: string }) => x.GVKEY === unleveringTaxRate.GVKEY);
            const ulbeta = this.unleveredBetas.find((x: { GVKEY: string }) => x.GVKEY === unleveringTaxRate.GVKEY);

            if (ulbeta && beta && unleveringTaxRate.TaxRateValue) {
                ulbeta.GVKEY = unleveringTaxRate.GVKEY;
                ulbeta.OLSBeta = this.RoundDecimalValue(((beta.OLSBeta / this.UnleveredDenominatorValue) * 100), 2);
                ulbeta.SumBeta = this.RoundDecimalValue(((beta.SumBeta / this.UnleveredDenominatorValue) * 100), 2);
                ulbeta.VasicekAdjusted = (beta.VasicekAdjusted != null && beta.VasicekAdjusted != '-') ? this.RoundDecimalValue(((beta.VasicekAdjusted / this.UnleveredDenominatorValue) * 100), 2) : "-";
                ulbeta.BlumeAdjusted = this.RoundDecimalValue(((beta.BlumeAdjusted / this.UnleveredDenominatorValue) * 100), 2);
                ulbeta.PeerGroup = this.RoundDecimalValue(((beta.PeerGroup / this.UnleveredDenominatorValue) * 100), 2);
            }

        }
        const taxItem: any = taxRateValues.find((x) => x.GVKEY === unleveringTaxRate.GVKEY);
        const companyGVKEY = this.unleveringTaxRateValues.find((x: { GVKEY: string }) => x.GVKEY === unleveringTaxRate.GVKEY);
        if (taxItem && !companyGVKEY) {
            this.unleveringTaxRateValues.push(taxItem);
        }
        if (companyGVKEY) {
            if (taxItem.TaxRateValue !== companyGVKEY.TaxRateValue) {
                const taxvalueUpdated = this.unleveringTaxRateValues.find((x) => x.GVKEY === companyGVKEY.GVKEY);
                if (taxvalueUpdated) {
                    taxvalueUpdated.TaxRateValue = taxItem.TaxRateValue;
                }
            }
        }

    }
    public insertTaxRateReleveredOptions(): void {
        this.taxRateReleveredOptions = [];
        this.releveredAverageTaxRate = this.summaryStatisticsValues.AverageTaxRate;
        this.releveredMedianTaxRate = this.summaryStatisticsValues.MedianTaxRate;
        const taxRate: IntlData | null = this.IntlTaxRate.length > 0
            ? this.IntlTaxRate[0] : null;
        this.releveredTaxRate = taxRate;
        if (this.releveredTaxRate) {
            this.taxRateReleveredOptions.push({
                name: NumberFormatUtil.numberWithCommas(Util.round(this.releveredTaxRate.Value), 2).toString() + '% - ' + this.releveredTaxRate.Label,
                value: NumberFormatUtil.numberWithCommas(Util.round(this.releveredTaxRate.Value), 2).toString(),
                type: ComboboxDataType.String,
                source: this.releveredTaxRate.Label,
                id: 3
            });
        }

        // if (this.releveredAverageTaxRate !== "-" && this.releveredMedianTaxRate !== "-") {
        //     this.taxRateReleveredOptions.push({
        //         name: this.releveredAverageTaxRate + '% - ' + "Average Tax Rate of Comparable Companies",
        //         value: this.releveredAverageTaxRate,
        //         type: ComboboxDataType.String,
        //         source: "Average Tax Rate of Comparable Companies",
        //         id: 1
        //     },
        //         {
        //             name: this.releveredMedianTaxRate + '% - ' + "Median Tax Rate of Comparable Companies",
        //             value: this.releveredMedianTaxRate,
        //             type: ComboboxDataType.String,
        //             source: "Median Tax Rate of Comparable Companies",
        //             id: 2
        //         }
        //     );
        // }

    }
    public insertTargetCapitalRateOptions(): void {
        this.targetCapitalRateOptions = [];
        this.targetCapitalAverageValue = this.summaryStatisticsValues.AverageDebt_TotalCapital;
        this.targetCapitalMedianValue = this.summaryStatisticsValues.MedianDebt_TotalCapital;
        this.targetCapitalOriginalAverageValue = this.summaryStatisticsValues.AverageDebt_Equity;
        this.targetCapitalOriginalMedianValue = this.summaryStatisticsValues.MedianDebt_Equity;
        if (this.targetCapitalAverageValue && this.targetCapitalMedianValue) {
            this.targetCapitalRateOptions.push({
                name: this.targetCapitalAverageValue + '% - ' + "Average D/TC of Comparable Companies",
                value: this.targetCapitalAverageValue,
                type: ComboboxDataType.String,
                source: "Average D/TC of Comparable Companies",
                id: 1
            },
                {
                    name: this.targetCapitalMedianValue + '% - ' + "Median D/TC of Comparable Companies",
                    value: this.targetCapitalMedianValue,
                    type: ComboboxDataType.String,
                    source: "Median D/TC of Comparable Companies",
                    id: 2
                }
            );

        }

    }
    public getReleveredSummaryStatistcs(input: any): void {
        this.releveredInputType = input;
        if (this.unleveredBetas.length === 0 || !this.capitalRateSelected || !this.taxRateSelected) {
            this.minimumSummaryValue = '-';
            this.maximumSummaryValue = '-';
            this.averageSummaryValue = '-';
            this.firstQuartileSummaryValue = '-';
            this.thirdQuartileSummaryValue = '-';
            this.medianSummaryValue = '-';
        } else if (this.releveredInputType === "VasicekAdjusted" && this.unleveredBetas.every((x) => x.VasicekAdjusted === '-')) {
            this.minimumSummaryValue = '-';
            this.maximumSummaryValue = '-';
            this.averageSummaryValue = '-';
            this.firstQuartileSummaryValue = '-';
            this.thirdQuartileSummaryValue = '-';
            this.medianSummaryValue = '-';
        } else if (this.unleveredBetas.find((x) => x.OLSBeta !== "-") && this.capitalRateSelected && this.taxRateSelected) {
            this.unleveredMinimumSummaryValue = this.summaryStatisticsValues[`UnleveredMinimum${this.releveredInputType}`];
            this.unleveredMaximumSummaryValue = this.summaryStatisticsValues[`UnleveredMaximum${this.releveredInputType}`];
            this.unleveredAverageSummaryValue = this.summaryStatisticsValues[`UnleveredAverage${this.releveredInputType}`];
            this.unleveredFirstQuartileSummaryValue = this.summaryStatisticsValues[`UnleveredFirstQuartile${this.releveredInputType}`];
            this.unleveredThirdQuartileSummaryValue = this.summaryStatisticsValues[`UnleveredThirdQuartile${this.releveredInputType}`];
            this.unleveredMedianSummaryValue = this.summaryStatisticsValues[`UnleveredMedian${this.releveredInputType}`];
            const releveredBetaconsatnt = (1 + this.capitalRateSelected / 100 * (1 - this.taxRateSelected / 100));
            this.minimumSummaryValue = (this.unleveredMinimumSummaryValue * releveredBetaconsatnt).toFixed(2);
            this.maximumSummaryValue = (this.unleveredMaximumSummaryValue * releveredBetaconsatnt).toFixed(2);
            this.averageSummaryValue = (this.unleveredAverageSummaryValue * releveredBetaconsatnt).toFixed(2);
            this.firstQuartileSummaryValue = (this.unleveredFirstQuartileSummaryValue * releveredBetaconsatnt).toFixed(2);
            this.thirdQuartileSummaryValue = (this.unleveredThirdQuartileSummaryValue * releveredBetaconsatnt).toFixed(2);
            this.medianSummaryValue = (this.unleveredMedianSummaryValue * releveredBetaconsatnt).toFixed(2);
        }
        this.releveredInputs = {
            BetaType: this.releveredInputType,
            TargetCapitalStructure: this.capitalRateSelected,
            OriginalTargetCapitalStructure: this.originalTargetCapitalStructure,
            TaxRate: this.taxRateSelected,
            TargetLabel: this.capitalRateSelectedLabel,
            TaxRateLabel: this.taxRateSelectedLabel
        };
        this.releveredStatistics = {
            Minimum: this.minimumSummaryValue,
            FirstQuartile: this.firstQuartileSummaryValue,
            Average: this.averageSummaryValue,
            Median: this.medianSummaryValue,
            ThirdQuartile: this.thirdQuartileSummaryValue,
            Maximum: this.maximumSummaryValue
        };
    }
    public getLeveredSummaryStatistics(input: any): void {
        this.leveredInputType = input;
        if (this.leveredBetas.length > 0) {
            this.leveredminimumSummaryValue = this.summaryStatisticsValues[`LeveredMinimum${this.leveredInputType}`];
            this.leveredmaximumSummaryValue = this.summaryStatisticsValues[`LeveredMaximum${this.leveredInputType}`];
            this.leveredaverageSummaryValue = this.summaryStatisticsValues[`LeveredAverage${this.leveredInputType}`];
            this.leveredfirstQuartileSummaryValue = this.summaryStatisticsValues[`LeveredFirstQuartile${this.leveredInputType}`];
            this.leveredthirdQuartileSummaryValue = this.summaryStatisticsValues[`LeveredThirdQuartile${this.leveredInputType}`];
            this.leveredmedianSummaryValue = this.summaryStatisticsValues[`LeveredMedian${this.leveredInputType}`];
        }
        this.leveredStatistics = {
            Minimum: this.leveredminimumSummaryValue,
            FirstQuartile: this.leveredfirstQuartileSummaryValue,
            Average: this.leveredaverageSummaryValue,
            Median: this.leveredmedianSummaryValue,
            ThirdQuartile: this.leveredthirdQuartileSummaryValue,
            Maximum: this.leveredmaximumSummaryValue
        };
    }
    public getUneveredSummaryStatistics(input: any): void {
        this.unleveredInputType = input;
        if (this.unleveredBetas.length > 0) {
            this.unleveredMinimumSummaryValue = this.summaryStatisticsValues[`UnleveredMinimum${this.unleveredInputType}`];
            this.unleveredMaximumSummaryValue = this.summaryStatisticsValues[`UnleveredMaximum${this.unleveredInputType}`];
            this.unleveredAverageSummaryValue = this.summaryStatisticsValues[`UnleveredAverage${this.unleveredInputType}`];
            this.unleveredFirstQuartileSummaryValue = this.summaryStatisticsValues[`UnleveredFirstQuartile${this.unleveredInputType}`];
            this.unleveredThirdQuartileSummaryValue = this.summaryStatisticsValues[`UnleveredThirdQuartile${this.unleveredInputType}`];
            this.unleveredMedianSummaryValue = this.summaryStatisticsValues[`UnleveredMedian${this.unleveredInputType}`];
        }
        this.unleveredStatistics = {
            Minimum: this.unleveredMinimumSummaryValue,
            FirstQuartile: this.unleveredFirstQuartileSummaryValue,
            Average: this.unleveredAverageSummaryValue,
            Median: this.unleveredMedianSummaryValue,
            ThirdQuartile: this.unleveredThirdQuartileSummaryValue,
            Maximum: this.unleveredMaximumSummaryValue
        };
    }
    public searchComparableCompanies(isComparableSearch: boolean): void {
        this.isSearchClicked = true;
        this.userStore.user.onceDefined((user) => {
            this.isBasicSubscriber = user.Subscriptions.some((item) => item.Type === "BtaBasic");
            if (this.isBasicSubscriber) {
                this.betaComparbleCompanyRequest.incorporationCountry = "United States";
                this.betaComparbleCompanyRequest.headquarterCountry = null;
            }
            if (isComparableSearch) {
                this.getComparbleCompanyList();
            } else {
                this.getCompanyByCompanyName(isComparableSearch);
            }
        });

    }

    public clearFields(): void {
        if (this.isSearchClicked) {
            this.clearSelectedFilters();
            this.betaComparbleCompanyRequest.companyName = null;
            this.betaComparbleCompanyRequest.observations = null;
            this.betaComparbleCompanyRequest.observationsOperatorName = null;
            this.betaComparbleCompanyRequest.industry = null;
            this.betaComparbleCompanyRequest.keyWords = null;
            this.betaComparbleCompanyRequest.sales = null;
            this.betaComparbleCompanyRequest.salesOperatorName = '';
            this.betaComparbleCompanyRequest.EBITDA = null;
            this.betaComparbleCompanyRequest.EBITDAOperatorName = null;
            this.betaComparbleCompanyRequest.assets = null;
            this.betaComparbleCompanyRequest.assetsOperatorName = null;
            this.betaComparbleCompanyRequest.marketCap = null;
            this.betaComparbleCompanyRequest.marketCapOperatorName = null;
            this.betaComparbleCompanyRequest.incorporationCountry = null;
            this.betaComparbleCompanyRequest.headquarterCountry = null;
            // this.getComparbleCompanyList();
            // this.AssigneSelectedCompanies();
            this.setPageCount();

            this.isSearchClicked = false;
        } else {
            this.clearSelectedFilters();
        }
    }
    public clearSelectedFilters(): void {
        this.filteredCompanyList = [];
        this.isSerchByValue = false;
        this.operators = [];
        this.selectedFilters.forEach((filter) => {
            filter.inputValue = '';
            filter.isInputDisabled = true;
        });


    }
    public insertTaxRateOptions(): void {
        this.taxRateconfig = [];
        this.taxRateOptions = [];
        this.taxRate = {};
        this.finalSelectedCompanies.forEach((company: BetaComparbleCompanyResults) => {
            // const taxRate = this.unleveringTaxRateValues.find((x: { GVKEY: string; }) => x.GVKEY === company.GVKEY);
            this.taxRateconfig.push({
                name: '',
                containerClassList: 'input-flex-display inline-disable-text',
                stackedDisplay: true,
                mask: '{}%',
                iconWithLabel: true,
                disabled: company.IsHidden ? true : false
            });


            this.taxRateOptions.push(this.getTaxRateOptions(company.GVKEY, company.IsHidden));
            if (this.getTaxRateOptions(company.GVKEY).length > 0) {
                const rate: ContextualString = {
                    value: this.getTaxRateOptions(company.GVKEY)[0].value,
                    context: null,
                    source: "" + this.getTaxRateOptions(company.GVKEY)[0].source,
                    dataAsOf: null,
                    id: null
                };
                this.taxRate[company.GVKEY] = rate;
            }
            // this.getUnleveredTaxRate(rate, company.GVKEY)
            // if (taxRate) {
            //     const rate: ContextualString = {
            //         value: taxRate.TaxRateValue.toString(),
            //         context: null,
            //         source: null,
            //         dataAsOf: null,
            //         id: null
            //     };
            //     this.taxRate[company.GVKEY] = rate;
            // } else {
            //     this.taxRate[company.GVKEY] = new ContextualString();
            // }
        });
        if (this.taxRate) {
            Object.keys(this.taxRate).forEach((GVKEY) => {
                this.getUnleveredTaxRate(this.taxRate[GVKEY], GVKEY);
            });
        }
    }

    // public SetUleverdBetas(): void {
    //     Object.keys(this.taxRate).forEach((GVKEY) => {
    //         this.getUnleveredTaxRate(this.taxRate[GVKEY], GVKEY);
    //     });
    // }

    public getTaxRateOptions(gvKey: any, isGvKeyHidden: boolean = false): Suggestion<string>[] {
        const options: Suggestion<string>[] = [];
        if (this.IntlTaxRates.length > 0) {
            const taxRate: IntlDataUnlevering | null
                = this.IntlTaxRates.length > 0
                    ? this.IntlTaxRates.filter((x) => x.GVKEY === gvKey)[0] : null;
            if (taxRate && taxRate.Value !== null && taxRate.Value >= 0) {
                options.push({
                    name: NumberFormatUtil.numberWithCommas(Util.round(taxRate.Value), 2).toString() + '% - ' + taxRate.Label,
                    value: NumberFormatUtil.numberWithCommas(Util.round(taxRate.Value), 2).toString(),
                    type: ComboboxDataType.String,
                    source: taxRate.Label ? taxRate.Label : '-',
                    sourceName: gvKey,
                    disabled: isGvKeyHidden ? true : false
                });
            } else if (!taxRate || taxRate.Value === null) {
                options.push({
                    name: '',
                    value: '',
                    type: ComboboxDataType.String,
                    source: '',
                    sourceName: gvKey,
                    disabled: isGvKeyHidden ? true : false
                });
            }
        } else {
            options.push({
                name: '',
                value: '',
                type: ComboboxDataType.String,
                source: '',
                sourceName: gvKey,
                disabled: isGvKeyHidden ? true : false
            });
        }
        return options;
    }

    public getUnleveredTaxRate(TaxRate: any, GVKEY: any): void {
        const tempTaxRate = TaxRate.value.replace(/[^0-9\.]/g, "");
        if (tempTaxRate !== TaxRate.value) {
            this.taxRate[GVKEY].value = tempTaxRate;
            return;
        }
        this.unleveringTaxRateoptions.TaxRateValue = TaxRate.value;
        this.unleveringTaxRateoptions.GVKEY = GVKEY;
        this.taxRateOptions.forEach((rate: any) => {
            const label = rate.find((x: any) => x.sourceName === GVKEY);
            if (label) {
                label.source = TaxRate.value === label.value ? this.getTaxRateOptions(GVKEY)[0].source : "Custom";
                this.unleveringTaxRateoptions.Label = label.source;
            }
        });
        if (this.unleveringTaxRateoptions) {
            this.getUnleveredBetas(this.unleveringTaxRateoptions);
            this.unleveringTaxInputs(this.unleveringTaxRateoptions);
        }
        this.getAllSummaryStatistics();
        this.insertTaxRateReleveredOptions();
        if (this.betaTypeSelected) {
            this.getReleveredSummaryStatistcs(this.betaTypeSelected);
        }
    }

    public setEstimates(): void {
        this.metricInputs = {} as MetricInputs,
            // this.betaService.metricInputs.ValuationDate = new Date() as any,
            //     this.betaService.metricInputs.Currency = '',
            //     this.betaService.metricInputs.Frequency = '',
            //     this.betaService.metricInputs.LookbackPeriod = '',
            this.marketIndexName = '',
            this.betasAsOf = new Date(),
            this.finalSelectedCompanies = [],
            this.leveredBetas = [],
            this.betaStatistics = [],
            this.unleveringInputs = [],
            this.unleveredBetas = [],
            this.releveredInputs = {} as ReleveredInputs,
            this.releveredStatistics = {} as Statistics,
            this.leveredStatistics = {} as Statistics,
            this.unleveredStatistics = {} as Statistics;
    }

    public updateEstimate(betasName?: string): void {
        const betaEstimate: BetaEstimateRequest = {
            EstimateId: this.estimateId,
            MetricInputs: this.metricInputs,
            MarketIndexName: this.marketIndexName,
            BetasDataAsOf: this.betasAsOf,
            Companies: this.finalSelectedCompanies,
            LeveredBetas: this.leveredBetas,
            BetaStatistics: this.betaStatistics,
            UnleveringBetas: this.unleveringInputs,
            UnleveredBetas: this.unleveredBetas,
            ReleveredInputs: this.releveredInputs,
            ReleveredStatistics: this.releveredStatistics,
            LeveredStatistics: this.leveredStatistics,
            UnleveredStatistics: this.unleveredStatistics
        };




        // betaEstimate.MetricInputs.Location = this.locationName;
        // betaEstimate.MetricInputs.Currency = betaEstimate.MetricInputs.Currency !== null ? this.fullCurrencyName : 'USD - US Dollar';
        betaEstimate.Companies.forEach((company: BetaComparbleCompanyResults) => {
            company.mAsOf = company.mAsOf !== "" ? company.mAsOf : betaEstimate.MetricInputs.ValuationDate;
        });
        betaEstimate.LeveredBetas.forEach((beta: LeveredBetas) => {
            beta.VasicekAdjusted = beta.VasicekAdjusted === '-' ? null : beta.VasicekAdjusted;
        });

        betaEstimate.UnleveredBetas.forEach((beta: LeveredBetas) => {
            beta.VasicekAdjusted = beta.VasicekAdjusted === '-' ? null : beta.VasicekAdjusted;
            beta.OLSBeta = beta.OLSBeta === '-' ? null : beta.OLSBeta;
            beta.SumBeta = beta.SumBeta === '-' ? null : beta.SumBeta;
            beta.PeerGroup = beta.PeerGroup === '-' ? null : beta.PeerGroup;
            beta.BlumeAdjusted = beta.BlumeAdjusted === '-' ? null : beta.BlumeAdjusted;
        });

        betaEstimate.UnleveringBetas.forEach((beta: UnleveringInputs) => {
            beta.TotalDebt = beta.TotalDebt === '-' ? null : beta.TotalDebt;
            beta.MarketCap = beta.MarketCap === '-' ? null : beta.MarketCap;
            beta.Debt_Equity = beta.Debt_Equity === '-' ? null : beta.Debt_Equity;
            beta.Debt_TotalCapital = beta.Debt_TotalCapital === '-' ? null : beta.Debt_TotalCapital;
            beta.TaxRate = (beta.TaxRate === '-' || beta.TaxRate === '') ? null : beta.TaxRate;
            beta.Label = beta.Label === '-' ? '' : beta.Label;
        });

        if (betaEstimate.ReleveredInputs) {
            betaEstimate.ReleveredInputs.OriginalTargetCapitalStructure = betaEstimate.ReleveredInputs.OriginalTargetCapitalStructure !== null ?
                betaEstimate.ReleveredInputs.OriginalTargetCapitalStructure : betaEstimate.ReleveredInputs.TargetCapitalStructure;
        }

        if (betaEstimate.LeveredStatistics) {
            betaEstimate.LeveredStatistics.Minimum = betaEstimate.LeveredStatistics.Minimum === '-' || isNaN(betaEstimate.LeveredStatistics.Minimum) ? null : betaEstimate.LeveredStatistics.Minimum;
            betaEstimate.LeveredStatistics.Average = betaEstimate.LeveredStatistics.Average === '-' || isNaN(betaEstimate.LeveredStatistics.Average) ? null : betaEstimate.LeveredStatistics.Average;
            betaEstimate.LeveredStatistics.Median = betaEstimate.LeveredStatistics.Median === '-' || isNaN(betaEstimate.LeveredStatistics.Median) ? null : betaEstimate.LeveredStatistics.Median;
            betaEstimate.LeveredStatistics.FirstQuartile = betaEstimate.LeveredStatistics.FirstQuartile === '-' || isNaN(betaEstimate.LeveredStatistics.FirstQuartile) ? null : betaEstimate.LeveredStatistics.FirstQuartile;
            betaEstimate.LeveredStatistics.ThirdQuartile = betaEstimate.LeveredStatistics.ThirdQuartile === '-' || isNaN(betaEstimate.LeveredStatistics.ThirdQuartile) ? null : betaEstimate.LeveredStatistics.ThirdQuartile;
            betaEstimate.LeveredStatistics.Maximum = betaEstimate.LeveredStatistics.Maximum === '-' || isNaN(betaEstimate.LeveredStatistics.Maximum) ? null : betaEstimate.LeveredStatistics.Maximum;
        }

        if (betaEstimate.UnleveredStatistics) {
            betaEstimate.UnleveredStatistics.Minimum = betaEstimate.UnleveredStatistics.Minimum === '-' || isNaN(betaEstimate.UnleveredStatistics.Minimum) ? null : betaEstimate.UnleveredStatistics.Minimum;
            betaEstimate.UnleveredStatistics.Average = betaEstimate.UnleveredStatistics.Average === '-' || isNaN(betaEstimate.UnleveredStatistics.Average) ? null : betaEstimate.UnleveredStatistics.Average;
            betaEstimate.UnleveredStatistics.Median = betaEstimate.UnleveredStatistics.Median === '-' || isNaN(betaEstimate.UnleveredStatistics.Median) ? null : betaEstimate.UnleveredStatistics.Median;
            betaEstimate.UnleveredStatistics.FirstQuartile = betaEstimate.UnleveredStatistics.FirstQuartile === '-' || isNaN(betaEstimate.UnleveredStatistics.FirstQuartile) ? null : betaEstimate.UnleveredStatistics.FirstQuartile;
            betaEstimate.UnleveredStatistics.ThirdQuartile = betaEstimate.UnleveredStatistics.ThirdQuartile === '-' || isNaN(betaEstimate.UnleveredStatistics.ThirdQuartile) ? null : betaEstimate.UnleveredStatistics.ThirdQuartile;
            betaEstimate.UnleveredStatistics.Maximum = betaEstimate.UnleveredStatistics.Maximum === '-' || isNaN(betaEstimate.UnleveredStatistics.Maximum) ? null : betaEstimate.UnleveredStatistics.Maximum;
        }

        if (betaEstimate.ReleveredStatistics) {
            betaEstimate.ReleveredStatistics.Minimum = betaEstimate.ReleveredStatistics.Minimum === '-' || isNaN(betaEstimate.ReleveredStatistics.Minimum) ? null : betaEstimate.ReleveredStatistics.Minimum;
            betaEstimate.ReleveredStatistics.Average = betaEstimate.ReleveredStatistics.Average === '-' || isNaN(betaEstimate.ReleveredStatistics.Average) ? null : betaEstimate.ReleveredStatistics.Average;
            betaEstimate.ReleveredStatistics.Median = betaEstimate.ReleveredStatistics.Median === '-' || isNaN(betaEstimate.ReleveredStatistics.Median) ? null : betaEstimate.ReleveredStatistics.Median;
            betaEstimate.ReleveredStatistics.FirstQuartile = betaEstimate.ReleveredStatistics.FirstQuartile === '-' || isNaN(betaEstimate.ReleveredStatistics.FirstQuartile) ? null : betaEstimate.ReleveredStatistics.FirstQuartile;
            betaEstimate.ReleveredStatistics.ThirdQuartile = betaEstimate.ReleveredStatistics.ThirdQuartile === '-' || isNaN(betaEstimate.ReleveredStatistics.ThirdQuartile) ? null : betaEstimate.ReleveredStatistics.ThirdQuartile;
            betaEstimate.ReleveredStatistics.Maximum = betaEstimate.ReleveredStatistics.Maximum === '-' || isNaN(betaEstimate.ReleveredStatistics.Maximum) ? null : betaEstimate.ReleveredStatistics.Maximum;
        }



        this.betaEstimateRequest = betaEstimate;
        console.log(this.betaEstimateRequest);


        if (betasName === 'levered' || betasName === 'statistics') {
            // this.updateEstimateCalled = true;
            const request = this.dataStore.updateBetaEstimate(this.betaEstimateRequest);
            if (this.metricInputs.MarketIndex != null) {
                //this.spinner.while(request)
                this.spinner.begin();
            }
            request.once(() => {
                this.betasUpdated = true;
                this.store.dispatch(new GetBetaEstimates());
                this.spinner.end();
            });
        } else {
            // this.dataStore.updateBetaEstimate(this.betaEstimateRequest);
            // this.store.dispatch(new GetBetaEstimates());
            const request = this.dataStore.updateBetaEstimate(this.betaEstimateRequest);
            // this.spinner.while(request);
            this.spinner.begin();
            request.once(() => {
                this.store.dispatch(new GetBetaEstimates());
                this.spinner.end();
            });
        }
        if (!this.isBetaEstimate) {
            this.startSpinner = true;
        }

    }


    // public getUnleveredData() {
    //     const data: LeveredBetas[] = [];
    //     this.unleveredBetas.forEach((beta: LeveredBetas) => {
    //         data.push(beta);
    //     });

    //     data.forEach((beta: LeveredBetas) => {
    //         beta.VasicekAdjusted = beta.VasicekAdjusted === '-' ? null : beta.VasicekAdjusted;
    //         beta.OLSBeta = beta.OLSBeta === '-' ? null : beta.OLSBeta;
    //         beta.SumBeta = beta.SumBeta === '-' ? null : beta.SumBeta;
    //         beta.PeerGroup = beta.PeerGroup === '-' ? null : beta.PeerGroup;
    //         beta.BlumeAdjusted = beta.BlumeAdjusted === '-' ? null : beta.BlumeAdjusted;
    //     });

    //     return data;
    // }

    public getCompanyByCompanyName(iscompSearch = true): void {
        const request = this.dataStore.getComparbleCompanyList(this.betaComparbleCompanyRequest);
        this.spinner.while(request);
        request.once((results: BetaComparbleCompanyResults[]) => {
            if (results.length > 0) {
                results.map((obj) => {
                    obj.Assets = obj.Assets ? Util.round(obj.Assets, 1) : obj.Assets;
                    obj.EBITDA = obj.EBITDA ? Util.round(obj.EBITDA, 1) : obj.EBITDA;
                    obj.MarketCap = obj.MarketCap ? Util.round(obj.MarketCap, 1) : obj.MarketCap;
                    obj.Sales = obj.Sales ? Util.round(obj.Sales, 1) : obj.Sales;
                    obj.TotalDebt = obj.TotalDebt ? Util.round(obj.TotalDebt, 1) : obj.TotalDebt;

                });
                const targetcomp = this.finalSelectedCompanies.find((obj: any) => obj.GVKEY === results[0].GVKEY);
                Object.assign(targetcomp, results[0]);

                if (!iscompSearch) {

                    const companyRequest: CompanyReturnsRequest = {
                        GVKEY: [targetcomp.GVKEY],
                        Metrics: this.metricInputs
                    };
                    this.getCompanyOLSBeta(companyRequest)
                }

            }
        });
    }

    public getCompanyInformation(selectedCompany: any, isCmprblcmpSectionClick: boolean): void {

        const inputs: CompanyValuationInputs = {
            GVKEY: selectedCompany.GVKEY,
            ValuationDate: new Date(this.metricInputs.ValuationDate)
        };
        const request = this.getCompanyDetails(inputs);
        this.spinner.begin();
        //this.spinner.while(request)
        request.once((data: CompanyInformation) => {

            this.CompanyInfo = data
            this.CompanyInfo.lstBusinessSegments.map((item: any) => { item.BusinesssSalesPrc = Util.round((item.BusinessSales * 100), 2) + '%'; });
            this.CompanyInfo.lstGeographySegment.map((item: any) => { item.GeoSalesprc = Util.round((item.GeoSales * 100), 2) + '%'; });

            this.selectedCompany = this.finalSelectedCompanies.find((cmp: any) => cmp.GVKEY == selectedCompany.GVKEY);;

            if (!this.selectedCompany) {
                this.selectedCompany = selectedCompany;
            }

            const companyRequest: CompanyReturnsRequest = {
                GVKEY: [selectedCompany.GVKEY],
                Metrics: this.metricInputs
            };


            // if (isCmprblcmpSectionClick && !selectedCompany.Website) {
            //     this.betaComparbleCompanyRequest.companyName = selectedCompany.CompanyName;
            //     this.searchComparableCompanies(false);


            // } else {

            this.getCompanyOLSBeta(companyRequest);
            // }
        });
    }

    public getCompanyDetails(companyInfo: CompanyValuationInputs): Observable<CompanyInformation> {
        return this.dataStore.GetCompanyInformation(companyInfo);
    }


    public getCompanyOLSBeta(companyRequest: CompanyReturnsRequest) {

        this.isCompanyDisplay = false;
        const loockBackperiod = this.lookBackKeyValue[companyRequest.Metrics.LookbackPeriod as keyof typeof this.lookBackKeyValue]
        const loockBackDate = new Date(companyRequest.Metrics.ValuationDate);
        loockBackDate.setFullYear(loockBackDate.getFullYear() - loockBackperiod);


        const request = this.dataStore.getCompanyOLSBeta(companyRequest);
        request.once((data: OLSBetaCompanyReturns[]) => {



            const dates = companyRequest.Metrics.Frequency === "Monthly" ? this.GetMonthlyDates(companyRequest, new Date(data[0].DataAsOf)) : this.GetWeeklyDates(companyRequest, new Date(data[0].DataAsOf));

            if (dates.length > 0) {
                dates.forEach((date) => {
                    let remainingDates: OLSBetaCompanyReturns = {
                        GVKEY: companyRequest.GVKEY[0],
                        DataAsOf: date,
                        OlsBeta: null,
                        SumBeta: null
                    };
                    if (this.compareDate(date, data[data.length - 1])) {
                        data.push(remainingDates)
                    } else {
                        data.unshift(remainingDates);
                    }
                });
            }
            // const finalRetuns = data.filter(s => new Date(s.DataAsOf).getTime() <= new Date(companyRequest.Metrics.ValuationDate).getTime() && new Date(s.DataAsOf).getTime() >= loockBackDate.getTime());
            this.SetOLSGraphCData(data, companyRequest.Metrics.Frequency);
        });

    }



    public SetOLSGraphCData(companyReturns: OLSBetaCompanyReturns[], Frequency: string) {

        let marketReturns: any[] = [];
        let dateArray: string[] = [];
        this.OlsBetaChart = null;
        const interval = Frequency === 'Monthly' ? 1 : 52;

        companyReturns.forEach((msciReturn) => {
            dateArray = [...dateArray, moment(msciReturn.DataAsOf).format('MM/DD/YYYY')];
            marketReturns = [...marketReturns, msciReturn.OlsBeta];
        });


        try {

            // this.OlsBetaChart.series[0].data = [...marketReturns];
            // this.OlsBetaChart.xAxis.categorie = [...dateArray];
            // this.OlsBetaChart.xAxis.tickPositions = [0, dateArray.length - 1];




            this.OlsBetaChart = new Chart({
                chart: {
                    height: 200,
                    width: 380,
                    type: 'line',
                    style: {
                        fontFamily: "nunito-sans"
                    }
                },
                navigation: {
                    buttonOptions: {
                        enabled: false
                    }
                },
                title: {
                    text: ''
                },
                series: [
                    {
                        showInLegend: false,
                        type: 'line',
                        name: '',
                        color: 'rgb(0, 48, 87)',
                        data: [...marketReturns],
                        marker: {
                            enabled: false
                        },


                    }
                ],
                xAxis: {
                    categories: [...dateArray],
                    gridLineWidth: 0,
                    // tickInterval: interval,
                    gridLineColor: 'transparent',
                    tickPositions: [0, dateArray.length - 1]
                },
                yAxis: {
                    title: {
                        text: ''
                    },
                    tickInterval: 0.1,
                    gridLineWidth: 0,
                    gridLineColor: 'transparent'
                    // min: 1,
                    // startOnTick: true
                },
                tooltip: {
                    enabled: true
                },
                credits: {
                    enabled: false
                }
            });
            this.spinner.end();
            setTimeout(() => {
                this.isCompanyDisplay = true;
            }, 10);


        } catch (error) {
            console.log(error);
        }


    }


    public compareDate(remaindate: any, lastDate: any): boolean {
        const remain = new Date(remaindate);
        const last = new Date(lastDate);
        if (remain > last) {
            return true;
        }
        else {
            return false;
        }


    }

    // public getCompanyOlsBeta(companyRequest: CompanyReturnsRequest): Observable<CompanyReturns[]> {
    //     return this.dataStore.getCompanyOLSBeta(companyRequest);
    // }

    public GetMonthlyDates(companyRequest: any, startDate: Date): string[] {
        let dates: string[] = [];
        const date = new Date(companyRequest.Metrics.ValuationDate);
        const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        const lastDay = date.toDateString() === lastDayOfMonth.toDateString() ? lastDayOfMonth : new Date(date.getFullYear(), date.getMonth(), 0);
        const endDate = new Date(lastDay.setFullYear(lastDay.getFullYear() - 10));
        if (startDate !== endDate) {
            let currentDate = new Date(
                startDate.getFullYear(),
                startDate.getMonth(),
                startDate.getDate()
            );

            while (currentDate >= endDate) {
                dates.push(moment(currentDate).format('YYYY-MM-DD hh:mm:ss'));
                currentDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
            }
        }
        return dates;
    }

    public GetWeeklyDates(companyRequest: any, startDate: Date): string[] {
        let dates: string[] = [];

        const date = new Date(companyRequest.Metrics.ValuationDate);
        const day = date.getDate() + (6 - date.getDay() - 1) - 7;

        const endDate = new Date(date.setFullYear(date.getFullYear() - 10));
        const day2 = endDate.getDate() + (6 - endDate.getDay() - 1) - 7;

        const fridayStartDate = startDate.getDay() === 5 ? startDate : new Date(startDate.setDate(day));
        const fridayEndDate = endDate.getDay() === 5 ? endDate : new Date(endDate.setDate(day2));

        if (fridayStartDate !== fridayEndDate) {
            let currentDate = new Date(
                fridayEndDate.getFullYear(),
                fridayEndDate.getMonth(),
                fridayEndDate.getDate()
            );

            while (currentDate <= fridayStartDate) {
                currentDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 7);
                dates.push(moment(currentDate).format('YYYY-MM-DD hh:mm:ss'));
            }
        }
        const finalDates = dates.splice(0, 1);
        // lastFriday.setDate(day);
        return dates;
    }


    public getGicList(): Observable<GicIndustry[]> {
        return this.dataStore.getGicList();

    }


    // public AssigneSelectedCompanies(): Observable<boolean> {
    //     this.isSearchClicked = false;
    //     if (this.finalSelectedCompanies.length > 0) {
    //         this.tempIndustryCodeDescription = [];
    //         this.finalSelectedCompanies.forEach((result: any) => {
    //             this.filteredCompanyList = this.finalSelectedCompanies;
    //             const splitData = result.IndustryCodeDescription ? result.IndustryCodeDescription.split("–") : [];
    //             if (!this.tempIndustryCodeDescription.some((ind) => ind.IndustryCode === splitData[0])) {
    //                 this.tempIndustryCodeDescription.push({
    //                     IndustryCode: splitData[0],
    //                     Sector: splitData[1]
    //                 });
    //             }
    //         })

    //         this.lstIndustryCodeDescription = this.tempIndustryCodeDescription;
    //     } else {
    //         this.filteredCompanyList = [];
    //     }
    //     return of(true);

    // }



}
