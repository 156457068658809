import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Auth0ApiClient } from "../api.client";

@Injectable()
export class CompanyClient extends Auth0ApiClient {
    protected baseUrl = `${environment.urls.navigatorApi}/api`;

    public getComapnyNames(industryId: number, dataAsOf: string, betaCompanyType: string, area: string, currency: string): Observable<string[]> {
        return this.get(`Company/Details?industryId=${industryId}&&dataAsOf=${dataAsOf}&&betaCompanyType=${betaCompanyType}&&area=${area}&&currency=${currency}`);
    }
}