import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Spinner, SubscriberEntity } from '@concurrency/angular';
import { takeUntil } from 'rxjs/operators';
import { Industry } from '../_api/responses/us-industry.response';
import { Tab } from '../_navigator/data/enum/tab.enum';
import { Step } from '../_navigator/data/model/step.model';
import { BenchmarkingService } from '../_navigator/data/service/benchmarking.service';
import { DataStore } from '../_navigator/data/store/data.store';
import { Steps } from '../_navigator/data/util/steps-utilities';
import { IndustryDataSharingService } from '../estimate/studies/service/industry-data-sharing.service';
import { BenchmarkScenario } from './data/benchmark-scenario';

@Component({
    selector: 'app-benchmarking',
    templateUrl: './benchmarking.component.html'
})
export class BenchmarkingComponent extends SubscriberEntity implements OnInit, OnDestroy {
    public canExport = false;
    public steps: Step[] = Steps.getBenchmarkingSteps();
    public activeTab!: Tab | null;
    public modName = 'usiBenchmarking';

    constructor(
        public router: Router,
        public spinner: Spinner,
        private dataStore: DataStore,
        private benchmarkingService: BenchmarkingService,
        private industryDataSharingService: IndustryDataSharingService
    ) {
        super();
    }

    public ngOnInit(): void {


        this.dataStore.allowExport.pipe(takeUntil(this.destroyed)).while((allowExport) => this.canExport = allowExport);

        this.activeTab = Steps.setupTab(this.router.url, this.steps);
        this.router.events.pipe(takeUntil(this.destroyed)).while(() => {
            this.activeTab = Steps.setupTab(this.router.url, this.steps);
        });
    }
    public ngOnDestroy(): void {
        this.benchmarkingService.setBenchmarkingScenarios = new Array<BenchmarkScenario>();
        this.benchmarkingService.benchMarkingValuationDate = undefined;
        this.industryDataSharingService.setSharedBenchmarkingIndustries = new Array<Industry>();
    }

}
