import { Component } from '@angular/core';
import { SelectionType } from 'src/app/_api/enums/selection-type';
import { EquationType, ImplicationType } from 'src/app/_api/responses/equation.response';
import { InputType } from 'src/app/_api/responses/input.response';
import { Equation, EquationParameter } from 'src/app/_navigator/data/model/equation.model';
import { OperandUtility } from '../operand.utility';
import { StudyComponent } from '../study.component';

@Component({ templateUrl: '../study.component.html' })
export class HfrBuildupComponent extends StudyComponent {
    protected equationType = EquationType.HfrsBuildupHfr;
    protected hasRegressionToggle = false;

    public buildEquation(): Equation {
        const costOfEquityCapital = OperandUtility.getCostOfEquityCapital(this.equationType);

        const ke: EquationParameter = { item: costOfEquityCapital, operator: '=', canEdit: false };
        const rf: EquationParameter = { item: OperandUtility.riskFreeRate, operator: '+', canEdit: true };
        const rp: EquationParameter = { item: OperandUtility.hfrRiskPremiumOverRfr, operator: '+', canEdit: true };
        const erpa: EquationParameter = { item: OperandUtility.erpAdjusted, operator: '', canEdit: true };

        return {
            name: '',
            equationType: this.equationType,
            implicationType: ImplicationType.None,
            parameters: [ke, rf, rp, erpa],
            calculate: (scenario) => {
                const equation = scenario.getEquation(this.equationType, ImplicationType.None);
                const riskFreeRate = scenario.getValueOrNaN(SelectionType.RiskFreeRate, InputType.None);
                const riskPremium = scenario.getValueOrNaN(SelectionType.HighFinancialRiskPremiumOverRiskFreeRate, InputType.None);
                const equityRiskPremiumAdjustment = scenario.getValueOrNaN(SelectionType.EquityRiskPremiumAdjustment, InputType.None);

                const result = riskFreeRate + riskPremium + equityRiskPremiumAdjustment;
                equation.Result = result || undefined;

                return equation.Result != null;
            }
        };
    }
}
