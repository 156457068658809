import { SelectionType } from 'src/app/_api/enums/selection-type';
import { CountryCreditRating } from 'src/app/_api/responses/country-credit-rating.response';
import { InputType } from 'src/app/_api/responses/input.response';
import { Estimate } from 'src/app/_navigator/data/model/estimate.model';
import { CcrUtil } from './ccr.util';
import { IntlEstimateUtil } from './intl-estimate.util';

export class SelectionsUtil {
    public static setCostOfEquitySelections(estimate: Estimate): void {
        const scenario = estimate.Scenarios[0];
        const isCcrApplicable = scenario.hasValue(SelectionType.CountryRiskPremium);
        const isYsApplicable = scenario.hasValue(SelectionType.YieldSpread);
        const isRvApplicable = scenario.hasValue(SelectionType.RelativeVolatility);

        if (isCcrApplicable) {
            CcrUtil.setCostOfEquityCcr(estimate, SelectionType.CountryRiskPremium, SelectionType.CostOfEquityCapmCcr);
            CcrUtil.setFisherEffect(estimate, SelectionType.CostOfEquityCapmCcr, SelectionType.CostOfEquityFisherEffectCcr);
        }

        if (isYsApplicable) {
            CcrUtil.setCostOfEquityCcr(estimate, SelectionType.YieldSpread, SelectionType.CostOfEquityCapmYieldSpread);
            CcrUtil.setFisherEffect(estimate, SelectionType.CostOfEquityCapmYieldSpread, SelectionType.CostOfEquityFisherEffectYieldSpread);
        }

        if (isRvApplicable) {
            CcrUtil.setCostOfEquityVolatility(estimate);
            CcrUtil.setFisherEffect(
                estimate,
                SelectionType.CostOfEquityCapmVolatility,
                SelectionType.CostOfEquityFisherEffectVolatility
            );
        }
    }

    public static setCostOfDebtSelections(estimate: Estimate): void {
        const scenario = estimate.Scenarios[0];
        const isCcrApplicable = scenario.hasValue(SelectionType.CountryRiskPremium);
        const isYsApplicable = scenario.hasValue(SelectionType.YieldSpread);
        const isRvApplicable = scenario.hasValue(SelectionType.RelativeVolatility);

        if (isCcrApplicable) {
            CcrUtil.setCostOfDebtCcr(estimate, SelectionType.CountryRiskPremium, SelectionType.CostOfDebtCcr);
            CcrUtil.setFisherEffect(estimate, SelectionType.CostOfDebtCcr, SelectionType.CostOfDebtFisherEffectCcr);
        }

        if (isYsApplicable) {
            CcrUtil.setCostOfDebtCcr(estimate, SelectionType.YieldSpread, SelectionType.CostOfDebtYieldSpread);
            CcrUtil.setFisherEffect(estimate, SelectionType.CostOfDebtYieldSpread, SelectionType.CostOfDebtFisherEffectYieldSpread);
        }

        if (isRvApplicable) {
            CcrUtil.setCostOfDebtVolatility(estimate);
            CcrUtil.setFisherEffect(estimate, SelectionType.CostOfDebtVolatility, SelectionType.CostOfDebtFisherEffectVolatility);
        }
    }

    public static setWaccSelections(
        estimate: Estimate,
        applyKeFisherEffect: boolean,
        applyKdFisherEffect: boolean,
        useSingleCountryKd: boolean
    ): void {
        const scenario = estimate.Scenarios[0];
        const isCcrApplicable = scenario.hasValue(SelectionType.CountryRiskPremium);
        const isYsApplicable = scenario.hasValue(SelectionType.YieldSpread);
        const isRvApplicable = scenario.hasValue(SelectionType.RelativeVolatility);
        const useYsModelOnly = scenario.getSelection(SelectionType.UseYieldSpreadOnly, InputType.None).Value ? true : false;

        if (isCcrApplicable) {
            const keCcr = applyKeFisherEffect ? SelectionType.CostOfEquityFisherEffectCcr : SelectionType.CostOfEquityCapmCcr;
            const kdCcr = IntlEstimateUtil.getCostOfDebtSelection(
                applyKdFisherEffect,
                useSingleCountryKd,
                useYsModelOnly,
                SelectionType.CostOfDebtCcr,
                SelectionType.CostOfDebtFisherEffectCcr
            );
            CcrUtil.setWacc(estimate, keCcr, kdCcr, SelectionType.WaccCcr);
        }

        if (isYsApplicable) {
            const keYs = applyKeFisherEffect
                ? SelectionType.CostOfEquityFisherEffectYieldSpread
                : SelectionType.CostOfEquityCapmYieldSpread;
            const kdYs = IntlEstimateUtil.getCostOfDebtSelection(
                applyKdFisherEffect,
                useSingleCountryKd,
                useYsModelOnly,
                SelectionType.CostOfDebtYieldSpread,
                SelectionType.CostOfDebtFisherEffectYieldSpread
            );
            CcrUtil.setWacc(estimate, keYs, kdYs, SelectionType.WaccYieldSpread);
        }

        if (isRvApplicable) {
            const keRv = applyKeFisherEffect
                ? SelectionType.CostOfEquityFisherEffectVolatility
                : SelectionType.CostOfEquityCapmVolatility;
            const kdRv = IntlEstimateUtil.getCostOfDebtSelection(
                applyKdFisherEffect,
                useSingleCountryKd,
                useYsModelOnly,
                SelectionType.CostOfDebtVolatility,
                SelectionType.CostOfDebtFisherEffectVolatility
            );
            CcrUtil.setWacc(estimate, keRv, kdRv, SelectionType.WaccVolatility);
        }
    }

    public static setSingleCountryCapmSelections(estimate: Estimate): void {
        CcrUtil.setSingleCountryCapm(estimate);
        CcrUtil.setFisherEffect(estimate, SelectionType.KeSingleCountryCapm, SelectionType.KeSingleCountryFisherEffect);
    }

    public static setSingleCountryCostOfDebtSelections(estimate: Estimate): void {
        CcrUtil.setSingleCountryCostOfDebt(estimate);
        CcrUtil.setFisherEffect(estimate, SelectionType.KdSingleCountryCapm, SelectionType.KdSingleCountryFisherEffect);
    }

    public static setCcrSelections(estimate: Estimate, ccr: CountryCreditRating): void {
        CcrUtil.setCcr(ccr, estimate);
    }

    public static setSingleCountryWaccSelections(estimate: Estimate, applyKeFisherEffect: boolean, applyKdFisherEffect: boolean): void {
        const ke = applyKeFisherEffect ? SelectionType.KeSingleCountryFisherEffect : SelectionType.KeSingleCountryCapm;
        const kd = applyKdFisherEffect ? SelectionType.KdSingleCountryFisherEffect : SelectionType.KdSingleCountryCapm;
        CcrUtil.setWacc(estimate, ke, kd, SelectionType.WaccSingleCountry);
    }
}
