import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Observable } from 'rxjs/internal/Observable';
import { ValuesClient } from 'src/app/_api/clients/values.client';
import { MinimumDate } from 'src/app/_api/responses/minimum-date.response';
import { GetBasicMinimumDate } from './values-actions';
import { Injectable } from '@angular/core';


export type ValuesModel = MinimumDate | undefined;

@State<ValuesModel>({
    name: 'values',
    defaults: undefined
})

@Injectable()
export class ValuesState {

    @Selector()
    public static get(state: ValuesModel): MinimumDate | undefined {
        return state;
    }
    constructor(private valuesClient: ValuesClient) { }

    @Action(GetBasicMinimumDate)
    public getBasicMinimumDate(context: StateContext<ValuesModel>): Observable<void> {
        return this.valuesClient.getBasicMinimumDate().once((response) => {
            context.setState(response);
        });
    }
}
